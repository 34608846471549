import { useState } from "react";
import { logsEventUpselling, logsEventWarningUpselling } from "../Helpers/upsellingEvent";
import ApiService from "../Services/ApiService";

export const usePostDomicilioCredencial = ()=>{ 

    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setisLoading] = useState(false)
    const [errorDomicilio, setErrorDomicilio] = useState(false)
    
    const postDomicilioCredencial = (payload, callback)=>{
        
        let context = { 
            canalDeVenta: payload.dataDomicilio.IdCanalVenta,
            formaDeContacto: payload.IdFormaDeContacto,
            planId: payload.dataDomicilio.PlanId
        }
        
        setisLoading(true)
        setErrorDomicilio(false)

        ApiService.post(`${REACT_APP_APIPAYWALL}Domicilio/PosdatarDomicilio`, payload.dataDomicilio)
            .then((data) => {
                if (data && data.code === '0000') {
                    logsEventUpselling(context, payload.dataDomicilio)
                    callback(data.response)
                } else {
                    logsEventWarningUpselling(context, payload.dataDomicilio, null, data)
                }
            })
            .catch(() => {
                setisLoading(false)
                setErrorDomicilio(true)
                logsEventWarningUpselling(context, payload.dataDomicilio, null, data)
            })
            .finally(()=>{
                setisLoading(false)
            })
    }

    return { isLoading, errorDomicilio, postDomicilioCredencial }
}