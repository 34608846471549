import * as Yup from 'yup';

const MiClubFormLargoSchema = Yup.object().shape({
    tipoDoc: Yup.string()
        .required('Campo requerido'),
    nroDoc: Yup.string()
        .when('tipoDoc',{
            is: (val) => val === 'PAS',
            then: Yup.string().matches('^[A-Za-z0-9]{6,10}$', "El número de pasaporte es incorrecto"),
            otherwise: Yup.string().matches('^[1-9][0-9]{6,7}$', "El número de documento es incorrecto")
        })
        .required('Campo requerido'),
    credencial1: Yup.string()
        .matches('^[0-9]{6}$', 'Ingresá los primeros 6 números')
        .required('Campo requerido'),
    credencial2: Yup.string()
        .matches('^[0-9X]{8}$', 'Ingresá los 8 números')
        .required('Campo requerido'),
    credencial3: Yup.string()
        .matches('^[0-9]{2}$', 'Ingresá los últimos 2 números')
        .required('Campo requerido'),
});

export default MiClubFormLargoSchema;