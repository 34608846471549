import React, { useState } from 'react';
import useListarEventos from '../../../Hooks/useListarEventos';
import useObtenerDetalleTipoDeEvento from '../../../Hooks/useObtenerDetalleTipoDeEvento';
import BasicTablePaginationWithDrawer from '../../Common/Modules/Table/BasicTablePaginationWithDrawer';
import MapHistorialCredencialesToTable from '../../../Map/HistorialCredenciales';
import HistorialCredencialesTableColumns from '../../../Models/HistorialCredencialesTableColumns';
import DrawerInfo from '../../Common/Modules/Drawer/DrawerInfo';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import Text from '../../Common/Text/Text';
import Link from '../../Common/Atoms/Link';
import '../../../Statics/Scss/Pages/MisCredenciales.scss';

const CargaHistorial = ({ historial, errorHistorial, isLoadingHistorial }) => {
    const [selectedInfo, errorInfo, loadingInfo, getInfo] = useObtenerDetalleTipoDeEvento();
    const [item, setItem] = useState(null);

    const errorText = () => {
        return (
            <Text className="--arial" size="2xs">
                Hubo un problema con tu solicitud. Por favor,{' '}
                <Link target="_blank" href={'#'}>
                    volvé a intentar
                </Link>
                .
            </Text>
        );
    };

    if (isLoadingHistorial) {
        return (
            <div className="loading">
                <LoadingSpinner isLoading={!historial || isLoadingHistorial} spinnerSmall />
            </div>
        );
    }

    return (
        <div>
            <BasicTablePaginationWithDrawer
                className="cargar-historial"
                cols={HistorialCredencialesTableColumns}
                items={historial && MapHistorialCredencialesToTable(historial?.EventosHistoricosViewModel)}
                noResultsTitle={
                    historial?.EventosHistoricosViewModel.length < 1 && !errorHistorial
                        ? 'Historial sin movimientos'
                        : errorHistorial
                        ? 'Ocurrió un error'
                        : ''
                }
                noResultsDescription={
                    historial?.EventosHistoricosViewModel.length < 1 && !errorHistorial
                        ? 'No se encontraron movimientos'
                        : errorHistorial
                        ? errorText()
                        : ''
                }
                noResultsIcon={'search'}
                classNameDrawer={'mc-drawer'}
                tooltipText="Ver detalle"
                actionPositionSticky="right"
                getInfoDrawer={getInfo}
                itemSelected={(i) => setItem(i)}
            >
                <DrawerInfo item={item} itemSelected={selectedInfo} drawerError={errorInfo} drawerLoading={loadingInfo} />
            </BasicTablePaginationWithDrawer>
        </div>
    );
};

export default CargaHistorial;
