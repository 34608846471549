import React, { useState } from 'react';
import { useHistory } from 'react-router';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import stepsTarjeta from '../../../Config/StepsTarjeta';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import useObtenerCredenciales from '../../../Hooks/useObtenerCredenciales';
import useObtenerMotivosTarjeta from '../../../Hooks/useObtenerMotivosReclamoTarjeta';
import Motivos from '../../../Components/Features/Motivos';
import ReclamoSinCredencial from '../../../Components/Features/ReclamoTarjeta/ReclamoSinCredencial';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import CredencialesSelector from '../../../Components/Common/FormBlocks/CredencialesSelector';
import TitleWeb from '../../../Components/Common/Web/Title';
import CredencialesEnDistribucion from '../../../Components/Features/ReclamoTarjeta/CredencialesEnDistribucion';
import PathsConfig from '../../../Config/RoutePaths';
import useObtenerVerificacionCredenciales from './../../../Hooks/useObtenerVerificacionCredenciales ';
import { ToastBloqueoCredenciales } from './../../../Components/Common/Banners/ToastBloqueoCredenciales';
import { useStore } from '../../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';

const MotivoTarjeta = (props) => {
    const CredencialPreSelected = props?.location?.state?.CredencialPreSelected;
    const history = useHistory();
    const [credenciales, errorCredenciales, isLoadingCredenciales] = useObtenerCredenciales();
    const [motivosReclamo, errorMotivo, isLoadingMotivos] = useObtenerMotivosTarjeta();
    const [motivoSeleccionado, setMotivoSeleccionado] = useState(null);
    const [motivoValError, setMotivoValError] = useState(null);
    const [credencialesSeleccionadas, setCredencialesSeleccionadas] = useState(
        CredencialPreSelected ? [CredencialPreSelected.IdCredencialAsignada.toString()] : []
    );
    const [credValError, setCredValError] = useState(false);
    const [isBlockedCredenciales, errorVerificacionCredenciales, isLoadingVerificacionCredenciales] = useObtenerVerificacionCredenciales();
    const { HandleSwitchsStates } = useStore();

    const redirectPasoDos = () => {
        if (credencialesSeleccionadas.length == 0 || !motivoSeleccionado) {
            credencialesSeleccionadas.length == 0 && setCredValError(true);
            !motivoSeleccionado && setMotivoValError({ status: 'error', msg: 'Seleccioná un motivo de reclamo' });
        } else {
            history.push({
                pathname: PathsConfig.ReclamoTarjetaDomicilioEntrega,
                state: {
                    motivoSelected: motivoSeleccionado,
                    credenciales: credenciales.filter(({ IdCredencial }) => credencialesSeleccionadas.includes(IdCredencial.toString())),
                },
            });
        }
    };

    const handleMotivoSelected = (value) => {
        if (isNaN(value)) {
            setMotivoValError({ status: 'error', msg: 'Seleccioná un motivo de reclamo' });
            setMotivoSeleccionado(null);
        } else {
            let motivoObject = motivosReclamo.find((motivo) => motivo.Id == value);
            setMotivoValError(null);
            setMotivoSeleccionado(motivoObject);
        }
    };

    const handleCredencialesSelected = (creds) => {
        setCredencialesSeleccionadas(creds);
    };

    return (
        <>
            <Breadcrumb firstItem="Mis reclamos" href="#" text="Credencial Club" />
            <TitleWeb text="Credencial Club" />
            <div className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.ReclamosCredencial) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.ReclamosCredencial}  />
                ) : (
                    <LoadingSpinner isLoading={isLoadingCredenciales || isLoadingMotivos || isLoadingVerificacionCredenciales}>
                        <ErrorBoundary error={errorCredenciales || errorMotivo || errorVerificacionCredenciales}>
                            {errorCredenciales || errorMotivo || errorVerificacionCredenciales ? (
                                <ErrorGenerico />
                            ) : isBlockedCredenciales ? (
                                <ToastBloqueoCredenciales
                                    title={'Atención'}
                                    estado={'alert'}
                                    subtitle={'El reclamo de credenciales está temporalmente suspendida.'}
                                />
                            ) : credenciales?.length == 0 ? (
                                <ReclamoSinCredencial />
                            ) : (
                                <>
                                    <Wizard steps={stepsTarjeta} activeStep={1} />
                                    <CredencialesEnDistribucion credenciales={credenciales} />
                                    <fieldset>
                                        <legend className="--mb-32">Seleccioná la/s credencial/es y el motivo del reclamo</legend>
                                        {credenciales && (
                                            <CredencialesSelector
                                                title={'Elegí la/s credencial/es con inconveniente'}
                                                credencialesSelected={handleCredencialesSelected}
                                                credValError={credValError}
                                                credenciales={credenciales}
                                                preSelected={CredencialPreSelected ? CredencialPreSelected : null}
                                                setCredValError={setCredValError}
                                            />
                                        )}
                                        <FormRow>
                                            <FormCol>
                                                {motivosReclamo?.length > 0 && (
                                                    <Motivos
                                                        hasValidationError={motivoValError}
                                                        motivos={motivosReclamo}
                                                        setMotivoSelected={handleMotivoSelected}
                                                    />
                                                )}
                                            </FormCol>
                                        </FormRow>
                                        <FormRow mod="--btn-groups">
                                            <Button
                                                mod="state--mobile-w100"
                                                type="button"
                                                label="CONTINUAR RECLAMO"
                                                onClick={() => redirectPasoDos()}
                                            />
                                        </FormRow>
                                    </fieldset>
                                </>
                            )}
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </div>
        </>
    );
};

export default MotivoTarjeta;
