import React from 'react'
import { useHistory } from 'react-router-dom'
import PathsConfig from '../../../Config/RoutePaths';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import { Text } from '@ln/common-ui-text';
import { Button } from '@ln/experiencias-ui-button';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import LoadingSpinnerWithData from '../../../Helpers/LoadingSpinnerWithData';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import Divider from '../../../Components/Common/Atoms/Divider';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import { CarouselBeneficios } from '../../../Components/Features/MisSuscripciones/components/CarouselBeneficios';
import useObtenerEventosSuscripciones from '../../../Hooks/useObtenerEventosSuscripciones';
import { readCookie } from '../../../Helpers/Cookies';
import { esProductoPremiumConClub } from '../../../Models/ProductoPremiumHelper';

export const ConfirmacionActivarSuscripcion = (props) => {
  const history = useHistory();

  const stepProps = ['pin','crmId'];
  const {pin, crmId} = useRedirectWithout(
    props.location.state,
    stepProps,
    PathsConfig.MisSuscripciones
  );
  const [, , isLoadingObtenerEventosOnDemand] = useObtenerEventosSuscripciones(crmId);
  const pp = readCookie('ProductoPremiumId');
  const tieneClub = esProductoPremiumConClub(pp)
  const tipoDePlan = tieneClub ? 'lnClub' : 'ln'
  const handleVolver = () => {
    history.push({
      pathname: PathsConfig.MisSuscripciones
    })
  }

  return (
    <>
      <section>
        <Breadcrumb firstItem="Mis suscripciones" text="Activar suscripción" href="#" />
        <TitleWeb text="Activar suscripción" />
      </section>

      <section className="container-fluid-streched">
        <LoadingSpinnerWithData 
          isLoading={isLoadingObtenerEventosOnDemand || !pp}
          title='Estamos activando tu suscripción'
          description='Aguarda un momento...'
        >
          <ErrorBoundary error={false}> {/* TODO: ver si hace falta ErrorBoundry */}
            <div className='flex flex-column gap-32 mb-32 arial'>
              <div className='flex flex-column gap-8'>
                <Text as='h5' className='arial-bold text-20'>
                  ¡Bienvenido!
                </Text>
                <div className='text-16'>
                  <Text as='p' className='arial-bold'>
                    Tu suscripción digital fue activada exitosamente.
                  </Text>
                  <Text as='p'>
                    Navegá sin límites y disfrutá de una experiencia única y personalizada. A partir de ahora, accederás a contenido exclusivo para suscriptores.
                  </Text>
                </div>
              </div>
              <div className='flex flex-column gap-8'>
                <Text as="h5" className="arial-bold text-18">Estos son algunos de los beneficios que tenés con tu suscripción:</Text>
                <CarouselBeneficios plan={tipoDePlan} />
              </div>
              <Groupbutton>
                <Button title="IR A LA NACION" href={process.env.REACT_APP_HOME}>IR A LA NACION</Button>
                <Button title="VOLVER" variant='secondary' onClick={handleVolver}>VOLVER</Button>
              </Groupbutton>
            </div>
            <Divider />
            <ContactoDudasBanner />
          </ErrorBoundary>
        </LoadingSpinnerWithData>
      </section>
    </>
  )
}

export default ConfirmacionActivarSuscripcion;