import React, { useEffect, useState } from 'react';
import BasicTablePagination from './BasicTablePagination';
import Drawer from '../../Modules/Drawer/Drawer';

const BasicTablePaginationWithDrawer = ({
    cols,
    items,
    className,
    actionPositionSticky,
    tooltipText,
    tooltipPosition,
    getInfoDrawer,
    classNameDrawer,
    itemSelected,
    noResultsTitle,
    noResultsDescription,
    noResultsIcon,
    children,
}) => {
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        const body = document.querySelector('body');

        if (showDrawer) {
            body?.classList.add('drawer-active');
            return () => {
                body?.classList.remove('drawer-active');
            };
        }
    }, [showDrawer]);

    const handleItemSelected = (id) => {
        const elemento = items.find((i) => i.id.value == id);
        itemSelected(elemento);
        getInfoDrawer(elemento.id.value);
    };

    return (
        <div className={className}>
            <BasicTablePagination
                cols={cols}
                items={items}
                noResultsTitle={noResultsTitle}
                noResultsDescription={noResultsDescription}
                noResultsIcon={noResultsIcon}
                actionPositionSticky={actionPositionSticky}
                cellHeaderSticky={actionPositionSticky === 'right' ? 'last' : 'first'}
                tooltipText={tooltipText}
                tooltipPosition={tooltipPosition}
                align="center"
                itemsPerPage={3}
                itemSelected={handleItemSelected}
                onClickItemButton={setShowDrawer}
            />
            {showDrawer && (
                <Drawer
                    className={classNameDrawer}
                    direction="right"
                    isClosable
                    buttonBackgroundNone
                    isOpen={showDrawer}
                    closePosition="top-right"
                    handleClose={() => {
                        setShowDrawer(false);
                    }}
                >
                    {children}
                </Drawer>
            )}
        </div>
    );
};

export default BasicTablePaginationWithDrawer;
