import { useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";

const useCrearDomicilio = () => {
    let { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    const sendData = async (data) => {
        const pathUrl = `${REACT_APP_APIPAYWALL}Domicilio/CrearDomicilio`;
        setIsLoading(true);
        return ApiService.post(pathUrl, data)
            .then(response => {
                if (response.code == 0) {
                    Datadog.trackCustomEvent(CustomEventsDatadog.crearDomicilio, { tipoDePropiedad: data.tipoDePropiedad });
                    setData(response.response);
                    setIsLoading(false);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCrearDomicilio, { tipoDePropiedad: data.tipoDePropiedad });
                    setIsLoading(false);
                    setError(response);
                }
                
                return Promise.resolve(response);
            })
            .catch((error) => {
                Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorCrearDomicilio, { tipoDePropiedad: data.tipoDePropiedad });
                setIsLoading(false);
                setError(true);
                return Promise.reject(error);
            })
    };

    return [data, error, isLoading, sendData];

};


export default useCrearDomicilio;

