import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Wizard from '../../Components/Common/Modules/Wizard';
import steps from '../../Config/StepsMisCredenciales';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import Text from '../../Components/Common/Text/Text';
import TitleWeb from '../../Components/Common/Web/Title';
import Button from '../../Components/Common/Modules/Button';
import Modal from '../../Components/Common/Organism/Modal';
import '../../Statics/Scss/Pages/OtorgarCredencialPaso2.scss';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import useAsignarCredencial from '../../Hooks/useAsignarCredencial';
import useObtenerGrupos from '../../Hooks/useObtenerGrupos';
import Domicilios from '../../Components/Features/Domicilios';
import useObtenerDomicilioPrincipal from '../../Hooks/useObtenerDomicilioPrincipal';
import PathsConfig from '../../Config/RoutePaths';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import { formatearDomicilio } from '../../Helpers/DomicilioFormatter';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';

const SeleccionarDomicilio = (props) => {
    const history = useHistory();
    const stepProps = ['cliente'];
    const { cliente } = useRedirectWithout(props.location.state, stepProps, PathsConfig.MisCredenciales);

    const [errorForm, setErrorForm] = useState();
    const [domicilioSeleccionado, setDomicilioSeleccionado] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [esSuDomicilio, setEsSuDomicilio] = useState();
    const [domicilio, setDomicilio] = useState();
    const [errorSeleccion, setErrorSeleccion] = useState(false);
    const [dataAsignarCredencial, errorAsignarCredencial, isLoadingAsignarCredencial, asignarCredencial] = useAsignarCredencial();
    const [gruposObtenidos, credencialesOtorgadas, errorGrupos, isLoadingGrupos] = useObtenerGrupos();
    const [domicilioPrincipal, errorDomicilioPrincipal, isLoadingDomicilioPrincipal] = useObtenerDomicilioPrincipal();

    const [dataRelacionClub, errorRelacionClub, isLoadingRelacion] = useObtenerRelacionClub();

    const handleSubmit = () => {
        if (domicilio) {
            setOpenModal(true);
        } else if (!domicilioSeleccionado) {
            setErrorSeleccion(true);
        }
    };

    const setAddressData = (domicilio, nuevo) => {
        if (nuevo) {
            setDomicilioSeleccionado(formatearDomicilio(domicilio));
        } else {
            setDomicilioSeleccionado(domicilio);
        }
    };

    const confirmarOtorgarCredencial = async () => {
        let data = {
            IdGrupoDeCredencial: gruposObtenidos[0].Id,
            IdDisenio: gruposObtenidos[0].IdDisenio,
            IdPapeleria: gruposObtenidos[0].IdPapeleria,
            AddressId: domicilioSeleccionado.Id,
            CrmidCredencial: cliente.CrmId,
        };
        let tipoCredencial = dataRelacionClub?.response?.categoria;
        asignarCredencial(data, tipoCredencial)
            .then((resp) => {
                setOpenModal(false);
                history.push({
                    pathname: PathsConfig.MisCredencialesConfirmacionOtorgarSuscripcion,
                    state: {
                        respOtorgar: resp.Response,
                        cliente: cliente,
                        domicilio: domicilioSeleccionado,
                    },
                });
            })
            .catch(() => {
                history.push({
                    pathname: PathsConfig.MisCredencialesConfirmacionOtorgarSuscripcion,
                    state: {
                        respOtorgar: null,
                        cliente: null,
                        domicilio: null,
                    },
                });
            });
    };

    return (
        <>
            <section>
                <Breadcrumb firstItem="Club LA NACION" text="Mis credenciales" href="#" />
                <TitleWeb text="Otorgar credencial" />
            </section>

            <section className="container-streched">
                <LoadingSpinner isLoading={isLoadingGrupos || isLoadingAsignarCredencial || isLoadingDomicilioPrincipal || isLoadingRelacion}>
                    {openModal && (
                        <Modal closeModal={() => setOpenModal(false)}>
                            <Text tag="p" size="2xs" font="arial" className="link-text">
                                Confirmás otorgarle una credencial <strong>{cliente.TipoCredencial}</strong> a:
                                <div className="modal-text-list">
                                    <span>
                                        <strong>{`${cliente.Nombre} ${cliente.Apellido}`}</strong>
                                    </span>
                                    <span>DNI: {cliente.NroDocumento ? cliente.NroDocumento : cliente.NumeroDocumento}</span>
                                    <span>
                                        Dirección de entrega: {domicilioSeleccionado?.Calle} {domicilioSeleccionado?.Altura},{' '}
                                        {domicilioSeleccionado?.Localidad} - {domicilioSeleccionado?.Provincia}
                                    </span>
                                </div>
                            </Text>

                            <div className="otorgar-credencial-buttons-modal">
                                <Button label="Confirmar" size="small" onClick={() => confirmarOtorgarCredencial()} />
                                <Button label="Cancelar" mod="tertiary" size="small" onClick={() => setOpenModal(false)} />
                            </div>
                        </Modal>
                    )}

                    <Wizard steps={steps} activeStep={2} />

                    <Text tag="p" size="--s" font="arial" weight="bold" className="text-title">
                        Seleccioná o agrega un domicilio de entrega
                    </Text>

                    <Text tag="p" size="2xs" font="arial" className="link-text">
                        Para la credencial de{' '}
                        <span className="--font-highlight">
                            <strong>{`${cliente.Nombre} ${cliente.Apellido}`}</strong>
                        </span>
                        , DNI {cliente.NroDocumento ? cliente.NroDocumento : cliente.NumeroDocumento}.
                    </Text>

                    <Domicilios
                        setDomicilio={setDomicilio}
                        setErrorForm={setErrorForm}
                        errorForm={errorForm}
                        setEsSuDomicilio={setEsSuDomicilio}
                        idCredencial={cliente.CrmId}
                        setAddressData={setAddressData}
                    />

                    {domicilio || esSuDomicilio || esSuDomicilio === undefined ? (
                        <div className="otorgar-credencial-buttons">
                            <FormRow mod="--btn-groups --mb-32">
                                <Button mod="state--mobile-w100" label="Continuar" onClick={() => handleSubmit()} />
                                <Button mod="tertiary" label="Cancelar"
                                onClick={() => {
                                    history.push({
                                        pathname: PathsConfig.MisCredencialesBuscarCliente,
                                    });
                                }} />
                            </FormRow>
                        </div>
                    ) : (
                        <></>
                    )}
                </LoadingSpinner>
            </section>
        </>
    );
};

export default SeleccionarDomicilio;
