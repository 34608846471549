import React from 'react';

import '../../../../Statics/Scss/Components/InputGroup.scss'

const InputGroup = ({children, className}) => {

    return ( 
        <div className={ `com-container ${className && className}`}>
            {children}
        </div>
     );
}
 
export default InputGroup;