const mapAndSortCountries = countries => {
    const countriesSorted = countries
        .map(c => ({ ...c, value: c.Barrio, name: c.Barrio }))
        .sort(function (a, b) {
            let textA = a.Barrio;
            let textB = b.Barrio;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    return countriesSorted;
};

export default mapAndSortCountries;
