import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Atoms/Icon';
import '../../../Statics/Scss/Modules/Badge.scss';

const propTypes = {
    /**
     * Clases adicinonales.
     */
    className: PropTypes.string,
    /**
     * Tipo de badge.
     */
    type: PropTypes.oneOf([
        'classic',
        'premium',
        'black',
        'tag',
        'danger',
        'warning',
        'success',
        'primary',
    ]),
    /**
     * Tamaño del badge.
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /**
     * Icono asociado.
     */
    icon: PropTypes.string,
    /**
     * Flag, ¿se posiciona el icono a la izquierda?
     */
    iconLeft: PropTypes.bool,
};

const Badge = ({ children, className, type, size, icon, iconLeft, ...r }) => {
    const classes = classNames('badge', className, {
        [`--${type}`]: type,
        [`--${size}`]: size,
        '--icon-left': iconLeft,
    });
    const icon_ = icon && <Icon name={icon} size="s" />;

    return (
        <span className={classes} {...r}>
            {iconLeft && icon_}
            {children}
            {!iconLeft && icon_}
        </span>
    );
};

Badge.propTypes = propTypes;

export default Badge;
