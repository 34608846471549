import React from "react";

const ListIcon = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 0.333984H24V3.00065H10.6667V0.333984ZM10.6667 5.66732H18.6667V8.33398H10.6667V5.66732ZM10.6667 13.6673H24V16.334H10.6667V13.6673ZM10.6667 19.0007H18.6667V21.6673H10.6667V19.0007ZM0 0.333984H8V8.33398H0V0.333984ZM2.66667 3.00065V5.66732H5.33333V3.00065H2.66667ZM0 13.6673H8V21.6673H0V13.6673ZM2.66667 16.334V19.0007H5.33333V16.334H2.66667Z"
        fill="#0250C9"
      />
    </svg>
  );
};

export default ListIcon;
