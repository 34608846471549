import Datadog from "../../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../../Config/Datadog";
import Auth0 from '../../Services/Auth0Service';
import Cookies from 'js-cookie';
import Auth0Config from '../../Config/Auth0';

const useLinkFacebookAccount = (setIdentitiesProviderData, showAlertToast, setLoadingFacebook) => {

    const linkFacebokAccountProcess = (accessToken) => {
        const data = {
            primary_acccount_access_token: Cookies.get('access-token'),
            secondary_acccount_access_token: accessToken,
            secondary_account_provider: Auth0Config.FacebookConnection,
        };

        setLoadingFacebook(true);
        Auth0.LinkAccount(data)
            .then((apiResponse) => {
                if (apiResponse && apiResponse.code) {
                    if (apiResponse.code === '0000' || apiResponse.code === '0020') {
                        Datadog.trackCustomEvent(CustomEventsDatadog.vincularRedSocial, { redSocial: 'Facebook' });
                        let profiles = {};
                        apiResponse.response.forEach((identity) => {
                            profiles[identity.profile_name] = identity
                        });
                        setIdentitiesProviderData();
                        setLoadingFacebook(false);
                        showAlertToast('¡Tu vinculación con Facebook se realizó con éxito!', 'success');
                    } else {
                        Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorVincularRedSocial, { redSocial: 'Facebook' });
                        const errorMessage = apiResponse.code === '0003' || apiResponse.code === '0004' || apiResponse.code === '0007' ? 'El perfil de Facebook seleccionado ya se encuentra vinculado a otra cuenta' :
                            apiResponse.message;
                        showAlertToast(errorMessage, 'alert');
                        setLoadingFacebook(false);
                    }
                }
            })
            .catch((reason) => {
                console.log(`rejected by ${reason}`);
                setLoadingFacebook(false);
            });
    };

    return [linkFacebokAccountProcess];
};
export default useLinkFacebookAccount;
