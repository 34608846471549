import React from 'react';
import PropTypes from 'prop-types';

import '../../../Statics/Scss/Modules/RangePicker.scss';

const propTypes = {
	/**
	 * DatePicker que contiene y settea la
	 * fecha inicial.
	 */
	initialDatePicker: PropTypes.element,
	/**
	 * DatePicker que contiene y settea la
	 * fecha final.
	 */
	finalDatePicker: PropTypes.element,
	/**
	 * Mensaje de error.
	 */
	errorMessage: PropTypes.string,
	/**
	 * Flag, ¿es válido el rango seleccionado?
	 */
	isInvalid: PropTypes.bool,
};

const RangePicker = ({
	initialDatePicker,
	finalDatePicker,
	errorMessage,
	isInvalid,
	...r
}) => {
	return (
		<div className="mod-range-picker" {...r}>
			{initialDatePicker}
			{finalDatePicker}
			{errorMessage && isInvalid && (
				<p className="error --fivexs --arial --font-regular">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

RangePicker.propTypes = propTypes;

export default RangePicker;
