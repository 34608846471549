import React from 'react';
import PropTypes from 'prop-types';
import { validacionDePrecio } from '../../../Helpers/Precio';
import { Cardupselling } from '@ln/experiencias-ui-cardupselling';
import { ImagenCredencial } from './ImagenCredencial';

const propTypes = {
    cantCredenciales: PropTypes.number.isRequired,
    titulo: PropTypes.string.isRequired,
    precio: PropTypes.number.isRequired,
    cantidadDeMeses: PropTypes.number,
    precioFinal: PropTypes.number,
};

function ComboPlan(props) {
    const { titulo, precio, cantidadDeMeses = null, precioFinal = null, variant, tipoPlan, selectPlan, cantCredenciales } = props;

    const getButtonText = (variant) => {
        if (variant === 'actual') return '¡YA ESTÁS EN ESTE PLAN!'
        if (variant === 'selected') return `SELECCIONADO ${titulo.toUpperCase()}`
        return `SELECCIONAR ${titulo.toUpperCase()}`
    }
    const buttonText = getButtonText(variant)

    const validacionDeMeses = (cantidadDeMeses, precioFinal) => {
        if (cantidadDeMeses === null) return
        return {
            details: `Durante ${cantidadDeMeses} meses`,
            description: `luego ${precioFinal} por mes`,
        }
    };

    const { details, description } = validacionDeMeses(cantidadDeMeses, precioFinal) || {}

    return (
        <Cardupselling className='col-span-8 col-span-6_md as-end' variant={variant} plan={tipoPlan}>
            <Cardupselling.Chip>{variant === 'actual' ? 'Plan actual' : 'Nuevo plan'}</Cardupselling.Chip>
            <Cardupselling.Body>
                <Cardupselling.Header
                    credentialIcon={<ImagenCredencial cantCredenciales={cantCredenciales} />}
                    credentialIconProps={{ height: 56 }}
                    planName={titulo}
                    price={validacionDePrecio(precio)}
                    details={details}
                    description={description}
                />
                <Cardupselling.Button title={buttonText} onClick={selectPlan}>{buttonText}</Cardupselling.Button>
            </Cardupselling.Body>
        </Cardupselling>
    );
}

ComboPlan.propTypes = propTypes;

export default ComboPlan;
