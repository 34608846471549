import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';
import usePagination from './usePagination';
import '../../../../Statics/Scss/Modules/Pagination.scss';
import Paragraph from '../../Atoms/Paragraph';

const propTypes = {
	/**
	 * Cantidad de items por páginas.
	 */
	itemsPerPage: PropTypes.number,
	/**
	 * Cantidad total de items.
	 */
	totalItems: PropTypes.number,
	/**
	 * Página activa.
	 */
	activePage: PropTypes.number,
	/**
	 * Handler para el cambio de página.
	 */
	onPageClick: PropTypes.func,
	/**
	 * Elemento adicional.
	 */
	otherElement: PropTypes.element,
	/**
	 * Flag, ¿se muestran los números?
	 */
	compressed: PropTypes.bool,
	/**
	 * Números de elementos máximo
	 * alrededor del activo.
	 */
	siblingCount: PropTypes.number,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const defaultProps = {
	activePage: 0,
	itemsPerPage: 8,
	siblingCount: 1,
};

const Pagination = (props) => {
	const {
		itemsPerPage,
		totalItems,
		activePage,
		onPageClick,
		otherElement,
		compressed,
		siblingCount,
		className,
		...r
	} = props;
	const classes = classNames('mod-pagination', className);

	let pageNumbers = 0;
	for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) pageNumbers++;

	const { items } = usePagination({ ...props, pageNumbers });

	return (
		<nav className={classes} tabIndex="0" aria-label="pagination navigation" {...r}>
			{otherElement && { otherElement }}

			<ol className="mod-pagination__controls">
				{items.map((item, i) => (
					<li key={i} className="mod-pagination__controls__page">
						{item.isText ? <Paragraph>{item.content}</Paragraph> : <Button {...item} />}
					</li>
				))}
			</ol>
		</nav>
	);
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
