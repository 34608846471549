import React from 'react';
import PropTypes from 'prop-types';
import "../../../Statics/Scss/Components/List.scss";


const List = ({children}) => {
    return(
        <ul className="list">
            { children.map( (item,index) => 
                <li className="--arial --font-regular --twoxs" key={index}>{item}</li> ) }
        </ul>
    );
}


List.propTypes = {
    children: PropTypes.node,
}


export default List;