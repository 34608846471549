import ApiService from "./ApiService";
import Auth0Config from '../../src/Config/Auth0';

const { REACT_APP_APILOGIN } = process.env;

const Auth0Service =
{
    GetUserInfo: function () {
        return ApiService.get(`${REACT_APP_APILOGIN}Auth0/GetUserInfo`);
    },

    LinkAccount: function (data) {
        return ApiService.post(`${REACT_APP_APILOGIN}Auth0/LinkAccount`, data);
    },

    UnlinkAccount: function (data) {
        return ApiService.post(`${REACT_APP_APILOGIN}Auth0/UnlinkAccount`, data);
    },

    LinkAccountApple: function (data) {
        return ApiService.post(`${REACT_APP_APILOGIN}Auth0/LinkAccountApple`, data);
    },

    UnlinkAccountApple: function () {
        return ApiService.post(`${REACT_APP_APILOGIN}Auth0/UnlinkAccountApple`);
    },

    AuthorizeAuth0: function (connection) {
        const endpoint = `${process.env.REACT_APP_AUTH0_DOMAIN}authorize?
        response_type=${Auth0Config.ResponseType}
        &client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}
        &scope=${Auth0Config.Scopes}
        &connection=${connection}
        &redirect_uri=${encodeURIComponent(process.env.REACT_APP_AUTH0_REDIRECT_URI)}
        &audience=${process.env.REACT_APP_AUTH0_AUDIENCE}
        &event_name=${Auth0Config.LinkEventName}
        &state=${connection}
        &max_age=${Auth0Config.MaxAge}
        &prompt=${Auth0Config.PromptLogin}`.replace(/\n\s*/g, '');
        //Replace de los espacios en blanco por el formato del string.
        window.location.href = endpoint;
    }


}
export default Auth0Service;