import React from 'react';
import { telefono, horario } from '../../../Config/DatosDeContacto';

const NoRecordasTarjeta = () => {
    return (
        <>
            <p className="--twoxs --light-neutral-800">
                {' '}
                <strong>¿No recordás el número de la credencial Club LA NACION?</strong> Llamanos al <strong>{telefono}</strong> de {horario}
            </p>
            <br />
            <br />
            <br />
        </>
    );
};

export default NoRecordasTarjeta;
