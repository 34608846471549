import { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";
const useObtenerDatosUsuario = () => {
  let { REACT_APP_APILOGIN } = process.env;
  const [isLoadingUser, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [errorUser, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      ApiService.post(`${REACT_APP_APILOGIN}Usuario/Obtener`)
        .then((data) => {
          if (data && data.code) {
            if (data.code === "0000" || data.code === "4010") {
              setUser(data.response.usuario);
              setIsLoading(false);
            } else {
              setError(true);
              setIsLoading(false);
            }
          }
        })
        .catch(() => {
          setError(true);
          setIsLoading(false);
        });
    };
      fetchData();
  }, []);
  return [user, errorUser, isLoadingUser];
};
export default useObtenerDatosUsuario;
