import React     from "react";
import PropTypes from 'prop-types';
import "../../../Statics/Scss/Components/Link.scss";


const Link = ({ children, href, text, target, title, mod, size, onClick, tabindex, ariaLabel }) => {
  const className = `com-link${size ? ` ${size}` : ""}${
    mod ? ` ${mod}` : ""
  }`;
  const content = children || text;

  return (
    <a
      aria-label={ariaLabel}
      tabIndex={tabindex}
      className={className}
      href={href}
      target={target}
      rel={target === "_blank" ? target : "nonoopener noreferrer" }
      title={title || text}
      onClick= { onClick }
    >{content}</a>
  );
};


Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  text: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  mod: PropTypes.string,
  size: PropTypes.string,
  item: PropTypes.element,
}


export default Link;