import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFontFamily, getFontSize, getFontWeight } from '../../../Helpers/getFontData';

import './style.scss';

const propTypes = {
	/**
	 *href link.
	 */
	href: PropTypes.string,
	/**
	 * texto o elelentos.
	 */
	children: PropTypes.element,
	/**
	 * texto dentro del link.
	 */
	text: PropTypes.string,
	/**
	 * target.
	 */
	target: PropTypes.string,
	/**
	 * rel.
	 */
	rel: PropTypes.string,
	/**
	 * title del elemento 
	 */
	title: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tamaño del font.
	 */
	size: PropTypes.string,
};

const Link = ({
	href,
	children,
	text,
	rel,
	target,
	title,
	onClick,
	className,
	font,
	size,
	weight,
	bold,
}) => {
	const _font = getFontFamily(font);
	const _size = getFontSize(size);
	const _weight = getFontWeight(weight);

	const classes = classNames('com-link', className, {
		[_font]: font,
		[_size]: size,
		[_weight]: weight,
		'--font-bold': bold,
	});
	const content = children || text;

	return (
		<a
			className={classes}
			href={href}
			target={target}
			rel={rel || (target === '_blank' ? 'nofollow' : undefined)}
			title={title || text}
			onClick={onClick}>
			{content}
		</a>
	);
};

Link.propTypes = propTypes;

export default Link;
