import * as Yup from 'yup';

const ValidateFourDigitsInput = () => {
    return Yup.string()
        .required("Éste campo es obligatorio")
        .matches(/[0-9]$/, "Debe contener sólo números")
        .matches(/\b\d{4}\b/, "Ingresá un número de 4 dígitos")
}

const InfoContactoSchema = Yup.object().shape({
    celular: Yup.string().matches(/^[0-9]{6,}$/, "Ingresá un teléfono celular.")
        .min(8, "Ingresá al menos ${min} números")
        .max(20, "Ingresá hasta ${max} números")
        .required('El celular es requerido'),
    particular: Yup.string().matches(/^[0-9]{6,}$/, "Ingresá un teléfono particular.")
        .min(8, "Ingresá al menos ${min} números")
        .max(20, "Ingresá hasta ${max} números"),
    nroLinea: ValidateFourDigitsInput(),
    nroDistribuidor: ValidateFourDigitsInput(),
    nroVendedor: ValidateFourDigitsInput(),
    observacionContacto: Yup.string()
        .max(255, "Ingresá hasta ${max} caracteres").required("La observación es obligatoria."),
});


export default InfoContactoSchema;