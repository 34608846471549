import React from 'react';
import classNames from 'classnames';
import './Style.scss';


const SuperCardBox = ({ children, bgState }) => {

    let classes = classNames(
        'com-SuperCardBox',
        bgState ? `state--bg-${bgState}` : false,
    );

    return (
        <div className={classes}>
            {children}
        </div>
    );
}

export { SuperCardBox };