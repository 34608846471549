import React from 'react';
import { Drawer, useDrawer } from '@ln/common-ui-drawer'
import { Text } from '@ln/common-ui-text'
import { Button } from '@ln/experiencias-ui-button'
import { DRAWER } from '../../../Models/Drawers';

export const UpsellingDrawer = ({ selectedPlan, onClose, children, ConfirmarPlan, secondaryButtonText }) => {
  const drawerId = DRAWER.UPSELLING;
  const { hideDrawer } = useDrawer({ id: drawerId })

  const handleClose = () => {
    hideDrawer()
    onClose?.();
  }
  return (
    <Drawer
      id={drawerId}
      position='bottom'
      maxHeight='content'
      className="bg-neutral-light-1 pt-16 pb-16 pb-24_md pb-32_xl shadow-up-md transition-regular"
      overlayClasses="z-10"
      onClose={onClose}
    >
      <div className='container flex flex-column gap-16'>
        <Drawer.Header className='flex jc-between gap-16' showCloseButton>
          <Text className='prumo prumo-black text-24 text-28_md text-32_xl'>Estás eligiendo el plan {selectedPlan?.NombreUpsell}</Text>
        </Drawer.Header>

        <hr className='w-100' />

        <Drawer.Body className="flex flex-column gap-32">
          {children}
        </Drawer.Body>

        <hr className='w-100' />

        <Drawer.Footer className='flex flex-column flex-row_xl gap-8'>
          <Button fullWidth onClick={ConfirmarPlan} title='CONFIRMAR PLAN'>CONFIRMAR PLAN</Button>
          <Button fullWidth onClick={handleClose} title={secondaryButtonText} variant='secondary'>{secondaryButtonText}</Button>
        </Drawer.Footer>
      </div>
    </Drawer>
  )
}
