
export const planesNoAction= [
    {
        "id": 3281
    },
    {
        "id": 3506
    },
    {
        "id": 3247
    },
    {
        "id": 3244
    },
    {
        "id": 3260
    },
    {
        "id": 3049
    },
    {
        "id": 3279
    },
    {
        "id": 3256
    },
    {
        "id": 3271
    },
    {
        "id": 3250
    },
    {
        "id": 3254
    },
    {
        "id": 3272
    },
    {
        "id": 3268
    },
    {
        "id": 3258
    },
    {
        "id": 3259
    },
    {
        "id": 3267
    },
    {
        "id": 3257
    },
    {
        "id": 3270
    },
    {
        "id": 3255
    },
    {
        "id": 3266
    }
];

export const productos= [
    {
        "Id": 2,
        "Texto": "Accedé a notas ilimitadas todos los meses en LA NACION"
    },
    {
        "Id": 3,
        "Texto": "Edición impresa en PDF"
    }
]
