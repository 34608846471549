import React, { useState } from 'react'
import MiniCards from '../../../Common/Atoms/MiniCards'
import { Alert } from '../../../Common/Modules/Alert';
import { Accordion } from '../../../Common/Modules/Accordion';
import { CardBeneficiosItem } from '../../../Common/Modules/CardBeneficios/CardBeneficiosItem'
import BasicTablePagination from '../../../Common/Modules/Table/BasicTablePagination';
import mapNotasToTable from '../../../../Map/NotasMap';
import mapBeneficiosToTable from '../../../../Map/BeneficiosMap';
import mapNewslettersToTable from '../../../../Map/NewslettersMap';
import OrderObjectByDate from '../../../../Helpers/OrderObjectByDate';
import BeneficiosTableColumns from '../../../../Models/BeneficiosTableColumns';
import BeneficiosNotasTableColumns from '../../../../Models/BeneficiosNotasTableColumns';
import BeneficiosNewslettersTableColumns from '../../../../Models/DTO/BeneficiosNewslettersTableColumns';

export const SeccionBeneficios = ({ Nombre, Credencial, DetallesBeneficio, NotasLeidas, Newsletters, NotasMasLeidas, visible = false, tipoDeSuscripcion, closedOnLoad = false }) => {
  const [displayTableConsumos, setDisplayTableConsumos] = useState(!closedOnLoad && tipoDeSuscripcion == 'lnClub');
  const toggleDisplayTableConsumos = () => setDisplayTableConsumos(prev => !prev);

  const [displayTableNotas, setDisplayTableNotas] = useState(!closedOnLoad);
  const toggleDisplayTableNotas = () => setDisplayTableNotas(prev => !prev);

  const [displayTableNewsletters, setDisplayTableNewsletters] = useState(false);
  const toggleDisplayTableNewsletters = () => setDisplayTableNewsletters(prev => !prev);

  const {
    TotalBeneficios,
    Beneficios = [],
  } = DetallesBeneficio || {}

  const beneficiosMapeados = mapBeneficiosToTable(OrderObjectByDate(Beneficios, 'Fecha', true))
  const notasMasLeidasMapeadas = mapNotasToTable(NotasMasLeidas, true)
  const newslettersMapeadas = mapNewslettersToTable(Newsletters)

  const mostrarDetalle = TotalBeneficios || NotasMasLeidas?.length > 0 || Newsletters?.length > 0

  return (
    <Accordion
      visible={visible}
      header={
        Nombre && Credencial && (
          <CardBeneficiosItem
            title={Nombre}
            icon={<MiniCards cardType={Credencial} />}
          />
        )
      }
    >
      <div className='flex flex-column gap-16'>
        {
          tipoDeSuscripcion == 'lnClub' && beneficiosMapeados.length > 0 && (
            <div className='flex flex-column gap-16'>
            <CardBeneficiosItem
              variant='club'
              title='Tus consumos de beneficios'
              quantity={TotalBeneficios}
              button={{
                text: `${displayTableConsumos ? 'Ocultar' : 'Ver'} detalle`,
                onClick: toggleDisplayTableConsumos
              }}
            />
            {displayTableConsumos && (
              <BasicTablePagination
                isFullWidth
                noMargin
                align="center"
                itemsPerPage={5}
                cols={BeneficiosTableColumns}
                items={beneficiosMapeados}
              />
            )}
            </div>
          )
        }
        {!mostrarDetalle ? (
          <Alert hideIcon variant='info' message='Sin consumos en los útlimos meses' />
        ) : (
          <div className='flex flex-column gap-16'>
            {
              notasMasLeidasMapeadas.length > 0 && (
              <>
                <CardBeneficiosItem
                  title='Tus notas leídas'
                  quantity={NotasLeidas}
                  button={{
                    text: `${displayTableNotas ? 'Ocultar' : 'Ver'} detalle`,
                    onClick: toggleDisplayTableNotas
                  }}
                />
                {displayTableNotas && (
                  <BasicTablePagination
                    isFullWidth
                    noMargin
                    align="center"
                    itemsPerPage={5}
                    cols={BeneficiosNotasTableColumns}
                    items={notasMasLeidasMapeadas}
                  />
                )}
              </>
            )}
            
            {
              tipoDeSuscripcion == 'ln' && newslettersMapeadas.length > 0 && (
                <>
                  <CardBeneficiosItem
                    title='Tus newsletters'
                    quantity={Newsletters?.length}
                    button={{
                      text: `${displayTableNewsletters ? 'Ocultar' : 'Ver'} detalle`,
                      onClick: toggleDisplayTableNewsletters
                    }}
                  />

                  {displayTableNewsletters && (
                    <BasicTablePagination
                      isFullWidth
                      noMargin
                      align="center"
                      itemsPerPage={5}
                      cols={BeneficiosNewslettersTableColumns}
                      items={newslettersMapeadas}
                    />
                  )}
              </>
            )}
          </div>
        )}
      </div>
    </Accordion>
  )
}
