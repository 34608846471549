import PropTypes from 'prop-types';

import classNames from 'classnames';
import './callback-article.scss';
import Title from '../../../Atoms/Title';
import Text from '../../../Text/Text';
import React from 'react'
import { Image } from '../index'

const propTypes = {
	/**
	 * Título asociado.
	 */
	title: PropTypes.node,
	/**
	 * URL asociada a la imagen.
	 */
	imageUrl: PropTypes.string,
	/**
	 * Descripción asociada.
	 */
	description: PropTypes.node,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Font Family.
	 */
	font: PropTypes.string,
};

const CallbackArticle = ({
	title,
	text,
	imageWithoutPadding,
	imageUrl,
	description,
	className,
	bg,
	font,
	...r
}) => {
	const classes = classNames('mc-callback-article',
		className,
		{
			'--bg': bg,
			'--image-without-padding': imageWithoutPadding,
		},
		font ? `--${font}` : "--arial"
	);

	return (
		<div className={classes} {...r}>
			<div className="description">
				{text && <Text size="2xs">{text}</Text>}
				{title && (
					<Title size="s" className="--font-bold">
						{title}
					</Title>
				)}
				{description}
			</div>

			{imageUrl && (
				<div className="img">
					<Image src={imageUrl} alt={title} />
				</div>
			)}
		</div>
	);
};

CallbackArticle.propTypes = propTypes;

export default CallbackArticle;
