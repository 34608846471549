import React from 'react'
import { Form, Formik } from "formik";
import Paragraph from "../../../Common/Atoms/Paragraph";
import FormRow from "../../../Common/Atoms/Form/FormRow";
import Label from "../../../Common/Atoms/Form/Label";
import InputBox from "../../../Common/Atoms/Form/InputBox";
import FormCol from "../../../Common/Atoms/Form/FormCol";
import InputFormik from "../../../Common/Formik/Input";
import RadioFormik from "../../../Common/Formik/Radio";
import TextAreaFormik from '../../../Common/Formik/TextArea';
import InfoContactoSchema from "../../../../Validations/InfoContactoSchema";
import InputGroup from "../../../Common/Atoms/Form/InputGroup";
import Divider from "../../../Common/Atoms/Divider";
import "../../../../Statics/Scss/Components/MiddleLineDivider.scss";
import parseToBool from '../../../../Helpers/ParseToBool';


const MismoDomicilioDiario = (config) => {

    const { setInfoContacto, setStatusErrorCanillita, optionCheckedTelCanillita, setOptionCheckedTelCanillita, 
        optionCheckedCambiarCanillita, setOptionCheckedCambiarCanillita, 
        formRef} = config;

    const handleChangeTel = (event) => {
        setOptionCheckedTelCanillita(parseToBool(event.target.value));
    };

    const handleChangeCambiar = (event) => {
        setOptionCheckedCambiarCanillita(parseToBool(event.target.value));
    };

    const updateValues = ({values, initialValues, errors}) => {
        let errorTelCanillita = optionCheckedTelCanillita && (values.celular === initialValues.celular || errors.celular || errors.particular);
    
        let errorCambiarCanillita = optionCheckedCambiarCanillita && (values.nroLinea === initialValues.nroLinea || errors.nroLinea
            || values.nroDistribuidor === initialValues.nroDistribuidor || errors.nroDistribuidor
            || values.nroVendedor === initialValues.nroVendedor || errors.nroVendedor
            || values.observacionContacto === initialValues.observacionContacto || errors.observacionContacto);

        let statusError = errorTelCanillita || errorCambiarCanillita;
        setStatusErrorCanillita(statusError);
        if (!statusError) setInfoContacto(values);
    };

    return (
        <div>
            <Formik innerRef={formRef}
          validationSchema={InfoContactoSchema}
          enableReinitialize
          initialValues={{
            celular: "",
            particular: "",
            nroLinea: "",
            nroDistribuidor: "",
            nroVendedor: "",
            observacionContacto: "",
            telCanillita: false,
            cambiarCanillita: false,
          }}
        >
          {(props) => (
            <Form noValidate>
              {updateValues(props)}

              <fieldset>
                <FormRow>
                  <Label text="¿Querés que le demos tu telefono al canillita para coordinar la entrega?" />
                  <InputGroup className="state--no-wrap">
                    <FormCol>
                      <InputBox
                        input={
                          <RadioFormik
                            name="telCanillita"
                            value="true"
                            checked={optionCheckedTelCanillita}
                            onChange={handleChangeTel}
                          >
                            Si
                          </RadioFormik>
                        }
                      />
                    </FormCol>
                    <FormCol>
                      <InputBox
                        input={
                          <RadioFormik
                            name="telCanillita"
                            value="false"
                            checked={!optionCheckedTelCanillita}
                            onChange={handleChangeTel}
                          >
                            No
                          </RadioFormik>
                        }
                      />
                    </FormCol>
                  </InputGroup>
                </FormRow>

                {optionCheckedTelCanillita && (
                  <>
                    <Paragraph
                      size="--twoxs"
                      mod="--arial --light-neutral-800 --mb-32"
                    >
                      Verifica tus números de contacto
                    </Paragraph>

                    <FormRow>
                      <InputGroup>
                        <FormCol>
                          <Label text={"Teléfono celular"} />
                          <InputFormik type="number" name="celular" />
                        </FormCol>
                        <FormCol>
                          <Label text={"Teléfono particular"} />
                          <InputFormik
                            type="number"
                            name="particular"
                            description="Opcional"
                          />
                        </FormCol>
                      </InputGroup>
                    </FormRow>

                    <Divider />
                  </>
                )}

                <FormRow>
                  <Label
                    text="¿Querés sugerir otro canillita para que realice la entrega?"
                    size="--fourxs"
                  />
                  <InputGroup className="state--no-wrap">
                    <FormCol>
                      <InputBox
                        input={
                          <RadioFormik
                            name="cambiarCanillita"
                            value="true"
                            onChange={handleChangeCambiar}
                            checked={optionCheckedCambiarCanillita}
                          >
                            Si
                          </RadioFormik>
                        }
                      />
                    </FormCol>
                    <FormCol>
                      <InputBox
                        input={
                          <RadioFormik
                            name="cambiarCanillita"
                            value="false"
                            checked={!optionCheckedCambiarCanillita}
                            onChange={handleChangeCambiar}
                          >
                            No
                          </RadioFormik>
                        }
                      />
                    </FormCol>
                  </InputGroup>
                </FormRow>

                {optionCheckedCambiarCanillita && (
                  <>
                    <Paragraph
                      size="--twoxs"
                      mod="--arial --light-neutral-800 --mb-32"
                    >
                      Ingresá los siguientes datos que podés solicitar al
                      canillita
                    </Paragraph>

                    <FormRow>
                      <InputGroup>
                        <FormCol>
                          <Label text="N° de línea" />
                          <InputFormik name="nroLinea" />
                        </FormCol>
                        <FormCol>
                          <Label text="N° de distribuidor" />
                          <InputFormik name="nroDistribuidor" />
                        </FormCol>
                        <FormCol>
                          <Label text="N° de vendedor" />
                          <InputFormik name="nroVendedor" />
                        </FormCol>
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <FormCol>
                        <Label text="Observación" />
                        <TextAreaFormik
                          name="observacionContacto"
                          placeholder="Ingresá cualquier información adicional"
                        />
                      </FormCol>
                    </FormRow>
                  </>
                )}
              </fieldset>                    
            </Form>
          )}
        </Formik>
        </div>
    )
}

export default MismoDomicilioDiario;
