import React from 'react';
import { useHistory } from 'react-router';

import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Toast from '../../../Components/Common/Modules/Toast';
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle';
import Button from '../../../Components/Common/Modules/Button';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import ParagraphList from '../../../Components/Common/Modules/ParagraphList';
import MotivoReclamo from '../../../Models/MotivoReclamo';
import Wizard from '../../../Components/Common/Modules/Wizard';
import steps from '../../../Config/Steps';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import Divider from '../../../Components/Common/Atoms/Divider';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';

const Confirmacion = (props) => {
    const history = useHistory();
    const reclamoResult = props.location.state;
    const motivoReclamo = MotivoReclamo.find((item) => item.id == reclamoResult.idMotivoDeReclamo).value;

    return (
        <div>
            <div className="lay-strechted">
                <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                <TitleWeb text="Nuevo reclamo de entrega" />
            </div>

            <div className="container-streched">
                <Wizard steps={steps} activeStep={4} />

                <Toast status="success" title="¡Reclamo generado con éxito!">
                    {`Se ha generado un reclamo por el siguiente motivo: ${motivoReclamo || 'MOTIVO'}`}
                </Toast>

                <CallbackArticle
                    description={
                        <Paragraph size="--s" mod="--font-bold --arial">
                            El número de gestión correspondiente es: <span className="--font-highlight">{reclamoResult.numeroUnicoDeGestion}</span>
                        </Paragraph>
                    }
                />

                <CallbackArticle
                    description={
                        <ParagraphList>
                            {reclamoResult.valorDeLaNotaDeCredito > 0 && (
                                <>
                                    <Paragraph size="--twoxs" mod="--arial --font-regular">
                                        Se realizó una nota de crédito por: <strong>${reclamoResult.valorDeLaNotaDeCredito}</strong>
                                    </Paragraph>
                                    <Paragraph size="--twoxs" mod="--arial --font-regular">
                                        Estarás viendo la devolución de ese importe en los próximos 30 a 60 días en tu tarjeta de crédito/débito.
                                    </Paragraph>
                                </>
                            )}
                            <Paragraph size="--twoxs" mod="--arial --font-regular">
                                Fecha estimada de resolución: <strong>{'10 días corridos'}</strong>
                            </Paragraph>
                        </ParagraphList>
                    }
                />
                <FormRow mod="--btn-groups --mb-32">
                    <Button
                        onClick={() => history.push({ pathname: PathsConfig.ReclamoEntrega, state: null })}
                        href={'#'}
                        label="Realizar otro reclamo"
                        mod="state--mobile-w100"
                    />
                </FormRow>
                <Divider />
                <ContactoDudasBanner />
            </div>
        </div>
    );
};

export default Confirmacion;
