import React from 'react';
import PropTypes from 'prop-types';
import CallBackArticle from '../../Components/Common/Modules/CallbackArticle';
import Button from '../../Components/Common/Modules/Button';
import Paragraph from '../../Components/Common/Atoms/Paragraph';
import FormRow from '../Common/Atoms/Form/FormRow';

const propTypes = {
    nuevoDomicilio: PropTypes.object.isRequired,
    confirmarCambio: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    idCredencial: PropTypes.string, // Se usa para la confirmación exprese si se trata de una credencial o un producto print
};

const ConfirmarCambioDomicilio = (props) => {
    const { nuevoDomicilio, confirmarCambio, closeModal, nombreSuscripcion } = props;

    return (
        <>
            <CallBackArticle
                description={
                    <Paragraph>
                        <>
                            Su nuevo domicilio de entrega del producto <strong>{nombreSuscripcion}</strong> será{' '}
                        </>
                        <strong>
                            {nuevoDomicilio.Calle} {nuevoDomicilio.Altura} {nuevoDomicilio.Localidad}
                            {' - '}
                            {nuevoDomicilio.Provincia} {nuevoDomicilio.CodigoPostal}
                        </strong>
                    </Paragraph>
                }
            />

            <FormRow mod="--btn-groups --mb-32">
                <Button mod="state--mobile-w100" tag="input" label="CONFIRMAR" type="button" onClick={confirmarCambio} mobileW100={true} />
                <Button mod="secondary" tag="input" label="CANCELAR" onClick={closeModal} type="button" mobileW100={true} />
            </FormRow>
        </>
    );
};

ConfirmarCambioDomicilio.propTypes = propTypes;

export default ConfirmarCambioDomicilio;
