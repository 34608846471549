import React from 'react';
import Link from '../Atoms/Link';
import {telefonoSecundario, horario, mailAccesoDigital } from '../../../Config/DatosDeContacto';

const SumarBeneficios = () => {
    return (
        <>
            Para sumar los beneficios de <strong>Club LA NACION</strong> a tu Acceso Digital llamá al <strong>{telefonoSecundario}</strong> de {horario} O si sos del exterior envianos un email a{' '}
            <Link href={`mailto: ${mailAccesoDigital}`}>
                <strong>{mailAccesoDigital}</strong>
            </Link>
        </>
    );
};

export default SumarBeneficios;
