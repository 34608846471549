import { estadosSuscripcionesMuestranDomicilioEnvio } from "../../../Config/Suscripciones";

export const generateClassHeader = estado => {
    switch (estado) {
        case 2:
        case 3:
            return 'state--warning';
        case 4:
        case 5:
        case 7:
            return 'state--danger';
        default:
            return '';
    };
};

export const isShownDomicilioByState = estado => {
    return estadosSuscripcionesMuestranDomicilioEnvio.includes(estado);
};
