import React from 'react'
import { useHistory } from 'react-router';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb'
import TitleWeb from '../../../Components/Common/Web/Title'
import { useStore } from '../../../Store/storeProvider'
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio'
import LoadingSpinner from '../../../Helpers/LoadingSpinner'
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary'
import { Text } from '@ln/common-ui-text';
import { CarouselBeneficios } from '../../../Components/Features/MisSuscripciones/components/CarouselBeneficios';
import { Button } from '@ln/experiencias-ui-button';
import PathsConfig from '../../../Config/RoutePaths';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import useGuardarRetencion from '../../../Hooks/useGuardarRetencion';

export const MantenerSuscripcion = (props) => {
  const { HandleSwitchsStates } = useStore();
  const history = useHistory();
  const stepProps = ['suscripcionABajar', 'motivo', 'retencion', 'tipoDeSuscripcion'];
  const state = useRedirectWithout(props.location.state, stepProps, PathsConfig.MisSuscripciones);
  const [result, errorGuardarRetencion, isLoadingGuardarRetencion] = useGuardarRetencion(state.retencion.idEstrategiaRetencion, state.retencion.idHerramienta, state.suscripcionABajar.id);

  const handleVolver = () => {
    history.push({
      pathname: PathsConfig.MisSuscripciones,
    })
  }

  const obtenerNombreAmigable = (value)  => value.split('/')[0]

  return (
    <>
      <section>
        <Breadcrumb firstItem={'Mis suscripciones'} href={'#'} text={'Solicitud de baja'} />
        <TitleWeb text="Solicitud de baja" />
      </section>
      <section className="container-fluid-streched arial">
        {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.BajaSuscripciones) ? (
          <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.BajaSuscripciones} />
        ) : (
          <LoadingSpinner isLoading={isLoadingGuardarRetencion}>
            <ErrorBoundary error={errorGuardarRetencion || (!result && !isLoadingGuardarRetencion)}>
              <div className='flex flex-column gap-32 text-neutral-light-800'>
                <div className='flex flex-column gap-8'>
                  <Text as='h3' className='arial-bold text-20'>¡Gracias por seguir con nosotros!</Text>
                  <Text as='p' className='text-16'>Tu suscripción {obtenerNombreAmigable(state.suscripcionABajar.nombre)} sigue activa.</Text>
                </div>
                <div className='flex flex-column gap-8'>
                  <Text as="h5" className="arial-bold text-18">Seguí disfrutando de todos estos beneficios con tu suscripción</Text>
                  <CarouselBeneficios plan={state.tipoDeSuscripcion} origen={PathsConfig.MantenerSuscripcion} />
                </div>
                <Groupbutton>
                  <Button title='Volver' onClick={handleVolver}>VOLVER</Button>
                </Groupbutton>
              </div>
            </ErrorBoundary>
          </LoadingSpinner>
        )}
      </section>

    </>
  )
}

export default MantenerSuscripcion;