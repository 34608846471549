import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  getFontFamily,
  getFontSize,
  getFontWeight,
} from "../../../Helpers/getFontData";

const propTypes = {
  /**
   * Elemento a renderizar
   */
  tag: PropTypes.string,
  className: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  bold: PropTypes.bool,
  highlight: PropTypes.bool,
  capital: PropTypes.bool,
};

const defaultProps = {
  tag: "span",
};

const Text = ({
  tag: Component,
  className,
  font,
  size,
  weight,
  highlight,
  capital,
  children,
  text,
  color,
}) => {
  const _content = children || text;

  const _font = getFontFamily(font);
  const _size = getFontSize(size);
  const _weight = getFontWeight(weight);

  const classes = classNames("text", className, _font, _size, _weight, {
    "--font-highlight": highlight,
    "--capital": capital,
  });
  const _color = { color: `${color}` };

  if (!_content) return null;

  return (
    <Component className={classes} style={_color}>
      {_content}
    </Component>
  );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
