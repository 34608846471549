export const domicilioCredencial = {
    IdCanalVenta: "",
    PlanId: "",
    EditarDomicilio: {
        addressId: "",
        altura: "",
        calle: "",
        cp: "",
        dpto: "",
        entreCalle1: "",
        entreCalle2: "",
        localidad: "",
        pais: 'ARGENTINA',
        piso: "",
        provincia: "",
        torre: null,
        cuerpo: null,
        lote: '',
        observaciones: null,
        tipoDePropiedad: "",
        normalizada: true,
    },
};