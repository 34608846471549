import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useGetSwitchsStates = () => {
    const { REACT_APP_MYACCOUNT } = process.env
    const [isLoading, setIsLoading] = useState(true);
    const [switchsStates, setSwitchsStates] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.get(`${REACT_APP_MYACCOUNT}GetSwitchsStates`)
                .then((data) => {
                    if (!data.error) {
                        const obj = data.reduce((acc, item) => {
                            acc[item.key] = item.value.toUpperCase() === "TRUE" ? true : false
                            return acc;
                        }, {})
                        setSwitchsStates(obj);
                    } else {
                        setError(data.message);
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [switchsStates, error, isLoading];
}

export default useGetSwitchsStates;