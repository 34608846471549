import React from "react";
import Paragraph from "../../Common/Atoms/Paragraph";
import Title from "../../Common/Atoms/Title";
import ParagraphList from "../../Common/Modules/ParagraphList";
import { horario, telefonoSecundario, mailAccesoDigital } from "../../../Config/DatosDeContacto";

const SuccessPagoRealizado = ({ nroGestion }) => {
  return (
    <div>
      <Title size="--s" mod="--mb-32">
        El número de gestión correspondiente es:{" "}
        <span className="--font-highlight">{nroGestion}</span>
      </Title>

      <ParagraphList>
        <Paragraph size="--twoxs" className="--arial --font-regular">
          Si tenés una suscripción digital,{" "}
          <strong>
            deberás aguardar 15 minutos para poder ingresar sin inconvenientes.
          </strong>{" "}
          Transcurrido dicho tiempo, si continuas visualizando la pantalla de
          saldo pendiente, comunicate al: {telefonoSecundario} de {horario} O si estás en el
          exterior envianos un email a: {mailAccesoDigital}
        </Paragraph>
      </ParagraphList>
    </div>
  );
};

export default SuccessPagoRealizado;
