import { useState } from 'react';
import Datadog from '../Helpers/Datadog';
import ApiService from '../Services/ApiService';

const useActualizarProductoPremium = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (evento, callback) => {
        setError(false);
        setIsLoading(true);
        ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripcionesV1/ActualizarProductoPremium`)
            .then((data) => {
                if (data && data.code === '0000') {
                    Datadog.trackCustomEvent('actualizacion-productoPremium-bonificado');
                    Datadog.trackCustomEvent('actualizacion-pps-evento-payload', {evento}); //sacar log debug
                    callback(evento);
                } else {
                    Datadog.trackCustomEventWarning('errorCode-actualizar-prod-premium-bonificado', { error: resp.message, code: resp.code });
                    setError(true);
                }
            })
            .catch((error) => {
                setError(true);
                Datadog.trackCustomEventWarning('error-actualizarProductoPremium-bonificado', { error });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return [error, isLoading, fetchData];
};

export default useActualizarProductoPremium;