import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import '../../../Statics/Scss/Components/InfoCard.scss';

import Paragraph from '../Atoms/Paragraph';
import Title from '../Atoms/Title';
import MiniCard from '../Atoms/MiniCards';

const propTypes = {
	/**
	 * Tipo de tarjeta.
	 *  1. Visa.
	 *  2. Master.
	 *  3. American.
	 * No utilizamos oneOf
	 * debido a que puede venir
	 * en cualquier case.
	 */
	cardType: PropTypes.string,
	/**
	 * Últimos números de la tarjeta.
	 */
	lastNumbers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * Banco asociado.
	 */
	bank: PropTypes.string,
	/**
	 * Flag, ¿tiene separador vertical?
	 */
	isVerticalDivider: PropTypes.bool,
	/**
	 * Flag, ¿es compacta?
	 */
	isCompact: PropTypes.bool,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const InfoCard = ({
	cardType,
	lastNumbers,
	bank,
	isVerticalDivider,
	isCompact,
	className,
	...r
}) => {
	const classes = classNames('mc-info-card', className, {
		'--compact': isCompact,
	});

	return (
		<div className={classes} {...r}>
			<MiniCard cardType={cardType} />

			<div className="content">
				{!isCompact && (
					<Title size="--fourxs" mod="--arial --font-regular">
						Tarjeta
					</Title>
				)}

				<div className="info">
					<Paragraph
						size="--twoxs"
						mod="--arial --font-bold --ai-center">
						{lastNumbers}
					</Paragraph>

					{bank && (
						<Paragraph size="--twoxs" mod="--arial --font-light">
							<span>{`${
								isVerticalDivider || !isCompact ? '|' : '●'
							}`}</span>
							Banco {bank}
						</Paragraph>
					)}
				</div>
			</div>
		</div>
	);
};

InfoCard.propTypes = propTypes;

export default InfoCard;
