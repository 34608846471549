export const getPendientes = (rechazos) => {
    const vencidas = rechazos?.rechazosCobrosVencidos?.map((deuda) => {
        return {...deuda, vencida: true }
    })
    const adelantadas = rechazos?.rechazosCobrosAdelantados?.map((deuda) => {
        return {...deuda, adelantada: true }
    })
    const _pendientes = vencidas?.concat(adelantadas);
    if (_pendientes) {
        const deudas = _pendientes.map((p) => {
            return { ...p, check: true };
        });
        return deudas;
    } else {
        return [];
    }
};
