import React from 'react'
import Toast from '../../Common/Modules/Toast'
import { telefonoSecundario, mailAccesoDigital } from '../../../Config/DatosDeContacto'

const ErrorNoCoincide = props => {
    return (
        <Toast status="warning" title="Ocurrió un error">
                Los números de DNI y Tarjeta que ingresaste no coinciden.  Cargalos nuevamente. <br /> 
                ¿Tenés dudas? Llamanos al {telefonoSecundario} o escribinos desde el exterior a <b>{mailAccesoDigital}</b>
        </Toast>
    )
}

export default ErrorNoCoincide
