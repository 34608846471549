import React from "react";

function TimeModal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="104"
      fill="none"
      viewBox="0 0 86 104"
    >
      <path
        fill="#081FA0"
        d="M51.36.035L9.059 0C3.978.018.168 5.248.168 9.923L.132 93.716a8.86 8.86 0 002.611 6.306 8.867 8.867 0 006.307 2.62l42.31.035c4.896-.018 8.891-4.013 8.891-8.909l.036-84.807C60.277 4.05 56.282.044 51.36.035zM9.068 2.232l.018 1.005 42.276.035c3.13 0 5.68 2.558 5.68 5.69l-.035 84.806c0 3.123-2.54 5.663-5.69 5.672l-42.275-.035a5.631 5.631 0 01-4.013-1.668 5.643 5.643 0 01-1.659-4.021l.036-83.793c0-3.017 2.531-6.677 5.662-6.686V2.232z"
      ></path>
      <path
        stroke="#126FFF"
        strokeLinecap="round"
        strokeWidth="3"
        d="M26.668 23.336h19.666M26.668 35.482h19.666M26.668 47.629h19.666"
      ></path>
      <path
        fill="#126FFF"
        d="M18.281 25.939a2.603 2.603 0 100-5.206 2.603 2.603 0 000 5.206zM18.281 38.085a2.603 2.603 0 100-5.206 2.603 2.603 0 000 5.206zM18.281 50.231a2.603 2.603 0 100-5.205 2.603 2.603 0 000 5.205z"
      ></path>
      <circle cx="59.119" cy="74.046" r="17.771" fill="#FEFEFE"></circle>
      <path
        fill="#126FFF"
        fillRule="evenodd"
        stroke="#126FFF"
        strokeWidth="0.3"
        d="M58.094 89.744a15.474 15.474 0 01-3.97-.869c-.017-.006-.03-.013-.046-.02a15.394 15.394 0 01-2.495-1.158c-7.595-4.362-10.41-14.122-6.266-21.76.9-1.66 2.052-3.083 3.39-4.254.018-.015.03-.027.048-.044 4.712-4.088 11.631-5.003 17.479-1.84l-.972 1.83c-.272.511-.03.84.533.732l5.016-.954c.56-.106.842-.64.628-1.185l-1.925-4.864c-.215-.546-.614-.575-.884-.064l-.978 1.834c-4.292-2.34-9.19-2.916-13.842-1.616-.47.13-.931.279-1.379.443-.005 0-.006.003-.01.003-.017.006-.036.015-.055.02-3.968 1.468-7.265 4.247-9.421 7.965a13.302 13.302 0 00-.592 1.095c-.014.027-.023.052-.037.079a18.95 18.95 0 00-1.689 11.349l.005.04c.059.379.13.764.217 1.139.004.025.012.048.02.073.083.379.176.758.287 1.135 1.11 3.852 3.323 7.201 6.347 9.68l.023.018.008.004a19.365 19.365 0 002.61 1.797 18.64 18.64 0 007.76 2.439c.83.07 1.544-.557 1.597-1.398.05-.84-.58-1.579-1.407-1.649z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#126FFF"
        fillRule="evenodd"
        stroke="#126FFF"
        strokeWidth="0.3"
        d="M56.651 65.391l-.076 10.312 8.897 5.626a1.191 1.191 0 001.643-.485 1.261 1.261 0 00-.51-1.678l-7.584-4.9.065-8.807a1.257 1.257 0 00-1.207-1.27 1.199 1.199 0 00-1.228 1.202zM75.163 66.096a1.286 1.286 0 01-1.838.054c-.53-.504-.569-1.34-.087-1.87.48-.532 1.303-.555 1.836-.053.532.502.57 1.338.09 1.869zM78.053 72.522a1.284 1.284 0 01-1.836.053c-.53-.504-.571-1.34-.088-1.87.48-.531 1.303-.554 1.837-.052.532.502.569 1.338.087 1.868zM77.46 78.817a1.284 1.284 0 01-1.837.054c-.532-.504-.571-1.34-.088-1.87.48-.531 1.302-.554 1.837-.052.532.5.567 1.338.087 1.868zM70.9 88.863a1.284 1.284 0 01-1.836.053c-.532-.504-.571-1.34-.088-1.87.48-.531 1.302-.554 1.837-.052.532.502.567 1.338.087 1.869zM64.812 91.724a1.286 1.286 0 01-1.839.053c-.53-.504-.569-1.34-.087-1.87.482-.531 1.303-.554 1.837-.052.532.502.569 1.338.089 1.868zM74.808 84.49a1.286 1.286 0 01-1.839.054c-.53-.502-.57-1.34-.085-1.87.478-.531 1.301-.554 1.835-.052a1.34 1.34 0 01.089 1.868z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TimeModal;
