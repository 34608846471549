import React, { useEffect, useState } from 'react';

import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import Text from '../../Components/Common/Text/Text';
import Link from '../../Components/Common/Atoms/Link';
import TitleWeb from '../../Components/Common/Web/Title';
import IncentivarVincularClubBanner from '../../Components/Common/Banners/IncentivarVincularClub';
import IncentivarSuscripcionBanner from '../../Components/Common/Banners/IncentivarSuscripcion';

import IconList from '../../Statics/Imgs/listIcon';
import useObtenerGrupos from '../../Hooks/useObtenerGrupos';
import useDarDeBajaCredencial from '../../Hooks/useDarDeBajaCredencial';
import useObtenerCredenciales from '../../Hooks/useObtenerCredenciales';

import '../../Statics/Scss/Pages/MisCredenciales.scss';
import CargaHistorial from '../../Components/Features/MisCredenciales/CargarHistorial';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import useListarEventos from '../../Hooks/useListarEventos';

import useObtenerVerificacionCredenciales from '../../Hooks/useObtenerVerificacionCredenciales ';
import { ToastBloqueoCredenciales } from './../../Components/Common/Banners/ToastBloqueoCredenciales';
import Credenciales from '../../Components/Features/MisCredenciales/Credenciales';
import { useStore } from '../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';

const MisCredenciales = () => {
    const { HandleSwitchsStates } = useStore();
    const [showHistorial, setShowHistorial] = useState(false);
    const [gruposObtenidos, credencialesOtorgadas, errorGrupos, isLoadingGrupos] = useObtenerGrupos();

    const [darDeBaja, errorBaja, isLoadingBaja] = useDarDeBajaCredencial();
    const [credenciales, errorCredenciales, isLoadingCredenciales] = useObtenerCredenciales();
    const [dataRelacionClub, errorRelacionClub, isLoadingRelacion] = useObtenerRelacionClub();
    const [historial, errorHistorial, isLoadingHistorial, getHistorial] = useListarEventos();
    const [isBlockedCredenciales, errorVerificacionCredenciales, isLoadingVerificacionCredenciales] = useObtenerVerificacionCredenciales();
    const [adicional, setAdicional] = useState(false);
    const [tieneCredenciales, setTieneCredenciales] = useState(false);

    useEffect(() => {
        setAdicional(esAdicional());
        setTieneCredenciales(tieneCredencialesDisponibles());
    }, [credenciales, credencialesOtorgadas]);

    const onDetalles = () => {
        if (!errorHistorial && !historial) getHistorial();
        setShowHistorial(!showHistorial);
    };

    const esAdicional = () => {
        return credenciales?.length == 1 && credenciales?.some((credencial) => credencial.CondicionGrupo === 'Adicional');
    };

    const tieneCredencialesDisponibles = () => {
        return credenciales?.length && credencialesOtorgadas?.length;
    };

    return (
        <>
            <section>
                <Breadcrumb firstItem="Club LA NACION" text="Mis credenciales" href="#" />
                <TitleWeb text="Mis credenciales" />
            </section>

            <section className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.MisCredenciales) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.MisCredenciales} />
                ) : (
                    <LoadingSpinner
                        isLoading={
                            isLoadingBaja || isLoadingGrupos || isLoadingCredenciales || isLoadingRelacion || isLoadingVerificacionCredenciales
                        }
                    >
                        {errorCredenciales || errorGrupos || errorRelacionClub || errorBaja || errorVerificacionCredenciales ? (
                            <Toast status="warning" title="Ocurrió un error">
                                Hubo un problema al consultar tus credenciales. Por favor, intentalo de nuevo.
                            </Toast>
                        ) : (
                            <div className="mis-credenciales-body">
                                {isBlockedCredenciales ? (
                                    <ToastBloqueoCredenciales
                                        title={'Atención'}
                                        estado={'alert'}
                                        subtitle={'La gestión de tus credenciales está temporalmente suspendida.'}
                                    />
                                ) : (
                                    <>
                                        {tieneCredenciales ? (
                                            <Credenciales
                                                credencialesOtorgadas={credencialesOtorgadas}
                                                gruposObtenidos={gruposObtenidos}
                                                adicional={adicional}
                                                darDeBaja={darDeBaja}
                                                dataRelacionClub={dataRelacionClub}
                                            />
                                        ) : (
                                            <>
                                                <Toast status="info" title="No tenés credenciales disponibles"></Toast>
                                                <IncentivarVincularClubBanner showBottomDivider />
                                                <IncentivarSuscripcionBanner showBottomDivider />
                                            </>
                                        )}
                                        {!adicional && (
                                            <div className="mis-credenciales-historial">
                                                <div className="historial">
                                                    <IconList />

                                                    <div className="">
                                                        <Text tag="p" size="2xs" font="arial" weight="bold">
                                                            Historial de movimientos
                                                        </Text>
                                                        <Link target="_blank" onClick={onDetalles}>
                                                            {showHistorial ? 'Ocultar detalle' : 'Ver detalle'}
                                                        </Link>
                                                    </div>
                                                </div>
                                                {showHistorial && (
                                                    <CargaHistorial
                                                        historial={historial}
                                                        errorHistorial={errorHistorial}
                                                        isLoadingHistorial={isLoadingHistorial}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </LoadingSpinner>
                )}
            </section>
        </>
    );
};

export default MisCredenciales;
