import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Link from '../../../Components/Common/Atoms/Link';
import Button from '../../../Components/Common/Modules/Button';
import InfoCard from '../../../Components/Common/Modules/InfoCard';
import TitleWeb from '../../../Components/Common/Web/Title';
import Title from '../../../Components/Common/Atoms/Title';
import RadioInput from '../../../Components/Common/Atoms/Form/RadioInput';
import InputBox from '../../../Components/Common/Atoms/Form/InputBox';
import Label from '../../../Components/Common/Atoms/Form/Label';
import TotalImport from '../../../Components/Common/Modules/TotalImport';
import Box from '../../../Components/Common/Atoms/Box';
import CardBoxContent from '../../../Components/Common/Modules/CardBoxContent';
import steps from '../../../Config/StepsPagosPendientes';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import useListarTarjetasDeCreditoReclamos from '../../../Hooks/useListarTarjetasDeCreditoReclamos';
import WondersoftContainer from '../../../Components/Features/Pagos/WondersoftContainer';
import { trackCustomEvent } from '../../../Helpers/Datadog';
import stepsTrackeoPagosPendientes from '../../../Config/StepsTrackeoPagosPendientes';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import { descripcionEstadosSuscripciones } from '../../../Config/Suscripciones';
import estadoSuscripciones from '../../../Models/EstadoSuscripciones';
import GetCardType from '../../../Helpers/GetCardType';
import { useDispatch } from '../../../Store/storeProvider';

const SeleccionarTarjeta = (props) => {
    const history = useHistory();
    const stepProps = ['campanita', 'deudas', 'idAbandonoCobranza'];
    const { campanita, deudas, idAbandonoCobranza } = useRedirectWithout(props.location.state, stepProps, PathsConfig.SeleccionarTarjeta);
    const [tarjetas, error, isLoading] = useListarTarjetasDeCreditoReclamos();
    const [pay, setPay] = useState(false);
    const [tarjetaSelected, setTarjetaSelected] = useState();
    const [errorTarjeta, setErrorTarjeta] = useState(false);
    const [anotherCard, setAnotherCard] = useState(false);
    const [showImport, setShowImport] = useState();
    const total = deudas?.reduce((sum, { Importe }) => sum + +Importe, 0);
    const suscripcionesDeDeuda = deudas?.flatMap((d) => d.Suscripcion);
    const dispatch = useDispatch();
    const ocultarCheckCIC = suscripcionesDeDeuda.filter(
        (suscripcion) =>
            ![
                descripcionEstadosSuscripciones[estadoSuscripciones.ACTIVA],
                descripcionEstadosSuscripciones[estadoSuscripciones.SUSP_VACACIONES],
                descripcionEstadosSuscripciones[estadoSuscripciones.PENDIENTE],
                descripcionEstadosSuscripciones[estadoSuscripciones.FALTA_PAGO],
            ].includes(suscripcion.Estado)
    ).length;

    const onMountOnly = () => {
        trackCustomEvent(stepsTrackeoPagosPendientes["VER-MEDIOS-DE-PAGO"])

        dispatch({
            type: 'PagoPendienteValues',
            value: {
                Campanita: campanita,
                Monto: total
            }
        })
    }

    useEffect(() => {
        onMountOnly()
    }, []);

    const handleContinuar = () => {
        if (tarjetaSelected) {
            setPay(true);
            trackCustomEvent(stepsTrackeoPagosPendientes["CLICK-AVANZA-PAGO"]);
        } else {
            setErrorTarjeta(true);
        }
    };

    const selectTarjeta = (e) => {
        let tarjetaSeleccionada = tarjetas.find((t) => e.target.value == t.Id);
        setErrorTarjeta(false);
        setTarjetaSelected(tarjetaSeleccionada);
    };

    const newCardReturn = () => {
        setAnotherCard(false);
        setTarjetaSelected(null);
    };

    const paymentReturn = () => {
        setPay(false);
        setTarjetaSelected(null);
    };

    const newCard = () => {
        setAnotherCard(true);
        trackCustomEvent(stepsTrackeoPagosPendientes["CLICK-AGREGAR-TARJETA"]);
    };

    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Pagos pendientes" />

                <TitleWeb text="Pagos pendientes" />
            </section>

            <section className="container-streched">
                <LoadingSpinner withBody isLoading={isLoading}>
                    <ErrorBoundary error={error}>
                        <Wizard steps={steps} activeStep={2} />

                        {pay ? (
                            <WondersoftContainer
                                title={'Ingresá los datos de tu tarjeta'}
                                showImport={setShowImport}
                                deudas={deudas}
                                idTarjeta={tarjetaSelected.Id}
                                idAbandonoCobranza={idAbandonoCobranza}
                                total={total}
                                volverFunc={paymentReturn}
                                ocultarCheckCIC={ocultarCheckCIC}
                            />
                        ) : anotherCard ? (
                            <WondersoftContainer
                                title={'Nueva tarjeta'}
                                showImport={setShowImport}
                                deudas={deudas}
                                deudasCompletas={deudas}
                                idAbandonoCobranza={idAbandonoCobranza}
                                total={total}
                                volverFunc={newCardReturn}
                                ocultarCheckCIC={ocultarCheckCIC}
                            />
                        ) : (
                            <>
                                <Title size="--s" mod="--font-bold --arial --mb-24">
                                    Elegí un medio de pago
                                </Title>

                                <form>
                                    <Label
                                        text="Mis medios de pago"
                                        link={
                                            <Link
                                                onClick={() => {
                                                    newCard();
                                                }}
                                            >
                                                + Nueva tarjeta
                                            </Link>
                                        }
                                    />
                                    {!isLoading && tarjetas?.length ? (
                                        tarjetas?.map((tarjeta, i) => (
                                            <InputBox
                                                key={i}
                                                mod="--mb-8"
                                                className={'--mb-8'}
                                                error={errorTarjeta}
                                                input={
                                                    <RadioInput
                                                        onChange={selectTarjeta}
                                                        error={errorTarjeta}
                                                        value={tarjeta.Id}
                                                        name="payment-method"
                                                    >
                                                        <InfoCard
                                                            key={i}
                                                            cardType={GetCardType(tarjeta.InstrumentoDeCobro.Nombre)}
                                                            lastNumbers={tarjeta.UltimosNumeros}
                                                            className="--mb-0"
                                                            bank={tarjeta.Banco ? tarjeta.Banco.Nombre : ''}
                                                            isCompact
                                                        />
                                                    </RadioInput>
                                                }
                                            />
                                        ))
                                    ) : (
                                        <div className="--boxBorder --mb-8">No hay medio de pago registrado, agregá una nueva tarjeta</div>
                                    )}
                                    {errorTarjeta && <div className="--red-500">Seleccioná un método de pago</div>}

                                    <Paragraph size="--s" mod="--font-bold --arial --mx-24">
                                        Saldos a pagar
                                    </Paragraph>

                                    <Box>
                                        {deudas?.map((deuda, i) => (
                                            <CardBoxContent
                                                key={i}
                                                cost={deuda.Importe}
                                                period={`${deuda.FechaPeriodoDesde} al ${deuda.FechaPeriodoHasta}`}
                                                description={deuda.Suscripcion[0].Nombre}
                                                billNumber={deuda.NroFactura}
                                            />
                                        ))}
                                    </Box>

                                    <TotalImport className="--mx-24" cost={total} />
                                    <FormRow mod="--btn-groups">
                                        <Button
                                            mod="state--mobile-w100"
                                            onClick={() => {
                                                handleContinuar();
                                            }}
                                            label="Continuar"
                                        />
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: PathsConfig.SeleccionDeudaHash,
                                                    //redirect a componente hash con el filtro al 100%, sino debe ir a SeleccionDeuda
                                                });
                                            }}
                                            label="Volver"
                                            mod="tertiary"
                                        />
                                    </FormRow>
                                </form>
                            </>
                        )}
                    </ErrorBoundary>
                </LoadingSpinner>
            </section>
        </>
    );
};

SeleccionarTarjeta.propTypes = {
    toastCondition: PropTypes.bool,
};

export default SeleccionarTarjeta;
