import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useListarTarjetasDeCreditoPaywall = () => {
  const { REACT_APP_APIPAYWALL } = process.env;
  const [isLoading, setIsLoading] = useState(true);
  const [tarjetas, setTarjetas] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      ApiService.post(`${REACT_APP_APIPAYWALL}Venta/ListarTarjetasDeCredito`)
        .then((data) => {
          if (data && data.code === "0000") {
            setTarjetas(data.response);
            setIsLoading(false);
          } else {
            setError(data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return [tarjetas, error, isLoading];
};

export default useListarTarjetasDeCreditoPaywall;