import { useState } from 'react';
import ApiService from '../Services/ApiService';
import { useStore } from '../Store/storeProvider';
import { funcionalidadDeshabilitada } from '../Helpers/FuncionalidadDeshabilitada'

const useGetDeudasSinHistoryPushPromise = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [rechazos, setRechazos] = useState([]);
    const [error, setError] = useState(null);
    const { HandleSwitchsStates } = useStore();

    const fetchData = () => {
        if (!funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.PagosPendientes)) {
            ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/obtenerRechazosDeCobroVencidoYAdelantado`)
                .then((data) => {
                    if (data && data.Code === 0) {
                        setRechazos(data.Response);
                    } else {
                        setError(data.Message);
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        else {
            if (HandleSwitchsStates) {
                setIsLoading(false);
            }
        }
    };


    return [rechazos, error, isLoading, fetchData];
};

export default useGetDeudasSinHistoryPushPromise;
