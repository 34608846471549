import { useEffect, useState } from 'react';
import Datadog from '../Helpers/Datadog';
import ApiService from '../Services/ApiService';
import useConsultarProductosPremiumPorCrmId from './useConsultarProductosPremiumPorCrmId';
import actualizarProductoPremiumPorParametro from '../Services/actualizarProductoPremiumPorParametro';
import SessionHandler from '../Handlers/Session';
import GoogleTagManagerService from '../Helpers/GoogleTagManagerService';
import { EstadosBonificados } from '../Config/EstadosBonificados';
import { useDispatch } from '../Store/storeProvider';

const useObtenerEventosSuscripcionesOnDemand = (crmId) => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);
    const [, , , getProductosPremium] = useConsultarProductosPremiumPorCrmId();
    const [cookiesActualizadas, setCookiesActualizadas] = useState(null);
    const dispatch = useDispatch();

    const updateCookies = () => {
        SessionHandler.Reload(dispatch)
            .then(() => {
                setCookiesActualizadas(true);
                setIsLoading(false);
            })
    }

    const buscarEvento = (contador, delay) => {
        let intentos = contador;
        const timeout = delay;
        setTimeout(() => {
            const fetchData = async () => {
                ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripcionesV1/ObtenerEventosSuscripciones`)
                    .then(async (data) => {
                        if (data && data.code === '0000') {
                            setEventos(data.response.evento);
                            if (data.response.evento?.EstadoTyC === EstadosBonificados[2] && data.response.evento?.EstadoSuscripcion === EstadosBonificados[6]) {
                                updateCookies();
                            } else if (intentos > 2) {
                                getProductosPremium(crmId).then((resp) => {
                                    if (resp?.code === '0000') {
                                        const pps = resp?.response?.ProductosPremiumId;
                                        if (pps) {
                                            actualizarProductoPremiumPorParametro(pps).then((response) => {
                                                updateCookies();
                                            });
                                        } else {
                                            setIsLoading(false);
                                            Datadog.trackCustomEventError('error-actualizarProductoPremiumPorParametro', { error: resp.message });
                                            setError(true);
                                        }
                                    }
                                });
                            } else {
                                intentos += 1;
                                buscarEvento(intentos, delay + 500);
                            }
                        } else {
                            setError(true);
                            setIsLoading(false);
                        }

                    })
                    .catch((err) => {
                        setError(err);
                        datadog.trackCustomEventWarning('error-obtenerEvento-bonificado', { error });
                        setIsLoading(false);
                    });
            };
            fetchData();
        }, timeout);
    };

    useEffect(() => {
        buscarEvento(1, 500);
    }, []);

    return [eventos, cookiesActualizadas, error, isLoading];
};

export default useObtenerEventosSuscripcionesOnDemand;
