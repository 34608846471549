import { useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';

const { REACT_APP_APIPAYWALL } = process.env;
const pathUrl = `${REACT_APP_APIPAYWALL}Suscripcion/CambioDomicilio`;

const useCambioDomicilio = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const outFunc = async (dataRequest) => {
        setIsLoading(true);
        return ApiService.post(pathUrl, { idDomicilio: dataRequest.idDomicilio, idSuscripciones: dataRequest.idSuscripciones })
            .then((response) => {
                if (response.code == 0) {
                    Datadog.trackCustomEvent(CustomEventsDatadog.cambioDomicilio, {idSuscripciones: dataRequest.idSuscripciones});
                    setError(null);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCambioDomicilio, { error: response.message, code: response.code , idSuscripciones: dataRequest.idSuscripciones});
                    setError(true);
                }
                setIsLoading(false);
                return Promise.resolve(response);
            })
            .catch((error) => {
                console.error(error.message);
                setError(true);
                setIsLoading(false);
                return Promise.reject(error);
            });
    };

    return [error, isLoading, outFunc];
};

export default useCambioDomicilio;
