import Metering from "../Handlers/Metering";
import { setupCookies } from "./Cookies";
import Usuario from '../Services/UsuarioService';
import { RefreshAsync } from "@ln/user.client.libs";

const UpdateCookiesAsync = async () => {
    try {
        const resp = await Usuario.Me();
        const { Usuario: usuarioMe } = JSON.parse(resp.response);
        console.log(usuarioMe,'usuarioMe')
        setupCookies(usuarioMe);
        await Metering.obtenerCookieSuscriptor();
        await RefreshAsync();
    } catch (error) {
        throw new Error();
    }
};

export default UpdateCookiesAsync;