import React from 'react'
import Modal from '../../Common/Organism/Modal';
import { Icon } from '@ln/common-ui-icon';
import { Text } from '@ln/common-ui-text'
import { Button } from '@ln/experiencias-ui-button';
import { CloseIcon } from '@ln/experiencias-ui-assets';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import { ListaBeneficiosModal } from './components/ListaBeneficiosModal';
import { getBeneficiosPorPlan } from '../../../Helpers/getBeneficiosPorPlan';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Datadog from '../../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../../Config/Datadog';

export const ModalSolicitarBaja = ({ show, handleCloseModal, plan, state }) => {
  const history = useHistory();

  if (!show) return <></>

  const { beneficiosConSuscripcion, beneficiosSinSuscripcion } = getBeneficiosPorPlan(plan)
  const handleMantenerSuscripcion = () => {
    Datadog.trackCustomEvent(CustomEventsDatadog.solicitudRetencion, { origen: "Modal" })

    history.push({
      pathname: PathsConfig.MantenerSuscripcion,
      state
    })    
  }
  const handleSolicitarBaja = () => {
    history.push({
      pathname: PathsConfig.ConfirmarBaja,
      state
    })
  }

  return (
    <Modal closeModal={handleCloseModal} mod='--dense'>
      <div className='flex flex-column gap-32 arial'>
        <Button
          variant='link'
          negative
          title='Cerrar modal'
          onClick={handleCloseModal}
          className='as-end'
        >
          <Icon size={24}><CloseIcon /></Icon>
        </Button>
        <div className='flex flex-column gap-24'>
          <Text as='p' className='text-16'>
            Estos son los beneficios con los que contas actualmente y los mismos que{' '}
            <Text className='arial-bold'>perderás luego de gestionar la baja</Text>
          </Text>
          <div className='grid grid-cols-1 grid-cols-2_md gap-24 text-14'>
            <ListaBeneficiosModal
              title='Con suscripción'
              beneficios={beneficiosConSuscripcion}
              check
            />
            <ListaBeneficiosModal
              title='Sin suscripción'
              beneficios={beneficiosSinSuscripcion}
              withoutBlueFirstItem
            />
          </div>
          <Groupbutton>
            <Button title="MANTENER MI SUSCRIPCION" onClick={handleMantenerSuscripcion}>MANTENER MI SUSCRIPCION</Button>
            <Button title="SOLICITAR LA BAJA" variant='secondary' onClick={handleSolicitarBaja}>SOLICITAR LA BAJA</Button>
          </Groupbutton>
        </div>
      </div>
    </Modal>
  )
}
