import React from 'react';

const FormCol = ({children, ...r}) => {
    return (
        <div className="com-col" {...r}>
            {children}
        </div>
     );
}

export default FormCol;