import React, { useState } from 'react';
import { volver } from '../../../Helpers/Reclamo';
import { useHistory } from 'react-router';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import Select from '../../../Components/Common/Atoms/Form/Select';
import CallBackArticle from '../../../Components/Common/Modules/CallbackArticle';
import InputGroup from '../../../Components/Common/Atoms/Form/InputGroup';
import Label from '../../../Components/Common/Atoms/Form/Label';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import '../../../Statics/Scss/Components/MiddleLineDivider.scss';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import useCrearReclamoSuscripcion from '../../../Hooks/useCrearReclamoDeSuscripcion';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import steps from '../../../Config/Steps';
import ErrorBlock from '../../../Components/Features/ReclamoEntrega/ErrorBlock';
import options from '../../../Models/HorariosEntrega';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';

const DemorasEnLaEntrega = (props) => {
    const history = useHistory();
    const stepProps = ['suscripcionSelected', 'motivoSelected', 'despachos', 'address', 'fechaDesde', 'fechaHasta'];
    const { suscripcionSelected, motivoSelected, despachos, address, fechaDesde, fechaHasta } = useRedirectWithout(
        props.location.state,
        stepProps,
        PathsConfig.ReclamoEntrega
    );
    const [dates, datesSelected] = useState({ desde: '', hasta: '' });
    const [validations, setValidations] = useState([]);
    const [reclamoError, reclamoIsLoading, CrearReclamoDeSuscripcion] = useCrearReclamoSuscripcion();

    const confirmarReclamo = () => {
        if (!validations.length && (dates.desde.length || dates.hasta.length)) {
            var data = {
                IdSuscripcion: suscripcionSelected,
                IdMotivoDeReclamo: motivoSelected,
                Despachos: despachos,
                AddressId: address,
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta,
                Horario: dates.desde + ' - ' + dates.hasta,
            };
            CrearReclamoDeSuscripcion(data);
        } else {
            var vale = [];
            if (!dates.desde.length || !dates.hasta.length) {
                if (!dates.desde.length) {
                    vale.push('desde');
                }
                if (!dates.hasta.length) {
                    vale.push('hasta');
                }
                setValidations(vale);
            }
        }
    };

    const handleDateChange = (event) => {
        var vals = [];
        var newState = dates;
        //seleccionó desde o hasta
        if (event.target.value === 'Desde' || event.target.value === 'Hasta') {
            vals.push(event.target.value.toLowerCase());
            newState[event.target.value.toLowerCase()] = '';
        }
        // seleccionó algo pero no es desde ni hasta
        else {
            if (event.target.id == 'desde') {
                //seleccionó horario desde
                newState.desde = event.target.value;
                vals.pop('desde');
            }
            if (event.target.id == 'hasta') {
                //seleccionó horario hasta
                newState.hasta = event.target.value;
                vals.pop('hasta');
            }
        }
        if (!newState.desde) {
            //no tenemos seleccionado horario desde
            vals.push('desde');
        }
        if (!newState.hasta) {
            //no tenemos seleccionado horario hasta
            vals.push('hasta');
        } else if (parseInt(newState.desde.replace(':', '')) >= parseInt(newState.hasta.replace(':', ''))) {
            //tenemos seleccionados ambos pero son excluyentes
            event.target.blur();
            vals.push('exclusion');
        }

        datesSelected(newState);
        setValidations(vals);
    };

    return (
        <div>
            <section className="lay-strechted">
                <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                <TitleWeb text="Nuevo reclamo de entrega" />
            </section>
            <section className="container-streched">
                <LoadingSpinner isLoading={reclamoIsLoading}>
                    <>
                        <Wizard steps={steps} activeStep={3} />
                        <form>
                            {reclamoError ? (
                                <ErrorBlock />
                            ) : (
                                <>
                                    <CallBackArticle
                                        title={'Confirmá la siguiente información antes de generar el reclamo'}
                                        description={
                                            <Paragraph size="--twoxs" mod="--arial --font-regular">
                                                Por favor, indicá el nuevo horario en el que querés recibir el producto
                                            </Paragraph>
                                        }
                                    />
                                    <FormRow>
                                        <Label text="Horario entre las" size="--fourxs" />
                                        <InputGroup className="grid-50-50 com-middle-line-divider state--no-wrap">
                                            <FormCol>
                                                <Select
                                                    leftIcon="icon-clock"
                                                    id="desde"
                                                    handleChange={handleDateChange}
                                                    state={
                                                        validations.includes('desde')
                                                            ? { status: 'error', msg: 'Seleccioná una hora' }
                                                            : validations.includes('exclusion')
                                                            ? { status: 'error', msg: null }
                                                            : null
                                                    }
                                                >
                                                    <option>{'Desde'}</option>
                                                    {options.map((item, key) => (
                                                        <option key={key}>{item}</option>
                                                    ))}
                                                </Select>
                                            </FormCol>
                                            <FormCol>
                                                <Select
                                                    leftIcon="icon-clock"
                                                    id="hasta"
                                                    handleChange={handleDateChange}
                                                    state={
                                                        validations.includes('hasta')
                                                            ? { status: 'error', msg: 'Seleccioná una hora' }
                                                            : validations.includes('exclusion')
                                                            ? { status: 'error', msg: null }
                                                            : null
                                                    }
                                                >
                                                    <option>{'Hasta'}</option>
                                                    {options.map((item, key) => (
                                                        <option key={key}>{item}</option>
                                                    ))}
                                                </Select>
                                            </FormCol>
                                        </InputGroup>
                                        {validations.includes('exclusion') && (
                                            <div className="com-select --fivexs state--error">
                                                <span class="com-select__descrip --fivexs">
                                                    El horario inicial/desde debe ser menor al final/hasta.
                                                </span>
                                            </div>
                                        )}
                                    </FormRow>
                                    <FormRow mod="--btn-groups">
                                        <Button mod="state--mobile-w100" href={'#'} onClick={() => confirmarReclamo()} label="GENERAR RECLAMO" />
                                        <Button
                                            onClick={() => volver(history, suscripcionSelected, motivoSelected)}
                                            href={'#'}
                                            mod="secondary"
                                            label="VOLVER"
                                        />
                                    </FormRow>
                                </>
                            )}
                        </form>
                    </>
                </LoadingSpinner>
            </section>
        </div>
    );
};

export default DemorasEnLaEntrega;
