export const SubscriberAttentions = [
    {
        content: 'Preguntas Frecuentes',
        variant: 'default',
        type: 'link',
        showArrow: true,
        href: 'https://www.contacto.lanacion.com.ar/ayuda',
        target:'_blank'
    },
    {
        content: 'Reglamento de Participación',
        variant: 'default',
        type: 'link',
        showArrow: true,
        href: 'https://www.contacto.lanacion.com.ar/reglamento-participacion?_ga=2.194351725.1974383294.1703010787-2102492923.1703010787',
        target:'_blank'
    },
    {
        content: 'Términos y condiciones generales',
        variant: 'default',
        type: 'link',
        showArrow: true,
        href: 'https://www.contacto.lanacion.com.ar/tyc?_ga=2.117284550.1974383294.1703010787-2102492923.1703010787',
        target:'_blank'
    },
    {
        content: 'Términos y condiciones de Acceso Digital',
        variant: 'default',
        type: 'link',
        showArrow: true,
        href: 'https://www.contacto.lanacion.com.ar/tyc-acceso-digital?_ga=2.118904137.1974383294.1703010787-2102492923.1703010787',
        target:'_blank'
    },
    {
        content: 'Políticas de privacidad',
        variant: 'default',
        type: 'link',
        showArrow: true,
        href: 'https://www.contacto.lanacion.com.ar/privacidad?_ga=2.193371757.1974383294.1703010787-2102492923.1703010787',
        target:'_blank'
    },
];
