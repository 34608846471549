import parseToBool from '../Helpers/ParseToBool';

const model = {
    calle: '',
    altura: '',
    dpto: '',
    piso: '',
    localidad: '',
    provincia: '',
    pais: 'ARGENTINA',
    cp: '',
    observaciones: null,
    entreCalle1: '',
    entreCalle2: '',
    barrio: '',
    country: '',
    nombreDePropiedad: '',
    torre: '',
    cuerpo: '',
    escalera: '',
    tipoDePropiedad: '',
    omiteNormalizar: true,
};

const validateKey = (values, camelKey) => {
    // El servicio lo espera el modelo con minúsculas y lo guarda tipo título, por lo que el listado de domicilios
    // que vuelven del servicio el modelo tiene los keys en formato título
    // por ésto acá podemos recibir el modelo a enviar que espera el servicio (minúsculas)
    // o el modelo recibido del servicio (título)
    let value = values[camelKey] || values[camelKey.charAt(0).toUpperCase() + camelKey.slice(1)];
    if (!parseToBool(value) || value === '') {
        if (['dpto', 'piso'].includes(camelKey)) return '0'; //return "-" pincha del lado del servicio (espera al menos un caracter alfanumérico pero por alguna razón "-" no lo cumple)
        return '';
    }
    return value;
};

const domicilioToObjectMap = (values) => {
    let obj = Object.assign({}, model);
    obj.altura = validateKey(values, 'altura');
    obj.barrio = validateKey(values, 'barrio');
    obj.calle = validateKey(values, 'calle');
    obj.cp = validateKey(values, 'cp');
    obj.dpto = validateKey(values, 'dpto');
    obj.entreCalle1 = validateKey(values, 'entreCalle1');
    obj.entreCalle2 = validateKey(values, 'entreCalle2');
    obj.localidad = validateKey(values, 'localidad');
    obj.piso = validateKey(values, 'piso');
    obj.provincia = validateKey(values, 'provincia');
    obj.torre = validateKey(values, 'torre');
    obj.cuerpo = validateKey(values, 'cuerpo');
    obj.escalera = validateKey(values, 'escalera');
    obj.nombreDePropiedad = validateKey(values, 'nombreDePropiedad');
    obj.tipoDePropiedad = validateKey(values, 'tipoDePropiedad').toUpperCase();

    return obj;
};

export default domicilioToObjectMap;
