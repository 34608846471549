import React from 'react';
import PropTypes from 'prop-types';

import '../../../../Statics/Scss/Components/TableCells.scss';
import '../../../../Statics/Scss/Components/Pill.scss';
import '../../../../Statics/Scss/Components/ColorBox.scss';
import { useWindowSize } from '../../../../Helpers/UseWindowSize';
import TooltipWrapper from '../../Atoms/TooltipWrapper';
import Button from '../Button';
import ConditionalWrapper from '../../../../Helpers/ConditionalWrapper';

const propTypes = {
    /**
     * Clases adicionales.
     */
    mod: PropTypes.string,
    /**
     * Propiedades de los botones.
     */
    buttonProps: PropTypes.array,
    /**
     * Propiedades de las celdas.
     */
    cellProps: PropTypes.object,
    /**
     * Propiedades del elemento contenido.
     */
    elementProps: PropTypes.object,
    /**
     * Flag, ¿truncar el texto?
     */
    truncateText: PropTypes.bool,
    /**
     * Flag, ¿es invisible la celda?
     */
    hidden: PropTypes.bool,
    /**
     * Orientacion de la celda fija
     */
    positionSticky: PropTypes.oneOf(['left', 'right']),
};

const defaultProps = {
    mod: '--fourxs --arial --font-regular',
};

const TableBodyCell = ({ children, mod, tooltipPosition, tooltipText, buttonProps, positionSticky, cellProps, elementProps, hidden, ...r }) => {
    const className =
        `com-table-body-cell${mod ? ` ${mod}` : ''}${buttonProps ? ' --flex' : ''}` +
        `${cellProps?.truncateText ? ' --truncate-text' : ''}` +
        `${buttonProps ? ' --full-width' : ''}` +
        `${cellProps?.hidden || hidden ? ' --hidden' : ''}` +
        ` --${positionSticky}`;
    const ElementTag = elementProps?.tag || 'div';
    const windowWidth = useWindowSize();
    const isMobileOrTablet = windowWidth < 1280;

    return (
        <td className={className} {...r}>
            {buttonProps && !isMobileOrTablet ? (
                buttonProps.map((props, i) => (
                    <TooltipWrapper position={tooltipPosition ? tooltipPosition : 'top'} tooltipText={tooltipText} key={i}>
                        <Button key={i} icon={props.icon} size="small" iconSize="s" {...(props.text && { label: props.text })} {...props} />
                    </TooltipWrapper>
                ))
            ) : buttonProps && isMobileOrTablet ? (
                buttonProps.map((props, i) => (
                    <Button key={i} icon={props.icon} size="small" iconSize="s" {...(props.text && { label: props.text })} {...props} />
                ))
            ) : (
                <ConditionalWrapper
                    condition={elementProps}
                    wrapper={(children) => <ElementTag {...elementProps}>{children}</ElementTag>}
                    defaultWrapper={(children) => <>{children}</>}
                >
                    {children}
                </ConditionalWrapper>
            )}
        </td>
    );
};

TableBodyCell.propTypes = propTypes;
TableBodyCell.defaultProps = defaultProps;

export default TableBodyCell;
