import React from 'react';
import Text from '../Text/Text';
import Image from '../Atoms/Image';
import ErrorImage from '../../../Statics/Imgs/error-not-found.png';
import './style.scss';

const ErrorNotFound = () => {
    return (
        <div className="error404">
            <Image className="--error-image" src={ErrorImage} />
            <Text as="p" size="--xl" className="--sueca --error-title">
                La página que buscas no está disponible
            </Text>
            <Text as="p" size="2xs" className="--roboto --error-subtitle">
                Seguí navegando y encontrá lo que necesitás
            </Text>
        </div>
    );
};

export default ErrorNotFound;
