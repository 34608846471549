import React from 'react';
import PropTypes from 'prop-types';

import '../../../../Statics/Scss/Components/Select.scss'

const Select = (props) => {
    const {children, name, id, disabled, leftIcon, state, description, handleChange} = props;
    const {status, msg} = state || {};
    return (
        <div className={ `com-select${ status == "error" && " state--error " || ""}${leftIcon && " leftIcon" || ""}${status == 'success' && " state--success " || ""}`}>
            <select {...props} onChange={handleChange} disabled={disabled} name={name} id={id} className="com-select__input">
                {children}
            </select>

            {leftIcon &&
                <i className={"com-select__leftIcon "+ leftIcon}></i>
            }

            {status == 'error'  ?
                <span className="com-select__descrip --fivexs">{msg}</span>
                :
                description &&
                <span className="com-select__descrip --fivexs">{description}</span>
            }
        </div>
    );
}


Select.propTypes = {
    state:    PropTypes.object,
    disabled: PropTypes.bool
}


export default Select;