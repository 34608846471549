import { useState } from 'react';
import ApiService from '../Services/ApiService';
import obtenerDespachoMap from '../Map/ObtenerDespachoMap';

const useObtenerDespacho = () => {
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const fetchData = async (suscripSelected, motivoSelected, detalleSelected, fechaDesde, fechaHasta) => {
        let dataSend = obtenerDespachoMap.obtenerDespachoMap(suscripSelected, motivoSelected, detalleSelected, fechaDesde, fechaHasta)
        if (dataSend.DetalleDeSuscripcion) {
            const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/ObtenerDespachos`;
            setIsLoading(true);
            ApiService.post(pathUrl, dataSend)
                .then(data => {
                    if (data.Code == 0 && data.Response) {
                        setData(data.Response);
                        setError(null);
                        setIsLoading(false);
                    } else {
                        setData(null);
                        setError(true);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    setData(null);
                    setError(true);
                    setIsLoading(false);
                });
        }
    };

    return [data, error, isLoading, fetchData];
};

export default useObtenerDespacho;
