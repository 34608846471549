import { useEffect, useState } from 'react';
import { createModifiableSuscriptionArray } from '../Filter/suscripciones';
import ApiService from '../Services/ApiService';
import { useDispatch, useStore } from '../Store/storeProvider';

const useObtenerSuscripciones = (includeAddressStatus = false) => {
    const { StateSuscripciones } = useStore();
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [suscripciones, setSuscripciones] = useState(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch(); 

    const saveState = (result) => {
        dispatch({
            type: 'Suscripciones',
            value: {
                suscripciones: result,
                fetchSuscripciones: false
            },
        });
    }

    useEffect(() => {
        if(StateSuscripciones?.suscripciones && !StateSuscripciones.fetchSuscripciones){
            setIsLoading(false);
            setSuscripciones(StateSuscripciones?.suscripciones);
        } else {
            const fetchData = async () => {
                ApiService.post(`${REACT_APP_APIPAYWALL}Suscripcion/ObtenerSuscripciones?IncludeAddressStatus=${includeAddressStatus}`)
                .then((data) => {
                    if (data && data.code === '0000') {
                        setSuscripciones(includeAddressStatus ?  
                        createModifiableSuscriptionArray(data.response) : data.response);
                        saveState(data.response);
                        setIsLoading(false);
                    } else if(data.code === '0001'){
                        setError(data.message);
                        setIsLoading(false);
                    }
                    else {
                        setSuscripciones([]);
                        setError(data.message);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
                //si error && !suscripciones: toast error al obtener suscr, si error y []: toast no tiene suscr
            };
            fetchData();
        }
    }, []);

    return [suscripciones, error, isLoading];
};

export default useObtenerSuscripciones;
