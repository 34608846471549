import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import ErrorNotFound from '../Error/ErrorNotFound';
import { notFoundConfig } from '../../../Config/NotFoundConfig';
import Cookies from 'js-cookie';
import Url from '../../../Helpers/Url'

const NotFoundLayout = () => {
    const history = useHistory();
    const usuarioEmail = Cookies.get('usuarioemail')

    useEffect(() => {
        if(!usuarioEmail) {
           Url.RedirectToLoginPreserveCallback()  
        }
        setTimeout(
            () =>
                history.push({
                    pathname: notFoundConfig.path,
                }),
            notFoundConfig.delayToRedirect
        );
    }, []);

    return (
        <div>
            <main className="menu__main">
                <ErrorNotFound />
            </main>
        </div>
    );
};

export default NotFoundLayout;
