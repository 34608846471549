import { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";

const useObtenerDatosClub = () => {
  let { REACT_APP_APILOGIN } = process.env;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      const pathUrl = `${REACT_APP_APILOGIN}Usuario/ObtenerDatosClub`;
      ApiService.post(pathUrl, {})
        .then((data) => {
          if (data && data.code) {
            if (data.code !== "1" && data.code !== "2") {
              setData(data);
              setError(`${data.message} - (codigo de error: ${data.code})`);
              setIsLoading(false);
            } else {
              setData(data);
              setIsLoading(false);
              setError(null);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return [data, error,isLoading];
};

export default useObtenerDatosClub;
