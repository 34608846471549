import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Atoms/Link';
import '../../../Statics/Scss/Modules/Breadcrumb.scss';
import EmbedHide from '../Organism/EmbedHide';

const Breadcrumb = ({ firstItem, secondItem, href, text, markup, target }) => (
    <EmbedHide>
        <div className="mod-breadcrumb">
            <ol className="com-list --inline --arial --font-normal --twoxs --flex-wrap">
                <li>{firstItem}</li>
                {href && (
                    <li>
                        <p>
                            {markup && markup}
                            {text}
                        </p>
                    </li>
                )}
                {secondItem && <li>{secondItem}</li>}
            </ol>
        </div>
    </EmbedHide>
);

Breadcrumb.propTypes = {
    href: PropTypes.string,
    text: PropTypes.string,
};

export default Breadcrumb;
