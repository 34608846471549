import React from "react";
import { useHistory } from "react-router";

import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import LoadingSpinner from "../../Helpers/LoadingSpinner";
import TitleWeb from "../../Components/Common/Web/Title";
import "../../Statics/Scss/Pages/SolicitudDeBaja.scss";
import useRedirectWithout from "../../Hooks/useRedirectWithout";
import ConfirmacionBajaCredencial from "../../Components/Features/MisCredenciales/ConfirmacionBajaCredencial";
import PathsConfig from "../../Config/RoutePaths";

const SolicitudDeBaja = (props) => {
  const history = useHistory();
  const stepProps = ["credencial", "success"];
  const { credencial, success } = useRedirectWithout(
    props.location.state,
    stepProps,
    PathsConfig.MisCredenciales
  );

  return (
    <>
      <section>
        <Breadcrumb
          firstItem="Club LA NACION"
          text="Mis credenciales"
          href="#"
        />
        <TitleWeb text="Solicitud de baja" />
      </section>

      <section className="container-streched">
        <LoadingSpinner isLoading={false}>
          <ConfirmacionBajaCredencial
            success={success}
            credencial={credencial}
          />
        </LoadingSpinner>
      </section>
    </>
  );
};

export default SolicitudDeBaja;
