const stepsTrackeoPagosPendientes = {
    "VER-DEUDAS": "visualizacion-seleccion-deuda",
    "CLICK-SELECCION-DEUDA": "click-seleccion-deuda",
    "VER-MEDIOS-DE-PAGO": "visualizacion-deuda-y-medios-de-pago",
    "CLICK-AVANZA-PAGO": "click-avanza-al-pago",
    "CLICK-AGREGAR-TARJETA": "click-agregar-tarjeta",
    "VER-IFRAME-PAGO": "visualizacion-iframe-wondersoft-pago-pendiente",
    "CLICK-CONTINUAR-IFRAME": "click-continuar-iframe-wondersoft",
    "VER-MENSAJE-CONFIRMACION": "visualizacion-mensaje-de-confirmacion",
    "CLICK-FINALIZAR": "click-finalizar"
}

export default stepsTrackeoPagosPendientes;