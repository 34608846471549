import React from 'react'
import PropTypes from 'prop-types'
import Toast from '../../Common/Modules/Toast'

const MensajeExito = props => {
    return (
        <Toast status="success" title="¡Felicitaciones!">Tu tarjeta está asociada a tu cuenta. A partir de ahora ya podés gestionar acá todos tus beneficios.</Toast>
    )
}

export default MensajeExito
