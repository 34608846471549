import React, { useState } from 'react';
import { useHistory } from 'react-router';

import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';
import Wizard from '../../Components/Common/Modules/Wizard';
import steps from '../../Config/StepsMisDomicilios';
import Domicilios from '../../Components/Features/Domicilios';
import Button from '../../Components/Common/Modules/Button';
import NotaCoberturaDeEnviosBanner from '../../Components/Common/Banners/NotaCoberturaDeEnviosArgentina';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import ConfirmarCambioDomicilio from '../../Components/Modals/ConfirmarCambioDomicilio';
import Modal from '../../Components/Common/Organism/Modal';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import PathsConfig from '../../Config/RoutePaths';
import { formatearDomicilio } from '../../Helpers/DomicilioFormatter';
import useCambioDomicilio from '../../Hooks/useCambioDomicilio';
import DateHelper from '../../Helpers/Date';
import '../../Statics/Scss/Pages/SeleccionarDomicilio.scss';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';

const SeleccionarDomicilio = () => {
    const history = useHistory();
    const stepProps = ['suscripcionsSelected'];
    const { suscriptionsSelected } = useRedirectWithout(history.location.state, stepProps, PathsConfig.MisSuscripcionesCambiarDomicilio);

    const [idDomicilioSelected, setIdDomicilioSelected] = useState(null);
    const [domicilioSelected, setDomicilioSelected] = useState(null);
    const [domicilioUnchecked, setDomicilioUnchecked] = useState(false);
    const [cambioDomicilioSuscripciones, setCambioDomicilioSuscripciones] = useState(true);
    const [modalCambioDomicilioSuscripciones, setModalCambioDomicilioSuscripciones] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [domicilioSeleccionado, setDomicilioSeleccionado] = useState(null);

    const [errorDomicilio, isLoadingDomicilio, outFuncDomicilio] = useCambioDomicilio();

    const idSuscripciones = suscriptionsSelected?.map((suscripcion) => suscripcion.idSuscription);
    const nombreSuscripciones = suscriptionsSelected?.map((suscripcion) => suscripcion.nombreSuscripcion);

    const stepBack = () => {
        history.goBack();
    };

    const handleOpenModal = () => {
        if (idDomicilioSelected) {
            setDomicilioSelected(domicilioSeleccionado);
            setOpenModal(true);
        } else {
            setDomicilioUnchecked(true);
        }
    };

    const handleDomicilioRedirect = (response) => {
        if (response.code == 0) {
            history.push({
                pathname: PathsConfig.MisSuscripcionesCambiarDomicilioConfirmacion,
                state: {
                    fechaEfectiva: DateHelper.convertDate_ddMMyyyy(response.response[0].FechaMinimaImplementacion),
                    nombreSuscripciones: nombreSuscripciones,
                    suscriptionsSelected: history.location.state.suscriptionsSelected,
                    code: response.code,
                    numGestion: response.message,
                },
            });
        } else {
            history.push({
                pathname: PathsConfig.MisSuscripcionesCambiarDomicilioConfirmacion,
                state: {
                    code: response.code,
                    suscriptionsSelected: history.location.state.suscriptionsSelected,
                },
            });
        }
    };

    const handleConfirmarCambio = () => {
        setOpenModal(false);

        outFuncDomicilio({
            idDomicilio: idDomicilioSelected,
            idSuscripciones: idSuscripciones,
        })
            .then((response) => {
                return handleDomicilioRedirect(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const setAddressData = (domicilio, nuevo) => {
        if (nuevo) {
            setDomicilioSeleccionado(formatearDomicilio(domicilio));
        } else {
            setDomicilioSeleccionado(domicilio);
        }
    };

    return (
        <>
            <section className="lay-strechted">
                <Breadcrumb firstItem="Mis suscripciones" href="#" text="Cambiar domicilio de entrega" />

                <TitleWeb text="Cambiar domicilio de entrega" />
            </section>

            <section className="container-streched">
                <Wizard steps={steps} activeStep={2} />
                <LoadingSpinner isLoading={isLoadingDomicilio}>
                    <Domicilios
                        setDomicilio={setIdDomicilioSelected}
                        setDomicilioSelected={setDomicilioSelected}
                        errorForm={domicilioUnchecked}
                        setErrorForm={setDomicilioUnchecked}
                        cambiarDomicilioDeEntrega={cambioDomicilioSuscripciones}
                        setCambiarDomicilioDeEntrega={setCambioDomicilioSuscripciones}
                        confirmarCambioDomicilioDeEntrega={handleConfirmarCambio}
                        modalCambioDomicilioEntrega={modalCambioDomicilioSuscripciones}
                        setModalCambioDomicilioEntrega={setModalCambioDomicilioSuscripciones}
                        idDomicilioDeEntrega={idDomicilioSelected}
                        setAddressData={setAddressData}
                        esCambioDomicilioDeEntrega={true}
                    />
                    <FormRow mod="--btn-groups --mb-32">
                        <Button mod="state--mobile-w100" label="CAMBIAR DOMICILIO" onClick={handleOpenModal} />
                        <Button mod="tertiary" label="VOLVER" onClick={stepBack} />
                    </FormRow>
                    <NotaCoberturaDeEnviosBanner />

                    {openModal && (
                        <Modal closeModal={handleCloseModal} title={'Confirmar cambio de domicilio'}>
                            <ConfirmarCambioDomicilio
                                closeModal={handleCloseModal}
                                confirmarCambio={handleConfirmarCambio}
                                nuevoDomicilio={domicilioSelected}
                                nombreSuscripcion={nombreSuscripciones}
                                idSuscripcion={suscriptionsSelected}
                                confirmarDomicilioEntrega={openModal}
                            />
                        </Modal>
                    )}

                </LoadingSpinner>
            </section>
        </>
    );
};

export default SeleccionarDomicilio;
