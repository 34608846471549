let Factura = {
    date: {
      value: "",
    },
    number: {
      value: "",
    },
    buttonProps: [
      {
        
				icon: 'download-file',
				text: 'Descargar',
				size: '--small',
				mod: '--primary',
				iconSize: '--s',
				tag: 'a',
				href: '',
				target: '_blank',
        download: "true"
      },
    ],
  };

export default Factura;