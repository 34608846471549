import React from 'react';
import DrawerHeader from './DrawerHeader';
import DrawerItem from './DrawerItem';
import DrawerError from '../../../../Statics/Imgs/drawerError';
import Skeleton from '../../Atoms/Skeleton';
import Text from '../../Text/Text';
import '../../../../Statics/Scss/Modules/DrawerInfo.scss';

const DrawerInfo = ({
    item,
    itemSelected,
    drawerError,
    drawerLoading,
    showDrawerMenu,
    setShowDrawerMenu,
    ...r
}) => {
    const listItem = [
        {
            text: 'Número de gestión',
            value: item && item.id.value,
        },
        {
            text: 'Fecha',
            value: item && item.fecha.value,
        },
        {
            text: 'Evento',
            value: item && item.evento.value,
        },
        {
            text: 'Origen',
            value: item && item.origen.value,
        },
    ];
    const listCardItem = [
        {
            text: 'Número',
            card:
                itemSelected &&
                itemSelected.CategoriaDeCredencial.toLowerCase(),
            value: itemSelected && itemSelected.NumeroDeCredencial,
        },
        {
            text: 'Categoría',
            value: itemSelected && itemSelected.CategoriaDeCredencial,
        },
        {
            text: 'Titular',
            value: itemSelected && itemSelected.Contacto,
        },
    ];

    return (
        <>
            {!drawerError ? (
                <>
                    <DrawerHeader className="drawer-header">
                        <Text className="--font-bold --sueca" size="--l">
                            Detalle
                        </Text>
                    </DrawerHeader>

                    {itemSelected && !drawerLoading ? (
                        <div>
                            <div className="item">
                                {listItem.map((item, i) => (
                                    <DrawerItem
                                        key={i}
                                        titleText={item.text}
                                        descriptionText={item.value}
                                    />
                                ))}
                            </div>

                            <Text
                                className="--font-bold --arial data-title"
                                size="2xs"
                            >
                                Datos de la credencial
                            </Text>

                            {listCardItem.map((item, i) => (
                                <DrawerItem
                                    key={i}
                                    titleText={item.text}
                                    card={item.card}
                                    descriptionText={item.value}
                                />
                            ))}
                        </div>
                    ) : (
                        drawerLoading && (
                            <div className="item">
                                {listItem.map((item, i) => (
                                    <div className="drawer-item">
                                        <Skeleton height={12} width={102} />
                                        <Skeleton height={16} width={163} />
                                    </div>
                                ))}

                                <div className="drawer-item">
                                    <Skeleton height={16} width={102} />
                                </div>

                                {listCardItem.map((item, i) => (
                                    <div className="drawer-item">
                                        <Skeleton height={12} width={102} />
                                        <Skeleton height={16} width={163} />
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </>
            ) : (
                <>
                    <div className="drawer-error">
                        <DrawerError />

                        <Text
                            tag="p"
                            size="--l"
                            font="arial"
                            weight="bold"
                            className="title-error"
                        >
                            Ocurrió un error
                        </Text>

                        <Text tag="p" size="2xs" font="arial" weight="normal">
                            Hubo un problema con tu solicitud. <br /> Por favor,
                            volvé a intentar.
                        </Text>
                    </div>
                </>
            )}
        </>
    );
};

export default DrawerInfo;
