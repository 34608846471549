import React, { useEffect } from 'react'
import Toast from './../Modules/Toast';
// import { telefono, horario } from '../../../Config/DatosDeContacto';
import { CustomEventsDatadog } from './../../../Config/Datadog';
import Datadog from './../../../Helpers/Datadog';

export const ToastFuncionalidadSinServicio = ({funcionalidad}) => {
    const evento = funcionalidad?.evento;
    useEffect(() => {
        Datadog.trackCustomEvent(CustomEventsDatadog.vistaFuncionalidadApagada, {funcionalidad: evento})
    }, []);

    return (
        <Toast status="info" title="Funcionalidad no disponible.">
            {funcionalidad.descripcion} está temporalmente fuera de servicio. Estamos trabajando para solucionarlo lo antes posible.
            {/* Por cualquier consulta comunicate al {telefono} de {horario} */}
        </Toast>
    )
}
