import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from '../Helpers/Datadog';
import { CustomEventsErrorDatadog } from '../Config/Datadog';

const useObtenerGrupos = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [gruposObtenidos, setGruposObtenidos] = useState([]);
    const [credencialesOtorgadas, setCredencialesOtorgadas] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIRECLAMOS}credenciales/ObtenerGrupo`)
                .then((data) => {
                    if (data.Code == 0) {
                        let respuesta = Array.isArray(data.Response) ? data.Response : [data.Response];
                        setGruposObtenidos(respuesta);
                        setCredencialesOtorgadas(respuesta?.map((grupo) => grupo?.Credenciales).flat());
                        setIsLoading(false);
                    } else {
                        setError(null);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorObtenerGrupos, error)
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [gruposObtenidos, credencialesOtorgadas, error, isLoading];
};

export default useObtenerGrupos;
