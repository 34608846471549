import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import '../../../Statics/Scss/Components/CardBoxItem.scss';

import InfoCard from './InfoCard';

const propTypes = {
	/**
	 * Título opcional del módulo.
	 */
	title: PropTypes.string,
	/**
	 * Flag, ¿contiene una mini card?
	 */
	isCard: PropTypes.bool,
	/**
	 * Tipo de tarjeta.
	 */
	cardType: PropTypes.string,
	/**
	 * Últimos números de la tarjeta.
	 */
	lastNumbers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const CardBoxItem = ({
	title,
	children,
	isCard,
	cardType,
	lastNumbers,
	className,
	mobileItems,
	...r
}) => {
	const classes = classNames('mc-card-box-item', className, {
		'--mobile-three-items': mobileItems
	});

	return (
		<div className={classes} {...r}>
			{title && (
				<h3 className="title --sixxs --arial --font-bold">{title}</h3>
			)}
			<div className={`content${isCard ? ' card' : ''}`}>
				{isCard && (
					<InfoCard
						cardType={cardType}
						isCompact={true}
						lastNumbers={lastNumbers}
					/>
				)}
				{children && (
					<p className="text --twoxs --arial --font-regular">
						{children}
					</p>
				)}
			</div>
		</div>
	);
};

CardBoxItem.propTypes = propTypes;

export default CardBoxItem;
