import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Common/Atoms/Link';
import CallbackArticle from '../../Common/Modules/CallbackArticle';
import imgPromo2 from '../../../Statics/Imgs/image-promo-2.png';
import EmbedHide from '../../Common/Organism/EmbedHide';
import Toast from '../../Common/Modules/Toast';
import IncentivarVincularClubBanner from '../../Common/Banners/IncentivarVincularClub';

const propTypes = {
    club: PropTypes.object,
};

const SinBeneficiosParaMostrar = (props) => {
    const relacionClub = props.club;

    return (
        <>
            {!relacionClub || !relacionClub.nroCredencial ? (
                <IncentivarVincularClubBanner />
            ) : (
                <Toast
                    status="info"
                    title="¡Todavía no tenés consumos con Club LA NACION!"
                    children="Acá encontrás tus consumos de los últimos 6 meses"
                />
            )}
            <EmbedHide>
                <CallbackArticle
                    className="sin-beneficios"
                    bigTitle
                    title="¡Hay muchos beneficios disponibles para vos!"
                    imageUrl={imgPromo2}
                    description={
                        <p className="--twoxs">
                            <Link target="_blank" href="https://club.lanacion.com.ar/?utm_source=micuenta&utm_medium=online">
                                Ingresá acá
                            </Link>{' '}
                            y conocé todo lo que Club LA NACION tiene para vos.
                        </p>
                    }
                />
            </EmbedHide>
        </>
    );
};

SinBeneficiosParaMostrar.propTypes = propTypes;

export default SinBeneficiosParaMostrar;
