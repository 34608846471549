import React from 'react'
import FormRow from '../../../Common/Atoms/Form/FormRow';
import Label from '../../../Common/Atoms/Form/Label';
import FormCol from '../../../Common/Atoms/Form/FormCol';
import InputGroup from '../../../Common/Atoms/Form/InputGroup';
import InputBox from '../../../Common/Atoms/Form/InputBox';
import RadioFormik from '../../../Common/Formik/Radio';
import { Form, Formik } from "formik";
import parseToBool from '../../../../Helpers/ParseToBool';

const MismoDomicilioRevista = ({setOptionRetiraProductoEnKiosko, optionRetiraProductoEnKiosko, formRef}) => {
    
    let handleChangeRetiraKiosco = (event) => {
        setOptionRetiraProductoEnKiosko(parseToBool(event.target.value));
    };

    return(
        <Formik 
            innerRef={formRef} 
            initialValues={{
                retiraEnKiosko: '',
            }}>
            {(props) => (
                <Form noValidate>
                    <fieldset>
                        <FormRow>
                        <Label
                            text="¿Compraste los ejemplares en el kiosco?"
                            size="--fourxs"
                        />
                        <InputGroup className="state--no-wrap">
                            <FormCol>
                                <InputBox
                                    input={
                                    <RadioFormik
                                        name="retiraEnKiosko"
                                        value="true"
                                        onChange={handleChangeRetiraKiosco}
                                        checked={optionRetiraProductoEnKiosko}
                                    >
                                        Si
                                    </RadioFormik>
                                    }
                                />
                            </FormCol>
                            <FormCol>
                                <InputBox
                                    input={
                                    <RadioFormik
                                        name="retiraEnKiosko"
                                        value="false"
                                        checked={!optionRetiraProductoEnKiosko}
                                        onChange={handleChangeRetiraKiosco}
                                    >
                                        No
                                    </RadioFormik>
                                    }
                                />
                            </FormCol>
                        </InputGroup>
                    </FormRow>
                    </fieldset>
                </Form>
            )}
        </Formik>
    )
}

export default MismoDomicilioRevista;