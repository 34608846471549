import React from 'react';
import PropTypes from 'prop-types';

import '../../../Statics/Scss/Modules/ParagraphList.scss';

const propTypes = {
	children: PropTypes.element,
};

const ParagraphList = ({children}) => {
	return <div className="mod-paragraph-list">{children}</div>;
};

ParagraphList.propTypes = propTypes;

export default ParagraphList;
