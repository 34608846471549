import React from 'react';
import PropTypes from 'prop-types';
import '../../../Statics/Scss/Components/Title.scss';

const Title = ({as, size, children, className, mod}) => {
	const Element = as || 'h4';
	const classes = `com-title${size ? ` ${size}` : ``}${mod ? ` ${mod}` : ``}${
		className ? ` ${className}` : ``
	}`;

	return <Element className={classes}>{children}</Element>;
};

Title.propTypes = {
	as: PropTypes.string,
	size: PropTypes.string,
	children: PropTypes.node,
	mod: PropTypes.string,
};

export default Title;
