import React, { useState } from 'react';
import ContactoDudasBanner from '../../Components/Common/Banners/ContactoDudas';
import IncentivarSuscripcionBanner from '../../Components/Common/Banners/IncentivarSuscripcion';
import ToastEmptyState from '../../Components/Common/Banners/ToastEmptyState';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import TitleWeb from '../../Components/Common/Web/Title';
import SuscripcionConSaldo from '../../Components/Features/Pagos/SuscripcionConSaldo';
import SuscripcionSinSaldo from '../../Components/Features/Pagos/SuscripcionSinSaldo';
import useObtenerImporteDeudas from '../../Components/Features/Pagos/useObtenerImporteDeudas';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import useObtenerSuscripciones from '../../Hooks/useObtenerSuscripciones';
import { getCondicionSuscripcionUsuario } from '../../Models/CondicionSuscripcion';
import { useStore } from '../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';

const EstadoDeCuenta = () => {
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const [total, errorImporte, isLoadingImporte] = useObtenerImporteDeudas();
    const [dataRelacionClub, errorRelacionClub, isLoadingRelacionClub] = useObtenerRelacionClub();
    const condicionSuscripcion = getCondicionSuscripcionUsuario(dataRelacionClub?.response);
    const tieneSuscripcion = suscripciones?.length > 0 || condicionSuscripcion == 3 || condicionSuscripcion == 4;
    const { HandleSwitchsStates } = useStore();
    const [isLoadingPdf, setIsLoadingPdf] = useState();

    return (
        <div>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Estado de cuenta" />

                <TitleWeb text="Estado de cuenta" />
            </section>
            <section className="container-streched">
                <LoadingSpinner isLoading={isLoadingImporte || isLoadingSuscripciones || isLoadingRelacionClub || isLoadingPdf}>
                    {errorImporte || errorRelacionClub || (errorSuscripciones && !suscripciones) ? (
                        <>
                            <Toast closeAuto={3000} status="warning" title="Ocurrió un error">
                                Hubo un problema al consultar tu estado de cuenta. Por favor, intentalo de nuevo.
                            </Toast>
                            <ContactoDudasBanner />
                        </>
                    ) : (
                        funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.EstadoDeCuenta) ? (
                            <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.EstadoDeCuenta}/>
                        ) : (
                            <>
                                {tieneSuscripcion && total > 0 && <SuscripcionConSaldo saldoPendiente={total} />}
                                {tieneSuscripcion && total < 1 && (
                                    <>
                                       <SuscripcionSinSaldo setIsLoadingPdf={setIsLoadingPdf}/>
                                    </>
                                )}

                                {!tieneSuscripcion && total > 0 && (
                                    <>
                                        <SuscripcionConSaldo saldoPendiente={total} />
                                        <IncentivarSuscripcionBanner />
                                    </>
                                )}
                                {!tieneSuscripcion && total < 1 && (
                                    <>
                                        <ToastEmptyState hasBanner generico />
                                    </>
                                )}
                            </>
                        )
                    )}
                </LoadingSpinner>
            </section>
        </div>
    );
};
export default EstadoDeCuenta;
