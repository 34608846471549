
const initialState = {
    HandleExisteDeuda: {
        ExisteDeuda: null,
        TotalDeuda: null,
        ErrorApi: null,
    },
    PagoPendienteEfectuado: {
        Campanita: null,
        Monto: null
    },
    HandleSwitchsStates: null,
    actualizarAvatar: false,
    StateSuscripciones: {
        suscripciones: null,
        fetchSuscripciones: false
    },
};

const storeReducer = (state, action) => {
    switch (action.type) {
        case 'ExisteDeuda': {
            return {
                ...state,
                HandleExisteDeuda: {
                    ExisteDeuda: action.value.ExisteDeuda,
                    TotalDeuda: action.value.TotalDeuda,
                    ErrorApi: action.value.ErrorApi,
                },
            };
        }
        case 'PagoPendienteValues': {
            return {
                ...state,
                PagoPendienteEfectuado: {
                    Campanita: action.value.Campanita,
                    Monto: action.value.Monto
                },
            };
        }
        case 'SwitchsStates': {
            return {
                ...state,
                HandleSwitchsStates: action.value
            }
        }
        case 'actualizarAvatar':{
            return {
                ...state,
                actualizarAvatar: action.value
            }
        }
        case 'Suscripciones':{
            return {
                ...state,
                StateSuscripciones: {
                    ...state.StateSuscripciones, //actualiza el estado de suscripciones solamente si recibe un nuevo estado
                    suscripciones: action.value.suscripciones !== undefined 
                    ? action.value.suscripciones 
                    : state.StateSuscripciones.suscripciones,
                    fetchSuscripciones: action.value.fetchSuscripciones
                }                 
            }
        }
    }
    return state;
};
export { initialState, storeReducer };
