import React from 'react'
import PropTypes from 'prop-types'
import Toast from '../../Common/Modules/Toast'
import Link from '../../Common/Atoms/Link'
import { telefonoSecundario, mailAccesoDigital } from '../../../Config/DatosDeContacto'

const ErrorYaExisteRelacion = props => {
    return (
        <Toast status="alert" title="Ocurrió un error">
            <>
                La cuenta está creada con el email: <b>{props.email}</b>
                <br />Si es tuya, ingresá con ese email.
                <br /><br />
                    ¿Tenés dudas? Llamanos al {telefonoSecundario} o escribinos desde el exterior a <b>{mailAccesoDigital}</b>
                <br /><br />
                <Link href="https://goo.gl/K3gZWY" target="_blank">¿cómo desvincular la credencial para usarla en mi nueva cuenta?</Link>
            </>
        </Toast>
    )
}

ErrorYaExisteRelacion.PropTypes = {
    email: PropTypes.string.isRequired
}

export default ErrorYaExisteRelacion
