import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Common/Modules/Button';

import Modal from '../Common/Organism/Modal';
import Paragraph from '../Common/Atoms/Paragraph';

const propTypes = {
    setOpenModal: PropTypes.func.isRequired,
    onLogOut: PropTypes.func.isRequired,
};

const MsgModal = (props) => {
    const onLogOut = () => {
        props.setOpenModal(false);
        props.onLogOut();
    };

    return (
        <Modal closeModal={onLogOut}>
            <div>
                <Paragraph size="--threexs" mod="--arial --mb-32">
                    <b>Iniciá sesión con tu nuevo email</b> e ingresá al link que te enviamos al mismo.
                </Paragraph>
                <Button type="button" onClick={onLogOut} label="INICIAR SESIÓN" className="state--w100 --mb-0" />
            </div>
        </Modal>
    );
};

MsgModal.propTypes = propTypes;

export default MsgModal;
