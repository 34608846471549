export const getUpsellingNombresAmigables = {
    'pasate-a-club': 'Acceso Digital + Club',
    'pasate-a-duo': 'Dúo Premium',
    'pasate-a-triple': 'Triple Premium',
    'pasate-a-black': 'Black Familiar',
}

export const getUpsellingKeys = {
    AccesoYClub: 'pasate-a-club',
    DuoPremium: 'pasate-a-duo',
    TriplePremium: 'pasate-a-triple',
    BlackFamiliar: 'pasate-a-black',
}