const Auth0Config = {
    MaxAge: `0`,
    PromptLogin: `login`,
    ResponseType: `token`,
    LinkEventName: `account-linking`,
    Scopes: `openid profile email offline_access`,
    GoogleConnection: `google-oauth2`,
    FacebookConnection: `facebook`,
    AppleConnection: `apple`,    
};

export default Auth0Config;
