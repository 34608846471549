import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import PathsConfig from '../../../Config/RoutePaths';
import stepsTrackeoPagosPendientes from '../../../Config/StepsTrackeoPagosPendientes';
import { trackCustomEvent } from '../../../Helpers/Datadog';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import useObtenerRechazosCobroVencidoYAdelantado from '../../../Hooks/useObtenerRechazosCobroVencidoYAdelantado';

//componente intermedio para poder ir a seleccionar deuda por la url hasheada a pagos pendientes, con el filtro al 100%
//el click volver en seleccion tarjeta pasa por este componente
const PasoPagoPendienteHash = () => {
    const history = useHistory();
    const [rechazos, errorRechazos, isLoadingRechazos] = useObtenerRechazosCobroVencidoYAdelantado();

    useEffect(() => {
        if (rechazos?.rechazosCobrosAdelantados?.length || rechazos?.rechazosCobrosVencidos?.length) {
            trackCustomEvent(stepsTrackeoPagosPendientes["VER-DEUDAS"]);
            history.push({
                pathname: PathsConfig.SeleccionarDeuda,
                state: {
                    rechazos: rechazos,
                    filtroUsuario: true,
                },
            });
        }
    }, [rechazos]);

    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Pagos pendientes" />

                <TitleWeb text="Pagos pendientes" />
            </section>

            <section className="container-streched">
                <LoadingSpinner withBody={true} isLoading={isLoadingRechazos} />
                {errorRechazos && <ErrorGenerico />}
            </section>
        </>
    );
};

export default PasoPagoPendienteHash;
