import '../../../Statics/Scss/Banner/ConfirmacionDetalle.scss';
import Icon from '../Atoms/Icon'
import Text from '../Text/Text';
import React from 'react'
import Beneficios from './Beneficios';

const DetallesVentaBonificada = ({ email }) => {
    return (
        <>
            <Text className="confirmacion --twoxs">
                <div className="--mb-16">
                    Tu credencial Club LA NACION te brinda <strong>Acceso Digital</strong> sin cargo.
                </div>
                <Beneficios  featuresBonificada={true} />
            </Text>
            <div className="mod-toast --info --fourxs --mb-16">
                <Icon name="info" size="s" />
                <p>Recordá que para utilizar tu suscripción, tenés que iniciar sesión con {email}</p>
            </div>
            <Text as="p" className="--terms --fourxs --mb-24">
                Al hacer click en Continuar, confirmo que he leído y aceptado los{' '}
                <a href="https://www.contacto.lanacion.com.ar/tyc" target="_blank" className="com-link --font-bold">
                    términos y condiciones.
                </a>
            </Text>
        </>
    );
};

export default DetallesVentaBonificada;
