export const handleRedeemPinErrorMessage = ( code ) => {

    let errorMessage = ''

    switch (code) {
        case "6008":
        case "6011":
            errorMessage = 'Código de Activación inválido.'
            break;
        case "6009":
            errorMessage = 'El código de activación que ingresaste ya ha sido utilizado.'
            break;
        case "6010":
            errorMessage = 'El código de activación ha vencido.'
            break;
    
        default:
            errorMessage = ""
            break;
    }

    return errorMessage;
}