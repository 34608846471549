import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import Toast from "../../Components/Common/Modules/Toast";
import Button from '../../Components/Common/Modules/Button';
import FormCol from '../../Components/Common/Atoms/Form/FormCol';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';
import Input from '../../Components/Common/Atoms/Form/Input';
import InputGroup from '../../Components/Common/Atoms/Form/InputGroup';
import Label from '../../Components/Common/Atoms/Form/Label';
import Divider from '../../Components/Common/Atoms/Divider';
import Paragraph from '../../Components/Common/Atoms/Paragraph';
import Link from '../../Components/Common/Atoms/Link';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useObtenerDatosCRM from "../../Hooks/useObtenerDatosCRM";
import useObtenerDatosUsuario from "../../Hooks/useObtenerDatosUsuario";
import useObtenerIdentitiesProviderData from "../../Hooks/AccountLinking/useObtenerIdentitiesProviderData";
import useLinkGoogleAccount from "../../Hooks/AccountLinking/useLinkGoogleAccount";
import useUnlinkGoogleAccount from "../../Hooks/AccountLinking/useUnlinkGoogleAccount";
import useLinkFacebookAccount from "../../Hooks/AccountLinking/useLinkFacebookAccount";
import useUnlinkFacebookAccount from "../../Hooks/AccountLinking/useUnlinkFacebookAccount";
import { Form, Formik } from "formik";
import MiUsuarioForm from "../../Validations/MiUsuarioForm";
import InputFormik from "../../Components/Common/Formik/Input";
import ConfirmarCambioMail from "../../Components/Modals/ConfirmarCambioMail";
import ConfirmarCambioMailSecundario from "../../Components/Modals/ConfirmarCambioMailSecundario";
import _Logout from "../../Handlers/_Logout";
import MsgModal from "../../Components/Modals/MsgModal";
import ApiService from "../../Services/ApiService";
import TitleWeb from "../../Components/Common/Web/Title";
import PathsConfig from "../../Config/RoutePaths";
import Datadog from "../../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../../Config/Datadog";
import Google from "../../Components/Features/MiUsuario/Google";
import Facebook from "../../Components/Features/MiUsuario/Facebook";
import useVerificarClaveUsuario from "../../Hooks/useVerificarClaveUsuario";
import { useStore } from "../../Store/storeProvider";
import Embed from '../../Models/Embed';
import EmbedHide from "../../Components/Common/Organism/EmbedHide";
import Auth0 from '../../Services/Auth0Service';
import Url from '../../Helpers/Url';
import Auth0Config from '../../Config/Auth0';
import { funcionalidadDeshabilitada } from "../../Helpers/FuncionalidadDeshabilitada";
import { ToastFuncionalidadSinServicio } from './../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from "../../Config/FuncionalidadesConfig";

const { REACT_APP_APIPAYWALL } = process.env;
const { REACT_APP_APILOGIN } = process.env;

const MiUsuario = () => {
    const history = useHistory();
    const showAlertToast = (msg, status) => { showFloatingToast({ status, titulo: msg }); };
    const [identitiesProviderData, identitiesProviderError, isLoadingIdentityProviderData, fetchAuth0IdentityProvider] =
        useObtenerIdentitiesProviderData();
    const [isLoadingGoogle, setLoadingGoogle] = useState(false);
    const [isLoadingFacebook, setLoadingFacebook] = useState(false);
    const [user, userError, userLoading] = useObtenerDatosUsuario();
    const [linkGoogleAccountProcess] = useLinkGoogleAccount(fetchAuth0IdentityProvider, showAlertToast, setLoadingGoogle);
    const [unlinkGoogleAccountProcess] = useUnlinkGoogleAccount(fetchAuth0IdentityProvider, showAlertToast, setLoadingGoogle);
    const [linkFacebookAccountProcess] = useLinkFacebookAccount(fetchAuth0IdentityProvider, showAlertToast, setLoadingFacebook);
    const [unlinkFacebookAccountProcess] = useUnlinkFacebookAccount(fetchAuth0IdentityProvider, showAlertToast, setLoadingFacebook);
    const [client, clientError, clientLoading, fetchData] = useObtenerDatosCRM();
    const [loading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [mailPrincipal, setMailPrincipal] = useState(null);
    const [mailSecundario, setMailSecundario] = useState(null);
    const [floatingToast, showFloatingToast] = useState(false);
    const [auxEmail, setAuxEmail] = useState(null);
    const [msgModal, openMsgModal] = useState(false);
    const [tieneClave, isLoadingTieneClave, errorTieneClave] = useVerificarClaveUsuario();
    const { HandleSwitchsStates } = useStore();

    useEffect(() => {
        fetchData();

        if(identitiesProviderData){
            auth0Callback()
        }
          
    }, [identitiesProviderData]);

    const auth0Callback = () => {
        const accessToken = Url.GetParam('access_token');
        if (accessToken) {
            switch (Url.GetParam('state')) {
                case Auth0Config.GoogleConnection:
                    linkGoogleAccountProcess(accessToken);
                    break;
                case Auth0Config.FacebookConnection:
                    linkFacebookAccountProcess(accessToken);
                    break;
                case Auth0Config.AppleConnection:
                    break;
                default:
                    // Acción por defecto si el valor de state no coincide con ninguno de los casos anteriores
                    break;
            }
        }
        Url.CleanHash();
    };

    const handleChangePassword = () => {
        history.push({
            pathname: PathsConfig.CambiarPassword,
            params: { user: user },
            state: {
                succes: true,
            },
        });
    };

    const handleDeleteEmail2 = () => {
        setMailSecundario('');
        setAuxEmail(user.usuario_detalle_email);
        setOpenModal(true);
    };

    const changeMailPrincipal = (mailPrincial) => {
        return ApiService.post(`${REACT_APP_APILOGIN}Auth0V1/ChangeUserEmail`, { NewEmail: mailPrincial })
            .then((data) => {
                Datadog.trackCustomEvent(CustomEventsDatadog.cambiarEmailPrincipal);
                return Promise.resolve(data);
            })
            .catch((error) => {
                Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCambiarEmailPrincipal);
                return Promise.reject(data);
            });
    };

    const handleShowModal = (values) => {
        var hayCambios = false;
        console.table(values);
        if (values.email != user.usuario_detalle_email) {
            // Se modificó mail principal así que hay que ir a confirmación con la data
            setMailPrincipal(values.email);
            hayCambios = true;
        }
        if (client && client.DatosPersonales && values.email2 != client.DatosPersonales.EMail) {
            setMailSecundario(values.email2);
            hayCambios = true;
        }

        if (!hayCambios) {
            showFloatingToast({ status: 'success', titulo: 'Cambios guardados' });
        } else {
            setOpenModal(true);
        }
    };

    const changeSecEmail = (params) => {
        return ApiService.post(`${REACT_APP_APIPAYWALL}Cliente/GuardarEmailCliente`, params)
            .then((data) => {
                if (data && data.code === '0000') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.cambiarEmailSecundario);
                    return Promise.resolve(data.response.email);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCambiarEmailSecundario);
                    showFloatingToast({ status: 'warning', titulo: data.message });
                    setIsLoading(false);
                    return Promise.reject(data.code);
                }
            })
            .catch((error) => {
                showFloatingToast({ status: 'alert', titulo: 'Ocurrió un error al intentar cambiar el email secundario' });
                setIsLoading(false);
                return Promise.reject(data.code);
            });
    };

    const prepareToast = (code, mailSecSucc) => {
        var msg = '';
        switch (code) {
            case '0285':
                msg = 'No se puede cambiar el email principal. Ese email ya fue registrado por otro usuario.';
                break;
            case '0195':
                msg = 'El email principal seleccionado corresponde a un usuario que ya esta registrado por redes sociales.';
                break;
            default:
                msg = 'Ocurrió un error al intentar realizar el cambio del email principal.';
                break;
        }
        msg = mailSecSucc ? msg + ' El email secundario fue cambiado correctamente.' : msg;
        showFloatingToast({ status: 'warning', titulo: msg });
    };

    const onConfirm = (mailPrincial, mailSecundario) => {
        setIsLoading(true);
        if (mailSecundario) {
            changeSecEmail({ email: mailSecundario })
                .then((mail2response) => {
                    if (mailPrincial) {
                        changeMailPrincipal(mailPrincial).then((mail1response) => {
                            if (mail1response.code === '0000') {
                                afterChangeMailPrincipal();
                            } else {
                                prepareToast(mail1response.code, true);
                                client.DatosPersonales.EMail = mail2response;
                                setIsLoading(false);
                                setMailPrincipal(null);
                            }
                        });
                    } else {
                        client.DatosPersonales.EMail = mail2response;
                        setMailSecundario(null);
                        showFloatingToast({ status: 'success', titulo: 'Email secundario modificado satisfactoriamente.' });
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    showFloatingToast({ status: 'warning', titulo: 'No se pudo cambiar el email secundario, vuelva a intentarlo más tarde.' });
                });
        } else {
            if (mailPrincial) {
                changeMailPrincipal(mailPrincial).then((mail1response) => {
                    if (mail1response.code === '0000') {
                        console.log(`mail principal cambiado`, mailPrincial);
                        setIsLoading(false);
                        afterChangeMailPrincipal();
                    } else {
                        prepareToast(mail1response.code, false);
                        setIsLoading(false);
                        setMailPrincipal(null);
                    }
                });
            }
        }
    };

    const afterChangeMailPrincipal = () => {
        openMsgModal(true);
    };

    const onLogOut = () => {
        _Logout.Logout(true);
    };

    const onFloatingToastClose = () => {
        showFloatingToast(null);
    };

    const linkGoogleAccount = () => {
        Auth0.AuthorizeAuth0(Auth0Config.GoogleConnection);
    };

    const linkFacebookAccount = () => {
        Auth0.AuthorizeAuth0(Auth0Config.FacebookConnection);
    };

    const linkAppleAccount = () => {
        Auth0.AuthorizeAuth0(Auth0Config.AppleConnection);
    };

    return (
        <>
            <div className="lay-strechted">
                <Breadcrumb firstItem={'Mi cuenta'} href={'#'} text={'Mi usuario'} />
                <TitleWeb text="Mi usuario" />
            </div>
            <div className="container-streched">
                <LoadingSpinner
                    isLoading={
                        isLoadingIdentityProviderData ||
                        userLoading ||
                        clientLoading ||
                        loading ||
                        isLoadingGoogle ||
                        isLoadingFacebook ||
                        isLoadingTieneClave
                    }
                >
                    {userError || clientError ? (
                        <Toast
                            status="alert"
                            title={
                                userError
                                    ? 'Hubo un error al obtener la información del usuario.'
                                    : 'Hubo un error al obtener la información del CRM.'
                            }
                        />
                    ) : (
                        user && (
                            <>
                                {floatingToast && (
                                    <Toast
                                        positionFloat={'center'}
                                        closeAuto={true}
                                        value={floatingToast != null}
                                        close
                                        changeValue={onFloatingToastClose}
                                        status={floatingToast.status}
                                        title={floatingToast.titulo}
                                    />
                                )}
                                {Embed ? (
                                    <Toast status="alert" title={'Atención'}>
                                        <Paragraph size="--s">
                                            Si necesitás cambiar tu email o contraseña, por favor, dirigite a la página web de LA NACION
                                        </Paragraph>
                                    </Toast>
                                ) : (
                                    <>
                                        <Formik
                                            validationSchema={MiUsuarioForm}
                                            initialValues={{
                                                email: user.usuario_detalle_email,
                                                email2:
                                                    client && client.DatosPersonales && client.DatosPersonales.EMail
                                                        ? client.DatosPersonales.EMail
                                                        : '',
                                            }}
                                            onSubmit={handleShowModal}
                                        >
                                            {(props) => (
                                                <Form noValidate>
                                                    <FormRow>
                                                        <FormCol>
                                                            <Label text={'Email principal'} />
                                                            <InputFormik
                                                                name="email"
                                                                type="email"
                                                                description="Esta es la dirección de email que verificamos y a la que nos comunicaremos con vos"
                                                                aria-label="Ingrese su email principal"
                                                            />
                                                        </FormCol>
                                                    </FormRow>
                                                    {/* Si no encontré el CRM o lo encontré y el correo no coincide con la información del usuario, mostrar input secundario */}
                                                    {client &&
                                                    client.DatosPersonales &&
                                                    user.usuario_detalle_email !== client.DatosPersonales.EMail ? (
                                                        <FormRow>
                                                            <FormCol>
                                                                <Label
                                                                    readOnly
                                                                    text={'Email secundario'}
                                                                    link={
                                                                        client &&
                                                                        client.DatosPersonales &&
                                                                        client.DatosPersonales.EMail && (
                                                                            <Link
                                                                                tabindex="0"
                                                                                ariaLabel="Eliminar mail secundario."
                                                                                onClick={handleDeleteEmail2}
                                                                            >
                                                                                Eliminar
                                                                            </Link>
                                                                        )
                                                                    }
                                                                />
                                                                <InputFormik
                                                                    name="email2"
                                                                    type="email"
                                                                    description="En este email podrás recibir información relacionada a Club LA NACION u otros productos"
                                                                    aria-label="Ingrese su email secundario"
                                                                />
                                                            </FormCol>
                                                        </FormRow>
                                                    ) : null}
                                                    <FormRow mod="--btn-groups">
                                                        <Button type="submit" tag="input" mod="state--mobile-w100" label="GUARDAR CAMBIOS" />
                                                    </FormRow>
                                                </Form>
                                            )}
                                        </Formik>

                                        {user.muestra_clave == 1 &&
                                            (!funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.CambiarContrasenia) ? (
                                                <>
                                                    <Divider />
                                                    <FormRow>
                                                        <InputGroup className="grid-75-25 --ai-end">
                                                            <FormCol>
                                                                <Label text={'Contraseña'} />
                                                                <Input
                                                                    type="password"
                                                                    disabled
                                                                    placeholder="●●●●●●●"
                                                                    state={{ status: '', msg: '' }}
                                                                />
                                                            </FormCol>
                                                            <FormCol>
                                                                <Button
                                                                    type="button"
                                                                    onClick={handleChangePassword}
                                                                    href={'#'}
                                                                    label="CAMBIAR"
                                                                    aria-label="Cambiar contraseña."
                                                                    isFirstPositioned={true}
                                                                    icon="edit-filled"
                                                                    mod="state--w100 --mb-0 --tertiary"
                                                                    iconSize="--threexs"
                                                                />
                                                            </FormCol>
                                                        </InputGroup>
                                                    </FormRow>
                                                </>
                                            ) : (
                                                <>
                                                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.CambiarContrasenia} />
                                                </>
                                            ))}
                                    </>
                                )}

                                {HandleSwitchsStates && HandleSwitchsStates?.SocialNetWorks && !identitiesProviderError && (
                                    <EmbedHide>
                                        <Divider />

                                        {(!user.googleProfile?.user_id || !user.facebookProfile?.user_id) && (
                                            <>
                                                <Paragraph size="--s" mod="--font-bold --arial --mb-8">
                                                    ¿Sabías que podés ingresar con tu usuario de redes sociales?
                                                </Paragraph>
                                                <Paragraph size="--twoxs" mod="--arial --mb-32">
                                                    Conectate con tus redes sociales a tu cuenta de LA NACION y, la próxima vez, ingresá sin
                                                    contraseña. No vamos a publicar nada en tus perfiles.
                                                </Paragraph>
                                            </>
                                        )}

                                        {user.googleProfile?.user_id && user.facebookProfile?.user_id && (
                                            <>
                                                <Paragraph size="--s" mod="--font-bold --arial --mb-8">
                                                    Recordá que podés ingresar con tu usuario de redes sociales
                                                </Paragraph>
                                            </>
                                        )}

                                        {HandleSwitchsStates?.facebook && (
                                            <Facebook
                                                identitiesProviderData={identitiesProviderData}
                                                showAlertToast={showAlertToast}
                                                linkFacebookAccount={linkFacebookAccount}
                                                unlinkFacebookAccount={unlinkFacebookAccountProcess}
                                            />
                                        )}

                                        {HandleSwitchsStates?.google && (
                                            <Google
                                                identitiesProviderData={identitiesProviderData}
                                                showAlertToast={showAlertToast}
                                                linkGoogleAccount={linkGoogleAccount}
                                                unlinkGoogleAccount={unlinkGoogleAccountProcess}
                                            />
                                        )}
                                    </EmbedHide>
                                )}

                                {/* 
                        // por el momento éste servicio no estará disponible
                        <CallbackIconTextButton
                            mod={"--ai-center"}
                            icon={ <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" fill="none"><path d="M12.4985 8.62918C11.3305 8.62918 9.5225 7.30118 7.61851 7.34918C5.1065 7.38118 2.8025 8.80518 1.5065 11.0612C-1.1015 15.5892 0.834504 22.2772 3.3785 25.9572C4.6265 27.7492 6.09851 29.7652 8.0505 29.7012C9.92251 29.6212 10.6265 28.4852 12.8985 28.4852C15.1545 28.4852 15.7945 29.7012 17.7785 29.6532C19.7945 29.6212 21.0745 27.8292 22.3065 26.0212C23.7305 23.9412 24.3225 21.9252 24.3545 21.8132C24.3065 21.7972 20.4345 20.3092 20.3865 15.8292C20.3545 12.0852 23.4425 10.2932 23.5865 10.2132C21.8265 7.63718 19.1225 7.34918 18.1785 7.28518C15.7145 7.09318 13.6505 8.62918 12.4985 8.62918ZM16.6585 4.85318C17.6985 3.60518 18.3865 1.86118 18.1945 0.133179C16.7065 0.197179 14.9145 1.12518 13.8425 2.37318C12.8825 3.47718 12.0505 5.25318 12.2745 6.94918C13.9225 7.07718 15.6185 6.10118 16.6585 4.85318Z" fill="black"/></svg> }
                            text={<> <Paragraph size="--twoxs" mod="--font-bold --arial --mb-8">Apple</Paragraph> <Paragraph size="--fourxs" mod="--arial --mb-8">Si no sos Nombre Apellido hacé clic aquí</Paragraph></>}
                            button={ <Button 
                                        href={"#"} 
                                        label="CONECTAR"
                                        mod="state--w100 --mb-0 --tertiary"
                                        isFirstPositioned= {true} />  }
                        />
                        */}
                            </>
                        )
                    )}
                </LoadingSpinner>

                {msgModal && <MsgModal setOpenModal={openMsgModal} onLogOut={onLogOut} />}
                {openModal && mailPrincipal && (
                    <ConfirmarCambioMail
                        setOpenModal={setOpenModal}
                        mailPrincipal={mailPrincipal}
                        mailSecundario={mailSecundario}
                        onConfirm={onConfirm}
                    />
                )}
                {openModal && (mailSecundario || mailSecundario === '') && !mailPrincipal && (
                    <ConfirmarCambioMailSecundario
                        setOpenModal={setOpenModal}
                        mailPrincipal={auxEmail}
                        mailSecundario={mailSecundario}
                        onConfirm={onConfirm}
                    />
                )}
            </div>
        </>
    );
};

export default MiUsuario;
