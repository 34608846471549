import Cookies from 'js-cookie'

const getEmbed = () => {
    
    if (Cookies.get('platform'))
    {
        return (true);
    }
    else
    {
        return false;
    }
}

const Embed = getEmbed();

export default Embed;