import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import stepsTarjeta from '../../../Config/StepsTarjeta';
import Modal from '../../../Components/Common/Organism/Modal';
import MiniCards from '../../../Components/Common/Atoms/MiniCards';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import Toast from '../../../Components/Common/Modules/Toast';
import Domicilios from '../../../Components/Features/Domicilios';
import useGenerarReclamoCredencial from '../../../Hooks/useGenerarReclamoCredencial';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';

const DomicilioTarjeta = (props) => {
    const stepProps = ['motivoSelected', 'credenciales'];
    const [errorReclamoCredencial, isLoadingReclamoCredencial, generarReclamoCredencial] = useGenerarReclamoCredencial();
    const { motivoSelected, credenciales } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoTarjeta);
    const [domicilioSelected, setDomicilio] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [confirmacionCambio, setConfirmacionCambio] = useState(false);
    const [errorFormDomicilios, setErrorFormDomicilios] = useState(false);
    const history = useHistory();
    
    const confirmarDomicilio = () => {
        if (!domicilioSelected) {
            setErrorFormDomicilios(true);
            return;
        }

        setConfirmacionCambio(!confirmacionCambio);
        setOpenModal(!openModal);
    };

    const generarReclamo = () => {
        setOpenModal(!openModal);

        const data = {
            AddressId: domicilioSelected,
            IdCredencialesAReclamar: credenciales.map((cred) => cred.IdCredencial),
            MotivoId: motivoSelected.Id,
        };

        const motivoReclamo = motivoSelected?.Nombre;

        generarReclamoCredencial(data, motivoReclamo);
    };

    return (
        <>
            <Breadcrumb firstItem="Mis reclamos" href="#" text="Credencial Club" />

            <TitleWeb text="Credencial Club" />
            <div className="container-streched">
                {errorReclamoCredencial && <Toast positionFloat="center" closeAuto={3000} status="warning" title={errorReclamoCredencial} />}
                <LoadingSpinner isLoading={isLoadingReclamoCredencial}>
                    <Wizard steps={stepsTarjeta} activeStep={2} />
                    <form action="#">
                        <fieldset>
                            <Domicilios setDomicilio={setDomicilio} errorForm={errorFormDomicilios} setErrorForm={setErrorFormDomicilios} />
                            <FormRow mod="--btn-groups --mb-32">
                                <Button mod="state--mobile-w100" onClick={confirmarDomicilio} label="CONTINUAR RECLAMO" />
                                <Button
                                    mod="tertiary"
                                    size="small"
                                    label="Volver"
                                    onClick={() => {
                                        history.push({
                                            pathname: PathsConfig.ReclamoTarjeta,
                                        });
                                    }}
                                />
                            </FormRow>
                            <Paragraph size="--fivexs" mod="--arial --font-regular --light-neutral-600 --mt-16">
                                La cobertura de envío es sólo para domicilios en Argentina.
                            </Paragraph>
                        </fieldset>
                    </form>
                </LoadingSpinner>
            </div>

            {openModal && (
                <Modal>
                    <>
                        <Paragraph size="--twoxs" mod="--arial --font-regular --mb-24">
                            Estás por inciar un reclamo con el motivo <b>"{motivoSelected.Nombre}"</b> de la credencial:
                        </Paragraph>
                        <div className="--mb-32">
                            {credenciales.map((credencial) => {
                                return (
                                    <div key={credencial.IdCredencial} className="--grid --ai-center --gt-40-1fr --mb-16">
                                        <MiniCards cardType={credencial.CategoriaCredencial.Descripcion} />
                                        <p>
                                            <b className="--ml-8">{credencial.NumeroCredencialFormateado}</b>
                                            <span className="--ml-8 --light-neutral-600 --flex-inline">{`${credencial.NombreCliente} ${credencial.ApellidoCliente}`}</span>
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <FormRow mod="--btn-groups --mb-32">
                            <Button
                                type="button"
                                mod="state--mobile-w100"
                                onClick={generarReclamo}
                                href={'#'}
                                label="CONFIRMAR"
                                isFirstPositioned={true}
                                className="state--mobile-w100 --mb-0"
                                iconSize="s"
                            />

                            <Button
                                type="button"
                                onClick={() => {
                                    setOpenModal(!openModal);
                                }}
                                href={'#'}
                                label="CANCELAR"
                                isFirstPositioned={true}
                                className="state--mobile-w100 --mb-0"
                                mod="secondary"
                                iconSize="s"
                            />
                        </FormRow>
                    </>
                </Modal>
            )}
        </>
    );
};

export default DomicilioTarjeta;
