import React from 'react'
import { useHistory } from 'react-router';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb'
import TitleWeb from '../../../Components/Common/Web/Title'
import { useStore } from '../../../Store/storeProvider'
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio'
import LoadingSpinner from '../../../Helpers/LoadingSpinner'
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary'
import { Text } from '@ln/common-ui-text';
import { Button } from '@ln/experiencias-ui-button';
import PathsConfig from '../../../Config/RoutePaths';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';

export const ConfirmacionBaja = (props) => {
  const { HandleSwitchsStates } = useStore();
  const history = useHistory();
  const stepProps = ['suscripcionABajar', 'motivo'];
  useRedirectWithout(props.location.state, stepProps, PathsConfig.MisSuscripciones);

  const handleVolver = () => {
    history.push({
      pathname: PathsConfig.MisSuscripciones,
    })
  }

  return (
    <>
      <section>
        <Breadcrumb firstItem={'Mis suscripciones'} href={'#'} text={'Solicitud de baja'} />
        <TitleWeb text="Solicitud de baja" />
      </section>
      <section className="container-fluid-streched arial">
        {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.BajaSuscripciones) ? (
          <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.BajaSuscripciones} />
        ) : (
          <LoadingSpinner isLoading={false}>
            <ErrorBoundary error={false}>
              <div className='flex flex-column gap-32 text-neutral-light-800'>
                <div className='flex flex-column gap-8'>
                  <Text as='h3' className='arial-bold text-20'>¡Lamentamos que hayas tomado esta decisión!</Text>
                  <Text as='p' className='text-16'>Nos estaremos contactando a la brevedad para continuar con este proceso.</Text>
                </div>
                <Groupbutton>
                  <Button title='Volver' onClick={handleVolver}>VOLVER</Button>
                </Groupbutton>
              </div>
            </ErrorBoundary>
          </LoadingSpinner>
        )}
      </section>

    </>
  )
}

export default ConfirmacionBaja;