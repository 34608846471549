import React, { useEffect, useState } from 'react';
import { Text } from '@ln/common-ui-text';
import Menu from '../Routes/Home';
import { CardBoxHome } from '../Components/Common/Modules/CardBoxHome';
import PromoNoSuscriptores from '../Components/Modals/PromoNoSuscriptores';
import { createCookieExpiresInDay, readCookie } from '../Helpers/Cookies';
import SessionHandler from "../Handlers/Session";
import getProductoPremium from '../Models/ProductoPremium';
import { useDispatch } from '../Store/storeProvider';

const Home = () => {
    let suscriber = getProductoPremium(readCookie("ProductoPremiumId"));
    const showModalPromoCookie = readCookie("show-modal-promo")
    const [showModalPromo, setShowModalPromo] = useState(false)
    const dispatch = useDispatch();

    const showModalNoSuscriptor = async () => {
        if (!suscriber) {
            await SessionHandler.Reload(dispatch);
            suscriber = getProductoPremium(readCookie('ProductoPremiumId'));
            if (!suscriber && !Boolean(showModalPromoCookie)) {
                setShowModalPromo(true);
                createCookieExpiresInDay('show-modal-promo', true);
            }
        }
    };

    useEffect(()=>{
        showModalNoSuscriptor();
    },[])

    return (
        <div className="gap-16 gap-24_md mt-24 mb-24">
            <div className="w-100 flex flex-column">
                <Text className="mb-16 prumo prumo-medium text-32 pb-4 text-center">Mi cuenta</Text>
                {/* <Text className="mb-16 prumo prumo-medium text-18 text-center">Descripción acá de dos líneas</Text> */}
            </div>
            <div className="grid grid-cols-8 grid-cols-12_md grid-cols-16_xl grid-row-gap home-grid">
                {Menu.map(({ GroupName, Icon, Childrens }) => {
                    return <CardBoxHome key={GroupName} icon={Icon} title={GroupName} links={Childrens} />;
                })}
            </div>
            {showModalPromo && (
                <PromoNoSuscriptores closeModal={()=>setShowModalPromo(false)} />
            )}
        </div>
    );
};

export default Home;
