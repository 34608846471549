import { useHistory } from "react-router";

//Utilizar para pasar props a un componente, en caso de que se acceda a este sin las props requeridas se redirecciona
// al path definido como redirectURL
const useRedirectWithout = (currentProps, requiredParams, redirectURL) => {
  if (missingPropInParams(currentProps, requiredParams)) {
    redirect(redirectURL);
    return [null];
  }

  return currentProps;
};

const missingPropInParams = (currentProps, obligatorios) => {
  if (!currentProps && obligatorios) {
    return true;
  }

  const existentes = Object.keys(currentProps);
  // busco por lo menos un item obligatorio que no se encuentre entre los existentes
  // si lo encuentro hay un faltante entre las props que tengo
  return obligatorios.filter((item) => !existentes.includes(item)).lengh > 0;
};

const redirect = (url) => {
  const history = useHistory();
  history.replace({
    pathname: url,
  });
};

export default useRedirectWithout;
