import React from 'react';
import Paragraph from '../../Common/Atoms/Paragraph';
import Button from '../../Common/Modules/Button';
import CallbackIconTextButton from '../../Common/Modules/CallbackIconTextButton';

const Google = ({ identitiesProviderData, showAlertToast, linkGoogleAccount, unlinkGoogleAccount }) => {

    const vinculadoGoogle = identitiesProviderData?.googleProfile ? true : false;
    const profileName = identitiesProviderData?.googleProfile?.display_name ?? '';
    const isPrimaryProfile = identitiesProviderData?.googleProfile?.is_primary;
    const nativeProfileExist = identitiesProviderData?.nativeProfile ? true : false;
    const shouldNativeLogin = nativeProfileExist && isPrimaryProfile;
    const shouldGoogleLogin = vinculadoGoogle && !identitiesProviderData?.googleProfile?.is_migrated;
    const handleUnlinkGoogle = () => {
        if(shouldNativeLogin){
            showAlertToast(`Para poder desvincular su perfil de Google debe iniciar sesión con su cuenta de email y contraseña: ${identitiesProviderData?.nativeProfile.display_name}`, 'alert')
        }
        else if (shouldGoogleLogin){
            showAlertToast(`Para poder desvincular su perfil de Google debe iniciar sesión con su cuenta de Google`, 'alert');
        }
        else{
            unlinkGoogleAccount();
        }
    }

    return (
        <CallbackIconTextButton
            mod={'--ai-center --mb-32'}
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.9999 16.3105C29.9999 15.1593 29.9047 14.3193 29.6986 13.4482H16.2988V18.6438H24.1642C24.0057 19.935 23.1494 21.8794 21.2464 23.1861L21.2197 23.3601L25.4564 26.5797L25.75 26.6085C28.4458 24.1662 29.9999 20.5728 29.9999 16.3105Z"
                        fill="#4285F4"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.2856 29.9998C20.1427 29.9998 23.3808 28.7553 25.746 26.6086L21.238 23.1863C20.0316 24.0107 18.4125 24.5863 16.2856 24.5863C12.5078 24.5863 9.30142 22.1441 8.15841 18.7686L7.99092 18.7825L3.58112 22.127L3.52344 22.2841C5.87264 26.8575 10.6982 29.9998 16.2856 29.9998Z"
                        fill="#34A853"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.15865 18.7597C7.8571 17.8895 7.6825 16.957 7.6825 15.9936C7.6825 15.0301 7.85702 14.0978 8.14279 13.2276L8.13482 13.0422L3.66982 9.64746L3.52376 9.71552C2.55552 11.6114 2 13.7403 2 15.9935C2 18.2468 2.55561 20.3756 3.52376 22.2715L8.15873 18.7596L8.15865 18.7597Z"
                        fill="#FBBC04"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.2856 7.41329C18.9681 7.41329 20.7776 8.5489 21.8094 9.49787L25.8412 5.64003C23.3651 3.38442 20.1427 2 16.2856 2C10.6982 2 5.87272 5.14223 3.52344 9.7156L8.14255 13.2313C9.30142 9.85571 12.5078 7.41338 16.2856 7.41338V7.41329Z"
                        fill="#EA4335"
                    />
                </svg>
            }
            text={
                <>
                    <Paragraph size="--twoxs" mod="--font-bold --arial">
                        Google
                    </Paragraph>
                    {vinculadoGoogle && (
                        profileName != '' ? (
                            <Paragraph size="--fourxs" mod="--arial --mb-8">
                                Conectado como: {profileName}
                            </Paragraph>
                        ) : (
                            <Paragraph size="--fourxs" mod="--arial --mb-8">
                                Cuenta ya vinculada
                            </Paragraph>
                        )
                    )}
                </>
            }
            button={
                (vinculadoGoogle && (!isPrimaryProfile || shouldNativeLogin || shouldGoogleLogin)) ? //vinculado: vinculada con gmail y que gmail no sea su perfil primario, y en caso de ser perfil primario y tieneuna cuenta de usuario y contraseña sin migrar. Permite desvincular.
                    <Button
                        onClick={handleUnlinkGoogle}
                        href={'#'}
                        label="DESVINCULAR"
                        className="state--w100 --mb-0"
                        mod="tertiary"
                        isFirstPositioned={true}
                    /> :
                    !vinculadoGoogle && //si no está vinculado
                    <Button
                        onClick={linkGoogleAccount}
                        href={'#'}
                        label="VINCULAR"
                        aria-label="Vincular con la cuenta de Google"
                        className="state--w100 --mb-0"
                        mod="tertiary"
                        isFirstPositioned={true}                     
                    />
            }
        />
    );
};

export default Google;