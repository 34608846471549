import { TipoVenta as TIPOVENTA } from "../Models/TipoVenta";
import { validacionesCambioPago } from "../Config/Suscripciones";

const esAptoParaBaja = (suscripcion) => {
    if (!suscripcion || suscripcion.CobroExterno || suscripcion.EventoHistorico || TIPOVENTA.Corporativa === suscripcion.TipoDeVenta || suscripcion.EsBonificada) {
        return false;
    };

    return validacionesCambioPago.includes(suscripcion.Estado);
};

const esExternoOHistorico = (suscripcion) => {
    return (suscripcion.CobroExterno != null || suscripcion.EventoHistorico)
};

export { esAptoParaBaja, esExternoOHistorico };
