import { domicilioCredencial } from "../Models/DomicilioCredencial"

export const domicilioCredencialMapper = (dataDomicilio, canalDeVenta, idCampania) =>{ 

    let domicilioToPost = domicilioCredencial

    if(dataDomicilio){ 
        domicilioToPost.IdCanalVenta = canalDeVenta,
        domicilioToPost.PlanId = idCampania,
        domicilioToPost.EditarDomicilio.addressId = dataDomicilio.Id,
        domicilioToPost.EditarDomicilio.altura = dataDomicilio.Altura,
        domicilioToPost.EditarDomicilio.calle = dataDomicilio.Calle,
        domicilioToPost.EditarDomicilio.cp = dataDomicilio.CodigoPostal,
        domicilioToPost.EditarDomicilio.cuerpo = dataDomicilio.Cuerpo,
        domicilioToPost.EditarDomicilio.dpto = dataDomicilio.Dpto,
        domicilioToPost.EditarDomicilio.entreCalle1 = dataDomicilio.EntreCalle1,
        domicilioToPost.EditarDomicilio.entreCalle2 = dataDomicilio.EntreCalle2,
        domicilioToPost.EditarDomicilio.localidad = dataDomicilio.Localidad,
        domicilioToPost.EditarDomicilio.lote = '',
        domicilioToPost.EditarDomicilio.normalizada = true,
        domicilioToPost.EditarDomicilio.observaciones = dataDomicilio.Observaciones,
        domicilioToPost.EditarDomicilio.pais = dataDomicilio.Pais,
        domicilioToPost.EditarDomicilio.piso = dataDomicilio.Piso,
        domicilioToPost.EditarDomicilio.provincia = dataDomicilio.Provincia,
        domicilioToPost.EditarDomicilio.tipoDePropiedad = dataDomicilio.NombreDePropiedad,
        domicilioToPost.EditarDomicilio.torre = dataDomicilio.Torre
    }

    return domicilioToPost
}