import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@ln/experiencias-ui-button'
import { Groupbutton } from '@ln/experiencias-ui-groupbutton'
import Label from '../../Common/Atoms/Form/Label'
import InputFormik from '../../Common/Formik/Input'
import { Form, Formik } from 'formik'
import FormActivarSuscripcionSchema from '../../../Validations/FormActivarSuscripcionSchema'
import Toast from '../../Common/Modules/Toast';
import { handleRedeemPinErrorMessage } from '../../../Helpers/getErrorMessage'

export const FormActivarSuscripcion = ({ redimirPin, pinResponse }) => {

  const [error, setError] = useState(false)
  const formikRef = useRef();
  const initialValuesFormik = {
    pin: '',
  };
  useEffect(()=>{
    if(pinResponse && pinResponse?.code !== "0000"){
      setError(true)
      formikRef.current.setFieldTouched('pin', true)
      formikRef.current.setFieldError('pin', handleRedeemPinErrorMessage(pinResponse?.code))
    }
  },[pinResponse])
  
  const onSubmitFormik = async (values) => {
    const payload = {
      ...values,
    };
      redimirPin(payload.pin)
  };

  return (
    <>
    {error && (
      <Toast
          positionFloat="right"
          closeAuto={9000}
          status="warning"
          value={error}
          changeValue={setError}
          title="¡Ups!"
          mod="z-1000"
      >
          Parece que hubo un problema, por favor, revisá nuevamente el código escrito
      </Toast>
  )}
    <Formik
      innerRef={formikRef}
      validationSchema={FormActivarSuscripcionSchema}
      initialValues={initialValuesFormik}
      onSubmit={onSubmitFormik}
      validateOnBlur={false}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ values }) => (
          <Form noValidate>
            <div className='mb-16'>
              <Label text="Código de activación" />
              <InputFormik
                name="pin"
                type="text"
                aria-label="Ingrese su código de activación"
              />
            </div>
            <Groupbutton>
              <Button title="ACTIVAR SUSCRIPCIÓN" disabled={values.pin === ""} type='submit'>ACTIVAR SUSCRIPCIÓN</Button>
            </Groupbutton>
          </Form>
      )}
    </Formik>
    </>
  )
}
