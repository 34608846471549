import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerFacturas = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [facturas, setFacturas] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(
                `${REACT_APP_APIPAYWALL}Comprobantes/ObtenerComprobantes`
            )
                .then((data) => {
                    if (data && data.code) {
                        const inv = data.response ? data.response : [];
                        if (data.code != '0000') {
                            setFacturas(inv);
                            setError(
                                `${data.message} - (codigo de error: ${data.code})`
                            );
                            setIsLoading(false);
                        } else {
                            setError(null);
                            setFacturas(inv);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [facturas, isLoading, error];
};

export default useObtenerFacturas;
