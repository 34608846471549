import React from 'react';
// import Logo from './Logo';
import '../../../Statics/Scss/Components/Spinner.scss';
import classNames from 'classnames';

const Spinner = ({ spinnerSmall, className, withBody}) => {
    const classes = classNames('com-spinner', className, {
		'--relative': withBody,
	});
    return (
        <div className={classes}>
            <div className="com-spinner__container">
                {spinnerSmall ? (
                    <div className="small">
                        <svg height="80" width="80">
                            <circle cx="40" cy="40" r="34" strokeWidth="4" />
                        </svg>
                        {/* <Logo className="ln" name="ln" href="#" /> */}
                        <svg
                            className="ln"
                            width="44.17"
                            height="21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 19.557V21h18.446v-8.36h-1.523v.904c0 4.774-3.228 6.013-7.274 6.013h-.725V1.442h3.393V0H0v1.442h3.08v18.115H0zm20.873 0V21h7.62v-1.443h-.598c-1.933 0-2.702-.289-2.702-1.988V4.09h.07L38.643 21h2.55V3.045c0-.749.65-1.603 2.23-1.603h.75V0h-7.636v1.442h.922c1.54 0 2.377.551 2.377 1.695v9.27h-.071L29.915 0H20.98v1.442h.527c1.161 0 2.347.924 2.347 2.348v13.623c0 1.817-1.01 2.144-2.085 2.144h-.896z"
                                fill="#006998"
                            />
                        </svg>
                    </div>
                ) : (
                    <div className="big">
                        <svg height="90" width="90">
                            <circle cx="45" cy="45" r="37" strokeWidth="4" />
                        </svg>
                        {/* <Logo className="ln" name="ln" href="#" /> */}
                        <svg
                            className="ln"
                            width="44.7"
                            height="21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 19.557V21h18.446v-8.36h-1.523v.904c0 4.774-3.228 6.013-7.274 6.013h-.725V1.442h3.393V0H0v1.442h3.08v18.115H0zm20.873 0V21h7.62v-1.443h-.598c-1.933 0-2.702-.289-2.702-1.988V4.09h.07L38.643 21h2.55V3.045c0-.749.65-1.603 2.23-1.603h.75V0h-7.636v1.442h.922c1.54 0 2.377.551 2.377 1.695v9.27h-.071L29.915 0H20.98v1.442h.527c1.161 0 2.347.924 2.347 2.348v13.623c0 1.817-1.01 2.144-2.085 2.144h-.896z"
                                fill="#006998"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Spinner;
