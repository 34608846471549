const provinciasMap = [
    {
        id: 'BUENOS AIRES',
        descripcion: 'BUENOS AIRES',
    },
    {
        id: 'CAPITAL FEDERAL',
        descripcion: 'CAPITAL FEDERAL',
    },
    {
        id: 'CATAMARCA',
        descripcion: 'CATAMARCA',
    },
    {
        id: 'CHACO',
        descripcion: 'CHACO',
    },
    {
        id: 'CHUBUT',
        descripcion: 'CHUBUT',
    },
    {
        id: 'CORDOBA',
        descripcion: 'CÓRDOBA',
    },
    {
        id: 'CORRIENTES',
        descripcion: 'CORRIENTES',
    },
    {
        id: 'ENTRE RIOS',
        descripcion: 'ENTRE RÍOS',
    },
    {
        id: 'FORMOSA',
        descripcion: 'FORMOSA',
    },
    {
        id: 'JUJUY',
        descripcion: 'JUJUY',
    },
    {
        id: 'LA PAMPA',
        descripcion: 'LA PAMPA',
    },
    {
        id: 'LA RIOJA',
        descripcion: 'LA RIOJA',
    },
    {
        id: 'MENDOZA',
        descripcion: 'MENDOZA',
    },
    {
        id: 'MISIONES',
        descripcion: 'MISIONES',
    },
    {
        id: 'NEUQUEN',
        descripcion: 'NEUQUEN',
    },
    {
        id: 'RIO NEGRO',
        descripcion: 'RÍO NEGRO',
    },
    {
        id: 'SALTA',
        descripcion: 'SALTA',
    },
    {
        id: 'SAN JUAN',
        descripcion: 'SAN JUAN',
    },
    {
        id: 'SAN LUIS',
        descripcion: 'SAN LUIS',
    },
    {
        id: 'SANTA CRUZ',
        descripcion: 'SANTA CRUZ',
    },
    {
        id: 'SANTA FE',
        descripcion: 'SANTA FÉ',
    },
    {
        id: 'SANTIAGO DEL ESTERO',
        descripcion: 'SANTIAGO DEL ESTERO',
    },
    {
        id: 'TIERRA DEL FUEGO',
        descripcion: 'TIERRA DEL FUEGO',
    },
    {
        id: 'TUCUMAN',
        descripcion: 'TUCUMAN',
    },
]

export default provinciasMap;