import React from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Button from '../../Common/Modules/Button';
import Toast from '../../Common/Modules/Toast';
import Text from '../../Common/Text/Text';

const ExitoOtorgarCredencial = ({ respOtorgar, cliente, domicilio, fechaEstimada }) => {
    const history = useHistory();
    return (
        <div>
            <Toast closeAuto={3000} status="success" title="¡Credencial otorgada con éxito!"></Toast>

            <Text tag="p" size="--s" font="arial" weight="bold" className="text-title">
                Conocé el detalle de la credencial otorgada
            </Text>

            <Text tag="p" size="2xs" font="arial">
                La credencial{' '}
                <strong>
                    {respOtorgar.NumeroCredencial} {cliente.TipoCredencial}
                </strong>{' '}
                se otorgó a <strong>{`${cliente.Nombre} ${cliente.Apellido}`}</strong> y se entregará en{' '}
                <strong>{`${domicilio.Calle}
                    ${domicilio.Altura}
                    ${domicilio.Localidad} 
                    ${domicilio.Provincia ? domicilio.Provincia : ''}`}</strong>{' '}
                con fecha estimada el <strong>{fechaEstimada}</strong>.
                <br />
                <br />
            </Text>

            <Button
                label="Ir a mis credenciales"
                className="button-otorgar"
                size="small"
                onClick={() => {
                    history.push({
                        pathname: PathsConfig.MisCredenciales,
                    });
                }}
            />
        </div>
    );
};

export default ExitoOtorgarCredencial;
