import React from 'react';
import classNames from 'classnames';

import '../../../../Statics/Scss/Components/Label.scss';

const Label = ({labelIcon, text, link, className, ariaLabel}) => {
	const classes = classNames('com-label', className);

	return (
		<div className={classes}>
			<label className="--font-bold" aria-label={ariaLabel}>
				{text}
				{labelIcon && <i className={labelIcon}></i>}
			</label>
			{link && <> {link} </>}
		</div>
	);
};

export default Label;
