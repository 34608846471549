import { useState, useEffect } from 'react';
import ApiService from '../Services/ApiService';

const useSuscripcionesConEstado = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [suscripciones, setSuscripciones] = useState();
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.get(`${REACT_APP_APIPAYWALL}Suscripcion/ObtenerSuscripcionesConEstado`)
                .then(data => {
                    if (data && data?.code === '0000') {
                        setSuscripciones(data.response.suscripciones);
                        setIsLoading(false);
                    } else if(data?.code === '0001'){
                        setError(data.message);
                        setIsLoading(false);
                    }
                    else {
                        setSuscripciones([]);
                        setError(data.message);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    setError(error);
                    setIsLoading(false);
                });
                //si error && !suscripciones: toast error al obtener suscr, si error y []: toast no tiene suscr
        };
        fetchData();
    }, []);

    return [suscripciones, error, isLoading];
};

export default useSuscripcionesConEstado;
