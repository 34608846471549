import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@ln/common-ui-icon';
import { Link } from '@ln/common-ui-link';
import { Text } from '@ln/common-ui-text';

export const CardBoxHome = ({ icon, title, links = [] }) => {
    return (
        <div className="col-span-8 col-span-4_sm text-center border border-2 border-all border-light-100 rounded-24 p-24 flex flex-column jc-start ai-center gap-16">
            <div className="flex flex-column ai-center gap-16">
                <Icon size={24} hasWrapper bgColor="#E6E6E6">
                    {icon}
                </Icon>
                <Text className="size-text__title">{title}</Text>
            </div>
            <div className="flex flex-column ai-center gap-8 text-blue-500 size-text__links">
                {links.map(({ Display, Path }, i) => {
                    if (Path.startsWith("http")) return (
                        <Link key={i} text={Display} href={Path} target='_blank'>
                            {Display}
                        </Link>
                    )

                    return (
                        <NavLink key={i} to={`/${Path}`}>
                            {Display}
                        </NavLink>
                    );
                })}
            </div>
        </div>
    );
};
