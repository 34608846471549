import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Toast from '../../Common/Modules/Toast';
import Modal from '../../../Components/Common/Organism/Modal';
import Button from '../../../Components/Common/Modules/Button';
import CredentialCardBox from '../../../Components/Common/Modules/CredentialCardBox';
import { EstadosCredencial } from '../../../Config/EstadoCredencial';
import { SituacionDeEntregaCredencial } from '../../../Config/SituacionEntregaCredencial';
import CardCredencialesPendientesDeOtorgar from '../../../Components/Features/MisCredenciales/CardCredencialesPendientesDeOtorgar';
import Link from '../../../Components/Common/Atoms/Link';
import PathsConfig from '../../../Config/RoutePaths';
import Text from '../../Common/Text/Text';
import { NavigateTo } from '../../../Helpers/NavigateTo';
import { readCookie } from '../../../Helpers/Cookies';

const Credenciales = ({ credencialesOtorgadas, adicional, gruposObtenidos, darDeBaja, dataRelacionClub }) => {
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const [credencialSeleccionada, setCredencialSeleccionada] = useState();
    const crmId = readCookie("Crm_id");

    const handleBajaCredencial = (credencial) => {
        let idTitular = credencial.CrmidTitular;
        let idCredencial = credencial.Crmid;
        let nroCredencial = credencial.NumeroCredencial;
        let tipoCredencial = dataRelacionClub?.response?.categoria;
        if (credencial) {
            darDeBaja(idTitular, idCredencial, nroCredencial, tipoCredencial)
                .then((success) => {
                    NavigateTo(history, PathsConfig.MisCredencialesSolicitudDeBaja, {
                        credencial: credencial,
                        success: success,
                    });
                })
                .catch(() => {
                    NavigateTo(history, PathsConfig.MisCredencialesSolicitudDeBaja, {
                        credencial: credencial,
                    });
                });
        }
        setOpenModal(false);
    };

    const onClickReclamar = (credencial) => {
        NavigateTo(history, PathsConfig.ReclamoTarjeta, {
            CredencialPreSelected: credencial,
        });
    };

    return (
        <>
            {adicional && (
                <Toast status="info" title="Tu cuenta no tiene permisos para administrar la credencial.">
                    Podes reclamar tu credencial en <Link href={PathsConfig.ReclamoEntrega} text="Reclamo de entrega"></Link>. Si necesitas realizar{' '}
                    una gestión diferente, deberás comunicarte con el titular del grupo de credenciales.
                </Toast>
            )}
            {openModal && (
                <Modal closeModal={() => setOpenModal(false)}>
                    <Text tag="p" size="2xs" font="arial">
                        Estás por eliminar la credencial Nro. <strong>{credencialSeleccionada.NumeroCredencial}</strong> de{' '}
                        <strong>{credencialSeleccionada.NombreTitular}</strong>. Al confirmar, podrás asignar la credencial a otra persona. Recordá{' '}
                        que la baja de credencial no implica la baja de la suscripción.
                        <div>
                            <br />
                        </div>
                    </Text>
                    <div className="buttons-modal">
                        <Button label="Confirmar" onClick={() => handleBajaCredencial(credencialSeleccionada)} />
                        <Button label="Cancelar" onClick={() => setOpenModal(false)} mod={'tertiary'} />
                    </div>
                </Modal>
            )}
            {adicional ? (
                <Text tag="p" size="--s" font="arial" weight="bold" className="mis-credenciales-text">
                    Mi Credencial
                </Text>
            ) : (
                <>
                    <CardCredencialesPendientesDeOtorgar gruposCredenciales={gruposObtenidos} />
                    <Text tag="p" size="--s" font="arial" weight="bold" className="mis-credenciales-text">
                        Credenciales otorgadas
                    </Text>{' '}
                </>
            )}
            {credencialesOtorgadas.map((cred, i) => (
                <CredentialCardBox
                    key={i}
                    className="--mb-8"
                    nombre={cred.NombreTitular}
                    tipoDeCredencial={cred.CategoriaCredencial}
                    vigencia={`${cred.VigenciaDesde} - ${cred.VigenciaHasta}`}
                    domicilioDeEntrega={cred.DomicilioDetalle}
                    estadoCredencial={EstadosCredencial[cred.IdEstado]}
                    situacionDeEntrega={SituacionDeEntregaCredencial[cred.IdSituacionDeEntrega]}
                    nroDeCredencial={cred.NumeroCredencial}
                    onClickBaja={() => {
                        setOpenModal(true);
                        setCredencialSeleccionada(cred);
                    }}
                    onClickReclamar={() => onClickReclamar(cred)}
                    esAdicional={adicional}
                    esCredTitular={cred.Crmid === crmId}
                />
            ))}
        </>
    );
};

export default Credenciales;
