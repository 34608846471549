import React from "react";

function PhoneModal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="104"
      fill="none"
      viewBox="0 0 96 104"
    >
      <path
        fill="#081FA0"
        d="M60.165.036L17.318 0c-5.146.018-9.005 5.316-9.005 10.05l-.036 84.873c0 2.412.938 4.681 2.644 6.388a8.984 8.984 0 006.388 2.653l42.856.036c4.959-.018 9.006-4.065 9.006-9.023l.035-85.9C69.197 4.1 65.15.045 60.166.036zM17.327 2.26l.018 1.019 42.82.035c3.172 0 5.754 2.591 5.754 5.763l-.036 85.9c0 3.162-2.573 5.735-5.762 5.744l-42.82-.035a5.704 5.704 0 01-4.066-1.689 5.716 5.716 0 01-1.68-4.074l.036-84.872c0-3.056 2.564-6.763 5.736-6.772V2.26z"
      ></path>
      <path
        fill="#FEFEFE"
        fillRule="evenodd"
        stroke="#126FFF"
        strokeWidth="3"
        d="M65.091 30.255l-49.039-4.932c-5.262-.528-9.977 3.2-10.51 8.312L2.505 62.72c-.487 4.633 2.631 8.839 7.14 9.979.463.117.944.202 1.433.252l49.043 4.931c5.26.53 9.978-3.2 10.509-8.313l3.039-29.083c.483-4.637-2.633-8.841-7.14-9.98a9.546 9.546 0 00-1.438-.251z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#126FFF"
        fillRule="evenodd"
        d="M58.011 51.996a1.386 1.386 0 01-1.164-1.64 4.295 4.295 0 00-.654-3.232 4.25 4.25 0 00-2.737-1.818 4.309 4.309 0 00-5.03 3.418c-.146.754-.89 1.216-1.62 1.1a1.383 1.383 0 01-1.09-1.627c.741-3.839 4.452-6.373 8.268-5.618a6.993 6.993 0 014.5 2.99 7.065 7.065 0 011.074 5.314 1.385 1.385 0 01-1.547 1.113zM30.1 48.445a1.386 1.386 0 01-1.164-1.64 4.295 4.295 0 00-.654-3.232 4.25 4.25 0 00-2.736-1.818c-2.304-.43-4.578 1.082-5.03 3.418-.146.754-.857 1.22-1.62 1.1a1.383 1.383 0 01-1.091-1.627c.742-3.839 4.444-6.375 8.269-5.618a6.993 6.993 0 014.5 2.989 7.066 7.066 0 011.074 5.316 1.385 1.385 0 01-1.547 1.112zM37.154 62.085a6.783 6.783 0 01-5.683-5.187 1.393 1.393 0 011.022-1.677 1.382 1.382 0 011.666 1.026 4.047 4.047 0 007.22 1.443c.45-.62 1.314-.76 1.932-.31.615.45.752 1.32.303 1.94a6.8 6.8 0 01-6.46 2.765z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#126FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M77.77 24.486l5.246-5.65M82.485 32.434l11.236-5.897M83.43 41.5l7 .735"
      ></path>
    </svg>
  );
}

export default PhoneModal;
