import React from 'react';
import DateHelper from './Date';

const sortObjectArrayByDate = (array, field, isDDMMYYYFormat) => {
    array?.sort(function(a, b){
        let dateA, dateB;
        if(isDDMMYYYFormat){
            dateA = DateHelper.convertStringDDMMYYYYToDate(a[field]);
            dateB = DateHelper.convertStringDDMMYYYYToDate(b[field]);
        }
        else{
            dateA = new Date(a[field]);
            dateB = new Date(b[field]);
        }
        return dateB - dateA;
    })

    return array;
};

export default sortObjectArrayByDate;
