import React, { useState } from "react";
import { useHistory } from "react-router";

import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import Wizard from "../../Components/Common/Modules/Wizard";
import steps from "../../Config/StepsMisCredenciales";
import LoadingSpinner from "../../Helpers/LoadingSpinner";
import Toast from "../../Components/Common/Modules/Toast";
import Text from "../../Components/Common/Text/Text";
import TitleWeb from "../../Components/Common/Web/Title";
import Link from "../../Components/Common/Atoms/Link";

import "../../Statics/Scss/Pages/OtorgarCredencial.scss";
import FormCrearUsuarioSchema from "../../Validations/FormCrearUsuarioSchema";
import useListarClientesPorDni from "../../Hooks/useListarClientesPorDni";
import useCrearCliente from "../../Hooks/useCrearCliente";
import DateHelper from "../../Helpers/Date";
import CrearUsuarioForm from "../../Components/Features/MisCredenciales/CrearUsuarioForm";
import PathsConfig from "../../Config/RoutePaths";
import useRedirectWithout from "../../Hooks/useRedirectWithout";

const cliente = {
  nombre: "",
  apellido: "",
  tipoDocumento: "DNI",
  numeroDocumento: "",
  correo: "",
  fechaCumpleanios: "",
  celular: "",
  particular: "",
  Sexo: "",
};

const CrearUsuario = (props) => {
  const vieneDeBoton = useRedirectWithout(props.location.state,
    ['vieneDeBoton'],
    PathsConfig.MisCredenciales);
  const history = useHistory();
  const [generoSelected, setGeneroSelected] = useState(null);
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [errorGenero, setErrorGenero] = useState(false);
  const [
    buscarClientes,
    clientesEncontrados,
    errorClientes,
    isLoadingClientes,
  ] = useListarClientesPorDni();
  const [
    clienteGenerado,
    errorCrearCliente,
    isLoadingCrearCliente,
    funcCrearCliente,
  ] = useCrearCliente();

  const handleSubmit = (props) => {
    setErrorGenero(generoSelected ? false : true);
    if (isPayloadReady(props)) {
      return;
    } else {
      let clienteCreado = {
        ...props.values,
        Sexo: generoSelected,
        fechaCumpleanios: fechaDesde,
      };

      let fechaCumple = DateHelper.changeDateStringFormat(
        clienteCreado.fechaCumpleanios.toISOString()
      );

      if (props.touched.numeroDocumento && !props.errors.numeroDocumento) {
        buscarClientes(clienteCreado.numeroDocumento).then((resp) => {
          if (!resp) {
            let payload = {
              Nombre: clienteCreado.nombre,
              Apellido: clienteCreado.apellido,
              TipoDocumento: clienteCreado.tipoDocumento,
              NumeroDocumento: clienteCreado.numeroDocumento,
              Sexo: clienteCreado.Sexo,
              TelefonoParticular: clienteCreado.particular,
              TelefonoCelular: clienteCreado.celular,
              EMail: clienteCreado.correo,
              FechaNacimiento: fechaCumple,
            };

            funcCrearCliente(payload)
              .then((resp) => {
                if (resp.Code == 0) {
                  history.push({
                    pathname: PathsConfig.MisCredencialesSeleccionarDomicilio,
                    state: {
                      cliente: resp.Response,
                    },
                  });
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      }
    }
  };

  const isPayloadReady = (props) => {
    return (
      !props.values.nombre ||
      !props.values.apellido ||
      !(props.values.celular || props.values.particular) ||
      !props.values.correo ||
      !props.values.numeroDocumento ||
      !generoSelected ||
      !fechaDesde
    );
  };

  const onChangeCalendario = (e) => {
    setFechaDesde(e);
  };

  const handleOnChange = (event) => {
    const genero = event.target.value;
    setErrorGenero(false);
    setGeneroSelected(genero);
  };

  const redirectBuscarUsuario = () => {
    history.push({
      pathname: PathsConfig.MisCredencialesBuscarCliente,
      state: vieneDeBoton
    });
  };

  return (
    <>
      <section>
        <Breadcrumb
          firstItem="Club LA NACION"
          text="Mis credenciales"
          href="#"
        />
        <TitleWeb text="Otorgar credencial" />
      </section>

      <section className="container-streched">
        <LoadingSpinner isLoading={isLoadingClientes || isLoadingCrearCliente}>
          <Wizard steps={steps} activeStep={1} />

          {errorCrearCliente && (
            <Toast
              closeAuto={3000}
              status="warning"
              title="Ocurrió un error al crear el cliente"
            >
              Intente nuevamente
            </Toast>
          )}

          {!!clientesEncontrados.length && (
            <Toast
            closeAuto={3000}
            status="warning"
            title="El cliente que estás intentando crear ya existe"
          >
            Para buscar un cliente existente, hace click en "buscá uno existente" e insertá el DNI de la persona a quien querés otorgarle la credencial.
          </Toast>
          )}

          <Text
            tag="p"
            size="--s"
            font="arial"
            weight="bold"
            className="text-title"
          >
            Completá el siguiente formulario con los datos de la nueva
            credencial
          </Text>

          <Text tag="p" size="2xs" font="arial" className="link-text">
            Creá un cliente nuevo o{" "}
            <Link onClick={redirectBuscarUsuario}>
              buscá uno existente.
            </Link>
          </Text>

          <CrearUsuarioForm
            FormCrearUsuarioSchema={FormCrearUsuarioSchema}
            cliente={cliente}
            fechaDesde={fechaDesde}
            onChangeCalendario={onChangeCalendario}
            errorGenero={errorGenero}
            handleOnChange={handleOnChange}
            generoSelected={generoSelected}
            handleSubmit={handleSubmit}
          />
        </LoadingSpinner>
      </section>
    </>
  );
};

export default CrearUsuario;
