import React, { useState } from "react";
import Breadcrumb from "../../../Components/Common/Modules/Breadcrumb";
import Button from "../../../Components/Common/Modules/Button";
import FormCol from "../../../Components/Common/Atoms/Form/FormCol";
import FormRow from "../../../Components/Common/Atoms/Form/FormRow";
import TitleWeb from "../../../Components/Common/Web/Title";
import RangePicker from "../../../Components/Common/Modules/RangePicker";
import DatePicker from "../../../Components/Common/Modules/DatePicker";
import Wizard from "../../../Components/Common/Modules/Wizard";
import Title from "../../../Components/Common/Atoms/Title";
import Modal from "../../../Components/Common/Organism/Modal";
import Paragraph from "../../../Components/Common/Atoms/Paragraph";
import steps from "../../../Config/StepsSuscripcion";
import { useHistory } from "react-router-dom";
import DateHelper from "../../../Helpers/Date";
import LoadingSpinner from "../../../Helpers/LoadingSpinner";
import ErrorBoundary from "../../../Components/Common/Organism/ErrorBoundary";
import useRedirectWithout from "../../../Hooks/useRedirectWithout";
import useSuscripcionesSuspenderPorVacaciones from "../../../Hooks/useSuscripcionesSuspenderPorVacaciones";
import useObtenerFechaLimiteSuspensionVacaciones from "../../../Hooks/useObtenerFechaLimiteSuspensionVacaciones";
import PathsConfig from "../../../Config/RoutePaths";

const CANT_DIAS_MIN_PERIODO_SUSP = 4;
const CANT_DIAS_MAX_PERIODO_SUSP = 90;
const anulacionSuspension = "Anulación de suspensión por vacaciones";

const SeleccionFechaSuspension = (props) => {
  const stepProps = ["suscripcionSeleccionado", "anulable"];
  const { suscripcionSeleccionado, anulable } = useRedirectWithout(
    props.location.state,
    stepProps,
    PathsConfig.SuspensionVacaciones
  );
  const history = useHistory();
  const [fechasLimite, errorFechasLimite, isLoadingFechas] =
    useObtenerFechaLimiteSuspensionVacaciones(suscripcionSeleccionado.Id);
  const [moreThanSevenDays] = useState(anulable);
  const [openModal, setOpenModal] = useState(false);
  const [alertaLimite, setAlertaLimite] = useState(false);
  const [periodoValido, setPeriodoValido] = useState(true);
  const [submitValido, setsubmitValido] = useState(true);
  const fechaMaximaDatepicker = DateHelper.addDaystoDate(90, fechasLimite?.FechaMinima)

  const [fechaInicioCompleta, setFechaInicioCompleta] = useState(
    suscripcionSeleccionado.SituacionSuspensionVacaciones?.FechaDesde &&
      anulable
  );

  const [fechaFinCompleta, setFechaFinCompleta] = useState(
    suscripcionSeleccionado.SituacionSuspensionVacaciones?.FechaHasta &&
      anulable
  );

  const [modificarSuspension, errorSuspension, isLoadingSuspension] =
    useSuscripcionesSuspenderPorVacaciones();

    const [startDate, setStartDate] = useState(
    suscripcionSeleccionado.SituacionSuspensionVacaciones?.FechaDesde &&
    suscripcionSeleccionado.SituacionSuspensionVacaciones
      .TipoDeGestionDeVacaciones !== anulacionSuspension
    ? new Date(
        suscripcionSeleccionado.SituacionSuspensionVacaciones.FechaDesde
      )
    : ""
);

const [endDate, setEndDate] = useState(
  suscripcionSeleccionado.SituacionSuspensionVacaciones?.FechaHasta &&
    suscripcionSeleccionado.SituacionSuspensionVacaciones
      .TipoDeGestionDeVacaciones !== anulacionSuspension
    ? new Date(
        suscripcionSeleccionado.SituacionSuspensionVacaciones.FechaHasta
      )
    : ""
);

const fechaDesdeSeleccionado = () => {
  if (
suscripcionSeleccionado.SituacionSuspensionVacaciones?.FechaDesde &&
      suscripcionSeleccionado.SituacionSuspensionVacaciones
        .TipoDeGestionDeVacaciones !== anulacionSuspension
    ) {
      let fechaDesdeSusp = getFechaDesdeSusp(
        suscripcionSeleccionado,
        startDate
      );
      return fechaDesdeSusp;
    }
  };
  const fechaHastaSeleccionado = () => {
    if (
      suscripcionSeleccionado.SituacionSuspensionVacaciones?.FechaHasta &&
      suscripcionSeleccionado.SituacionSuspensionVacaciones
        .TipoDeGestionDeVacaciones !== anulacionSuspension
    ) {
      let fechaHastaSusp = getFechaHastaSusp(suscripcionSeleccionado, endDate);
      return fechaHastaSusp;
    }
  };

  const validarPeriodo = (startDate, endDate) => {
    if (suscripcionSeleccionado?.TipoFamilia === "D") {
      let validacionPeriodo = DateHelper.esIntervaloFechasValido(
        startDate,
        endDate,
        CANT_DIAS_MIN_PERIODO_SUSP,
        CANT_DIAS_MAX_PERIODO_SUSP
      );
      setPeriodoValido(validacionPeriodo);
    }
  };

  const setearAlertas = (startDate, limite) => {
    if (startDate < limite) {
      setsubmitValido(true);
    } else {
      setAlertaLimite(false);
    }
  };

  const getFechaDesdeSusp = (suscripcionSeleccionado, startDate) => {
    return new Date(
      suscripcionSeleccionado.SituacionSuspensionVacaciones
        ? suscripcionSeleccionado.SituacionSuspensionVacaciones.FechaDesde
        : startDate
    );
  };

  const getFechaHastaSusp = (suscripcionSeleccionado, endDate) => {
    return new Date(
      suscripcionSeleccionado.SituacionSuspensionVacaciones
        ? suscripcionSeleccionado.SituacionSuspensionVacaciones.FechaHasta
        : endDate
    );
  };

  const changeStartDate = async (e) => {
    setStartDate(e);
    setFechaInicioCompleta(true);
    if (fechaFinCompleta) {
      setsubmitValido(true);
    }
    validarPeriodo(e, endDate);
    validaciones(e);
  };

  const changeEndDate = (e) => {
    setEndDate(e);
    setFechaFinCompleta(true);
    if (fechaInicioCompleta) {
      setsubmitValido(true);
    }
    validarPeriodo(startDate, e);
    validaciones(startDate);
  };

  const confirmarSuspension = () => {
    if (!fechaInicioCompleta || !fechaFinCompleta) {
      setsubmitValido(false);
      setAlertaLimite(false);
    } else if (
      !alertaLimite &&
      submitValido &&
      periodoValido &&
      startDate < endDate
    ) {
      crearSuspension(suscripcionSeleccionado.Id);
    }
  };

  const validaciones = (startDate) => {
    if (suscripcionSeleccionado?.TipoFamilia === "D") {
      let fechaActual = new Date();
      let fechaDesdeSusp = getFechaDesdeSusp(
        suscripcionSeleccionado,
        startDate
      );
      let limite = new Date();
      limite.setDate(fechaActual.getDate() + 7);
      limite.setHours(0, 0, 0, 0);

      if (
        (suscripcionSeleccionado.SituacionSuspensionVacaciones &&
          fechaDesdeSusp < limite) ||
        startDate < limite
      ) {
        setAlertaLimite(true);
      } else {
        setAlertaLimite(false);
      }
      if (!suscripcionSeleccionado.SituacionSuspensionVacaciones) {
        setAlertaLimite(startDate < limite);
      }
      setearAlertas(startDate, limite);
    }
  };

  const mensajesValidacion = () => {
    if (startDate > endDate) {
      return "Seleccioná un rango de fechas válido";
    } else if (alertaLimite) {
      return "Necesitamos que la suscripción se genere con 7 días de anticipación";
    } else if (!periodoValido && startDate <= endDate) {
      return "El período válido mínimo deberá ser de 5 dias y no podrá superar un máximo de 90 días al año";
    } else if (!submitValido) {
      return "Seleccioná el período en el que necesitas suspender tu suscripción";
    }
  };

  const crearSuspension = (id) => {
    startDate.setUTCHours(0,0,0,0);
    endDate.setUTCHours(0,0,0,0);
    let desde = startDate.toISOString();
    let hasta = endDate.toISOString();
    let model = {
      idSuscripciones: [id],
      fechaDesde: desde,
      fechaHasta: hasta,
    };
    const palabra = !anulable ? "generó" : "modificó";
    modificarSuspension(model, suscripcionSeleccionado, anulable, palabra);
  };

  return (
    <>
      <section className="lay-strechted">
        <Breadcrumb
          firstItem={"Mis suscripciones"}
          href={PathsConfig.SuspensionVacaciones}
          text={"Suspender por vacaciones"}
        />

        <TitleWeb text="Suspender por vacaciones" />
      </section>
      <section className="container-streched">
        <LoadingSpinner isLoading={isLoadingSuspension || isLoadingFechas}>
          <ErrorBoundary error={errorSuspension}>
            <Wizard steps={steps} activeStep={2} />
            <Title size="--s" className="--arial --font-bold --mb-24">
              Seleccioná la fecha a suspender por vacaciones{" "}
            </Title>

            <form action="#">
              <FormRow>
                <FormCol>
                  <RangePicker
                    errorMessage={mensajesValidacion()}
                    isInvalid={
                      startDate > endDate ||
                      alertaLimite ||
                      !periodoValido ||
                      !submitValido
                    }
                    initialDatePicker={
                      <DatePicker
                        label="Desde"
                        onChange={changeStartDate}
                        startDate={startDate}
                        endDate={endDate}
                        placeholder={startDate ? DateHelper.convertDate_ddMMyyyy(startDate) : ""}
                        minDate={new Date(fechasLimite.FechaMinima)}
                        maxDate={fechaMaximaDatepicker}
                        selected={fechaDesdeSeleccionado()}
                        isInvalid={
                          startDate > endDate ||
                          alertaLimite ||
                          !periodoValido ||
                          !submitValido
                        }
                        aria-label="Desde"
                        adjustDateOnChange={false}
                      />
                    }
                    finalDatePicker={
                      <DatePicker
                        label="Hasta"
                        onChange={changeEndDate}
                        placeholder={endDate ? DateHelper.convertDate_ddMMyyyy(endDate) : ""}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date(fechasLimite.FechaMinima)}
                        maxDate={fechaMaximaDatepicker}
                        selected={fechaHastaSeleccionado()}
                        isInvalid={
                          startDate > endDate ||
                          alertaLimite ||
                          !periodoValido ||
                          !submitValido
                        }
                        aria-label="Hasta"
                        adjustDateOnChange={false}
                      />
                    }
                  />
                </FormCol>

                <FormRow mod="--btn-groups">
                  <Button
                    mod="state--mobile-w100"
                    disabled={!startDate || !endDate}
                    label={
                      !moreThanSevenDays
                        ? "SUSPENDER POR VACACIONES"
                        : "MODIFICAR FECHA"
                    }
                    onClick={() => confirmarSuspension()}
                  />
                  {moreThanSevenDays && (
                    <Button
                      label="ANULAR SUSPENSIÓN"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      className="mod-button --font-bold --secondary"
                      mod="state--mobile-w100"
                    />
                  )}
                  <Button
                    label="Volver"
                    onClick={() => {
                      history.push({pathname: PathsConfig.SuspensionVacaciones});
                    }}
                    className="mod-button --font-bold --tertiary"
                    mod="state--mobile-w100"
                  />
                </FormRow>
              </FormRow>
            </form>
            {openModal && (
              <Modal
                closeModal={() => {
                  setOpenModal(false);
                }}
              >
                <Paragraph size="--twoxs" mod="--arial --font-regular --mb-32">
                  Estás anulando la suspención del producto{" "}
                  <strong>{suscripcionSeleccionado.NombreAmigable}</strong> por
                  vacaciones desde{" "}
                  <strong>
                    {DateHelper.convertDate_ddMMyyyy(
                      getFechaDesdeSusp(suscripcionSeleccionado, startDate)
                    )}
                  </strong>{" "}
                  hasta{" "}
                  <strong>
                    {DateHelper.convertDate_ddMMyyyy(
                      getFechaHastaSusp(suscripcionSeleccionado, endDate)
                    )}
                  </strong>
                  . ¿Deseás continuar?
                </Paragraph>
                <FormRow mod="--btn-groups">
                  <Button
                    label="CONTINUAR"
                    // mod="--mb-0"
                    mod="state--mobile-w100"
                    onClick={() =>
                      modificarSuspension(
                        {
                          idSuscripciones: [suscripcionSeleccionado.Id],
                        },
                        suscripcionSeleccionado,
                        anulable,
                        "anuló"
                      )
                    }
                  />
                  <Button
                    label="CANCELAR"
                    className="mod-button --font-bold --tertiary --mb-0"
                    mod="state--mobile-w100"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  />
                </FormRow>
              </Modal>
            )}
          </ErrorBoundary>
        </LoadingSpinner>
      </section>
    </>
  );
};

export default SeleccionFechaSuspension;
