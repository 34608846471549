import React from 'react';

import "../../../Statics/Scss/Modules/CallbackArticleLink.scss";

const CallBackArticleLink = ({title, descrip, link}) => {
    return (
        <div className="com-articleLink">
            {title &&
            <h5 className="--sixxs --mb-4">{title}</h5>
            }
            <div className="com-articleLink__descrip">
                {descrip &&
                <div className="com-articleLink__descrip__article"> {descrip} </div>
                }
                {link &&
                <div className="com-articleLink__descrip__link">  {link}  </div>
                }
            </div>
        </div>
    );
}
 
export default CallBackArticleLink;