import React from 'react';
import Divider from '../../../Components/Common/Atoms/Divider';
import MiniCards from '../../../Components/Common/Atoms/MiniCards';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import { useHistory } from 'react-router';

import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Button from '../../../Components/Common/Modules/Button';
import Toast from '../../../Components/Common/Modules/Toast';
import Wizard from '../../../Components/Common/Modules/Wizard';
import steps from '../../../Config/Steps';

import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import DateHelper from '../../../Helpers/Date';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';
import { useStore } from '../../../Store/storeProvider';
import ExperienceModal from '../../../Components/Modals/ExperienceModal';
import { origenesEncuesta } from '../../../Config/OrigenEncuesta'

const ConfirmacionTarjeta = (props) => {
    const stepProps = ['reclamoGenerado'];
    const { reclamoGenerado } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoTarjetaDomicilioEntrega);
    const history = useHistory();
    const { HandleSwitchsStates } = useStore();

    const redirectPasoUno = () => {
        history.push({ pathname: PathsConfig.ReclamoTarjeta });
    };

    return (
        <>
            <Breadcrumb firstItem="Mis reclamos" href="#" text="Credencial Club" />

            <TitleWeb text="Credencial Club" />
            <div className="container-streched">
                <Wizard steps={steps} activeStep={3} />

                <Toast status="success" title={'¡Reclamo generado con exito!'}>
                    Se ha generado un reclamo por el siguiente motivo: {reclamoGenerado.Motivo.Nombre} en credencial (
                    {reclamoGenerado.Credenciales[0].NombreCliente} {reclamoGenerado.Credenciales[0].ApellidoCliente})
                </Toast>

                <Paragraph size="--s" mod="--font-bold --arial --mb-8">
                    El número de gestión correspondiente es: <span className="--font-highlight">{reclamoGenerado.NroGestion}</span>
                </Paragraph>

                <Paragraph size="--twoxs" mod="--arial --mb-32">
                    Las nuevas credenciales emitidas son:
                </Paragraph>

                <div className="--mb-32">
                    {reclamoGenerado.Credenciales.map((credencial) => {
                        return (
                            <div key={credencial.IdCredencial} className="--grid --ai-center --gt-40-1fr --mb-16">
                                <MiniCards cardType={credencial.CategoriaCredencial.Descripcion} />
                                <p>
                                    <b className="--ml-8">{credencial.NumeroCredencialFormateado}</b>
                                    <span className="--ml-8 --light-neutral-600 --flex-inline">
                                        {credencial.NombreCliente} {credencial.ApellidoCliente}
                                    </span>
                                </p>
                            </div>
                        );
                    })}
                </div>

                <Paragraph size="--twoxs" mod="--arial --mb-32">
                    Fecha estimada de entrega: <b>{DateHelper.convertDate_ddMMyyyy(reclamoGenerado.FechaEntrega)}</b>
                </Paragraph>

                <Button
                    className="state--mobile-w100"
                    onClick={() => {
                        redirectPasoUno();
                    }}
                    label="GENERAR OTRO RECLAMO"
                />

                {HandleSwitchsStates && HandleSwitchsStates?.EncuestaReclamoTarjeta && <ExperienceModal nroGestion={reclamoGenerado.NroGestion} origenEncuesta={origenesEncuesta.reclamoTarjeta}/>}
            </div>
        </>
    );
};

export default ConfirmacionTarjeta;
