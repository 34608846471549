import React from 'react';

import Link  from '../Atoms/Link';
import Title from '../Atoms/Title';

import '../../../Statics/Scss/Components/Footer.scss';



const Footer = () => {
    return (
        <footer className="footer">
            <ul className="com-list --arial --twoxs">
                <li><Title>Ayuda:</Title></li>
                <li><Link target={'_blank'} href="https://www.contacto.lanacion.com.ar/ayuda" text="Preguntas frecuentes"/></li>
                <li><Link target={'_blank'} href="https://www.contacto.lanacion.com.ar/reglamento-participacion" text="Reglamento de participación"/></li>
                <li><Link target={'_blank'} href="https://www.contacto.lanacion.com.ar/tyc" text="Términos y condiciones generales"/></li>
                <li><Link target={'_blank'} href="https://www.contacto.lanacion.com.ar/tyc-acceso-digital" text="Términos y condiciones de Acceso Digital"/></li>
                <li><Link target={'_blank'} href="https://www.contacto.lanacion.com.ar/privacidad" text="Políticas de privacidad"/></li>
            </ul>
        </footer>
        
    );
}
 


export default Footer;