import React from 'react';

function LegalesUpselling() {
    return (
        <div className="legal">
            <p>
                Al continuar, estás aceptando{' '}
                <a href="https://micuenta.lanacion.com.ar/tyc" target="_blank">
                    términos y condiciones
                </a>
                .
            </p>
        </div>
    );
}

export default LegalesUpselling;
