const TipoMensaje = {
    "ok": 1,
    "warning": 2,
    "error": 3,
    "success": 4
}

const error = {
    'GENERAL': 'Se produjo un error. Por favor, volvé a intentarlo más tarde.',
    'GENERAL2': 'Se produjo un error inesperado. Por favor, volvé a intentar en unos minutos.'
};

const usuario = {
    'EXISTE': 'El Email ya existe',
    'LOGEATE_RED_SOCIAL': 'El usuario ya esta registrado por redes sociales. Iniciá sesión con Google o Facebook.',
    'DOMINIO_INVALIDO': 'El dominio está inhabilitado. Intentá con otro.',
    'INVALIDO': 'Ingresá un email válido',
    'CODIGO_CONFIRMACION': 'Te hemos enviado un nuevo código de confirmación.',
    'MAIL_MODIFICO_CORRECTO': 'Se modifico tu mail con exito.',
    'EXISTE_DOMICILIO': 'Los datos del domicilio que estás ingresando coinciden con otro domicilio que ya cargaste.',
    'CAPTCHA_EXPIRADO': 'La verificación ha caducado. Vuelve a marcar la casilla de verificación.',
    'CAPTCHA_REQUERIDO': 'Por favor, confirmá el captcha',
    'CLAVE_INCORRECTA': 'La contraseña actual no es correcta. Ingresá nuevamente.',
    'CLAVE_NUEVA_UTILIZADA': 'La nueva contraseña no puede ser igual a una contraseña utilizada previamente.',
    'SUSCRIPCION_NO_SELECTED': 'Debes seleccionar un producto.',
    'DOMICILIO_NO_SELECTED': 'Debés ingresar un nuevo domicilio o elegir uno de la lista.',
    'DOMICILIO_NO_VALIDATED': 'Es necesario que valides el domicilio antes de continuar.',
    'DOMICILIO_NORMALIZAR': 'Los datos ingresados no corresponden a un domicilio con cobertura de entrega.',
    'VACACIONES_SUSPENDER': 'Tu suspensión por vacaciones ha sido anulada correctamente.',
    'SERVICIOS_OK': 'Tu configuración se guardó correctamente.',
    'USUARIO_REPORTADO': 'Este Perfil ha sido reportado por un usuario y est&#225; a la espera de la revisi&#243;n del moderador.',
    'COMENTARIO_VACIO': 'Por favor complete con un comentario de consulta.',
    'WDS_DATOS_INVALIDOS': 'Los datos ingresados no son correctos. Por favor, ingresalos nuevamente.',
    'WDS_SOLICITUD_VENCIDA': 'Has excedido el tiempo de compra. Por favor, volvé a ingresar los datos de la forma de pago.',
    'NO_SUSCRIPTION_CHECKED': 'Seleccioná una suscripción.',
    'NO_MOTIVO_SELECTED': 'Necesitamos que selecciones un motivo de cambio.',
    'NO_MODO_PAGO_SELECTED': 'Seleccioná una tarjeta de crédito.',
    'BORRAR_EMAIL_SECUNDARIO': 'Modificaste tu informacion personal con exito.',
    'DATOS_PERSONALES_EXITO': 'Modificaste tu información personal con exito.',
    'FACTURAS_NO_TIENE': 'No tenés facturas para mostrar en esta cuenta.',
    'CONFIRMACION_BORRAR_MAIL_SECUNDARIO': 'Al borrarlo, todas las comunicaciones de Club LA NACION, se enviarán a tu email principal.',
    'NO_TIENE_SUSCRIPCIONES_A_BAJAR': 'Si ya solicitaste la baja de tu/s suscripciones recientemente, te pedimos que por favor aguardes. Nos contactaremos a la brevedad a través de los medios indicados en la solicitud.'
};

const credencial = {
    'ERROR_ASOCIAR': 'No se pudo vincular tu tarjeta correctamente.',
    'ERROR_NO_COINCIDEN': 'Los números de DNI y Tarjeta que ingresaste no coinciden.  Cargalos nuevamente. <br> ¿Tenés dudas? Llamanos al 5199-4787 o escribinos desde el exterior a <b>accesodigital@lanacion.com.ar</b> ',
    'ERROR_YA_ASOCIADO': 'La cuenta está creada con el email: <b>{{email}}</b> <br>' +
        'Si es tuya, ingresá con ese email.<br><br>' +
        '¿Tenés dudas? Llamanos al 5199-4787 o escribinos desde el exterior a <b>accesodigital@lanacion.com.ar</b> <br><br>' +
        '<a href="https://goo.gl/K3gZWY" target="_blank">¿cómo desvincular la credencial para usarla en mi nueva cuenta?</a>',
    'DNI_INCOMPLETO': 'Revisá que el número de documento esté completo',
    'INCORRECTA': 'Credencial incorrecta'
}

const procesando = {
    'CAMBIO_EMAIL_PROCESANDO': 'Estamos procesando tu cambio de email.',
    'CAMBIO_EMAIL_PROCESANDO_SECUNDARIO': 'Estamos procesando tu cambio de email. En unos instantes regresamos.',
    'CAMBIO_CLAVE_PROCESANDO': 'Estamos procesando tu cambio de contraseña. Ya volvemos.',
    'CREDENCIAL_VINCULAR': 'Estamos vinculando tu tarjeta a tu cuenta. Sólo nos tomará unos instantes.',
    'CREDENCIAL_DESVINCULAR': 'Estamos desvinculando tu tarjeta de tu cuenta. Sólo nos tomará unos instantes.',
    'PROCESSING': 'Procesando..',
    'REPORTANDO_USUARIO': 'Estamos procesando tu reporte. Danos un momento y te contamos como seguimos.'
}

var validacion = {
    'REQUIRED': 'Campo obligatorio',
    'EMAIL': 'El email es inválido',
    'LOCALIDAD': 'Completá la localidad, es obligatorio',
    'PROVINCIA': 'Completá la provincia, es obligatorio',
    'PARTIDO': 'Completá el partido, es obligatorio',
    'DIGITS': 'Número inválido',
    'CODIGO_POSTAL': 'Completá el código postal, es obligatorio',
    'CP': 'Debe ser numerico.',
    'CP_EXT': 'Código postal inválido',
    'STREET': 'Solo acepta numeros y letras',
    'NUMERO_INVALIDO': 'Numero invalido'
}


const getError = (msg, formatOptions) => {
    if (!msg)
        return '';
    return replaceFormat(error[msg.toUpperCase()], formatOptions);
}
const getUsuario = (msg, formatOptions) => {
    if (!msg)
        return '';
    return replaceFormat(usuario[msg.toUpperCase()], formatOptions);
}
const getCredencial = (msg, formatOptions) => {
    if (!msg)
        return '';
    return replaceFormat(credencial[msg.toUpperCase()], formatOptions);
}
const getProcesando = (msg, formatOptions) => {
    if (!msg)
        return '';
    return replaceFormat(procesando[msg.toUpperCase()], formatOptions);
}
const getValidacion = (msg, formatOptions) => {
    if (!msg)
        return '';
    return replaceFormat(validacion[msg.toUpperCase()], formatOptions);
}

const replaceFormat = (msg, formatOptions) => {
    if (!formatOptions) return msg;
    for (var key in formatOptions) {
        msg = msg.replace('{{' + key + '}}', formatOptions[key]);
    }
    return msg;
}

export default { getCredencial, getError, getProcesando, getUsuario, TipoMensaje, getValidacion }
