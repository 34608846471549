import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Toast from '../../../Components/Common/Modules/Toast';
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import Suscripcion from '../../../Components/Features/ReclamoEntrega/Suscripcion';
import Motivos from '../../../Components/Features/Motivos';
import steps from '../../../Config/Steps';
import useObtenerSuscripcionesReclamos from '../../../Hooks/useObtenerSuscripcionesReclamos';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import useObtenerMotivos from '../../../Hooks/useObtenerMotivosReclamo';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';
import '../../../Statics/Scss/Pages/Motivo.scss';
import ToastEmptyState from '../../../Components/Common/Banners/ToastEmptyState';
import { useStore } from '../../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';

const Motivo = (props) => {
    const history = useHistory();
    const suscripcionSeleccionado = props.location.state?.suscripcionSeleccionado;
    const [suscripReclamo, errorSuscripReclamo, isLoadingSucripReclamo] = useObtenerSuscripcionesReclamos();
    const [motivosReclamo, errorMotivo, isLoadingMotivos, getMotivos] = useObtenerMotivos();
    const [suscripSelected, setSuscripSelected] = useState(null);
    const [motivoSelected, setMotivoSelected] = useState(null);
    const [valMotivo, setValMotivo] = useState(null);
    const { HandleSwitchsStates } = useStore();

    const redirectPasoDos = (suscripcion, motivo) => {
        history.push({
            pathname: PathsConfig.ReclamoEdiciones,
            state: {
                suscripcionSelected: suscripcion.Id,
                motivoSelected: motivo,
            },
        });
    };

    const redirect = (suscripcion, motivo) => {
        if (!motivo) {
            setValMotivo({ status: 'error', msg: 'Seleccioná un motivo de reclamo' });
        } else {
            redirectPasoDos(suscripcion, motivo);
        }
    };

    useEffect(() => {
        if (suscripReclamo.length) {
            getMotivos(suscripcionSeleccionado ? suscripcionSeleccionado.Id : suscripReclamo[0].Id);
            setSuscripSelected(suscripcionSeleccionado ? suscripReclamo.find((sus) => suscripcionSeleccionado.Id === sus.Id) : suscripReclamo[0]);
        }
    }, [suscripReclamo]);

    const handleSusSelected = (value) => {
        if (suscripSelected != value.Id) {
            setSuscripSelected(value);
            getMotivos(value.Id);
        }
    };

    const handleMotivoSelected = (value) => {
        if (isNaN(value)) {
            setMotivoSelected(null);
            setValMotivo({ status: 'error', msg: 'Seleccioná un motivo de reclamo' });
        } else {
            setMotivoSelected(value);
            setValMotivo(null);
        }
    };

    return (
        <div className="motivo">
            <ErrorBoundary error={errorSuscripReclamo || errorMotivo}>
                <section>
                    <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                    <TitleWeb text="Nuevo reclamo de entrega" />
                </section>
                <section className="container-streched">
                    {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.ReclamoSuscripcion) ? (
                        <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.ReclamoSuscripcion}  />
                    ) : (
                        <LoadingSpinner isLoading={isLoadingSucripReclamo || isLoadingMotivos}>
                            {errorSuscripReclamo || errorMotivo ? (
                                <ErrorGenerico />
                            ) : !suscripReclamo.length ? (
                                <ToastEmptyState
                                    titulo={'No tenés suscripciones editoriales activas por reclamar (diarios y/o revistas).'}
                                    bajada={
                                        'No se encontraron suscripciones (o despachos) editoriales para solicitar un reclamo de entrega a domicilio'
                                    }
                                />
                            ) : (
                                <>
                                    <Wizard steps={steps} activeStep={1} />

                                    <CallbackArticle
                                        title="Seleccioná la suscripción y motivo del reclamo"
                                        description="Queremos solucionar tu problema, completá el siguiente formulario."
                                    />

                                    <FormRow>
                                        <FormCol>
                                            {suscripReclamo && (
                                                <Suscripcion
                                                    susSelected={!suscripSelected ? suscripReclamo[0].Id : suscripSelected.Id}
                                                    suscripciones={suscripReclamo}
                                                    onSuscripSelected={handleSusSelected}
                                                />
                                            )}
                                        </FormCol>
                                    </FormRow>
                                    <FormRow>
                                        <FormCol>
                                            {motivosReclamo && motivosReclamo.length > 0 && (
                                                <Motivos
                                                    hasValidationError={valMotivo}
                                                    motivos={motivosReclamo}
                                                    setMotivoSelected={handleMotivoSelected}
                                                />
                                            )}
                                        </FormCol>
                                    </FormRow>
                                    <FormRow mod="--btn-groups">
                                        <Button
                                            mod="state--mobile-w100"
                                            label="CONTINUAR RECLAMO"
                                            onClick={() => redirect(suscripSelected, motivoSelected)}
                                        />
                                    </FormRow>
                                </>
                            )}
                        </LoadingSpinner>
                    )}
                </section>
            </ErrorBoundary>
        </div>
    );
};

export default Motivo;
