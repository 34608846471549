import React, { useState } from 'react'
import ApiService from '../Services/ApiService';
import Datadog from '../Helpers/Datadog';

export default function useDescargarLibreDeuda() {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const outFunc = async () => {
        setIsLoading(true);
        const pathUrl = `${REACT_APP_APIPAYWALL}DescargaPdfV1/DescargarLibreDeuda`;
        return ApiService.getPdf(pathUrl)
            .then((blob) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([blob], {
                        type: 'application/pdf',
                        encoding: 'UTF-8'
                    }))
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'LibreDeuda');
                document.body.appendChild(link);
                link.click();
                link.remove();              
                setIsLoading(false);
                return Promise.resolve(blob);
            })
            .catch((err) => {
                Datadog.trackCustomEventError("DescargarLibreDeuda", err);
                console.log(err)
                setIsLoading(false);
                setError(true);
                return Promise.reject(error);
            });
    };


    return [outFunc, error, isLoading];
};
