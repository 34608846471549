import React     from "react";
import PropTypes from "prop-types";
import Icon      from "./Icon";
import "../../../Statics/Scss/Components/WizardItem.scss";


const WizardItem = ({ text, stepNumber, mod }) => {
    const className = `com-wizard-item${` ${mod}` || ``}`

    return(
        <div className={className}>
            <div className="com-wizard-item__step-n">{(mod && mod.includes('done')) ? <Icon name="check-mark" size="3xs" /> : String(stepNumber)}</div>
            <p className="com-wizard-item__text">{text}</p>
        </div>
    );
}


WizardItem.propTypes = {
    text:       PropTypes.string,
    stepNumber: PropTypes.number,
    mod:        PropTypes.string,
}


export default WizardItem;