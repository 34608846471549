import React from 'react';
const IconMsgDeuda = () => {
    return (
        <svg
            width="158"
            height="160"
            viewBox="0 0 158 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M157.109 31.1545V145.572C157.107 149.398 155.586 153.066 152.881 155.772C150.176 158.477 146.507 159.998 142.681 160H45.2654C41.4394 159.998 37.7706 158.477 35.0652 155.772C32.3598 153.066 30.8389 149.398 30.8369 145.572V14.4285C30.8369 10.6018 32.3571 6.93189 35.0629 4.22602C37.7688 1.52015 41.4388 0 45.2654 0H126.728L157.109 31.1545Z"
                fill="url(#paint0_linear_1069_36743)"
            />
            <path
                d="M142.811 17.4L126.728 0H45.2654C41.4388 0 37.7688 1.52015 35.0629 4.22602C32.3571 6.93189 30.8369 10.6018 30.8369 14.4285V17.8978L142.811 17.4Z"
                fill="url(#paint1_linear_1069_36743)"
                fill-opacity="0.7"
            />
            <path
                d="M155.906 31.6905H141.424C139.449 31.6975 137.491 31.3155 135.664 30.5661C133.836 29.8167 132.174 28.7147 130.772 27.323C129.371 25.9313 128.257 24.2771 127.495 22.4549C126.732 20.6327 126.336 18.6782 126.329 16.7029V1.34011C126.329 1.20695 126.369 1.07685 126.444 0.966585C126.518 0.856317 126.624 0.770941 126.748 0.721486C126.872 0.672031 127.007 0.660753 127.137 0.689116C127.267 0.717479 127.386 0.784172 127.478 0.8806L156.388 30.5647C156.478 30.6591 156.539 30.7779 156.562 30.9062C156.585 31.0345 156.57 31.1668 156.518 31.2867C156.467 31.4065 156.382 31.5087 156.273 31.5805C156.164 31.6522 156.036 31.6905 155.906 31.6905Z"
                fill="#1076E6"
            />
            <path
                d="M45.1959 12.5431C46.8158 12.5431 48.1291 11.2299 48.1291 9.60994C48.1291 7.98999 46.8158 6.67676 45.1959 6.67676C43.5759 6.67676 42.2627 7.98999 42.2627 9.60994C42.2627 11.2299 43.5759 12.5431 45.1959 12.5431Z"
                fill="#1076E6"
            />
            <path
                d="M53.9947 12.5431C55.6147 12.5431 56.9279 11.2299 56.9279 9.60994C56.9279 7.98999 55.6147 6.67676 53.9947 6.67676C52.3748 6.67676 51.0615 7.98999 51.0615 9.60994C51.0615 11.2299 52.3748 12.5431 53.9947 12.5431Z"
                fill="#1076E6"
            />
            <path
                d="M62.7945 12.5431C64.4145 12.5431 65.7277 11.2299 65.7277 9.60994C65.7277 7.98999 64.4145 6.67676 62.7945 6.67676C61.1746 6.67676 59.8613 7.98999 59.8613 9.60994C59.8613 11.2299 61.1746 12.5431 62.7945 12.5431Z"
                fill="#1076E6"
            />
            <path
                opacity="0.42"
                d="M133.276 109.102H51.2001C48.0617 109.102 45.5176 111.646 45.5176 114.784V118.23C45.5176 121.369 48.0617 123.913 51.2001 123.913H133.276C136.414 123.913 138.958 121.369 138.958 118.23V114.784C138.958 111.646 136.414 109.102 133.276 109.102Z"
                fill="#1076E6"
            />
            <path
                opacity="0.42"
                d="M133.276 129.956H51.2001C48.0617 129.956 45.5176 132.5 45.5176 135.639V139.085C45.5176 142.223 48.0617 144.767 51.2001 144.767H133.276C136.414 144.767 138.958 142.223 138.958 139.085V135.639C138.958 132.5 136.414 129.956 133.276 129.956Z"
                fill="#1076E6"
            />
            <path
                opacity="0.42"
                d="M56.9133 97.7134C63.207 97.7134 68.3091 92.6113 68.3091 86.3176C68.3091 80.0239 63.207 74.9219 56.9133 74.9219C50.6196 74.9219 45.5176 80.0239 45.5176 86.3176C45.5176 92.6113 50.6196 97.7134 56.9133 97.7134Z"
                fill="#1076E6"
            />
            <path
                opacity="0.42"
                d="M91.3215 97.7134C97.6152 97.7134 102.717 92.6113 102.717 86.3176C102.717 80.0239 97.6152 74.9219 91.3215 74.9219C85.0278 74.9219 79.9258 80.0239 79.9258 86.3176C79.9258 92.6113 85.0278 97.7134 91.3215 97.7134Z"
                fill="#1076E6"
            />
            <path
                opacity="0.42"
                d="M127.57 97.7134C133.863 97.7134 138.965 92.6113 138.965 86.3176C138.965 80.0239 133.863 74.9219 127.57 74.9219C121.276 74.9219 116.174 80.0239 116.174 86.3176C116.174 92.6113 121.276 97.7134 127.57 97.7134Z"
                fill="#1076E6"
            />
            <g filter="url(#filter0_d_1069_36743)">
                <path
                    d="M19.1725 35.1152H61.8275C65.7189 35.1152 69.4509 36.6611 72.2025 39.4127C74.9541 42.1644 76.5 45.8963 76.5 49.7877V102.913C76.5002 103.366 76.3759 103.811 76.1406 104.198C75.9053 104.585 75.5681 104.901 75.1657 105.109C74.7634 105.318 74.3114 105.412 73.8591 105.382C73.4069 105.351 72.9717 105.197 72.6013 104.935L61.6284 97.1589C60.2891 96.2115 58.689 95.7025 57.0485 95.7021H19.1725C15.2811 95.7021 11.5491 94.1563 8.79749 91.4047C6.04587 88.6531 4.5 84.921 4.5 81.0296V49.7877C4.5 45.8963 6.04587 42.1644 8.79749 39.4127C11.5491 36.6611 15.2811 35.1152 19.1725 35.1152Z"
                    fill="white"
                />
            </g>
            <path
                d="M40 46C35.9455 46 31.982 47.173 28.6108 49.3706C25.2396 51.5682 22.612 54.6918 21.0604 58.3463C19.5088 62.0009 19.1029 66.0222 19.8939 69.9018C20.6849 73.7814 22.6373 77.3451 25.5043 80.1421C28.3713 82.9392 32.024 84.844 36.0006 85.6157C39.9772 86.3874 44.0991 85.9913 47.845 84.4776C51.5909 82.9638 54.7925 80.4004 57.0451 77.1114C59.2977 73.8224 60.5 69.9557 60.5 66C60.5 60.6957 58.3402 55.6086 54.4957 51.8579C50.6512 48.1071 45.4369 46 40 46Z"
                fill="#000044"
            />
            <path
                d="M32.5 70.6667H35.5C35.5 72.3467 37.555 73.7778 40 73.7778C42.445 73.7778 44.5 72.3467 44.5 70.6667C44.5 68.9556 42.94 68.3333 39.64 67.5089C36.46 66.6844 32.5 65.6578 32.5 61.3333C32.5 58.5489 34.705 56.1844 37.75 55.3911V52H42.25V55.3911C45.295 56.1844 47.5 58.5489 47.5 61.3333H44.5C44.5 59.6533 42.445 58.2222 40 58.2222C37.555 58.2222 35.5 59.6533 35.5 61.3333C35.5 63.0444 37.06 63.6667 40.36 64.4911C43.54 65.3156 47.5 66.3422 47.5 70.6667C47.5 73.4511 45.295 75.8156 42.25 76.6089V80H37.75V76.6089C34.705 75.8156 32.5 73.4511 32.5 70.6667Z"
                fill="#00FFFC"
            />
            <defs>
                <filter
                    id="filter0_d_1069_36743"
                    x="0.5"
                    y="33.1152"
                    width="80"
                    height="78.2725"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1069_36743"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1069_36743"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1069_36743"
                    x1="137.297"
                    y1="165.338"
                    x2="41.7272"
                    y2="-0.19146"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#000044" />
                    <stop offset="1" stop-color="#3857FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1069_36743"
                    x1="30.8369"
                    y1="8.94505"
                    x2="142.819"
                    y2="8.94505"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00FFFF" />
                    <stop offset="1" stop-color="#0081FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default IconMsgDeuda;
