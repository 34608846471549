const Capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export const obtenerIniciales = (usuarioNombre, usuarioApellido, usuarioEmail) => { 
    let iniciales = ''
    if (usuarioNombre && usuarioApellido) {
        iniciales = (usuarioNombre[0] + usuarioApellido[0]).toUpperCase();
    } else {
        if (usuarioEmail) {
            iniciales = usuarioEmail.slice(0, 2).toUpperCase();
        }
    }

    return iniciales
}

export default { Capitalize }