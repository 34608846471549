import React from "react";
import classNames from "classnames";

const DrawerHeader = ({ children, className, ...r }) => {
  const classes = classNames("drawer-header", className);

  return <header className={classes}>{children}</header>;
};

export default DrawerHeader;
