import React from "react";
import EmbedHide from "../Organism/EmbedHide";
import CallbackArticle from "../Modules/CallbackArticle";
import Link from "../Atoms/Link";
import imgPromo2 from "../../../Statics/Imgs/image-promo-2.png";
import { telefonoMiClubCallcenter } from "../../../Config/DatosDeContacto";

const IncentivarAgregarClubBanner = () => {
    const EnvMiClub = process.env.REACT_APP_MICLUB;

    return (
        <EmbedHide>
            <CallbackArticle
                title="¡Agregá Club LA NACIÓN a tu suscripción!"
                imageUrl={imgPromo2}
                description={
                    <p className="--twoxs"> Dejanos tus datos y empezá a disfrutar de los beneficios. <br />
                        <Link
                            target="_blank"
                            href={`${EnvMiClub}suscribirme/callcenter?utm_source=micuenta&utm_medium=online`}
                        >
                            Completá el formulario
                        </Link> o llamanos al <Link link="#">{telefonoMiClubCallcenter}</Link>.
                    </p>
                }
            />
        </EmbedHide>
    );
};

export default IncentivarAgregarClubBanner;