import { useState, useEffect } from 'react';

const getWidth = () =>     window.innerWidth 
                        || document.documentElement.clientWidth 
                        || document.body.clientWidth;

export const useWindowSize = () => {

    let [width, setWidth] = useState(getWidth());

    useEffect(() => {

        let timeoutId = null;

        let isMounted = true;

        const resizeListener = () => {

            if (isMounted) {
                
                // Agregar timeout si impacta en performance.
                // clearTimeout(timeoutId);
                // timeoutId = setTimeout(() => 
                    setWidth(getWidth());
                // );
            }
        };

        window.addEventListener('resize', resizeListener);

        return () => window.removeEventListener('resize', resizeListener)

    }, [])

    return width;
}