import React, { useState, useContext } from "react";
import DesvincularService from '../Services/DesvincularService';
import { CookieContext } from '../Contexts/CookieContext';
import SessionHandler from '../Handlers/Session';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';
import Metering from '../Handlers/Metering';
import Toast from "../Components/Common/Modules/Toast";
import { useDispatch } from "../Store/storeProvider";

const useDesvincularCredencial = () => {
    const [_, setUpdateCookie] = useContext(CookieContext);
    const [error, setError] = useState(null);
    const [isLoadingDesvincular, setIsLoadingDesvincular] = useState(false);
    const dispatch = useDispatch();

    const fetchData = async (esBonificado) => {
        setIsLoadingDesvincular(true);
        DesvincularService()
            .then((res) => {
            if (res.code == '0000') {
                Datadog.trackCustomEvent(
                    CustomEventsDatadog.desvincularCredencial, { esBonificado }
                );
                SessionHandler.Reload(dispatch).then(() =>
                    Metering.obtenerCookieSuscriptor().then(() => {
                        setUpdateCookie(true);
                    })
                );
                setIsLoadingDesvincular(false);
            }else{
                setIsLoadingDesvincular(false);
                setError(
                    <Toast status="warning" title="¡Uppps!">
                      No pudimos procesar tu solicitud. Por favor, volvé a intentarlo más tarde.
                    </Toast>
                  );
                Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorDesvincularCredencial , { esBonificado });
            }
        })
        .catch((error) => {
            setError(
                <Toast status="warning" title="¡Uppps!">
                  No pudimos procesar tu solicitud. Por favor, volvé a intentarlo más tarde.
                </Toast>
              );
            setIsLoadingDesvincular(false);
            Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorDesvincularCredencial , { esBonificado });
        });
    };
    return [error, isLoadingDesvincular, fetchData];
};

export default useDesvincularCredencial;
