import React, { useState, useEffect } from 'react';
import Button from '../../Common/Modules/Button';
import Text from '../../Common/Text/Text';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import UrlHelper from '../../../Helpers/Url';
import Cookies from 'js-cookie';
import _Logout from '../../../Handlers/_Logout';

function SinSuscripcionDigital(props) {
    const [isLoading, setIsLoadingState] = useState(false);

    const logout = (e) => {
        e.preventDefault();
        setIsLoadingState(true);
        _Logout.Logout(true);
    };

    const verPlanesDigitales = (e) => {
        e.preventDefault();
        setIsLoadingState(true);
        window.location.href = process.env.REACT_APP_WALL_VOLUNTARIO;
    };

    return (
        <LoadingSpinner isLoading={isLoading}>
            <div className="upselling">
                <Text className="--sueca" size="--m">
                    Hola <strong>{Cookies.get('usuarioemail')},</strong>
                </Text>

                <div className="proximos-pasos">
                    <Text tag="p" className="--mt-16 --mb-24" size="2xs">
                        La oferta de credenciales bonificadas durante el primer mes solo está disponible para clientes que ya poseen una suscripción
                        digital. <br />
                        Actualmente ingresaste con tu cuenta <strong>{Cookies.get('usuarioemail')}</strong>. Si esta no es tu cuenta de suscriptor
                        digital, cerrá la sesión e iniciá sesión con el usuario correspondiente para poder acceder a este beneficio.
                        <br /> <br />
                        Si todavía no contás con una suscripción digital, aprovechá nuestras promociones de <strong>Acceso Digital</strong> y{' '}
                        <strong>Acceso Digital con Club LA NACION</strong>.
                    </Text>

                    <div className="flex ai-center gap-8">
                        <Button tag="input" onClick={logout} value="CAMBIAR USUARIO" type="submit" mobileW100={true} />
                        <Button
                            tag="input"
                            mod="secondary"
                            onClick={verPlanesDigitales}
                            value="VER PLANES DIGITALES"
                            type="submit"
                            mobileW100={true}
                        />
                    </div>
                </div>
            </div>
        </LoadingSpinner>
    );
}

export default SinSuscripcionDigital;
