import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import Label from '../../../Components/Common/Atoms/Form/Label';
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle';
import RadioInput from '../../../Components/Common/Atoms/Form/RadioInput';
import InputBox from '../../../Components/Common/Atoms/Form/InputBox';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Title from '../../../Components/Common/Atoms/Title';
import Button from '../../../Components/Common/Modules/Button';
import steps from '../../../Config/StepsSuscripcion';
import useObtenerSuscripciones from '../../../Hooks/useObtenerSuscripciones';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import DateHelper from '../../../Helpers/Date';
import NotasSupenderPorVacaciones from '../../../Components/Common/Banners/NotasSupenderPorVacaciones';
import SinSuscripcionesActivas from './SinSuscripcionesActivas';
import TitleWeb from '../../../Components/Common/Web/Title';
import { isBlocked } from '../../../Filter/suscripciones';
import PathsConfig from '../../../Config/RoutePaths';
import ErrorServicioYContacto from '../../../Components/Common/Banners/ErrorServicioYContacto';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import ToastEmptyState from '../../../Components/Common/Banners/ToastEmptyState';
import Divider from '../../../Components/Common/Atoms/Divider';
import ErrorBoundary from './../../../Components/Common/Organism/ErrorBoundary';
import { useStore } from '../../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';

const anulacionSuspension = 'Anulación de suspensión por vacaciones';

const SeleccionSuscripcion = () => {
    const history = useHistory();
    const {HandleSwitchsStates} = useStore();
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const [seleccionado, setSeleccionado] = useState();
    const [btnContinuar, setBtnContinuar] = useState(false);
    const [alertTexto, setAlertTexto] = useState(false);

    const suscripcionesPrintActivas = suscripciones?.filter(
        (sus) => sus.Estado === 1 && (sus.TipoFamilia === 'D' || sus.TipoFamilia === 'R' || sus.TipoFamilia === 'H')
    );

    const setSelect = (e) => {
        let suscripcionObject = suscripciones.find((sus) => e.target.value == sus.Id);
        setSeleccionado(suscripcionObject);
        setAlertTexto(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let anular = false;
        setBtnContinuar(true);

        if (seleccionado) {
            if (seleccionado.SituacionSuspensionVacaciones?.FechaDesde) {
                anular = seleccionado.SituacionSuspensionVacaciones.TipoDeGestionDeVacaciones !== anulacionSuspension;
            }
            window.scrollTo(0, 0);
            history.push({
                pathname: PathsConfig.SeleccionFechaSuspension,
                state: {
                    suscripcionSeleccionado: seleccionado,
                    anulable: anular,
                },
            });
        } else {
            setAlertTexto(true);
        }
    };

    const suspensionShow = (sus) => {
        if (sus.SituacionSuspensionVacaciones?.FechaDesde && sus.SituacionSuspensionVacaciones.TipoDeGestionDeVacaciones !== anulacionSuspension) {
            const fechaDesdeDate = DateHelper.changeDateStringFormat(sus.SituacionSuspensionVacaciones.FechaDesde);
            const fechaHastaDate = DateHelper.changeDateStringFormat(sus.SituacionSuspensionVacaciones.FechaHasta);
            return (
                <>
                    {sus.NombreAmigable}.{' '}
                    <b>
                        Suspendida desde {fechaDesdeDate} hasta {fechaHastaDate}
                    </b>
                </>
            );
        } else {
            return sus.NombreAmigable;
        }
    };

    return (
        <>
            <section className="lay-strechted">
                <Breadcrumb firstItem={'Mis suscripciones'} href={'#'} text={'Suspender por vacaciones'} />
                <section className="container-streched">
                    <TitleWeb text="Suspender por vacaciones" />
                    {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.SuspenderPorVacaciones) ? (
                        <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.SuspenderPorVacaciones} />
                    ) : (
                        <LoadingSpinner isLoading={isLoadingSuscripciones}>
                            <ErrorBoundary error={errorSuscripciones && !suscripciones}>
                                {errorSuscripciones && !suscripciones ? (
                                    <>
                                        <ErrorServicioYContacto title={'Hubo un error al consultar tus suscripciones'} />
                                        <ContactoDudasBanner />
                                    </>
                                ) : (
                                    <>
                                        {suscripciones?.length == 0 ? (
                                            <>
                                                <ToastEmptyState
                                                    titulo={'No tenés suscripciones editoriales activas (diarios y/o revistas)'}
                                                    bajada={
                                                        'No se encontraron suscripciones editoriales activas para solicitar una suspensión de entrega a domicilio. Consultá el estado de tus suscripción ingresando a'
                                                    }
                                                    hasBanner
                                                    isSuspender
                                                />
                                                <Divider />
                                                <ContactoDudasBanner />
                                            </>
                                        ) : suscripcionesPrintActivas?.length > 0 ? (
                                            <>
                                                <Wizard steps={steps} activeStep={1} />
                                                <CallbackArticle
                                                    title={
                                                        <Title size="--xs" className="--blue-500">
                                                            Tené en cuenta lo siguiente:
                                                        </Title>
                                                    }
                                                    description={<NotasSupenderPorVacaciones />}
                                                />

                                                <Title size="--s" className="--arial --font-bold --mb-24">
                                                    Seleccioná el producto a suspender por vacaciones
                                                </Title>

                                                <form onSubmit={handleSubmit}>
                                                    <FormRow>
                                                        <FormCol>
                                                            <Label text="Mis suscripciones" className="--arial --font-bold" size="--fourxs" />
                                                            {suscripcionesPrintActivas.map((sus, index) => {
                                                                return (
                                                                    <InputBox
                                                                        className="--mb-8"
                                                                        key={index}
                                                                        disabled={isBlocked(sus)}
                                                                        error={!seleccionado && btnContinuar ? true : false}
                                                                        input={
                                                                            <RadioInput
                                                                                disabled={isBlocked(sus)}
                                                                                error={!seleccionado && btnContinuar ? true : false}
                                                                                onChange={setSelect}
                                                                                name="mis-suscripciones"
                                                                                value={sus.Id}
                                                                            >
                                                                                {sus.FamiliaDeProductoNombre}
                                                                            </RadioInput>
                                                                        }
                                                                        text={suspensionShow(sus)}
                                                                    />
                                                                );
                                                            })}
                                                            {alertTexto && <p className="--red-500">Seleccionar una suscripción para continuar</p>}
                                                        </FormCol>
                                                    </FormRow>
                                                    <FormRow mod="--btn-groups">
                                                        <Button
                                                            disabled={suscripcionesPrintActivas?.length === 0}
                                                            type="submit"
                                                            tag="input"
                                                            mod="state--mobile-w100"
                                                            label="Continuar"
                                                        />
                                                    </FormRow>
                                                </form>
                                            </>
                                        ) : (
                                            <SinSuscripcionesActivas />
                                        )}
                                    </>
                                )}
                            </ErrorBoundary>
                        </LoadingSpinner>
                    )}
                </section>
            </section>
        </>
    );
};

export default SeleccionSuscripcion;
