export const esProductoPremiumConClub = (productoPremiumId) => {
    return productoPremiumId === ProductoPremiumId.AdMasC
}

export const ProductoPremiumId = {
    SinSuscripcion: "",
    Ad: "2,3",
    AdMasC: "2,3,4,5",
    Duo: "2,3,4,5",
    Triple: "2,3,4,5",
    Black: "1,2,3,4,5"
}