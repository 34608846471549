import { useState, useEffect } from "react";
import ApiService from "../../Services/ApiService";
import { useStore } from "../../Store/storeProvider";
import { trackCustomEventError } from "../../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../../Config/Datadog";

const useObtenerIdentitiesProviderData = () => {
    const { HandleSwitchsStates } = useStore();
    let { REACT_APP_APILOGIN } = process.env;
    const [isLoadingIdentityProviderData, setIsLoading] = useState(false);
    const [identitiesProviderData, setIdentitiesProviderData] = useState(null);
    const [identitiesProviderError, setErroridentitiesProvider] = useState(null);

    const fetchIdentitiesProviderData = () => {
        setIsLoading(true)
        ApiService.get(`${REACT_APP_APILOGIN}Auth0/GetIdentitiesProviderData`)
            .then((data) => {
                if (data && data.code) {
                    if (data.code === "0000") {
                        let profiles = {};
                        data.response.forEach((identity) => {
                            profiles[identity.profile_name] = identity
                        });

                        setIdentitiesProviderData(
                            profiles
                        );
                    } else {
                        trackCustomEventError(CustomEventsErrorDatadog.errorObtenerIdentitiesProviderData, { code: data.code, error: data.message })
                        setErroridentitiesProvider(true);
                    }
                }
            })
            .catch((error) => {
                trackCustomEventError(CustomEventsErrorDatadog.errorObtenerIdentitiesProviderData, { error: error.message })
                setErroridentitiesProvider(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (HandleSwitchsStates?.SocialNetWorks) {
            fetchIdentitiesProviderData();
        }
    }, [HandleSwitchsStates]);

    return [identitiesProviderData, identitiesProviderError, isLoadingIdentityProviderData, fetchIdentitiesProviderData];
};

export default useObtenerIdentitiesProviderData;