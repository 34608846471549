import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toast from '../Modules/Toast';
import Icon from '../Atoms/Icon';
import Link from '../Atoms/Link';
import { MainLogin, TextLogin } from './components/index';
import BannerConfirmacion from '../../../Statics/Imgs/banner-confirmacion.png';
import ImagePromoBanner from '../../../Statics/Imgs/image-promo-banner.png';
import CallbackArticle from '../Modules/CallbackArticle';
import './_confirmacion.scss';
import Button from '../Modules/Button';
//import Image from '../Atoms/Image';

const propTypes = {
    /**
     * Nombre del titulo
     */
    titleText: PropTypes.string,
    /**
     * Src de la imagen
     */
    //image: PropTypes.string,
    /**
     * Texto del primer botón del footer
     */
    firstBtnLabel: PropTypes.string,
    /**
     * Si existe, texto del segundo botón del footer
     */
    secondBtnLabel: PropTypes.string,
    /**
     * Mostrar CallbackArticle
     */
    showCallbackArticle: PropTypes.bool,
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const ConfirmacionLogin = ({
    titleText,
    //image,
    //imageSrc,
    firstBtnLabel,
    firstBtnOnClick,
    secondBtnLabel,
    secondBtnOnClick,
    showBannerDudas,
    showCallbackArticle,
    children,
    className,
    black,
    nroCredencial,
}) => {
    const classes = classNames('lgn-confirmacion', className);
    const [showToast, setShowToast] = useState(false);

    console.log(classes)
    return (
        <div className={classes}>
            <MainLogin>
                {showToast && <Toast status="success" title="Número de credencial copiado" closeAuto={3000} value={showToast} changeValue={setShowToast} positionFloat="right" />}

                {/* <div className={"image-confirmacion"}>{image ? image : <Image src={imageSrc} width={imageWidth}></Image>}</div> */}

                <TextLogin title={titleText}>{children}</TextLogin>

                {showCallbackArticle && (
                    <CallbackArticle
                        bg
                        description={
                            <>
                                {nroCredencial ? (
                                    <Link
                                        className="banner-link"
                                        onClick={() => {
                                            navigator.clipboard.writeText(nroCredencial);
                                            setShowToast(!showToast);
                                        }}
                                    >
                                        <Icon icon="copy" size="m" />
                                        Copiar
                                    </Link>
                                ) : (
                                    <div>
                                        <p className="--twoxs">Conocé los planes digitales de LA NACION.</p>
                                        <Link className="banner-link" href="#">
                                            Mas información.
                                        </Link>
                                    </div>
                                )}
                            </>
                        }
                        imageUrl={nroCredencial ? BannerConfirmacion : ImagePromoBanner}
                        imageWithoutPadding={nroCredencial}
                        text={nroCredencial && 'Tu número de credencial es'}
                        title={nroCredencial ? nroCredencial : '¡Suscribite y navegá sin límites!'}
                    />
                )}
            </MainLogin>

                {firstBtnLabel && <Button className="--not-disabled --mb-16" label={firstBtnLabel} onClick={firstBtnOnClick} />}
                {secondBtnLabel && <Button className='--border-blue --mb-16' label={secondBtnLabel} onClick={secondBtnOnClick} />}

            {showBannerDudas && <PhonesBanner notSuscribe />}
        </div>
    );
};

ConfirmacionLogin.propTypes = propTypes;

export default ConfirmacionLogin;
