import { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";

const useObtenerPinUsuario = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const pathUrl = `${REACT_APP_APIPAYWALL}Pin/ObtenerPinAUsuario`;
            ApiService.post(pathUrl, {})
                .then((data) => {
                    if (data && data.code) {
                        if (data.code == "0000") {
                            setData(data.response);
                            setIsLoading(false);
                        } else {
                            setError(data.message);
                            setIsLoading(false);
                        };
                    };
                })
                .catch(() => {
                    setError(true);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [data, error, isLoading];
};

export default useObtenerPinUsuario;
