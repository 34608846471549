import React, { useState } from "react";
import Toast from "../Components/Common/Modules/Toast";
import ApiService from "../Services/ApiService";
import Link from "../Components/Common/Atoms/Link";
import Datadog, { CUSTOM_EVENTS } from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import { horario, mailAtencionSocios, telefono } from "../Config/DatosDeContacto";

const buildResponse = (type, msg) => {
  return (
    <Toast
      status={type}
      title={msg}
      closeAuto={true}
      value={true}
      positionFloat={"relative"}
    />
  );
};

const useActualizarDatosPersonales = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const outFunc = async (params, callBack) => {
    setIsLoading(true);
    const pathUrl = `${process.env.REACT_APP_APIPAYWALL}Cliente/GuardarClienteOnline`;
    ApiService.post(pathUrl, params)
      .then((data) => {
        if (data.code === "0000") {
          Datadog.trackCustomEvent(CustomEventsDatadog.datosPersonales);
          callBack(true);
        } else {
          Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorDatosPersonales, {codigo: data.code, error: data.message});
          setError(
            <Toast status="warning" title="No pudimos procesar tu solicitud">
              Identificamos que alguno de tus datos personales es inválido y no{" "}
              podemos procesar el cambio. Para normalizarlo, escribinos a{" "}
              <Link href={`mailto: ${mailAtencionSocios}`}> {mailAtencionSocios}</Link> o llamanos al{" "}
              <b>{telefono}</b> de {horario}
            </Toast>
          );
        }
      })
      .catch((error) => {
        setError(
          <Toast status="warning" title="Ocurrió un error">
            Hubo un problema con tu solicitud. Por favor, inténtalo nuevamente{" "}
            más tarde.
          </Toast>
        );
      })
      .finally((_) => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  };

  return [error, isLoading, outFunc];
};

export default useActualizarDatosPersonales;
