const cols = [
    {
        field: 'categorias',
        name: 'Categorías',
    },
    {
        field: 'consumo',
        name: 'Consumo',
    },
];

export default cols;