import React from 'react';
import Divider from '../../../Components/Common/Atoms/Divider';
import Button from '../../../Components/Common/Modules/Button';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Box from '../../../Components/Common/Atoms/Box';
import Text from '../../../Components/Common/Text/Text';
import Toast from '../../../Components/Common/Modules/Toast';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import PathsConfig from '../../../Config/RoutePaths';
import { useHistory } from 'react-router';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import steps from '../../../Config/StepsCambioFormaDePago';
import ExperienceModal from '../../../Components/Modals/ExperienceModal';
import { useStore } from '../../../Store/storeProvider';
import { origenesEncuesta } from '../../../Config/OrigenEncuesta';
import Link from './../../../Components/Common/Link/Link';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';

const PaymentMethodChanged = (props) => {
    const history = useHistory();
    const stepProps = ['succeed', 'tarjeta', 'suscripciones', 'nroGestion'];
    const { succeed, tarjeta, suscripciones, nroGestion } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ElegirSuscripcion);
    const { HandleSwitchsStates } = useStore();

    return (
        <>
            <section className="container-streched">
                <section>
                    <Breadcrumb firstItem="Mis Pagos" text="Cambiar forma de pago" href="#" />

                    <TitleWeb text="Cambiar forma de pago" />
                </section>
                <Wizard steps={steps} activeStep={3} />
                <Toast
                    status={succeed ? 'success' : 'warning'}
                    title={succeed ? '¡Tu nueva forma de pago se guardó con éxito!' : 'No pudimos cambiar tu forma de pago'}
                >
                    {!succeed && (
                        <Paragraph className="--arial --font-regular">
                            Por favor intentalo de nuevo. Si el error persiste comunicate con nosotros a <Link href="mailto:atencionsocios@lanacion.com.ar">atencionsocios@lanacion.com.ar</Link>
                        </Paragraph>
                    )}
                </Toast>
                {succeed && (
                    <>
                        <Text size="--m" tag="h4" className="--mb-8">
                            Información de tu cambio de forma de pago
                        </Text>
                        <Paragraph mod="p" size="--twoxs" className="--mb-8">
                            Seleccionaste el medio de pago <strong>XXXX-{tarjeta}</strong> para las siguientes suscripciones:
                        </Paragraph>
                        <Box className="--mb-32 --mt-32">
                            {suscripciones.map((suscripcion) => {
                                return <Paragraph size="--twoxs">{suscripcion.nombreSuscripcion}</Paragraph>;
                            })}
                        </Box>
                        <Text tag="p" size="--twoxs" className="--arial --mb-32">
                            El número de gestión correspondiente es: <strong>{nroGestion}</strong>
                        </Text>
                        {HandleSwitchsStates && HandleSwitchsStates?.EncuestaCambioFormaPago && <ExperienceModal nroGestion={nroGestion} origenEncuesta={origenesEncuesta.cambioFormaPago}/>}
                    </>
                )}
                <FormRow mod="--btn-groups">
                    <Button
                        label={succeed ? 'IR A MI USUARIO' : 'Volver a intentar'}
                        className="state--mobile-w100"
                        onClick={() =>
                            history.push({
                                pathname: succeed ? PathsConfig.MiUsuario : PathsConfig.ElegirSuscripcion,
                            })
                        }
                    />
                </FormRow>
                <Divider />
                <ContactoDudasBanner />
            </section>
        </>
    );
};

export default PaymentMethodChanged;
