import React, { useEffect, useState } from 'react';
import useObtenerRechazosCobroVencidoYAdelantado from '../../../Hooks/useObtenerRechazosCobroVencidoYAdelantado';

const useObtenerImporteDeudas = () => {
    const [deudas, errorDeudas, isLoadingDeudas] = useObtenerRechazosCobroVencidoYAdelantado(true);
    const [total, setTotal] = useState();

    useEffect(() => {
        setTotal(deudas?.rechazosCobrosVencidos?.reduce(
            (sum, { Importe }) => sum + +Importe,
            0
        ) + deudas?.rechazosCobrosAdelantados?.reduce(
            (sum, { Importe }) => sum + +Importe,
            0
        ));
    }, [isLoadingDeudas]);

    return [total, errorDeudas, isLoadingDeudas] ;
};

export default useObtenerImporteDeudas;
