const getCondicionSuscripcionUsuario = (serviceData) => {

    if (serviceData == null || (!serviceData.tieneClub && !serviceData.categoria )) { return estadoSuscripcion.SIN_SUSCRIPCION; }

    if (serviceData.categoria != null && !serviceData.tieneClub) { return estadoSuscripcion.SUSCRIP_SIN_CLUB; }

    if (serviceData.categoria.toUpperCase() === 'CLASSIC') { return estadoSuscripcion.CLASSIC; }

    if (serviceData.categoria.toUpperCase() === 'PREMIUM') { return estadoSuscripcion.PREMIUM; }

    if (serviceData.categoria.toUpperCase() === 'BLACK') { return estadoSuscripcion.BLACK; }

    return null;
}

const estadoSuscripcion = {
    SIN_SUSCRIPCION: 0,
    SUSCRIP_SIN_CLUB: 1,
    CLASSIC: 2,
    PREMIUM: 3,
    BLACK: 4
}

export {estadoSuscripcion, getCondicionSuscripcionUsuario}