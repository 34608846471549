import PathsConfig from "../Config/RoutePaths";

const MotivoReclamo = [
    {
        id: 12,
        value: 'Demora en entrega',
        url: PathsConfig.ReclamoEntregaDemora
    },
    {
        id: 6,
        value: 'No Recibí',
        url: PathsConfig.ReclamoEntregaNoRecibi
    },
    {
        id: 7,
        value: 'Recibí dañado',
        url:  PathsConfig.ReclamoEntregaRecibiDaniado
    },
    {
        id: 10,
        value: 'Vendedor reclama pago',
        url: PathsConfig.ReclamoEntregaVendedor
    },
    {
        id: 9,
        value: 'Sin ejemplar en el Kiosco',
        url:  PathsConfig.ReclamoEntregaContactoCallcenter
    },
    {
        id: 8,
        value: 'Recibí un producto no solicitado',
        url:  PathsConfig.ReclamoEntregaProdNoSolicitado
    },
];

export default MotivoReclamo;
