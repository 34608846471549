import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@ln/experiencias-ui-spinner';
import { ExclusiveLn } from '@ln/experiencias-ui-assets';
import { Icon } from '@ln/common-ui-icon';
import '@ln/experiencias-ui-spinner/index.css';

const LoadingSpinnerWithData = ({ isLoading, children, title, description }) => {
    return (
        <React.Fragment>
            {isLoading ? (
                <div className='flex flex-column jc-center ai-center gap-16 w-100 h-100 absolute'>
                    <Spinner>
                        <Icon width={34} color='inherit'>
                            <ExclusiveLn />
                        </Icon>
                    </Spinner>
                    {(title || description) && (
                        <div className='text-center'>
                            {title && <p><strong>{title}</strong></p>}
                            {description && <p>{description}</p>}
                        </div>
                    )}
                </div>
            ) : children}
        </React.Fragment>
    );
};
LoadingSpinnerWithData.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export default LoadingSpinnerWithData;
