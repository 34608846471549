import React, { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";

const useObtenerCredenciales = () => {
  let { REACT_APP_APIPAYWALL } = process.env;
  const [isLoadingCredenciales, setIsLoadingCredenciales] = useState(true);
  const [credenciales, setCredenciales] = useState(null);
  const [errorCredenciales, setErrorCredenciales] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      ApiService.post(`${REACT_APP_APIPAYWALL}Reclamos/ObtenerCredenciales`)
        .then((data) => {
          if (data && data.code) {
            if (data.code === "0000" || data.code === "4010") {
              setCredenciales(data.response);
              setIsLoadingCredenciales(false);
            } else {
              setErrorCredenciales(true);
              setIsLoadingCredenciales(false);
              Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorObtenerCredenciales, data)
            }
          }
        })
        .catch((err) => {
          Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorObtenerCredenciales, err)
          setErrorCredenciales(true);
          setIsLoadingCredenciales(false);
        });
    };
    fetchData();
  }, []);
  return [credenciales, errorCredenciales, isLoadingCredenciales];
};

export default useObtenerCredenciales;
