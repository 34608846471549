import React from 'react';
import { useHistory } from 'react-router';

import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import Button from '../../Components/Common/Modules/Button';
import Wizard from '../../Components/Common/Modules/Wizard';
import ContactoDudasBanner from '../../Components/Common/Banners/ContactoDudas';
import Divider from '../../Components/Common/Atoms/Divider';
import Link from '../../Components/Common/Atoms/Link';
import IncentivarActivacionBanner from '../../Components/Common/Banners/IncentivarActivacion';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import TitleWeb from '../../Components/Common/Web/Title';
import PathsConfig from '../../Config/RoutePaths';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';
import { mailAtencionSocios } from '../../Config/DatosDeContacto';

const configKiosko = {
	status: "warning",
	text: "Para realizar este tipo de reclamo, comunicate con nosotros a"
};

const configDefault = {
	status: "alert",
	text: "Para realizar este tipo de reclamo, precisamos que te pongas en contacto a través de"
};

const ContactoCallCenter = () => {
	const history = useHistory();
	const stepProps = ['title', 'buttonRoute', 'showBanner', 'steps', 'activeStep', 'breadcrumb'];
	const { title, buttonRoute, showBanner, steps, activeStep, motivoSelected, breadcrumb } = useRedirectWithout(history.location.state, stepProps, PathsConfig.HomeMyAccount);

	const volverAReclamos = () => {
		history.push({
			pathname: buttonRoute
		});
	};

	return (
		<div>
			<div className="lay-strechted">
				<Breadcrumb firstItem="Mis reclamos" text={breadcrumb} href="#" />
				<TitleWeb text={title} />
			</div>

			<div className="container-streched">

				<Wizard steps={steps} activeStep={activeStep} />

				<Toast
					status={motivoSelected == 9 ? configKiosko.status : configDefault.status}
					title="No pudimos procesar tu reclamo"
				>
					{motivoSelected == 9 ? configKiosko.text : configDefault.text}
					<Link href={`mailto: ${mailAtencionSocios}`}> {mailAtencionSocios}</Link> para gestionarlo
					y dar solución al mismo.
				</Toast>
				<FormRow mod="--btn-groups --mb-32">
					<Button mod="state--mobile-w100" onClick={() => volverAReclamos()} href={'#'} label="Realizar otro reclamo" />
				</FormRow>

				<Divider />

				{showBanner &&
					<>
						<IncentivarActivacionBanner />
						<Divider />
					</>
				}

				<ContactoDudasBanner />
			</div>
		</div>
	);
};

export default ContactoCallCenter;
