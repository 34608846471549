import React from 'react';
import PropTypes from 'prop-types';

import '../../../../Statics/Scss/Components/TableRow.scss';

import CheckInput from '../../Atoms/Form/CheckInput';
import TableBodyCell from './TableBodyCell';
import TableHeaderCell from './TableHeaderCell';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	mod: PropTypes.string,
	/**
	 * Flag, ¿es checkable?
	 */
	isCheckable: PropTypes.bool,
	/**
	 * Flag, ¿pertenece al header?
	 */
	isHeaderRow: PropTypes.bool,
	/**
	 * Handler para los checkboxes.
	 */
	handlerCheck: PropTypes.func,
	/**
	 * Valor del check.
	 */
	checkValue: PropTypes.bool,
	/**
	 * Flag, ¿está deshabilitada?
	 */
	disabled: PropTypes.bool,
};

const defaultProps = {
	isHeaderRow: false,
	isCheckable: false,
	handlerCheck: () => {},
};

const TableRow = ({
	children,
	mod,
	isCheckable,
	isHeaderRow,
	disabled,
	handlerCheck,
	checkValue,
	id,
	...r
}) => {
	const className = `com-table-row${mod ? ` ${mod}` : ''}${
		disabled ? ' --disabled' : ''
	}`;

	const handleSelection = (e, isChecked) => {
		handlerCheck(e, id);
	};

	return (
		<tr className={className} {...r}>
			{isCheckable && !isHeaderRow ? (
				<TableBodyCell mod="--check">
					<CheckInput
						value={checkValue}
						handlerCheck={handleSelection}
						disabled={disabled}
					/>
				</TableBodyCell>
			) : (
				isCheckable && isHeaderRow && <TableHeaderCell />
			)}

			{children}
		</tr>
	);
};

TableRow.propTypes = propTypes;
TableRow.defaultProps = defaultProps;

export default TableRow;
