import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import App from './App';
import { StoreProvider } from './Store/storeProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <StoreProvider>
        <BrowserRouter baseName={baseUrl}>
            <App />
        </BrowserRouter>
    </StoreProvider>,
    rootElement
);

//registerServiceWorker();
