import { StepsTrackeoRedimirPin } from '../Models/ActivarPinCodes';
import { readCookie } from '../Helpers/Cookies';
import { trackCustomEvent, trackCustomEventError } from '../Helpers/Datadog';
import GoogleTagManagerService from '../Helpers/GoogleTagManagerService';
import { esProductoPremiumConClub } from '../Models/ProductoPremiumHelper';


    export const handleActivarPinResponse = (code, pin, callback, crmId) => {
        const payloadDD = {
            mail: readCookie('usuarioemail'),
            crmId: crmId,
        };

        switch (code) {
            case '0000':
                trackCustomEvent(StepsTrackeoRedimirPin[4], payloadDD);
                GoogleTagManagerService.trackCustomEvent('validar_pines_gracias');
                callback(pin, crmId)
                break;
            case '6008':
            case '6011':
                // PIN NO EXISTE
                trackCustomEventError(StepsTrackeoRedimirPin[6], payloadDD);
                break;
            case '6009':
                // PIN USADO
                trackCustomEventError(StepsTrackeoRedimirPin[7], payloadDD);
                GoogleTagManagerService.trackCustomEvent('pin_ya_utilizado');
                break;

            case '6010':
                // PIN VENCIDO
                trackCustomEventError(StepsTrackeoRedimirPin[8], payloadDD);
                break;
            case '0201':
                // PIN ASOCIOADO A OTRO CRM
                trackCustomEventError(StepsTrackeoRedimirPin[9], payloadDD);
                break;
            default:
                break;
        }
    };