import React, { useState } from "react";
import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import Wizard from "../../Components/Common/Modules/Wizard";
import steps from "../../Config/StepsMisCredenciales";
import LoadingSpinner from "../../Helpers/LoadingSpinner";
import Divider from "../../Components/Common/Atoms/Divider";
import TitleWeb from "../../Components/Common/Web/Title";
import ContactoDudasBanner from "../../Components/Common/Banners/ContactoDudas";
import "../../Statics/Scss/Pages/OtorgarCredencialPaso3.scss";
import useRedirectWithout from "../../Hooks/useRedirectWithout";
import useObtenerGrupos from "../../Hooks/useObtenerGrupos";
import ExitoOtorgarCredencial from "../../Components/Features/MisCredenciales/ExitoOtorgarCredencial";
import ErrorMisCredenciales from "../../Components/Common/Banners/ErrorMisCredenciales";
import PathsConfig from "../../Config/RoutePaths";

const ConfirmacionOtorgarCredencial = (props) => {
  const stepProps = ["respOtorgar", "cliente", "domicilio"];
  const { respOtorgar, cliente, domicilio } = useRedirectWithout(
    props.location.state,
    stepProps,
    PathsConfig.MisCredenciales
  );
  const [success] = useState(respOtorgar?.NumeroCredencial ? true : false);
  const [gruposObtenidos, credencialesOtorgadas, errorGrupos, isLoadingGrupos] = useObtenerGrupos();


  const obtenerFechaEstimada = () => {
    if (!isLoadingGrupos) {
      return credencialesOtorgadas && respOtorgar
        ? credencialesOtorgadas.find(
          (c) => c.NumeroCredencial === respOtorgar?.NumeroCredencial
        ).FechaEstimadaEntrega
        : null;
    }
  }
  const fechaEstimada = obtenerFechaEstimada();
  return (
    <>
      <section>
        <Breadcrumb
          firstItem="Club LA NACION"
          text="Mis credenciales"
          href="#"
        />
        <TitleWeb text="Otorgar credencial" />
      </section>

      <section className="container-streched">
        <LoadingSpinner isLoading={isLoadingGrupos}>
          <Wizard steps={steps} activeStep={3} />

          {success ? (
            <>
              <ExitoOtorgarCredencial
                respOtorgar={respOtorgar}
                cliente={cliente}
                domicilio={domicilio}
                fechaEstimada={fechaEstimada}
              />
            </>
          ) : (
            <>
              <ErrorMisCredenciales
                title={"No pudimos otorgar la credencial"}
              />

              <Divider />

              <ContactoDudasBanner />
            </>
          )}
        </LoadingSpinner>
      </section>
    </>
  );
};

export default ConfirmacionOtorgarCredencial;
