import * as Yup from 'yup';

const MiUsuarioForm = Yup.object().shape({
    email: Yup.string()
        .email('Ingresá un email válido')
        .required('Campo requerido'),
    email2: Yup.string()
        .email('Ingresá un email válido')
});

export default MiUsuarioForm;