// import config from 'react-global-configuration';
import Send from './AppEvent'

const RedirectToLoginHomeMicuenta = function () {
    if (Send('LOGIN', "")) return;
    RedirectToLogin(process.env.REACT_APP_MYACCOUNT);
}

const RedirectToLoginPreserveCallback = () => {
    if (Send('LOGIN', "")) return;
    RedirectToLogin(location.href);
}


const RedirectToLogin = (callback) => {
    location.href = `${process.env.REACT_APP_LOGIN}auth0-login/D/9/?callback=${window.btoa(callback)}`;
}

const ObtenerLinkActivarSuscripcion = () => {
    return `${process.env.REACT_APP_CHECKOUT}proceso-suscripcion/C/111/?cv=637&fc=176&productCategory=Voluntario&callback=${window.btoa(location.href)}`
}

const GetParam = (paramName) => {
    const hashValue = window.location.hash;
    const hashWithoutHashSymbol = hashValue.substring(1);
    const urlParams = new URLSearchParams(hashWithoutHashSymbol);
    return urlParams.get(paramName);
}

const GetSearchParam = (param) => {
    const url = new URL(document.location);
    const value = url.searchParams.get(param);
    
    // Validar el valor obtenido
    if (!value) {
        return null;
    }

    return value;
}

const CleanHash = () => {
    const cleanURL = window.location.origin + window.location.pathname;
    history.replaceState(null, null, cleanURL);
}

export default {
    RedirectToLoginHomeMicuenta,
    RedirectToLoginPreserveCallback,
    ObtenerLinkActivarSuscripcion,
    GetParam,
    CleanHash,
    GetSearchParam
}