import React from 'react'
import '../../../Statics/Scss/Modules/InfoCanillita.scss';

const InfoCanillita = ({canillitaDescripcion,canillitaObservaciones}) => {
    return (
        <>
            {canillitaDescripcion && canillitaObservaciones &&
                <div className="infoCanillita">
                    <p className="com-paragraph --4xs --arial">Te compartimos los datos de tu canillita en caso que quieras coordinar tu entrega:</p>
                    <p className="com-paragraph --4xs --font-bold --arial">{canillitaDescripcion}  {canillitaObservaciones}</p>
                </div>
            }
        </>
    )
}

export default InfoCanillita
