import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormCol from '../Atoms/Form/FormCol';
import FormRow from '../Atoms/Form/FormRow';
import Label from '../Atoms/Form/Label';
import Link from '../Atoms/Link';
import CheckInput from '../Atoms/Form/CheckInput';
import InputBox from '../Atoms/Form/InputBox';
import Button from '../Modules/Button';
import { dateLong } from '../../../Helpers/Dates';
import { getSuscripcionesHabilitadas } from '../../Features/CambiarFormaDePago/getSuscripcionesHabilitadas';
import { MensajesSuscripcionesDeshabilitadas } from '../../../Config/MensajesSuscripcionesDeshabilitadas';
import { ObtenerEstadoSuscripcionPorTipoVenta } from '../../../Helpers/ObtenerEstadoSuscripcionPorTipoDeVenta';
import { UltimosNumerosTarjetaFormat } from '../../../Helpers/UltimosNumerosTarjetaFormat';
import SuscripcionModel from '../../../Models/Suscripcion';

const propTypes = {
    suscriptions: PropTypes.array,
    onSuscriptionsSelected: PropTypes.func.isRequired,
    unChecked: PropTypes.bool,
    onUnChecked: PropTypes.func.isRequired,
    redirectPasoDos: PropTypes.func,
    textoTarjeta: PropTypes.bool,
    cambioFormaDePago: PropTypes.bool,
    planes: PropTypes.array
};

const SuscripcionesSelector = (props) => {
    const { suscriptions, unChecked, onUnChecked, redirectPasoDos, textoTarjeta, cambioFormaDePago, planes, evento } = props;
    const [suscriptionsSelected, setSuscriptionsSelected] = useState([]);
    const [suscriptionsAllSelected, setSuscriptionsAllSelected] = useState(false);
    const suscripcionesHabilitadas = cambioFormaDePago ? getSuscripcionesHabilitadas(suscriptions, evento, planes) : suscriptions;

    const opcionesDeSeleccion = ['Quitar selección', 'Seleccionar todas'];
    useEffect(() => {
        if (suscriptionsSelected.length == suscripcionesHabilitadas.length) {
            setSuscriptionsAllSelected(true);
        } else {
            setSuscriptionsAllSelected(false);
        }
    }, [suscriptionsSelected]);

    const selectAllSuscriptions = () => {
        let allSelected = [];
        if (!suscriptionsAllSelected) {
            let suscripForEach;
            if (cambioFormaDePago) {
                suscripForEach = suscripcionesHabilitadas;
            } else {
                suscripForEach = suscriptions;
            }
            suscripForEach.forEach((suscripcion) => {
                if (suscripcion.Estado === SuscripcionModel.Estado.ACTIVA) {
                    const data = {
                        idSuscription: suscripcion.Id,
                        addressId: suscripcion.AddressId,
                        nombreSuscripcion: suscripcion.NombreAmigable,
                        plandeDeVentaNombre: suscripcion.PlandeDeVentaNombre,
                        nombreSuscripcionFormatted: `${suscripcion.Nombre}, ${suscripcion.PlanDeVentaNombre}`,
                    };
                    allSelected.push(data);
                }

            });
        }
        setSuscriptionsSelected(allSelected);
        props.onSuscriptionsSelected(allSelected);
        setSuscriptionsAllSelected(allSelected.length > 0);
        onUnChecked(false);
    };

    const handleChange = (event, suscripcion) => {
        const val = event.target.value;
        let selected = suscriptionsSelected;
        const data = {
            idSuscription: val,
            addressId: suscripcion.AddressId,
            nombreSuscripcion: suscripcion.Nombre,
            plandeDeVentaNombre: suscripcion.PlanDeVentaNombre,
            nombreSuscripcionFormatted: `${suscripcion.Nombre}, ${suscripcion.PlanDeVentaNombre}`,
        };

        if (event.target.checked) {
            selected = [...suscriptionsSelected, data];
        } else {
            const restSuscriptions = suscriptionsSelected.filter((sus) => sus.idSuscription != val);
            selected = restSuscriptions;
        }

        setSuscriptionsSelected(selected);
        props.onSuscriptionsSelected(selected);
        onUnChecked(false);
    };

    const isDisabled = (suscripcion) => {
        if (suscripcion.Estado != SuscripcionModel.Estado.ACTIVA || suscripcion.EsDomicilioModificable == false) {
            return true;
        } else if (cambioFormaDePago) {
            return Boolean(ObtenerEstadoSuscripcionPorTipoVenta(suscripcion, evento));
        } else {
            return false;
        }
    }

    return (
        <form action="#">
            <FormRow>
                <FormCol>
                    <Label
                        text="Mis suscripciones"
                        mod="--arial --font-bold"
                        size="--fourxs"
                        link={suscripcionesHabilitadas?.length > 0 ? <Link onClick={selectAllSuscriptions}>{opcionesDeSeleccion[suscriptionsAllSelected ? 0 : 1]}</Link> : null}
                    />
                    {suscriptions.map((suscripcion) => {
                        return (
                            <InputBox
                                disabled={isDisabled(suscripcion)}
                                error={unChecked}
                                key={suscripcion.Id}
                                input={
                                    <CheckInput
                                        disabled={isDisabled(suscripcion)}
                                        error={unChecked}
                                        checked={suscriptionsSelected.some((el) => el.idSuscription == suscripcion.Id)}
                                        onChange={(event) => handleChange(event, suscripcion)}
                                        children={suscripcion.NombreAmigable}
                                        value={suscripcion.Id}
                                    />
                                }
                                text={isDisabled(suscripcion) ? 'La suscripción no permite realizar esta gestión.' : cambioFormaDePago && ObtenerEstadoSuscripcionPorTipoVenta(suscripcion, evento) ? MensajesSuscripcionesDeshabilitadas[ObtenerEstadoSuscripcionPorTipoVenta(suscripcion, evento)] : textoTarjeta ? UltimosNumerosTarjetaFormat(suscripcion) : `${suscripcion.PlanDeVentaNombre} ${suscripcion.SituacionDomicilio?.FechaAplicacion
                                    ? '- Cambia el domicilio de entrega a partir de ' + dateLong(suscripcion.SituacionDomicilio.FechaAplicacion)
                                    : ''
                                    }`}
                                isChecked={suscriptionsSelected.some((el) => el.idSuscription == suscripcion.Id)}
                                className="--mb-8"
                            />
                        );
                    })}
                    {unChecked && !cambioFormaDePago && (
                        <div className="com-select --fivexs state--error">
                            <span className="com-select__descrip --fivexs">Seleccioná al menos una suscripción cuyo domicilio deseas modificar.</span>
                        </div>
                    )}
                </FormCol>
            </FormRow>
            {
                !cambioFormaDePago && (
                    <FormRow mod="--btn-groups">
                        <Button type="submit" mod="state--mobile-w100" label="Continuar" onClick={redirectPasoDos} />
                    </FormRow>
                )
            }
        </form>
    );
};

SuscripcionesSelector.propTypes = propTypes;

export default SuscripcionesSelector;
