import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardBox from '../../../Components/Common/Modules/CardBox';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Title from '../../../Components/Common/Atoms/Title';
import Pagination from '../../../Components/Common/Modules/Pagination';
import TitleWeb from '../../../Components/Common/Web/Title';
import useObtenerRechazosCobroVencidoYAdelantado from '../../../Hooks/useObtenerRechazosCobroVencidoYAdelantado';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import { getPendientes } from '../../../Components/Features/PagosPendientes/getDeudasPendientes';
import Toast from '../../../Components/Common/Modules/Toast';
import { horario, telefonoSecundario, mailAccesoDigital } from '../../../Config/DatosDeContacto';
import GetCardType from '../../../Helpers/GetCardType';

/*
** Éste componente se usa si por error de la pasarela de pagos o errores detectados de usuarios
** necesitamos deshabilitar la funcionalidad de pagos pendientes por medio de la autogestión
*/
const DeudaContactoCallcenter = () => {
    const [rechazos, errorRechazos, isLoading] = useObtenerRechazosCobroVencidoYAdelantado();
    const [total, setTotal] = useState(0);
    const [pendientes, setPendientes] = useState();

    useEffect(() => {
        setPendientes(getPendientes(rechazos));
    }, [rechazos]);

    useEffect(() => {
        const cacularTotal = () => {
            let sum = pendientes?.filter((p) => p.check).reduce((sum, { Importe }) => sum + +Importe, 0);
            setTotal(sum);
        };
        cacularTotal();
    }, [pendientes]);

    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Pagos pendientes" />

                <TitleWeb text="Pagos pendientes" />
            </section>
            <section className="container-streched">
                <LoadingSpinner withBody isLoading={isLoading}>
                    <Toast status="info" title="No disponible momentáneamente desde autogestión">
                        Para saldar tus pagos pendientes comunicate al {telefonoSecundario} de {horario}{" "}
                        Si estás en el exterior envianos un email a: {mailAccesoDigital}
                    </Toast>
                    <Title size="--s" mod="--font-bold --arial --mb-24">
                        Éstos son tus pagos pendientes
                    </Title>
                    <form>
                        <Pagination itemsQty={4} hasCost cost={+total}>
                            {pendientes &&
                                pendientes.map((pendiente, i) => (
                                    <CardBox
                                        key={i}
                                        costo={pendiente.Importe.toString()}
                                        estadoTitulo={pendiente.Estado}
                                        estadoDescripcion={pendiente.Suscripcion[0].Nombre}
                                        motivoRechazo={`Motivo Del Rechazo: ${pendiente.MotivoRechazo}. Último intento de cobro: ${pendiente.Fecha}`}
                                        periodoContenido={`${pendiente.FechaPeriodoDesde} al ${pendiente.FechaPeriodoHasta}`}
                                        numeroFacturaContenido={pendiente.NroFactura}
                                        checked={false}
                                        cardType={GetCardType(pendiente.InstrumentoDeCobro)}
                                        lastNumbers={pendiente.UltimosNumerosTarjeta}
                                        className="--mb-8"
                                        disabled={true}
                                    />
                                ))}
                        </Pagination>
                    </form>
                </LoadingSpinner>
            </section>
        </>
    );
};

DeudaContactoCallcenter.propTypes = {
    toastCondition: PropTypes.bool,
};

export default DeudaContactoCallcenter;