import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../Common/Organism/ErrorBoundary';
import ComboPlan from './ComboPlan';
import TarjetaInfo from './Tarjeta';
import InfoAContinuacion from './InfoAContinuacion';
import ImagenSuperior from './ImagenSuperior';
import LegalesUpselling from './LegalesUpselling';
import { UpsellingDrawer } from './UpsellingDrawer';
import { useDrawer } from '@ln/common-ui-drawer';
import { DRAWER } from '../../../Models/Drawers';

const Planes = ({
    ConfirmarPlan,
    planes = [],
    TieneClub,
    hasError,
    noDrawer,
    setPlan
}) => {
    const [selectedPlan, setSelectedPlan] = useState(null)
    const { showDrawer, hideDrawer } = useDrawer({ id: DRAWER.UPSELLING })

    const planActual = {
        NombreActual: planes[0]?.NombreActual,
        CantidadCredencialesActual: planes[0]?.CantidadCredencialesActual,
        NombreActual: planes[0]?.NombreActual,
        PrecioActual: planes[0]?.PrecioActual,
    }

    const selectPlan = (plan) => {
        setSelectedPlan(plan)
        setPlan && setPlan(plan);
        Boolean(plan) ? showDrawer() : hideDrawer()
    }

    const getPlan = (plan = '') => {
        if (plan.toLowerCase().includes('black')) return 'black'
        if (plan.toLowerCase().includes('premium')) return 'premium'
        return undefined
    }

    useEffect(() => {
        if(planes.length < 2){
            selectPlan(planes[0]);
            return;
        }
        if (!selectedPlan) {
            selectPlan((hasError || !planes.length || noDrawer) ? null : planes[0]);
        }
    }, [planes, hasError]);

    if (!planes.length) return <></>

    return (
        <section className="flex flex-column gap-16 arial">

            <ImagenSuperior cantidad={planes.length === 1 ? planes[0].CredencialesPlanUpsell : undefined} />

            <div className="grid grid-cols-8 grid-cols-12_md grid-cols-16_lg gap-32">
                <ComboPlan
                    variant='actual'
                    tipoPlan={getPlan(planActual.NombreActual)}
                    cantCredenciales={planActual.CantidadCredencialesActual}
                    titulo={planActual.NombreActual}
                    precio={planActual.PrecioActual}
                />
                {planes.map((plan, i) => (
                    <ComboPlan
                        key={`plan-${i}`}
                        variant={selectedPlan?.NombreUpsell === plan.NombreUpsell ? 'selected' : 'default'}
                        tipoPlan={getPlan(plan.NombreUpsell)}
                        cantCredenciales={plan.CredencialesPlanUpsell}
                        titulo={plan.NombreUpsell}
                        precio={plan.PlanDeVenta[1].PrecioConDescuentos}
                        cantidadDeMeses={plan.PlanDeVenta[1].CantDespachos}
                        precioFinal={plan.PrecioUpsell}
                        selectPlan={() => selectPlan(plan)}
                    />
                ))}
            </div>

            <UpsellingDrawer
                selectedPlan={selectedPlan}
                onClose={() => setSelectedPlan(null)}
                ConfirmarPlan={ConfirmarPlan}
                secondaryButtonText={planes.length > 1 ? 'SELECCIONAR OTRO PLAN' : 'VOLVER'}
            >

                <div className='flex flex-column flex-row_md jc-between_md gap-8 row-gap-0'>
                    <div className='flex flex-column gap-4'>
                        <InfoAContinuacion
                            selectedPlan={selectedPlan}
                        />
                    </div>

                    <ErrorBoundary>
                        <TarjetaInfo tarjeta={selectedPlan?.Tarjeta} />
                    </ErrorBoundary>
                </div>

                <LegalesUpselling />
            </UpsellingDrawer>
        </section>
    );
};

export default Planes;
