import React, { useEffect, useState } from 'react';
import { CustomEventsDatadog } from '../Config/Datadog';
import Datadog from '../Helpers/Datadog';
import ApiService from '../Services/ApiService';

const useCerrarAbandonoCobranza = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    const outFunc = async (idAbandonoCobranza, guardarTarjetaChecked, campanita, monto) => {
        const payload = {
            idAbandonoCobranza: idAbandonoCobranza,
        }
        return ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/CerrarAbandonoCobranza`, payload)
            .then((data) => {
                Datadog.trackCustomEvent(CustomEventsDatadog.pagarPendiente,
                    { realizaCIC: guardarTarjetaChecked, canalContacto: campanita ? 'campanita' : 'autogestion', monto });
                if (guardarTarjetaChecked) {
                    Datadog.trackCustomEvent(CustomEventsDatadog.cambioFormaDePago, { origen: 'pagos-pendientes' });
                }
                if (data && data.Code === 0) {
                    setData(data.Response);
                } else {
                    setError(data.Message);
                }
                setIsLoading(false);
                return Promise.resolve(data);
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
                console.error('CerrarAbandonoCobranza::abandono de cobranza - Error: ', error);
                return Promise.reject(error);
            });
    };

    return [data, error, isLoading, outFunc];
};

export default useCerrarAbandonoCobranza;
