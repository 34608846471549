import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import FormularioIniciarBaja from '../../Components/Features/MisSuscripciones/FormularioIniciarBaja';
import TitleWeb from '../../Components/Common/Web/Title';
import { useStore } from '../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';
import useSuscripcionesParaBaja from '../../Hooks/useObtenerSuscripcionesParaBaja';
import useObtenerMotivosBajaSuscripcion from '../../Hooks/useObtenerMotivosBajaSuscripcion';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Datadog from '../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../Config/Datadog';

const SolicitudBaja = () => {
    const location = useLocation()
    const isInternalNavigation = location.state?.internalNavigation;
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useSuscripcionesParaBaja()
    const [motivosBajaSuscripcion, errorMotivos, isLoadingMotivos] = useObtenerMotivosBajaSuscripcion()
    const { HandleSwitchsStates } = useStore();
    const [habilidadBasica, setHabilidadBasicaStates] = useState({ isLoadingHabilidadBasica: false, errorHabilidadBasica: null})
    const { isLoadingHabilidadBasica, errorHabilidadBasica } = habilidadBasica

    const enviarEvento = () => {
        const origen = isInternalNavigation ? 'MyAccount': 'url'

        Datadog.trackCustomEvent(CustomEventsDatadog.visualizacionBaja, { origen })
    }

    useEffect(() => {
        enviarEvento()
    }, [])

    return (
        <>
            <section>
                <Breadcrumb firstItem={'Mis suscripciones'} href={'#'} text={'Solicitud de baja'} />
                <TitleWeb text="Solicitud de baja" />
            </section>
            <section className="container-streched arial">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.BajaSuscripciones) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.BajaSuscripciones} />
                ) : (
                    <LoadingSpinner isLoading={isLoadingSuscripciones || isLoadingMotivos || isLoadingHabilidadBasica}>
                        <ErrorBoundary error={errorSuscripciones || errorMotivos || errorHabilidadBasica}>
                            <FormularioIniciarBaja suscripciones={suscripciones} motivosBajaSuscripcion={motivosBajaSuscripcion} setHabilidadBasicaStates={setHabilidadBasicaStates} />
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </section>
        </>
    );
};

export default SolicitudBaja;
