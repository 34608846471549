import React from 'react';
import { useField } from 'formik';
import TextArea from '../Atoms/Form/Textarea';
import _handleStatusFormik from '../../../Helpers/StatusFormik'

const TextAreaFormik = (props) => {
    const [field, meta, helpers] = useField(props);

    return (
        <TextArea {...field} {...props} state={_handleStatusFormik(meta)} />
    );

};

export default TextAreaFormik;