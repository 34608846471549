const handleOpenModal = (openModal, setOpenModal) =>{
    if(openModal){
        setTimeout( () => {setOpenModal(!openModal)}, 700)
        document.body.classList.remove("modal-active")
    }else{
        setOpenModal(!openModal);
        document.body.classList.add("modal-active")
    }
}

export default handleOpenModal;