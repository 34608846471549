import React, { useState } from "react";
import { volver } from '../../../Helpers/Reclamo';
import { useHistory } from 'react-router';
import Paragraph       from '../../../Components/Common/Atoms/Paragraph';
import FormRow         from '../../../Components/Common/Atoms/Form/FormRow';
import Textarea         from '../../../Components/Common/Atoms/Form/Textarea';
import FormCol         from '../../../Components/Common/Atoms/Form/FormCol';
import Select          from '../../../Components/Common/Atoms/Form/Select';
import CallBackArticle from '../../../Components/Common/Modules/CallbackArticle';
import useCrearReclamoSuscripcion from '../../../Hooks/useCrearReclamoDeSuscripcion';
import LoadingSpinner from "../../../Helpers/LoadingSpinner";
import Wizard from "../../../Components/Common/Modules/Wizard";
import Button from "../../../Components/Common/Modules/Button";
import Breadcrumb from "../../../Components/Common/Modules/Breadcrumb";
import Label from "../../../Components/Common/Atoms/Form/Label";
import Danios from "../../../Models/TiposDanios";
import MotivoReclamo from '../../../Models/MotivoReclamo';
import steps from "../../../Config/Steps";
import ErrorBlock from "../../../Components/Features/ReclamoEntrega/ErrorBlock";
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import TitleWeb from "../../../Components/Common/Web/Title";
import PathsConfig from "../../../Config/RoutePaths";

const limitesTextoLibre = {min: 5, max:250};

const RecibiDanado = (props) => {
    const history = useHistory();
    const stepProps = ['suscripcionSelected', 'motivoSelected', 'despachos', 'address', 'fechaDesde', 'fechaHasta'];
    const { suscripcionSelected, motivoSelected, despachos, address, fechaDesde, fechaHasta } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoEntrega);
    const [danioSelected, setDanioSelected] = useState(null);
    const [danio, setDanio] = useState(null);
    const [otroSelected, setOtroSelected] = useState(false);
    const [descripcion, setDescripcion] = useState('');
    const [errorDescripcion, setErrorDescripcion] = useState(false);
    const [reclamoError, reclamoIsLoading, CrearReclamoDeSuscripcion] = useCrearReclamoSuscripcion();
    
    const handleDanio = (event) => {
        if(event.target.value === "Seleccioná el tipo de daño"){
            setDanioSelected(false);
            setOtroSelected(false);
        }
        else{
            setDanio(event.target.value);
            setDanioSelected(true);
            if(event.target.value === "Otro"){
                setOtroSelected(true);
            }
            else{
                setOtroSelected(false);
            }
        }
    }

    const handleDescChanged = (event) => {
        const text = event.currentTarget.value;
        if(text.length < limitesTextoLibre.min && text.length > limitesTextoLibre.max){
            setErrorDescripcion(true);
        }
        else{
            setErrorDescripcion(false);
            setDescripcion(text);
        }
    }

    const confirmarReclamo = () => {
        if((danioSelected && !otroSelected) || (otroSelected && !errorDescripcion)){
            var data = {
                IdSuscripcion: suscripcionSelected,
                IdMotivoDeReclamo: motivoSelected,
                Despachos: despachos,
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta,
                AddressId: address,
                Danios: danio,
                Descripcion: descripcion
            }
            CrearReclamoDeSuscripcion(data);
        }
        else{
            if(!danioSelected){
                setDanioSelected(false);
            }
            if(otroSelected && (descripcion.length < limitesTextoLibre.min || descripcion.length > limitesTextoLibre.max)){
                setErrorDescripcion(true);
            }
        }
    }
    
    return (
        <>
            <section className="lay-strechted">
                <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                <TitleWeb text="Nuevo reclamo de entrega" />
            </section>
            <section className="container-streched">
            <LoadingSpinner isLoading={reclamoIsLoading}>
            <Wizard steps={steps} activeStep={3} />
            <form>
                {reclamoError?
                    <ErrorBlock />
                    :<>
                        <CallBackArticle
                            title={`Confirmá la siguiente información antes de generar el reclamo por motivo: ${MotivoReclamo.find(item => item.id == motivoSelected)?.value}`}
                            description={<Paragraph size="--twoxs" mod="--arial --font-regular">Por favor, indicá el daño del producto recibido</Paragraph>}
                        />
                        <FormRow>
                            <Label text={"Tipo de daño"}/>
                            <FormCol>
                                <Select
                                    onChange={handleDanio}
                                    state={danioSelected===false ? {status:"error", msg:"Seleccioná un tipo de daño"} : null}>
                                    <option>Seleccioná el tipo de daño</option>
                                    {
                                        Danios.map((item, key) => {
                                            return(<option key={key}>{item.Descripcion}</option>)
                                        })
                                    }
                                </Select>
                            </FormCol>
                        </FormRow>
                        {otroSelected?
                        <FormRow>
                            <Label text={'¿Qué tipo de daño?'}/>
                            <FormCol>
                                <Textarea placeholder={'Contanos los daños del producto'}
                                    state={errorDescripcion?{status:'error', msg:'Ingrese una descripción del daño entre ' + 
                                        limitesTextoLibre.min + ' y ' + limitesTextoLibre.max + ' caracteres'}:null}
                                    onChange={handleDescChanged}/>
                            </FormCol>
                        </FormRow>
                        :null}
                        <FormRow mod="--btn-groups">
                            <Button mod="state--mobile-w100" href={'#'} onClick={() => confirmarReclamo()} label="GENERAR RECLAMO" />
                            <Button
                                onClick={() => volver(history, suscripcionSelected, motivoSelected)}
                                href={'#'}
                                mod="secondary"
                                label="VOLVER"
                            />
                        </FormRow>
                    </>
                }
            </form>
            
            </LoadingSpinner>
            </section>
        </>
    );
}

export default RecibiDanado;