import React from 'react';
import Icon from '../../Common/Atoms/Icon'
import '../../../Statics/Scss/Features/Beneficios.scss';

const Beneficios = ({ features, featuresBonificada }) => {
    return (
        <>
            {features && <div className="beneficios" dangerouslySetInnerHTML={{ __html: features }}></div>}
            {featuresBonificada && (
                <div className="beneficios --mb-16">
                    <p className='--mb-8'>
                        <Icon name="check-mark" size="s" />
                        <span>Accedé a las notas sin límite mensual</span>
                    </p>
                    <p className='--mb-8'>
                        <Icon name="check-mark" size="s" />
                        <span>Accedé a notas exclusivas</span>
                    </p>
                    <p className='--mb-8'>
                        <Icon name="check-mark" size="s" />
                        <span>Accedé a la edición impresa en pdf y a las revistas</span>
                    </p>
                </div>
            )}
        </>
    );
};

export default Beneficios;
