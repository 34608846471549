import React from "react";
import PathsConfig from "../../../Config/RoutePaths";
import '../../../Statics/Scss/Components/ProteccionDatos.scss';
import Link from "../../Common/Atoms/Link";

const TarjetaDatosPersonales = () => {
  return (
    <p className="com-proteccion-datos --sixxs --arial">Estos son los datos personales que corresponden a tu tarjeta de Club LA NACION. Si no son tus datos, desasociá esta tarjeta y asociá la que corresponde <Link ariaLabel="Si estos no son sus datos personales ingrese aquí"href={PathsConfig.VincularCredencial}>ingresando aquí</Link> .</p>
  );
};

export default TarjetaDatosPersonales;
