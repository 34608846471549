import React from 'react';
import FormCol from '../../Common/Atoms/Form/FormCol';
import FormRow from '../../Common/Atoms/Form/FormRow';
import Button from '../../Common/Modules/Button';
import RadioInput from '../../Common/Atoms/Form/RadioInput';
import NoResults from '../../Common/Modules/NoResults';
import { Form, Formik } from 'formik';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import SelectFormik from '../../Common/Formik/Select';
import InputFormik from '../../Common/Formik/Input';
import Label from '../../Common/Atoms/Form/Label';
import Text from '../../Common/Text/Text';
import InputBox from '../../Common/Atoms/Form/InputBox';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';

const NumeroDocumentoForm = ({ ValidacionSchemaDni, resultados, handleSubmit, clientesEncontrados, tieneCredencial }) => {
    const history = useHistory();
    return (
        <div>
            <Formik
                validationSchema={ValidacionSchemaDni}
                initialValues={{
                    tipoDeDocumento: 'DNI',
                    numeroDocumento: '',
                }}
            >
                {(props) => (
                    <Form className="otorgar-credencial-form">
                        <FormRow>
                            <InputGroup className="grid-25-50-25 ">
                                <FormRow>
                                    <FormCol>
                                        <Label text={'Tipo de documento'} />
                                        <SelectFormik
                                            name="tipoDeDocumento"
                                            state={{ status: '', msg: '' }}
                                            description="Documento de la persona"
                                            aria-label="Seleccione su tipo de documento"
                                        >
                                            <option value="DNI">D.N.I.</option>
                                            <option value="PAS">PAS.</option>
                                            <option value="LC">L.C.</option>
                                            <option value="LE">L.E.</option>
                                            <option value="CI">C.I.</option>
                                        </SelectFormik>
                                    </FormCol>
                                </FormRow>

                                <FormRow>                                    
                                    <FormCol>
                                        <Label text={'Número de documento'} />
                                        <InputFormik
                                            type="text"
                                            name="numeroDocumento"
                                            description="a asignar la credencial"
                                            state={{ status: '', msg: '' }}
                                            aria-label="Ingrese número de documento"
                                        />
                                    </FormCol>
                                </FormRow>

                                <FormRow mod="--btn-groups">
                                    <Button type="submit" tag="input" mod="state--mobile-w100" label="Buscar cliente" onClick={() => handleSubmit(props)} />
                                </FormRow>
                            </InputGroup>
                        </FormRow>

                        {resultados && (
                            <>
                                <Text tag="p" size="4xs" font="arial" weight="bold" className="text-title">
                                    Resultados de búsqueda
                                </Text>

                                {clientesEncontrados?.length ? (
                                    <>
                                        <InputGroup>
                                            <FormCol className="--mb-16">
                                                <InputBox
                                                    error={tieneCredencial}
                                                    disabled={tieneCredencial}
                                                    input={
                                                        <RadioInput
                                                            disabled={tieneCredencial}
                                                            name="nombre"
                                                            checked={!tieneCredencial}
                                                            value={'nombre'}
                                                        >
                                                            {`${clientesEncontrados[0].Nombre} ${''}
                                    ${clientesEncontrados[0].Apellido}`}
                                                        </RadioInput>
                                                    }
                                                />
                                            </FormCol>
                                        </InputGroup>
                                        <>
                                            {tieneCredencial && (
                                                <Text tag="p" size="4xs" font="arial" className="--red-500">
                                                    El cliente encontrado ya tiene una credencial asignada.
                                                </Text>
                                            )}
                                        </>

                                        <div className="otorgar-credencial-buttons">
                                            <FormRow mod="--btn-groups">
                                                <Button
                                                    label="Continuar"
                                                    disabled={tieneCredencial}
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: PathsConfig.MisCredencialesSeleccionarDomicilio,
                                                            state: {
                                                                cliente: clientesEncontrados[0],
                                                            },
                                                        });
                                                    }}
                                                />
                                                <Button
                                                    label="Cancelar"
                                                    mod="tertiary"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: PathsConfig.MisCredenciales,
                                                        });
                                                    }}
                                                    />
                                            </FormRow>
                                        </div>
                                    </>
                                ) : (
                                    <NoResults title={'No hay ningun cliente con ese número de documento.'} iconName={'search'} />
                                )}
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default NumeroDocumentoForm;
