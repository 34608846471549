import PathsConfig from "../Config/RoutePaths";

export const userSinSuscripcion = {
    variantButton: 'accentSubs',
    variant: 'sinSuscripcion',
    badge: 'Sin suscripción digital',
    buttonText: 'SUSCRIBITE',
    link: `${process.env.REACT_APP_WALL_VOLUNTARIO}?cv=670&fc=771&callback=${window.btoa(location.href)}`,
};

export const userConSuscripcion = {
    variantButton: 'primary',
    variant: 'conSuscripcion',
    badge: 'Suscriptor digital',
    buttonText: 'MIS SUSCRIPCIONES',
    link: PathsConfig.MisSuscripciones,
};
