import React from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Link from '../../Common/Atoms/Link';
import Toast from '../../Common/Modules/Toast';
import Button from '../../Common/Modules/Button';
import FormRow from '../../Common/Atoms/Form/FormRow';

function YaTenesCredencial() {
    const history = useHistory();
    return (
        <>
            <Toast status="info" title={`Ya tenés una credencial`}>
                <strong>Ya tenés una credencial asociada en tu cuenta.</strong>
                <br />
                <Link href={PathsConfig.VincularCredencialMiClub} text="Ingresa aquí"></Link> para verla o hacé click en "Ver credencial"
            </Toast>
            <FormRow mod="--btn-groups">
                <Button
                    onClick={() => {
                        history.push({
                            pathname: PathsConfig.VincularCredencialMiClub,
                            state: {
                                redireccion: true,
                            },
                        });
                    }}
                    className="--mb-0"
                    label="Ver Credencial"
                />
            </FormRow>
        </>
    );
}

export default YaTenesCredencial;
