import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import Datadog from "./Datadog";

export const logsEventUpselling = (context, e) => {
    Datadog.trackCustomEvent(CustomEventsDatadog.registrarDomicilioUpselling, {
        datos: e,
    });
    Datadog.trackCustomEvent(CustomEventsDatadog.asignarCredencialUpselling, {
        canalDeVenta: context?.canalDeVenta,
        formaDeContacto: context?.formaDeContacto,
        planId: context?.planCampania?.id,
    });
};

export const logsEventWarningUpselling = (context, e, data, error) => {
    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorRegistrarDomicilioUpselling, {
        datos: e,
        code: data?.code,
        error: error !== null ? error : data.message,
    });
    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorAsignarCredencialUpselling, {
        canalDeVenta: context?.canalDeVenta,
        formaDeContacto: context?.formaDeContacto,
        planId: context?.planCampania?.id,
        code: data?.code,
        error: error !== null ? error : data.message,
    });
};