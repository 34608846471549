import React from 'react';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle';
import steps from '../../../Config/Steps';
import '../../../Statics/Scss/Components/MiddleLineDivider.scss';
import Wizard from '../../../Components/Common/Modules/Wizard';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import VendedorReclamaPagoForm from '../../../Components/Features/ReclamoEntrega/VendedorReclamaPagoForm';
import useCrearReclamoSuscripcion from '../../../Hooks/useCrearReclamoDeSuscripcion';
import ErrorBlock from '../../../Components/Features/ReclamoEntrega/ErrorBlock';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';

const VendedorReclamaPago = (props) => {
    const stepProps = ['suscripcionSelected', 'motivoSelected', 'despachos', 'address', 'fechaDesde', 'fechaHasta'];
    const { suscripcionSelected, motivoSelected, despachos, address, fechaDesde, fechaHasta } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoEntrega);
    const [error, loading, CrearReclamoDeSuscripcion] = useCrearReclamoSuscripcion();

    return (
        <div>
            <section>
                <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                <TitleWeb text="Nuevo reclamo de entrega" />
            </section>

            <section className="container-streched">
                <LoadingSpinner isLoading={loading}>
                    <Wizard steps={steps} activeStep={3} />
                    {error ? <ErrorBlock /> :
                        <>
                            <CallbackArticle
                            description={
                                <Paragraph size="--s" mod="--font-bold --arial">
                                        Confirmá la siguiente información antes de generar el
                                        reclamo
                                    </Paragraph>
                                }
                                />
                            <VendedorReclamaPagoForm 
                                data={{
                                    suscripcionSelected: suscripcionSelected, 
                                    motivoSelected:motivoSelected, 
                                    despachos:despachos, 
                                    address:address, 
                                    fechaDesde:fechaDesde, 
                                    fechaHasta:fechaHasta 
                                }} 
                                onSubmit={CrearReclamoDeSuscripcion}/>
                        </>
                    }
                </LoadingSpinner>
            </section>
      </div>
    );
};

export default VendedorReclamaPago;
