import PathsConfig from "../Config/RoutePaths";
import steps from "../Config/Steps";
import stepsTarjeta from "../Config/StepsTarjeta";
import { EstadosDespacho } from "../Models/Despacho";

export const reclamoEntregaParams = {
  title: 'Nuevo reclamo de entrega',
  buttonRoute: PathsConfig.ReclamoEntrega,
  showBanner: false,
  steps: steps,
  activeStep: 4,
  breadcrumb: 'De entrega'
};

export const reclamoTarjetaParams = {
  title: 'Credencial Club',
  buttonRoute: PathsConfig.ReclamoTarjeta,
  showBanner: true,
  steps: stepsTarjeta,
  activeStep: 3,
  breadcrumb: 'Credencial Club'
};

export const volver = (history, suscripcion, motivo) => {
  history.push({
    pathname: PathsConfig.ReclamoEdiciones,
    state: {
      suscripcionSelected: suscripcion,
      motivoSelected: motivo,
      detalleSuscripcion: '',
    },
  });
};

export const esDespachoNoReclamable = (despacho) => {
  const despachosReclamables = [
    EstadosDespacho.Entregado.Id,
    EstadosDespacho.EnGestionVencido.Id,
    EstadosDespacho.EnDevolucion.Id
  ];

  const estadosDespachosNoReclamable = despacho.EstaReclamado || despacho.Inhabilitado;
  // los despachos no pueden ser reclamables si su estado está en Reclamado o inhabilitado
  // o si NO se encuentra entregado, En Gestión Vencido o En Devolución
  return estadosDespachosNoReclamable || !despachosReclamables.some(i => i == despacho.EstadoDespacho.Id);
}
