import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../Text/Text';
import classNames from 'classnames';
import MiniCards from '../../Atoms/MiniCards';
import Skeleton from '../../Atoms/Skeleton';

const propTypes = {
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const DrawerItem = ({
    titleText,
    descriptionText,
    card,
    isLoading,
    className,
    ...r
}) => {
    const classes = classNames('drawer-item', className);

    return (
        <div className={classes} {...r}>
            {titleText && (
                <Text className="--arial title" size="5xs">
                    {titleText}
                </Text>
            )}

            <div className="description">
                {descriptionText && (
                    <>
                        {card && <MiniCards cardType={card} />}
                        <Text className="--arial description-text" size="2xs">
                            {descriptionText}
                        </Text>
                    </>
                )}
            </div>
        </div>
    );
};

DrawerItem.propTypes = propTypes;

export default DrawerItem;
