const PrimerosTarjeta = 639130;

const SituacionDeEntrega = {
    ENTREGADA: 5
};

const Categorias = [
    {
        Id: 1,
        Nombre: "Classic"
    },
    {
        Id: 2,
        Nombre: "Premium"
    },
    {
        Id: 3,
        Nombre: "Black"
    }
];

export default { PrimerosTarjeta, SituacionDeEntrega, Categorias }
