import React     from 'react'

import '../../../Statics/Scss/Modules/CallbackIconTextButton.scss';


const CallbackIconTextButton  = ( {icon, text, button, mod} ) => {
    const className = `mod-callbackIconTextButton${mod ? ` ${mod}` : ""}`
    return (
        <div className={className}>
            <div className="mod-callbackIconTextButton__container">
                <div>
                    {icon}
                </div>
                <div>
                    {text}
                </div>
            </div>
            {button && <div className="mod-callbackIconTextButton__button">{button}</div>}
        </div>
    )
}


export default CallbackIconTextButton;