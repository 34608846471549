import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import '../../../Statics/Scss/Components/Paragraph.scss';



const Paragraph = ({ children, size, mod }) => {
    const className = `com-paragraph${size ? ` ${size}` : ''}${mod ? ` ${mod}` : ''}`;

    return ( 
        <p 
            className={className}
            dangerouslySetInnerHTML={{__html: ReactDOMServer.renderToStaticMarkup(children)}}
        >
        </p>
    );
};



Paragraph.propTypes = {
    className: PropTypes.string,
}



export default Paragraph;