import { useState } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";

const useSuscripcionesBajaWeb = () => {
    const { REACT_APP_APIPAYWALL } = process.env;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const outFunc = async (payload, callback) => {
        setIsLoading(true);
        const pathUrl = `${REACT_APP_APIPAYWALL}Suscripcion/SolicitarBajaWeb`;
        ApiService.post(pathUrl, payload)
            .then(data => {
                if (data && data.code === "0000") {
                    Datadog.trackCustomEvent(
                        CustomEventsDatadog.solicitudBajaSuscripcion,
                        {
                            suscripcionesABajar: payload.suscripcionesABajar,
                            motivo: payload.motivo
                        }
                    );
                    
                    callback?.()
                } else {
                    Datadog.trackCustomEventWarning(
                        CustomEventsErrorDatadog.errorSolicitudBajaSuscripcion,
                        {
                            suscripcionesABajar: payload.suscripcionesABajar,
                            codigo: data.code,
                            error: data.message
                        }
                    );
                    setError(data.message);
                    setIsLoading(false);
                };
            })
            .catch(error => {
                Datadog.trackCustomEventError(
                    CustomEventsErrorDatadog.errorSolicitudBajaSuscripcion,
                    {
                        suscripcionesABajar: payload.suscripcionesABajar,
                        error: error
                    }
                );
                setError(error);
                setIsLoading(false);
            })
            .finally(_ => {
                window.scrollTo(0, 0)
            });
    };

    return [data, error, isLoading, outFunc];
};

export default useSuscripcionesBajaWeb;
