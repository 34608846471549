import { useState } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";

const useObtenerEstrategiaRetencion = (setHabilidadBasicaStates) => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoadingEstrategia, setIsLoadingEstrategia] = useState(false);
    const [errorEstrategia, setErrorEstrategia] = useState(null);
    const [estrategia, setEstrategia] = useState(null);
  
    const fetchData = async (idMotivo, idRegimenFacturacion) => {
      setIsLoadingEstrategia(true);

      ApiService.get(`${REACT_APP_APIPAYWALL}Retencion/GetRetentionEstrategyByCrmIdReasonId?idMotivo=${idMotivo}&idRegimenFacturacion=${idRegimenFacturacion}`)
        .then((data) => {
          if (data?.code === "0000") {
            setEstrategia(data.response)
          } else {
            setErrorEstrategia(true);
            Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorObtenerHabilidadBasica, { response: data });
          }
          setIsLoadingEstrategia(false);
        })
        .catch((error) => {
          setErrorEstrategia(error);
          setIsLoadingEstrategia(false);
          Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorObtenerHabilidadBasica, { error });
        }).finally(() => {
          setHabilidadBasicaStates({ isLoadingHabilidadBasica: isLoadingEstrategia, errorHabilidadBasica: errorEstrategia})
        });
    };

    return [estrategia, errorEstrategia, isLoadingEstrategia, fetchData];
};
export default useObtenerEstrategiaRetencion;