import Datadog from "../../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../../Config/Datadog";
import Auth0 from '../../Services/Auth0Service';
import Cookies from 'js-cookie';
import Auth0Config from '../../Config/Auth0';


const useLinkGoogleAccount = (setIdentitiesProviderData, showAlertToast, setLoadingGoogle) => {

    const linkGoogleAccountProcess = (accessToken) => {
        const data = {
            primary_acccount_access_token: Cookies.get('access-token'),
            secondary_acccount_access_token: accessToken,
            secondary_account_provider: Auth0Config.GoogleConnection,
        };

        setLoadingGoogle(true);
        Auth0.LinkAccount(data)
            .then((apiResponse) => {
                if (apiResponse && apiResponse.code) {
                    if (apiResponse.code === '0000' || apiResponse.code === '0020') {
                        Datadog.trackCustomEvent(CustomEventsDatadog.vincularRedSocial, { redSocial: 'Google' });
                        let profiles = {};
                        apiResponse.response.forEach((identity) => {
                            profiles[identity.profile_name] = identity
                        });
                        setIdentitiesProviderData();
                        setLoadingGoogle(false);
                        showAlertToast('¡Tu vinculación con Google se realizó con éxito!', 'success');
                    } else {
                        Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorVincularRedSocial, { redSocial: 'Google' });
                        const errorMessage = apiResponse.code === '0003' || apiResponse.code === '0004' || apiResponse.code === '0007' ? 'El perfil de Google seleccionado ya se encuentra vinculado a otra cuenta' :
                            apiResponse.message;
                        showAlertToast(errorMessage, 'alert');
                        setLoadingGoogle(false);
                    }
                }
            })
            .catch((reason) => {
                console.log(`rejected by ${reason}`);
                setLoadingGoogle(false);
            });
    };

    return [linkGoogleAccountProcess];
};
export default useLinkGoogleAccount;
