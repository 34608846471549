import React from 'react'
import Toast from '../Common/Modules/Toast'

const ErrorGenerico = () => {
    return (
        <Toast status="warning" title="Ocurrió un error">Hubo un problema con tu solicitud. Por favor, inténtalo de nuevo.</Toast>
    )
}

export default ErrorGenerico
