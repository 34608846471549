import { useState } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import SessionHandler from '../../src/Handlers/Session';
import { useDispatch } from "../Store/storeProvider";

const useDarDeBajaCredencial = () => {
  const { REACT_APP_APIRECLAMOS } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const outFunc = async (idTitular, idCredencial, nroCredencial, tipoCredencial) => {
    setIsLoading(true);
    const pathUrl = `${REACT_APP_APIRECLAMOS}Credenciales/DarDeBajaCredencial`;
    return ApiService.post(pathUrl, {
      CrmidTitular: idTitular,
      CrmidCredencial: idCredencial,
      NumeroCredencial: nroCredencial,
    })
      .then((response) => {
        let success = false;
        if (response.Code == 0) {
          Datadog.trackCustomEvent(CustomEventsDatadog.bajaCredencial, { tipoCredencial });
          success = true;
          setIsLoading(false);
          setError(false);
          return success;
        } else {
          Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorBajaCredencial, { tipoCredencial })
          setIsLoading(false);
          setError(false);
          return success;
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError(true);
      })
      .finally(()=>{
        SessionHandler.Reload(dispatch)
      });
  };

  return [outFunc, error, isLoading];
};

export default useDarDeBajaCredencial;
