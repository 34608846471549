import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useObtenerPlanes = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [planes, setPlanes] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIPAYWALL}Plan/ObtenerDigitales`)
                .then(data => {
                    if (data && data.code === "0000") {
                        setPlanes(data.response);
                        setIsLoading(false);
                    } else {
                        setError(data.message);
                        setIsLoading(false);
                    };
                })
                .catch(error => {
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [planes, error, isLoading];
};

export default useObtenerPlanes;
