import { useState } from 'react';
import ApiService from '../Services/ApiService';
import { useDispatch, useStore } from '../Store/storeProvider';

const useObtenerSuscripcionesOnDemand = (includeAddressStatus = false) => {
    const { StateSuscripciones } = useStore();
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [suscripciones, setSuscripciones] = useState([]);
    const [error, setError] = useState(null);
    const dispatch = useDispatch(); 

    const saveState = (result) => {
        dispatch({
            type: 'Suscripciones',
            value: {
                suscripciones: result,
                fetchSuscripciones: false
            },
        });
    }

    const fetchData = () => {
        if (StateSuscripciones?.suscripciones && !StateSuscripciones.fetchSuscripciones) {
            setSuscripciones(StateSuscripciones?.suscripciones);
        } else {
            setIsLoading(true);
            setError(null);
            ApiService.post(`${REACT_APP_APIPAYWALL}Suscripcion/ObtenerSuscripciones?IncludeAddressStatus=${includeAddressStatus}`)
                .then((data) => {
                    if (data && data.code === '0000') {
                        setSuscripciones(data.response);
                        saveState(data.response);
                    } else {
                        setError(data.message);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        }
    };

    return [suscripciones, isLoading, error, fetchData];
};

export default useObtenerSuscripcionesOnDemand;
