const trackClick = (category, action, label, additional) => {
    trackAnalytics('trackClick', category, action, label, additional);
}

const trackClickBtnSuscribirme = (category, action, label, additional) => {
    trackAnalyticsDynamicEvent('e_linkclick', category, action, label, additional);
}

const trackClickModalPromoNoSuscriptor = (category, action, label, additional) => {
    trackAnalyticsDynamicEvent('e_linkclick', category, action, label, additional);
}

const trackEvent = (category, action, label, additional) => {
    trackAnalytics('trackEvent', category, action, label, additional);
}

const trackActionNotification = (additional) => {
    trackAnalytics('action_notification', 'campanita', 'campanita', 'N/A', additional);
}

const trackAnalyticsDynamicEvent = (type, category, action, label, additional) => {
    let data = {
        event: type,
        dynamic_action: action,
        dynamic_category: category,
        dynamic_label: label
    };
    data = { ...data,...additional};
    window.dataLayer.push(data);
}

const trackAnalytics = (type, category, action, label, additional) => {
    let data = {
        event: type,
        eventCategory: category,
        eventAction: action,
        eventLabel: label
    };
    data = { ...data,...additional};
    window.dataLayer.push(data);
}


export default { trackClick, trackEvent, trackActionNotification, trackClickBtnSuscribirme, trackClickModalPromoNoSuscriptor } 