const stepsUpselling = {
	step_1: {
		number: 1,
		text: 'Pago',
	},
	step_2: {
		number: 2,
		text: 'Completar domicilio',
	},
	step_3: {
		number: 3,
		text: 'Confirmacion',
	}
};

export default stepsUpselling;
