import React from 'react';

import classNames from 'classnames';
import '../../../Statics/Scss/Components/TotalImport.scss';

import Paragraph from '../Atoms/Paragraph';
import Title from '../Atoms/Title';

const TotalImport = ({className, cost = 0, ...r}) => {
	const classes = classNames('total-import', className);

	return (
		<article className={classes} {...r}>
			<Title size="--sixxs">IMPORTE TOTAL</Title>
			<Paragraph size="--l">${cost.toFixed(2)}</Paragraph>
		</article>
	);
};

export default TotalImport;
