import { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";

const useObtenerRelacionClub = () => {
  let { REACT_APP_APIPAYWALL } = process.env;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const pathUrl = `${REACT_APP_APIPAYWALL}Credencial/ObtenerRelacionClub`;
      ApiService.post(pathUrl, {})
        .then(data => {
          if (data) {
            data.nroCredencial = data.response?.nroCredencial;
            setData(data)
            setError(null)
            setIsLoading(false)
          }
          else {
            setError(true)
            setIsLoading(false)
          }
        })
        .catch(error => {
          setError(error)
          setIsLoading(false)
        });
    };
    fetchData();
  }, []);

  return [data, error, isLoading];
};

export default useObtenerRelacionClub;
