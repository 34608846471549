import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Link from '../../Common/Atoms/Link';
import Toast from '../../Common/Modules/Toast';

function DowngradeAviso(props) {
    let { nombreActual } = props;
    const history = useHistory();

    return (
        <Toast status="info" title={`Ya contás con los beneficios de ${nombreActual}`}>
            Podés asignar las credenciales desde el menú <Link href={PathsConfig.MisCredenciales} text="Mis Credenciales"></Link>.
        </Toast>
    );
}

DowngradeAviso.propTypes = {
    nombreActual: PropTypes.string.isRequired,
};

export default DowngradeAviso;
