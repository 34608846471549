import React from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../Components/Common/Modules/Breadcrumb";
import Toast from "../../../Components/Common/Modules/Toast";
import Button from "../../../Components/Common/Modules/Button";
import Paragraph from "../../../Components/Common/Atoms/Paragraph";
import ParagraphList from "../../../Components/Common/Modules/ParagraphList";
import TitleWeb from "../../../Components/Common/Web/Title";
import CallbackArticle from "../../../Components/Common/Modules/CallbackArticle";
import Wizard from "../../../Components/Common/Modules/Wizard";
import Divider from "../../../Components/Common/Atoms/Divider";
import steps from "../../../Config/StepsSuscripcion";
import "../../../Statics/Scss/Pages/SuspenderPorVacaciones3.scss";
import ContactoDudasBanner from "../../../Components/Common/Banners/ContactoDudas";
import useRedirectWithout from "../../../Hooks/useRedirectWithout";
import NotasConfirmacionSuspension from "../../../Components/Common/Banners/NotasConfirmacionSuspension";
import PathsConfig from "../../../Config/RoutePaths";
import FormRow from "../../../Components/Common/Atoms/Form/FormRow";
import { mailAtencionSocios } from "../../../Config/DatosDeContacto";

const ConfirmacionSuspension = (props) => {
  const history = useHistory();
  const stepProps = [
    "suspensionGenerada",
    "suscripcionSeleccionado",
    "success",
    "anulable",
    "palabra",
  ];
  const {
    suspensionGenerada,
    suscripcionSeleccionado,
    anulable,
    success,
    numeroUnicoDeGestion,
    palabra,
  } = useRedirectWithout(
    props.location.state,
    stepProps,
    PathsConfig.SuspensionVacaciones
  );

  const handleButton = (history, suspensionGenerada) => {
    // volver a paso 1.
    if (success) {
      history.push(PathsConfig.SuspensionVacaciones);
    } else {
      // en caso de error volver al paso 2.
      history.push({
        pathname: PathsConfig.SeleccionFechaSuspension,
        state: {
          model: suspensionGenerada,
          suscripcionSeleccionado: suscripcionSeleccionado,
          anulable: anulable,
        },
      });
    }
  };

  return (
    <>
      <section className="lay-strechted">
        <Breadcrumb
          firstItem={"Mis suscripciones"}
          href={"#"}
          text={"Suspender por vacaciones"}
        />

        <TitleWeb text="Suspender por vacaciones" />
      </section>

      <section className="container-streched">
        <Wizard steps={steps} activeStep={3} />

        {success ? (
          <>
            <Toast
              status={"success"}
              title={`Tu suspensión se ${palabra} correctamente`}
            ></Toast>
            <CallbackArticle
              title="Información de tu suspensión por vacaciones"
              description={
                <ParagraphList>
                  <NotasConfirmacionSuspension
                    numeroUnicoDeGestion={numeroUnicoDeGestion}
                    suscripcionSeleccionado={suscripcionSeleccionado}
                    subscriptionCanceled={
                      suscripcionSeleccionado.SituacionSuspensionVacaciones &&
                      !suspensionGenerada.fechaDesde
                        ? true
                        : false
                    }
                    suspensionGenerada={suspensionGenerada}
                  />
                </ParagraphList>
              }
            />
          </>
        ) : (
          <Toast
            status={"warning"}
            title="No pudimos procesar tu solicitud de suspensión por vacaciones"
          >
            <Paragraph className="--arial --font-regular">
              Por favor, intentalo de nuevo. Si el error persiste comunicate con
              nosotros a{" "}
              <span className="email">
                <strong>{mailAtencionSocios}</strong>
              </span>
            </Paragraph>
          </Toast>
        )}
        <FormRow mod="--btn-groups">
          <Button
            onClick={() => handleButton(history, suspensionGenerada)}
            label={success ? "SUSPENDER OTRO PRODUCTO" : "VOLVER A INTENTAR"}
            mod="state--mobile-w100"
          />
        </FormRow>
        <Divider />
        <ContactoDudasBanner />
      </section>
    </>
  );
};

export default ConfirmacionSuspension;
