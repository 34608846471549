import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label from '../../Common/Atoms/Form/Label';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import InputBox from '../../Common/Atoms/Form/InputBox';
import RadioInput from '../../Common/Atoms/Form/RadioInput';
import classNames from 'classnames';
import estadoSuscripciones from '../../../Models/EstadoSuscripciones';

const propTypes = {
    suscripciones: PropTypes.array.isRequired,
    susSelected: PropTypes.number,
    onSuscripSelected: PropTypes.func.isRequired,
};

const Suscripcion = (props) => {
    const { suscripciones, susSelected, onSuscripSelected, className } = props;
    const [optionChecked, setOptionChecked] = useState(susSelected);
    const classes = classNames('suscripcion', className);

    const handleChange = (event) => {
        setOptionChecked(event.target.value);
        onSuscripSelected(suscripciones.find((i) => i.Id == event.target.value));
    };

    const obtenerVigenciaSuscripcion = (suscrip) => {
        if (suscrip.Estado == estadoSuscripciones.ACTIVA) {
            return "Vigente";
        } else {
            const fechaFin = new Date(suscrip.FechaFin);
            return "Fecha hasta: " + fechaFin.getDate() + "/" + fechaFin.getMonth() + "/" + fechaFin.getFullYear();
        }
    }

    return (
        <div className={classes}>
            <Label text="Mis suscripciones" />
            <InputGroup>
                {suscripciones &&
                    suscripciones.map((suscrip) => {
                        return (
                            <InputBox
                                key={suscrip.Id}
                                input={
                                    <RadioInput checked={suscrip.Id == optionChecked} name={'suscripcion'} value={suscrip.Id} onChange={handleChange}>
                                        {suscrip.Nombre}
                                    </RadioInput>
                                }
                                text={obtenerVigenciaSuscripcion(suscrip)}
                            />
                        );
                    })}
            </InputGroup>
        </div>
    );
};

Suscripcion.propTypes = propTypes;

export default Suscripcion;
