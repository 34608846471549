import React, { useState } from 'react';
import { Button } from '@ln/experiencias-ui-button';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import { SeccionBeneficios } from './components/SeccionBeneficios';
import { CarouselBeneficios } from './components/CarouselBeneficios';
import { ModalSolicitarBaja } from './ModalSolicitarBaja';
import { Text } from '@ln/common-ui-text';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Datadog from '../../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../../Config/Datadog';

export const ListadoBeneficiosUtilizados = ({
    fechaDesde,
    fechaHasta,
    consumosTitular,
    notasAndNewslettersTitular,
    consumosAdicionales,
    notasAndNewslettersAdicionales,
    tipoDePlan,
    tipoDeSuscripcion,
    state,
}) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal((prev) => !prev);
    const handleMantenerSuscripcion = () => {
        history.push({
            pathname: PathsConfig.MantenerSuscripcion,
            state: {...state, tipoDeSuscripcion: tipoDeSuscripcion }
        });

        Datadog.trackCustomEvent(CustomEventsDatadog.solicitudRetencion, { origen: PathsConfig.BeneficiosUtilizados })
    };

    const shouldShowTitular = !((consumosTitular?.TotalBeneficios == null || consumosTitular?.TotalBeneficios == undefined) && (notasAndNewslettersTitular?.porSeccionUltimos30Dias == null || notasAndNewslettersTitular?.porSeccionUltimos30Dias.length == 0)) &&
        !(tipoDeSuscripcion == 'lnClub' && notasAndNewslettersTitular?.newsletters.length > 0 && notasAndNewslettersTitular?.porSeccionUltimos30Dias.length == 0) != false
    const shouldShowAdicionalesValidation = notasAndNewslettersAdicionales && notasAndNewslettersAdicionales.length > 0;

    const shouldShowAdiccionales = () => {
        const consumos = consumosAdicionales?.some(consumo => consumo.Beneficios.length > 0)
        const mostrarAdicionales = notasAndNewslettersAdicionales?.some(notasAndNewsletters => (notasAndNewsletters?.newsletters.length == 0 && notasAndNewsletters?.ultimos90Dias == 0 && consumos == false) == false);

        return mostrarAdicionales && shouldShowAdicionalesValidation
    }

    return (
        <div className="flex flex-column gap-32">
            {
                shouldShowTitular && (
                    <div className="flex flex-column gap-8">
                        <Text as="h5" className="arial-bold text-18 text-neutral-light-800">
                            Te compartimos como utilizaste tu suscripción los últimos meses:
                        </Text>
                        <div className="flex flex-column gap-24">
                            <SeccionBeneficios
                                tipoDeSuscripcion={tipoDeSuscripcion}
                                Nombre={(notasAndNewslettersTitular?.nombre.toUpperCase() + " " + notasAndNewslettersTitular?.apellido.toUpperCase())}
                                Credencial={notasAndNewslettersTitular?.disenioCredencial}
                                DetallesBeneficio={consumosTitular}
                                NotasLeidas={notasAndNewslettersTitular?.ultimos90Dias}
                                NotasMasLeidas={notasAndNewslettersTitular?.porSeccionUltimos30Dias}
                                Newsletters={notasAndNewslettersTitular?.newsletters}
                                FechaDesde={fechaDesde}
                                FechaHasta={fechaHasta}
                                visible={true}
                            />
                    </div>
                </div>
                )
            }
                

            {shouldShowAdiccionales() && (
                <div className="flex flex-column gap-8">
                    <Text as="h5" className="arial-bold text-18 text-neutral-light-800">Tus adicionales están disfrutando sus beneficios:</Text>
                    <div className="flex flex-column gap-24">
                        {notasAndNewslettersAdicionales.map((notasAndNewsletters, i) => {
                            const nombreAdicional = notasAndNewsletters.nombre.toUpperCase() + ' ' + notasAndNewsletters.apellido.toUpperCase();
                            const consumoAdicional = consumosAdicionales.find((item) => item.Nombre.toUpperCase() === nombreAdicional);
                            return (
                                <SeccionBeneficios
                                    tipoDeSuscripcion={tipoDeSuscripcion}
                                    key={`seccion-beneficio-${i}`}
                                    Nombre={nombreAdicional}
                                    Credencial={notasAndNewsletters.disenioCredencial}
                                    DetallesBeneficio={consumoAdicional}
                                    NotasLeidas={notasAndNewsletters.ultimos90Dias}
                                    NotasMasLeidas={notasAndNewsletters.porSeccionUltimos30Dias}
                                    Newsletters={notasAndNewsletters.newsletters}
                                    FechaDesde={fechaDesde}
                                    FechaHasta={fechaHasta}
                                    closedOnLoad
                                />
                            );
                        })}
                    </div>
                </div>
            )}

            <div className="flex flex-column gap-8">
                <Text as="h5" className="arial-bold text-18 text-neutral-light-800">Descubrí todos estos beneficios con tu suscripción</Text>
                <CarouselBeneficios plan={tipoDeSuscripcion} pantalla={PathsConfig.BeneficiosUtilizados} />
            </div>

            <Groupbutton>
                <Button title="MANTENER MI SUSCRIPCION" onClick={handleMantenerSuscripcion}>
                    MANTENER MI SUSCRIPCION
                </Button>
                <Button title="CONTINUAR CON LA BAJA" variant="secondary" onClick={toggleModal}>
                    CONTINUAR CON LA BAJA
                </Button>
            </Groupbutton>

            <ModalSolicitarBaja show={showModal} handleCloseModal={toggleModal} plan={tipoDePlan} state={{...state, tipoDeSuscripcion: tipoDeSuscripcion }} />
        </div>
    );
};
