import React from 'react';
import { Text } from "@ln/common-ui-text";
import { Icon } from "@ln/common-ui-icon";
import { DigitalSubscriber as Crow, Padlock as Lock} from '@ln/experiencias-ui-assets';
import classNames from 'classnames';

export const PlanDetails = ({ variant, badge }) => {

  const getSubscriptionProps = (subscription) => {
    if (subscription === 'conSuscripcion')

      return {
        icon: <Crow />,
        bgColor: "bg-orange-400"
      };

    return {
      icon: <Lock />,
      bgColor: "bg-neutral-light-200"
    };
  };

  const { icon, bgColor } = getSubscriptionProps(variant);

  return (
    <div className="flex flex-column gap-4 min-w-0">
      <Text className='text-10 text-neutral-light-600'>PLAN</Text>
      <div className='flex ai-center gap-4'>
        <Icon size={16} className={classNames("rounded-circle content-box", bgColor)}>
          {icon}
        </Icon>
        <Text className='arial-bold text-12 text-neutral-light-800 white-space-nowrap text-ellipsis-1'>{badge}</Text>
      </div>
    </div>
  )
}

export default PlanDetails;