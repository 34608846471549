import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../../Statics/Scss/Components/CardBox.scss';

import Title from '../Atoms/Title';
import Paragraph from '../Atoms/Paragraph';
import CardBoxItem from './CardBoxItem';
import CheckInput from '../Atoms/Form/CheckInput';

const propTypes = {
    /**
     * Período asociado.
     */
    periodoContenido: PropTypes.string,
    /**
     * Número de la factura asociado.
     */
    numeroFacturaContenido: PropTypes.string,
    /**
     * Título asociado.
     */
    estadoTitulo: PropTypes.string,
    /**
     * Costo asociado.
     */
    costo: PropTypes.string,
    /**
     * Motivo del rechazo asociado.
     */
    motivoRechazo: PropTypes.string,
    /**
     * Flag, ¿está deshabilitado?
     */
    disabled: PropTypes.bool,
    /**
     * Flag, ¿contiene error?
     */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /**
     * Últimos números de la tarjeta.
     */
    lastNumbers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
    /**
     * Handler para la acción check.
     */
    onCheck: PropTypes.func,
    /**
     * Flag, ¿está checked?
     */
    checked: PropTypes.bool,
};

const defaultProps = {
    onCheck: () => {},
};

const CardBox = forwardRef(
    (
        {
            periodoContenido,
            numeroFacturaContenido,
            estadoTitulo,
            estadoDescripcion,
            costo,
            motivoRechazo,
            disabled,
            error,
            lastNumbers,
            className,
            onCheck,
            checked,
            cardType,
            ...r
        },
        ref
    ) => {
        const classes = classNames('mc-card-box', className, {
            '--error': error,
            '--checked': checked,
        });

        const onChange = (e) => {
            onCheck(e, costo);
        };

        return (
            <label className={classes} {...r}>
                <div className="checkbox">
                    <CheckInput disabled={disabled} checked={checked} onChange={onChange} ref={ref} />
                </div>

                <div className="content">
                    <div className="heading">
                        <div className="description">
                            <Title size="--fivexs" mod="--arial --font-bold">
                                {estadoTitulo}
                            </Title>
                            <Paragraph size="--twoxs" mod="--arial --font-bold">
                                {estadoDescripcion}
                            </Paragraph>
                        </div>

                        <Paragraph size="--s" mod="--arial --font-bold">{`$${costo}`}</Paragraph>
                    </div>

                    <div className="data">
                        {lastNumbers && (
                            <CardBoxItem
                                title={'Método de pago'}
                                isCard={true}
                                cardType={cardType}
                                lastNumbers={lastNumbers}
                                className="--mobile-three-items"
                            />
                        )}
                        {periodoContenido && (
                            <CardBoxItem title={'Período'} className="--mobile-three-items">
                                {periodoContenido}
                            </CardBoxItem>
                        )}
                        {numeroFacturaContenido && <CardBoxItem title={'Número de factura'}>{numeroFacturaContenido}</CardBoxItem>}
                    </div>

                    <Paragraph size="--fivexs" className="--arial --font-regular">
                        {motivoRechazo}
                    </Paragraph>
                </div>
            </label>
        );
    }
);

CardBox.propTypes = propTypes;
CardBox.defaultProps = defaultProps;

export default CardBox;
