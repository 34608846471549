import React from "react";
import PropTypes from "prop-types";
import Paragraph from "../Atoms/Paragraph";
import DateHelper from "../../../Helpers/Date";

const propTypes = { 
    suscripcionSeleccionado: PropTypes.object,
    subscriptionCanceled: PropTypes.bool,
    suspensionGenerada: PropTypes.object
};

const NotasConfirmacionSuspension = (props) => {
    const {suscripcionSeleccionado, subscriptionCanceled, suspensionGenerada, numeroUnicoDeGestion } = props;
    const desde = DateHelper.correctTimeZone(suspensionGenerada.fechaDesde);
    const hasta = DateHelper.correctTimeZone(suspensionGenerada.fechaHasta);
    return (
        <>
            <Paragraph size="--twoxs" className="--arial --font-regular">
                Tu suscripción a{" "}
                <strong>
                    {suscripcionSeleccionado.NombreAmigable} 100% dto 3 meses
                </strong>{" "}
                {!subscriptionCanceled ? (
                <>
                    será suspendida por vacaciones desde{" "}
                    <strong>
                        {DateHelper.convertDate_ddMMyyyy(
                            desde
                        )}
                    </strong>{" "}
                    hasta{" "}
                    <strong>
                        {DateHelper.convertDate_ddMMyyyy(
                            hasta
                        )}
                    </strong>
                </>
                ) : (
                <>fue anulada</>
                )}
            </Paragraph>

            <Paragraph size="--twoxs" className="--arial --font-regular">
                El número de gestión correspondiente es{" "}
                <span className="gestionNumber">
                    <strong>{numeroUnicoDeGestion}</strong>
                </span>
            </Paragraph>
        </>
    );
};

NotasConfirmacionSuspension.propTypes = propTypes;

export default NotasConfirmacionSuspension;
