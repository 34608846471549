import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Icon from "../Atoms/Icon";
import Title from "../Atoms/Title";
import Paragraph from "../Atoms/Paragraph";

import "../../../Statics/Scss/Modules/Toast.scss";

const propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  mod: PropTypes.string,
  value: PropTypes.bool,
  positionFloat: PropTypes.string,
};

const Toast = ({
  title,
  children,
  status,
  close,
  mod,
  changeValue,
  value,
  closeAuto,
  positionFloat
}) => {
  /*
    cierra el toast agegando animacion
    value - boolean - hook state heredado de parent
    changeValue - fun - hook fun modif el value
  */
  const handleCloseToast = (value, changeValue) => {
    if (value) {
      let $toast = document.querySelector(".mod-toast");

      if ($toast == null) {
        return false;
      }

      $toast.classList.add("fadeOutUp");
      setTimeout(() => {
        changeValue(!value);
      }, 600);
    } else {
      changeValue(!value);
    }
  };

  /*
    cierra el toast con un determinado tiempo | validamos que no ejecute la fun si el user toca antes la x
    closeAuto - number - ms que uqeremos que tenga antes que desaparezca
  */
  const handleCloseAuto = (closeAuto) => {
    closeAuto = typeof closeAuto === "boolean" ? 6000 : closeAuto;

    const interval = setInterval(() => {
      if (value == true) {
        handleCloseToast(value, changeValue);
        clearInterval(interval);
      }
    }, closeAuto);
  };

  useEffect(() => {
    if (closeAuto) {
      handleCloseAuto(closeAuto);
    }
  }, []);

  const className = `mod-toast${` --${status}` || ""}${` ${mod}` || ""}${
    positionFloat ? ` --positionFloat --${positionFloat} fadeInDown` : ""
  }`;

  return (
    <div className={className}>
      <div className="state">
        {close && (
          <div
            onClick={() => {
              handleCloseToast(value, changeValue);
              if (typeof Function === typeof close) {
                close();
              }
            }}
          >
            <Icon name="close" mod={"close"} />
          </div>
        )}

        {
          {
            success: <Icon name="check-mark" />,
            warning: <Icon name="error-warning" mod={"error-warning"} />,
            info: <Icon name="info" mod={"info"} />,
            alert: <Icon name="alert" mod={"alert"} />,
          }[status]
        }
      </div>
      <div className="mod-description">
        <Title tag="h3" size="--s" className="--arial --font-bold">
          {title}
        </Title>

        {children && (
          <Paragraph size="--twoxs" className="--arial --font-regular">
            {children}
          </Paragraph>
        )}
      </div>
    </div>
  );
};

Toast.propTypes = propTypes;

export default Toast;
