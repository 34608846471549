import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";

const useGetNotasLeidas = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [notasLeidas, setNotasLeidas] = useState({});
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            ApiService.get(`${REACT_APP_APIPAYWALL}Retencion/GetReadArticles`)
                .then((data) => {
                    if (data?.code === "0000") {
                        setNotasLeidas(data.response);
                    }else{
                        setError(true);
                        Datadog.trackCustomEventWarning( CustomEventsErrorDatadog.errorObtenerNotasLeidas, { response: data })
                    }
                })
                .catch((error) => {
                    setError(error);
                    Datadog.trackCustomEventError( CustomEventsErrorDatadog.errorObtenerNotasLeidas, { error })
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);
    return [notasLeidas, error, isLoading];
};

export default useGetNotasLeidas;
