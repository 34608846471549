import React, { useState } from 'react';
import TitleWeb from '../../../Components/Common/Web/Title';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import PathsConfig from '../../../Config/RoutePaths';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Text from '../../../Components/Common/Text/Text';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Domicilios from '../../../Components/Features/Domicilios';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import { formatearDomicilio } from '../../../Helpers/DomicilioFormatter';
import stepsUpselling from '../../../Config/StepUpselling';
import Button from '../../../Components/Common/Modules/Button';
import Toast from '../../../Components/Common/Modules/Toast';
import { useEffect } from 'react';
import { domicilioCredencialMapper } from '../../../Map/DomicilioCredencialMap';
import { usePostDomicilioCredencial } from '../../../Hooks/usePostDomicilioCredencial';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import Cookies from 'js-cookie';
import Date from '../../../Helpers/Date';
import { readCookie } from '../../../Helpers/Cookies';

export const UpsellingAdClubDomicilio = () => {
    const stepProps = ["IdCanalDeVenta", "IdCampaniaUpsell", "IdFormaDeContacto"];
    const { plan } = useParams();
    const { state } = useLocation();
    const { IdCanalDeVenta, IdCampaniaUpsell, IdFormaDeContacto } = useRedirectWithout(state, stepProps, PathsConfig.MisSuscripciones);
    const history = useHistory();
    const [idDomicilioSelected, setIdDomicilioSelected] = useState(null);
    const [domicilioUnchecked, setDomicilioUnchecked] = useState(false);
    const [cambioDomicilioSuscripciones, setCambioDomicilioSuscripciones] = useState(true);
    const [error, setError] = useState(false);
    const [modalCambioDomicilioSuscripciones, setModalCambioDomicilioSuscripciones] = useState(false);
    const [domicilioSeleccionado, setDomicilioSeleccionado] = useState(null);
    const { errorDomicilio, isLoading, postDomicilioCredencial } = usePostDomicilioCredencial();
    const usuarioNombre = Cookies.get('usuario_detalle_nombre')
    const usuarioApellido = Cookies.get('usuario_detalle_apellido')

    const setAddressData = (domicilio, nuevo) => {
        if (nuevo) {
            setDomicilioSeleccionado(formatearDomicilio(domicilio));
        } else {
            setDomicilioSeleccionado(domicilio);
        }
    };

    const routeConfirmOnSuccess = (data) => {
        const name = `${usuarioNombre} ${usuarioApellido}`
        history.push({
            pathname: `${PathsConfig.UpsellingConfirmar}/${plan}/confirmacion`,
            state:{ 
                numeroCredencial: data.NumeroCredencial,
                tipoCredencial: data.CategoriaCredencial.Descripcion,
                domicilio: data.Domicilio,
                fechaEstimada: Date.changeDateStringFormat(data.FechaEstimadaEntrega),
                nombre: usuarioNombre ? name : null
            }
        });
    };

    const handleContinuar = () => {
        if(domicilioSeleccionado){
            let domicilioMap = domicilioCredencialMapper(domicilioSeleccionado, IdCanalDeVenta, IdCampaniaUpsell);
            let payload = {
                dataDomicilio: domicilioMap,
                IdFormaDeContacto,
            };
    
            postDomicilioCredencial(payload, routeConfirmOnSuccess);
        }else{
            setDomicilioUnchecked(true)
        }
    };

    useEffect(() => {
        if (errorDomicilio) {
            setError(true);
        }
    }, [errorDomicilio]);

    return (
        <>
            <section className="lay-strechted">
                <Breadcrumb firstItem="Mis suscripciones" href="#" text="Cambiar a Acceso Digital + Club" />
                <TitleWeb text="Cambiar a Acceso Digital + Club" />
            </section>
            <section className="container-streched">
                <LoadingSpinner isLoading={isLoading}>
                    {error && (
                        <Toast closeAuto={3000} status="warning" value={error} changeValue={setError} title="¡Uppps!">
                            No pudimos procesar tu solicitud. Por favor, intentalo de nuevo. Si el error persiste comunicate con nosotros a
                            atencionsocios@lanacion.com.ar
                        </Toast>
                    )}
                    <Wizard steps={stepsUpselling} activeStep={2} />
                    <Toast value={true} status="success" title="¡Listo! Tu método de pago se confirmó con éxito"></Toast>
                    <Domicilios
                        setDomicilio={setIdDomicilioSelected}
                        errorForm={domicilioUnchecked}
                        setErrorForm={setDomicilioUnchecked}
                        cambiarDomicilioDeEntrega={cambioDomicilioSuscripciones}
                        setCambiarDomicilioDeEntrega={setCambioDomicilioSuscripciones}
                        modalCambioDomicilioEntrega={modalCambioDomicilioSuscripciones}
                        setModalCambioDomicilioEntrega={setModalCambioDomicilioSuscripciones}
                        idDomicilioDeEntrega={idDomicilioSelected}
                        setAddressData={setAddressData}
                        esCambioDomicilioDeEntrega={true}
                        isUpselling={true}
                    />
                    <FormRow mod="--btn-groups --mb-32">
                        <Button mod="state--mobile-w100" label="CONTINUAR" onClick={handleContinuar} />
                    </FormRow>
                </LoadingSpinner>
            </section>
        </>
    );
};
