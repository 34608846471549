import { useState } from 'react';
import { trackPage, trackError } from '../Helpers/UpsellingTracker';
import Mensaje from '../Models/Mensaje';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog } from '../Config/Datadog';
import ApiService from '../Services/ApiService';

const useGetInfoPlanesUpselling = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [infoConfirmacion, setInfoConfirmacion] = useState(null);

    const fetch = (props) => {
        setIsLoading(true);
        const payload = { Campania: props.campania, Planes: props.planes }
        ApiService.post(`${REACT_APP_APIPAYWALL}CambioPlanV1/GetInfoPlanesUpselling`, payload)
            .then((res) => {
                Datadog.trackCustomEvent(CustomEventsDatadog.consultaUpselling, { planUpselling: { PlansIds: props.planes }, origen: props?.origen });
                setIsLoading(false);
                if (res.code == '0000') {
                    setInfoConfirmacion(res.response);
                    trackPage(null, props?.origen, res.response);
                } else {
                    throw new Error();
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setError(Mensaje.getError('GENERAL'));
                trackError(props?.origen);
            });
    };

    return [infoConfirmacion, error, loading, fetch];
};
export default useGetInfoPlanesUpselling;