import React, { useState } from 'react';
import PropTypes from 'prop-types'
import PaginationWrapper from '../../Common/Modules/Pagination/PaginationWrapper';
import InputList from '../../Common/Atoms/Form/InputsList';
import NoEditions from '../../../Components/Common/Modules/NoResults';
import CheckBoxesMap from '../../../Map/DespachoToPaginationCheckboxesMap';
import Label from '../../Common/Atoms/Form/Label';
import Link from '../../../Components/Common/Atoms/Link';
import { EstadosDespacho } from '../../../Models/Despacho';

const propTypes = {
    despachos: PropTypes.array,
    onSelected: PropTypes.func.isRequired,
    onInvalid: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    isRevista: PropTypes.bool
}

const SelectorDespachos = (props) => {
    const {invalid, onInvalid, despachos, isRevista} = props;
    const [despachosSelected, setSelected] = useState([]);
    const [despachosAllSelected, setDespachosAllSelected] = useState(false);

    const handleChecked = event => {
        const val = event.target.value;
        let selected = despachosSelected;
        let validation = invalid;
        if (event.target.checked) {
            selected = [...despachosSelected, val];
            validation = false;
        } else {
            const despachosRestantes = despachosSelected.filter(despacho => despacho != val)
            selected = despachosRestantes;
            if (!despachosRestantes.length) {
                validation = true;
            }
        }

        setSelected(selected);
        // send valid state and selected array to parent
        props.onSelected(selected);
        onInvalid(validation);
    };
    
    const handleSelectAll = () => {
        let allSelected = [];         
        if (!despachosAllSelected){
            Array.from(despachos).map(   
                desp => {
                    const disabledDespachos = desp.EstaReclamado || desp.Inhabilitado || (desp.EstadoDespacho.Id !== EstadosDespacho.Entregado.Id && desp.EstadoDespacho.Id !== EstadosDespacho.EnDevolucion.Id && desp.EstadoDespacho.Id !== EstadosDespacho.EnGestionVencido.Id);
                    if (!disabledDespachos)
                        allSelected.push(`${desp.Id}`);
                }
            )
        }
        setSelected(allSelected);
        props.onSelected(allSelected);
        setDespachosAllSelected(!despachosAllSelected);
    }

    return (
        (!despachos || despachos.length == 0) ?
            <NoEditions
                iconName="search"
                title="No hay ninguna edición en el rango de fechas seleccionado."
                description="Intenta modificando el rango de fechas en el filtro. En las siguientes fechas especiales no hay entregas: 1 de Enero, Viernes Santo, 1 de Mayo, 7 de Noviembre y 25 de Diciembre."
            /> :
            <>
                <PaginationWrapper items={CheckBoxesMap(despachos, handleChecked, despachosSelected)}>
                    <InputList
                        cols= {isRevista ? '1' : '2'}
                        hasRowSpacing={true}
                        label={
                            <Label text="Ediciones" className="--fourxs"
                                link={<Link onClick={handleSelectAll} mod="--font-regular">{despachosAllSelected ? "Deseleccionar todos" : "Seleccionar todos" }</Link>}
                            />
                        }>
                    </InputList>

                </PaginationWrapper>
                {invalid && <span className="--red-500">Seleccioná al menos una edición que desees reclamar.</span>}
            </>
    )
}


SelectorDespachos.propTypes = propTypes;

export default SelectorDespachos;