import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../../../Statics/Scss/Components/Skeleton.scss";

const propTypes = {
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * El elemento a utilizar.
   */
  as: PropTypes.oneOf(["div", "span", "article", "section"]),
  /**
   * Flag, ¿es un círculo?
   */
  circle: PropTypes.bool,
  /**
   * Flag, ¿es un cuadrado?
   */
  square: PropTypes.bool,
  /**
   * Flag, ¿ocupar el ancho y el alto
   * del padre?
   */
  fill: PropTypes.bool,
  /**
   * Número de líneas a renderizar.
   */
  lines: PropTypes.number,
  /**
   * Flag, ¿eliminar los márgenes?
   */
  noMargin: PropTypes.bool,
  /**
   * Ancho específico del elemento.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Alto específico del elemento.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  as: "div",
};

const Skeleton = ({
  as: Component,
  className,
  circle,
  square,
  fill,
  lines,
  noMargin,
  width,
  height,
  ...r
}) => {
  const classes = classNames("skeleton", className, {
    "--circle": circle,
    "--square": square,
    "--fill": fill,
    "--lines": lines,
    "--no-margin": noMargin,
  });
  const width_ = typeof width === "string" ? width : width && `${width}px`;
  const height_ = typeof height === "string" ? height : height && `${height}px`;
  const styles = {
    ...(width && { width: width_ }),
    ...(height && { height: height_ }),
  };
  const Element = () => <Component className={classes} style={styles} {...r} />;

  return !lines ? (
    <Element />
  ) : (
    <>
      {[...Array(lines).keys()].map((_) => (
        <Element />
      ))}
    </>
  );
};

Skeleton.propTypes = propTypes;
Skeleton.defaultProps = defaultProps;

export default Skeleton;
