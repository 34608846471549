import React from 'react'
import { Text } from '@ln/common-ui-text'
import { Button } from '@ln/experiencias-ui-button'

export const CardBeneficiosItem = ({ icon, quantity, title, description, button, variant }) => {
  if (!quantity && !icon) return <></>

  const { text: buttonText, ...buttonProps } = button || {};

  const getFormattedQuantity = (quantity) => {
    const strQuantity = quantity.toString();
    return strQuantity.length > 1 ? strQuantity : `0${strQuantity}`
  }

  return (
    <div className={`grid ai-center gap-16 ${icon ? 'grid-cols-36-fr' : 'grid-cols-56-fr'}`}>
      <div className='my-auto text-center'>
        {icon}
        {quantity && (
          <Text className={`arial-bold text-32 ${variant === 'club' ? 'text-blue-400' : 'text-blue-500'}`}>
            {getFormattedQuantity(quantity)}
          </Text>
        )}
      </div>
      <div className='flex flex-column'>
        {title && <Text as='p' className='arial-bold text-16'>{title}</Text>}
        {description && <Text as='p' className='text-14 text-neutral-light-600'>{description}</Text>}
        {buttonText && <Button variant='link' {...buttonProps}><span className='text-14 arial-regular'>{buttonText}</span></Button>}
      </div>
    </div>
  )
}
