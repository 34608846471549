import React, { useState, useEffect } from "react";
import IconCheck from "../Statics/Imgs/iconCheck";
import PersonModal from "../Statics/Imgs/personModal";
import PhoneModal from "../Statics/Imgs/phoneModal";
import TimeModal from "../Statics/Imgs/timeModal";
import Link from '../Components/Common/Link/Link';
import "../Statics/Scss/Modules/ModalWizard.scss";

const ModalWizard = ({close}) => {
    const [active, setActive] = useState("step1");
    const [animate, setAnimate] = useState (false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500)
    })

 return (
     <>
            <div className="row">
                <div className="modalwizard__main">
                    <div className={`row --main ${animate ? "animate" : "" }`}>
                         {active  === 'step1' && 
                            <>
                                <PersonModal />
                                <h3 className="com-title --l --sueca --font-bold">¿Sabés qué podés hacer en Mi Cuenta?</h3>
                                <p  className="com-paragraph --twoxs --arial">Podés actualizar tus datos personales, ver el estado de tu suscripción, vincular tu credencial de Club LA NACION y otorgar credenciales adicionales. También podes ver los beneficios que utilizaste, chequear tus facturas y suscribirte a newsletters.</p>
                                <div className="contentButtons">
                                <button className="radioButton active" aria-label="Paso uno. Actual"></button>
                                <button className="radioButton" aria-label="Ir al paso 2" onClick={() => setActive('step2') & setAnimate(!animate)}></button>
                                <button className="radioButton" aria-label="Ir al paso 3" onClick={() => setActive('step3') & setAnimate(!animate)}></button>
                                </div>
                            </>
                        }
                        {active === 'step2' &&
                            <>
                                <PhoneModal />
                                <h3 className="com-title --l --sueca --font-bold">Administrá tus suscripciones</h3>
                                <p  className="com-paragraph --twoxs --arial">Podés cambiar el domicilio de entrega de tus diarios o revistas, suspender por vacaciones y cambiar la forma de pago de tu suscripción.</p>
                                <div className="contentButtons">
                                <button className="radioButton" aria-label="Ir al paso uno." onClick={() => setActive('step1') & setAnimate(!animate)}></button>
                                <button className="radioButton active" aria-label="Paso dos. Actual"></button>
                                <button className="radioButton" aria-label="Ir al paso tres." onClick={() => setActive('step3') & setAnimate(!animate)}></button>  
                                </div>
                            </>
                        }
                        {active === 'step3' &&
                            <>
                                <TimeModal />
                                <h3 className="com-title --l --sueca --font-bold">Gestioná tus reclamos</h3>
                                <p  className="com-paragraph --twoxs --arial">Podés gestionar reclamos de entrega de diarios o revistas, solicitar la reposición de tu credencial y realizar pagos pendientes.</p>
                                <div className="contentButtons">
                                <button className="radioButton" aria-label="Ir al paso uno." onClick={() => setActive('step1') & setAnimate(!animate)}></button>
                                <button className="radioButton"  aria-label="Ir al paso dos."onClick={() => setActive('step2') & setAnimate(!animate)}></button>
                                <button className="radioButton active" aria-label="Paso tres. Actual"></button>  
                                </div>
                            </>
                        }
                        {active === 'step4' &&
                            <>
                                <IconCheck />
                                <h3 className="com-title --l --sueca --font-bold">¡Administrá tu cuenta ahora!</h3>
                                <p  className="com-paragraph --twoxs --arial">Si te surge alguna duda podés consultar las Preguntas Frecuentes.</p>
                                <div className="contentButtons">
                                </div>
                            </>
                        }
                    </div>
                    <div className="row --footer">
                        {active === 'step1' && <button  aria-label="Ir al paso siguiente." className="mod-button --5xs --font-bold" onClick={() => setActive('step2') & setAnimate(!animate)}>Siguiente</button>}
                        {active === 'step2' && <><button aria-label="Ir al paso anterior." className="mod-button --5xs --font-bold --secondary" onClick={() => setActive('step1') & setAnimate(!animate)}>Anterior</button><button  aria-label="Ir al paso siguiente." className="mod-button --5xs --font-bold" onClick={() => setActive('step3') & setAnimate(!animate)}>Siguiente</button></>}
                        {active === 'step3' && <><button aria-label="Ir al paso anterior." className="mod-button --5xs --font-bold --secondary" onClick={() => setActive('step2') & setAnimate(!animate)}>Anterior</button><button  aria-label="Ir al paso siguiente." className="mod-button --5xs --font-bold" onClick={() => setActive('step4') & setAnimate(!animate)}>Siguiente</button></>}
                        {active === 'step4' && <><Link href="https://www.contacto.lanacion.com.ar/ayuda" target="_blank" className="mod-button --5xs --font-bold --secondary" >ir a preguntas frecuentes</Link><button  className="mod-button --5xs --font-bold" onClick={ close } >ir a mi cuenta</button></>}
                    </div>
                </div>
            </div>
     </>
 )
}

export default ModalWizard;