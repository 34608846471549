import React from 'react';
import useCustomHistory from '../../../Hooks/UseCustomHistory';
import CallbackArticle from "../Modules/CallbackArticle";
import Paragraph from '../Atoms/Paragraph';
import Button from '../Modules/Button';
import PathsConfig from '../../../Config/RoutePaths';
import Divider from '../Atoms/Divider';
import { useHistory } from 'react-router-dom';
import FormRow from '../Atoms/Form/FormRow';

const IncentivarVincularClubBanner = ({ showBottomDivider }) => {
    const history = useHistory();
    const vincular = () => {
        history.replace({
            pathname: PathsConfig.VincularCredencialVincular,
        });
    };
    
    return (
        <>
            <CallbackArticle
                title="¿Tenés Club LA NACION?"
                description={
                    <>
                        <Paragraph size="--twoxs" mod="--arial --font-regular">
                            Vinculá tu credencial Club LA NACION con tu cuenta para gestionar desde acá tus suscripciones.
                        </Paragraph>
                        <br />
                        <FormRow mod="--btn-groups">
                            <Button
                                href={'#'}
                                label="VINCULAR CREDENCIAL"
                                onClick={vincular}
                            />
                        </FormRow>
                    </>
                }
            />
            {showBottomDivider && <Divider />}
        </>
    );
};

export default IncentivarVincularClubBanner;
