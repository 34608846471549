import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import FormRow from '../Common/Atoms/Form/FormRow';
import FormCol from '../Common/Atoms/Form/FormCol';
import InputGroup from '../Common/Atoms/Form/InputGroup';
import Label from '../Common/Atoms/Form/Label';
import Button from '../Common/Modules/Button';
import Divider from '../Common/Atoms/Divider';
import Link from '../Common/Atoms/Link';
import SelectFormik from '../Common/Formik/Select';
import InputFormik from '../Common/Formik/Input';
import InputAutocomplete from '../Common/Atoms/Form/InputAutocomplete';
import DomicilioSchema from '../../Validations/DomicilioSchema';
import useNormalizarDomicilio from '../../Hooks/useNormalizarDomicilio';
import useCrearDomicilio from '../../Hooks/useCrearDomicilio';
import provinciasMap from '../../Models/Provincias';
import domicilioToObjectMap from '../../Map/DomicilioToObjectMap';
import tipoPropiedadMap from '../../Models/TipoPropiedad';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import ErrorBoundary from '../Common/Organism/ErrorBoundary';
import { validaDomicilioExistente } from '../../Validations/ValidaDomicilioExistente';
import CallbackArticle from '../Common/Modules/CallbackArticle';
import Paragraph from '../Common/Atoms/Paragraph';
import ToastDomicilioExistente from '../Features/ReclamoEntrega/ReclamoNoRecibi/ToastDomicilioExistente';
import useObtenerCountries from '../../Hooks/useObtenerCountries';
import TextAreaFormik from '../Common/Formik/TextArea';
import Toast from '../Common/Modules/Toast';
import { horario, mailAtencionSocios, telefono } from '../../Config/DatosDeContacto';

const propTypes = {
    domicilios: PropTypes.object.array,
    setDomicilioCreado: PropTypes.func.isRequired,
    setErrorCreandoDomicilio: PropTypes.func.isRequired,
};

const NuevoDomicilio = (props) => {
    const { domicilios, setDomicilioCreado, setErrorCreandoDomicilio } = props;
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [domicilioDuplicado, setDomicilioDuplicado] = useState(null);
    const [messageNormalizarError, setMessageNormalizarError] = useState(null);
    const [domicilioElegido, setDomicilioElegido] = useState();
    const [domicilio, errorNormalizar, isLoading, sendNormalizar] = useNormalizarDomicilio();
    const [isDomicilioSelected, setIsDomicilioSelected] = useState(false);
    const [domicilioCreado, isErrorDomicilio, isLoadingDomicilio, sendData] = useCrearDomicilio();
    const [observaciones, setObservaciones] = useState(null);
    const [countries, errorCountries, isLoadingCountries, fetchCountries] = useObtenerCountries();
    const [countriesConId, setCountriesConId] = useState();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState(domicilioElegido?.provincia ? domicilioElegido.provincia : 'BUENOS AIRES');
    const [suggestionsCountries, setSuggestionsCountries] = useState([]);
    const [userInputLocal, setUserInputLocal] = useState('');
    const [tipoPropiedad, setTipoPropiedad] = useState();
    const [inputDomicilio, setInputDomicilio] = useState({
        calle: '',
        codigoPostal: '',
        altura: '',
        entreCalle1: '',
        entreCalle2: '',
        localidad: ''
    });

    const handleNormalizaDomicilio = (calle, numero, cp) => {
        const soloNumeros = /^[0-9\b]+$/;

        const domicilioSend = {
            calle: calle,
            codigoPostal: cp,
            altura: numero,
        };

        if (!selectedCountry) {
            if (calle == '' || numero == '' || cp == '')
                return;

            if (!(soloNumeros.test(numero) && soloNumeros.test(cp)))
                return;

            sendNormalizar({ calle: calle, altura: numero, cp: cp }).then((resp) => {
                if (resp.code !== '0000') {
                    setInputDomicilio(domicilioSend);
                }
                setDomicilioElegido(resp.response);
                setIsFirstStep(false);
            }).catch((error) => {
                setInputDomicilio(domicilioSend);
            })
            setIsDomicilioSelected(true);
        } else {
            setIsFirstStep(false);
            setIsDomicilioSelected(false);
        }

        setDomicilioDuplicado(null);
    };

    useEffect(() => {
        if (domicilioElegido) {
            setIsFirstStep(false);
        }
    }, [domicilioElegido]);

    useEffect(() => {
        const countriesId = setSuggestionsId(countries);
        setSuggestionsCountries(countriesId);
        setCountriesConId(countriesId);
    }, [countries]);

    const handleSubmit = (values) => {
        if (!selectedCountry) {
            values.codigoPostal = domicilioElegido ? domicilioElegido.codigoPostal : values.codigoPostal; //obtengo el normalizado
        } else {
            values.codigoPostal = selectedCountry.CodigoPostal; //obtengo el normalizado
        }
        values.barrio = values.barrio.split('(')[0].trim();
        const aux = domicilioToObjectMap(values);
        const existeDomicilio = validaDomicilioExistente(aux, domicilios);
        if (!existeDomicilio) {
            let payload = domicilioToObjectMap(values);
            payload['cp'] = domicilioElegido ? domicilioElegido.codigoPostal : selectedCountry ? selectedCountry.CodigoPostal : values.codigoPostal;
            payload['observaciones'] = observaciones;
            payload.provincia = provinciaSeleccionada;
            payload.EstaHabilitada = false;
            payload.EstaNormalizada = true;
            if (selectedCountry) {
                payload['country'] = true;
                payload.dpto = selectedCountry.Dpto ? selectedCountry.Dpto : 'S/N';
            }
            payload.piso = values?.piso ? values.piso : 'S/N';
            payload.dpto = values?.dto ? values.dto : 'S/N';
            
            sendData(payload)
                .then((response) => {
                    setIsFirstStep(true);
                    setDomicilioCreado(response.response);
                })
                .catch((error) => {
                    setErrorCreandoDomicilio(error);
                });
        } else {
            setIsFirstStep(true);
            setDomicilioDuplicado(ToastDomicilioExistente);
        }
    };

    const handleProvincia = (e) => {
        setProvinciaSeleccionada(e.target.value);
    };

    const handleSelectedItem = (value) => {
        setSelectedCountry(countriesConId.find((c) => c.Id == value));
        setUserInputLocal('');
        setSuggestionsCountries([]);
        setDomicilioDuplicado(null);
    };

    const setSuggestionsId = (suggestions) => {
        return suggestions.map((item, Id) => ({ ...item, Id, Barrio: item.name + ' (' + item.CodigoPostal + ')' }));
    };

    const fetchCountriesMemory = (userInput) => {
        setSuggestionsCountries([]);
        if (userInput?.length > 3) {
            const selectedCountries = countriesConId.filter((c) => c.name?.toLowerCase().includes(userInput));
            setSuggestionsCountries(selectedCountries);
        } else {
            if (selectedCountry) {
                setSelectedCountry(null);
                if (!isFirstStep) {
                    setIsFirstStep(true);
                }
            }
        }
    };

    const handleTyping = (userInput) => {
        setUserInputLocal(userInput);
        if (userInput?.length == 3) {
            fetchCountries(userInput);
        } else {
            fetchCountriesMemory(userInput);
        }
    };

    const handleChange = (event, props) => {
        setDomicilioDuplicado(null);
        setSuggestionsCountries([]);
        const inputDom = {
            calle: props.values.calle,
            codigoPostal: props.values.codigoPostal,
            altura: props.values.altura,
            entreCalle1: props.values.entreCalle1,
            entreCalle2: props.values.entreCalle2,
            localidad: props.values.localidad
        };
        setTipoPropiedad(event.target.value);
        if (event.target.value !== 'COUNTRY-BARRIO-CLUB DE CAMPO') {
            if (!selectedCountry) {
                setInputDomicilio(inputDom);
            }
            setSelectedCountry(null);
            if (domicilioElegido) {
                setIsDomicilioSelected(true);
            }
        }
        if (domicilioElegido && event.target.value == 'COUNTRY-BARRIO-CLUB DE CAMPO') {
            setIsDomicilioSelected(false);
        }
        if (event.target.value == 'COUNTRY-BARRIO-CLUB DE CAMPO') {
            setInputDomicilio({
                calle: '',
                codigoPostal: '',
                altura: '',
            });
            setDomicilioElegido(null);
            setMessageNormalizarError(null);
            setIsFirstStep(true);
        }
        return event.target.value;
    };

    const handleBotonVolver = () => {
        setProvinciaSeleccionada(domicilioElegido?.provincia ? domicilioElegido.provincia : 'BUENOS AIRES');
        setIsFirstStep(true);
    };


    return (
        <div className="nuevo-domicilio">
            <LoadingSpinner isLoading={isLoading || isLoadingDomicilio} withBody={true}>
                <ErrorBoundary>
                    {errorNormalizar && errorNormalizar}
                    <CallbackArticle
                        description={
                            <Paragraph>Ingresá la información del nuevo domicilio. Sólo pueden ingresarse domicilios en Argentina.</Paragraph>
                        }
                    />
                    <Formik
                        validationSchema={DomicilioSchema}
                        enableReinitialize
                        initialValues={{
                            calle:
                                domicilioElegido && isDomicilioSelected
                                    ? domicilioElegido.calle
                                    : selectedCountry
                                        ? selectedCountry.Calle
                                        : inputDomicilio.calle,
                            altura:
                                domicilioElegido && isDomicilioSelected
                                    ? domicilioElegido.altura
                                    : selectedCountry
                                        ? selectedCountry.Altura
                                        : inputDomicilio.altura,
                            codigoPostal:
                                domicilioElegido && isDomicilioSelected
                                    ? domicilioElegido.codigoPostal.replace(/\D/g, '')
                                    : selectedCountry
                                        ? selectedCountry.CodigoPostal.replace(/\D/g, '')
                                        : inputDomicilio.codigoPostal,
                            entreCalle1: domicilioElegido && isDomicilioSelected ? domicilioElegido.entreCalle1 ? domicilioElegido.entreCalle1 : inputDomicilio.entreCalle1 : selectedCountry ? ' - ' : '',
                            entreCalle2: domicilioElegido && isDomicilioSelected ? domicilioElegido.entreCalle1 ? domicilioElegido.entreCalle2 : inputDomicilio.entreCalle2 : selectedCountry ? ' - ' : '',
                            localidad:
                                domicilioElegido && isDomicilioSelected
                                    ? domicilioElegido.localidad ? domicilioElegido.localidad : inputDomicilio.localidad
                                    : selectedCountry
                                        ? selectedCountry.Localidad
                                        : '',
                            barrio: selectedCountry ? selectedCountry.Barrio : '',
                            torre: '',
                            escalera: '',
                            cuerpo: '',
                            piso: '',
                            dto: '',
                            nombreCountry: selectedCountry ? selectedCountry.Barrio : '',
                            nombreComplejo: domicilioElegido ? domicilioElegido.nombreComplejo : '',
                            tipoDePropiedad: tipoPropiedad ? tipoPropiedad : 'CASA',
                        }}
                        onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <Form noValidate>
                                {domicilioDuplicado}
                                <fieldset>
                                    <FormRow>
                                        <Label text={'Tipo de propiedad'} />
                                        <SelectFormik
                                            handleChange={(e) => handleChange(e, props)}
                                            disabled={!!selectedCountry && !isFirstStep}
                                            name="tipoDePropiedad"
                                        >
                                            {tipoPropiedadMap.map((tipoDePropiedad) => {
                                                return (
                                                    <option value={tipoDePropiedad.id} key={tipoDePropiedad.id}>
                                                        {tipoDePropiedad.descripcion}
                                                    </option>
                                                );
                                            })}
                                        </SelectFormik>
                                    </FormRow>

                                    {props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO' && (
                                        <FormRow>
                                            <FormRow>
                                                <Label text={'Nombre Country'} />
                                                <InputAutocomplete
                                                    name="nombreCountry"
                                                    data={suggestionsCountries}
                                                    placeholder=""
                                                    value=""
                                                    startsAt={3}
                                                    isLoading={isLoadingCountries}
                                                    handleOnSelection={handleSelectedItem}
                                                    handleChange={handleTyping}
                                                />
                                            </FormRow>

                                            <Paragraph size="--fourxs" mod="--arial --light-neutral-800 --textBox">
                                                Si no encontrás el country donde vivis por favor comunicate con nosotros al email{' '}
                                                <strong>{mailAtencionSocios}</strong> o llamanos al <strong>{telefono}</strong> de {' '}{horario}{' '}
                                            </Paragraph>
                                        </FormRow>
                                    )}

                                    <FormRow>
                                        <InputGroup>
                                            <FormCol>
                                                <Label text={'Domicilio'} />
                                                <InputFormik
                                                    disabled={
                                                        (!isFirstStep && !messageNormalizarError) ||
                                                        props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'
                                                    }
                                                    name="calle"
                                                    type="text"
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <InputGroup className="state--no-wrap">
                                                    <FormCol>
                                                        <Label text={'Número'} />
                                                        <InputFormik
                                                            disabled={
                                                                (!isFirstStep && !messageNormalizarError) ||
                                                                props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'
                                                            }
                                                            name="altura"
                                                            type="text"
                                                        />
                                                    </FormCol>
                                                    <FormCol>
                                                        <Label text={'Código Postal'} />
                                                        <InputFormik
                                                            disabled={
                                                                (!isFirstStep && !messageNormalizarError) ||
                                                                props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'
                                                            }
                                                            name="codigoPostal"
                                                            type="text"
                                                            description={
                                                                <Link href="https://www.correoargentino.com.ar/formularios/cpa" target="_blank">
                                                                    No sé mi código postal.{' '}
                                                                </Link>
                                                            }
                                                        />
                                                    </FormCol>
                                                </InputGroup>
                                            </FormCol>
                                        </InputGroup>
                                    </FormRow>
                                    {isFirstStep && (
                                        <FormRow mod="--btn-groups">
                                            <Button
                                                label="VALIDAR DOMICILIO"
                                                type="submit"
                                                onClick={() =>
                                                    handleNormalizaDomicilio(props.values.calle, props.values.altura, props.values.codigoPostal)
                                                }
                                                mod="state--mobile-w100"
                                            />
                                        </FormRow>
                                    )}

                                    {!isFirstStep && (
                                        <>
                                            <Divider />

                                            <FormRow>
                                                <InputGroup className="grid-50-50">
                                                    <FormCol>
                                                        <Label text={'Entre calle'} />
                                                        <InputFormik
                                                            disabled={props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'}
                                                            name="entreCalle1"
                                                            type="text"
                                                        />
                                                    </FormCol>
                                                    <FormCol>
                                                        <Label text={'Y calle'} />
                                                        <InputFormik
                                                            disabled={props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'}
                                                            type="text"
                                                            name="entreCalle2"
                                                        />
                                                    </FormCol>
                                                </InputGroup>
                                            </FormRow>

                                            <FormRow>
                                                <InputGroup className="grid-50-50">
                                                    <FormCol>
                                                        <Label text={'Provincia'} />
                                                        <SelectFormik
                                                            handleChange={handleProvincia}
                                                            disabled={props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'}
                                                            name="provincia"
                                                        >
                                                            {provinciasMap &&
                                                                provinciasMap.map((provincia) => {
                                                                    return (
                                                                        <option value={provincia.id} key={provincia.id}>
                                                                            {provincia.descripcion}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </SelectFormik>
                                                    </FormCol>
                                                    <FormCol>
                                                        <Label text={'Localidad'} />
                                                        <InputFormik
                                                            disabled={props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'}
                                                            type="text"
                                                            name="localidad"
                                                        />
                                                    </FormCol>
                                                </InputGroup>
                                            </FormRow>

                                            <FormRow>
                                                <FormCol>
                                                    <Label text={'Barrio'} />
                                                    <InputFormik
                                                        disabled={props.values.tipoDePropiedad === 'COUNTRY-BARRIO-CLUB DE CAMPO'}
                                                        name="barrio"
                                                        type="text"
                                                    />
                                                </FormCol>
                                            </FormRow>

                                            <Divider />

                                            {(props.values.tipoDePropiedad === 'COMERCIO' ||
                                                props.values.tipoDePropiedad === 'COMPLEJO-MONOBLOCK') && (
                                                    <FormRow>
                                                        <Label text={'Nombre del complejo'} />
                                                        <InputFormik name="nombreComplejo" type="text" />
                                                    </FormRow>
                                                )}

                                            {props.values.tipoDePropiedad !== 'COUNTRY-BARRIO-CLUB DE CAMPO' &&
                                                props.values.tipoDePropiedad !== 'CASA' && (
                                                    <>
                                                        <FormRow>
                                                            <InputGroup className="state--no-wrap">
                                                                <FormCol>
                                                                    <Label text={'Piso'} />
                                                                    <InputFormik name="piso" />
                                                                </FormCol>
                                                                <FormCol>
                                                                    <Label text={'Dto'} />
                                                                    <InputFormik name="dto" />
                                                                </FormCol>
                                                                <FormCol>
                                                                    <Label text={'Torre'} />
                                                                    <InputFormik
                                                                        name="torre"
                                                                        type="text"
                                                                        description={
                                                                            props.values.tipoDePropiedad !== 'COMPLEJO-MONOBLOCK' && 'Opcional'
                                                                        }
                                                                    />
                                                                </FormCol>
                                                            </InputGroup>
                                                        </FormRow>

                                                        <FormRow>
                                                            <InputGroup>
                                                                <FormCol>
                                                                    <Label text={'Cuerpo'} />
                                                                    <InputFormik
                                                                        name="cuerpo"
                                                                        type="text"
                                                                        description={
                                                                            props.values.tipoDePropiedad !== 'COMPLEJO-MONOBLOCK' && 'Opcional'
                                                                        }
                                                                    />
                                                                </FormCol>
                                                                <FormCol>
                                                                    <Label text={'Escalera'} />
                                                                    <InputFormik
                                                                        name="escalera"
                                                                        type="text"
                                                                        description={
                                                                            props.values.tipoDePropiedad !== 'COMPLEJO-MONOBLOCK' && 'Opcional'
                                                                        }
                                                                    />
                                                                </FormCol>
                                                            </InputGroup>
                                                        </FormRow>
                                                    </>
                                                )}

                                            {props.values.tipoDePropiedad && (
                                                <FormRow>
                                                    <Label text={'Observaciones'} />
                                                    <TextAreaFormik
                                                        maxlength="100"
                                                        onBlur={(e) => setObservaciones(e.target.value)}
                                                        name="observaciones"
                                                        description="La observación no puede tener más de 100 caracteres"
                                                    />
                                                </FormRow>
                                            )}

                                            <FormRow mod="--btn-groups">
                                                <Button
                                                    mobileW100={true}
                                                    type="submit"
                                                    label="CREAR DOMICILIO"
                                                    className="state--mobile-w100"
                                                    iconSize="s"
                                                    mod="state--mobile-w100"
                                                />
                                                <Button
                                                    mod="secondary"
                                                    tag="input"
                                                    label="VOLVER A VALIDAR DOMICILIO"
                                                    mobileW100={true}
                                                    onClick={handleBotonVolver}
                                                />
                                            </FormRow>
                                        </>
                                    )}
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                </ErrorBoundary>
            </LoadingSpinner>
        </div>
    );
};

NuevoDomicilio.propTypes = propTypes;

export default NuevoDomicilio;
