import React from 'react';

import '../../../../Statics/Scss/Components/Input.scss';

const Input = props => {
	const {
		meta,
		leftIcon,
		rightIcon,
		type,
		name,
		id,
		placeholder,
		value,
		disabled,
		description,
		funcIconDer,
		state,
		hideStatusIcons,
	} = props;
	const {status, msg} = state || {};

	// Solo muestra icono y color verde si el campo tiene algún valor, no está disabled y el status es success
	const isSuccess = Boolean(value) && !disabled && status === 'success'
	
	const className = `com-input${(status == 'error' &&
		' state--error rightIcon') ||
		''}${(isSuccess && ' state--success rightIcon') ||
		''}${(leftIcon && ' leftIcon') || ''}${(rightIcon && ' rightIcon') ||
		''}`;

	return (
		<div className={className}>
			<div className="com-input__box">
				{leftIcon && (
					<i className={'com-input__leftIcon ' + leftIcon}></i>
				)}

				<input
					type={type}
					name={name}
					id={id}
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					{...meta}
					{...props}
				/>

				{rightIcon && (
					<i
						className={'com-input__rightIcon ' + rightIcon}
						{...(funcIconDer && {
							onClick: funcIconDer,
							style: {cursor: 'pointer'},
						})}></i>
				)}

				{/*meta != undefined  && meta.error &&  meta.touched*/}
				{status === 'error' && !hideStatusIcons && (
					<i className="com-input__rightIcon icon-error-warning"></i>
				)}

				{/*meta != undefined && !meta.error && meta.touched*/}
				{isSuccess && !hideStatusIcons && (
					<i className="com-input__rightIcon icon-check-mark"></i>
				)}

				{/*meta != undefined  && meta.error &&  meta.touched*/}
				{status === 'error' ? (
					<span className="com-input__box__descrip --fivexs">
						{msg}
					</span>
				) : (
					description && (
						<span className="com-input__box__descrip --fivexs">
							{description}
						</span>
					)
				)}
			</div>
		</div>
	);
};

export default Input;
