import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useObtenerMontoMinimoValidacionTarjeta = () => {
  const { REACT_APP_APIPAYWALL } = process.env;
  const [isLoading, setIsLoading] = useState(true);
  const [monto, setMonto] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      ApiService.post(`${REACT_APP_APIPAYWALL}Suscripcion/ObtenerMontoMinimoValidacionTarjeta`)
        .then((data) => {
          if (data.code == "0000") {
            setMonto(data.response);
            setIsLoading(false);
          } else {
            setError(null);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return [monto, error, isLoading];
};

export default useObtenerMontoMinimoValidacionTarjeta;

