import React, { useState } from 'react';
import classNames from 'classnames';

import Button from '../../Modules/Button';
import LinkNew from '../../../../Components/Common/Link/Link';

import OnClickOutElement from './onClickOutElement';
import './Style.scss';

const HeaderSuperCardBox = ({ children, listDrop, className }) => {
    //hook open desplegable
    const [openDrop, setOpenDrop] = useState(false);

    let classes = classNames('com-SuperCardBox__header', className);

    let handleOutClick = () => {
        setOpenDrop(false);
    };

    return (
        <div className={classes}>
            <div className="com-SuperCardBox__header__title">{children}</div>

            {listDrop && (
                <div className="com-SuperCardBox__header__button" ref={OnClickOutElement(handleOutClick)}>
                    <Button
                        icon="more"
                        className="--mb-0"
                        mod="tertiary"
                        iconSize="s"
                        onClick={() => {
                            setOpenDrop(!openDrop);
                        }}
                    />

                    {openDrop && (
                        <div className="com-SuperCardBox__header__button__drop">
                            <ul>
                                {listDrop.map((list, key) => {
                                    return (
                                        <li>
                                            <LinkNew key={key} className={'--fourxs'} onClick={list.onClick}>
                                                {list.name}
                                            </LinkNew>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export { HeaderSuperCardBox };
