import arrayOfCardTypes from "../Config/CardTypes";

const GetCardType = (cardName) => {
  const lowerCaseCardName = cardName.toLowerCase();

  const foundCardType = arrayOfCardTypes.find(type => lowerCaseCardName.includes(type));

  return foundCardType || '';
}

export default GetCardType;