import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { Header as CommonHeader, MainHeader, SubHeader } from '@ln/common-ui-header';
import { toggleDrawer, useDrawer } from '@ln/common-ui-drawer';
import { Button } from '@ln/common-ui-button';
import { Icon } from '@ln/common-ui-icon';
import { Avatar } from '@ln/experiencias-ui-avatar';
import { Text } from '@ln/common-ui-text';
import { NotificationsCentre } from '@ln/lib-personalizacion';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Hamburger, LeftArrow, User } from '@ln/experiencias-ui-assets';
import classNames from 'classnames';
import DrawerSidebar from './DrawerSidebar/DrawerSidebar';
import HeaderPath from './HeaderPath';
import Campanita from '../../../Helpers/Campanita';
import SubscribeButton from '../../Features/Header/SubscribeButton'
import PlanDetails from '../../Features/Header/PlanDetails';
import { DRAWER } from '../../../Models/Drawers';
import getProductoPremium from '../../../Models/ProductoPremium';
import { readCookie } from '../../../Helpers/Cookies';
import { userConSuscripcion, userSinSuscripcion } from '../../../Helpers/HeaderUserProps';
import { useStore } from '../../../Store/storeProvider';
import { obtenerIniciales } from '../../../Helpers/String';
import { BuildBearerAccessTokenAsync, getIdTokenCookie } from '@ln/user.client.libs';

const Header = ({ setVisibleSubscriberAttentions }) => {
  const headerRightRef = useRef(null);
  const subheaderRef = useRef(null);
  const { REACT_APP_ENV } = process.env;
  const usuarioEmail = Cookies.get('usuarioemail');
  const usuarioNombre = Cookies.get('usuario_detalle_nombre');
  const usuarioApellido = Cookies.get('usuario_detalle_apellido');
  const [userSuscriptionProps, setUserSuscriptionProps] = useState(userSinSuscripcion);
  const [userIniciales, setUserIniciales] = useState('');
  const { actualizarAvatar } = useStore();
  let history = useHistory();
  const location = useLocation();
  const { showDrawer: showDrawerSidebar } = useDrawer({ id: DRAWER.SIDEBAR })
  const { showDrawer: showDrawerAccount } = useDrawer({ id: DRAWER.ACCOUNT })
  const HideHamburgerHome = location.pathname !== "/";
  const [sticky, setSticky] = useState(false);
  const transitionHeader = classNames('transition-regular', sticky ? 'py-8' : 'py-16');
  const logoClassName = classNames('h-16', sticky ? 'h-24_md h-32_xl' : 'h-32_md h-40_xl');
  const [notificationsCentreProps, setNotificationsCentreProps] = useState({});
  
  const setNotificationsProps = async () => {
    const notificationsCentreProps = {
      userAccessToken: await BuildBearerAccessTokenAsync(),
      userIdToken: getIdTokenCookie(),
      isTestEnvironment: REACT_APP_ENV !== "PROD" && REACT_APP_ENV !== "PRE",
      zone: 'myaccount'
    };
    setNotificationsCentreProps(notificationsCentreProps);
  };

  useEffect(() => {
    setNotificationsProps();
    const callback = (entries) => {
      entries.forEach((entry) => {
        setSticky(!entry.isIntersecting);
      });
    };
    
    const sentinel = document.querySelector('.header-sentinel'); // Reemplaza esto con el selector correcto
    const intersectionObserver = new IntersectionObserver(callback, {
      rootMargin: '0px',
    });

    sentinel && intersectionObserver.observe(sentinel);

    return () => {
      sentinel && intersectionObserver.unobserve(sentinel);
    };
  }, []);

  useEffect(() => {
    if (getProductoPremium(readCookie('ProductoPremiumId'))) {
        setUserSuscriptionProps(userConSuscripcion);
    }
    setUserIniciales(obtenerIniciales(usuarioNombre, usuarioApellido, usuarioEmail))
}, [actualizarAvatar])

  return (
    <>
      <CommonHeader classNameContainer='checkout-header z-10 w-100 sticky top-0 arial'>
        <MainHeader className='bg-neutral-light-1 border border-bottom border-thin border-light-100'>
          <MainHeader.Content className={transitionHeader}>
            <MainHeader.Content.Left className="ai-center">
              <Button
                title="Volver"
                className="bg-transparent xl-only flex ai-center gap-8 bg-neutral-light-800_hover p-16 rounded-4"
                onClick={() => history.goBack()}
              >
                <Icon size={24}>
                  <LeftArrow />
                </Icon>
                <Text as="strong" className="--twoxs">VOLVER</Text>
              </Button>
              {HideHamburgerHome && (
                <Button
                  className='bg-transparent'
                  title="Menu"
                  onClick={showDrawerSidebar}
                >
                  <Icon size={24} className="xl-none">
                    <Hamburger />
                  </Icon>
                </Button>
              )}
              <DrawerSidebar />
            </MainHeader.Content.Left>
            <MainHeader.Content.Center className="ai-center jc-center">
              <MainHeader.Brand href="https://www.lanacion.com.ar" title="Ir a la página principal" className="flex">
                <Icon className={logoClassName}>
                  <svg
                    width="152"
                    height="16"
                    viewBox="0 0 152 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <HeaderPath />
                  </svg>
                </Icon>
              </MainHeader.Brand>
            </MainHeader.Content.Center>
            <MainHeader.Content.Right className="flex jc-end ai-center gap-16 gap-24_md">
              {/* <Notificationdrawer.Button drawerId='drawer-notifications' title='Abrir Notificaciones' hasNotifications={true} tooltipText='Aquí encontrarás todas nuestras notificaciones' showTooltip={showTooltip} onClick={()=>setShowTooltip(false)} /> */}
              <div ref={headerRightRef} />
              {notificationsCentreProps?.userAccessToken &&
                <NotificationsCentre
                  onMessageButtonClick={Campanita.handleButtonClick}
                  onNotificationsClick={Campanita.handleNotificationsClick}
                  onBellClick={Campanita.handleBellClick}
                  {...notificationsCentreProps} />
              }
              <Button onClick={() => (showDrawerAccount(), setVisibleSubscriberAttentions(false))} data-test-id className='bg-transparent' title='Abrir Menu Usuario'>
                <Icon size={24} className='sm-none'>
                  <User />
                </Icon>
                <Avatar className='sm-only'>
                  <Avatar.UserImage key={userSuscriptionProps.variant} variant={userSuscriptionProps.variant}>{userIniciales}</Avatar.UserImage>
                </Avatar>
              </Button>
            </MainHeader.Content.Right>
          </MainHeader.Content>
        </MainHeader>
        <SubHeader className='py-12 flex gap-16 gap-24_md ai-center border border-bottom border-thin border-light-100 xl-none bg-neutral-light-1'>
          <PlanDetails key={userSuscriptionProps.variant} {...userSuscriptionProps} />
          <div ref={subheaderRef} />
        </SubHeader>
      </CommonHeader>
      <div className="header-sentinel"></div>
      <SubscribeButton portalDesktopRef={headerRightRef} portalMobileRef={subheaderRef} />
    </>

  );
};

export default Header;
