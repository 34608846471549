import React from "react";
import PropTypes from "prop-types";

import "../../../../Statics/Scss/Modules/Table.scss";

const propTypes = {
  /**
   * Alineación general.
   */
  align: PropTypes.string,
  /**
   * Flag, ¿adaptación responsive?
   */
  responsive: PropTypes.bool,
  /**
   * Flag, ¿es el layout fixed?
   */
  isFixedLayout: PropTypes.bool,
};

const defaultProps = {
  responsive: true,
};

const Table = ({ children, align, responsive, isFixedLayout, ...r }) => {
  const className = `mod-table${align ? ` --a-${align}` : ""}
                       ${responsive ? ` --responsive` : ""}
                       ${isFixedLayout ? ` --layout-fixed` : ""}`;

  return (
    <div className="table-wrapper">
      <table className={className} align={align} {...r}>
        {children}
      </table>
    </div>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
