import Datadog from '../Helpers/Datadog';
import ApiService from './ApiService';

const actualizarProductoPremiumPorParametro = async (productosPremium) => {
    try {
        const { REACT_APP_APIPAYWALL } = process.env;
        const data = await ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripcionesV1/ActualizarProductoPremiumPorParametro`, productosPremium);
        if (data && data.code === '0000') {
            Datadog.trackCustomEvent('actualizacion-productoPremiumPorParametro');
        } else {
            Datadog.trackCustomEventWarning('error-productoPremiumPorParametro', { error: data.message, code: data.code });
        }
        return await Promise.resolve(data);
    } catch (error) {
        Datadog.trackCustomEventWarning('error-productoPremiumPorParametro', { error });
        return Promise.reject(error);
    }
};

export default actualizarProductoPremiumPorParametro;
