import React from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Button from '../../Common/Modules/Button';
import Toast from '../../Common/Modules/Toast';
import FormRow from '../../Common/Atoms/Form/FormRow';

const ErrorBlock = ({ errorMsg = 'Hubo un error al intentar generar el reclamo. Por favor vuelva a intentarlo nuevamente más tarde.' }) => {
    const history = useHistory();

    return (
        <>
            <Toast title={'Error inesperado'} children={errorMsg} status={'warning'} />
            <FormRow mod="--btn-groups --mb-32">
                <Button mod="state--mobile-w100" onClick={() => history.push({ pathname: PathsConfig.ReclamoEntrega, state: null })} href={'#'} label="Realizar otro reclamo" />
            </FormRow>
        </>
    );
};

export default ErrorBlock;
