import React from 'react';
import Toast from '../../Common/Modules/Toast';
import IncentivarVincularClubBanner from '../../Common/Banners/IncentivarVincularClub';
import IncentivarSuscripcionBanner from '../../Common/Banners/IncentivarSuscripcion';

const ReclamoSinCredencial = () => {
    return (
        <div>
            <Toast status="info" title="No tenés credenciales disponibles." />
            <IncentivarVincularClubBanner showBottomDivider />
            <IncentivarSuscripcionBanner />
        </div>
    );
};

export default ReclamoSinCredencial;