import React from 'react'
import sanitize from '../Wrappers/SanitizeHtml'
import { Icon } from '@ln/common-ui-icon'
import { Checked } from '@ln/experiencias-ui-assets'

const Beneficio = ({ iconColor, text }) => {
  if (!text) return <></>
  
  return (
    <li className="flex ai-center gap-8 text-14 py-4">
      <Icon color={iconColor} size={20}>
        <Checked />
      </Icon>
      <span dangerouslySetInnerHTML={sanitize(text)} />
    </li>
  )
}

export default Beneficio