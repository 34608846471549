import React from 'react';
import {Logout} from '@ln/experiencias-ui-assets';
import PathsConfig from '../Config/RoutePaths';

const Menu = [
    {
        SectionName: '',
        SectionClass: '4xs',
        SectionGroup: [
            {
                GroupName: 'Mi cuenta',
                Childrens: [
                    {
                        Display: 'Mi usuario',
                        Path: PathsConfig.MenuMiUsuario,
                    },
                    {
                        Display: 'Datos personales',
                        Path: PathsConfig.MenuDatosPersonales,
                    },
                ],
            },
            {
                GroupName: 'Club LA NACION',
                Childrens: [
                    {
                        Display: 'Vincular credencial',
                        Path: PathsConfig.MenuVincularCredencial,
                    },
                    {
                        Display: 'Mis credenciales',
                        Path: PathsConfig.MenuMisCredenciales,
                    },
                    {
                        Display: 'Mis consumos',
                        Path: PathsConfig.MenuMisConsumos,
                    },
                ],
            },
            {
                GroupName: 'Mis suscripciones',
                Childrens: [
                    {
                        Display: 'Ver suscripciones',
                        Path: PathsConfig.MenuMisSuscripciones,
                    },
                    {
                        Display: 'Suspender por vacaciones',
                        Path: PathsConfig.MenuSuspensionVacaciones,
                    },
                    {
                        Display: 'Cambiar domicilio de entrega',
                        Path: PathsConfig.MenuMisDomicilios,
                    },
                ],
            },
            {
                GroupName: 'Mis reclamos',
                Childrens: [
                    {
                        Display: 'De entrega',
                        Path: PathsConfig.MenuReclamoEntrega,
                    },
                    {
                        Display: 'Credencial Club',
                        Path: PathsConfig.MenuReclamoTarjeta,
                    },
                    {
                        Display: 'Acceso digital',
                        Path: PathsConfig.MenuReclamoAccesoDigital,
                    }
                ],
            },
            {
                GroupName: 'Mis pagos',
                Childrens: [
                    {
                        Display: 'Ver facturas',
                        Path: PathsConfig.MenuMisFacturas,
                    },
                    {
                        Display: 'Pagos pendientes',
                        Path: PathsConfig.MenuPagosPendientes,
                    },
                    {
                        Display: 'Cambiar forma de pago',
                        Path: PathsConfig.MenuCambiarFormaDePago,
                    },
                    {
                        Display: 'Estado de cuenta',
                        Path: PathsConfig.MenuEstadoDeCuenta,
                    },
                ],
            },
        ],
    },
];

export default Menu;
