import MotivoReclamo from '../Models/MotivoReclamo'

const MotivosMap = () => {
    
    const getMotivo = (motivoSelected) => {
        let urlToRedirect;
        MotivoReclamo.map(value => {
            if (value.id == motivoSelected)
                urlToRedirect = value.url;
        })

        return urlToRedirect;
    }

    return [getMotivo]
}

export default MotivosMap;