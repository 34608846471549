import { useState } from 'react';
import { useHistory } from 'react-router';
import ApiService from '../Services/ApiService';
import { reclamoTarjetaParams } from '../Helpers/Reclamo';
import PathsConfig from '../Config/RoutePaths';
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import SessionHandler from '../../src/Handlers/Session';
import { useDispatch } from '../Store/storeProvider';

const useGenerarReclamoCredencial = () => {
    const history = useHistory();
    let { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    const outFunc = async (data, motivoReclamo) => {
        const pathUrl = `${REACT_APP_APIPAYWALL}Reclamos/GestionarReclamoCredencial`;
        setIsLoading(true);

        ApiService.post(pathUrl, data)
            .then(response => {
                if (response.code === '0000') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.reclamarTarjeta, { motivoReclamo, credAReclamar: data.IdCredencialesAReclamar });
                    SessionHandler.Reload(dispatch);
                    history.push({
                        pathname: PathsConfig.ReclamoTarjetaFinalizado,
                        state: {
                            reclamoGenerado: response.response[0]
                        }
                    });
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorReclamoTarjeta, { motivoReclamo, credAReclamar: data.IdCredencialesAReclamar, codigo: response.code, error: response.message });
                    SessionHandler.Reload(dispatch);
                    setIsLoading(false);
                    history.push({
                        pathname: PathsConfig.ReclamoTarjetaContactoCallcenter,
                        state: reclamoTarjetaParams
                    });
                };
            })
            .catch((error) => {
                Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorReclamoTarjeta, { motivoReclamo, credAReclamar: data.IdCredencialesAReclamar, error: error.message });
                setIsLoading(false);
                setError(error.message);
            });
    };

    return [error, isLoading, outFunc];

};

export default useGenerarReclamoCredencial;
