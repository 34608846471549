import React, { useState, useEffect } from 'react';
import ErrorGenerico from '../../Features/ErrorGenerico';

const ErrorBoundary = ({children, error}) => {
    if (error) { //si hay error renderiza el generico
        return (
            <ErrorGenerico />
        );
    }
    return children; //si no hay error renderiza sus hijos
};


export default ErrorBoundary;
