import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_image.scss';
import Link from '../../../Atoms/Link';


const propTypes = {
	src: PropTypes.string.isRequired,
	amp: PropTypes.bool,
	alt: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	withLazy: PropTypes.bool,
	href: PropTypes.string,
	target: PropTypes.string,
};

const Image = ({ amp, src, alt, className, width, height, withLazy, href, target, ...r }) => {
	const Element = amp ? 'amp-img' : 'img';
	const loading = withLazy ? 'lazy' : undefined;
	const classes = classNames('image', className);

	const image = (
		<Element
			src={src}
			width={width}
			height={height}
			loading={loading}
			className={classes}
			alt={alt || ''}
			{...r}
		/>
	);

	return (
		<>
			{href ? (
				<Link href={href} title={alt} target={target}>
					{image}
				</Link>
			) : (
				image
			)}
		</>
	);
};

Image.propTypes = propTypes;

export default Image;
