export const daysMap = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export const days = {
    LU: 'Lunes',
    MA: 'Martes',
    MI: 'Miércoles',
    JU: 'Jueves',
    VI: 'Viernes',
    SA: 'Sábado',
    DO: 'Domingo'
};

export const monthMap = {
    'Enero':      'Ene',
    'Febrero':    'Feb',
    'Marzo':      'Mar',
    'Abril':      'Apr',
    'Mayo':       'May',
    'Junio':      'Jun',
    'Julio':      'Jul',
    'Agosto':     'Ago',
    'Septiembre': 'Sep',
    'Octubre':    'Oct',
    'Noviembre':  'Nov',
    'Diciembre':  'Dec'
};

export const meses = {
    0: 'enero',
    1: 'febrero',
    2: 'marzo',
    3: 'abril',
    4: 'mayo',
    5: 'junio',
    6: 'julio',
    7: 'agosto',
    8: 'septiembre',
    9: 'octubre',
    10: 'noviembre',
    11: 'diciembre',
};
