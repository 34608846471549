import estadoSuscripciones from "../Models/EstadoSuscripciones";

const hasAvailableGoogleSuscription = (suscripciones) => {
  var sus = suscripciones.filter((suscripcion) => {
    return canPagoGoogle(suscripcion);
  });

  return sus.length > 0;
};

const canPagoGoogle = (suscripcion) => {
  if (!suscripcion || !suscripcion.EsGoogle) {
    return false;
  }

  return (
    suscripcion.Estado == estadoSuscripciones.ACTIVA ||
    suscripcion.Estado == estadoSuscripciones.PENDIENTE ||
    suscripcion.Estado == estadoSuscripciones.SUSP_VACACIONES ||
    suscripcion.Estado == estadoSuscripciones.FALTA_PAGO
  );
};

export default { hasAvailableGoogleSuscription };
