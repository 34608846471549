import { useEffect, useState } from "react";
import useConsultarProductosPremiumPorCrmId from "./useConsultarProductosPremiumPorCrmId";
import ApiService from "../Services/ApiService";
import UpdateCookiesAsync from "../Helpers/UpdateCookiesAsync";
import actualizarProductoPremiumPorParametro from "../Services/actualizarProductoPremiumPorParametro";
import Datadog from "../Helpers/Datadog";
import { useDispatch } from "../Store/storeProvider";

const useObtenerEventosSuscripciones = (crmId) => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);
    const [, , , getProductosPremium] = useConsultarProductosPremiumPorCrmId();
    const dispatch = useDispatch();

    const buscarEvento = (contador, delay) => {
        setIsLoading(true);
        let intentos = contador;
        const timeout = delay;
        setTimeout(() => {
            const fetchData = async () => {
                ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripcionesV1/ObtenerEventosAccesosDigitales`)
                    .then(async (data) => {
                        if (data && data.code === '0000') {
                            setEventos(data.response.evento);
                            setIsLoading(false);
                            await UpdateCookiesAsync();
                            dispatch({
                                type: 'actualizarAvatar',
                                value: true
                            })
                        } else if (intentos > 2) {
                            getProductosPremium(crmId).then(async(resp) => {
                                if (resp?.code === '0000') {
                                    const pps = resp?.response?.ProductosPremiumId;
                                    if (pps) {
                                       await actualizarProductoPremiumPorParametro(pps);
                                       await UpdateCookiesAsync();
                                       dispatch({
                                            type: 'actualizarAvatar',
                                            value: true
                                        })
                                    } else {
                                        Datadog.trackCustomEventError('error-actualizarProductoPremiumPorParametro', { error: resp.message });
                                        setError(true);
                                    }
                                }
                                setIsLoading(false);
                            });
                        } else {
                            intentos += 1;
                            buscarEvento(intentos, delay + 500);
                        }
                    })
                    .catch((err) => {
                        setError(err);
                        Datadog.trackCustomEventWarning('error-obtenerEvento-bonificado', { error });
                        setIsLoading(false);
                    })
                    .finally(()=>{
                        dispatch({
                            type: 'actualizarAvatar',
                            value: false
                        })
                    })
            };
            fetchData();
        }, timeout);
    };

    useEffect(() => {
        buscarEvento(1, 500);
    }, []);

    return [eventos, error, isLoading];
};

export default useObtenerEventosSuscripciones;