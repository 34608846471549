import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BasicTablePagination from '../../Common/Modules/Table/BasicTablePagination';
import BeneficiosTableColumns from '../../../Models/BeneficiosTableColumns';
import Link from '../../Common/Atoms/Link';
import CallbackIconTextButton from '../../Common/Modules/CallbackIconTextButton';
import Paragraph from '../../Common/Atoms/Paragraph';
import MiniCards from '../../Common/Atoms/MiniCards';
import mapBeneficiosToTable from '../../../Map/BeneficiosMap';
import OrderObjectByDate from '../../../Helpers/OrderObjectByDate';
import ErrorBoundary from '../../Common/Organism/ErrorBoundary';

const propTypes = {
    detalleBeneficio: PropTypes.object.isRequired,
    displayTable: PropTypes.bool.isRequired,
    card: PropTypes.object.isRequired,
};

const BeneficioDeCliente = (props) => {
    const { TotalBeneficios, MostrarTotalBeneficios, Beneficios } = props.detalleBeneficio;
    const Nombre = props.detalleBeneficio.Nombre ? props.detalleBeneficio.Nombre.toLowerCase() : '';
    const card = props.card;

    const [displayTable, setDisplayTable] = useState(props.displayTable);

    const handleDisplayTable = () => {
        setDisplayTable(!displayTable);
    };

    return (
        <div className="--mb-32">
            <ErrorBoundary>
                <div className="com-container state--no-gap --boxBorder --mb-16">
                    <CallbackIconTextButton
                        icon={<MiniCards cardType={card.cardType} />}
                        text={
                            <>
                                <Paragraph size="--twoxs --tt-capitalize" mod="--font-bold --arial">
                                    {Nombre}
                                </Paragraph>
                                {/* <Paragraph size="--fivexs" mod="--arial">{card.cardNumber}</Paragraph> */}
                            </>
                        }
                    />
                    {MostrarTotalBeneficios && (
                        <CallbackIconTextButton
                            icon={
                                <Paragraph size="--xl" mod="--font-bold --arial">
                                    {TotalBeneficios}
                                </Paragraph>
                            }
                            text={
                                <>
                                    {' '}
                                    <Paragraph size="--twoxs" mod="--font-bold --arial">
                                        Consumos
                                    </Paragraph>
                                    <Paragraph size="--fivexs" mod="--arial --light-neutral-600">
                                        En los últimos 6 meses.
                                    </Paragraph>
                                    <Link size="--fivexs" onClick={handleDisplayTable}>
                                        {displayTable ? ' Ocultar Detalle.' : ' Ver Detalle.'}
                                    </Link>
                                </>
                            }
                        />
                    )}
                </div>

                {displayTable && (
                    <BasicTablePagination
                        cols={BeneficiosTableColumns}
                        items={mapBeneficiosToTable(OrderObjectByDate(Beneficios, 'Fecha', true))}
                        align="center"
                        itemsPerPage={5}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
};

BeneficioDeCliente.propTypes = propTypes;

export default BeneficioDeCliente;
