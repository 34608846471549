import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../Config/RoutePaths';
import ApiService from '../Services/ApiService';

const useObtenerRechazosCobroVencidoYAdelantado = (EstadoDeCuenta) => {
    const history = useHistory();
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [rechazos, setRechazos] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            ApiService.post(
                `${REACT_APP_APIRECLAMOS}cobranzas/obtenerRechazosDeCobroVencidoYAdelantado`
            )
                .then((data) => {
                    if (data && data.Code === 0) {
                        setRechazos(data.Response);
                        if (data.Response.rechazosCobrosAdelantados?.length < 1 && data.Response.rechazosCobrosVencidos?.length  < 1) {
                            history.push({
                                pathname: EstadoDeCuenta ? PathsConfig.EstadoDeCuenta : PathsConfig.PagosPendientes,
                            });
                        }
                    } else {
                        setError(data.Message);
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [rechazos, error, isLoading];
};

export default useObtenerRechazosCobroVencidoYAdelantado;
