import { useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';

const useWondersoftCobroMinimoValidacionTarjeta = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async (payload) => {
        setIsLoading(true);
        ApiService.post(`${REACT_APP_APIPAYWALL}Suscripcion/WondersoftCobroMinimoValidacionTarjeta`, payload)
            .then((data) => {
                if (data.code == '0000') {
                    setResponse(data.response);
                    setIsLoading(false);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsDatadog.bloqueoCambioInstrumentoDeCobro, { response: data.response })
                    setError(data);
                    setIsLoading(false);
                }
            })
            .catch((error) => {  
                Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorBloqueoCambioInstrumentoDeCobro, { error })
                setError("Ha ocurrido un error. Por favor, intente más tarde");
                setIsLoading(false);
            });
    };

    return [response, error, isLoading, fetchData];
};

export default useWondersoftCobroMinimoValidacionTarjeta;
