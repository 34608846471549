import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Mensaje from '../../Models/Mensaje';
import DowngradeAviso from '../../Components/Features/UpSelling/DowngradeAviso';
import { CambioPlanCodigo } from '../../Models/CambioPlanCodigo';
import Deuda from '../../Components/Common/Banners/Deuda';
import CallCenter from '../../Components/Features/UpSelling/CallCenter';
import SinSuscripcionDigital from '../../Components/Features/UpSelling/SinSuscripcionDigital';
import { trackPage } from '../../Helpers/UpsellingTracker';
import Toast from '../../Components/Common/Modules/Toast';
import '../../Statics/Scss/Pages/UpsellingConfirmar.scss';
import useGetInfoPlanUpselling from '../../Hooks/useGetInfoPlanUpselling';
import Planes from '../../Components/Features/UpSelling/Planes';
import useConfirmarCambioUpselling from '../../Hooks/useConfirmarCambioUpselling';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';
import { getUpsellingNombresAmigables } from '../../Config/UpsellingNombresAmigables';
import UrlProperties from '../../Helpers/UrlProperties';
import PathsConfig from '../../Config/RoutePaths';
import useGetCampaniasUpselling from '../../Hooks/useGetCampaniasUpselling';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import { useStore } from '../../Store/storeProvider';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';
import UpsellingConfirmadoBlack from './UpsellingConfirmadoBlack';
import useObtenerSuscripcionesDigitalesAnalitics from '../../Hooks/useObtenerSuscripcionesDigitalesAnalitics';
import { UpsellingPlanes, UpsellingPlanesIds } from '../../Models/UpsellingPlanes';
import { accesosDigitalesIds } from '../../Config/AccesosDigitalesIds';
import { TipoVentaUpselling } from '../../Models/TipoVenta';

const UpsellingConfirmar = () => {
    const history = useHistory();
    const { HandleSwitchsStates } = useStore();
    const stepProps = ['tieneClub'];
    const { pathname, state } = useLocation();
    const stateParse = state ?? { tieneClub: true };
    const { tieneClub, planActual } = useRedirectWithout(stateParse, stepProps, PathsConfig.MisSuscripciones);
    const [infoUpselling, errorInfoUpselling, loadingInfoUpselling, getInfoUpselling] = useGetInfoPlanUpselling();
    const [errorConfirmarUpselling, loadingConfirmarUpselling, confirmarUpselling] = useConfirmarCambioUpselling();
    const [campaniasUpselling, errorCampaniasUpselling, loadingCampaniasUpselling] = useGetCampaniasUpselling();
    const [suscripcionesDA, errorSuscripcionesDA, isLoadingSuscripcionesDA] = useObtenerSuscripcionesDigitalesAnalitics();
    const { plan } = useParams();
    const pathUpselling = pathname.split('confirmar-upselling')[1];
    const upsellClub = pathUpselling?.includes('club');
    const [planUp, setPlanUp] = useState();
    const [campaniaEncontrada, setCampaniaUp] = useState();
    const gaAD = "ga-combo1";
    const {StateSuscripciones} = useStore();
    
    useEffect(() => {
        if (campaniasUpselling !== null && suscripcionesDA) {
            let planEncontrado = null;
            let campaniaEncontrada = null;

            campaniasUpselling.some((campania) => {
                const foundPlan = campania.planes.find((singlePlan) => singlePlan.key.includes(plan));
                if (foundPlan) {
                    planEncontrado = foundPlan;
                    campaniaEncontrada = campania;
                    return true; // Termina el bucle al encontrar el elemento
                }
                return false; // Continúa buscando en la siguiente iteración
            });
            if (!planEncontrado) {
                history.push({ pathname: PathsConfig.MisSuscripciones });
            } else {
                setPlanUp(planEncontrado);
                setCampaniaUp(campaniaEncontrada);
                getInfoUpselling({ campania: campaniaEncontrada.id, planUpselling: planEncontrado, pathUpselling, planActual });
            }
        }
    }, [campaniasUpselling, suscripcionesDA]);

    const handleRouteToADClub = () => {
        history.push({
            pathname: `${PathsConfig.UpsellingConfirmar}/${plan}/domicilio`,
            state: {
                IdCanalDeVenta: UrlProperties.getParamOrSetIfNotExist('cv', campaniaEncontrada.canalVentaDefault),
                IdCampaniaUpsell: infoUpselling.IdCampaniaUpsell,
                IdFormaDeContacto: UrlProperties.getParamOrSetIfNotExist('fc', campaniaEncontrada.formaContactoDefault),
            },
        });
    };

    const handleClickConfirmacionUpsell = (data) => {
        confirmarUpselling(
            campaniaEncontrada.id,
            planUp.id,
            data.NombreUpsell,
            pathUpselling,
            infoUpselling,
            UrlProperties.getParamOrSetIfNotExist('cv', campaniaEncontrada.canalVentaDefault),
            UrlProperties.getParamOrSetIfNotExist('fc', campaniaEncontrada.formaContactoDefault),
            tieneClub,
            handleRouteToADClub,
            infoUpselling.IdSuscripcionActual,
            planActual
        );
    };

    const redirectMiUsuario = () => {
        history.push({
            pathname: PathsConfig.MiUsuario,
        });
    }

    const PageState = (data) => {
        const dataSuscripcionActual = StateSuscripciones?.suscripciones?.find((suscr) => suscr.Id == data?.IdSuscripcionActual); // cuando tengamos en memoria las suscripciones, validar si es plan anual o corpo para no dejarlo upsellear
        if (data == null || !data || data.Codigo == null) return null;
        let result = null;
        const upsellClub6Meses = upsellClub && data.Codigo == CambioPlanCodigo.OK;
        if(suscripcionesDA[0]?.nombre == gaAD && !upsellClub6Meses){
            return result =  <SinSuscripcionDigital />;
        }
        if(dataSuscripcionActual && dataSuscripcionActual.IdAccesoDigital == accesosDigitalesIds.LN && dataSuscripcionActual.TipoDeVenta != TipoVentaUpselling.Corporativa && dataSuscripcionActual.NombreRegimenDeFacturacion != 'Anual'){
            switch (data.Codigo) {
                case CambioPlanCodigo.ERROR_DATOS:
                result = <Toast status="warning" closeAuto={3000} title={Mensaje.getError('GENERAL')} />;
                break;
                case CambioPlanCodigo.OK:
                    result = (
                        <Planes
                        planes={[ data ]}
                        TieneClub={tieneClub}
                        ConfirmarPlan={() => handleClickConfirmacionUpsell(data)}
                        hasError={errorInfoUpselling || errorConfirmarUpselling || errorCampaniasUpselling}
                        />
                    );
                    break;
                    case CambioPlanCodigo.USUARIO_INVALIDO:
                trackPage('upselling_usuario_no_valido', pathUpselling, data);
                result = <SinSuscripcionDigital />;
                break;
                case CambioPlanCodigo.DEUDA:
                    result = <Deuda />;
                    break;
                    case CambioPlanCodigo.CALL_CENTER:
                        result = <CallCenter />;
                        break;
                        case CambioPlanCodigo.DOWNSALE:
                const planActual = UpsellingPlanes.find(pl => pl.nombre == data.NombreActual);
                const planUpsel = UpsellingPlanes.find(pl => pl.nombre == data.NombreUpsell);
                if((planActual?.id >= planUpsel?.id) && planActual?.id !== UpsellingPlanesIds.BLACK){
                    result = <SinSuscripcionDigital />;
                } else {
                    result = <UpsellingConfirmadoBlack handleClick={redirectMiUsuario}/>;
                }
                break;
            }
        } else {
            result =  <SinSuscripcionDigital />;
        }
        return result;
    };

    return (
        <div className="container-fluid-streched">
            <section>
                <Breadcrumb
                    firstItem="Mis suscripciones"
                    secondItem={`Cambiando a ${getUpsellingNombresAmigables[plan]}`}
                    text="Ver suscripciones"
                    href="#"
                />
                <TitleWeb text={`Cambiando a ${getUpsellingNombresAmigables[plan]}`} />
            </section>
            {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.Upselling) ? (
                <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.Upselling}  />
            ) : (
                <LoadingSpinner isLoading={loadingInfoUpselling || loadingConfirmarUpselling || loadingCampaniasUpselling || isLoadingSuscripcionesDA}>
                    {(errorInfoUpselling || errorConfirmarUpselling || errorCampaniasUpselling || errorSuscripcionesDA) && (
                        <Toast status="warning" title={errorInfoUpselling || errorConfirmarUpselling || errorCampaniasUpselling || errorSuscripcionesDA} />
                    )}
                    {infoUpselling && PageState(infoUpselling)}
                </LoadingSpinner>
            )}
        </div>
    );
};

export default UpsellingConfirmar;
