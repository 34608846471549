import _ from 'lodash';

export function validaDomicilioExistente(domicilioBuscado, domicilios) {
  return (domicilios.some(x =>
  	x.calle === domicilioBuscado.calle &&
    x.altura === domicilioBuscado.altura &&
    x.barrio === domicilioBuscado.barrio &&
    x.localidad === domicilioBuscado.localidad &&
    x.provincia === domicilioBuscado.provincia &&
    x.pais === domicilioBuscado.pais &&
    x.tipoDePropiedad === domicilioBuscado.tipoDePropiedad &&
    x.torre === domicilioBuscado.torre &&
    x.piso === domicilioBuscado.piso &&
    x.dpto === domicilioBuscado.dpto
  ));
}
