import React from 'react';

const UnderMaintenance = () => {
    return (
        <div>
        Éste módulo se encuentra bajo mantenimiento, diculpe las molestias.
      </div>
    );
};

export default UnderMaintenance;