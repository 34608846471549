const EstadosDespacho = {
    PendienteDeAprobacion: {
        Id : 1,
        Leyenda : "Pendiente"
    },
    Aprobado: {
        Id : 2,
        Leyenda : "Aprobado"
    },
    Anulado: {
        Id : 3,
        Leyenda : "Anulado"
    },
    EnGestionVencido: {
        Id : 4,
        Leyenda : "Vencido"
    },
    NoConsumado: {
        Id : 5,
        Leyenda : "No consumado"
    },
    Entregado: {
        Id : 6,
        Leyenda : "Entregado"
    },
    EnDevolucion: {
        Id : 7,
        Leyenda : "En devolución"
    },
    EnGestion: {
        Id : 8,
        Leyenda : "En gestión"
    },
    AprobacionEnProceso: {
        Id : 9,
        Leyenda : "En proceso de aprobación"
    },
    AnulacionEnProceso: {
        Id : 10,
        Leyenda : "En proceso de anulación"
    },
}

const obtenerLeyenda = (id) => {
    return Object.values(EstadosDespacho).find(estado => estado.Id == id).Leyenda;
}

export { EstadosDespacho, obtenerLeyenda };