import { useState } from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../Config/RoutePaths';
import { trackPage, trackTransaction } from '../Helpers/UpsellingTracker';
import SessionHandler from '../../src/Handlers/Session';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';
import ApiService from '../Services/ApiService';
import Mensaje from '../Models/Mensaje';
import { useDispatch } from '../Store/storeProvider';

const useConfirmarCambioUpselling = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const redirectBienvenido = (nuevoPlan) => {
        history.push({
            pathname: PathsConfig.UpsellingGracias,
            state: {
                nombreNuevoPlan: nuevoPlan,
            },
        });
    };

    const outFunc = (Campania, Plan, NombrePlan, pathName, infoConfirmacion, IdCanalDeVenta, IdFormaDeContacto, tieneClub, handleRouteToADClub, IdSuscripcionActual, planActual) => {
        trackPage('upselling_clic_update', pathName, infoConfirmacion);
        setIsLoading(true);
        const data = { Campania, Plan, IdCanalDeVenta, IdFormaDeContacto };

        if (!tieneClub) {
            data.IdSuscripcionActual = IdSuscripcionActual
            data.IdOrigen = 1
        }

        ApiService.post(`${REACT_APP_APIPAYWALL}CambioPlanV1/UpsellingMyAccount`, data)
            .then((res) => {
                setIsLoading(false);

                if (res.code == '0000') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.confirmacionUpselling, { planUpselling: { nombre: NombrePlan, id: Plan }, planActual, origen: pathName });
                    const _infoConfirmacion = {
                        ...infoConfirmacion,
                        IdCanalDeVenta: parseInt(IdCanalDeVenta) || 0,
                        IdFormaDeContacto: parseInt(IdFormaDeContacto) || 0,
                    };
                    trackTransaction(_infoConfirmacion);
                    if (tieneClub) {
                        SessionHandler.Reload(dispatch).then(redirectBienvenido(NombrePlan));
                    } else {
                        SessionHandler.Reload(dispatch).then(handleRouteToADClub())
                    }
                } else {
                    const { Errores, ...rest } = res.response
                    if (Errores?.length > 0) {
                        Errores.forEach((err) => {
                            Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConfirmacionUpselling, {
                                error: { ...res, response: {...rest , ...err } },
                            });
                        });
                    } else {
                        Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConfirmacionUpselling, { error: res });
                    }

                    setError(Mensaje.getError('GENERAL'));
                    SessionHandler.Reload(dispatch)
                }
            })
            .catch((err) => {
                Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConfirmacionUpselling, { error: err });
                setError(Mensaje.getError('GENERAL'));
                setIsLoading(false);
            })
    };

    return [error, loading, outFunc];
};

export default useConfirmarCambioUpselling;
