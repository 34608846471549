import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useListarClientesPorDni = () => {
  const { REACT_APP_APIRECLAMOS } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientes, setClientes] = useState([]);

  const buscarClientes = async (dni) => {
    setIsLoading(true);
    return ApiService.post(
      `${REACT_APP_APIRECLAMOS}credenciales/ListarClientesPorApellidoYDni`,
      { Clave: dni }
    )
      .then((data) => {
        if (data.Code == 0) {
          setIsLoading(false);
          setClientes(data.Response);
        } else {
          setError(null);
          setIsLoading(false);
          setClientes([]);
        }
        return Promise.resolve(data.Response);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        setClientes([]);
        return Promise.reject(error);
      });
  };

  return [buscarClientes, clientes, error, isLoading];
};

export default useListarClientesPorDni;
