import React from 'react';

import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Paragraph from '../../Components/Common/Atoms/Paragraph';
import CallbackIconTextButton from '../../Components/Common/Modules/CallbackIconTextButton';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import ErrorGenerico from '../../Components/Features/ErrorGenerico';
import useObtenerBeneficios from '../../Hooks/useObtenerBeneficios';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import SinBeneficiosParaMostrar from '../../Components/Features/MiClub/SinBeneficiosParaMostrar';
import BeneficioDeCliente from '../../Components/Features/MiClub/BeneficioDeCliente';
import TitleWeb from '../../Components/Common/Web/Title';
import { useStore } from '../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';

const MisConsumos = () => {
    const { HandleSwitchsStates } = useStore();
    const [club, errorRelacionClub, isLoadingRelacion] = useObtenerRelacionClub();
    const [beneficios, errorBeneficios, isLoadingBeneficios] = useObtenerBeneficios();
    return (
        <div>
            <section>
                <Breadcrumb firstItem={'Club LA NACION'} href={'#'} text={'Mis consumos'} />
                <TitleWeb text="Mis consumos" />
            </section>
            <section className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.MisConsumos) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.MisConsumos} />
                ) : (
                    <LoadingSpinner isLoading={isLoadingBeneficios || isLoadingRelacion}>
                        {errorBeneficios || errorRelacionClub ? (
                            <ErrorGenerico />
                        ) : !club || !beneficios || !beneficios.DetallesBeneficios || !beneficios.DetallesBeneficios.length ? (
                            <SinBeneficiosParaMostrar club={club} />
                        ) : (
                            <>
                                <div className="--boxBorder --mb-32">
                                    <CallbackIconTextButton
                                        icon={
                                            <Paragraph size="--xl" mod="--font-bold --arial">
                                                {beneficios.TotalBeneficios}
                                            </Paragraph>
                                        }
                                        text={
                                            <>
                                                <Paragraph size="--twoxs" mod="--font-bold --arial">
                                                    Consumos totales
                                                </Paragraph>
                                                <Paragraph size="--fourxs" mod="--arial --light-neutral-600">
                                                    En los últimos 6 meses. Periodo {beneficios.FechaDesde} - {beneficios.FechaHasta}.
                                                </Paragraph>
                                            </>
                                        }
                                    />
                                </div>
                                {beneficios.DetallesBeneficios.map((item, key) => (
                                    <BeneficioDeCliente
                                        key={`${item.nroCredencial}-${key}`}
                                        card={{ cardNumber: club.nroCredencial, cardType: club.response.categoria }}
                                        detalleBeneficio={item}
                                        displayTable={beneficios.DetallesBeneficios.length == 1}
                                    />
                                ))}
                            </>
                        )}
                    </LoadingSpinner>
                )}
            </section>
        </div>
    );
};

export default MisConsumos;
