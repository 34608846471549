const { REACT_APP_DIAS_INTERVALO_DESPACHOS_DEFAULT } = process.env;

const obtenerDespachoMap = (idSuscripcion, idMotivo, detalleSelected, fechaDesde, fechaHasta) => {
    const limitDate = new Date().setDate(new Date().getDate() - REACT_APP_DIAS_INTERVALO_DESPACHOS_DEFAULT)
    if (!fechaDesde || fechaDesde < limitDate) {
        fechaDesde = new Date();
        fechaDesde = limitDate;
    } 
    if (!fechaHasta)
        fechaHasta = new Date()

    let request = {
        "IdSuscripcion": idSuscripcion,
        "FechaDesde": new Date(fechaDesde).toISOString(),
        "FechaHasta": new Date(fechaHasta).toISOString(),
        "IdMotivo": idMotivo,
        "DetalleDeSuscripcion": detalleSelected
    }

    return request
}


export default { obtenerDespachoMap }