import React, { useState, useEffect } from 'react';
import { Button } from '@ln/experiencias-ui-button';
import { EnlacesDeRedireccionPorID, IdsFamiliaProducto } from '../../../Config/UpsellingConfig';
import { readCookie } from '../../../Helpers/Cookies';
import estadoSuscripciones from '../../../Models/EstadoSuscripciones';
import idProductoSuscripciones from '../../../Models/TipoSuscripciones';
import { ProductoPremiumId } from '../../../Models/ProductoPremium';
import useObtenerSuscripcionesOnDemand from '../../../Hooks/useObtenerSuscripcionesOnDemand';
import Date from '../../../Helpers/Date'
import { useWindowSize } from '@ln/hooks';
import { createPortal } from 'react-dom';
import Analytics from '../../../Helpers/Analytics';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';
import { useStore } from '../../../Store/storeProvider';

const SubscribeButton = ({ portalMobileRef, portalDesktopRef }) => {
    const pp = readCookie('ProductoPremiumId');
    const suscriber = pp?.includes('2');
    const [suscripciones, isLoadingSuscripciones, errorSuscripciones, getSuscripciones] = useObtenerSuscripcionesOnDemand();
    const [url, setUrl] = useState(null);
    const [title, setTitle] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const [showButton, setShowButton] = useState(false)
    const [upsellingButton, setUpsellingButton] = useState(false);
    const { width: viewportWidth } = useWindowSize();
    const { HandleSwitchsStates } = useStore();

    const portalTarget = viewportWidth < 1280 ? portalMobileRef?.current : portalDesktopRef?.current

    const tieneBlack = () => {
      return pp == ProductoPremiumId.Black.toString();
    }

    const showButtonMejoraTuPlan = (suscripcion) => {
        const idFamilia = suscripcion.IdFamiliaDeProducto;
        //si no pasaron 6 meses o la funcionalidad esta deshabilitada, no debo mostrar boton
        if (
            idFamilia === idProductoSuscripciones.SuscriptorDigital &&
            (!Date.validatePeriodByMonth(suscripcion.FechaDesde, Number(process.env.REACT_APP_UPSELL_ADC_MONTHS)) ||
                funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.BotonMejoraTuPlanAD))
        ) {
            setShowButton(false);
            return;
        }

        setUrl(EnlacesDeRedireccionPorID[idFamilia]);
        setTitle('Mejora tu plan');
        setDescripcion('MEJORA TU PLAN');
        setUpsellingButton(true);
        setShowButton(true);
    };
    
    useEffect(() => {
      if (suscripciones?.length > 0) {
        const suscripcionesActivas = suscripciones.filter((sus) => sus.Estado === estadoSuscripciones.ACTIVA);
        const suscripcion = suscripcionesActivas.find((sus) => IdsFamiliaProducto.includes(String(sus.IdFamiliaDeProducto)));
    
        if (suscripcion) {
          showButtonMejoraTuPlan(suscripcion)
        }
      }
    }, [suscripciones]);

    useEffect(()=>{
        if(suscriber){
            getSuscripciones();
        }else{
            setUrl(EnlacesDeRedireccionPorID['wall'])
            setTitle('Suscribite');
            setDescripcion('SUSCRIBITE');
            setShowButton(true)
        }
    },[]);

    const trackAnalytics = () => {
      const action = upsellingButton ? 'click_upselling_head' : 'click_suscribirme_head';
      const label = upsellingButton ? 'upselling_mejora_tu_plan' : 'suscribirme';
      
      Analytics.trackClickBtnSuscribirme('my_account', action, label);
    }

    return (
      showButton && !tieneBlack() && Boolean(portalTarget) &&
        createPortal(
          <Button data-test-id id="btnupselling" onClick={trackAnalytics} title={title} variant="accentSubs" href={url} size={32}>
              {descripcion}
          </Button>,
          portalTarget
        )
    )
};

export default SubscribeButton;
