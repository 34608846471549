import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FormRow from '../Common/Atoms/Form/FormRow';
import Label from '../Common/Atoms/Form/Label';
import InputGroup from '../Common/Atoms/Form/InputGroup';
import InputBox from '../Common/Atoms/Form/InputBox';
import RadioInput from '../Common/Atoms/Form/RadioInput';
import Paragraph from '../Common/Atoms/Paragraph';
import Link from '../Common/Atoms/Link';
import Text from '../Common/Text/Text';
import FormCol from '../Common/Atoms/Form/FormCol';
import parseToBool from '../../Helpers/ParseToBool';
import ConfirmarCambioDomicilio from '../Modals/ConfirmarCambioDomicilio';
import NuevoDomicilio from '../Modals/NuevoDomicilio';
import Modal from '../Common/Organism/Modal';
import useObtenerDomicilios from '../../Hooks/useObtenerDomicilios';
import Toast from '../Common/Modules/Toast';
import domicilioToObjectMap from '../../Map/DomicilioToObjectMap';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useCambioDomicilio from '../../Hooks/useCambioDomicilio';
import DateHelper from '../../Helpers/Date';
import '../../../src/Statics/Scss/Base/Helpers.scss';

/*
const propTypes = {
    nombreSuscripcion: PropTypes.string.isRequired,
    susDomicilioId: PropTypes.string
    idSuscripcion: PropTypes.string,
    domicilioPrincipal: PropTypes.object.isRequired,
    setIdDomicilioSeleccionado: PropTypes.func.isRequired,
    setErrorForm: PropTypes.func.isRequired,
    handleErrorDomiclios: PropTypes.func.isRequired,
};
*/

const Domicilios = (props) => {
    const {
        nombreSuscripcion,
        susDomicilioId,
        idSuscripcion,
        idCredencial,
        setDomicilio,
        setErrorForm,
        errorForm,
        setEsSuDomicilio,
        domicilioPrincipal,
        setAddressData,
        esCambioDomicilioDeEntrega,
        isUpselling
    } = props;
    const [domiciliosDisponibles, isError, isLoading, downloadDomicilios] = useObtenerDomicilios();
    const [modalConfirmacion, setModalConfirmacion] = useState(false);
    const [domicilioSeleccionado, setDomicilioSeleccionado] = useState(null);
    const [errorNuevoDomicilio, setErrorNuevoDomicilio] = useState(null);
    const [domicilioSeleccionadoAnterior, setDomicilioAnterior] = useState(null);
    const existeDomicilioPrincipal = domicilioPrincipal?.Calle !== null;
    const [domicilioCambiado, setDomicilioCambiado] = useState(!existeDomicilioPrincipal);
    const [errorDomicilio, isLoadingDomicilio, outFuncDomicilio] = useCambioDomicilio();
    const [actualizarDomicilioPpalResponse, setActualizarDomicilioPpalResponse] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [idDomicilioSelected, setIdDomicilioSelected] = useState(null);
    const [domicilioUnchecked, setDomicilioUnchecked] = useState(false);

    useEffect(() => {
        downloadDomicilios();
    }, []);

    const handleOpenNuevoDomicilio = () => {
        setDomicilio(null);
        setDomicilioSeleccionado(null);
        setModalConfirmacion(false);
        setOpenModal(true);
    };

    const buildResponse = (type, msg) => {
        return <Toast status={type} title={msg} closeAuto={true} value={true} positionFloat={'relative'} />;
    };

    const handleCambioConfirmado = () => {
        idSuscripcion &&
            outFuncDomicilio({
                idDomicilio: domicilioSeleccionado.Id,
                idSuscripciones: [idSuscripcion],
            })
                .then((response) => {
                    setActualizarDomicilioPpalResponse(
                        buildResponse(
                            'success',
                            'Se actualizó exitosamente el domicilio para la suscripción seleccionada. El mismo será efectivo a partir del ' +
                                DateHelper.convertDate_ddMMyyyy(response.response[0].FechaMinimaImplementacion)
                        )
                    );
                })
                .catch((error) => {
                    setActualizarDomicilioPpalResponse(buildResponse('warning', 'No se pudo actualizar el domicilio'));
                });

        setDomicilio(domicilioSeleccionado.Id);
        const domicilioBuscado = domiciliosDisponibles.find((i) => i.Id == domicilioSeleccionado.Id);
        setDomicilioAnterior(domicilioBuscado);
        setDomicilioSeleccionado(domicilioBuscado);
        esCambioDomicilioDeEntrega && setAddressData(domicilioBuscado, false);
        setOpenModal(false);
        setModalConfirmacion(false);
        window.scrollTo(0, 0);
    };

    const handleChangeOptionOtroDomicilio = (e) => {
        let suDom = parseToBool(e.target.value);
        if (suDom) {
            setDomicilioSeleccionado(domicilioPrincipal);
            setDomicilio(susDomicilioId);
        } else {
            setDomicilioSeleccionado(null);
            setDomicilio(null); //inform parent nothing selected
        }

        setEsSuDomicilio(suDom);
        setDomicilioCambiado(!suDom);
    };

    const handleValue = (e) => {
        let domicilioSeleccionado = domiciliosDisponibles.find((i) => i.Id == e.target.value);
        setDomicilioSeleccionado(domicilioSeleccionado);
        setErrorForm(false);
        if (susDomicilioId) {
            setModalConfirmacion(true);
            setOpenModal(true);
        } else {
            setDomicilio(e.target.value);
            if (idCredencial || esCambioDomicilioDeEntrega) {
                setAddressData(domicilioSeleccionado, false);
            }
        }
    };

    const handleNuevoDomicilio = (dom) => {
        if(dom){
            setDomicilio(dom.id);
            setDomicilioSeleccionado(dom);
            //Verificar flujo
            idSuscripcion &&
                outFuncDomicilio(dom)
                    .then((response) => {
                        setActualizarDomicilioPpalResponse(
                            buildResponse(
                                'success',
                                'Se actualizó exitosamente el domicilio para la suscripción seleccionada. El mismo será efectivo a partir del ' +
                                    DateHelper.convertDate_ddMMyyyy(response.response[0].FechaMinimaImplementacion)
                            )
                        );
                    })
                    .catch((error) => {
                        setActualizarDomicilioPpalResponse(buildResponse('warning', 'No se pudo actualizar el domicilio'));
                    });

            (idCredencial || esCambioDomicilioDeEntrega) && setAddressData(dom, true);
            setModalConfirmacion(false);
            setOpenModal(false);
            setErrorForm(false);
            downloadDomicilios();
        }else{
            handleErrorDomiclios('Hubo un problema con tu solicitud. Por favor, inténtalo nuevamente más tarde.');
        }
    };

    const handleErrorDomiclios = (msg) => {
        setErrorNuevoDomicilio(<Toast status={'alert'} title={'Error'} value={true} children={msg} />);
        setModalConfirmacion(false);
        setOpenModal(false);
    };

    const handleCloseModal = () => {
        modalConfirmacion && setDomicilioSeleccionado(domicilioSeleccionadoAnterior ? domicilioSeleccionadoAnterior : null);
        setOpenModal(false);
        setModalConfirmacion(false);
    };

    return (
        <>
            {isLoading ? (
                <LoadingSpinner isLoading={isLoading} withBody={true} />
            ) : isError ? (
                handleErrorDomiclios()
            ) : (
                <>
                    {errorNuevoDomicilio}
                    {actualizarDomicilioPpalResponse}
                    {domicilioPrincipal && (
                        <FormRow>
                            <Label text="¿Este es el domicilio correcto?" />
                            {existeDomicilioPrincipal ? (
                                <Paragraph size="--twoxs" mod="--arial --light-neutral-800 --mb-16">
                                    {domicilioPrincipal.Calle} {domicilioPrincipal.Altura} {domicilioPrincipal.Localidad} -{' '}
                                    {domicilioPrincipal.Provincia} {domicilioPrincipal.CodigoPostal}
                                </Paragraph>
                            ) : (
                                <Paragraph size="--twoxs" mod="--arial --light-neutral-800 --mb-16">
                                    No tiene un domicilio principal para ésta suscripción
                                </Paragraph>
                            )}
                            <InputGroup className="state--no-wrap">
                                <FormCol>
                                    <InputBox
                                        input={
                                            <RadioInput
                                                name="domicilioCorrecto"
                                                value="true"
                                                checked={!domicilioCambiado}
                                                disabled={!existeDomicilioPrincipal}
                                                onChange={handleChangeOptionOtroDomicilio}
                                            >
                                                Si
                                            </RadioInput>
                                        }
                                    />
                                </FormCol>
                                <FormCol>
                                    <InputBox
                                        input={
                                            <RadioInput
                                                name="domicilioCorrecto"
                                                value="false"
                                                checked={domicilioCambiado}
                                                disabled={!existeDomicilioPrincipal}
                                                onChange={handleChangeOptionOtroDomicilio}
                                            >
                                                No
                                            </RadioInput>
                                        }
                                    />
                                </FormCol>
                            </InputGroup>
                        </FormRow>
                    )}

                    {(domicilioCambiado || !domicilioPrincipal) && (
                        <>
                            <div>
                                <Text size="--s" className="--arial --font-bold --mb-24">
                                    Seleccioná el nuevo domicilio de entrega para { isUpselling ? 'la credencial' : 'las suscripciones'}
                                </Text>
                                <div className='--d-flex --jc-between --ai-center'>
                                    <Label className="--mt-16" text="Domicilios de entrega" />
                                    {domiciliosDisponibles.length > 0 && <Link onClick={handleOpenNuevoDomicilio}>+ Agregar nuevo</Link>}
                                </div>
                                {domiciliosDisponibles.length > 0 ? (
                                    domiciliosDisponibles
                                        .filter((x) => x.Id !== domicilioPrincipal?.Id)
                                        .map((domicilio) => {
                                            return (
                                                <FormCol className="--mb-16" key={domicilio.Id}>
                                                    <InputBox
                                                        error={errorForm}
                                                        input={
                                                            <RadioInput
                                                                onChange={handleValue}
                                                                name="radioDomicilio"
                                                                checked={domicilio.Id === (domicilioSeleccionado?.Id || domicilioSeleccionado?.id)}
                                                                error={errorForm}
                                                                value={domicilio.Id}
                                                            >
                                                                {domicilio.Calle} {domicilio.Altura} {domicilio.Localidad}
                                                                {' - '}
                                                                {domicilio.Provincia} {domicilio.CodigoPostal}
                                                            </RadioInput>
                                                        }
                                                    />
                                                </FormCol>
                                            );
                                        })
                                ) : (
                                    <Text className="--arial empty-state" size="4xs">
                                        No hay domicilios de entrega registrados, <Link onClick={handleOpenNuevoDomicilio}>agregá uno nuevo</Link>.
                                    </Text>
                                )}
                                {errorForm && <span className="com-input__box__descrip --red-500 --fivexs --mb-16">Seleccioná un domicilio</span>}
                            </div>
                        </>
                    )}
                    {openModal && (
                        <Modal closeModal={handleCloseModal} title={modalConfirmacion ? 'Confirmar cambio de domicilio' : 'Agregar domicilio'}>
                            {modalConfirmacion ? (
                                <ConfirmarCambioDomicilio
                                    closeModal={handleCloseModal}
                                    confirmarCambio={handleCambioConfirmado}
                                    nuevoDomicilio={domicilioSeleccionado}
                                    nombreSuscripcion={nombreSuscripcion}
                                />
                            ) : (
                                <NuevoDomicilio
                                    domicilios={domiciliosDisponibles.map(domicilioToObjectMap)}
                                    setDomicilioCreado={handleNuevoDomicilio}
                                    setErrorCreandoDomicilio={handleErrorDomiclios}
                                />
                            )}
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

// Domicilios.propTypes = propTypes;

export default Domicilios;
