import { useState, useEffect } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerMotivosTarjeta = () => {
  const { REACT_APP_APIPAYWALL } = process.env;

  const [isLoading, setIsLoading] = useState(true);
  const [motivos, setMotivos] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const pathUrl = `${REACT_APP_APIPAYWALL}Reclamos/ObtenerMotivosReclamos`;
      ApiService.post(pathUrl, { IdTipoDeReclamo: 2 })
        .then((data) => {
          if (data && data.code === "0000") {
            setMotivos(data.response);
            setIsLoading(false);
          } else {
            setError(`Código de Error:${data.code} - Mensaje: ${data.message}`);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  return [motivos, error, isLoading];
};

export default useObtenerMotivosTarjeta;
