import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const ObtenerProductosDeSuscripcion = dataRequest => {
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/ObtenerProductosDeSuscripcion`;
            ApiService.post(pathUrl, { idSuscripcion: dataRequest })
                .then(data => {
                    if (data.Code == 0) {
                        setData(data.Response);
                        setError(null);
                        setIsLoading(false);
                    } else {
                        setData(null);
                        setError(true);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    setData(null);
                    setError(true);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [data, error, isLoading];
};

export default ObtenerProductosDeSuscripcion;
