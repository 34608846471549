import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Common/Organism/Modal';
import Toast from '../Common/Modules/Toast';
import Button from '../Common/Modules/Button';
import handleOpenModal from '../../Helpers/OpenModal';
import FormRow from '../Common/Atoms/Form/FormRow';

const propTypes = {
    setOpenModal: PropTypes.func.isRequired,
    mailPrincipal: PropTypes.string,
    mailSecundario: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

const ConfirmarCambioMailSecundario = (props) => {
    const handleClose = () => {
        handleOpenModal(true, props.setOpenModal);
    };

    const onConfirmModal = (mailSecundario) => {
        props.setOpenModal(false);
        props.onConfirm(null, mailSecundario);
    };

    return (
        <Modal closeModal={handleClose} title={'Confirmar cambio de email'}>
            <>
                <Toast
                    status={'alert'}
                    title={
                        props.mailSecundario !== ''
                            ? 'Estás por confirmar el cambio de los siguientes datos de tu perfil'
                            : 'Estás por eliminar tu email secundario'
                    }
                >
                    {props.mailSecundario !== '' ? (
                        <span>
                            <b>Email secundario:</b> {props.mailSecundario}
                        </span>
                    ) : (
                        <span>Una vez eliminado, recibirás todas las comunicaciones a tu email principal.</span>
                    )}
                </Toast>
                <FormRow mod="--btn-groups --mb-32">
                    <Button
                        type="button"
                        onClick={() => {
                            onConfirmModal(props.mailSecundario === '' ? props.mailPrincipal : props.mailSecundario);
                        }}
                        href={'#'}
                        label="CONFIRMAR CAMBIO"
                        isFirstPositioned={true}
                        className="state--mobile-w100 --mb-0"
                        iconSize="s"
                    />
                    <Button
                        type="button"
                        onClick={handleClose}
                        href={'#'}
                        label="CANCELAR"
                        isFirstPositioned={true}
                        className="state--mobile-w100 --mb-0"
                        mod="secondary"
                        iconSize="s"
                    />
                </FormRow>
            </>
        </Modal>
    );
};

ConfirmarCambioMailSecundario.propTypes = propTypes;

export default ConfirmarCambioMailSecundario;
