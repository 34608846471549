import React from 'react'
import { Cardbenefit } from '@ln/experiencias-ui-cardbenefit'
import { Carousel } from '../../../Common/Modules/Carousel';
import { BeneficiosPorSuscripcion } from '../../../../Models/BeneficiosPorSuscripcion';
import '@ln/experiencias-ui-cardbenefit/index.css'

export const CarouselBeneficios = ({ plan = 'ln' }) => {
  const { club, digital } = BeneficiosPorSuscripcion;
  const carouselItems = {
    ln: [digital.newsletter, digital.edicionesImpresas, digital.eventosVivo, digital.seriesEspeciales, digital.appLn, digital.trivias, digital.suscriptores, digital.ln],
    lnClub: [digital.newsletter, digital.edicionesImpresas, club.beneficiosClub, club.codigosDescuento, digital.eventosVivo, digital.seriesEspeciales, club.appClub, club.credencialClub],
    club: [club.beneficiosClub, club.codigosDescuento, club.appClub, club.credencialClub]
  }[plan];

  return (
    <Carousel elementsToScroll={3}>
      {carouselItems.map(({ href, image, text }) => (
        <Cardbenefit key={text} linkProps={{ href, title: text, target: '_blank' }} className='h-100'>
          <Cardbenefit.Image src={image} />
          <Cardbenefit.Text text={text} />
        </Cardbenefit>
      ))}
    </Carousel>
  )
}
