import React from "react";

function IconCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        stroke="#126FFF"
        strokeWidth="5"
        d="M101.5 52c0 27.338-22.162 49.5-49.5 49.5S2.5 79.338 2.5 52 24.662 2.5 52 2.5s49.5 22.162 49.5 49.5z"
      ></path>
      <path
        stroke="#126FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M29.392 54.25l14.586 14.707 30.631-28.261"
      ></path>
    </svg>
  );
}

export default IconCheck;
