import React, {useEffect} from 'react';
import '../../../../Statics/Scss/Components/RadioInput.scss';

const RadioInput = ({
	name,
	value,
	children,
	error,
	success,
	disabled,
	checked,
	onChange,
	setIsActive = () => {},
}) => {
	useEffect(() => setIsActive(checked), [checked]);

	return (
		<label
			className={`com-radio${error ? ' state--error' : ''}${
				success ? ' state--success' : ''
			}`}>
			<input
				checked={checked}
				type="radio"
				name={name}
				value={value}
				disabled={disabled}
				onChange={onChange}
			/>
			<span>{children}</span>
		</label>
	);
};

export default RadioInput;
