import React from 'react';
import IncentivarSuscripcionBanner from '../../../Components/Common/Banners/IncentivarSuscripcion';
import IncentivarVincularClubBanner from '../../../Components/Common/Banners/IncentivarVincularClub';
import ToastEmptyState from '../../../Components/Common/Banners/ToastEmptyState';


const ReclamoSinSuscripciones = () => {
    return (
        <>
            <ToastEmptyState titulo={"No tenés suscripción digital"} bajada={"Actualmente no contás con una suscripción digital."} />
            <IncentivarVincularClubBanner showBottomDivider />
            <IncentivarSuscripcionBanner />
        </>
    );
};

export default ReclamoSinSuscripciones;