export const TipoVenta = {
    Alianza: 65,
    Corporativa: 67,
    Individual: 73,
    Granel: 71
};

export const TipoVentaUpselling = {
    Alianza: 'Venta Alianza',
    Corporativa: 'Venta Corporativa',
    Individual: 'Venta Individuo',
}