import { useState } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerDetalleTipoDeEvento = () => {
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoadingCredenciales, setIsLoadingCredenciales] = useState(false);
    const [eventos, setEventos] = useState(null);
    const [errorCredenciales, setErrorCredenciales] = useState(null);
    const [eventosAlreadyClicked, setEventosAlreadyClicked] = useState([]);

    const outFunc = async (IdEventoHistorico) => {
        const eventoMatched = eventosAlreadyClicked.find(
            (evento) => evento.id === IdEventoHistorico
        );

        if (eventoMatched) {
            setEventos({
                CategoriaDeCredencial: eventoMatched.CategoriaDeCredencial,
                Contacto: eventoMatched.Contacto,
                NumeroDeCredencial: eventoMatched.NumeroDeCredencial,
            });
        } else {
            setIsLoadingCredenciales(true);
            return ApiService.post(
                `${REACT_APP_APIRECLAMOS}credenciales/ObtenerDetalleTipoDeEventoAsync`,
                { IdEventoHistorico: IdEventoHistorico }
            )
                .then((data) => {
                    if (data) {
                        if (data.Code === 0 && data.Response) {
                            setIsLoadingCredenciales(false);
                            setEventos(data.Response);
                            setEventosAlreadyClicked([
                                ...eventosAlreadyClicked,
                                { ...data.Response, id: IdEventoHistorico },
                            ]);
                        } else {
                            setEventos(null);
                            setErrorCredenciales(true);
                            setIsLoadingCredenciales(false);
                        }
                    }
                })
                .catch(() => {
                    setErrorCredenciales(true);
                    setIsLoadingCredenciales(false);
                });
        }
    };

    return [eventos, errorCredenciales, isLoadingCredenciales, outFunc];
};

export default useObtenerDetalleTipoDeEvento;
