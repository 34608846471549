import React, { useState } from 'react';
import Title from '../../../Components/Common/Atoms/Title';
import Text from '../../../Components/Common/Text/Text';
import Link from '../../../Components/Common/Link/Link';
import Label from '../../../Components/Common/Atoms/Form/Label';
import RadioInput from '../../../Components/Common/Atoms/Form/RadioInput';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import InputBox from '../../../Components/Common/Atoms/Form/InputBox';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import InfoCard from '../../../Components/Common/Modules/InfoCard';
import useListarTarjetasDeCreditoPaywall from '../../../Hooks/useListarTarjetasDeCreditoPaywall';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import IframeTarjeta from '../IframeTarjeta';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import PathsConfig from '../../../Config/RoutePaths';
import { useHistory } from 'react-router';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import steps from '../../../Config/StepsCambioFormaDePago';
import '../../../Statics/Scss/Pages/SelectPaymentMethod.scss';
import Toast from '../../../Components/Common/Modules/Toast';
import useObtenerMontoMinimoValidacionTarjeta from '../../../Hooks/useObtenerMontoMinimoValidacionTarjeta.js';
import GetCardType from '../../../Helpers/GetCardType';
import { getErrorInstrumentoDeCobro } from '../../../Config/ErrorInstrumentoDeCobro.js';

const SelectPaymentMethod = (props) => {
    const stepProps = ['suscripciones', 'motivo'];
    const { suscripciones, motivo } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ElegirSuscripcion);
    const history = useHistory();
    const [tarjetas, errorTarjetas, isLoadingTarjetas] = useListarTarjetasDeCreditoPaywall();
    const noCardRegistered = tarjetas?.length < 1;
    const [selected, setSelected] = useState();
    const [dataNuevaTarjeta, setDataNuevaTarjeta] = useState();
    const [nombresSuscripciones, setNombresSuscripciones] = useState();
    const [cardState, setCardState] = useState({
        cardSelected: false,
        newCard: false,
        existingCard: false,
    });
    const [formState, setFormState] = useState({
        error: false,
        msg: '',
    });
    const [monto, errorMontoValidacionTarjeta, isLoadingMontoValidacionTarjeta] = useObtenerMontoMinimoValidacionTarjeta();
    const [errorValidacionTarjeta, setErrorValidacionTarjeta] = useState();

    const handleChange = (tarjeta) => {
        setSelected(tarjeta);
        setCardState({ cardSelected: true });
        setFormState(false);
    };

    const handleFormState = () => {
        if (noCardRegistered === true || !cardState.cardSelected) {
            setFormState({
                error: true,
                msg: 'Seleccioná un medio de pago',
            });
        } else {
            setDataNuevaTarjeta(getPayload());
            saveNamesAllSuscriptions();
            setCardState({ existingCard: true });
        }
    };

    //obtengo un string con los nombres de todas las suscripciones
    const saveNamesAllSuscriptions = () => {
        let str = '';
        suscripciones.forEach((s, index) => {
            if (index == suscripciones.length - 1) {
                str += s.nombreSuscripcion;
            } else {
                str += s.nombreSuscripcion + ' , ';
            }
        });
        setNombresSuscripciones(str);
    };

    const getPayload = (nuevaTarjeta) => {
        let idSuscripciones = [];
        suscripciones.forEach((s) => {
            idSuscripciones.push(s.idSuscription);
        });
        const payload = {
            InstrumentoDeCobro: {
                idSuscripciones: idSuscripciones,
                idTajeta: nuevaTarjeta ? null : selected?.Id,
                idInstrumentoDeCobro: selected?.InstrumentoDeCobro?.Id,
                IdMotivoDeCIC: motivo,
            },
        };
        return payload;
    };

    const handleNuevaTarjeta = () => {
        setDataNuevaTarjeta(getPayload(true));
        setCardState({ newCard: true });
    };

    const errorInstrumentoDeCobro = getErrorInstrumentoDeCobro(errorValidacionTarjeta?.code)

    //los <Iframe> pueden englobarse en uno solo y unificar las condiciones, porque se les pasa lo mismo TODO

    return (
        <>
            <section className="container-streched">
                <section>
                    <Breadcrumb firstItem="Mis Pagos" text="Cambiar forma de pago" href="#" />

                    <TitleWeb text="Cambiar forma de pago" />
                </section>
                <LoadingSpinner withBody isLoading={isLoadingTarjetas || isLoadingMontoValidacionTarjeta}>
                    <ErrorBoundary error={errorTarjetas || errorMontoValidacionTarjeta}>
                        <Wizard steps={steps} activeStep={2} />
                        {!errorInstrumentoDeCobro && (
                            <Toast
                                status={'info'}
                                title={`Para validar tu tarjeta, realizaremos un pequeño cargo de $${monto} que luego te devolveremos.`}
                            />
                        )}
                        {!cardState.newCard && !cardState.existingCard && (
                            <>
                                <Title size="--s" mod="--font-bold --mb-24">
                                    Elegí un medio de pago
                                </Title>

                                <form action="#">
                                    <Label
                                        text="Mis medios de pago"
                                        link={
                                            <Link className="com-link" onClick={handleNuevaTarjeta}>
                                                + Nueva tarjeta
                                            </Link>
                                        }
                                    />
                                    {!noCardRegistered ? (
                                        <FormRow>
                                            {tarjetas?.map((tarjeta) => {
                                                return (
                                                    <FormCol className="--mb-8">
                                                        <InputBox
                                                            error={formState.error}
                                                            input={
                                                                <RadioInput onChange={() => handleChange(tarjeta)} name="payment-method">
                                                                    <InfoCard
                                                                        cardType={GetCardType(tarjeta.InstrumentoDeCobro.Nombre)}
                                                                        lastNumbers={tarjeta.UltimosNumeros}
                                                                        className="--mb-0"
                                                                        bank={tarjeta.Banco ? tarjeta.Banco.Nombre : ''}
                                                                        isCompact
                                                                    />
                                                                </RadioInput>
                                                            }
                                                        />
                                                    </FormCol>
                                                );
                                            })}
                                            {formState.error && (
                                                <Text tag="p" size="5xs" className="--mt-8" color="#E2342D">
                                                    {formState.msg}
                                                </Text>
                                            )}
                                        </FormRow>
                                    ) : (
                                        <FormRow>
                                            <FormCol className="--mb-8">
                                                <InputBox
                                                    error={formState.error}
                                                    input={
                                                        <Text>
                                                            No tenés medios de pago registrados, <strong>agregá uno nuevo</strong>
                                                        </Text>
                                                    }
                                                />
                                            </FormCol>
                                        </FormRow>
                                    )}
                                    <FormRow mod="--btn-groups">
                                        <Button label="Cambiar medio de pago" mod="state--mobile-w100" onClick={handleFormState} />
                                        <Button
                                            label="Volver"
                                            mod="tertiary"
                                            className="--mt-0"
                                            onClick={() =>
                                                history.push({
                                                    pathname: PathsConfig.ElegirSuscripcion,
                                                })
                                            }
                                        />
                                    </FormRow>
                                </form>
                            </>
                        )}

                        {(cardState.newCard || cardState.existingCard) && (
                            errorInstrumentoDeCobro ? (
                                <div>
                                    <Toast {...errorInstrumentoDeCobro} />
                                    <Button label="Volver" onClick={() => setCardState({ newCard: false })} className="--mb-8 --tertiary" />
                                </div>
                            ) : (
                                <div>
                                    <Text size="--s --font-bold" mod="h4">
                                        {cardState.newCard ? 'Nueva Tarjeta' : cardState.existingCard && 'Validar Tarjeta'}
                                    </Text>
                                    <div>
                                        <IframeTarjeta
                                            cambioFormaPagoTarjeta={true}
                                            payloadCambiarFormaDePago={dataNuevaTarjeta}
                                            suscripciones={suscripciones}
                                            iframeHeight={cardState.newCard ? 410 : cardState.existingCard && 220}
                                            setErrorValidacionTarjeta={setErrorValidacionTarjeta}
                                        />
                                        <Button label="Volver" onClick={() => setCardState({ newCard: false })} className="--mb-8 --tertiary" />
                                    </div>
                                </div>
                            )
                        )}
                    </ErrorBoundary>
                </LoadingSpinner>
            </section>
        </>
    );
};

export default SelectPaymentMethod;
