import React from 'react';
import Paragraph from '../Atoms/Paragraph';
import Toast from '../Modules/Toast';
import { mailAtencionSocios } from '../../../Config/DatosDeContacto';

//Reutilizar en confirmar suspension
const ErrorServicioYContacto = ({title}) => {
    return (
        <div>
            <Toast
                status={'warning'}
                title={title} 
            >
                <Paragraph className="--arial --font-regular">
                    Por favor, intentalo de nuevo. Si el error persiste
                    comunicate con nosotros a{' '}
                    <span className="email">
                        <strong>{mailAtencionSocios}</strong>
                    </span>
                </Paragraph>
            </Toast>
        </div>
    );
};

export default ErrorServicioYContacto;
