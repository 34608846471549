import React, { useState } from 'react';

import classNames from 'classnames';
import '../../../../Statics/Scss/Components/InputInfoSuscription.scss'

const InputInfoSuscription = ({ input, className, error, success, disabled, isChecked, mod }) => {
    const [isActive, setIsActive] = useState(false);
    const classes = classNames('InputInfoSuscription', className, {
        'state--error': error,
        'state--success': success,
        'state--disabled': disabled,
        'state--active': (isActive && !success) || isChecked,
        mod,
    });

    return (
        <div className={classes} tabIndex="0">
            <div className="InputInfoSuscription__main__input">{React.cloneElement(input, { setIsActive })}</div>
        </div>
    );
};

export default InputInfoSuscription;
