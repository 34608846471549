import Cookie from 'js-cookie';
import { readCookie } from './Cookies';

const email = Cookie.get('usuarioemail');
const crmId = readCookie("Crm_id");

DD_LOGS.onReady(() => {
    console.log(`DLOG :: context :: email=${email}`);
    DD_LOGS.logger.addContext('user.email', email);
    DD_LOGS.logger.addContext('user.crmId', crmId);
});

function getGoogleAnaliticsId() {
    return new Promise(function (resolve, reject) {
        if (!ga) return resolve(); // No esta cargado GTM.
        ga(function () {
            const gaid = ga.getAll()[0].get('clientId');
            if (gaid) resolve(gaid);
            else reject('No se pudo obtener el GaId');
        });
    });
}

function initGoogleAnalitics() {
    getGoogleAnaliticsId().then(function (gaId) {
        if (!gaId) return;
        DD_LOGS.onReady(() => {
            console.log(`DLOG :: context :: gaId=${gaId}`);
            DD_LOGS.logger.addContext('user.gaId', gaId);
        });
        DD_RUM.onReady(function () {
            DD_RUM.setUser({
                gaId: gaId,
            });
        });
    });
}

initGoogleAnalitics();

export const trackCustomEvent = (event, data) => {
    DD_LOGS.onReady(() => {
        DD_LOGS.logger.info('customEvent', {
            eventData: {
                evento: event,
                ...data,
            },
        });
    });

    DD_RUM.onReady(() => {
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email,
            crmId: crmId,
        });
    });
};

export const trackCustomEventWarning = (event, data) => {
    DD_LOGS.onReady(() => {
        DD_LOGS.logger.warn('customEvent', {
            eventData: {
                evento: event,
                ...data,
            },
        });
    });

    DD_RUM.onReady(() => {
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email,
            crmId: crmId,
        });
    });
};

export const trackCustomEventError = (event, data) => {
    DD_LOGS.onReady(() => {
        DD_LOGS.logger.error('customEvent', {
            eventData: {
                evento: event,
                ...data,
            },
        });
    });

    DD_RUM.onReady(() => {
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email,
            crmId: crmId,
        });
    });
};

export default {
    trackCustomEvent,
    trackCustomEventWarning,
    trackCustomEventError
};
