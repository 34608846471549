import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
        'a': ['href']
    }
};

const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty,
        options = { ...defaultOptions, ...options }
    )
});

export default sanitize;