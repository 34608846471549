import * as Yup from "yup";

const FormBajaSuscripcionesSchema = Yup.object().shape({
    telefono: Yup.string()
        .matches(/^[0-9]{6,}$/, "Ingresá un teléfono válido.")
        .min(8, "Ingresá al menos ${min} números.")
        .max(20, "Ingresá hasta ${max} números.")
        .nullable()
        .test("Teléfono de Contacto", "Ingresá al menos un teléfono de contacto.", function (value) {
            return this.parent.telefono;
        }),
    telAlternativo: Yup.string()
        .matches(/^[0-9]{6,}$/, "Ingresá un teléfono válido.")
        .min(8, "Ingresá al menos ${min} números.")
        .max(20, "Ingresá hasta ${max} números.")
        .nullable(),
    email: Yup.string()
        .email('Ingresá un email válido.')
        .required('Campo requerido.'),
    observaciones: Yup.string().optional()
});

export default FormBajaSuscripcionesSchema;
