const errorInstrumentoDeCobro = {
  '1202': {
    status: 'warning',
    title: 'Ya superaste la cantidad de intentos de este mes',
    children: 'Para mas información o soporte en la gestión podes comunicarte al 115199-4700 de Lunes a Viernes de 8:00 a 20:00hs.'
  },
  '1203': {
    status: 'alert',
    title: 'Superaste la cantidad de intentos por el día de hoy',
    children: 'Te pedimos que intentes nuevamente dentro de algunas horas. Para mas información o soporte en la gestión podes comunicarte al 115199-4700 de Lunes a Viernes de 8:00 a 20:00hs'
  },
  '1205': {
    status: 'alert',
    title: 'Superaste el limite establecido para realizar el cambio de instrumento de cobro',
    children: 'Para mas información o soporte en la gestión podes comunicarte al 115199-4700 de Lunes a Viernes de 8:00 a 20:00hs.'
  },
}

export const getErrorInstrumentoDeCobro = (code) => errorInstrumentoDeCobro[code]