import React from "react";
import PropTypes from "prop-types";
import "../../../Statics/Scss/Components/WizardItemMobile.scss";

const WizardItemMobile = ({ stepNumber, totalSteps, steps }) => {

    const getStepName = (step) => Object.values(steps).find(({ number }) => number === step)?.text

    const currentStep = getStepName(stepNumber)
    const nextStep = getStepName(stepNumber + 1)
    const percentage = (stepNumber / totalSteps) * 100

    return (
        <div className="com-wizard-item-mob">
            <div className="com-wizard-item-mob__step">
                <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    className="circular-progress"
                    style={{ "--_progress": percentage }}
                >
                    <circle className="background" />
                    <circle className="progress" />
                </svg>
                {stepNumber} de {totalSteps}
            </div>
            <div className="com-wizard-item-mob__text">
                <p className="com-wizard-item-mob__text-step">{currentStep}</p>
                {nextStep && <p className="com-wizard-item-mob__text-next">Próximo paso: {nextStep}</p>}
            </div>
        </div>
    );
}

WizardItemMobile.propTypes = {
    stepNumber: PropTypes.number,
    totalSteps: PropTypes.number,
    steps: PropTypes.object,
}

export default WizardItemMobile;