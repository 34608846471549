const steps = {
    step_1: {
        number: 1,
        text: "Motivo del reclamo",
    },
    step_2: {
        number: 2,
        text: "Periodo a reclamar",
    },
    step_3: {
        number: 3,
        text: "Confirmación",
    },
};

export default steps;
