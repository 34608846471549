import React, { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useCrearAbandonoCobranza = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [idAbandonoCobranza, setIdAbandonoCobranza] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/CrearAbandonoCobranza`)
                .then((data) => {
                    if (data && data.Code === 0) {
                        setIdAbandonoCobranza(data.Response);
                        setIsLoading(false);
                    } else {
                        setError(data.Message);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [idAbandonoCobranza, error, isLoading];
};

export default useCrearAbandonoCobranza;
