import { useState } from 'react';
import { trackPage, trackError } from '../Helpers/UpsellingTracker';
import Mensaje from '../Models/Mensaje';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';
import ApiService from '../Services/ApiService';

const useGetInfoPlanUpselling = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [infoConfirmacion, setInfoConfirmacion] = useState(null);

    const outFunc = (data) => {
        setIsLoading(true);
        setError(null)
        const { campania, planUpselling, pathName, planActual } = data;
        ApiService.get(`${REACT_APP_APIPAYWALL}CambioPlanV1/GetInfoConfirmacionMyAccount`, { campania: campania, plan: planUpselling.id})
            .then((res) => {
                Datadog.trackCustomEvent(CustomEventsDatadog.consultaUpselling, { planUpselling: { nombre: planUpselling.nombre, id: planUpselling.id }, planActual, origen: pathName });
                setIsLoading(false);
                if (res.code === '0000') {
                    setInfoConfirmacion(res.response);
                    trackPage(null, pathName, res.response);
                } else {
                    setError(Mensaje.getError('GENERAL'))
                    Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConsultaUpselling, { planUpselling: { nombre: planUpselling.nombre, id: planUpselling.id }, planActual, origen: pathName, res })
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setError(Mensaje.getError('GENERAL'));
                Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConsultaUpselling, err)
                trackError(pathName);
            })
            .finally(()=>{
                setIsLoading(false)
            })
    };

    return [infoConfirmacion, error, loading, outFunc];
};
export default useGetInfoPlanUpselling;