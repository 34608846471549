import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import BasicTablePaginationBills from '../../Components/Common/Modules/Table/BasicTablePaginationBills';
import useObtenerFacturas from '../../Hooks/useObtenerFacturas';
import useObtenerSuscripciones from '../../Hooks/useObtenerSuscripciones';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import googleSuscription from '../../Filter/googleSuscription';
import appleSuscription from '../../Filter/appleSuscription';
import mapFacturasToTable from '../../Map/FacturasMap';
import MensajeGoogle from '../../Components/Features/Facturas/MensajeGoogle';
import MensajeApple from '../../Components/Features/Facturas/MensajeApple';
import TitleWeb from '../../Components/Common/Web/Title';
import Datadog from '../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../Config/Datadog';
import { useStore } from '../../Store/storeProvider';
import useDescargarFactura from '../../Hooks/useDescargarFactura';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';
const propTypes = {
    toastCondition: PropTypes.bool,
    comprobantes: PropTypes.bool,
};
const MisFacturas = () => {
    const [facturas, isLoadingFacturas, error] = useObtenerFacturas();
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const { REACT_APP_PAYMENTGOOGLE } = process.env;
    const { REACT_APP_PAYMENTAPPLE } = process.env;
    const { REACT_APP_APIPAYWALL } = process.env;
    const { HandleSwitchsStates } = useStore();
    const [descargarFactura, errorDescarga, isLoadingDescarga] = useDescargarFactura();
    const [initialActivePage, setInitialActivePage] = useState();
    const [googleSubscription, setGoogleSubscription] = useState();
    const [appleSubscription, setAppleSubscription] = useState();

    useEffect(() => {
        if (suscripciones?.length > 0) {
            if (googleSuscription.hasAvailableGoogleSuscription(suscripciones)) {
                setGoogleSubscription(true);
            }
            if (appleSuscription.hasAvailableAppleSuscription(suscripciones)) {
                setAppleSubscription(true);
            }
        }
    }, [suscripciones]);

    const tieneComprobantes = () => {
        return !error && facturas.length > 0;
    };
    const handlerClickItemButton = (e) => {
        const numeroFactura = e.number.value;
        descargarFactura(numeroFactura);
        Datadog.trackCustomEvent(CustomEventsDatadog.descargarFactura);
    };

    return (
        <div>
            <section>
                <Breadcrumb firstItem={'Mis pagos'} href={'#'} text={'Ver facturas'} />
                <TitleWeb text="Ver facturas" />
            </section>
            <section className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.MisFacturas) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.MisFacturas} />
                ) : (
                    <LoadingSpinner isLoading={isLoadingSuscripciones || isLoadingFacturas || isLoadingDescarga}>
                        <>
                            {tieneComprobantes() ? (
                                <>
                                    <BasicTablePaginationBills
                                        items={mapFacturasToTable(facturas, REACT_APP_APIPAYWALL)}
                                        align="center"
                                        itemsPerPage={5}
                                        onClickItemButton={handlerClickItemButton}
                                        initialActivePage={initialActivePage}
                                        setCurrentPage={setInitialActivePage}
                                    />
                                </>
                            ) : (
                                <>
                                    {!errorSuscripciones && suscripciones?.length > 0 && (appleSubscription || googleSubscription) ? (
                                        <>

                                            {googleSubscription && (
                                                <MensajeGoogle linkGoogle={REACT_APP_PAYMENTGOOGLE}></MensajeGoogle>
                                            )}
                                            {appleSubscription && (
                                                <MensajeApple linkApple={REACT_APP_PAYMENTAPPLE}></MensajeApple>
                                            )}

                                        </>
                                    ) : (
                                        facturas.length === 0 && (
                                            <ErrorBoundary error={error || (errorSuscripciones && !suscripciones)}>
                                                <Toast status="info" title="Esta cuenta no tiene facturas disponibles para descargar" />
                                            </ErrorBoundary>
                                        )
                                    )}
                                </>
                            )}
                        </>
                    </LoadingSpinner>
                )}
            </section>
        </div>
    );
};

MisFacturas.propTypes = propTypes;
export default MisFacturas;