import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ItemsBills from './ItemsBills';
import Paragraph from '../../Atoms/Paragraph';

const propTypes = {
    /**
     * Valores y propiedades de
     * cada celda.
     */
    items: PropTypes.array,
    /**
     * Página actual.
     */
    currentPage: PropTypes.number,
    /**
     * Alineación de las celdas.
     */
    align: PropTypes.string,
    /**
     * Cantidad de items por página.
     */
    itemsPerPage: PropTypes.number,
    /**
     * Handler para cuando cambie una página.
     */
    onPageChange: PropTypes.func,
    /**
     * Flag, ¿posee checkbox?
     */
    isCheckable: PropTypes.bool,
    /**
     * Handler para los checkboxes.
     */
    handlerCheck: PropTypes.func,
    /**
     * Flag, ¿es fixed layout?
     */
    isFixedLayout: PropTypes.bool,
    /**
     * Text del tooltip
     */
    tooltipText: PropTypes.string,
    /**
     * Posición del tooltip
     */
    tooltipPosition: PropTypes.string,
    /**
     * Orientacion de la celda botón fija
     */
    actionPositionSticky: PropTypes.oneOf(['left', 'right']),
    /**
     * Orientacion de la celda común fija
     */
    cellPositionSticky: PropTypes.oneOf(['left', 'right']),
    /**
     * Celda del header que va a quedar fija
     */
    cellHeaderSticky: PropTypes.oneOf(['first', 'last']),
};

const defaultProps = {
    currentPage: 0,
    align: 'center',
    itemsPerPage: 8,
    onPageChange: () => {},
    handlerCheck: () => {},
};

const BasicTableBills = ({
    items,
    tooltipText,
    tooltipPosition,
    actionPositionSticky,
    cellPositionSticky,
    cellHeaderSticky,
    itemSelected,
    itemsPerPage,
    currentPage,
    onPageChange,
    onClickItemButton,
    align,
    isCheckable,
    handlerCheck,
    isFixedLayout,
    totalItems,
    ...r
}) => {
    let maxIndex, minIndex, actualItems;

    /*
     * Si contiene la cantidad de items por páginas,
     * realizo cálculos para la paginación, y... pagino.
     */
    if (itemsPerPage) {
        maxIndex = (currentPage + 1) * itemsPerPage;
        minIndex = maxIndex - itemsPerPage;
        actualItems = items.slice(minIndex, maxIndex);

        items = actualItems;
    }

    const handleRowClick = (item) => {
        if (itemSelected) {
            const id = item.id.value;
            itemSelected(id);
        }

        if (onClickItemButton) {
            onClickItemButton(item);
        }
    };

    return (
        <div className="--mb-32">
            <Paragraph size="--s" mod="--font-bold --arial --mb-32">
                Facturas
            </Paragraph>
            <div className="containerBills">
                {items.map((item, i) => (
                    <div id={item.number.value} key={item.number.value}>
                        <ItemsBills item={item} onRowClick={handleRowClick}></ItemsBills>
                    </div>
                ))}
            </div>
        </div>
    );
};

BasicTableBills.propTypes = propTypes;
BasicTableBills.defaultProps = defaultProps;

export default BasicTableBills;
