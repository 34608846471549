import React from 'react';
import CheckInput from '../Components/Common/Atoms/Form/CheckInput';
import InputBox from '../Components/Common/Atoms/Form/InputBox';
import { transformDate } from '../Helpers/Dates';
import { obtenerLeyenda } from '../Models/Despacho';
import { esDespachoNoReclamable } from '../Helpers/Reclamo';

// {
//     "Id": 438617694,
//     "Nombre": "Abono Domingo",
//     "Fecha": "2021-01-31T00:00:00",
//     "TieneObservacion": false,
//     "Observacion": "",
//     "IdSuscripcion": 4580178,
//     "SalidaTardia": false,
//     "Inhabilitado": false,
//     "EstaReclamado": false
//   }

const Map = (despachos, clickHandler, selectedItems) => {

    const renderTextDespacho = (despacho)=>{
        let result = null;
        if(despacho.EstaReclamado && despacho.TieneObservacion){
            result = <p>Ya fué reclamado <br></br> {despacho.Observacion} </p>
        }else if ( despacho.TieneObservacion ){
            result = <p>{despacho.Observacion} </p>
        }
        return result;
    }

    return despachos.map((despacho, key) => {
        const leyendaDespacho = obtenerLeyenda(despacho.EstadoDespacho.Id);
        const despachoNoEsReclamable = esDespachoNoReclamable(despacho);

        return (
            <InputBox key={despacho.Id}
                value={despacho.Id}
                disabled={despachoNoEsReclamable}
                input={
                    <CheckInput 
                        disabled={despachoNoEsReclamable} 
                        checked={selectedItems.some(i => i == despacho.Id)} onChange={clickHandler} value={despacho.Id} >
                        {despacho.Nombre + ' ' + transformDate(despacho.Fecha)}
                    </CheckInput>
                }
                text={
                despacho.EstaReclamado 
                        ? (despacho.TieneObservacion 
                            ? (<p> Ya fué reclamado <br></br> {despacho.Observacion}</p>) 
                            : (<p>Ya fué reclamado <br></br></p>) 
                        )
                        : (<p>{leyendaDespacho}</p>)
                }/>
        );
    });
};

export default Map;
