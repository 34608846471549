import React from 'react';
import { Alert as LibAlert } from '@ln/experiencias-ui-alert';
import { Checked, Info, Danger, Warning } from '@ln/experiencias-ui-assets';

export const Alert = ({ variant, hideIcon, customContent, ...props }) => {
  const icon = {
    danger: <Danger />,
    success: <Checked />,
    info: <Info />,
    warning: <Warning />
  }[variant];

  return (
    <LibAlert variant={variant} icon={!hideIcon && icon} iconProps={{ color: variant === "warning" ? undefined : "light" }} {...props} customContent={customContent} />
  );
};
