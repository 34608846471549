const DiccionarioCookiesAGuardarBorrar = [
  "usuariosexo",
  "usuarioemail",
  "usuarioanio",
  "usuarioDetalleClubNacion",
  "UsuarioDetalleGuid",
  "usuario%5Fdetalle%5Fguid",
  "UsuarioDetalleNick",
  "usuario%5Fdetalle%5Fnick",
  "UsuarioId",
  "usuario%5Fid",
  "UsuarioUsuario",
  "usuario%5Fusuario",
  "usuarioLogTkn",
  "usuario%5Flogtkn",
  "cookieLogin",
  "syncLfLN",
  "tieneClub",
  "Provinciaid",
  "Paisid",
  "LNPreferencias",
  "Crm_id",
  "ProductoPremiumId",
  "token",
  "xvalue",
  "TokenJWT",
  "usuario_detalle_nombre",
  "usuario_detalle_apellido"
];

const ExpirationDays = 100;

const Map = (key) => {
  switch (key) {
    case 'UsuarioDetalleGuid':
      return 'usuario%5Fdetalle%5Fguid';
      break;
    case 'UsuarioDetalleNick':
      return "usuario%5Fdetalle%5Fnick";
      break;
    case 'UsuarioId':
      return "usuario%5Fid";
      break;
    case 'UsuarioUsuario':
      return "usuario%5Fusuario";
      break;
    case 'usuarioLogTkn':
      return "usuario%5Flogtkn";
      break;
    case 'TokenJWT':
      return "PersoTKN";
      break;
    default:
      return key;

  }
}

export { DiccionarioCookiesAGuardarBorrar, Map , ExpirationDays}

