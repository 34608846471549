import React from 'react'
import PropTypes from 'prop-types'
import Toast from '../../Common/Modules/Toast'
import Button from '../../../Components/Common/Modules/Button';

const MensajeDesvinculacionExitosa = props => {
    return (
        <Toast
            status="success"
            title="Desvinculación exitosa"
        >
            La desvinculación fue solicitada.
        </Toast>
    )
}

export default MensajeDesvinculacionExitosa
