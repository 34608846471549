const _location = new URL(window.location);
const urlParams = new URLSearchParams(_location?.search);

const getParam = (name) => urlParams?.get(name);

const getParamOrSetIfNotExist = (key, valueDefault) => {
    if(!(_location.searchParams.has(key))){
        _location.searchParams.append(key, valueDefault);
        window.history.pushState(null, '', _location.toString());
        return valueDefault;
    }
    return _location.searchParams.get(key)
}

export default { getParam, getParamOrSetIfNotExist };
