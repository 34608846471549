import React from 'react';
import PathsConfig from '../Config/RoutePaths';
import { User, ClubLn, FolderSettings, AlarmWarning, BankCard, Newspaper } from '@ln/experiencias-ui-assets';

const Menu = [
    {
        GroupName: 'Mi cuenta',
        Icon: <User />,
        Childrens: [
            {
                Display: 'Mi usuario',
                Path: PathsConfig.MenuMiUsuario,
            },
            {
                Display: 'Datos personales',
                Path: PathsConfig.MenuDatosPersonales,
            },
        ],
    },
    {
        GroupName: 'Club LA NACION',
        Icon: <ClubLn />,
        Childrens: [
            {
                Display: 'Vincular credencial',
                Path: PathsConfig.MenuVincularCredencial,
            },
            {
                Display: 'Mis credenciales',
                Path: PathsConfig.MenuMisCredenciales,
            },
            {
                Display: 'Mis consumos',
                Path: PathsConfig.MenuMisConsumos,
            },
        ],
    },
    {
        GroupName: 'Mis suscripciones',
        Icon: <FolderSettings />,
        Childrens: [
            {
                Display: 'Ver suscripciones',
                Path: PathsConfig.MenuMisSuscripciones,
            },
            {
                Display: 'Suspender por vacaciones',
                Path: PathsConfig.MenuSuspensionVacaciones,
            },
            {
                Display: 'Cambiar domicilio de entrega',
                Path: PathsConfig.MenuMisDomicilios,
            },
        ],
    },
    {
        GroupName: 'Mis reclamos',
        Icon: <AlarmWarning />,
        Childrens: [
            {
                Display: 'De entrega',
                Path: PathsConfig.MenuReclamoEntrega,
            },
            {
                Display: 'Credencial Club',
                Path: PathsConfig.MenuReclamoTarjeta,
            },
            {
                Display: 'Acceso digital',
                Path: PathsConfig.MenuReclamoAccesoDigital,
            },
        ],
    },
    {
        GroupName: 'Mis pagos',
        Icon: <BankCard />,
        Childrens: [
            {
                Display: 'Ver facturas',
                Path: PathsConfig.MenuMisFacturas,
            },
            {
                Display: 'Pagos pendientes',
                Path: PathsConfig.MenuPagosPendientes,
            },
            {
                Display: 'Cambiar forma de pago',
                Path: PathsConfig.MenuCambiarFormaDePago,
            },
            {
                Display: 'Estado de cuenta',
                Path: PathsConfig.MenuEstadoDeCuenta,
            },
        ],
    },
    {
        GroupName: 'Mi actividad',
        Icon: <Newspaper />,
        Childrens: [
            {
                Display: 'Leer edición impresa',
                Path: PathsConfig.EdicionImpresa,
            },
            {
                Display: 'Newsletters',
                Path: PathsConfig.NewsLetters,
            },
        ],
    },
];


export default Menu;
