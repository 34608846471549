import React from 'react';
import planGrupalImg from '../../Statics/Imgs/planes-grupales.png';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';
import { Image } from '@ln/experiencias-ui-image';
import { Text } from '@ln/common-ui-text';
import Button from '../../Components/Common/Modules/Button';

const UpsellingConfirmadoBlack = ({ handleClick }) => {
    return (
        <>
            <div className="w-100 flex flex-column ai-center jc-center arial">
                <Image height={84} src={planGrupalImg} alt="planes grupales" className="--mb-16 bg-white" />
                <Text as="h2" className="prumo prumo-black text-32">
                    ¡Felicitaciones!
                </Text>
                <Text as="p" className="arial-regular text-16 --mb-16 text-center text-neutral-light-600">
                    Ya sos un suscriptor full. Seguí disfrutando del contenido
                </Text>
                <Button onClick={() => handleClick('mis-credenciales')} label="Seguir navegando" className="state--mobile-w100" />
            </div>
        </>
    );
};

export default UpsellingConfirmadoBlack;
