import React from "react";
import Title from "../Atoms/Title";
import EmbedHide from "../Organism/EmbedHide";

const TitleWeb = props => {
    return (
        <EmbedHide>
            <div className="mod-title">
                <Title tag="h1" size="--xl" mod="--sueca --font-bold">
                    {props.text}
                </Title>
            </div>
        </EmbedHide>
    )
};

export default TitleWeb;
