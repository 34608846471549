import * as Yup from "yup";

const FormCrearUsuarioSchema = Yup.object().shape({
  nombre: Yup.string().required("Completá el Nombre para continuar"),
  apellido: Yup.string().required("Completá el Apellido para continuar"),
  numeroDocumento: Yup.string()
    .required("Completá el Número de Documento para continuar")
    .test({
      name: "numeroDocumento",
      message: "El número de DNI que ingresaste no es válido",
      test: function (value) {
        if (!value) {
          return false;
        } else {
          let myRe = /^[\d]{1,3}\.?[\d]{3,3}\.?[\d]{3,3}$/;
          return myRe.test(value);
        }
      },
    }),
  Sexo: Yup.string().required("Completá el Género para continuar"),
  correo: Yup.string()
    .email("Ingrese un email válido")
    .required("Completá el email para continuar"),
  fechaCumpleanios: Yup.date().required(
    "Completá la Fecha de nacimiento para continuar"
  ),
  celular: Yup.string()
    .matches(/^[0-9]{6,}$/, "Ingresá un telefono celular.")
    .min(8, "Ingresá al menos ${min} números")
    .max(20, "Ingresá hasta ${max} números")
    .nullable()
    .test(
      "Celular",
      "Por favor, completá un teléfono para continuar",
      function (value) {
        return this.parent.celular || this.parent.particular;
      }
    ),
  particular: Yup.string()
    .matches(/^[0-9]{6,}$/, "Ingresá un telefono particular.")
    .min(8, "Ingresá al menos ${min} números")
    .max(20, "Ingresá hasta ${max} números")
    .nullable()
    .test(
      "Particular",
      "Por favor, completá un teléfono para continuar",
      function (value) {
        return this.parent.celular || this.parent.particular;
      }
    ),
});

export default FormCrearUsuarioSchema;
