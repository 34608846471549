import { daysMap, days, meses } from "../Config/Dates";

export const daysArrayCreator = string => {
    return Object.keys(days).map(dayKey => {
        return { day: days[dayKey], disabled: !string.includes(dayKey) }
    });
};

export function dateShort(dateString) {
    if (dateString) {
        var d = dateString;
        if (typeof d === 'string') {
            d = new Date(dateString.substring(0, 4), dateString.substring(5, 7) - 1, dateString.substring(8, 10));
        };
        dateString = ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth() + 1)).slice(-2) + '/'
            + d.getFullYear();
    };
    return dateString;
};

export function dateLong(dateString) {
    if (dateString) {
        let d = dateString;
        if (typeof fecha != 'object') {
            d = new Date(dateString);
        };
        dateString = d.getDate() + ' de ' + meses[d.getMonth()] + ' de ' + d.getFullYear();
    };
    return dateString;
};

export const range = (start, end, length = end - start + 1) =>
    Array.from({ length }, (_, i) => start + i);

export const checkRange = (from, initialDate, finalDate, onChange, _d) => {
    if (from && initialDate >= finalDate)
        onChange(_d, null);
    else if (from && initialDate < finalDate)
        onChange(_d, finalDate);
    else if (!from && finalDate <= initialDate)
        onChange(null, _d);
    else if (!from && finalDate > initialDate)
        onChange(initialDate, _d);
};

export const years = range(1921, new Date().getFullYear());

export const getDateStringFromTimestamp = timestamp => {

    let dateObject = new Date(timestamp);
    let month = dateObject.getMonth() + 1;
    let date = dateObject.getDate();

    if (date && month && dateObject)
        return dateObject.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
}



const getNumberOfDays = (year, month) => {
    return 40 - new Date(year, month, 40).getDate();
}

const getDayDetails = args => {

    let date = args.index - args.firstDay;
    let day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;

    if (prevMonth < 0) {
        prevMonth = 11;
        prevYear--;
    }

    let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
    let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
    let timestamp = new Date(args.year, args.month, _date).getTime();

    return {
        date: _date,
        day,
        month,
        timestamp,
        dayString: daysMap[day]
    }
}

export const getMonthDetails = (year, month) => {

    let firstDay = (new Date(year, month)).getDay();
    let numberOfDays = 40 - new Date(year, month, 40).getDate();
    let monthArray = [];
    let rows = 6;
    let currentDay = null;
    let index = 1;
    let cols = 7;

    for (let row = 0; row < rows; row++) {

        for (let col = 0; col < cols; col++) {
            currentDay = getDayDetails({
                index,
                numberOfDays,
                firstDay,
                year,
                month
            });
            monthArray.push(currentDay);
            index++;
        }
    }

    return monthArray;
}


export const transformDate = (date) => {
    const options = { month: "long", day: "numeric" };
    return new Date(date).toLocaleString("es-AR", options);
};