const FuncionalidadesConfig = {
    PagosPendientes: {
        descripcion: "Pagos pendientes",
        evento: "Pagos Pendientes"
    },
    MisFacturas: {
        descripcion: "La descarga de facturas",
        evento: "Mis Facturas"
    },
    EstadoDeCuenta: {
        descripcion: "El estado de tu cuenta",
        evento: "Estado de Cuenta"
    },
    CambiarFormaDePago: {
        descripcion: "Cambio de forma de pago",
        evento: "Cambio Forma de Pago"
    },
    ReclamoSuscripcion: {
        descripcion: "Reclamos de suscripciones",
        evento: "Reclamo de Suscripcion"
    },
    ReclamosCredencial: {
        descripcion: "Reclamos de credencial",
        evento: "Reclamo de Credencial"
    },
    ReclamoAD: {
        descripcion: "Reclamos de acceso digital",
        evento: "Reclamos Acceso Digital"
    },
    SuspenderPorVacaciones: {
        descripcion: "Gestión de suspensión de envío por vacaciones",
        evento: "Suspender Por Vacaciones"
    },
    MisCredenciales: {
        descripcion: "Gestionar mis credenciales",
        evento: "Mis Credenciales"
    },
    MisConsumos: {
        descripcion: "La consulta de consumos",
        evento: "Mis Consumos"
    },
    Upselling: {
        descripcion: "Gestionar mejoras de plan",
        evento: "Upselling"
    },
    CambiarDomicilio: {
        descripcion: "Gestionar mis domicilios",
        evento: "Cambio Domicilio"
    },
    VinculacionCredencial: {
        descripcion: "La vinculación de credencial",
        evento: "Vincular Credencial"
    },
    BajaSuscripciones: {
        descripcion: "La Baja de Suscripciones",
        evento: "Baja Suscripcion"
    },
    CambiarContrasenia: {
        descripcion: "El cambio de contraseña",
        evento: "Cambiar Contraseña"
    },
    MejoraTuPlan: {
        descripcion:"Gestionar mejoras de plan",
        evento: "Mejora tu Plan"
    }
};
export default FuncionalidadesConfig;

   