import { axiosClient } from './AxiosInstance';
import { BuildBearerAccessTokenAsync, LogoutAsync, getIdTokenCookie } from '@ln/user.client.libs'

const post = async (url, body, header = false) => {
    const headers = {
        'Content-Type': 'application/json',
        'X-Token': getIdTokenCookie(),
        Authorization: await BuildBearerAccessTokenAsync(),
    };

    const isHandle = header ? handleWithHeader : handle;

    return axiosClient.post(`${url}`, body, { headers })
        .then(isHandle)
        .catch(handleError);
};

const get = async (url, params, customHeaders) => {
    const headers = {
        mode: 'cors',
        'Content-Type': 'application/json',
        'X-Token': getIdTokenCookie(),
        Authorization: await BuildBearerAccessTokenAsync(),
        ...customHeaders
    };
    const urlObject = new URL(url);
    if (params) {
        urlObject.search = new URLSearchParams(params).toString();
    }

    return axiosClient.get(`${urlObject}`, { headers })
        .then(handle)
        .catch(handleError);
};

const getPdf = async (url) => {
    const headers = {
        mode: 'cors',
        'Content-Type': 'application/pdf',
        'X-Token': getIdTokenCookie(),
        Authorization: await BuildBearerAccessTokenAsync(),
    };

    const urlObject = new URL(url);

    return axiosClient.get(`${urlObject}`, {
        headers,
        responseType: 'blob'
    })
        .then(handle)
        .catch(handleError);
};

const handleWithHeader = async (response) => {
    const resp = {};
    resp.header = response.headers.get('X-Token');
    resp.body = response.data;
    resp.XValue = response.headers.get('X-Value');
    return resp;
};

const handle = (response) => {
    return response.data
}

const handleError = (error) => {
    return new Promise(async (resolve, reject) => {
        let message;
        switch (error.response.status) {
            case 401:
                message = `Se requiere autenticación. Por favor ingrese sus credenciales nuevamente - (status code: ${error.response.status})`;
                await LogoutAsync({ embedShortCircuit: true, redirect: 'login', isVoluntary: false })
                break;
            case 402:
                break;
            case 403:
                message = `No posee los permisos necesarios para acceder al recurso solicitado. Por favor ingrese sus credenciales nuevamente - (status code: ${error.response.status})`;
                break;
            case 404:
                message = `No existe el recurso solicitado. Por favor intentelo nuevamente más tarde - (status code: ${error.response.status})`;
                break;
            case 400:
            case 500:
            case 'parsererror':
            case 'timeout':
            case 'abort':
            case 0:
            default:
                message =
                    'Ocurrió un error inesperado. Por favor intentelo nuevamente más tarde.';
        }
        reject(message);
    });
}
export default { post, get, getPdf };