import React, { useState } from 'react';
import ApiService from '../Services/ApiService';

const usePrePagoPendiente = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [error, setError] = useState(null);

    const outFunc = async (prePago) => {
        ApiService.post(`${REACT_APP_APIPAYWALL}SaveDataV1/GuardarPrePagoPendiente`, prePago).catch((error) => {
            setError(error);
        });
    };

    return [error, outFunc];
};

export default usePrePagoPendiente;
