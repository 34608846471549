import { useState, useEffect } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerSuscripcionesReclamos = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;

    const [isLoading, setIsLoading] = useState(true);
    const [suscripciones, setSuscripciones] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/ObtenerSuscripciones`;
            ApiService.post(pathUrl, {})
                .then(data => {
                    if (data && data.Code === 0) {
                        setSuscripciones(data.Response);
                        setIsLoading(false);
                    } else {
                        setError(
                            `Código de Error:${data.CodeError} - Mensaje: ${data.Message}`
                        );
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [suscripciones, error, isLoading];
};

export default useObtenerSuscripcionesReclamos;
