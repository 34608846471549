import React from 'react';

const InfoInput = ({
    children,
    error,
    success,
}) => {

    return (
        <label
            className={`${error ? ' state--error' : ''}${success ? ' state--success' : ''}`}>
            <span>{children}</span>
        </label>
    );
};

export default InfoInput;
