import React from 'react';
import { useHistory } from 'react-router';

import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import Paragraph from '../../Components/Common/Atoms/Paragraph';
import TitleWeb from '../../Components/Common/Web/Title';
import Wizard from '../../Components/Common/Modules/Wizard';
import Button from '../../Components/Common/Modules/Button';
import Divider from '../../Components/Common/Atoms/Divider';
import steps from '../../Config/StepsMisDomicilios';
import ContactoDudasBanner from '../../Components/Common/Banners/ContactoDudas';
import PathsConfig from '../../Config/RoutePaths';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';

const ConfirmacionDomicilio = () => {
    const history = useHistory();
    const stepProps = [
        'fechaEfectiva',
        'nombreSuscripciones',
        'suscriptionsSelected',
        'code',
        'numGestion',
    ];
    const {
        fechaEfectiva,
        nombreSuscripciones,
        suscriptionsSelected,
        code,
        numGestion,
    } = useRedirectWithout(
        history.location.state,
        stepProps,
        PathsConfig.MisSuscripcionesCambiarDomicilio
    );

    const status = code != 0 ? 'warning' : 'success';

    const handleButton = () => {
        status === 'success'
            ? history.push({
                  pathname: PathsConfig.MisSuscripcionesCambiarDomicilio,
                  state: null,
              })
            : history.push({
                  pathname:
                      PathsConfig.MisSuscripcionesCambiarDomicilioSeleccionar,
                  state: {
                      suscriptionsSelected: suscriptionsSelected,
                  },
              });
    };

    return (
        <>
            <section>
                <Breadcrumb
                    firstItem="Mis suscripciones"
                    href="#"
                    text="Cambiar domicilio de entrega"
                />

                <TitleWeb text="Cambiar domicilio de entrega" />
            </section>

            <section className="container-streched">
                <Wizard steps={steps} activeStep={3} />
                <Toast
                    status={status}
                    title={
                        status === 'success'
                            ? 'Se generó correctamente la suscripción con el cambio de domicilio.'
                            : 'No pudimos procesar tu solicitud.'
                    }
                >
                    {status === 'warning' && suscriptionsSelected && (
                        <>
                            El domicilio seleccionado ya se encuentra como
                            domicilio de entrega de{' '}
                            {suscriptionsSelected.lenght === 1
                                ? `la suscripción`
                                : `las suscripciones`}{' '}
                            {suscriptionsSelected.lenght === 1 ? (
                                <strong>
                                    {suscriptionsSelected[0].nombreSuscripcion}
                                </strong>
                            ) : (
                                suscriptionsSelected.map((sus) => (
                                    <strong>
                                        {sus.nombreSuscripcion}
                                        {sus.idSuscription ===
                                        suscriptionsSelected[
                                            suscriptionsSelected.length - 1
                                        ].idSuscription
                                            ? ''
                                            : ', '}
                                    </strong>
                                ))
                            )}
                            . Para continuar, tenés que seleccionar un domicilio
                            diferente.
                        </>
                    )}
                </Toast>

                {status === 'success' && (
                    <>
                        <Paragraph size="--twoxs" mod="--mb-16">
                            <strong>
                                El número de gestión correspondiente es:{' '}
                                {numGestion}
                            </strong>
                        </Paragraph>
                        <Paragraph size="--twoxs" mod="--mb-8">
                            Se cambió el domicilio de:
                        </Paragraph>
                        {nombreSuscripciones.map((suscripcion) => {
                            return (
                                <Paragraph
                                    key={suscripcion}
                                    size="--twoxs"
                                    mod="--mb-32"
                                >
                                    <strong>- {suscripcion}</strong>
                                </Paragraph>
                            );
                        })}
                        <Paragraph size="--twoxs" mod="--mb-32">
                            Será efectiva a partir del:{' '}
                            <strong>{fechaEfectiva}</strong>
                        </Paragraph>
                    </>
                )}

                <FormRow mod="--btn-groups --mb-32">
                    <Button mod="state--mobile-w100" onClick={handleButton} label="REALIZAR OTRO CAMBIO" />
                </FormRow>

                {status === 'warning' && (
                    <>
                        <Divider />
                        <ContactoDudasBanner />
                    </>
                )}
            </section>
        </>
    );
};

export default ConfirmacionDomicilio;
