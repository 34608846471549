const getProductoPremium = (productoPremiumId) => {
    return productoPremiumId ? productoPremiumId.split(',').includes('2') : false;
}

export const ProductoPremiumId = {
    SinSuscripcion: [""],
    Ad: [2,3],
    AdMasC: [2,3,4,5],
    Duo: [2,3,4,5],
    Triple: [2,3,4,5],
    Black: [1,2,3,4,5]
}

export default getProductoPremium