import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { volver } from '../../../Helpers/Reclamo';
import { useHistory } from 'react-router';
import VendedorReclamaPagoSchema from '../../../Validations/VendedorReclamaPagoSchema';
import FormCol from '../../Common/Atoms/Form/FormCol';
import FormRow from '../../Common/Atoms/Form/FormRow';
import InputBox from '../../Common/Atoms/Form/InputBox';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import Label from '../../Common/Atoms/Form/Label';
import RadioInput from '../../Common/Atoms/Form/RadioInput';
import Paragraph from '../../Common/Atoms/Paragraph';
import InputFormik from '../../Common/Formik/Input';
import Button from '../../Common/Modules/Button';
import CallbackArticle from '../../Common/Modules/CallbackArticle';
import PropTypes from 'prop-types';
import TextAreaFormik from '../../Common/Formik/TextArea';

const propTypes = {
    data: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const VendedorReclamaPagoForm = (props) => {
    const history = useHistory();
    const [usuarioPago, setPago] = useState(true);

    const handleCheckboxClick = () => {
        setPago(!usuarioPago);
    };

    const confirmarReclamo = (values) => {
        var obs = null;
        if (usuarioPago && values.observacion == '') {
            obs = 'Sin observación';
        } else {
            obs = values.observacion;
        }

        const data = {
            IdSuscripcion: props.data.suscripcionSelected,
            IdMotivoDeReclamo: props.data.motivoSelected,
            Despachos: props.data.despachos,
            FechaDesde: props.data.fechaDesde,
            FechaHasta: props.data.fechaHasta,
            AddressId: props.data.address,
            PagoAlCanillita: usuarioPago,
            Distribuidor: usuarioPago ? values.distribuidor : null,
            Linea: usuarioPago ? values.linea : null,
            Vendedor: usuarioPago ? values.vendedor : null,
            Observaciones: obs,
        };

        props.onSubmit(data);
    };

    //console.table({"response": JSON.stringify(response), "error": error, "loading": loading});

    return (
        <Formik
            validationSchema={usuarioPago && VendedorReclamaPagoSchema}
            initialValues={{
                pagoSi: true,
                pagoNo: false,
                linea: '',
                distribuidor: '',
                vendedor: '',
                observacion: '',
            }}
            onSubmit={confirmarReclamo}
        >
            {() => (
                <Form>
                    <FormRow>
                        <Label text="¿Le pagaste al vendedor?" size="--fourxs" />
                        <InputGroup className="grid-50-50 state--no-wrap">
                            <FormCol>
                                <InputBox
                                    aria-label="Sí"
                                    name="pagoSi"
                                    input={
                                        <RadioInput onChange={handleCheckboxClick} checked={usuarioPago}>
                                            Si
                                        </RadioInput>
                                    }
                                />
                            </FormCol>
                            <FormCol>
                                <InputBox
                                    aria-label="No"
                                    name="pagoNo"
                                    input={
                                        <RadioInput onChange={handleCheckboxClick} checked={!usuarioPago}>
                                            No
                                        </RadioInput>
                                    }
                                />
                            </FormCol>
                        </InputGroup>
                    </FormRow>

                    {usuarioPago && (
                        <>
                            <CallbackArticle
                                description={
                                    <Paragraph size="--twoxs" mod="--arial --font-regular">
                                        Ingresá los siguientes datos que podés solicitar al canillita
                                    </Paragraph>
                                }
                            />

                            <FormRow>
                                <InputGroup>
                                    <FormCol>
                                        <Label text="N° de línea" size="--fourxs" />
                                        <InputFormik type="text" name="linea" aria-label="Ingrese número de línea" />
                                    </FormCol>
                                    <FormCol>
                                        <Label text="N° de distribuidor" size="--fourxs" />
                                        <InputFormik type="text" name="distribuidor" aria-label="Ingrese número de distribuidor" />
                                    </FormCol>
                                    <FormCol>
                                        <Label text="N° de vendedor" size="--fourxs" />
                                        <InputFormik type="text" name="vendedor" aria-label="Ingrese número de vendedor" />
                                    </FormCol>
                                </InputGroup>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Label text="Observación" size="--fourxs" />
                                    <TextAreaFormik
                                        type="textArea"
                                        name="observacion"
                                        placeholder="Ingresá cualquier información adicional"
                                        description="Opcional"
                                    />
                                </FormCol>
                            </FormRow>
                        </>
                    )}
                    <FormRow mod="--btn-groups">
                        <Button mod="state--mobile-w100" type={'submit'} tag="input" href={'#'} label="GENERAR RECLAMO" />
                        <Button
                            onClick={() => volver(history, props.data.suscripcionSelected, props.data.motivoSelected)}
                            href={'#'}
                            mod="secondary"
                            label="VOLVER"
                        />
                    </FormRow>
                </Form>
            )}
        </Formik>
    );
};

VendedorReclamaPagoForm.propTypes = propTypes;

export default VendedorReclamaPagoForm;
