import { useEffect } from 'react';
import useObtenerMotivos from './useObtenerMotivosReclamo';

const useObtenerMotivosReclamosOnRender = (IdSuscripcion) => {
    const [motivosReclamo, errorMotivo, isLoadingMotivos, getMotivos] = useObtenerMotivos();

    useEffect(() => {
        getMotivos(IdSuscripcion);
    }, []);

    return [motivosReclamo, errorMotivo, isLoadingMotivos];
}

export default useObtenerMotivosReclamosOnRender;