import React from 'react'
import Toast from "../Components/Common/Modules/Toast";

const buildToast = (type, title, message) => {
    return (
        <Toast status={type} title={title} value={true} children={message} />
    )
}   

export default buildToast;
