import React from 'react';
import PropTypes from 'prop-types';

import '../../../../Statics/Scss/Components/TableHeader.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	mod: PropTypes.string,
};

const TableHeader = ({children, mod, ...r}) => {
	const className = `com-table-header --font-bold${mod ? ` ${mod}` : ''}`;

	return (
		<thead className={className} {...r}>
			{children}
		</thead>
	);
};

TableHeader.propTypes = propTypes;

export default TableHeader;
