import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import steps from '../../../Config/StepsPagosPendientes';
import Wizard from '../../../Components/Common/Modules/Wizard';
import TitleWeb from '../../../Components/Common/Web/Title';
import Link from '../../../Components/Common/Atoms/Link';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Toast from '../../../Components/Common/Modules/Toast';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import Divider from '../../../Components/Common/Atoms/Divider';
import Button from '../../../Components/Common/Modules/Button';
import SuccessPagoRealizado from '../../../Components/Features/PagosPendientes/SuccessPagoRealizado';
import PathsConfig from '../../../Config/RoutePaths';
import useCerrarAbandonoCobranza from '../../../Hooks/useCerrarAbandonoCobranza';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import useObtenerNumeroDeGestionPago from '../../../Hooks/useObtenerNumeroDeGestionPago';
import { trackCustomEvent } from '../../../Helpers/Datadog';
import stepsTrackeoPagosPendientes from '../../../Config/StepsTrackeoPagosPendientes';
import { useDispatch, useStore } from '../../../Store/storeProvider';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import { mailAtencionSocios } from '../../../Config/DatosDeContacto';

const PagosPendientesConfirmacion = (props) => {
    const history = useHistory();
    const stepProps = ['idRechazo', 'idAbandonoCobranza', 'guardarTarjetaChecked'];
    const { idRechazo, idAbandonoCobranza, guardarTarjetaChecked } = useRedirectWithout(props.location.state, stepProps, PathsConfig.PagosPendientes);
    const [dataCierre, errorCierre, isLoadingCierre, cerrarAbandonoCobranza] = useCerrarAbandonoCobranza();
    const [NumeroUnicoDeGestion, error, isLoading] = useObtenerNumeroDeGestionPago(idRechazo);
    const dispatch = useDispatch();
    const { PagoPendienteEfectuado } = useStore();

    useEffect(() => {
        dispatch({
            type: 'ExisteDeuda',
            value: {
                ExisteDeuda: null,
                TotalDeuda: null,
                ErrorApi: null,
            },
        });
        if (idAbandonoCobranza) {
          cerrarAbandonoCobranza(idAbandonoCobranza, guardarTarjetaChecked,
            PagoPendienteEfectuado.Campanita, PagoPendienteEfectuado.Monto);
        }
        trackCustomEvent(stepsTrackeoPagosPendientes["VER-MENSAJE-CONFIRMACION"]);
    }, []);

    const finalizar = () => {
        trackCustomEvent(stepsTrackeoPagosPendientes["CLICK-FINALIZAR"]);
        history.push({
            pathname: PathsConfig.PagosPendientes,
        });
    }

    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Pagos pendientes" />

                <TitleWeb text="Pagos pendientes" />
            </section>

            <section className="container-streched">
                <LoadingSpinner withBody isLoading={isLoadingCierre || isLoading}>
                  <ErrorBoundary error={errorCierre || error}>

                    <Wizard steps={steps} activeStep={3} />

                    <Toast status={idAbandonoCobranza ? 'success' : 'warning'} title={idAbandonoCobranza ? '¡El pago se registró exitosamente!' : 'No pudimos procesar tu pago'}>
                        { !idAbandonoCobranza && (
                            <>
                                Por favor, intentalo de nuevo. Si el error persiste comunicate con nosotros a{' '}
                                <Link href={`mailto: ${mailAtencionSocios}`}>{mailAtencionSocios}</Link>
                            </>
                        )}
                    </Toast>

                    {idAbandonoCobranza && <SuccessPagoRealizado nroGestion={NumeroUnicoDeGestion} />}

                    <FormRow mod="--btn-groups">
                        <Button
                            mod="state--mobile-w100"
                            onClick={finalizar}
                            label="SALDAR OTRO PAGO"
                        />
                    </FormRow>
                    <Divider />
                    <ContactoDudasBanner />
                  </ErrorBoundary>
                </LoadingSpinner>
            </section>
        </>
    );
};

export default PagosPendientesConfirmacion;
