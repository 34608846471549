const stepsTarjeta = {
    step_1: {
        number: 1,
        text: "Motivo de reclamo",
    },
    step_2: {
        number: 2,
        text: "Domicilio de entrega",
    },
    step_3: {
        number: 3,
        text: "Finalizar reclamo"
    }
};

export default stepsTarjeta;