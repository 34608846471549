import React, { useState, useEffect, useRef } from 'react';

import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import EmbedHide from '../../Common/Organism/EmbedHide';

import Logout from '../../../Handlers/_Logout';
import Modal from './Modal';
import ModalWizard from '../../../Pages/ModalWizard';
import getProductoPremium from '../../../Models/ProductoPremium';
import Cookies from 'js-cookie';
import { DrawerAccountContent } from './DrawerAccount/DrawerAccountContent';
import { userConSuscripcion, userSinSuscripcion } from '../../../Helpers/HeaderUserProps';

const Layout = ({ children }) => {
    const [menuVisibility, setMenuVisibility] = useState(false);
    const el = useRef(null);
    const [showModalSteps, setShowModalSteps] = useState(false);
    const [userSuscriptionProps, setUserSuscriptionProps] = useState(userSinSuscripcion);
    const [userIniciales, setUserIniciales] = useState('');
    const usuarioEmail = Cookies.get('usuarioemail');
    const usuarioNombre = Cookies.get('usuario_detalle_nombre');
    const usuarioApellido = Cookies.get('usuario_detalle_apellido');
    const [visibleSubscriberAttentions, setVisibleSubscriberAttentions] = useState(false);
    const toggleVisible = () => setVisibleSubscriberAttentions(!visibleSubscriberAttentions);

    const handleCloseModal = () => {
        setShowModalSteps(false);
    };

    const closeMenu = () => {
        setTimeout(() => setMenuVisibility(!menuVisibility), 700);
        document.body.classList.remove('menu-open');
    };

    const openMenu = () => {
        setMenuVisibility(!menuVisibility);
        document.body.classList.add('menu-open');
    };

    const handleMenuVisibilityChange = () => {
        if (menuVisibility) closeMenu();
        else openMenu();
    };

    const addBackDrop = (e) => {
        if (menuVisibility && el && !el.current.contains(e.target)) closeMenu();
    };

    useEffect(() => {
        if (getProductoPremium(Cookies.get('ProductoPremiumId'))) {
            setUserSuscriptionProps(userConSuscripcion);
        }
        if (usuarioNombre && usuarioApellido) {
            setUserIniciales((usuarioNombre[0] + usuarioApellido[0]).toUpperCase());
        } else {
            if (usuarioEmail) {
                setUserIniciales(usuarioEmail.slice(0, 2).toUpperCase());
            }
        }
        window.addEventListener('click', addBackDrop);
        return () => {
            window.removeEventListener('click', addBackDrop);
        };
    }, []);

    const handleLogout = () => {
        Logout.Logout(true);
    };

    const hideDrawerMenu = location.pathname !== '/';

    return (
        <div>
            <EmbedHide>
                <Header
                    setMenuVisibility={handleMenuVisibilityChange}
                    userSuscriptionProps={userSuscriptionProps}
                    setVisibleSubscriberAttentions={setVisibleSubscriberAttentions}
                    userIniciales={userIniciales}
                />
            </EmbedHide>

            <div className="layout container">
                <div className='grid grid-cols-8 grid-cols-12_md grid-cols-16_xl grid-row-gap'>
                    {hideDrawerMenu && (
                        <div className="layout__leftSide xl-only" ref={el}>
                            <EmbedHide>
                                <Nav menuVisibility={menuVisibility} closeMenu={closeMenu} />
                            </EmbedHide>
                        </div>
                    )}
                    <div className="layout__rightSide">{children}</div>
                </div>
                <DrawerAccountContent
                    handleLogout={handleLogout}
                    setShowModalSteps={setShowModalSteps}
                    userSuscriptionProps={userSuscriptionProps}
                    visibleSubscriberAttentions={visibleSubscriberAttentions}
                    toggleVisible={toggleVisible}
                    userIniciales={userIniciales}
                />
            </div>

            <EmbedHide>
                <Footer />
            </EmbedHide>

            {showModalSteps && (
                <Modal closeModal={handleCloseModal} classes="--none">
                    <ModalWizard close={handleCloseModal} />
                </Modal>
            )}
        </div>
    );
};

export default Layout;
