import React from 'react'
import Toast from './../Modules/Toast';
import { telefono } from '../../../Config/DatosDeContacto';

export const ToastBloqueoCredenciales = ({ title, subtitle, estado }) => {
    return (
        <Toast status={estado} title={title}>
            <strong>
                {subtitle}
            </strong>
            <br />
            Para poder regularizar la situación, por favor, necesitamos que te comuniques al: {telefono}
        </Toast>
    )
}
