import React from 'react';
import SumarBeneficios from '../../Common/Banners/SumarBeneficios';
import Toast from '../../Common/Modules/Toast';

function CallCenter() {
    return (
        <Toast target="_blank" status="info">
            <SumarBeneficios />
        </Toast>
    );
}

export default CallCenter;
