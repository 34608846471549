import React from 'react'
import { useHistory } from 'react-router-dom'
import PathsConfig from '../../../Config/RoutePaths'
import { Text } from '@ln/common-ui-text'
import { Button } from '@ln/experiencias-ui-button'
import { Groupbutton } from '@ln/experiencias-ui-groupbutton'
import { Alert } from '../../Common/Modules/Alert'

export const SinSuscripciones = () => {
  const history = useHistory();
  const handleActivarSuscripcion = () => {
    history.push({
      pathname: PathsConfig.ActivarSuscripcion
    })
  }
  return (
    <div className='flex flex-column gap-32 mb-32 arial'>
      <Alert
        variant='info'
        title='No tenés suscripciones'
        message='Actualmente no contás con suscripciones activas'
      />
      <div className='flex flex-column gap-16'>
        <Text as='h5' className='arial-bold text-18'>
          Activar suscripción
        </Text>
        <Text as='p' className='text-14'>
          Si adquiriste una suscripción digital por teléfono o en un stand, y tenés tu pin de activación, hacé clic en el botón y comenzá a disfrutar de tu suscripción.
        </Text>
        <Groupbutton>
          <Button title="ACTIVAR SUSCRIPCIÓN" onClick={handleActivarSuscripcion}>ACTIVAR SUSCRIPCIÓN</Button>
        </Groupbutton>
      </div>
    </div>
  )
}
