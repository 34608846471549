const cols = [
    {
        field: 'nombre',
        name: 'Nombre',
    },
    {
        field: 'fecha',
        name: 'Fecha',
    },
];

export default cols;