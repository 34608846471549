import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";
import { createCookie } from '../Helpers/Cookies';

const useGuardarRetencion = (idEstrategia, idHerramienta, idSuscripcion) => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [saveRetentionResult, setSaveRetentionResult] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIPAYWALL}Retencion/SaveRetention`, { idEstrategia, idHerramienta, idSuscripcion })
                .then((data) => {
                    if (data?.code === '0000') {
                        setSaveRetentionResult(data.response);
                        createCookie('retencionSavedLastDay', true, 1);
                    } else {
                        setError(true);
                        Datadog.trackCustomEventWarning( CustomEventsErrorDatadog.errorSolicitudRetencion, { response: data })
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error);
                    Datadog.trackCustomEventError( CustomEventsErrorDatadog.errorSolicitudRetencion, { error })
                })
        };
        fetchData();
    }, []);
    return [saveRetentionResult, error, isLoading];
};

export default useGuardarRetencion;
