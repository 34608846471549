const steps = {
    step_1: {
        number: 1,
        text: "Motivo de reclamo",
    },
    step_2: {
        number: 2,
        text: "Ediciones a reclamar",
    },
    step_3: {
        number: 3,
        text: "Finalizar reclamo",
    },
    step_4: {
        number: 4,
        text: "Confirmación",
    },
};

export default steps;