import React from 'react';

import '../../../../Statics/Scss/Components/FormRow.scss'

const  FormRow = ({children, align, mod}) => {
    return ( 
        <div className={`com-row ${align ? 'state--'+align : ''}${mod ? ` ${mod}` : ''}`}>
            {children}
        </div>
     );
}
 
export default  FormRow;