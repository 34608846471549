const cols = [
  {
    field: "id",
    name: "#",
  },
  {
    field: "fecha",
    name: "Fecha",
  },
  {
    field: "evento",
    name: "Evento",
  },
  {
    field: "origen",
    name: "Origen",
  },
  {
    field: "buttonProps",
    name: "",
  },
];

export default cols;
