import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import FormRow from '../../Common/Atoms/Form/FormRow';
import FormCol from '../../Common/Atoms/Form/FormCol';
import InputBox from '../../Common/Atoms/Form/InputBox';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import Label from '../../Common/Atoms/Form/Label';
import Button from '../../Common/Modules/Button';
import InputFormik from '../../Common/Formik/Input';
import SelectFormik from '../../Common/Formik/Select';
import RadioFormik from '../../Common/Formik/Radio';
import FormDatosPersonalesSchema from '../../../Validations/FormDatosPersonalesSchema';
import DateHelper from '../../../Helpers/Date';
import DatepickerFormik from '../../Common/Formik/Datepicker';
import Toast from '../../../Components/Common/Modules/Toast';

const propTypes = {
    cliente: PropTypes.shape({
        nombre: PropTypes.string,
        apellido: PropTypes.string,
        tipoDocumento: PropTypes.string,
        numeroDocumento: PropTypes.string,
        fechaCumpleanios: PropTypes.string,
        celular: PropTypes.string,
        particular: PropTypes.string,
        Sexo: PropTypes.string,
    }),
};

const FormularioDatosPersonales = (props) => {
    const { cliente, emailPrincipal } = props;
    const [generoSelected, setGeneroSelected] = useState(cliente.Sexo);
    const [errorForm, setErrorForm] = useState(null);
    //const [telefonoCelular, setTelefonoCelular] = useState(PhoneHelper.PhoneNumberFormat(cliente.TelCelular));
    //const [telefonoParticular, setTelefonoParticular] = useState(PhoneHelper.PhoneNumberFormat(cliente.TelParticular));
    const [fechaDesde, setFechaDesde] = useState(DateHelper.correctTimeZone(cliente.FechaCumpleaños));
    const initialValuesFormik = {
        nombre: cliente.Nombre,
        apellido: cliente.Apellido,
        tipoDocumento: cliente.TipoDocumento,
        numeroDocumento: cliente.NumDocumento,
        fechaCumpleanios: fechaDesde,
        celular: cliente.TelCelular,
        particular: cliente.TelParticular,
        Sexo: cliente.Sexo,
        email: cliente.EMail ? cliente.EMail : '',
    };

    const handleOnChange = (event) => {
        const genero = event.target.value;
        setGeneroSelected(genero);
    };

    /*const handleTelefono = (event) => {
    const telefonoFormateado = PhoneHelper.PhoneNumberFormat(event.target.value);
    setTelefonoCelular(telefonoFormateado);
  };

  const handleTelefonoParticular = (event) => {
    const telefonoFormateado = PhoneHelper.PhoneNumberFormat(event.target.value);
    setTelefonoParticular(telefonoFormateado);
  };*/

    return (
        <Formik
            enableReinitialize
            validationSchema={FormDatosPersonalesSchema}
            initialValues={initialValuesFormik}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);

                try {
                    const fechaFormateada = DateHelper.convertDate_ddMMyyyy(fechaDesde);
                    const generoFormateado = generoSelected == 'M' ? 'Masculino' : 'Femenino';
                    let payload = {
                        ...values,
                        Sexo: generoFormateado,
                        fechaCumpleanios: fechaFormateada,
                        email: values.email === '' ? emailPrincipal : values.email,
                    };

                    await props.onSave(payload);
                } catch (error) {
                    setErrorForm(error.message);
                }

                setSubmitting(false);
            }}
        >
            {(props) => (
                <Form noValidate>
                    {errorForm && <Toast positionFloat="center" closeAuto={3000} status="warning" title={errorForm} />}
                    <FormRow>
                        <InputGroup className="grid-50-50">
                            <FormCol>
                                <Label text={'Nombre'} />
                                <InputFormik
                                    type="text"
                                    name="nombre"
                                    state={{ status: '', msg: '' }}
                                    disabled={true}
                                    aria-label="Ingrese su nombre."
                                />
                            </FormCol>
                            <FormCol>
                                <Label text={'Apellido'} />
                                <InputFormik
                                    type="text"
                                    name="apellido"
                                    state={{ status: '', msg: '' }}
                                    disabled={true}
                                    aria-label="Ingrese su apellido"
                                />
                            </FormCol>
                        </InputGroup>
                    </FormRow>

                    <FormRow>
                        <InputGroup className="grid-25-75">
                            <FormCol>
                                <Label text={'Tipo'} />
                                <SelectFormik
                                    name="tipoDocumento"
                                    state={{ status: '', msg: '' }}
                                    disabled={true}
                                    aria-label="Seleccione su tipo de documento"
                                >
                                    <option value="DNI">D.N.I.</option>
                                    <option value="PAS">PAS.</option>
                                    <option value="LC">L.C.</option>
                                    <option value="LE">L.E.</option>
                                    <option value="CI">C.I.</option>
                                </SelectFormik>
                            </FormCol>
                            <FormCol>
                                <Label text={'Número de documento'} />
                                <InputFormik
                                    type="text"
                                    name="numeroDocumento"
                                    state={{ status: '', msg: '' }}
                                    disabled={true}
                                    aria-label="Ingrese número de documento"
                                />
                            </FormCol>
                        </InputGroup>
                    </FormRow>

                    <FormRow>
                        <InputGroup>
                            <FormCol>
                                <Label text={'Fecha de nacimiento'} />
                                <DatepickerFormik
                                    isInvalid={fechaDesde === null}
                                    hideCalendar={true}
                                    name="fechaCumpleanios"
                                    selected={fechaDesde}
                                    onChange={(event) => setFechaDesde(event)}
                                    startDate={fechaDesde}
                                    preventOpenOnFocus={true}
                                    placeholder="DD/MM/AAAA"
                                    isTabInsideEnabled={false}
                                    aria-label="Ingrese su fecha de nacimiento"
                                />
                            </FormCol>
                        </InputGroup>
                    </FormRow>

                    <FormRow>
                        <Label text={'Género'} />
                        <InputGroup className="grid-50-50 state--no-wrap">
                            <FormCol>
                                <InputBox
                                    input={
                                        <RadioFormik
                                            onChange={handleOnChange}
                                            value={'M'}
                                            name="Sexo"
                                            checked={generoSelected === 'M'}
                                            aria-label="Masculino"
                                        >
                                            Masculino
                                        </RadioFormik>
                                    }
                                />
                            </FormCol>
                            <FormCol>
                                <InputBox
                                    input={
                                        <RadioFormik
                                            onChange={handleOnChange}
                                            value={'F'}
                                            name="Sexo"
                                            checked={generoSelected === 'F'}
                                            aria-label="Femenino"
                                        >
                                            Femenino
                                        </RadioFormik>
                                    }
                                />
                            </FormCol>
                        </InputGroup>
                    </FormRow>

                    <FormRow>
                        <InputGroup className="grid-50-50">
                            <FormCol>
                                <Label text={'Teléfono celular'} />
                                <InputFormik type="text" name="celular" aria-label="Ingrese su número de teléfono celular" />
                            </FormCol>
                            <FormCol>
                                <Label text={'Teléfono particular'} />
                                <InputFormik type="text" name="particular" aria-label="Ingrese su número de teléfono particular" />
                            </FormCol>
                        </InputGroup>
                    </FormRow>
                    
                    <FormRow>
                        <FormCol>
                            <Label text={'Email secundario'} />
                            <InputFormik name="email" type="email" aria-label="Ingrese su email secundario" />
                        </FormCol>
                    </FormRow>

                    <FormRow mod="--btn-groups">
                        <Button type="submit" tag="input" mod="state--mobile-w100" label="GUARDAR CAMBIOS" />
                    </FormRow>
                </Form>
            )}
        </Formik>
    );
};

FormularioDatosPersonales.propTypes = propTypes;

export default FormularioDatosPersonales;
