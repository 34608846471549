import React from 'react';

import Text from '../../../Components/Common/Text/Text';
import Week from '../../../components/Common/week/Week';
import LinkNew from '../../../Components/Common/Link/Link';
import Button from '../../Common/Modules/Button';
import { SuperCardBox, HeaderSuperCardBox, BodySuperCardBox, FooterSuperCardBox } from '../../../Components/Common/SuperCardBox';
import { generateClassHeader, isShownDomicilioByState } from '../../../Components/Features/MisSuscripciones/SuscriptionCard';
import {
    getName,
    getBajada,
    suscriptionHasDays,
    findPlanForSuscription,
    isPinNYT,
    isSuspendida,
    getFecha,
    isStatus,
} from '../../../../src/Filter/suscripciones';
import { dateShort, daysArrayCreator } from '../../../Helpers/Dates';
import { titulosEstados } from '../../../Config/Suscripciones';
import PathsConfig from '../../../Config/RoutePaths';
import Embed from '../../../Models/Embed';
import useResponsive from '../../../Hooks/useResponsive';

const ContainerCardBox = (props) => {
    const { index, suscription, suscriptionLinks, planes, dataPin, evento } = props;
    const min1024 = useResponsive('(min-width:1024px)');

    return (
        <SuperCardBox key={suscription.Id} bgState={suscription.TipoDeVenta !== 'Venta Individuo' ? 'gray' : ''}>
            <HeaderSuperCardBox className={generateClassHeader(suscription.Estado)} listDrop={suscriptionLinks[index]} data={suscription.Id}>
                <Text tag="p" size="5xs" font="arial" weight="bold">
                    {titulosEstados[suscription.Estado]}
                </Text>

                <Text tag="h5" size="--twoxs" font="arial" weight="bold">
                    {getName(suscription, planes)} {suscription.NombreRegimenDeFacturacion == 'Anual' && '(Plan Anual)'}
                </Text>
            </HeaderSuperCardBox>

            <BodySuperCardBox>
                <Text tag="p" size="4xs" font="arial" className="--paragraph">
                    {getBajada(suscription, planes, evento)}
                </Text>

                {suscription.NombreRegimenDeFacturacion == 'Anual' && suscription.FechaUlitmoCobroSuscripcionDefinida && (
                    <Text tag="p" size="4xs" font="arial" className="--paragraph">
                        Fecha de finalización: {new Date(suscription.FechaUlitmoCobroSuscripcionDefinida).toLocaleString().split(' ')[0]}
                    </Text>
                )}
                {suscription.NombreRegimenDeFacturacion == 'Anual' &&
                    !suscription.FechaUlitmoCobroSuscripcionDefinida &&
                    suscription.FechaSiguienteFacturacion && (
                        <Text tag="p" size="4xs" font="arial" className="--paragraph">
                            Fecha de renovación: {new Date(suscription.FechaSiguienteFacturacion).toLocaleString().split(' ')[0]}
                        </Text>
                    )}

                {suscriptionHasDays(suscription) && <Week title="DÍAS DE ENTREGA" days={daysArrayCreator(suscription.DiasSemanaDiario)} />}
                {!findPlanForSuscription(suscription, planes) && isShownDomicilioByState(suscription.Estado) && (
                    <>
                        <h5 className="com-week__title --sixxs --mb-8 --mt-16">DOMICILIO DE ENTREGA</h5>
                        <Text tag="p" size="4xs" font="arial" className="--paragraph">
                            <span className="--mr-16">
                                {`
                                ${suscription.Calle ? suscription.Calle : ''} 
                                ${suscription.Altura ? suscription.Altura : ''}
                                ${suscription.Piso ? ` - Piso ${suscription.Piso}` : ''}
                                ${suscription.Dpto ? `, Depto ${suscription.Dpto}` : ''} 
                                ${suscription.CodigoPostal ? ` - ${suscription.CodigoPostal}` : ''} 
                                ${suscription.Localidad ? ` - ${suscription.Localidad}` : ''}
                                `}
                            </span>
                            {isStatus(suscription, 'ACTIVA') &&
                                !(suscription.FechaDesde && suscription.FechaHasta) &&
                                !suscription.SituacionDomicilio && (
                                    <LinkNew className={'--fourxs --mr-16'} href={PathsConfig.MisSuscripcionesCambiarDomicilio}>
                                        Modificar
                                    </LinkNew>
                                )}
                        </Text>

                        {isStatus(suscription, 'ACTIVA') && !(suscription.FechaDesde && suscription.FechaHasta) && suscription.SituacionDomicilio && (
                            <Text tag="p" size="4xs" font="arial" className="--paragraph --mt-16" color="#EF9B30" weight="bold">
                                Estamos comprobando tu domicilio con nuestro sistema de envíos. Este proceso toma unos días, no vas a poder volver a
                                cambiar tu domicilio hasta que esté comprobado.
                            </Text>
                        )}
                    </>
                )}
                {isPinNYT(suscription, planes) && (
                    <>
                        <h5 className="com-week__title --sixxs --mt-16">CÓDIGO DE ACCESO</h5>
                        <Text tag="p" size="4xs" font="arial" className="--paragraph --mb-16">
                            <strong>{dataPin && dataPin.pin.toUpperCase()}</strong>
                        </Text>
                        {dataPin && !Embed && <Button tag="a" href={`${dataPin.url}${dataPin.pin}`} target="_blank" label="ACTIVAR SUSCRIPCIÓN" />}
                    </>
                )}
                {isSuspendida(suscription) && (
                    <Text tag="p" size="4xs" font="arial" className="--paragraph --mt-16" color="#272727" weight="bold">
                        Suspendida por vacaciones desde {dateShort(getFecha(suscription, 'FechaDesde'))} al{' '}
                        {dateShort(getFecha(suscription, 'FechaHasta'))}.
                    </Text>
                )}
            </BodySuperCardBox>

            {min1024 && suscriptionLinks[index] && (
                <FooterSuperCardBox>
                    {suscriptionLinks[index] &&
                        suscriptionLinks[index].slice(0, 4).map((list, key) => {
                            return (
                                <LinkNew key={key} className={'--fourxs --mr-16'} onClick={list.onClick}>
                                    {list.name}
                                </LinkNew>
                            );
                        })}
                </FooterSuperCardBox>
            )}
        </SuperCardBox>
    );
};

export default ContainerCardBox;
