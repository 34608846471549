import { useEffect } from "react";
import useObtenerDatosCRM from "./useObtenerDatosCRM";

const useObtenerDatosClienteOnLoad = () => {
    const [cliente, errorCRM, isLoadingCRM, getDataCrm] = useObtenerDatosCRM();

    useEffect(() => {
        getDataCrm();
    }, []);

    return [
        cliente,
        errorCRM,
        isLoadingCRM
    ]

}

export default useObtenerDatosClienteOnLoad;