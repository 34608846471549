import React from 'react';
import EmbedHide from '../Organism/EmbedHide';
import CallbackArticle from '../Modules/CallbackArticle';
import Paragraph from '../Atoms/Paragraph';
import Button from '../Modules/Button';
import Link from '../Atoms/Link';
import getProductoPremium from '../../../Models/ProductoPremium';
import Cookies from 'js-cookie';
import FormRow from '../Atoms/Form/FormRow';

const IncentivarCambiarBlackBanner = () => {
    const EnvMiCuenta = process.env.REACT_APP_MYACCOUNT;
    return (
        <EmbedHide>
            <CallbackArticle
                description={
                    <>
                        <Paragraph size="--twoxs" mod="--arial --font-regular">
                            Potenciá tu suscripción con más beneficios cambiandoté a <strong>Black</strong>.
                            <Link target="_blank" href="https://club.lanacion.com.ar/beneficios-exclusivo-black">
                                {' '}
                                Más información
                            </Link>
                            .
                        </Paragraph>
                        <FormRow mod="--btn-groups">
                            <Button
                                tag="a"
                                className="--mb-0"
                                mod="primary"
                                href={
                                    getProductoPremium(Cookies.get('ProductoPremiumId'))
                                        ? `${EnvMiCuenta}confirmar-upselling/up-selling/pasate-a-black`
                                        : 'https://www.contacto.lanacion.com.ar/org-suscribirse-clubln-black'
                                }
                                label="Cambiar a Black"
                                target="_blank"
                            />
                        </FormRow>
                    </>
                }
            />
        </EmbedHide>
    );
};

export default IncentivarCambiarBlackBanner;
