import React from 'react';
import "./style.scss";

const BodySuperCardBox = ({children}) => {
    return (
        <div className="com-SuperCardBox__body">
          {children}  
        </div>
    );
}
 
export {BodySuperCardBox};