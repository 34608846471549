import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { volver } from '../../../Helpers/Reclamo';
import FormRow from '../../Common/Atoms/Form/FormRow';
import Label from '../../Common/Atoms/Form/Label';
import FormCol from '../../Common/Atoms/Form/FormCol';
import InputBox from '../../Common/Atoms/Form/InputBox';
import Button from '../../Common/Modules/Button';
import CheckInput from '../../Common/Atoms/Form/CheckInput';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import PathsConfig from '../../../Config/RoutePaths';

const ProductoNoSolicitadoForm = (props) => {
    const history = useHistory();
    const stepProps = ['suscripcionSelected', 'motivoSelected', 'despachos', 'address', 'fechaDesde', 'fechaHasta', 'detalleSuscripcion'];
    const { suscripcionSelected, motivoSelected, despachos, address, fechaDesde, fechaHasta, detalleSuscripcion } = useRedirectWithout(
        props.data,
        stepProps,
        PathsConfig.ReclamoEntrega
    );
    const { Productos, IdCampaniaPlanDeVenta, CantidadTotalDeProductos } = detalleSuscripcion;
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [otherOptions, setOtherOptions] = useState([]);
    const [formInvalid, setFormInvalid] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    const handleCheckboxClick = (event) => {
        const val = event.target.value;
        const checked = event.target.checked;
        if (isNaN(val)) {
            checked ? setOtherOptions([...otherOptions, val]) : setOtherOptions(otherOptions.filter((i) => i != val));
        } else {
            checked ? setSelectedProducts([...selectedProducts, val]) : setSelectedProducts(selectedProducts.filter((i) => i != val));
        }
    };

    const confirmarReclamo = () => {
        if (otherOptions.includes('noEncontrado')) {
            const state = { success: false };
            const params = { pathname: PathsConfig.ReclamoEntregaContacto, state: state };
            history.push(params);
        } else if (selectionIsValid()) {
            const data = {
                IdSuscripcion: suscripcionSelected,
                IdMotivoDeReclamo: motivoSelected,
                Despachos: despachos,
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta,
                AddressId: address,
                IdsProductoEnPlan: selectedProducts,
                IdCampaniaPlanDeVenta: IdCampaniaPlanDeVenta,
                PermiteFeriados: detalleSuscripcion.DespacharFeriados && otherOptions.includes('feriados'),
                PermiteEdicionesExtraordinarias: detalleSuscripcion.PermiteEnvioEdicionesExtraordinarias && otherOptions.includes('extraordinarias'),
            };

            props.onSubmit(data);
        } else {
            setFormInvalid(true);
        }
    };

    const selectionIsValid = () => {
        return selectedProducts.length > 0 || otherOptions.includes('noEncontrado');
    };

    const renderInputs = (item, key) => {
        var display = true;
        const idProductoDeSuscripcion = item.Id;
        const opcionDeVenta = item.OpcionDeVenta.Id;
        const cantidadDeEjemplares = item.ItemDeVenta.CantidadEjemplares;
        var disabled = false;
        if (firstTime) {
            switch (opcionDeVenta) {
                case 1:
                    disabled = true;
                    setSelectedProducts([...selectedProducts, idProductoDeSuscripcion.toString()]);
                    break;
                case 2:
                    if (detalleSuscripcion?.TipoFamilia == 'R') {
                        display = false;
                    }
                    break;
                case 3:
                    if (cantidadDeEjemplares != 0) {
                        setSelectedProducts([...selectedProducts, idProductoDeSuscripcion.toString()]);
                    } else if (detalleSuscripcion?.TipoFamilia == 'R') {
                        display = false;
                    }
                    break;
            }
            setFirstTime(false);
        }

        const checked = selectedProducts.includes(idProductoDeSuscripcion.toString());

        return (
            display && (
                <InputBox
                    input={
                        <CheckInput
                            key={key}
                            onChange={handleCheckboxClick}
                            checked={checked}
                            disabled={
                                otherOptions.includes('noEncontrado')
                                    ? true
                                    : selectedProducts.length == CantidadTotalDeProductos && !checked
                                    ? true
                                    : disabled
                            }
                            value={idProductoDeSuscripcion}
                        >
                            {item.Producto.Nombre}
                        </CheckInput>
                    }
                />
            )
        );
    };

    return (
        <form>
            <Label text="Listado de Productos" />
            <FormRow mod="com-input-list">
                {Productos.map((item, key) => renderInputs(item, key))}
                {detalleSuscripcion.DespacharFeriados && (
                    <InputBox
                        input={
                            <CheckInput
                                checked={otherOptions.includes('feriados')}
                                onChange={handleCheckboxClick}
                                value={'feriados'}
                                aria-label="Feriados"
                                disabled={otherOptions.includes('noEncontrado')}
                            >
                                Despachos FERIADOS
                            </CheckInput>
                        }
                    />
                )}
                {detalleSuscripcion.PermiteEnvioEdicionesExtraordinarias && (
                    <InputBox
                        input={
                            <CheckInput
                                checked={otherOptions.includes('extraordinarias')}
                                onChange={handleCheckboxClick}
                                value={'extraordinarias'}
                                aria-label="Extraordinarias"
                                disabled={otherOptions.includes('noEncontrado')}
                            >
                                Ediciones extraordinarias
                            </CheckInput>
                        }
                    />
                )}
                <InputBox
                    mod="--full-width"
                    input={
                        <CheckInput
                            checked={otherOptions.includes('noEncontrado')}
                            onChange={handleCheckboxClick}
                            aria-label="No encontrado"
                            value={'noEncontrado'}
                        >
                            No encuentro el producto en la lista
                        </CheckInput>
                    }
                />
                {formInvalid && <span className="--red-500">Debe seleccionar al menos una opción de la lista</span>}
            </FormRow>
            <FormRow mod="--btn-groups">
                <Button className="state--mobile-w100" href={'#'} onClick={() => confirmarReclamo()} label="GENERAR RECLAMO" />
                <Button onClick={() => volver(history, suscripcionSelected, motivoSelected)} href={'#'} mod="secondary" label="VOLVER" />
            </FormRow>
        </form>
    );
};

export default ProductoNoSolicitadoForm;
