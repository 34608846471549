import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Button from '../../../Components/Common/Modules/Button';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import steps from '../../../Config/StepUpselling';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Toast from '../../../Components/Common/Modules/Toast';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';

import PathsConfig from '../../../Config/RoutePaths';
import Text from '../../../Components/Common/Text/Text';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';

export const UpsellingAdClubConfirmacion = () => {
    const stepProps = ["numeroCredencial","tipoCredencial","domicilio","fechaEstimada","nombre",];
    const history = useHistory();
    const { state } = useLocation();
    const { numeroCredencial, tipoCredencial, domicilio, fechaEstimada, nombre } = useRedirectWithout(state, stepProps, PathsConfig.MisSuscripciones);
    const [error, setError] = useState(false)
    const credencialNro = numeroCredencial;
    const credencialTipo = tipoCredencial;
    const nombreUser = nombre;
    const domicilioUser = domicilio;
    const diaEstimadoEntrega = fechaEstimada;

    const handleIrMisCredenciales = ()=>{
        history.push({
            pathname: `${PathsConfig.MisCredenciales}`
        })};
    
    return (
        <>
            <section className="lay-strechted">
                <Breadcrumb firstItem="Mis suscripciones" href="#" text="Cambiar a Acceso Digital + Club" />
                <TitleWeb text="Cambiar a Acceso Digital + Club" />
            </section>
            <section className="container-streched">
                    {error && (
                        <Toast closeAuto={3000} status="warning" value={error} changeValue={setError} title="¡Uppps!">
                            No pudimos procesar tu solicitud. Por favor, intentalo de nuevo. Si el error persiste comunicate con nosotros a
                            atencionsocios@lanacion.com.ar
                        </Toast>
                    )}
                    <Wizard steps={steps} activeStep={3} />
                    <Toast value={true} status="success" title="¡Listo! ¡Suscripción actualizada exitosamente!"></Toast>
                    <Text tag="h2" size="--s" font="arial" weight="bold" className="--mb-8">Bienvenido a Acceso Digital + Club La Nacion</Text>
                    <ul className='--mb-24'>
                        <li><Text tag="p" size="--twoxs" font="arial">La credencial <b>{`${credencialNro} ${credencialTipo}`}</b></Text></li>
                        {nombreUser && <li><Text capital={true} tag="p" size="--twoxs" font="arial">Se otorgo a <b>{`${nombreUser}`}</b></Text></li>}
                        <li><Text tag="p" size="--twoxs" font="arial">Se entregara en <b>{`${domicilioUser}`}</b></Text></li>
                        <li><Text tag="p" size="--twoxs" font="arial">Con fecha estimada <b>{`${diaEstimadoEntrega}`}</b>.</Text></li>
                    </ul>
                    <FormRow mod="--btn-groups --mb-32">
                        <Button type="submit" mod="state--mobile-w100" label="Ir a mis credenciales" onClick={handleIrMisCredenciales} />
                        <Button tag="a" mod="tertiary" label="ir a Club La nacion" href={'https://miclub.lanacion.com.ar/'} target={'blank'} />
                    </FormRow>
            </section>
        </>
    );
};
