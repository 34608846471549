import React, { useState } from 'react';
import ApiService from '../Services/ApiService';
import buildToast from '../Helpers/BuildToast';

const useNormalizarDomicilio = () => {
    let { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [errorNormalizar, setErrorNormalizar] = useState(null);  

    const sendNormalizar = async (data) => {
        const pathUrl = `${REACT_APP_APIPAYWALL}Domicilio/Normalizar`;
        setErrorNormalizar(null);
        setIsLoading(true);
        return ApiService.post(pathUrl, data)
            .then(response => {
                if (response.code == "0000") {
                    setData(response.response);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setErrorNormalizar(buildToast('alert','Error','No pudimos validar el domicilio que ingresaste. Por favor, completá los siguientes datos para generar la solicitud.'));
                }
                return Promise.resolve(response);
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorNormalizar(buildToast('alert','Error','Hubo un problema con tu solicitud. Por favor, inténtalo nuevamente más tarde.'));
                return Promise.reject(error);
            });
    };

    return [data, errorNormalizar, isLoading, sendNormalizar];

};


export default useNormalizarDomicilio;

