import React from 'react';
import PropTypes from 'prop-types';
import StringHelper from '../../../Helpers/String';
import { Text } from '@ln/common-ui-text';
import { Image } from '@ln/experiencias-ui-image';
import tarjetaVisa from '../../../Statics/Imgs/visa.png';
import tarjetaMastercard from '../../../Statics/Imgs/mastercard.png';
import tarjetaAmex from '../../../Statics/Imgs/amex.png';
import tarjetaOtra from '../../../Statics/Imgs/otra.png';

function TarjetaInfo(props) {
    let suscripcion = props.tarjeta?.split('-');
    
    const getTarjeta = (tarjeta) => {
        if (tarjeta.indexOf('VISA') > -1) return tarjetaVisa
        if (tarjeta.indexOf('MASTERCARD') > -1) return tarjetaMastercard
        if (tarjeta.indexOf('AMERICAN EXPRESS') > -1) return tarjetaAmex
        return tarjetaOtra
    }

    if (suscripcion?.length >= 5) {
        let banco = '';
        let tarjeta = suscripcion[0].trim();
        let ultimos4 = suscripcion[4].trim();

        const urlTarjeta = getTarjeta(tarjeta)

        if (suscripcion.length == 6) {
            banco = StringHelper.Capitalize(suscripcion[5].trim());
        }

        return (
            <div className='flex flex-column gap-8 arial-bold text-16 min-w-max mt-24'>
                <Text>El pago se debitar&aacute; de:</Text>
                <div className='flex ai-center gap-8'>
                    <Image src={urlTarjeta} height={23}/>
                    <Text>****{ultimos4}</Text>
                    <Text>{banco}</Text>
                </div>
            </div>
        );
    }
    return null;
}

TarjetaInfo.propTypes = {
    tarjeta: PropTypes.string,
};

export default TarjetaInfo;
