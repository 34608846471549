import React from "react";
import PropTypes from "prop-types";
import "../../../Statics/Scss/Components/Divider.scss";

const Divider = ({ color, width }) => (
  <hr
    className="com-divider"
    style={{ backgroundColor: color, width: width + "%" }}
  />
);

Divider.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
};

Divider.defaultProps = {
  color: '#CCCCCC',
  width: '100',
}

export default Divider;
