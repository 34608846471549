import { useState } from 'react';
import { useHistory } from 'react-router';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import ApiService from '../Services/ApiService';
import PathsConfig from '../Config/RoutePaths';
import Datadog from "../Helpers/Datadog";
import SessionHandler from '../../src/Handlers/Session';
import { useDispatch } from '../Store/storeProvider';

const useGenerarReclamoAccesoDigital = () => {
    const history = useHistory();
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorReclamo, setErrorReclamo] = useState();
    const dispatch = useDispatch();

    const outFunc = async (data, motivoReclamo, nombreSuscripcion) => {
        const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/CrearReclamoDeAccesoDigital`;
        setIsLoading(true);

        ApiService.post(pathUrl, data)
            .then(response => {
                if (response.Code === 0) {
                    Datadog.trackCustomEvent(CustomEventsDatadog.reclamarAccesoDigital, { motivoReclamo });
                    setErrorReclamo(false);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorReclamarAccesoDigital, { motivoReclamo, codigo: response.Code, error: response.Message });
                    setIsLoading(false);
                    setErrorReclamo(true);
                };
                SessionHandler.Reload(dispatch);
                history.push({
                    pathname: PathsConfig.ReclamoAccesoDigitalFinalizado,
                    state: {
                        reclamoGenerado: {
                            nroGestion: response.Response.NumeroUnicoDeGestion,
                            nombreSuscripcion,
                            motivo: motivoReclamo
                        },
                        errorAlGenerarReclamo: errorReclamo,
                    }
                });
            })
            .catch((error) => {
                Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorReclamarAccesoDigital, { motivoReclamo , error: error.message });
                setIsLoading(false);
                setError(error.message);
            })
    };

    return [error, isLoading, outFunc];

};

export default useGenerarReclamoAccesoDigital;