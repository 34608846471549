import React, { useEffect } from 'react';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useDesvincularCredencial from '../../Hooks/useDesvincularCredencial';
import MensajeDesvinculacionExitosa from '../../Components/Features/MiClub/MensajeDesvinculacionExitosa';
import TitleWeb from '../../Components/Common/Web/Title';
import PathsConfig from '../../Config/RoutePaths';
import Button from '../../Components/Common/Modules/Button';
import { useHistory, useLocation } from 'react-router';

const Desvincular = () => {
    const location = useLocation();
    const esBonificado = location.state.esBonificado;
    const [errorDesvincular, isLoadingDesvincular, desvincularCredencial] =
    useDesvincularCredencial();
    const history = useHistory();
        
    const handleRedirectVincularCredencial = () => {
        history.replace({
            pathname: PathsConfig.VincularCredencial,
            state: null,
        })
    };

    useEffect(() => {
        desvincularCredencial(esBonificado);
    }, []);

    return (
        <div>
            <Breadcrumb
                firstItem="Club LA NACION"
                text="Vincular credencial"
                href="#"
            />
            <TitleWeb text="Vincular credencial" />
            <div className="container-streched">
                {/*Pantalla de carga*/}
                <LoadingSpinner isLoading={isLoadingDesvincular}>
                    {errorDesvincular ? (
                            <>
                                {errorDesvincular}
                                <Button
                                    onClick={
                                        handleRedirectVincularCredencial
                                    }
                                    href={'#'}
                                    mod="secondary"
                                    label="VOLVER"
                                />
                            </>
                        ) : (
                            <>
                                <MensajeDesvinculacionExitosa/>
                                <Button
                                    onClick={
                                        handleRedirectVincularCredencial
                                    }
                                    href={'#'}
                                    mod="secondary"
                                    label="VOLVER"
                                />
                            </>
                        )
                    }
                </LoadingSpinner>
            </div>
        </div>
    );
};

export default Desvincular;