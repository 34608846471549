import { useState } from 'react';
import ApiService from '../Services/ApiService';

const useGuardarCobranzaRechazo = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState();
    const [dataIframe, setDataIframe] = useState([]);
    const [error, setError] = useState(null);

    const outFunc = async (deudas, idTarjeta, idAbandonoCobranza, idRechazoCanalDeContacto) => {
        setIsLoading(true);
        const vencidas = deudas?.filter((d) => d.vencida == true).map((deuda) => deuda.Id);
        const adelantadas = deudas?.filter((d) => d.adelantada == true).map((deuda) => deuda.Id);
        const actualizarAbandonoCobranzaIntentoDeCobro = (IdTransaccion, idAbandonoCobranza) => {
            const pyld = {
                idAbandonoCobranza: idAbandonoCobranza,
                idAccionRechazo: IdTransaccion,
            };
            ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/ActualizarAbandonoCobranzaIntentoDeCobro`, pyld).catch((error) => {
                //TODO: enviar error a dd
            });
        };
        const payload = {
            idsRechazosPresentacionDetalle: adelantadas ? adelantadas : null,
            idTarjeta: idTarjeta ? idTarjeta : null,
            idsRechazosWs: vencidas ? vencidas : null,
            idRechazoCanalDeContacto: idRechazoCanalDeContacto ? idRechazoCanalDeContacto : null
        };
        ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/GuardarCobranzaRechazo`, payload)
            .then((data) => {
                if (data && data.Code === 0) {
                    setDataIframe(data.Response);
                    setIsLoading(false);
                    idAbandonoCobranza && actualizarAbandonoCobranzaIntentoDeCobro(data.Response.IdTransaccion, idAbandonoCobranza);
                } else {
                    setError(data.Message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
            });
    };

    return [dataIframe, error, isLoading, outFunc];
};

export default useGuardarCobranzaRechazo;
