import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Divider from '../../Components/Common/Atoms/Divider';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Card from '../../Components/Common/Modules/Card';
import Paragraph from '../../Components/Common/Atoms/Paragraph';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import MensajeExito from '../../Components/Features/MiClub/MensajeExito';
import IncentivarCambiarBlackBanner from '../../Components/Common/Banners/IncentivarCambiarBlack';
import TitleWeb from '../../Components/Common/Web/Title';
import useObtenerVerificacionCredenciales from '../../Hooks/useObtenerVerificacionCredenciales ';
import { ToastBloqueoCredenciales } from './../../Components/Common/Banners/ToastBloqueoCredenciales';
import { EstadosBonificados } from '../../Config/EstadosBonificados';
import PathsConfig from '../../Config/RoutePaths';
import ErrorBoundary from './../../Components/Common/Organism/ErrorBoundary';
import { useHistory } from 'react-router';
import { horario, telefonoMiClubCallcenter } from '../../Config/DatosDeContacto';
import useObtenerEventosBonificadosPorAccesoDigital from '../../Hooks/useObtenerEventosBonificadosPorAccesoDigital';
import useObtenerSuscripciones from '../../Hooks/useObtenerSuscripciones';

const MiClub = (props) => {
    const [eventosPendienteActivacion, errorEventos, isLoadingEventos, obtenerEventos] = useObtenerEventosBonificadosPorAccesoDigital();
    const [dataRelacionClub, errorRelacionClub, isLoadingRelacion] = useObtenerRelacionClub();
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const [isBlockedCredenciales, errorVerificacionCredenciales, isLoadingVerificacionCredenciales] = useObtenerVerificacionCredenciales();
    const history = useHistory();

    useEffect(() => {
        if (!props.location.state?.redirectFromNoSuscriptor && !props.location.state?.redireccion) {
            history.replace({
                pathname: PathsConfig.VincularCredencial,
            });
        }
    }, []);

    useEffect(() => {
        //si esta vinculada se busca el evento para consultar bonificado
        if (dataRelacionClub?.response?.desasociarHabilitado) {
            obtenerEventos();
        }
    }, [dataRelacionClub]);

    const handleClickDesvincular = () => {
        history.replace({
            pathname: PathsConfig.VincularCredencialDesvincular,
            state: { esBonificado: dataRelacionClub.response.tieneBonificado },
        });
    };
    const handleRedirect = () => {
        // el link activar AD no aparece si ningun evento
        //se envia el primer evento pendiente encontrado(el back solo necesita recibir el msj)
        history.replace({
            pathname: PathsConfig.VincularCredencialActivarBonificada,
            state: eventosPendienteActivacion[0],
        });
    };

    return (
        <>
            <section>
                <Breadcrumb firstItem="Club LA NACION" text="Vincular credencial" href="#" />
                <TitleWeb text="Vincular credencial" />
            </section>
            <section className="container-streched">
                {/*Pantalla de carga*/}
                <LoadingSpinner isLoading={isLoadingRelacion || isLoadingSuscripciones || isLoadingVerificacionCredenciales || isLoadingEventos}>
                    <ErrorBoundary
                        error={errorRelacionClub || errorEventos || errorVerificacionCredenciales || (errorSuscripciones && !suscripciones)}
                    >
                        {isBlockedCredenciales ? (
                            <ToastBloqueoCredenciales
                                title={'Atención'}
                                estado={'alert'}
                                subtitle={'La vinculación de tu credencial está temporalmente suspendida.'}
                            />
                        ) : (
                            <>
                                {props.location && props.location.state && props.location.state.succes && <MensajeExito></MensajeExito>}
                                {dataRelacionClub && dataRelacionClub.response && dataRelacionClub.response.tieneClub && (
                                    <>
                                        <Card
                                            isDesvincular={dataRelacionClub.response.desasociarHabilitado}
                                            onClickDesvincular={handleClickDesvincular}
                                            // {/* si esta vinculada, tiene bonificados y tyc pendientes se muestra el boton */}
                                            isActivar={dataRelacionClub.response.desasociarHabilitado && eventosPendienteActivacion?.length > 0}
                                            linkActivar={handleRedirect}
                                            type={dataRelacionClub.response.categoria}
                                            cardNumber={dataRelacionClub.response.nroCredencial}
                                        />

                                        {dataRelacionClub.response.categoria !== 'Black' && <IncentivarCambiarBlackBanner />}
                                        <Divider />
                                        <Paragraph size="--twoxs" mod="--arial --font-regular">
                                            Si tenés dudas o querés realizar una consulta comunicate al: <strong>{telefonoMiClubCallcenter}</strong> {' '} de {horario}
                                        </Paragraph>
                                    </>
                                )}
                            </>
                        )}
                    </ErrorBoundary>
                </LoadingSpinner>
            </section>
        </>
    );
};

MiClub.propTypes = {
    succes: PropTypes.bool,
};

export default MiClub;
