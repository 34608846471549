import { getUpsellingKeys } from "../Config/UpsellingNombresAmigables";

export const findKeyPlanUpselling = (nombreUpselling) => {
    const nombre = nombreUpselling.toLowerCase()
    let resultado = null;
    Object.keys(getUpsellingKeys).forEach(key => {
        const keyPlan = getUpsellingKeys[key].split('-').pop();
        if(nombre.includes(keyPlan)){
            resultado = getUpsellingKeys[key];
        }
    });
    return resultado
}
