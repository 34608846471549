import MiClub from '../Pages/mi-club/MiClub';
import Vincular from '../Pages/mi-club/Vincular';
import Desvincular from '../Pages/mi-club/Desvincular';
import VincularCredencial from '../Pages/mi-club/VincularCredencial';
import Motivo from '../Pages/reclamos/reclamo-entrega/Motivo';
import Ediciones from '../Pages/reclamos/reclamo-entrega/Ediciones';
import NoRecibi from '../Pages/reclamos/reclamo-entrega/NoRecibi';
import DemorasEnLaEntrega from '../Pages/reclamos/reclamo-entrega/DemorasEnLaEntrega';
import RecibiDanado from '../Pages/reclamos/reclamo-entrega/RecibiDanado';
import VendedorReclamaPago from '../Pages/reclamos/reclamo-entrega/VendedorReclamaPago';
import ContactoCallCenter from '../Pages/reclamos/ContactoCallCenter';
import Confirmacion from '../Pages/reclamos/reclamo-entrega/Confirmacion';
import ContactoReclamo from '../Pages/reclamos/reclamo-entrega/ContactoReclamo';
import DatosPersonales from '../Pages/mi-usuario/DatosPersonales';
import MiUsuario from '../Pages/mi-usuario/MiUsuario';
import CambiarPassword from '../Pages/mi-usuario/CambiarPassword';
import MisFacturas from '../Pages/Pagos/MisFacturas';
import MisConsumos from '../Pages/mi-club/MisConsumos';
import ProductoNoSolicitado from '../Pages/reclamos/reclamo-entrega/ProductoNoSolicitado';
import MisSuscripciones from '../Pages/mis-suscripciones/MisSuscripciones';
import SolicitudBaja from '../Pages/mis-suscripciones/SolicitudBaja';
import BeneficiosUtilizados from '../Pages/mis-suscripciones/solicitar-baja/BeneficiosUtilizados';
import MantenerSuscripcion from '../Pages/mis-suscripciones/solicitar-baja/MantenerSuscripcion';
import ConfirmarBaja from '../Pages/mis-suscripciones/solicitar-baja/ConfirmarBaja';
import ConfirmacionBaja from '../Pages/mis-suscripciones/solicitar-baja/ConfirmacionBaja';
import MotivoTarjeta from '../Pages/reclamos/reclamo-tarjeta/MotivoTarjeta';
import DomicilioTarjeta from '../Pages/reclamos/reclamo-tarjeta/DomicilioTarjeta';
import ConfirmacionTarjeta from '../Pages/reclamos/reclamo-tarjeta/ConfirmacionTarjeta';
import ActivarSuscripcion from '../Pages/mis-suscripciones/activar-suscripcion/ActivarSuscripcion';
import ConfirmacionActivarSuscripcion from '../Pages/mis-suscripciones/activar-suscripcion/ConfirmacionActivarSuscripcion';
import SeleccionSuscripcion from '../Pages/mis-suscripciones/suspender-por-vacaciones/SeleccionSuscripcion';
import SeleccionFechaSuspension from '../Pages/mis-suscripciones/suspender-por-vacaciones/SeleccionFechaSuspension';
import ConfirmacionSuspension from '../Pages/mis-suscripciones/suspender-por-vacaciones/ConfirmacionSuspension';
import MisCredenciales from '../Pages/mis-credenciales/MisCredenciales';
import BuscarCliente from '../Pages/mis-credenciales/BuscarCliente';
import SeleccionarDomicilio from '../Pages/mis-credenciales/SeleccionarDomicilio';
import CrearUsuario from '../Pages/mis-credenciales/CrearUsuario';
import ConfirmacionOtorgarCredencial from '../Pages/mis-credenciales/ConfirmacionOtorgarCredencial';
import SolicitudDeBaja from '../Pages/mis-credenciales/SolicitudDeBaja';
import PathsConfig from '../Config/RoutePaths';
import UnderMaintenance from '../Pages/UnderMaintenance';
import EstadoDeCuenta from '../Pages/Pagos/EstadoDeCuenta';
import MisDomiciliosSeleccionarSuscripcion from '../Pages/mis-domicilios/SeleccionarSuscripcionDomicilio';
import MisDomiciliosSeleccion from '../Pages/mis-domicilios/SeleccionarDomicilio';
import MisDomiciliosConfirmacion from '../Pages/mis-domicilios/ConfirmacionDomicilio';
import PagosPendientes from '../Pages/Pagos/PagosPendientes/PagosPendientes';
import SeleccionarDeuda from '../Pages/Pagos/PagosPendientes/SeleccionarDeuda';
import SeleccionarTarjeta from '../Pages/Pagos/PagosPendientes/SeleccionarTarjeta';
import PagosPendientesConfirmacion from '../Pages/Pagos/PagosPendientes/PagosPendientesConfirmacion';
import ElegirSuscripcion from '../Pages/Pagos/cambio-forma-pago/ElegirSuscripcion';
import SelectPaymentMethod from '../Pages/Pagos/cambio-forma-pago/SelectPaymentMethod';
import PaymentMethodChanged from '../Pages/Pagos/cambio-forma-pago/PaymentMethodChanged';
import UpsellingConfirmar from '../Pages/upselling/UpsellingConfirmar';
import UpsellingConfirmado from '../Pages/upselling/UpsellingConfirmado';
import DeudaContactoCallcenter from '../Pages/Pagos/PagosPendientes/DeudaContactoCallcenter';
import PasoPagoPendienteHash from '../Pages/Pagos/PagosPendientes/PasoPagoPendienteHash';
import AccesoDigital from '../Pages/reclamos/reclamo-acceso-digital/AccesoDigital';
import ReclamoPeriodo from '../Pages/reclamos/reclamo-acceso-digital/ReclamoPeriodo';
import ReclamoFinalizado from '../Pages/reclamos/reclamo-acceso-digital/ReclamoFinalizado';
import { ActivarBonificada } from '../Pages/mi-club/ActivarBonificada';
import { ActivarBonificadaConfirmacion } from '../Pages/mi-club/ActivarBonificadaConfirmacion';
import { UpsellingAdClubSeleccionarPago } from '../Pages/upselling/upselling-ad-club/UpsellingAdClubSeleccionarPago';
import { UpsellingAdClubDomicilio } from '../Pages/upselling/upselling-ad-club/UpsellingAdClubDomicilio';
import { UpsellingAdClubConfirmacion } from '../Pages/upselling/upselling-ad-club/UpsellingAdClubConfirmacion';
import RetainTypeform from '../Pages/retener-cliente/RetainTypeform';
import Home from '../Pages/Home'
import { UpsellingMejoraTuPlan } from '../Pages/upselling/UpsellingMejoraTuPlan';

const Routes = [
    {
        path: PathsConfig.HomeMyAccount,
        component: Home,
    },
    {
        path: [PathsConfig.MenuMiUsuario, PathsConfig.MiUsuario],
        component: MiUsuario,
    },
    {
        path: PathsConfig.CambiarPassword,
        component: CambiarPassword,
    },
    {
        path: PathsConfig.UnderMaintenance,
        component: UnderMaintenance,
    },
    {
        path: [PathsConfig.MenuDatosPersonales, PathsConfig.DatosPersonales],
        component: DatosPersonales,
    },
    {
        path: [PathsConfig.MenuVincularCredencial, PathsConfig.VincularCredencial],
        component: VincularCredencial,
    },
    {
        path: PathsConfig.VincularCredencialMiClub,
        component: MiClub,
    },
    {
        path: PathsConfig.VincularCredencialVincular,
        component: Vincular,
    },
    {
        path: PathsConfig.VincularCredencialDesvincular,
        component: Desvincular,
    },
    {
        path: PathsConfig.VincularCredencialActivarBonificada,
        component: ActivarBonificada,
    },
    {
        path: PathsConfig.VincularCredencialActivarBonificadaConfirmacion,
        component: ActivarBonificadaConfirmacion,
    },
    {
        path: [PathsConfig.MenuMisConsumos, PathsConfig.MisConsumos],
        component: MisConsumos,
    },
    {
        path: [PathsConfig.MenuReclamoEntrega, PathsConfig.ReclamoEntrega, PathsConfig.ReclamoEntregaMotivo],
        component: Motivo,
    },
    {
        path: PathsConfig.ReclamoEdiciones,
        component: Ediciones,
    },
    {
        path: PathsConfig.ReclamoEntregaDemora,
        component: DemorasEnLaEntrega,
    },
    {
        path: PathsConfig.ReclamoEntregaNoRecibi,
        component: NoRecibi,
    },
    {
        path: PathsConfig.ReclamoEntregaRecibiDaniado,
        component: RecibiDanado,
    },
    {
        path: PathsConfig.ReclamoEntregaVendedor,
        component: VendedorReclamaPago,
    },
    {
        path: PathsConfig.ReclamoEntregaProdNoSolicitado,
        component: ProductoNoSolicitado,
    },
    {
        path: PathsConfig.ReclamoEntregaConfirmacion,
        component: Confirmacion,
    },
    {
        path: PathsConfig.ReclamoEntregaContacto,
        component: ContactoReclamo,
    },
    {
        path: PathsConfig.ReclamoTarjeta,
        component: MotivoTarjeta,
    },
    {
        path: PathsConfig.ReclamoTarjetaDomicilioEntrega,
        component: DomicilioTarjeta,
    },
    {
        path: PathsConfig.ReclamoTarjetaFinalizado,
        component: ConfirmacionTarjeta,
    },
    {
        path: PathsConfig.ReclamoAccesoDigital,
        component: AccesoDigital
    },
    {
        path: PathsConfig.ReclamoAccesoDigitalPeriodo,
        component: ReclamoPeriodo
    },
    {
        path: PathsConfig.ReclamoAccesoDigitalFinalizado,
        component: ReclamoFinalizado
    },
    {
        path: [PathsConfig.MenuMisFacturas, PathsConfig.MisFacturas],
        component: MisFacturas,
    },
    {
        path: [PathsConfig.MenuMisSuscripciones, PathsConfig.MisSuscripciones],
        component: MisSuscripciones,
    },
    {
        path: [PathsConfig.ActivarSuscripcion, PathsConfig.ActivarSuscripcionConPinEnUrl],
        component: ActivarSuscripcion,
    },
    {
        path: PathsConfig.ConfirmacionActivarSuscripcion,
        component: ConfirmacionActivarSuscripcion,
    },
    {
        path: PathsConfig.SolicitudBaja,
        component: SolicitudBaja,
    },
    {
        path: PathsConfig.BeneficiosUtilizados,
        component: BeneficiosUtilizados,
    },
    {
        path: PathsConfig.MantenerSuscripcion,
        component: MantenerSuscripcion,
    },
    {
        path: PathsConfig.ConfirmarBaja,
        component: ConfirmarBaja,
    },
    {
        path: PathsConfig.ConfirmacionBaja,
        component: ConfirmacionBaja,
    },
    {
        path: [PathsConfig.MenuSuspensionVacaciones, PathsConfig.SuspensionVacaciones],
        component: SeleccionSuscripcion,
    },
    {
        path: PathsConfig.SeleccionFechaSuspension,
        component: SeleccionFechaSuspension,
    },
    {
        path: PathsConfig.ConfirmarSuspension,
        component: ConfirmacionSuspension,
    },
    {
        path: [PathsConfig.MenuMisCredenciales, PathsConfig.MisCredenciales],
        component: MisCredenciales,
    },
    {
        path: PathsConfig.MisCredencialesBuscarCliente,
        component: BuscarCliente,
    },
    {
        path: PathsConfig.MisCredencialesSeleccionarDomicilio,
        component: SeleccionarDomicilio,
    },
    {
        path: PathsConfig.MisCredencialesConfirmacionOtorgarSuscripcion,
        component: ConfirmacionOtorgarCredencial,
    },
    {
        path: PathsConfig.MisCredencialesCrearUsuario,
        component: CrearUsuario,
    },
    {
        path: PathsConfig.MisCredencialesSolicitudDeBaja,
        component: SolicitudDeBaja,
    },
    {
        path: [PathsConfig.ReclamoEntregaContactoCallcenter, PathsConfig.ReclamoTarjetaContactoCallcenter],
        component: ContactoCallCenter,
    },
    {
        path: [PathsConfig.PagosPendientes, PathsConfig.MenuPagosPendientes],
        component: PagosPendientes,
    },
    {
        path: PathsConfig.SeleccionarDeuda,
        component: SeleccionarDeuda,
    },
    {
        path: PathsConfig.SeleccionarTarjeta,
        component: SeleccionarTarjeta,
    },
    {
        path: PathsConfig.PagosPendientesConfirmacion,
        component: PagosPendientesConfirmacion,
    },
    {
        path: [PathsConfig.MenuEstadoDeCuenta, PathsConfig.EstadoDeCuenta],
        component: EstadoDeCuenta,
    },
    {
        path: [PathsConfig.MenuMisDomicilios, PathsConfig.MisSuscripcionesCambiarDomicilio],
        component: MisDomiciliosSeleccionarSuscripcion,
    },
    {
        path: PathsConfig.MisSuscripcionesCambiarDomicilioSeleccionar,
        component: MisDomiciliosSeleccion,
    },
    {
        path: PathsConfig.MisSuscripcionesCambiarDomicilioConfirmacion,
        component: MisDomiciliosConfirmacion,
    },
    {
        path: PathsConfig.ElegirSuscripcion,
        component: ElegirSuscripcion,
    },
    {
        path: PathsConfig.SeleccionarMetodoPago,
        component: SelectPaymentMethod,
    },
    {
        path: PathsConfig.CambiarFormaDePagoFinalizado,
        component: PaymentMethodChanged,
    },
    {
        path: PathsConfig.UpsellingConfirmar,
        component: UpsellingConfirmar,
    },
    {
        path: PathsConfig.UpsellingPlanes,
        component: UpsellingConfirmar,
    },
    {
        path: PathsConfig.UpsellingGracias,
        component: UpsellingConfirmado,
    },
    {
        path: PathsConfig.UpsellingADClubSeleccionPago,
        component: UpsellingAdClubSeleccionarPago
    },
    {
        path: PathsConfig.UpsellingADClubDomicilio,
        component: UpsellingAdClubDomicilio
    },
    {
        path: PathsConfig.UpsellingADClubConfirmacion,
        component: UpsellingAdClubConfirmacion
    },
    {
        path: PathsConfig.UpsellingMejoraTuPlan,
        component: UpsellingMejoraTuPlan
    },
    {
        path: '/930A6E28-E149-45AC-940A-3153A619B5DC', //path usado para entrar a pagos pendientes sin filtro de usuario
        component: PasoPagoPendienteHash,
    },
    {
        path: PathsConfig.SeleccionDeudaHash,
        component: PasoPagoPendienteHash,
    },
    {
        path: PathsConfig.DeudaContactoCallcenter, //componente de pagos pendientes que evita que se pueda pagar la deuda
        component: DeudaContactoCallcenter,
    },
    {
        path: PathsConfig.RetainTypeform, //pantalla para desplegar typeform
        component: RetainTypeform,
    },
];

export default Routes;
