import React from 'react'
import { Image } from '@ln/experiencias-ui-image';
import planesDuo from '../../../Statics/Imgs/planes-duo.png';
import planesPremium from '../../../Statics/Imgs/planes-triple.png';
import planesBlack from '../../../Statics/Imgs/planes-black.png';

export const ImagenCredencial = ({ cantCredenciales }) => {

  const urlImagen = {
    2: planesDuo,
    3: planesPremium,
    4: planesBlack,
    default: '',
  };

  const getUrlImagen = (key) => {
    if (key in urlImagen) return urlImagen[key];
    return urlImagen['default'];
  };

  return (
    <Image src={getUrlImagen(cantCredenciales)} height={50} width='auto' className='min-w-max' style={{ background: 'white' }} />
  )
}
