import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useObtenerFechaLimiteSuspensionVacaciones = (IdSuscripciones) => {
  const { REACT_APP_APIPAYWALL } = process.env;
  const [isLoading, setIsLoading] = useState(true);
  const [fechaLimite, setFechaLimite] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      ApiService.post(
        `${REACT_APP_APIPAYWALL}Suscripcion/ObtenerFechaLimiteSuspensionVacaciones`,
        { IdSuscripciones: [IdSuscripciones] }
      )
        .then((data) => {
          if (data && data.code === "0000") {
            setFechaLimite(data.response);
          } else {
            setFechaLimite([]);
            setError(data.message);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return [fechaLimite, error, isLoading];
};

export default useObtenerFechaLimiteSuspensionVacaciones;
