import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../Common/Atoms/Image';
import Title from '../../Common/Atoms/Title';
import classNames from 'classnames';

import '../../../Statics/Scss/Modules/CallbackArticle.scss';

const CallbackArticle = ({ title, imageUrl, description, bigTitle, className }) => {
    const classes = classNames(`mod-callbackArticle${imageUrl ? ` --withImg` : ``}`, className);

    return (
        <div className={classes}>
            <div className="mod-callbackArticle__descrip">
                {typeof title === 'string' ? <Title size={!bigTitle ? '--s' : '--l'}>{title}</Title> : title}
                {description}
            </div>
            {imageUrl && (
                <div className="mod-callbackArticle__img">
                    <Image src={imageUrl} alt={title} />
                </div>
            )}
        </div>
    );
};

export default CallbackArticle;

CallbackArticle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    imageUrl: PropTypes.string,
    description: PropTypes.node,
};

/*
const ModArticle  = props => {
    const { mod, title, buttonText, href, imageUrl } = props;
    if (!title) return null;

    return (        
        <div className={`mod-article --${mod || ``}`}>
            <div className="mod-description">
                <Title text={title} tag="h3" size="s"></Title>
                <p>Dejanos tus datos y empezá a disfrutar de los beneficios. <Link link="#">Completá el formulario</Link> o <Link link="#">llamanos al {telefonoMiClubCallcenter}</Link></p>
                { buttonText && <Button href={href}>{buttonText}</Button> }
            </div>
            { imageUrl && <div className="mod-media"><Image src={imageUrl} alt={title} /></div> }
        </div>
    )
}

export default ModArticle;
*/
