import React, { useState } from 'react';
import ApiService from '../Services/ApiService';

const usePostPagoPendiente = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [error, setError] = useState(null);

    const guardarPostPagoPendiente = async (postPago) => {
        ApiService.post(`${REACT_APP_APIPAYWALL}SaveDataV1/GuardarPostPagoPendiente`, postPago).catch((error) => {
            setError(error);
        });
    };

    return [error, guardarPostPagoPendiente];
};

export default usePostPagoPendiente;
