import React, {  useState } from 'react';
import ApiService from '../Services/ApiService';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';
import Datadog, { trackCustomEvent, trackCustomEventWarning } from '../Helpers/Datadog';

const useGuardarEventoVincularCredencial = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState();
    const [error, setError] = useState();

    const outFunc = async (numeroCredencial) => {
        setIsLoading(true);
        return ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripciones/GuardarEventoVincularCredencial`, numeroCredencial )
            .then((data) => {
                setResponse(data);
                if (data && data.code === '0000') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.guardarEventoVinculacionAD, { nroCredencial: numeroCredencial}); 
                } else {
                    DataDog.trackCustomEventWarning(CustomEventsErrorDatadog.errorGuardarEventoVinculacionAD, { origen: 'myaccount', error: data.message, code: data.code });
                    setError(data.message);
                }
                return Promise.resolve(data);
            })
            .catch((error) => {
                setError(error);
                DataDog.trackCustomEventWarning(CustomEventsErrorDatadog.errorGuardarEventoVinculacionAD, { origen: 'myaccount', error: error });
                return Promise.reject(error);
            });
    };

    return [response, error, isLoading, outFunc];
};

export default useGuardarEventoVincularCredencial;
