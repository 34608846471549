import { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";

const useObtenerDatosDomicilio = () => {
  const { REACT_APP_APIPAYWALL } = process.env;
  const [isLoadingDomicilios, setIsLoadingDomicilios] = useState(true);
  const [domicilios, setDomicilios] = useState(null);
  const [errorDomicilios, setErrorDomicilios] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      ApiService.post(`${REACT_APP_APIPAYWALL}Domicilio/ObtenerDomicilios`)
        .then((data) => {
          if (data && data.code) {
            if (data.code === "0000" || data.code === "4010") {
              setDomicilios(data.response.Domicilios);
              setIsLoadingDomicilios(false);
            } else {
              setError(true);
              setIsLoadingDomicilios(false);
            }
          }
        })
        .catch(() => {
          setErrorDomicilios(true);
          setIsLoadingDomicilios(false);
        });
    };
    fetchData();
  }, []);
  return [domicilios, errorDomicilios, isLoadingDomicilios];
};

export default useObtenerDatosDomicilio;
