import React from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import Link from '../Atoms/Link';
import Button from '../Modules/Button';
import Toast from '../Modules/Toast';
import FormRow from '../Atoms/Form/FormRow';
import { mailAtencionSocios } from '../../../Config/DatosDeContacto';

const ErrorMisCredenciales = ({ title }) => {
    const history = useHistory();
    return (
        <div>
            <Toast closeAuto={3000} status="warning" title={title}>
                Por favor, intentalo de nuevo. Si el error persiste comunicate con nosotros a{' '}
                <strong>
                    <Link href={`mailto: ${mailAtencionSocios}`} target="_blank">
                        {mailAtencionSocios}
                    </Link>
                </strong>
            </Toast>

            <FormRow mod="--btn-groups">
                <Button
                    label="Volver a intentar"
                    className="state--mobile-w100"
                    onClick={() => {
                        history.push({
                            pathname: PathsConfig.MisCredenciales,
                        });
                    }}
                />
            </FormRow>
        </div>
    );
};

export default ErrorMisCredenciales;
