import { useState, useEffect } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";

const useSuscripcionesParaBaja = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [suscripciones, setSuscripciones] = useState();
    const [errorSuscripciones, setErrorSuscripciones] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.get(`${REACT_APP_APIPAYWALL}Suscripcion/ObtenerSuscripcionesParaBaja`)
                .then(data => {
                    if (data?.code === '0000') {
                        setSuscripciones(data.response.suscripciones);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorObtenerSuscripcionesParaBaja, { response: data });
                    }
                })
                .catch(error => {
                    setErrorSuscripciones(error);
                    setIsLoading(false);
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorObtenerSuscripcionesParaBaja, { error });
                });
        };
        fetchData();
    }, []);

    return [suscripciones, errorSuscripciones, isLoading];
};

export default useSuscripcionesParaBaja;
