import React from 'react'
import { Text } from '@ln/common-ui-text'
import SelectFormik from '../../../Common/Formik/Select'

export const MotivoBaja = ({ name, label, setFieldValue, motivosBajaSuscripcion }) => {
  
  const handleChange = (e) => setFieldValue(name, e.target.value)

  return (
    <div className="flex flex-column gap-8">
      <Text as="h5" className="arial-bold text-18 text-neutral-light-800">{label}</Text>
      <SelectFormik name={name} aria-label={label} handleChange={handleChange} >
        <option hidden>Motivo de la baja</option>
        {motivosBajaSuscripcion?.map(({ motivoRetencionDescripcion, motivoRetencionIdBack }) => (
          <option key={motivoRetencionIdBack} value={`${motivoRetencionIdBack}|${motivoRetencionDescripcion}`}>{motivoRetencionDescripcion}</option>
        ))}
      </SelectFormik>
    </div>
  )
}
