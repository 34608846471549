import React from 'react';
import Link from "../Atoms/Link";
import CallbackArticle from "../Modules/CallbackArticle";
import image from '../../../Statics/Imgs/celAndCard.png';
import EmbedHide from '../Organism/EmbedHide';

const IncentivarActivacionBanner = () => {
  return (
    <EmbedHide>
      <CallbackArticle
        title="¡Ahora podés utilizar la credencial desde tu celular!"
        imageUrl={image}
        description={
          <p className="--twoxs">
            <Link href="https://club.lanacion.com.ar/search?tags.name.keyword=Credencial%20Virtual"> Activá tu credencial virtual ahora</Link>.
          </p>
        }
      />
    </EmbedHide>
  )
};

export default IncentivarActivacionBanner;