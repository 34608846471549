import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';
import Wizard from '../../Components/Common/Modules/Wizard';
import Title from '../../Components/Common/Atoms/Title';
import Divider from '../../Components/Common/Atoms/Divider';
import steps from '../../Config/StepsMisDomicilios';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useObtenerSuscripciones from '../../Hooks/useObtenerSuscripciones';
import SuscripcionesSelector from '../../Components/Common/FormBlocks/SuscripcionesSelector';
import ErrorServicioYContacto from '../../Components/Common/Banners/ErrorServicioYContacto';
import ContactoDudasBanner from '../../Components/Common/Banners/ContactoDudas';
import PathsConfig from '../../Config/RoutePaths';
import estadoSuscripciones from '../../Models/EstadoSuscripciones';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import ToastEmptyState from '../../Components/Common/Banners/ToastEmptyState';
import useObtenerEvento from '../../Hooks/useObtenerEvento';
import { useStore } from '../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';

const SeleccionarSuscripcionDomicilio = () => {
    const { HandleSwitchsStates } = useStore();
    const history = useHistory();
    const [suscripciones, errorSuscripciones, isLoading] = useObtenerSuscripciones(true);
    const [evento, errorEventos, isLoadingEventos] = useObtenerEvento();
    const conSuscripcion =
        suscripciones?.length > 0 &&
        suscripciones?.filter((suscripcion) => suscripcion.TipoFamilia !== 'E' && suscripcion.Estado !== estadoSuscripciones.BAJA);
    const [suscriptionsSelected, setSuscriptionsSelected] = useState([]);
    const [suscriptionsUnChecked, setSuscriptionsUnChecked] = useState(false);

    const redirect = () => {
        if (suscriptionsSelected.length > 0) {
            setSuscriptionsUnChecked(false);
            redirectPasoDos();
        } else {
            setSuscriptionsUnChecked(true);
        }
    };
    const redirectPasoDos = () => {
        history.push({
            pathname: PathsConfig.MisSuscripcionesCambiarDomicilioSeleccionar,
            state: {
                suscriptionsSelected: suscriptionsSelected,
            },
        });
    };

    return (
        <>
            <section className="lay-strechted">
                <Breadcrumb firstItem="Mis suscripciones" href="#" text="Cambiar domicilio de entrega" />

                <TitleWeb text="Cambiar domicilio de entrega" />
            </section>

            <section className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.CambiarDomicilio) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.CambiarDomicilio} />
                ) : (
                    <LoadingSpinner isLoading={isLoading || isLoadingEventos}>
                        <ErrorBoundary error={(errorSuscripciones && !suscripciones) || errorEventos}>
                            {errorSuscripciones && !suscripciones ? (
                                <>
                                    <ErrorServicioYContacto title={'Hubo un error al consultar tus suscripciones'} />
                                    <ContactoDudasBanner />
                                </>
                            ) : (
                                <>
                                    {conSuscripcion && conSuscripcion.length > 0 ? (
                                        <>
                                            <Wizard steps={steps} activeStep={1} />

                                            <Title size="--s" mod="--arial --font-bold --mb-24">
                                                Seleccioná las suscripciones donde necesites cambiar el domicilio
                                            </Title>
                                            <SuscripcionesSelector
                                                suscriptions={conSuscripcion}
                                                onSuscriptionsSelected={setSuscriptionsSelected}
                                                unChecked={suscriptionsUnChecked}
                                                onUnChecked={setSuscriptionsUnChecked}
                                                redirectPasoDos={redirect}
                                                evento={evento}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <ToastEmptyState
                                                titulo={'No tenés suscripciones editoriales activas (diarios y/o revistas).'}
                                                bajada={
                                                    'No se encontraron suscripciones editoriales para solicitar un cambio de domicilio de entrega.'
                                                }
                                                hasBanner
                                            />
                                            <Divider />
                                            <ContactoDudasBanner />
                                        </>
                                    )}
                                </>
                            )}
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </section>
        </>
    );
};

export default SeleccionarSuscripcionDomicilio;
