import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../../../Statics/Scss/Components/InputList.scss';

import FormCol from './FormCol';
import FormRow from './FormRow';

const propTypes = {
	/**
	 * Label asociado al listado.
	 */
	label: PropTypes.object,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Flag, ¿tiene espaciado por fila?
	 */
	hasRowSpacing: PropTypes.bool,
	/**
	 * Cantidad de columnas.
	 */
	cols: PropTypes.string,
	/**
	 * Página actual.
	 */
	activePage: PropTypes.number,
	/**
	 * Handler para el cambio de página.
	 */
	onPageClick: PropTypes.func,
	/**
	 * Items asociados.
	 */
	items: PropTypes.array,
	/**
	 * Cantidad de items por página.
	 */
	itemsPerPage: PropTypes.number,
};

const defaultProps = {
	cols: '1',
	onPageClick: () => {},
	goToPage: () => {},
	itemsPerPage: 8,
	activePage: 0,
};

const InputList = ({
	label,
	children,
	className,
	onPageClick,
	itemsPerPage,
	items,
	activePage,
	hasRowSpacing,
	cols,
	...r
}) => {
	const classes = classNames('com-inputList', className, [`--cols-${cols}`], {
		'--row-spacing': hasRowSpacing,
	});
	let maxIndex, minIndex, actualItems;

	/*
	 * Si contiene la cantidad de items por páginas,
	 * realizo cálculos para la paginación, y... pagino.
	 */
	if (itemsPerPage) {
		maxIndex = (activePage + 1) * itemsPerPage;
		minIndex = maxIndex - itemsPerPage;
		actualItems = items?.slice(minIndex, maxIndex);

		items = actualItems;
	}

	return (
		<div className={classes} {...r}>
			<FormRow>
				<FormCol>{label && <>{label}</>}</FormCol>
				<div className="com-inputList__list">
					{itemsPerPage ? items : children}
				</div>
			</FormRow>
		</div>
	);
};

InputList.propTypes = propTypes;
InputList.defaultProps = defaultProps;

export default InputList;
