import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import ErrorNoCoincide from "../../Components/Features/MiClub/ErrorNoCoincide";
import ErrorBoundary from "../../Components/Common/Organism/ErrorBoundary";
import FormularioCorto from "../../Components/Features/MiClub/FormularioCorto";
import FormularioLargo from "../../Components/Features/MiClub/FormularioLargo";
import useObtenerDatosClub from "../../Hooks/useObtenerDatosClub";
import ProteccionDatosPersonales from "../../Components/Features/MiClub/ProteccionDatosPersonales";
import NoRecordasTarjeta from "../../Components/Features/MiClub/NoRecordasTarjeta";
import LoadingSpinner from "../../Helpers/LoadingSpinner";
import TitleWeb from "../../Components/Common/Web/Title";
import useVincularCredencial from '../../Hooks/useVincularCredencial';
import useObtenerRelacionClub from "../../Hooks/useObtenerRelacionClub";
import YaTenesCredencial from "../../Components/Features/MiClub/YaTenesCredencial";

const Vincular = () => {
  const [error, setError] = useState();
  const [intentos, setIntentos] = useState(3);
  const [dataClub, errorClub, isLoading] = useObtenerDatosClub();
  const [errorVincularCredencial, isLoadingCredencial, vincularCredencial] = useVincularCredencial();
  const [dataRelacionClub, errorRelacionClub, isLoadingRelacion] = useObtenerRelacionClub();

  useEffect(() => {
    if(errorVincularCredencial!=null){
      setError(errorVincularCredencial);
    }
  }, [errorVincularCredencial])

  const validacionFormCorto = (dataClub, intentos) => {
    return (
      dataClub &&
      intentos > 0 &&
      dataClub.code === "1" &&
      dataClub.response &&
      (dataClub.response.length >= 1)
    );
  }

  const esLargo = (dataClub, intentos) => {
    return dataClub && (dataClub.code === "2" || intentos <= 0);
  };

  const onIntentoError = () => {
    setError(<ErrorNoCoincide></ErrorNoCoincide>);
    setIntentos(intentos - 1);
  };

  const vincular = (params) => {
    vincularCredencial(params);
  };

  const formCorto = validacionFormCorto(dataClub, intentos);

  const validacionCredencial = (dataRelacionClub) => {
    return dataRelacionClub && dataRelacionClub?.response && dataRelacionClub?.response?.tieneClub;
  };

  const tieneCredencial = validacionCredencial(dataRelacionClub);

  return (
    <>
      <Breadcrumb
        firstItem="Club LA NACION"
        href="#"
        text="Vincular credencial"
      />

      <TitleWeb text="Vincular credencial" />
      <div className="container-streched">
        {/*Pantalla de carga*/}
        <LoadingSpinner isLoading={isLoading || isLoadingCredencial || isLoadingRelacion}>
            {/*Pantalla de error*/}
            <ErrorBoundary error={errorClub || errorRelacionClub}>
                {error}
                {!tieneCredencial ? (
                    <>
                        {esLargo(dataClub, intentos) && <FormularioLargo OnVincular={vincular} />}
                        {formCorto && (
                            <FormularioCorto
                                Data={dataClub}
                                EsIntermedio={dataClub?.response.length > 1}
                                OnVincular={vincular}
                                OnError={onIntentoError}
                            ></FormularioCorto>
                        )}
                        <NoRecordasTarjeta />
                        <ProteccionDatosPersonales />
                    </>
                ) : (
                    <YaTenesCredencial />
                )}
            </ErrorBoundary>
        </LoadingSpinner>
      </div>
    </>
  );
};

export default Vincular;
