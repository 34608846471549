import { useState, useEffect } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerMotivos = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;

    const [isLoading, setIsLoading] = useState(false);
    const [motivos, setMotivos] = useState([]);
    const [error, setError] = useState(null);

    const outFunc = async (idSuscripcion) => {
        const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/ObtenerMotivosReclamos`;
        setIsLoading(true);
        ApiService.post(pathUrl, { idSuscripcion: idSuscripcion })
            .then(data => {
                if (data && data.Code === 0) {
                    setMotivos(data.Response);
                    setIsLoading(false);
                } else {
                    setError(
                        `Código de Error:${data.CodeError} - Mensaje: ${data.Message}`
                    );
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setError(error);
                setIsLoading(false);
            });
    };

    return [motivos, error, isLoading, outFunc];
};

export default useObtenerMotivos;
