import HistorialCredencialTableModel from "../Models/HistorialCredencialTableModel";

const MapHistorialCredenciales = (dataCambiosCredencial) => {
  const tablaCredenciales = [];

  dataCambiosCredencial.map((evento) => {
    let eventoTableRow = JSON.parse(
      JSON.stringify(HistorialCredencialTableModel)
    );

    eventoTableRow.id.value = evento.IdEventoHistorico;
    eventoTableRow.fecha.value = evento.Fecha;
    eventoTableRow.evento.value = evento.Evento;
    eventoTableRow.origen.value = evento.Origen;

    return tablaCredenciales.push(eventoTableRow);
  });

  return tablaCredenciales;
};

export default MapHistorialCredenciales;
