import React from "react";
import Paragraph from "../Atoms/Paragraph";
import List from "../Atoms/List";

const NotasSupenderPorVacaciones = () => {
  return (
    <>
      <List size="--twoxs" className="--arial --font-regular --bullet-xs">
        <Paragraph>
          Por razones de logística, no se puede modificar ni anular una suspensión de entrega por vacaciones de Diario y ¡HOLA! con menos de 7 dÍas de anticipación.
        </Paragraph>
        <Paragraph>
          Para el caso de revistas mensuales, las solicitudes de suspensión se deberán realizar antes del 10 de cada mes para que impacte al mes siguiente.
        </Paragraph>
        <Paragraph>
          Para Diario y ¡HOLA! el período mínimo de duración de las vacaciones deberá ser de 5 días y no podrá superar un máximo de 90 días al año.
        </Paragraph>
        <Paragraph>
          Si viajás al exterior este verano, te invitamos a que sigas informándote a través de lanacion.com.ar o nuestra APP. Podrás acceder al diario impreso en formato PDF, como también a las revistas Ohlalá, Lugares, Living, ¡HOLA!, Rolling Stone, Brando y Jardín.
        </Paragraph>
      </List>
    </>
  );
};

export default NotasSupenderPorVacaciones;
