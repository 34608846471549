import React, { useEffect, useState } from 'react'
import ApiService from '../Services/ApiService';

const useObtenerEvento = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripciones/ObtenerEventosSuscripciones`)
                .then(data => {
                    if (data && data.code === "0000") {
                        setEventos(data.response.evento)
                        setIsLoading(false)

                    } else if (data && (data.code === "0001" || data.code === "0002")) { //si code es 0001 no tiene eventos registrados, si es 02 no tiene crmId sino por catch
                        setEventos(null)
                        setIsLoading(false)
                    }
                    else {
                        setError(data.message)
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    setError(error)
                    setIsLoading(false)
                });
        };
        fetchData();
    }, []);

    return [eventos, error, isLoading];
}

export default useObtenerEvento