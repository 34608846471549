import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import "../../../Statics/Scss/Modules/DatePicker.scss";

import es from "date-fns/esm/locale/es";
import {
  default as ExternalDatePicker,
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import Icon from "../Atoms/Icon";

registerLocale("es", es);
setDefaultLocale("es");
const DATE_FORMAT = "dd/MM/yyyy";
const DROPDOWN_YEARS_ITEMS_NUMBER = 4;

const propTypes = {
  /**
   * Referencia a esta instancia.
   */
  datePickerRef: PropTypes.any,
  /**
   * Handler para cuando se cierra
   * con la cruz.
   */
  onClear: PropTypes.func,
  /**
   * Handler para el cambio de fechas.
   */
  onChange: PropTypes.func,
  /**
   * Handler para la presión de teclas.
   */
  onKeyDown: PropTypes.func,
  /**
   * Establece un icono diferente al
   * calendario.
   */
  icon: PropTypes.string,
  /**
   * Flag, ¿disparar onChange cuando cambia el
   * año y mes?.
   */
  adjustDateOnChange: PropTypes.bool,
  /**
   * La fecha máxima aceptada, formato moment.
   */
  maxDate: PropTypes.instanceOf(Date),
  /**
   * La fecha mínima aceptada, formato moment.
   */
  minDate: PropTypes.instanceOf(Date),
  /**
   * Formato de la fecha.
   */
  dateFormat: PropTypes.string,
  /**
   * Fecha inicial.
   */
  openToDate: PropTypes.instanceOf(Date),
  /**
   * Flag, ¿está deshabilitado?
   */
  disabled: PropTypes.bool,
  /**
   * Flag, ¿está es de solo lectura?
   */
  readOnly: PropTypes.bool,
  /**
   * Flag, ¿es requerido?
   */
  required: PropTypes.bool,
  /**
   * Posición del calendario.
   */
  popoverPlacement: PropTypes.string,
  /**
   * Flag, ¿cerrar al seleccionar?
   */
  shouldCloseOnSelect: PropTypes.bool,
  /**
   * Fecha seleccionada.
   */
  selected: PropTypes.instanceOf(Date),
  /**
   * Estila el input cuando es invalida
   * la selección.
   */
  isInvalid: PropTypes.bool,
  /**
   * Flag, ¿solo muestra el calendario?
   */
  inline: PropTypes.bool,
  /**
   * Flag, ¿solo muestra input?
   */
  hideCalendar: PropTypes.bool,
  /**
   * Array de fechas a incluir
   * ÚNICAMENTE.
   */
  includeDates: PropTypes.array,
  /**
   * Array de fechas a excluir.
   */
  excludeDates: PropTypes.array,
  /**
   * Flag, ¿prevenir focus en el
   * calendario?
   */
  preventOpenOnFocus: PropTypes.bool,
  /**
   * Texto inicial.
   */
  placeholder: PropTypes.string,
  /**
   * Flag, ¿está habilitado el tab
   * del calendario?
   */
  isTabInsideEnabled: PropTypes.bool,
};

const defaultProps = {
  icon: "calendar",
  onChange: () => {},
  onClear: () => {},
  onKeyDown: () => {},
  onBlur: () => {},
  adjustDateOnChange: true,
  dateFormat: DATE_FORMAT,
  popoverPlacement: "bottom-start",
  shouldCloseOnSelect: false,
  isTabInsideEnabled: true,
};

const DatePicker = ({
  datePickerRef,
  onClear,
  icon,
  onChange,
  adjustDateOnChange,
  maxDate,
  minDate,
  dateFormat,
  openToDate,
  placeholder,
  selected,
  shouldCloseOnSelect,
  disabled,
  readOnly,
  required,
  isInvalid,
  popoverPlacement,
  inline,
  hideCalendar,
  excludeDates,
  onKeyDown,
  preventOpenOnFocus,
  includeDates,
  onBlur,
  isTabInsideEnabled,
  ...r
}) => {
  const startRef = useRef();
  const [initialDate, setInitialDate] = useState(selected);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const inputClassName = `mod-date-picker__group__input${
    isInvalid ? " --invalid" : ""
  }${isCalendarOpen ? " --active" : ""}`;
  const popperClassName = `mod-date-picker__popper${
    hideCalendar ? " --hidden" : ""
  }`;

  const handleChange = (date) => {
    setInitialDate(date);
    onChange(date);
  };

  const handleKeyDown = (e) => {
    if (e.code === "Tab") {
      hideCalendar && setIsCalendarOpen(false);
    } else if (e.code === "Space") {
      if (e.stopPropagation) {
        e.stopPropagation();
        e.preventDefault();
      }
      return false;
    } else if (
      e.code !== "Enter" &&
      e.code !== "Backspace" &&
      e.code !== "Escape"
    ) {
      const value = e.target.value;

      if (value.match(/^\d{2}$/) !== null) e.target.value = value + "/";
      else if (value.match(/^\d{2}\/\d{2}$/) !== null)
        e.target.value = value + "/";
    }

    onKeyDown(e);
  };


  return (
    <div className="mod-date-picker">
      {icon && <Icon size="--icon-m" name={icon} />}
      <ExternalDatePicker
        ref={startRef}
        adjustDateOnChange={adjustDateOnChange}
        calendarClassName="mod-calendar"
        className={inputClassName}
        popperClassName={popperClassName}
        dateFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        openToDate={openToDate}
        placeholderText={placeholder}
        ref={datePickerRef}
        selected={initialDate}
        shouldCloseOnSelect={shouldCloseOnSelect}
        showMonthDropdown={true}
        showYearDropdown={minDate && maxDate ? minDate.getFullYear() !== maxDate.getFullYear() : true}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        popoverPlacement={popoverPlacement}
        yearDropdownItemNumber={DROPDOWN_YEARS_ITEMS_NUMBER}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
        inline={inline}
        hideCalendar={hideCalendar}
        excludeDates={excludeDates}
        onKeyDown={handleKeyDown}
        preventOpenOnFocus={preventOpenOnFocus}
        includeDates={includeDates}
        onBlur={(e) => onBlur(e)}
        previousMonthButtonLabel="Mes anterior"
        previousYearButtonLabel="Año anterior"
        // TODO: review
        scrollableYearDropdown={false}
        tabIndex={isTabInsideEnabled ? "-1" : "0"}
        {...r}
      />
    </div>
  );
};

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
