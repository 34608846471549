import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Common/Organism/Modal';
import Toast from '../Common/Modules/Toast';
import Button from '../Common/Modules/Button';
import Paragraph from '../Common/Atoms/Paragraph';
import handleOpenModal from '../../Helpers/OpenModal';
import FormRow from '../Common/Atoms/Form/FormRow';

const propTypes = {
    setOpenModal: PropTypes.func.isRequired,
    mailPrincipal: PropTypes.string.isRequired,
    mailSecundario: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
};

const ConfirmarCambioMail = (props) => {
    const handleClose = () => {
        handleOpenModal(true, props.setOpenModal);
    };

    const onConfirmModal = (mailPrincipal, mailSecundario) => {
        props.setOpenModal(false);
        props.onConfirm(mailPrincipal, mailSecundario);
    };

    return (
        <Modal closeModal={handleClose} title={'Confirmar cambio de email'}>
            <>
                <Toast status={'alert'} title={'Estás por confirmar el cambio de los siguientes datos de tu perfil'}>
                    <>
                        {props.mailPrincipal ? (
                            <span>
                                {' '}
                                <b>Email principal:</b> {props.mailPrincipal}
                            </span>
                        ) : null}{' '}
                        <br />
                        {props.mailSecundario ? (
                            <span>
                                {' '}
                                <b>Email secundario:</b> {props.mailSecundario}
                            </span>
                        ) : null}
                    </>
                </Toast>
                {/* Lo de acá abajo en negrita */}
                <Paragraph size="--threexs" mod="--font-bold --arial --mb-32">
                    Al continuar te enviaremos un link de confirmación a {props.mailPrincipal}. Revisá tu bandeja de entrada y de correo no deseado.
                </Paragraph>
                {/* Esto en texto comun */}
                <Paragraph size="--threexs" mod="--arial --mb-32">
                    Tené en cuenta que deberás usar esta nueva cuenta cada vez que inicies sesión en LA NACION. Tu contraseña sigue siendo la misma.
                </Paragraph>
                <FormRow mod="--btn-groups --mb-32">
                    <Button
                        type="button"
                        onClick={() => {
                            onConfirmModal(props.mailPrincipal, props.mailSecundario);
                        }}
                        href={'#'}
                        label="CONFIRMAR"
                        isFirstPositioned={true}
                        className="state--mobile-w100 --mb-0"
                        iconSize="s"
                    />
                    <Button
                        type="button"
                        onClick={handleClose}
                        href={'#'}
                        label="CANCELAR"
                        isFirstPositioned={true}
                        className="state--mobile-w100 --mb-0"
                        mod="secondary"
                        iconSize="s"
                    />
                </FormRow>
            </>
        </Modal>
    );
};

ConfirmarCambioMail.propTypes = propTypes;

export default ConfirmarCambioMail;
