import React from 'react'
import Toast from '../../Common/Modules/Toast'

const ErrorVincular = props => {
    return (
        <Toast status="warning" title="Ocurrió un error">No se pudo vincular tu tarjeta correctamente.</Toast>
    )
}

export default ErrorVincular
