import * as Yup from "yup";

const ValidacionSchemaDni = Yup.object().shape({
  numeroDocumento: Yup.string()
    .required("Ingresá el N° de documento del cliente a asignar la credencial")
    .test({
      name: "numeroDocumento",
      message: "El número de DNI que ingresaste no es válido",
      test: function (value) {
        if (!value) {
          return false;
        } else {
          let myRe = /^[\d]{1,3}\.?[\d]{3,3}\.?[\d]{3,3}$/;
          return myRe.test(value);
        }
      },
    }),
});

export default ValidacionSchemaDni;
