import React, { useEffect } from "react";

import classNames from "classnames";

import Spinner from "../../Atoms/Spinner";
import Button from "../Button";

const useDrawer = ({
  isClosable,
  buttonBackgroundNone,
  loadingColor,
  closePosition,
  isLoading,
  children,
  isOpen,
  handleClose,
  props,
}) => {
  const overlayClasses = classNames("overlay", { "--open": isOpen });
  const closeClasses = classNames("close", {
    [`--${closePosition}`]: closePosition,
    ["background-none"]: buttonBackgroundNone,
  });
  const content = isLoading ? (
    <Spinner color={loadingColor || "FFF"} />
  ) : (
    children
  );
  const drawer = (
    <>
      <article {...props}>
        {content}
        {isClosable && (
          <Button
            className={closeClasses}
            icon="close"
            iconSize="m"
            onClick={handleClose}
          />
        )}
      </article>
    </>
  );

  useEffect(() => {
    if (isOpen) document.body.classList.add("drawer-on");
    else document.body.classList.remove("drawer-on");
  }, [isOpen]);

  useEffect(() => {
    const closeOnEscape = function (e) {
      if (e.key === "Escape") handleClose();
    };

    document.addEventListener("keydown", closeOnEscape);

    return () => {
      document.removeEventListener("keydown", closeOnEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    overlayClasses,
    drawer,
  };
};

export default useDrawer;
