import Beneficio from "../Models/DTO/Beneficio";

const mapBeneficiosToTable = (beneficios) => {
    if(beneficios == null) return [];
    const BeneficiosMapeados = [];

    beneficios.map((beneficio) => {
      let BeneficioFormatted = JSON.parse(JSON.stringify(Beneficio));

      BeneficioFormatted.date.value = beneficio.Fecha;
      BeneficioFormatted.desc.value = beneficio.Descuento ? beneficio.Descuento + "%" : "0%";
      BeneficioFormatted.comercio.value = beneficio.Comercio && beneficio.Comercio.toLowerCase();
      BeneficioFormatted.rubro.value = beneficio.Rubro;

      return BeneficiosMapeados.push(BeneficioFormatted);
    });

    return BeneficiosMapeados.filter((beneficio) => beneficio.comercio.value && beneficio.rubro.value);
}

export default mapBeneficiosToTable;
