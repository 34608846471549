import React, { useState, useEffect } from 'react'
import '../../../Statics/Scss/Components/Input-list.scss'
import steps from '../../../Config/Steps';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Wizard from '../../../Components/Common/Modules/Wizard';
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import useCrearReclamoSuscripcion from '../../../Hooks/useCrearReclamoDeSuscripcion';
import ProductoNoSolicitadoForm from '../../../Components/Features/ReclamoEntrega/ProductoNoSolicitadoForm';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import ErrorBlock from '../../../Components/Features/ReclamoEntrega/ErrorBlock';
import useObtenerProductosDeSuscripcion from '../../../Hooks/useObtenerProductosDeSuscripcion';
import { reclamoEntregaParams } from '../../../Helpers/Reclamo';
import { useHistory } from 'react-router';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';

const ProductoNoSolicitado = props => {
    const history = useHistory();
    const stepProps = ['suscripcionSelected', 'motivoSelected', 'despachos', 'address', 'fechaDesde', 'fechaHasta', 'detalleSuscripcion'];
    const { suscripcionSelected, motivoSelected, despachos, address, fechaDesde, fechaHasta, detalleSuscripcion } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoEntrega);
    const [detalleSuscripcionProducto] = useObtenerProductosDeSuscripcion(suscripcionSelected);
    const [reclamoError, reclamoIsLoading, CrearReclamoDeSuscripcion] = useCrearReclamoSuscripcion();
    const [detalleCompleto, setDetalleCompleto] = useState(null);
    useEffect(() => {
        if (detalleSuscripcionProducto) {
            setDetalleCompleto({ ...detalleSuscripcionProducto, 'TipoFamilia': detalleSuscripcion?.TipoFamilia })
        }
    }, [detalleSuscripcionProducto])

    useEffect(() => {
        if(reclamoError){
            history.push({
                pathname: PathsConfig.ReclamoEntregaContactoCallcenter,
                state: reclamoEntregaParams
            });
        }
    }, [reclamoError])

    return (
        <>
            <div className="lay-strechted">
                <Breadcrumb firstItem={"Mis reclamos"} />

                <TitleWeb text="Nuevo reclamo de entrega" />
            </div>
            <div className="container-streched">
                <LoadingSpinner isLoading={reclamoIsLoading}>
                    <>
                        <Wizard steps={steps} activeStep={3} />
                        {reclamoError ?
                            (reclamoError.Message ?
                                <ErrorBlock errorMsg={reclamoError.Message}/>
                                : <ErrorBlock />) :
                            <>
                                <CallbackArticle
                                    title="Confirmá la siguiente información antes de generar el reclamo"
                                    description="Del siguiente listado seleccioná los productos que deberias tener suscripción"
                                />
                                {detalleCompleto && (
                                    <ProductoNoSolicitadoForm onSubmit={CrearReclamoDeSuscripcion}
                                        data={{
                                            suscripcionSelected: suscripcionSelected,
                                            motivoSelected: motivoSelected,
                                            despachos: despachos,
                                            address: address,
                                            fechaDesde: fechaDesde,
                                            fechaHasta: fechaHasta,
                                            detalleSuscripcion: detalleCompleto,
                                        }}
                                    />
                                )}

                            </>
                        }
                    </>
                </LoadingSpinner>
            </div>
        </>
    )
}


export default ProductoNoSolicitado