import React from 'react';
import Beneficio from '../../Features/UpSelling/Beneficio';

const PasosExplicacion = ({ precio, categoriaCredencial, cantCredenciales = 0 }) => {
    const features = [
        `A partir del próximo débito se te cobrará $${precio}/mes.`,
        'Las credenciales de Club LA NACION son distribuidas en domicilios de Argentina',
        categoriaCredencial === 3 ? 'Se te enviarán las nuevas credenciales Club LA NACION Black. Hasta tanto, las credenciales Premium siguen siendo válidas' : 'Se te enviará la nueva credencial Club LA NACION Premium',
        'Empezás a disfrutar del nuevo plan desde hoy:',
        `<b>${cantCredenciales === 0 ? 1 : cantCredenciales} cuentas con Acceso Digital</b>`,
        `<b>${cantCredenciales} credenciales Premium Club LA NACION</b>`
      ]

    return (
        <div className='flex flex-column gap-4'>
            <p className="arial-bold text-16">¿Qué pasa a continuación?</p>
            <ul className="flex flex-column gap-4">
                {features.map((feature, index) => (
                    <Beneficio iconColor={categoriaCredencial === 3 ? 'violet' : 'primary'} key={index} text={feature} />
                ))}
            </ul>
        </div>
    );
};

export default PasosExplicacion;
