import React, { useState } from "react";

import ErrorGenerico from "../Components/Features/ErrorGenerico";
import ErrorNoCoincide from "../Components/Features/MiClub/ErrorNoCoincide";
import ErrorVincular from "../Components/Features/MiClub/ErrorVincular";
import ErrorYaExisteRelacion from "../Components/Features/MiClub/ErrorYaExisteRelacion";

import ApiService from "../Services/ApiService";
import SessionHandler from "../Handlers/Session";
import Metering from "../Handlers/Metering";
import Hotjar from "../Handlers/Hotjar";
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import PathsConfig from "../Config/RoutePaths";
import { useHistory } from 'react-router';
import useGuardarEventoVincularCredencial from "./useGuardarEventoVincularCredencial";
import { useDispatch } from "../Store/storeProvider";

const useVincularCredencial = () => {
    const [error, setError] = useState();
    const [dataEvento, errorEvento, isLoadingEvento, guardarEvento] = useGuardarEventoVincularCredencial();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoadingCredencial, setIsLoadingCredencial] = useState(false);
    const redirectToVincularCredencial = () => {
        history.replace({
            pathname: PathsConfig.VincularCredencialMiClub,
            state: {
                succes: true,
                redirectFromNoSuscriptor: true,
            },
        });
    };

    const reload = async () => {
        await SessionHandler.Reload(dispatch);
        await Metering.obtenerCookieSuscriptor(); //No debería ser necesario, porque no hay cambio de estado al vincular
        redirectToVincularCredencial();
    }

    const fetchData = (params) => {
        setIsLoadingCredencial(true);
        return ApiService.post(
            `${process.env.REACT_APP_APILOGIN}Usuario/AsociarCredencial`,
            params
        )
            .then((data) => {
                if (data && data.code === "1") {
                    Datadog.trackCustomEvent(CustomEventsDatadog.vincularCredencial, { sitioOrigen: "Myaccount" });
                    guardarEvento(params.nro_tarjeta).then(() => {
                    reload();
                        return Promise.resolve(data.response);
                    }).catch((err) => {
                        console.error("Error", err);
                        reload();
                        return Promise.resolve(data.response); //la asociacion salio ok, pero el evento no se emitio
                    });
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorVincularCredencial, { sitioOrigen: "Myaccount", error: data.message, code: data.code, data: data.response });
                    setIsLoadingCredencial(false);
                    if (!data || !data.code) {
                        setError(<ErrorGenerico></ErrorGenerico>);
                        return Promise.reject(data);
                    }
                    switch (data.code) {
                        case "-1":
                            // Chequeo si el response del -1 error corresponde a credencial ya asociada a otro usuario .
                            if (data.message == "Ya existe la relacion") {
                                setError(
                                    <ErrorYaExisteRelacion
                                        email={data.response}
                                    ></ErrorYaExisteRelacion>
                                );
                            } else {
                                //sino es un error generico
                                setError(<ErrorVincular></ErrorVincular>);
                            }
                            break;
                        case "-2":
                            setError(<ErrorNoCoincide></ErrorNoCoincide>);
                            break;
                        case "-3":
                            setError(
                                <ErrorYaExisteRelacion
                                    email={data.response}
                                ></ErrorYaExisteRelacion>
                            );
                            Hotjar.HotjarTrigger("LN.MiCuenta.Vinculacion.ErrorEmail");
                            break;
                        default:
                            setError(<ErrorGenerico></ErrorGenerico>);
                            return Promise.reject(data);
                    }
                }
            })
            .catch((error) => {
                Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorVincularCredencial, error);
                setError(<ErrorGenerico></ErrorGenerico>);
                setIsLoadingCredencial(false);
                return Promise.reject(error);
            })
    };
    return [error, isLoadingCredencial, fetchData];
};

export default useVincularCredencial;