export const states = [
    {
        text: 'En gestión',
        color: 'warning',
    },
    {
        text: 'Gestión vencida',
        color: 'danger',
    },
    {
        text: 'Pendiente de despacho',
        color: 'warning',
    },
    {
        text: 'Despachada',
        color: 'warning',
    },
    {
        text: 'Entregada',
        color: 'success',
    },
    {
        text: 'No entregada',
        color: 'danger',
    },
    {
        text: 'Activa',
        color: 'primary',
    },
    {
        text: 'Suspendida',
        color: 'danger',
    },
    {
        text: 'Baja pendiente',
        color: 'warning',
    },
    {
        text: 'Baja',
        color: 'danger',
    },
];
