export const UpsellingPlanes = [
    {nombre:'Club LA NACION + Acceso Digital', id: 0},
    {nombre:'Dúo Premium',id: 1},
    {nombre:'Triple Premium', id: 2},
    {nombre:'Black Familiar', id: 3},
];

export const UpsellingPlanesIds = {
        DUO: 1,
        TRIPLE: 2,
        BLACK: 3,
}