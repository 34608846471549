import { RefreshAsync } from '@ln/user.client.libs';
import {borrarAlgunasCookies, setupCookies} from '../Helpers/Cookies';
import Usuario from '../Services/UsuarioService';

const Reload = async (dispatch) => {
    let promises = [Usuario.Me()];
    promises.push(RefreshAsync());

    return Promise.all(promises)
        .then((data) => {
            try {
                dispatch({
                    type: 'Suscripciones',
                    value: {
                      fetchSuscripciones: true,
                    },
                  });
                borrarAlgunasCookies([
                    'usuarioDetalleClubNacion',
                    'ProductoPremiumId',
                    'Crm_id',
                    'usuario_detalle_nombre',
                    'usuario_detalle_apellido'
                ]);
                let user = JSON.parse(data[0].response);
                setupCookies(user.Usuario);
            } catch {
                return Promise.resolve(false);
            }
            return Promise.resolve(true);
        })
        .catch(() => Promise.resolve(false)); //si da error el /me de todos modos resuelvo ya que la transaccion se hizo
};

export default {Reload};
