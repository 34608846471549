const PostPagoPendiente = {
    Codrta: '',
    Descripcion: '',
    Hash: '',
    Mascara: '',
    Token: '',
    TokenDa: '',
    MsgFrontError: '',
    GuardarRtaWsResponse: {
        Code: 0,
        CodeError: 0,
        Field: '',
        Message: '',
        MessageList: []
    }
}

export default PostPagoPendiente