import { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";

const useObtenerMotivosBajaSuscripcion = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoadingMotivosBaja, setIsMotivosBaja] = useState(true);
    const [motivosBaja, setMotivosBaja] = useState(null);
    const [errorMotivosBaja, setErrorMotivosBaja] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        ApiService.get(`${REACT_APP_APIPAYWALL}Retencion/GetReasonsToUnsubscribe`)
          .then((data) => {
            if (data?.code === "0000") {
              setMotivosBaja(data.response);
              setIsMotivosBaja(false);
            } else {
              setError(true);
              setIsMotivosBaja(false);
              Datadog.trackCustomEventWarning( CustomEventsErrorDatadog.errorObtenerMotivosBajaSuscripcion, { response: data })
            }
          })
          .catch((error) => {
            setErrorMotivosBaja(error);
            setIsMotivosBaja(false);
            Datadog.trackCustomEventError( CustomEventsErrorDatadog.errorObtenerMotivosBajaSuscripcion, { error })
          });
      };
      fetchData();
    }, []);

    return [motivosBaja, errorMotivosBaja, isLoadingMotivosBaja];
};
export default useObtenerMotivosBajaSuscripcion;