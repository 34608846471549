import React, { useEffect } from 'react'
import { Text } from '@ln/common-ui-text';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import Divider from '../../../Components/Common/Atoms/Divider';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import { FormActivarSuscripcion } from '../../../Components/Features/MisSuscripciones/FormActivarSuscripcion';
import LoadingSpinnerWithData from '../../../Helpers/LoadingSpinnerWithData';
import { ErrorActivarSuscripcion } from '../../../Components/Features/MisSuscripciones/ErrorActivarSuscripcion';
import useRedimirPin from '../../../Hooks/useActivarPin';
import { readCookie } from '../../../Helpers/Cookies';
import { trackCustomEvent } from '../../../Helpers/Datadog';
import { StepsTrackeoRedimirPin } from '../../../Models/ActivarPinCodes';
import Url from '../../../Helpers/Url';

export const ActivarSuscripcion = () => {
  const pin = Url.GetSearchParam("pin")
  const {pinResponse, isLoading, error, handleRedimirPin} = useRedimirPin();
  const esSuscriptor = readCookie("Crm_id");
  const email = readCookie("usuarioemail");
  const referrerUrl = document.referrer;
  const payloadDD = {
    mail: readCookie('usuarioemail'),
    crmId: readCookie('Crm_id'),
    origen: referrerUrl ? "url" : "myaccount"
  };
  useEffect(() => {
    if(pin && !esSuscriptor){
      handleRedimirPin(pin)
    }
    if(!esSuscriptor){
      trackCustomEvent(StepsTrackeoRedimirPin[1], payloadDD);
    }
    if(esSuscriptor){
      trackCustomEvent(StepsTrackeoRedimirPin[10], payloadDD);
    }
  }, [])

  return (
    <>
      <section>
        <Breadcrumb firstItem="Mis suscripciones" text="Activar suscripción" href="#" />
        <TitleWeb text="Activar suscripción" />
      </section>

      <section className="container-fluid-streched">
        <LoadingSpinnerWithData
          isLoading={isLoading}
          title='Estamos activando tu suscripción'
          description='Aguarda un momento...'
        >
          <div className='container-streched'>
            <ErrorBoundary error={error}>
              {esSuscriptor ? (
                <ErrorActivarSuscripcion email={email} />
              ) : (
                <>
                  <div className='flex flex-column gap-16 arial'>
                    <Text as='h5' className='arial-bold text-18'>
                      Activa tu suscripción
                    </Text>
                    <Text as='p' className='text-14'>
                      <span className='arial-bold'>Para activar tu suscripción, ingresá el código que recibiste en tu correo.</span>{' '}
                      Tené en cuenta que tu suscripción quedará activa en la cuenta {email}
                    </Text>
                    <FormActivarSuscripcion redimirPin={handleRedimirPin} pinResponse={pinResponse} />
                  </div>
                  <Divider />
                  <ContactoDudasBanner />
                </>
              )}
            </ErrorBoundary>
          </div>
        </LoadingSpinnerWithData>
      </section>
    </>
  )
}

export default ActivarSuscripcion;