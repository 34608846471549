import React from 'react';
import Link from './Link';
import PropTypes from "prop-types";

import '../../../Statics/Scss/Components/Image.scss';

const Image = ({ src, alt, mod, href, withLazy }) => {

    const loading   = withLazy ? 'lazy' : undefined;
    const className = `com-image${` ${mod}` || ''}`;
    const image     = <img src={src} loading={loading} className={className} alt={alt || ''} />;

    return(
        <>
            { href ?
                <Link href={href} title={alt}>{image}</Link> :
                image
            }
        </>
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    mod: PropTypes.string,
    href: PropTypes.string,
    withLazy: PropTypes.string
};

export default Image;