const steps = {
    step_1: {
        number: 1,
        text: 'Suscripción',
    },
    step_2: {
        number: 2,
        text: 'Domicilio de entrega',
    },
    step_3: {
        number: 3,
        text: 'Confirmación',
    },
};

export default steps;
