import React from 'react';
import { useField } from 'formik';
import Select from '../Atoms/Form/Select';
import _helperStatusFormik from '../../../Helpers/StatusFormik'

const SelectFormik = (props) => {
    const [field, meta, helpers] = useField(props);
    return ( 
        <Select {...props} {...field} state={_helperStatusFormik(meta)}>
            {props.children}
        </Select> 
    )
}

export default SelectFormik;