import * as Yup from 'yup';

const MiClubFormCortoSchema = Yup.object().shape({
    credencial1: Yup.string()
        .matches('^[0-9]{6}$', 'Ingresá los primeros 6 números')
        .required('Campo requerido'),
    credencial3: Yup.string()
        .matches('^[0-9]{2}$', 'Ingresá los últimos 2 números')
        .required('Campo requerido'),
});

export default MiClubFormCortoSchema;