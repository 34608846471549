import { productos, planesNoAction } from "../Config/Planes";
import { days } from "../Config/Dates";
import estadoSuscripciones from "../Models/EstadoSuscripciones";
import { validacionesCambioPago, validacionesReclamar } from "../Config/Suscripciones";
import { configFilterSuscripciones } from "../Config/Suscripciones";

export function sacarFamilia(nombre) {
    if (!nombre) return '';
    if (nombre.indexOf("Familia ") >= 0) {
        nombre = nombre.split("Familia ")[1];
    };
    nombre = nombre.replace(/\/[^)]+[0-9 ]{4,}/, "").replace(/##[0-9]*## /, '').replace(', INDEFINIDO_OLD', '');
    return nombre;
};



export function findPlanForSuscription(suscripcion, planes) {
    if (suscripcion.Estado === estadoSuscripciones.FALTA_PAGO) {
        return '';
    }
    const digitalPlan = planes?.find(plan => plan.IdPlanDeVenta == suscripcion.IdPlanDeVenta);
    return digitalPlan;
};

export function showReadPdfFeature(suscription, plans) {
    const isActiveSuscription = suscription?.Estado === estadoSuscripciones.ACTIVA ||
        suscription?.Estado === estadoSuscripciones.BAJA_DIFERIDA;
    const pdfPP = suscription?.ProductosPremium?.some(product => product.Id === 3);
    const digitalPlan = plans?.some(plan => plan.IdPlanDeVenta === suscription.IdPlanDeVenta);

    return pdfPP && digitalPlan && isActiveSuscription;
}


export function getName(suscripcion, planes) {
    if (!suscripcion) {
        return '';
    };

    let name = "";
    const digitalPlan = findPlanForSuscription(suscripcion, planes);

    if (digitalPlan) {
        name = digitalPlan.NombreAmigable;
        if (suscripcion.EsGoogle) {
            name += ' (suscripto con Google)';
        } else if (suscripcion.EsApple) {
            name += ' (suscripto con Apple)';
        };
    } else {
        if (suscripcion.EsDiario) {
            // Calculo cuántos días de la semana tiene cargado:
            const arrayDiasSimple = suscripcion.DiasSemanaDiario.split(', ');
            if (arrayDiasSimple.length == 1) {
                name = 'Diario del ' + days[suscripcion.DiasSemanaDiario];
            } else {
                name = 'Diario LA NACION';
            };
        } else {
            name = suscripcion.Nombre;
        };
    };

    return sacarFamilia(name);
};

export function suscriptionHasDays(suscripcion) {
    return suscripcion.PlanDeVentaNombre && suscripcion.PlanDeVentaNombre.indexOf('##') !== -1;
};

//Queda comentado a partir de la implementacion de bonificados por eventos 
// export function isBonificado(suscripcion, planes) {
//     const digitalPlan = findPlanForSuscription(suscripcion, planes);
//     return digitalPlan && digitalPlan.AccesoBonificado;
// };

//esta funcion valida, si el id de la suscripcion corresponde a la de un evento bonificado, segun su ultimo evento. si no es asi, valida segun el acceso bonificado de su plan
export function isBonificado(evento, suscripcion, planes) {
    return evento?.SuscripcionId == suscripcion.Id ? evento?.IsBonificada : findPlanForSuscription(suscripcion, planes)?.AccesoBonificado;
}

export function isPinNYT(suscripcion, planes) {
    const digitalPlan = findPlanForSuscription(suscripcion, planes);
    return digitalPlan && digitalPlan.IncluyePinNewYorkTimes;
};

export function hasCredencial(suscripcion, planes) {
    const digitalPlan = findPlanForSuscription(suscripcion, planes);
    return digitalPlan && digitalPlan.TieneCredencial;
};

export function getTextoDinamico(suscripcion, digitalPlan, planes, evento) {
    let texto = [];
    for (let productoEnConfig in productos) {
        const producto = digitalPlan.IdsProductosPremium.find(item => item == productos[productoEnConfig].Id);
        if (producto) {
            texto.push(productos[productoEnConfig].Texto);
        };
    };

    if (hasCredencial(suscripcion, planes) && !isBonificado(evento, suscripcion, planes)) {
        texto.push("Credencial Club LA NACION");
    };

    if (isPinNYT(suscripcion, planes)) {
        texto.push("Acceso Digital a The New York Times");
    };

    return (texto.join(" + "));
};

export function getBajada(suscripcion, planes, evento) {
    if (!suscripcion) return '';
    let name = '';
    const digitalPlan = findPlanForSuscription(suscripcion, planes);

    if (digitalPlan) {
        name = getTextoDinamico(suscripcion, digitalPlan, planes, evento);
    } else {
        if (!(suscriptionHasDays(suscripcion))) {
            name = suscripcion.PlanDeVentaNombre;
        };
    };

    return name;
};

export function isStatus(suscripcion, status) {
    if (!status || !suscripcion) return false;
    return suscripcion.Estado == estadoSuscripciones[status];
};

export function esAptoUpselling(suscripcion, planes) {
    return isStatus(suscripcion, 'ACTIVA') && findPlanForSuscription(suscripcion, planes) && !isExcludedFromUpselling(suscripcion);
};

export function matchPlanNoAction(suscripcion) {
    const plan = planesNoAction.find(item => item.id == suscripcion.IdPlanDeVenta);
    return plan;
};

export function canPago(suscripcion, eventoBonificado, planes) {
    if (!suscripcion || suscripcion.EsGoogle || suscripcion.EsApple) {
        return false;
    };

    let cambioPago = false;

    if (validacionesCambioPago.includes(suscripcion.Estado)) {
        cambioPago = true;
    };

    if (matchPlanNoAction(suscripcion)) {
        return false;
    };

    if (isBonificado(eventoBonificado, suscripcion, planes)) {
        cambioPago = false;
    };

    return cambioPago;
};

export function isSuspencionAnulada(suscripcion) {
    if (suscripcion.SituacionSuspensionVacaciones && suscripcion.SituacionSuspensionVacaciones.TipoDeGestionDeVacaciones.toUpperCase().indexOf(configFilterSuscripciones.anuladoFlag) !== -1) {
        return true;
    };
    return false;
};

export function isSuspendida(suscripcion) {
    if (!suscripcion) return false;
    return ((suscripcion.SituacionSuspensionVacaciones && !isSuspencionAnulada(suscripcion)) || suscripcion.Estado == estadoSuscripciones.SUSP_VACACIONES);
};

export function getFecha(suscripcion, tipoFecha) {
    if (!suscripcion) return '';
    if (suscripcion.SituacionSuspensionVacaciones) {
        return suscripcion.SituacionSuspensionVacaciones[tipoFecha];
    } else if (suscripcion.Estado == estadoSuscripciones.SUSP_VACACIONES) {
        return suscripcion[tipoFecha];
    };
    return '';
};

export function isBlocked(suscripcion) {
    if (isSuspendida(suscripcion)) {
        let d = new Date(getFecha(suscripcion, "FechaDesde"));
        d.setDate(d.getDate() - configFilterSuscripciones.daysToBlock);
        return d <= new Date();
    };
    return false;
};

export function canReclamarEditorial(suscripcion, planes) {
    if (!suscripcion) return false;
    if (findPlanForSuscription(suscripcion, planes)) return false;

    return validacionesReclamar.includes(suscripcion.Estado);
};

export function createModifiableSuscriptionArray(array) {
    return array.map(item => ({
        ...item.Suscripcion,
        EsDomicilioModificable: item.EsDomicilioModificable
    }));
};

export function isExcludedFromUpselling(suscripcion) {
    return configFilterSuscripciones.excludedIds.includes(suscripcion.IdPlanDeVenta)
}
