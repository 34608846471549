import React, { useContext, useReducer } from 'react'
import { initialState, storeReducer } from './storeReducer';

const StoreContext = React.createContext(false)

const StoreProvider = ({children}) =>{

    const [Store, dispatch] = useReducer(storeReducer, initialState);

    return (
        <StoreContext.Provider value={ [Store, dispatch] }>
            {children}
        </StoreContext.Provider>
    )
}

const useStore = ()=> useContext(StoreContext)[0];
const useDispatch = ()=> useContext(StoreContext)[1];



export { StoreContext , StoreProvider, useStore, useDispatch  };