import React from 'react'
import PropTypes from 'prop-types'
import Embed from '../../../Models/Embed'

const EmbedHide = props => {
    return (
        <>{!Embed && props.children}</>
    )
}

EmbedHide.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export default EmbedHide

