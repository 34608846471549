import React, { useEffect, useState } from 'react';
import Toast from '../../Components/Common/Modules/Toast';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useGuardarCobranzaRechazo from '../../Hooks/useGuardarCobranzaRechazo';
import WondersoftIframe from './WondersoftIframe';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import useGuardarRtaWondersoft from '../../Hooks/useGuardarRtaWondersoft';
import { getPrepagoModel } from '../../Components/Features/Pagos/getPrePagoModel';
import usePrePagoPendiente from '../../Hooks/usePrePagoPendiente';
import useWondersoftCobroMinimoValidacionTarjeta from '../../Hooks/useWondersoftCobroMinimoValidacionTarjeta';
import CheckInput from '../../Components/Common/Atoms/Form/CheckInput';

const IframeTarjeta = (props) => {
    const [dataIframe, error, isLoading, getUrlPagosPendientes] = useGuardarCobranzaRechazo(props.deudas, props.tarjeta, props.idAbandonoCobranza, props.idRechazoCanalDeContacto); //devuelve url iframe pagos pendientes
    const [dataWs, errorWsCvvForm, isLoadingWs, getCvvForm] = useWondersoftCobroMinimoValidacionTarjeta(); //url para iframe cambiar forma de pago
    const [dataRta, errorRtaWs, isLoadingRtaWs, guardarRtaWs] = useGuardarRtaWondersoft();
    const deudasComplete = props.deudasCompletas;
    const payloadCambiarFormaDePago = props.payloadCambiarFormaDePago;
    const suscripcionesFormDePago = props.suscripciones;
    const idAbandonoCobranza = props.idAbandonoCobranza;
    const origenCambiarFormaDePago = props.cambioFormaPagoTarjeta;
    const iframeHeight = props.iframeHeight;
    const [errorPagoWS, setErrorPagoWS] = useState(false);
    const [showToastErrorPagoWS, setShowToastErrorPagoWS] = useState(false);
    const [mensaje, setMensaje] = useState();
    const data = { ...dataIframe, deudasComplete, setErrorPagoWS, setMensaje, idAbandonoCobranza, guardarRtaWs };
    const sourceCatacambiarFormaDePagoExistente = { ...dataWs, origenCambiarFormaDePago, suscripcionesFormDePago, payloadCambiarFormaDePago };
    const [errorPrePago, savePrePago] = usePrePagoPendiente();
    const prePago = !origenCambiarFormaDePago ? getPrepagoModel(props?.deudas, props?.tarjeta, idAbandonoCobranza) : null;
    const [isLoadingCic, setIsLoadingCIC] = useState();
    const [checkCIC, setCheckCIC] = useState();
    const ocultarCheckCIC = props.ocultarCheckCIC;
    const setErrorValidacionTarjeta = props.setErrorValidacionTarjeta;

    useEffect(() => {
        if (origenCambiarFormaDePago) {
            setIsLoadingCIC(false);
            getCvvForm(payloadCambiarFormaDePago);
        } else {
            iniciarPagos();
        }
    }, [errorPagoWS]);

    const iniciarPagos = () => {
        if (!errorPagoWS) {
            props.showImport(true);
            savePrePago(prePago);
            getUrlPagosPendientes(props.deudas, props.tarjeta, props.idAbandonoCobranza, props.idRechazoCanalDeContacto);
        } else {
            props.showImport(false);
            setShowToastErrorPagoWS(true);
            setErrorPagoWS(false);
        }
    };

    const handleCheck = () => {
        setCheckCIC(!checkCIC);
    };

    useEffect(() => {
        if(errorWsCvvForm){
            setErrorValidacionTarjeta(errorWsCvvForm)
        }
    }, [errorWsCvvForm])    

    return (
        <section>
            <LoadingSpinner withBody isLoading={isLoading || isLoadingWs || isLoadingCic || isLoadingRtaWs}>
                <ErrorBoundary error={error}>
                    {showToastErrorPagoWS && (
                        <Toast
                            status={'warning'}
                            title="¡Ups!"
                            type={'toast'}
                            value={showToastErrorPagoWS}
                            closeAuto={true}
                            close
                            className="--roboto"
                            changeValue={() => setShowToastErrorPagoWS(false)}
                        >
                            {mensaje}
                        </Toast>
                    )}
                    {!errorPagoWS && (
                        <>
                            {error || errorWsCvvForm ? ( //si fallan los servicios para obtener URL de ws
                                error ? (
                                    <Toast status="warning" title={error} />
                                ) : (
                                    <Toast status="warning" title={typeof errorWsCvvForm === 'string' ? errorWsCvvForm : "Ha ocurrido un error. Por favor, intente más tarde"} />
                                )
                            ) : (
                                <>
                                    <WondersoftIframe
                                        dataWondersoft={origenCambiarFormaDePago ? sourceCatacambiarFormaDePagoExistente : data}
                                        setIsLoadingCIC={setIsLoadingCIC}
                                        iframeHeight={iframeHeight}
                                    />
                                    {!origenCambiarFormaDePago && !ocultarCheckCIC && (
                                        <div className="--mt-16">
                                            <CheckInput onChange={handleCheck} checked={checkCIC}>
                                                Quiero guardar este medio de pago para mi suscripción
                                            </CheckInput>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </ErrorBoundary>
            </LoadingSpinner>
        </section>
    );
};

export default IframeTarjeta;
