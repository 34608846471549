import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const propTypes = {
	/**
	 * Días asociados.
	 */
	days: PropTypes.array.isRequired,
	/**
	 * Título asociado.
	 */
	title: PropTypes.string,
	/**
	 * Clases adicionales
	 */
	className: PropTypes.string,
};

const Week = ({ days, title, className }) => {
	const classes = classNames('com-week', className);

	const render_day = day => {
		return day.substring(0, 1);
	};

	return (
		<div className={classes} >
			{title && <h5 className="com-week__title --sixxs --mb-8">{title}</h5>}
			<ul className="com-week__list">
				{days.map((day, key) => {
					return (
						<li
							key={key}
							className={`com-week__list__day ${day.disabled ? 'state--disabled' : ''
								}`}>
							{render_day(day.day)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

Week.propTypes = propTypes;

export default Week;
