import React from 'react';
import OnClickOutElement from '../../../Helpers/OnClickOutElement';

import Title from '../Atoms/Title';
import ModalPortalBG from '../Atoms/ModalPortalBG';
import Button from '../Modules/Button';

import '../../../Statics/Scss/Organism/Modal.scss';

const Modal = ({ closeModal, title, children }) => {
    return (
        <ModalPortalBG>
            <div className="contenedor">
                <div className="modal" ref={OnClickOutElement(closeModal)}>
                    <div className="modal__header">
                        {title && (
                            <div className="mod-title">
                                <Title tag="h1" size="--xl" mod="--sueca">
                                    {title}
                                </Title>
                                <Button mod="tertiary" size="small" onClick={closeModal} icon="close" aria-label="Cerrar" />
                            </div>
                        )}
                    </div>

                    <div className="modal__body">{children}</div>
                </div>
            </div>
        </ModalPortalBG>
    );
};

export default Modal;
