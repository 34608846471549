import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import "../../../Statics/Scss/Components/Tooltip.scss";

import Icon from "./Icon";

const propTypes = {
  /**
   * Flag, ¿se puede cerrar?
   */
  canBeClosed: PropTypes.bool,
  /**
   * Color asociado.
   */
  color: PropTypes.oneOf(["default", "black", "white"]),
  /**
   * Posición del tooltip.
   */
  position: PropTypes.oneOf(["top", "top-button", "right", "bottom", "left"]),
  /**
   * Handler para el evento click
   * al cerrar.
   */
  handlerClick: PropTypes.func,
};

const defaultProps = {
  position: "top",
  color: "default",
  handlerClick: () => {},
};

const Tooltip = ({
  children,
  className,
  canBeClosed,
  color,
  position,
  handlerClick,
  ...r
}) => {
  const classes = classNames(
    "tooltip --arial --fivexs --regular",
    className,
    `--${color}`,
    `--${position}`,
    {
      "--a-left": canBeClosed,
    }
  );

  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    setShowTooltip(true);
  }, [showTooltip]);

  return (
    <>
      {children && showTooltip && (
        <div className={classes} {...r}>
          {children}
          {canBeClosed && (
            <Icon
              name="close"
              size="m"
              onClick={() => setShowTooltip(!showTooltip)}
            />
          )}
        </div>
      )}
    </>
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
