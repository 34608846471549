import React from 'react'
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types'

import Label from '../../Common/Atoms/Form/Label'
import InputGroup from '../../Common/Atoms/Form/InputGroup'
import FormRow from '../../Common/Atoms/Form/FormRow';
import FormCol from '../../Common/Atoms/Form/FormCol';
import MiClubFormIntermedioSchema from '../../../Validations/MiClubFormIntermedioSchema';
import Button from '../../Common/Modules/Button';
import CredencialModel from '../../../Models/Credencial'
import InputFormik from '../../Common/Formik/Input';
import MiClubFormCortoSchema from '../../../Validations/MiClubFormCortoSchema';



const FormularioCorto = (props) => {
    const { EsIntermedio , Data} = props;


    return (
        <Formik
            validationSchema={EsIntermedio ? MiClubFormIntermedioSchema : MiClubFormCortoSchema}
            initialValues={{
                credencial1: CredencialModel.PrimerosTarjeta,
                credencial2: '',//solo se usa para intermedio
                credencialCorto : 'XXXXXXXX', //solo se usa para corto,
                credencial3: '',
            }}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true)
                let crm = '';
                let estado = '';
                if (EsIntermedio){
                    crm = Data.response.find(x=> x.nroTarjeta == `${CredencialModel.PrimerosTarjeta}${values.credencial2}${values.credencial3}`);
                    estado = 2;
                }
                else {
                    crm = Data.response.find(x=> x.nroTarjeta.substr(x.nroTarjeta.length - 2) == values.credencial3);
                    estado = 1;
                }

                if (!crm){
                    props.OnError()
                    return Promise.resolve();
                }

                let payload = {
                    crmId: crm.CrmId,
                    estado,
                };
                console.log('payload', payload)
                //probar cuando funcione formik (y borrar este comentario)
                await props.OnVincular(payload)
                setSubmitting(false);
            }}
        >
            {(props) => (
                <Form noValidate>
                    <FormRow>
                        <InputGroup>
                            <FormCol>
                                <Label text={"Número de credencial Club LA NACION"} />
                                <InputGroup className="grid-for-crencial">
                                    <InputFormik name="credencial1" type="text" disabled />
                                    {EsIntermedio ?
                                        <InputFormik name="credencial2" type="text" />
                                        :
                                        <InputFormik name="credencialCorto" type="text" disabled />
                                    }
                                    <InputFormik name="credencial3" type="text" />
                                </InputGroup>
                                <br></br>
                                <Label text={"Completá los dos últimos números de tu credencial"} />
                            </FormCol>
                        </InputGroup>
                    </FormRow>
                    <FormRow mod="--btn-groups">
                        <Button className="--block-mobile" type="submit" label="VINCULAR CREDENCIAL"/>
                    </FormRow>
                </Form>
            )}
        </Formik>

    )
}

FormularioCorto.propTypes = {
    OnVincular: PropTypes.func.isRequired,
    OnError : PropTypes.func.isRequired,
    EsIntermedio : PropTypes.bool,
    Data : PropTypes.object,
}

export default FormularioCorto
