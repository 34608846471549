import { useState } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";

const useCrearCliente = () => {
  let { REACT_APP_APIRECLAMOS } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  
 const crearCliente = async (data) => {
  const pathUrl = `${REACT_APP_APIRECLAMOS}credenciales/CrearCliente`;
  setIsLoading(true);
  return ApiService.post(pathUrl, data)
    .then((response) => {
      if (response.Code == 0) {
        Datadog.trackCustomEvent(CustomEventsDatadog.crearUsuario);
        setData(response);
        setIsLoading(false);
      } else {
        Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCrearUsuario);
        setIsLoading(false);
        setError(true);
      }

      return Promise.resolve(response);
    })
    .catch((error) => {
      setIsLoading(false);
      setError(true);
      return Promise.reject(error);
    });
};

  return [data, error, isLoading, crearCliente];
};

export default useCrearCliente;
