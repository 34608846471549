import { BuildBearerAccessTokenAsync, GetAccessTokenValidatedAsync, getIdTokenCookie } from '@ln/user.client.libs';
import axios from 'axios'

const REQUEST_DELAY = 4000;
const globalConfig = {
    retry: 1,
    retryDelay: 2000,
};
const axiosClient = axios.create(globalConfig);
let isRefreshing = false;

axiosClient.interceptors.request.use(
    async (requestConfig) => {
        if (isRefreshing) {
            await delayRetryRequest(REQUEST_DELAY);
            requestConfig = updateRequestHeaders(requestConfig);
        }
        return requestConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { config } = error;
        if (error.response.status === 401 && config && config.retry > 0) {
            if (!isRefreshing) {
                isRefreshing = true;
                await GetAccessTokenValidatedAsync();
                isRefreshing = false;
            }
            await delayRetryRequest(REQUEST_DELAY);
            const updatedRequest = await updateRequestHeaders(config);
            return axiosClient(updatedRequest);
        } else {
            return Promise.reject(error)
        }
    }
);

const delayRetryRequest = (delay) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, delay || 1000)
    })
}

const updateRequestHeaders = async (requestConfig) => {
    requestConfig.retry -= 1
    requestConfig.headers.Authorization = await BuildBearerAccessTokenAsync();
    requestConfig.headers["X-Token"] = getIdTokenCookie();
    return requestConfig
}


export { axiosClient };