import React from 'react'
import { Accordion as AccordionLib } from '@ln/common-ui-accordion';
import classNames from 'classnames';

import '@ln/common-ui-accordion/index.css'

export const Accordion = ({ header, children, ...props }) => {
  const bodyClassName = classNames({ 'pt-24': header })
  return (
    <AccordionLib 
      className='border border-all border-thin border-light-100 rounded-4 p-16 flex flex-column'
      {...props}
    >
      {header && <AccordionLib.Header>{header}</AccordionLib.Header>}
      <AccordionLib.Body>
        <div className={bodyClassName}>
          {children}
        </div>
      </AccordionLib.Body>
    </AccordionLib>
  )
}
