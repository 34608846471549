import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerSuscripcionesDigitalesAnaliticsOnDemand = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [suscripciones, setSuscripciones] = useState(null);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);
        ApiService.post(`${REACT_APP_APIPAYWALL}SuscripcionV1/ObtenerSuscripcionesDigitalesAnalytics`)
            .then((data) => {
                if (data.code === '0000' && data.response) {
                    setSuscripciones(data.response);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
            });
    };


    return [suscripciones, error, isLoading, fetchData];
};

export default useObtenerSuscripcionesDigitalesAnaliticsOnDemand;
