import React from 'react';
import { useHistory } from 'react-router';
import Divider from '../../Common/Atoms/Divider';
import ContactoDudasBanner from '../../Common/Banners/ContactoDudas';
import Button from '../../Common/Modules/Button';
import Toast from '../../Common/Modules/Toast';
import Text from '../../Common/Text/Text';
import PropTypes from 'prop-types';
import ErrorMisCredenciales from '../../Common/Banners/ErrorMisCredenciales';
import PathsConfig from '../../../Config/RoutePaths';

const propTypes = {
    success: PropTypes.bool,
    credencial: PropTypes.object,
};

const ConfirmacionBajaCredencial = ({ success, credencial }) => {
    const history = useHistory();
    return (
        <div>
            {success ? (
                <>
                    <Toast closeAuto={3000} status="success" title="¡Credencial dada de baja con éxito!"></Toast>

                    <Text tag="p" size="--s" font="arial" weight="bold" className="text-title-baja">
                        Conocé el detalle de la credencial dada de baja:
                    </Text>

                    <Text tag="p" size="2xs" font="arial" className="baja-credencial-detalles">
                        <span>{credencial.CategoriaCredencial}</span>
                        <span>{credencial.NumeroCredencial}</span>
                        <span>{credencial.NombreTitular}</span>
                    </Text>

                    <Button
                        label="Ir a mis credenciales"
                        className="button-confirmacion"
                        size="small"
                        onClick={() => {
                            history.push({
                                pathname: PathsConfig.MisCredenciales,
                            });
                        }}
                    />
                </>
            ) : (
                <>
                    <ErrorMisCredenciales title={'No pudimos dar de baja la credencial'} />

                    <Divider />

                    <ContactoDudasBanner />
                </>
            )}
        </div>
    );
};

ConfirmacionBajaCredencial.propTypes = propTypes;

export default ConfirmacionBajaCredencial;
