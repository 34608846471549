import React from 'react';
import useDescargarLibreDeuda from '../../../Hooks/useDescargarLibreDeuda';
import Button from '../../Common/Modules/Button';
import FormRow from '../../Common/Atoms/Form/FormRow';
import Datadog from '../../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../../Config/Datadog';

const ButtonDescargarLibreDeuda = ({ setIsLoadingPdf, setErrorDescarga }) => {
    const [descargar, errorDescargar, isLoadingDescargar] = useDescargarLibreDeuda();

    const descargaLibreDeuda = () => {
        setIsLoadingPdf(true);
        descargar().then((resp) => {
            Datadog.trackCustomEvent(CustomEventsDatadog.descargarLibreDeuda)
            setIsLoadingPdf(false);
        }).catch(() => {
            setIsLoadingPdf(false);
            setErrorDescarga(true);
          });
    }
    
    return (
            <div onClick={descargaLibreDeuda}>
                <FormRow mod="--btn-groups">
                    <Button
                        text="Descargar"
                        size="small"
                        iconSize="s"
                        tag="a"
                        target="_blank"
                        className="state--mobile-w100"
                        disabled={isLoadingDescargar}
                        label="Ver libre de deuda"
                    // href={`${process.env.REACT_APP_APIPAYWALL}Mobile/DescargarLibreDeuda?token=${token}`}
                    />
                </FormRow>
            </div>
    );
};

export default ButtonDescargarLibreDeuda;