import { hotjar } from "react-hotjar";

const HotjarRun = () => {
  return hotjar.initialize(`${process.env.REACT_APP_IDHOTJAR}`, `${process.env.REACT_APP_HOTJARSNIPPETVERSION}`);
};

const HotjarTrigger = (label) => {
    window.hj && window.hj('trigger', label);
};

export default { HotjarRun, HotjarTrigger };
