import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from "react-router-dom";
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import Paragraph from '../../Components/Common/Atoms/Paragraph';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';
import Label from '../../Components/Common/Atoms/Form/Label';
import FormCol from '../../Components/Common/Atoms/Form/FormCol';
import Button from '../../Components/Common/Modules/Button';
import useActualizarClaveMiCuenta from '../../Hooks/useActualizarClaveMiCuenta';
import ReCAPTCHA from 'react-google-recaptcha';
import InputFormik from '../../Components/Common/Formik/Input';
import CambiarContrasenaSchema from '../../Validations/CambiarContrasenaSchema';
import keys from '../../Config/Keys';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import Logout from '../../Handlers/_Logout';
import TitleWeb from '../../Components/Common/Web/Title';
import PathsConfig from '../../Config/RoutePaths';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';
import { useStore } from '../../Store/storeProvider';

const CambiarPassword = () => {
    const history = useHistory();
	const [data, error, isLoading, sendData] = useActualizarClaveMiCuenta(null);
	const SITE_KEY = keys.recaptchaId;
	const _reCaptchaRef = React.createRef();
	const [viejaPassword, setViejaPassword] = useState(true);
	const [nuevaPassword, setNuevaPassword] = useState(true);
	const [repitePassword, setRepitePassword] = useState(true);
	const { HandleSwitchsStates } = useStore();

	useEffect(() => {
		if (error?.code == 0) Logout.Logout();
	}, [error]);

	useEffect(() => {
		if(funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.CambiarContrasenia)){
			history.push({
				pathname: PathsConfig.MiUsuario,
				params: {}
			})
		}
	}, [HandleSwitchsStates]);

	const cancelarCambiarPassword = () => {
		history.push({
			pathname: PathsConfig.MiUsuario,
			params: {},
			state: {
				succes: true
			}
		})
	}

	return (
		<>
			<div className="lay-strechted">
			<Breadcrumb firstItem={"Mi cuenta"} href={"#"} text={"Mi usuario"} />

			<TitleWeb text="Cambiar contraseña" />
			</div>
			<div className="container-streched">
				{data?.code == 0 && (
					<Toast
						status="success"
						title="Ha actualizado su contraseña correctamente."
					/>
				)}

				{error?.code == -1 && (
					<Toast status="warning" title="La contraseña actual no es correcta." />
				)}

				{error?.message &&  (
					<Toast status="warning" title={error.message} />
				)}
				<LoadingSpinner isLoading={isLoading}>
					<Paragraph size="--twoxs" mod="--arial --mb-32">
						Tu contraseña debe tener 8 caracteres, incluyendo al
						menos un número y una letra.
					</Paragraph>
					<Formik
						validationSchema={CambiarContrasenaSchema}
						initialValues={{
							viejaContrasena: '',
							nuevaContrasena: '',
							repiteContrasena: '',
						}}
						onSubmit={async (
							values,
							{ setSubmitting, setFieldError },
						) => {
							setSubmitting(true);

							let payload = {
								ClaveVieja: values.viejaContrasena,
								ClaveNueva: values.nuevaContrasena,
							};

							_reCaptchaRef.current.executeAsync().then(value => {
								console.log(
									'executeAsync promise - Captcha value:',
									value,
								);
								payload.Recaptcha = value;

								sendData(payload);
							});
							setSubmitting(false);
						}}>
						<Form noValidate>
							<fieldset>
								<FormRow>
									<FormCol>
										<Label text={'Contraseña actual'}/>
										<InputFormik
											name="viejaContrasena"
											type={
												viejaPassword
													? 'password'
													: 'input'
											}
											placeholder="●●●●●●●"
											rightIcon={ viejaPassword ? 'icon-eye' : 'icon-eye-off' }
											hideStatusIcons={true}
											funcIconDer={() =>
												setViejaPassword(
													!viejaPassword,
												)
											}
										/>
									</FormCol>
								</FormRow>
								<FormRow>
									<FormCol>
										<Label text={'Nueva contraseña'}/>
										<InputFormik
											name="nuevaContrasena"
											type={
												nuevaPassword
													? 'password'
													: 'input'
											}
											placeholder="●●●●●●●"
											rightIcon=  { nuevaPassword ? 'icon-eye' : 'icon-eye-off' } 
											hideStatusIcons={true}
											funcIconDer={() =>
												setNuevaPassword(
													!nuevaPassword,
												)
											}
										/>
									</FormCol>
								</FormRow>
								<FormRow>
									<FormCol>
										<Label
											text={
												'Confirmar nueva contraseña'
											}
										/>
										<InputFormik
											name="repiteContrasena"
											type={
												repitePassword
													? 'password'
													: 'input'
											}
											hideStatusIcons={true}
											placeholder="●●●●●●●"
											aria-label="Confirmar nueva contraseña"
											rightIcon={ repitePassword ? 'icon-eye' : 'icon-eye-off' } 
											funcIconDer={() =>
												setRepitePassword(
													!repitePassword,
												)
											}
										/>
									</FormCol>
								</FormRow>
								<ReCAPTCHA
									style={{ display: 'inline-block' }}
									theme="dark"
									size="invisible"
									ref={_reCaptchaRef}
									sitekey={SITE_KEY}
								/>
								<FormRow mod="--btn-groups">
									<Button
										type="submit"
										aria-label="Guardar contraseña"
										label="GUARDAR CONTRASEÑA"
										mod="state--mobile-w100"
										iconSize="--threexs"
									/>
									<Button
										onClick={cancelarCambiarPassword}
										aria-label="Cancelar"
										label="CANCELAR"
										mod="state--mobile-w100 --mb-0 --secondary"
										iconSize="--threexs"
									/>
								</FormRow>
							</fieldset>
						</Form>
					</Formik>
				</LoadingSpinner>
			</div>

			{/*
            tu contraseña debe tener 8 caracteres, incluyendo al menuso un numero y una letra
            <Bloque contraseña actual label + input />
            <Bloque nueva contraseña label + input />
            <Bloque repetir nueva contraseña label + input />
            <Bloque captcha />
            <Bloque boton guardar boton cancelar />
            */}
		</>
	);
};

export default CambiarPassword;
