import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import Text from '../../Components/Common/Text/Text';
import Button from '../../Components/Common/Modules/Button';
import PathsConfig from '../../Config/RoutePaths';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';

function UpsellingConfirmado(props) {
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = useState({ nuevoPlan: props.location.state ? props.location.state.nombreNuevoPlan : null });
    const [isLoading, setIsLoadingState] = useState(true);
    //TODO: Por ahora lo dejo asi, cuando se integre lo de nacho de mejora tu plan ver si esto hay que ajustarlo
    const breadcrumbText = location?.pathname?.includes("mejora-tu-plan") ? "Mejora tu plan" : `Cambiando a ${state.nuevoPlan}`;

    //setState({
    //    nuevoPlan: nuevoPlan,
    //});
    useEffect(() => {
        if (!state.nuevoPlan) {
            handleClick(PathsConfig.MiUsuario);
            return;
        }
        setIsLoadingState(false);
        //return () => { } - retorno una funcion que react ejecuta al desmontar el componente = es el equivalente a componentdidunmount
    }, []);

    function handleClick(destino) {
        setIsLoadingState(true);
        history.push({ pathname: destino });
    }
    const isBlack = state.nuevoPlan === "Black Familiar";
    return (
        <>
            <div className="lay-strechted">
                <Breadcrumb firstItem={"Mis suscripciones"} href={"#"} text={breadcrumbText} />
                <TitleWeb text={state.nuevoPlan} />
            </div>
            <section className={isBlack ? "container-fluid-streched" : "container-streched"}>
                <LoadingSpinner isLoading={isLoading}>
                    <div className="upselling">
                        <Text className="--sueca" size="--m">
                            Bienvenido a <strong>{state.nuevoPlan}</strong>
                        </Text>

                        <div className="proximos-pasos">
                            <Text tag="p" className="--mt-16 --mb-24" size="2xs">
                                Desde el menú Mis Credenciales vas a poder asignar las credenciales de <strong>Club LA NACION</strong>. <br />
                                <br />
                                Con el número de credencial de <strong>CLUB LA NACION</strong> el invitado ya podrá navegar en LANACION.com{' '}
                                <strong>sin límites</strong>. Una vez que hayas dado de alta la credencial, el invitado debe vincular la misma a su cuenta{' '}
                                de LA NACION desde el menú Vincular Credencial.
                            </Text>

                            <div className="flex ai-center gap-16">
                                <FormRow mod="--btn-groups">
                                    <Button
                                        tag="input"
                                        onClick={() => handleClick('mis-credenciales')}
                                        value="IR A MIS CREDENCIALES"
                                        type="submit"
                                        mobileW100={true}
                                    />
                                    <Button
                                        tag="input"
                                        mod="secondary"
                                        onClick={() => handleClick('mi-usuario')}
                                        value="IR A MI USUARIO"
                                        type="submit"
                                        mobileW100={true}
                                    />
                                </FormRow>
                            </div>
                        </div>
                    </div>
                </LoadingSpinner>
            </section>
        </>
    );
}

export default UpsellingConfirmado;
