import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './DrawerAccountContent.scss';
import { Button } from '@ln/experiencias-ui-button';
import { Drawer, useDrawer } from '@ln/common-ui-drawer';
import { Icon } from '@ln/common-ui-icon';
import { Avatar } from '@ln/experiencias-ui-avatar';
import { Logout, LeftArrow, LightBulb, Newsletter, SubscriberAttention, DigitalSubscriber as Crow, Padlock as Lock } from '@ln/experiencias-ui-assets';
import { Carditem } from '@ln/experiencias-ui-carditem';
import { Text } from '@ln/common-ui-text';
import Cookies from 'js-cookie';
import { SubscriberAttentions } from '../../../../Config/MenuSubscriberAttentions';
import Analytics from '../../../../Helpers/Analytics';
import { DRAWER } from '../../../../Models/Drawers';
import getProductoPremium from '../../../../Models/ProductoPremium';
import { readCookie } from '../../../../Helpers/Cookies';
import { userConSuscripcion, userSinSuscripcion } from '../../../../Helpers/HeaderUserProps';
import { useStore } from '../../../../Store/storeProvider';
import { obtenerIniciales } from '../../../../Helpers/String';

export const DrawerAccountContent = ({
    handleLogout,
    setShowModalSteps,
    visibleSubscriberAttentions,
    toggleVisible,
}) => {
    const drawerId = DRAWER.ACCOUNT;
    const usuarioEmail = Cookies.get('usuarioemail');
    const [userSuscriptionProps, setUserSuscriptionProps] = useState(userSinSuscripcion);
    const [userIniciales, setUserIniciales] = useState('');
    const usuarioNombre = Cookies.get('usuario_detalle_nombre');
    const usuarioApellido = Cookies.get('usuario_detalle_apellido');
    const { toggleDrawer } = useDrawer({ id: drawerId })
    const { actualizarAvatar } = useStore();
    const redirect = (e, link) => {
        e.preventDefault();
        Analytics.trackClickBtnSuscribirme('my_account','click_suscribirme_avatar','suscribirme');
        window.location.href = link;
    };

    const avatars = [
        {
            content: 'Tutorial',
            variant: 'default',
            type: 'link',
            showArrow: true,
            icon: <LightBulb />,
            onClick: () => {
                toggleDrawer()
                setShowModalSteps(true)
            },
        },
        {
            content: 'Newsletters',
            variant: 'default',
            type: 'link',
            showArrow: true,
            icon: <Newsletter />,
            href: 'https://newsletter.lanacion.com.ar/',
            target: '_blank',
        },
        {
            content: 'Atención al suscriptor',
            description: '¿Cómo podemos ayudarte?',
            variant: 'highlight',
            type: 'link',
            showArrow: true,
            icon: <SubscriberAttention />,
            onClick: toggleVisible,
        },
    ];

    useEffect(() => {
        if (getProductoPremium(readCookie('ProductoPremiumId'))) {
            setUserSuscriptionProps(userConSuscripcion);
        }
        setUserIniciales(obtenerIniciales(usuarioNombre, usuarioApellido, usuarioEmail))
    }, [actualizarAvatar])

    const hasSuscription = userSuscriptionProps.variant.includes('con')

    const Body = () => {
        if (visibleSubscriberAttentions)
            return (
                <>
                    <div className="prumo prumo-medium text-28 w-100">
                        <button onClick={toggleVisible} className="flex ai-center gap-16 bg-transparent">
                            <Icon size={24}>
                                <LeftArrow />
                            </Icon>
                            <Text as="h2" className="">
                                Atención al suscriptor
                            </Text>
                        </button>
                    </div>
                    {SubscriberAttentions.map((item, i) => (
                        <Carditem key={`subscriber-item-${i}`} showArrow {...item}></Carditem>
                    ))}
                </>
            );
        return (
            <>
                <Avatar className="w-100 relative">
                    <div className="flex flex-column ai-center">
                        <Avatar.UserImage
                            variant={userSuscriptionProps.variant}
                            size={96}
                            className="uppercase border border-all border-thin border-light-100 --rounded-circle"
                        >
                            {userIniciales}
                        </Avatar.UserImage>
                        <Avatar.Badge
                            className="absolute z-1 border border-all border-100 border-thin rounded-24 border-neutral-light-50"
                            suscriber={hasSuscription}
                            icon={hasSuscription ? <Crow /> : <Lock />}
                            iconProps={{className: classNames("bg-orange-400 rounded-circle content-box", hasSuscription ? "bg-orange-400" : "bg-neutral-light-200")}}
                        >
                            {userSuscriptionProps.badge}
                        </Avatar.Badge>
                    </div>
                    <Avatar.Email>{usuarioEmail ? usuarioEmail : ''}</Avatar.Email>
                </Avatar>
                <div className="flex jc-center w-100">
                    <Button
                        className="--full-width"
                        title={userSuscriptionProps.buttonText.charAt(0) + userSuscriptionProps.buttonText.slice(1).toLowerCase()}
                        variant={userSuscriptionProps.variantButton}
                        onClick={(e) => {
                            toggleDrawer()
                            redirect(e, userSuscriptionProps.link)
                        }}
                    >
                        {!hasSuscription && <div className='w-24 p-4'><Crow /></div>}
                        {userSuscriptionProps.buttonText}
                    </Button>
                </div>
                {avatars.map((item, j) => (
                    <Carditem showArrow key={`avatar-item-${j}`} {...item}></Carditem>
                ))}
            </>
        );
    };

    return (
        <Drawer
            id={drawerId}
            position={'right'}
            className="h-100dvh h-100vh max-w-520_md bg-neutral-light-1 gap-16 p-16 p-24_md p-32_lg shadow-down-md transition-regular"
            overlayClasses="z-1000 left-0"
        >
            <Drawer.Header showCloseButton/>

            <Drawer.Body className={classNames('flex flex-column gap-16 ai-center')}>
                <Body />
            </Drawer.Body>

            <Drawer.Footer sticky className="drawer-footer">
                <hr className="w-100 hr" />
                <Carditem
                    data-test-id
                    className="danger bg-transparent"
                    key={`avatar-item-cerrar`}
                    icon={<Logout />}
                    onClick={handleLogout}
                    content="Cerrar Sesión"
                    showArrow
                    variant="danger"
                ></Carditem>
            </Drawer.Footer>
        </Drawer>
    );
};
