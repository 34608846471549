import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Paragraph from '../Atoms/Paragraph';

import '../../../Statics/Scss/Modules/Pagination.scss';

const Pagination = ({ itemsQty, hasCost, cost, cols, children }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const _itemsQty = itemsQty || 17171717;

    const pageNumbers = [];
    const maxIndex = currentPage * _itemsQty;
    const minIndex = maxIndex - _itemsQty;
    const actualItems = children?.slice(minIndex, maxIndex);
    const totalPages = Math.ceil(children?.length / _itemsQty);

    for (let i = 1; i <= totalPages; i++) pageNumbers.push(i);

    return (
        <div className="mod-pagination">
            <ul className={`mod-pagination__items ${cols ? `--cols-${cols}` : '--cols-1'}`}>
                {/* Abstraer en un nivel inferior, luego desde la API se obtendrían las props */}
                {actualItems?.map((item, index) => (
                    <li key={index} className="mod-pagination__items__item">
                        {item}
                    </li>
                ))}
            </ul>

            <nav>
                {hasCost && (
                    <div className="mod-pagination__cost">
                        <Paragraph size="--sixxs" mod="--font-bold --arial">
                            Importe total
                        </Paragraph>
                        <Paragraph size="--s" mod="--font-bold --arial">{`$${cost.toFixed(2)}`}</Paragraph>
                    </div>
                )}

                <ol className="mod-pagination__controls">
                    {currentPage !== 1 && currentPage && (
                        <li className="mod-pagination__controls__page">
                            <Button onClick={() => setCurrentPage(currentPage - 1)} icon="arrow-left" mod="--not-active" />
                        </li>
                    )}
                    {pageNumbers?.length > 1 &&
                        pageNumbers?.map((number) => (
                            <li key={number} className="mod-pagination__controls__page">
                                <Button
                                    {...(number !== currentPage && {
                                        onClick: () => setCurrentPage(number),
                                    })}
                                    label={number}
                                    mod={number === currentPage ? 'tertiary' : '--not-active'}
                                />
                            </li>
                        ))}
                    {currentPage !== totalPages && currentPage && (
                        <li className="mod-pagination__controls__page">
                            <Button onClick={() => setCurrentPage(currentPage + 1)} icon="arrow-right" mod="--not-active" />
                        </li>
                    )}
                </ol>
            </nav>
        </div>
    );
};

Pagination.propTypes = {
    itemsQty: PropTypes.number,
    children: PropTypes.node,
    hasCost: PropTypes.bool,
    cols: PropTypes.number,
};

export default Pagination;
