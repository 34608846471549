import React from "react";
import PropTypes from "prop-types";

import "../../../Statics/Scss/Components/TooltipWrapper.scss";
import classNames from "classnames";
import Tooltip from "./Tooltip";

const propTypes = {
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * Flag, ¿se puede cerrar?
   */
  canBeClosed: PropTypes.bool,
  /**
   * Color asociado.
   */
  color: PropTypes.oneOf(["default", "black", "white"]),
  /**
   * Posición del tooltip.
   */
  position: PropTypes.oneOf(["top", "top-button", "right", "bottom", "left"]),
  /**
   * Handler para el evento click
   * al cerrar.
   */
  handlerClick: PropTypes.func,
  /**
   * Texto asociado al tooltip.
   */
  tooltipText: PropTypes.string,
  /**
   * Orientacion de la celda fija
   */
  positionSticky: PropTypes.oneOf(["left", "right"]),
};

const defaultProps = {
  handlerClick: () => {},
};

const TooltipWrapper = ({
  children,
  className,
  canBeClosed,
  color,
  position,
  handlerClick,
  tooltipText,
  ...r
}) => {
  const classes = classNames("tooltip-wrapper", className);

  return (
    <div className={classes}>
      <Tooltip
        canBeClosed={canBeClosed}
        color={color}
        position={position}
        handlerClick={handlerClick}
        {...r}
      >
        {tooltipText}
      </Tooltip>
      {children}
    </div>
  );
};

TooltipWrapper.propTypes = propTypes;
TooltipWrapper.defaultProps = defaultProps;

export default TooltipWrapper;
