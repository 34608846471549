import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import InputFormik from '../../Common/Formik/Input';
import Label from '../../Common/Atoms/Form/Label';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import FormRow from '../../Common/Atoms/Form/FormRow';
import FormCol from '../../Common/Atoms/Form/FormCol';
import SelectFormik from '../../Common/Formik/Select';
import Button from '../../Common/Modules/Button';
import MiClubFormLargoSchema from '../../../Validations/MiClubFormLargoSchema';
import CredencialModel from '../../../Models/Credencial';

const FormularioLargo = (props) => {
    return (
        <Formik
            validationSchema={MiClubFormLargoSchema}
            initialValues={{
                tipoDoc: 'DNI',
                nroDoc: '',
                credencial1: CredencialModel.PrimerosTarjeta,
                credencial2: '',
                credencial3: '',
            }}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true);
                let payload = {
                    tipo_doc: values.tipoDoc,
                    nro_doc: values.nroDoc,
                    nro_tarjeta: `${CredencialModel.PrimerosTarjeta}-${values.credencial2}-${values.credencial3}`,
                };
                console.log('submited', payload);

                //probar cuando funcione formik (y borrar este comentario)
                await props.OnVincular(payload);
                setSubmitting(false);
            }}
        >
            {(props) => (
                //use props.isSubmitting --> loading
                <Form noValidate>
                    <fieldset>
                        <legend>Ingresá los siguientes datos</legend>
                        <FormRow>
                            <InputGroup className="grid-25-75">
                                <FormCol>
                                    <Label text={'Tipo de documento'} />
                                    <SelectFormik name="tipoDoc" aria-label="Seleccione su tipo de documento">
                                        <option value="DNI">D.N.I.</option>
                                        <option value="PAS">PAS.</option>
                                        <option value="LC">L.C.</option>
                                        <option value="LE">L.E.</option>
                                        <option value="CI">C.I.</option>
                                    </SelectFormik>
                                </FormCol>
                                <FormCol>
                                    <Label text={'Número de documento'} />
                                    <InputFormik
                                        {...props}
                                        name="nroDoc"
                                        type={props.values.tipoDoc === 'PAS' ? 'text' : 'number'}
                                        maxlength="8"
                                        aria-label="Ingrese numero de documento"
                                    />
                                </FormCol>
                            </InputGroup>
                        </FormRow>
                        <FormRow>
                            <FormCol>
                                <Label text={'Número de credencial Club LA NACION'} />
                                <InputGroup className="grid-for-crencial">
                                    <InputFormik name="credencial1" type="number" disabled />
                                    <InputFormik
                                        name="credencial2"
                                        type="text"
                                        maxlength="8"
                                        aria-label="Ingrese los ocho números del medio de su credencial"
                                    />
                                    <InputFormik
                                        name="credencial3"
                                        type="text"
                                        maxlength="2"
                                        aria-label="Ingrese los dos últimos números de su credencial"
                                    />
                                </InputGroup>
                            </FormCol>
                        </FormRow>
                        <FormRow mod="--btn-groups --mb-32">
                            <Button mod="state--mobile-w100" className="--block-mobile" label="VINCULAR CREDENCIAL" type="submit" />
                        </FormRow>
                    </fieldset>
                </Form>
            )}
        </Formik>
    );
};

FormularioLargo.propTypes = {
    OnVincular: PropTypes.func.isRequired,
};

export default FormularioLargo;
