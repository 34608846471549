import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useVerificarClaveUsuario = () => {
    const { REACT_APP_APILOGIN } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState();
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(
                `${REACT_APP_APILOGIN}Usuario/VerificarClaveUsuario`
            )
                .then((data) => {
                    if (data && data.code) {
                        if (data.code == '0000') {
                            setResponse(data.response);
                            setIsLoading(false);
                        } else {
                            setError(data.message);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [response, isLoading, error];
};

export default useVerificarClaveUsuario;