import React, { useState } from 'react';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import steps from '../../../Config/StepsReclamoAccesoDigital';
import Wizard from '../../../Components/Common/Modules/Wizard';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import Button from '../../../Components/Common/Modules/Button';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import RangePicker from '../../../Components/Common/Modules/RangePicker';
import DatePicker from '../../../Components/Common/Modules/DatePicker';
import DateHelper from '../../../Helpers/Date';
import Label from '../../../Components/Common/Atoms/Form/Label';
import useGenerarReclamoAccesoDigital from '../../../Hooks/useGenerarReclamoAccesoDigital';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import Textarea from '../../../Components/Common/Atoms/Form/Textarea';
import { Text } from '@ln/common-ui-text';
import InputInfoSuscription from '../../../Components/Common/Atoms/Form/InputInfoSuscription';
import InfoInput from '../../../Components/Common/Atoms/Form/InfoInput';

const msgErrorDetalleProblema = {
    status: 'error',
    msg: `Ingresá el detalle del problema`,
};

const msgErrorMaximoCaracteres = {
    status: 'error',
    msg: `El detalle del problema no puede tener más de 250 caracteres.`,
};

const ReclamoPeriodo = (props) => {
    const history = useHistory();
    const stepProps = ['IdSuscripcion', 'motivoSelected', 'nombreSuscripcion'];
    const { motivoSelected, suscripcion } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoAccesoDigital);
    const { Id: IdSuscripcion, NombreAmigable: nombreSuscripcion } = suscripcion;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [descripcion, setDescripcion] = useState('');
    const [errorDescripcion, setErrorDescripcion] = useState();

    const [errorReclamoAccesoDigital, isLoadingReclamoAccesoDigital, generarReclamoAccesoDigital] = useGenerarReclamoAccesoDigital();

    const validarStep = () => {
        if (!startDate && !endDate) {
            mensajeValidacion();
            return false;
        }
        if (descripcion.length === 0) {
            setErrorDescripcion(msgErrorDetalleProblema);
            return false;
        }
        if (descripcion.length > 250) {
            setErrorDescripcion(msgErrorMaximoCaracteres);
            return false;
        }
        return true;
    };

    const handleDescChanged = (text) => {
        if (text.length <= 250) {
            setDescripcion(text);
        }
        if (text.length === 0) {
            setErrorDescripcion(msgErrorDetalleProblema);
        } else if (text.length > 250) {
            setErrorDescripcion(msgErrorMaximoCaracteres);
        } else {
            setErrorDescripcion(null);
        }
    };

    const handleClickFinish = () => {
        if (validarStep()) {
            generarReclamoAccesoDigital(
                {
                    IdMotivoReclamo: motivoSelected.Id,
                    IdSuscripcion: IdSuscripcion,
                    FechaDesde: startDate,
                    FechaHasta: endDate,
                    Observaciones: descripcion,
                },
                motivoSelected.Nombre,
                nombreSuscripcion
            );
        }
    };

    const mensajeValidacion = () => {
        if (!startDate && !endDate) return 'Las fechas no pueden estar vacías';
        if (!startDate && endDate) return 'Seleccioná una fecha desde';
        if (startDate && !endDate) return 'Seleccioná una fecha hasta';
        if (startDate > endDate) return 'Seleccioná un rango de fechas válido';
    };

    const volver = () => {
        history.push({
            pathname: PathsConfig.ReclamoAccesoDigital,
        });
    };

    return (
        <>
            <Breadcrumb firstItem="Mis reclamos" href="#" text="Acceso Digital" />
            <TitleWeb text="Reclamo Acceso Digital" />
            <section className="container-streched">
                <LoadingSpinner isLoading={isLoadingReclamoAccesoDigital}>
                    <Wizard steps={steps} activeStep={2} />
                    <FormRow>
                        <FormCol>
                            <div className='flex flex-column gap-8 arial text-16'>
                                <Label text="Suscripción a reclamar" />
                                <InputInfoSuscription input={<InfoInput>{suscripcion.NombreAmigable}</InfoInput>} />
                            </div>
                            <FormRow>
                                <FormCol>
                                    <div className='arial mb-24'>
                                        <Text as="h3" className='arial-bold text-20'>Seleccioná el período a reclamar</Text>
                                    </div>
                                    <RangePicker
                                        style={{ display: "flex", gap: "24px", margin: "0px" }}
                                        isInvalid={startDate > endDate || !startDate || !endDate}
                                        errorMessage={mensajeValidacion()}
                                        initialDatePicker={
                                            <FormCol className="w-100">
                                                <Label text={'Desde'} />
                                                <DatePicker
                                                    label="Desde"
                                                    onChange={setStartDate}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    placeholder={startDate ? DateHelper.convertDate_ddMMyyyy(startDate) : ''}
                                                    selected={startDate}
                                                    isInvalid={startDate > endDate || !startDate || !endDate}
                                                    aria-label="Desde"
                                                    adjustDateOnChange={false}
                                                />
                                            </FormCol>
                                        }
                                        finalDatePicker={
                                            <FormCol className="w-100">
                                                <Label text={'Hasta'} />
                                                <DatePicker
                                                    label="Hasta"
                                                    onChange={setEndDate}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    placeholder={endDate ? DateHelper.convertDate_ddMMyyyy(startDate) : ''}
                                                    selected={endDate}
                                                    isInvalid={startDate > endDate || !startDate || !endDate}
                                                    aria-label="Hasta"
                                                    adjustDateOnChange={false}
                                                />
                                            </FormCol>
                                        }
                                    />
                                </FormCol>
                            </FormRow>
                        </FormCol>
                    </FormRow>
                    <FormRow>
                        <FormCol>
                            <Textarea
                                state={errorDescripcion}
                                onChange={(e) => handleDescChanged(e.target.value)}
                                name="textDescription"
                                placeholder="Escribí el detalle del problema en 250 caracteres"
                                rows="6"
                                errorMessage={errorDescripcion ? errorDescripcion.msg : ''}
                                value={descripcion}
                            />
                        </FormCol>
                    </FormRow>
                    <FormRow mod="--btn-groups">
                        <Button label="GENERAR RECLAMO" onClick={handleClickFinish} />
                        <Button href={'#'} mod="secondary" label="VOLVER" onClick={volver} />
                    </FormRow>
                </LoadingSpinner>
            </section>
        </>
    );
};

export default ReclamoPeriodo;
