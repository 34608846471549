import React from 'react';
import PathsConfig from '../../../Config/RoutePaths';
import Link from '../Atoms/Link';
import Toast from '../Modules/Toast';
import IncentivarSuscripcionBanner from './IncentivarSuscripcion';

const ToastEmptyState = ({ titulo, bajada, hasBanner, generico, isSuspender }) => {
    return (
        <>
            {generico ? (
                <Toast status="info" title={'No tenés suscripciones.'}>
                    Actualmente no contás con suscripciones activas.
                </Toast>
            ) : (
                <Toast status="info" title={titulo}>
                    {bajada}
                    {
                        isSuspender && (
                            <Link href={PathsConfig.MisSuscripciones}> "Ver suscripciones".</Link>
                        )
                    }
                </Toast>
            )}
            {hasBanner && <IncentivarSuscripcionBanner />}
        </>
    );
};

export default ToastEmptyState;
