import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import { useStore } from '../../../Store/storeProvider';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import { ListadoBeneficiosUtilizados } from '../../../Components/Features/MisSuscripciones/ListadoBeneficiosUtilizados';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import PathsConfig from '../../../Config/RoutePaths';
import useObtenerBeneficios from '../../../Hooks/useObtenerBeneficios';
import useGetNotasLeidas from '../../../Hooks/useGetNotasLeidas';
import { ProductoPremiumId } from '../../../Models/ProductoPremium';
import { readCookie } from '../../../Helpers/Cookies';
import idProductoSuscripciones from '../../../Models/TipoSuscripciones';


export const BeneficiosUtilizados = (props) => {
    const { HandleSwitchsStates } = useStore();
    const pp = readCookie('ProductoPremiumId');
    const stepProps = ['suscripcionABajar', 'motivo', 'retencion'];
    const state = useRedirectWithout(props.location.state, stepProps, PathsConfig.MisSuscripciones);
    const [titular, setTitular] = useState({});
    const [adicionales, setAdicionales] = useState([]);
    const [beneficios, errorBeneficios, isLoadingBeneficios] = useObtenerBeneficios();
    const [notasLeidas, errorNotasLeidas, isLoadingNotasLeidas] = useGetNotasLeidas();

    useEffect(() => {
        if (beneficios?.DetallesBeneficios?.length > 0) {
            const [titular, ...adicionales] = beneficios?.DetallesBeneficios;
            setTitular(titular);
            setAdicionales(adicionales);
        }
    }, [beneficios]);

    const tipoDeSuscripcion =
        state.suscripcionABajar.idFamiliaDeProducto === idProductoSuscripciones.SuscriptorDigital
            ? 'ln'
            : 'lnClub';
    
    let tipoDePlan;

    switch (pp) {
        case ProductoPremiumId.Black.toString():
            tipoDePlan = 'familiar';
            break;
        case ProductoPremiumId.Triple.toString():
            tipoDePlan = 'triple';
            break;
        case ProductoPremiumId.Duo.toString():
            tipoDePlan = 'duo';
            break;
        case ProductoPremiumId.AdMasC.toString():
            tipoDePlan = 'club';
            break;
        default:
            tipoDePlan = 'digital';
            break;
    } // 'digital' | 'club' | 'duo' | 'triple' | 'familiar'

    return (
        <>
            <section>
                <Breadcrumb firstItem={'Mis suscripciones'} href={'#'} text={'Solicitud de baja'} />
                <TitleWeb text="Solicitud de baja" />
            </section>
            <section className="container-fluid-streched arial">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.BajaSuscripciones) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.BajaSuscripciones} />
                ) : (
                    <LoadingSpinner isLoading={isLoadingBeneficios || isLoadingNotasLeidas}>
                        <ErrorBoundary error={errorBeneficios || errorNotasLeidas}>
                            <ListadoBeneficiosUtilizados
                                fechaDesde={beneficios?.FechaDesde}
                                fechaHasta={beneficios?.FechaHasta}
                                consumosTitular={titular}
                                notasAndNewslettersTitular={notasLeidas?.notasLeidasTitular}
                                consumosAdicionales={adicionales}
                                notasAndNewslettersAdicionales={notasLeidas?.notasLeidasAdicionales}
                                tipoDePlan={tipoDePlan}
                                tipoDeSuscripcion={tipoDeSuscripcion}
                                state={state}
                            />
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </section>
        </>
    );
};

export default BeneficiosUtilizados;