import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import Toast from '../../../Components/Common/Modules/Toast';
import useObtenerSuscripciones from '../../../Hooks/useObtenerSuscripciones';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import useObtenerRechazosCobroVencidoYAdelantado from '../../../Hooks/useObtenerRechazosCobroVencidoYAdelantado';
import PathsConfig from '../../../Config/RoutePaths';
import ToastEmptyState from '../../../Components/Common/Banners/ToastEmptyState';
import { trackCustomEvent } from '../../../Helpers/Datadog';
import stepsTrackeoPagosPendientes from '../../../Config/StepsTrackeoPagosPendientes';
import { FiltroUsuariosPagosPendientesMax, limiteUsuariosPagosPendientes } from '../../../Config/FiltroUsuariosPagosPendientes';
import { useStore } from '../../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import UrlProperties from '../../../Helpers/UrlProperties';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';

const PagosPendientes = () => {
    const history = useHistory();

    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const [rechazos, errorRechazos, isLoadingRechazos] = useObtenerRechazosCobroVencidoYAdelantado();
    const suscripcionesPagables = suscripciones?.filter((suscripcion) => suscripcion.Estado !== 5);

    const suscripcionesPagadas = suscripciones?.filter((suscripcion) => suscripcion.Estado !== 5 || suscripcion.Estado !== 7);

    const { HandleSwitchsStates } = useStore();
    const origen = UrlProperties.getParam('origen');

    useEffect(() => {
        if (HandleSwitchsStates?.PagosPendientes) {
            if (rechazos?.rechazosCobrosAdelantados?.length || rechazos?.rechazosCobrosVencidos?.length) {
                if (userCanOperate()) {
                    //filtro de % de usuarios que pueden serguir con el pago de la deuda - actualmente al 100%
                    trackCustomEvent(stepsTrackeoPagosPendientes["VER-DEUDAS"]);
                    history.push({
                        pathname: PathsConfig.SeleccionarDeuda,
                        state: {
                            rechazos: rechazos,
                            filtroUsuario: true,
                            campanita: origen === 'campanita'
                        },
                    });
                } else {
                    //queda sin uso con el filtro al 100%
                    history.push({
                        pathname: PathsConfig.DeudaContactoCallcenter,
                        state: {
                            rechazos: rechazos,
                        },
                    });
                }
            }
        }
    }, [rechazos, HandleSwitchsStates]);

    const userCanOperate = () => {
        //func para filtrar cantidad de usuarios que puedan seguir con el pago - esta en 100%
        const filtro = Math.floor(Math.random() * FiltroUsuariosPagosPendientesMax);
        return filtro <= limiteUsuariosPagosPendientes;
    };
    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Pagos pendientes" />
                <TitleWeb text="Pagos pendientes" />
            </section>
            <section className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.PagosPendientes) ? (<ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.PagosPendientes} />) : (
                    <LoadingSpinner isLoading={isLoadingSuscripciones || isLoadingRechazos}>
                        {(errorSuscripciones && !suscripciones) || errorRechazos ? (
                            <ErrorGenerico />
                        ) : (
                            <>
                                {suscripcionesPagadas?.length ? (
                                    <ErrorBoundary error={(errorSuscripciones && !suscripciones) || errorRechazos}>
                                        <Toast status="info" title="¡Genial! Tus pagos estan al día">
                                            Actualmente no contas con pagos pendientes para saldar.
                                        </Toast>
                                    </ErrorBoundary>
                                ) : (
                                    !suscripcionesPagables?.length && (
                                        <>
                                            <ToastEmptyState hasBanner generico />
                                        </>
                                    )
                                )}
                            </>
                        )}
                    </LoadingSpinner>
                )}
            </section>
        </>
    );
};

export default PagosPendientes;
