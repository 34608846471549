import React from 'react';
import classNames from 'classnames';
import { Drawer, useDrawer } from '@ln/common-ui-drawer';
import { Text } from '@ln/common-ui-text';
import './DrawerSidebar.scss';
import Nav from '../Nav';
import { DRAWER } from '../../../../Models/Drawers';

export function DrawerSidebar () {
    const drawerId = DRAWER.SIDEBAR;
    const { hideDrawer } = useDrawer({ id: drawerId })

    return (
        <Drawer
            id={drawerId}
            position={'left'}
            className="max-w-520_md bg-neutral-light-1 gap-16 p-16 p-24_md shadow-down-md transition-regular"
            overlayClasses="z-10"
        >
            <Drawer.Header showCloseButton>
                <Text className='prumo prumo-semibold text-24 text-28_md text-32_lg'>Menú</Text>
            </Drawer.Header>
            <hr className='w-100 bg-neutral-light-50 hrHeight' />
            <Drawer.Body className={classNames("flex flex-column gap-16 ai-stretch")}>
                <Nav closeMenu={hideDrawer}/>
            </Drawer.Body>
        </Drawer>
    );
};
export default DrawerSidebar;