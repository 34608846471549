import { useState } from "react";
import { useHistory } from "react-router";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";
import PathsConfig from "../Config/RoutePaths";
import SessionHandler from '../../src/Handlers/Session';
import { useDispatch } from "../Store/storeProvider";

function useSuscripcionesSuspenderPorVacaciones() {
  const history = useHistory();
  const { REACT_APP_APIPAYWALL } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const outFunc = async (data, suscripcionSeleccionado, anulable, palabra) => {
    setIsLoading(true);
    const pathUrl = `${REACT_APP_APIPAYWALL}Suscripcion/SuspensionPorVacaciones`;
    ApiService.post(pathUrl, data)
      .then((response) => {
        let successProp = false;
        if (response.code === "0000") {
          Datadog.trackCustomEvent(CustomEventsDatadog.suspenderPorVacaciones);
          successProp = true;
        } else {
          Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorSuspenderPorVacaciones, { codigo: response.code, error: response.message });
        }
        SessionHandler.Reload(dispatch);
        history.push({
          pathname: PathsConfig.ConfirmarSuspension,
          state: {
            suspensionGenerada: data,
            suscripcionSeleccionado: suscripcionSeleccionado,
            anulable: anulable,
            success: successProp,
            numeroUnicoDeGestion: response ? response.message : null,
            palabra: palabra,
          },
        });
        setIsLoading(false);
        setError(response.code !== "0000" ? true : false);
      })
      .catch((error) => {
        Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorSuspenderPorVacaciones, { error: error.message });
        setIsLoading(false);
        setError(true);
      })
  };

  return [outFunc, error, isLoading];
}

export default useSuscripcionesSuspenderPorVacaciones;
