export const CustomEventsDatadog = {
    vincularCredencial: 'vincular-credencial',
    datosPersonales: 'datos-personales',
    bajaCredencial: 'baja-credencial',
    crearUsuario: 'crear-usuario',
    crearDomicilio: 'crear-domicilio',
    cambioDomicilio: 'cambio-domicilio',
    otorgarCredencial: 'otorgar-credencial',
    suspenderPorVacaciones: 'suspender-por-vacaciones',
    desvincularCredencial: 'desvincular-credencial',
    cambiarContrasena: 'cambiar-contrasena',
    cambiarEmailPrincipal: 'cambiar-email-principal',
    cambiarEmailSecundario: 'cambiar-email-secundario',
    reclamarTarjeta: 'reclamar-tarjeta',
    reclamarAccesoDigital: 'reclamar-acceso-digital',
    vincularRedSocial: 'vincular-red-social',
    desvincularRedSocial: 'desvincular-red-social',
    reclamarEntrega: 'reclamo-de-suscripcion',
    descargarLibreDeuda: 'descargar-libre-deuda',
    descargarFactura: 'descargar-factura',
    pagarPendiente: 'pago-pendiente-efectuado',
    cambioFormaDePago: 'cambio-forma-de-pago',
    consultaUpselling: 'consulta-planes-upselling',
    confirmacionUpselling: 'upselling-confirmado',
    visualizacionIframeCambioFormaDePago: 'visualizacion-iframe-ws-cambio-forma-pago',
    responderEncuesta: 'responder-encuesta',
    solicitudBajaSuscripcion: 'solicitud-baja-suscripcion',
    guardarEventoVinculacionAD: 'guardar-evento-vinculacion-AD',
    asignarCredencialUpselling: 'asignar-credencial-upselling',
    registrarDomicilioUpselling: 'registrar-domicilio-upselling',
    vistaFuncionalidadApagada: 'vista-funcionalidad-apagada',
    displayModalDeuda: 'display-modal-deuda',
    bloqueoCambioInstrumentoDeCobro: 'bloqueo-cambio-instrumento-de-cobro',
    displayModalPromoNoSuscriptor: 'display-modal-promo-no-suscriptor',
    clickCardOnCarrousel: 'click-card-on-carrousel',
    solicitudRetencion: 'solicitud-retencion',
    visualizacionBaja: 'visualizacion-baja'
};

export const CustomEventsErrorDatadog = {
    errorVincularCredencial: 'error-vincular-credencial',
    errorDesvincularCredencial: 'error-desvincular-credencial',
    errorOtorgarCredencial: 'error-otorgar-credencial',
    errorReclamarEntrega: 'error-reclamo-de-suscripcion',
    errorReclamoTarjeta: 'error-reclamo-tarjeta',
    errorReclamarAccesoDigital: 'error-reclamo-acceso-digital',
    errorSuspenderPorVacaciones: 'error-suspender-por-vacaciones',
    errorCambiarContrasena: 'error-cambiar-contrasena',
    errorCambiarEmailPrincipal: 'error-cambiar-email-principal',
    errorCambiarEmailSecundario: 'error-cambiar-email-secundario',
    errorVincularRedSocial: 'error-vincular-red-social',
    errorDesvincularRedSocial: 'error-desvincular-red-social',
    errorDatosPersonales: 'error-datos-personales',
    errorCrearUsuario: 'error-crear-usuario',
    errorCrearDomicilio: 'error-crear-domicilio',
    errorBajaCredencial: 'error-baja-credencial',
    errorCambioFormaDePago: 'error-cambio-forma-de-pago',
    errorCambioDomicilio: 'error-cambio-domicilio',
    errorPagosPendientes: 'error-pagos-pendientes',
    errorObtenerIdentitiesProviderData: 'error-obtener-identities-provider-data',
    errorSolicitudBajaSuscripcion: 'error-solicitud-baja-suscripcion',
    errorGuardarEventoVinculacionAD: 'error-guardar-evento-vinculacion-AD',
    errorConsultaCampaniasUpselling: 'error-consulta-campanias-upselling',
    errorAsignarCredencialUpselling: 'error-asignar-credencial-upselling',
    errorRegistrarDomicilioUpselling: 'error-registrar-domicilio-upselling',
    errorConfirmacionUpselling: 'error-confirmacion-upselling',
    errorBloqueoCambioInstrumentoDeCobro: 'error-bloqueo-cambio-instrumento-de-cobro',
    errorBloqueoGestionCredenciales: 'inconsistencia-gestion-credenciales-bloqueadas',
    errorObtenerCredenciales: 'error-obtener-credenciales',
    errorObtenerGrupos: 'error-obtener-grupos',
    errorObtenerHabilidadBasica: 'error-obtener-habilidad-basica',
    errorObtenerSuscripcionesParaBaja: 'error-obtener-suscripciones-para-baja',
    errorObtenerMotivosBajaSuscripcion: 'error-obtener-motivos-baja-suscripcion',
    errorObtenerNotasLeidas: 'error-obtener-notas-leidas',
    errorSolicitudRetencion: 'error-solicitud-retencion',
    errorObtenerBeneficiosParaBaja: 'error-obtener-beneficios-para-baja',
    errorConsultaUpselling: 'error-consulta-planes-upselling',
}