import { useState } from "react";
import mapAndSortCountries from "../Map/CountriesMap";
import ApiService from "../Services/ApiService";

const useObtenerCountries = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async (info) => {
        setIsLoading(true);
        ApiService.post(`${REACT_APP_APIPAYWALL}Domicilio/ObtenerCountries`, { descripcion: info })
            .then(data => {
                if (data && data.code === "0000") {
                    if (data.response?.length > 0) {
                        setCountries(mapAndSortCountries(data.response));
                    } else {
                        setError("Sin resultados");
                    };
                } else {
                    setError("Sin resultados");
                };
            })
            .catch(error => setError(error))
            .finally(() => setIsLoading(false));
    };

    return [countries, error, isLoading, fetchData];
};

export default useObtenerCountries;
