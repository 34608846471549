import { range } from '../../../../Helpers/Utils';

export default function usePagination(props = {}) {
	// Posible refactor, implementación de más configuraciones.
	const BOUNDARY_PAGES = 1,
		MAX_INLINE_ITEMS = 5,
		DISABLED = false,
		HIDE_PREVIOUS_BUTTON = false,
		HIDE_NEXT_BUTTON = false;

	const {
		itemsPerPage,
		totalItems,
		activePage = 0,
		onPageClick,
		otherElement,
		compressed,
		siblingCount = 1,
		pageNumbers,
		...r
	} = props;

	const startPages = range(1, Math.min(BOUNDARY_PAGES, pageNumbers));
	const endPages = range(
		Math.max(pageNumbers - BOUNDARY_PAGES + 1, BOUNDARY_PAGES + 1),
		pageNumbers,
	);

	const siblingsStart = Math.max(
		Math.min(activePage + 1 - siblingCount, pageNumbers - BOUNDARY_PAGES - siblingCount * 2),
		BOUNDARY_PAGES + 1,
	);

	const siblingsEnd = Math.min(
		Math.max(activePage + 1 + siblingCount, BOUNDARY_PAGES + siblingCount * 2 + 1),
		endPages.length > 0 ? endPages[0] - 1 : pageNumbers - 1,
	);

	// Lista básica de items a renderizar.
	// ej. itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
	const itemList = [
		...(HIDE_PREVIOUS_BUTTON ? [] : ['previous']),
		...startPages,

		// Elipses iniciales.
		...(MAX_INLINE_ITEMS < pageNumbers && siblingsStart > BOUNDARY_PAGES + 1
			? ['ellipsis']
			: // : BOUNDARY_PAGES + 1 < pageNumbers - BOUNDARY_PAGES
			  // ? [BOUNDARY_PAGES + 1]
			  []),

		// Números a mostrarse.
		...range(siblingsStart, siblingsEnd),

		// Elipses finales.
		...(MAX_INLINE_ITEMS < pageNumbers && siblingsEnd < pageNumbers - BOUNDARY_PAGES
			? ['ellipsis']
			: // : pageNumbers - BOUNDARY_PAGES > BOUNDARY_PAGES
			  // ? [pageNumbers - BOUNDARY_PAGES]
			  []),

		...endPages,
		...(HIDE_NEXT_BUTTON ? [] : ['next']),
	];

	// Mappeo del botón con su respectivo número de página.
	const buttonPage = type => {
		switch (type) {
			case 'previous':
				return activePage - 1;
			case 'next':
				return activePage + 1;
			default:
				return null;
		}
	};

	const items = itemList.map(item => {
		return typeof item === 'number'
			? {
					onClick: () => {
						onPageClick(item - 1);
					},
					label: item,
					selected: item === activePage,
					disabled: DISABLED,
					className: `mod-button --font-bold${item === activePage + 1 ? ' --tertiary --selected' : ' --tertiary'}`,
					'aria-current': item === activePage + 1 ? 'true' : undefined,
			  }
			: item === 'ellipsis'
			? {
					isText: true,
					content: '...',
			  }
			: {
					onClick: () => {
						onPageClick(buttonPage(item));
					},
					icon: item === 'previous' ? 'arrow-left' : item === 'next' ? 'arrow-right' : '',
					selected: false,
					label: item === 'ellipsis' ? '...' : null,
					className: 'mod-button --font-bold --tertiary',
					disabled:
						DISABLED ||
						(item === 'next'
							? activePage >= pageNumbers - 1
							: item === 'previous'
							? activePage <= 0
							: item === 'ellipsis'),
			  };
	});

	return {
		items,
		...r,
	};
}
