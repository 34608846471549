import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerSuscripcionesDigitalesAnalitics = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [suscripciones, setSuscripciones] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIPAYWALL}SuscripcionV1/ObtenerSuscripcionesDigitalesAnalytics`)
                .then((data) => {
                    if (data.code === '0000' && data.response) {
                        setSuscripciones(data.response);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [suscripciones, error, isLoading];
};

export default useObtenerSuscripcionesDigitalesAnalitics;
