import React from "react";
import Divider from "../../../Components/Common/Atoms/Divider";
import ContactoDudasBanner from "../../../Components/Common/Banners/ContactoDudas";
import Toast from "../../../Components/Common/Modules/Toast";
import Link from "../../../Components/Common/Atoms/Link";
import PathsConfig from "../../../Config/RoutePaths";

const SinSuscripcionesActivas = () => {
  return (
    <>
      <section className="container-streched">
        <Toast
          status="info"
          title="No posees suscripciones editoriales activas (diarios y/o revistas)."
        >
          No se han encontrado suscripciones editoriales activas. Consultá el
          estado de tu suscripción ingresando a{" "}
          <Link href={PathsConfig.MisSuscripciones}> Ver suscripciones.</Link>
        </Toast>
        <Divider />
        <ContactoDudasBanner />
      </section>
    </>
  );
};

export default SinSuscripcionesActivas;
