import React, { useState } from 'react';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Toast from '../../../Components/Common/Modules/Toast';
import Wizard from '../../../Components/Common/Modules/Wizard';
import TitleWeb from '../../../Components/Common/Web/Title';
import useObtenerSuscripciones from '../../../Hooks/useObtenerSuscripciones';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import Title from '../../../Components/Common/Atoms/Title';
import Text from '../../../Components/Common/Text/Text';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import Button from '../../../Components/Common/Modules/Button';
import PathsConfig from '../../../Config/RoutePaths';
import { useHistory } from 'react-router';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import useObtenerPlanes from '../../../Hooks/useObtenerPlanes';
import SuscripcionesSelector from '../../../Components/Common/FormBlocks/SuscripcionesSelector';
import steps from '../../../Config/StepsCambioFormaDePago';
import estadoSuscripciones from '../../../Models/EstadoSuscripciones';
import ToastEmptyState from '../../../Components/Common/Banners/ToastEmptyState';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import useObtenerEvento from '../../../Hooks/useObtenerEvento';
import { useStore } from '../../../Store/storeProvider';
import { motivoDefault } from '../../../Helpers/MotivoDefault';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';


const ElegirSuscripcion = () => {
    const history = useHistory();
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const [evento, errorEventos, isLoadingEventos] = useObtenerEvento();
    const suscripcionesActivas = suscripciones?.filter(
        (s) =>
            s.Estado === estadoSuscripciones.ACTIVA || s.Estado === estadoSuscripciones.SUSP_VACACIONES || s.Estado === estadoSuscripciones.FALTA_PAGO
    );
    const [selected, setSelected] = useState([]);
    const [planes, errorPlanes, isLoadingPlanes] = useObtenerPlanes();
    const [suscriptionsUnChecked, setSuscriptionsUnChecked] = useState(false);
    const [formState, setFormState] = useState({
        checkBoxError: false,
        checkBoxErrorMsg: 'Seleccioná al menos una suscripción donde necesites cambiar la forma de pago.',
        selectError: 'success',
        selectErrorMsg: 'Seleccioná un motivo del cambio',
    });
    const { HandleSwitchsStates } = useStore();

    const handleContinuar = () => {
        if (selected.length < 1) {
            setSuscriptionsUnChecked(true);
        }
        
        if (selected.length > 0 && formState.selectError == 'success') {
            setSuscriptionsUnChecked(false);
            setFormState((formState) => ({ ...formState, checkBoxError: false }));
            history.push({
                pathname: PathsConfig.SeleccionarMetodoPago,
                state: {
                    suscripciones: selected,
                    motivo: motivoDefault.Id,
                },
            });
        }
    };

    return (
        <div>
            <section className="container-streched">
                <section>
                    <Breadcrumb firstItem="Mis Pagos" text="Cambiar forma de pago" href="#" />

                    <TitleWeb text="Cambiar forma de pago" />
                </section>
                <LoadingSpinner withBody isLoading={isLoadingSuscripciones || isLoadingPlanes || isLoadingEventos}>
                    {(funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.CambiarFormaDePago) ? (
                        <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.CambiarFormaDePago} />
                    ) : (
                        <ErrorBoundary error={errorSuscripciones || errorPlanes || errorEventos}>

                            {(errorSuscripciones && !suscripciones) &&
                            (
                            <Toast status="warning" title="Ocurrió un error">
                                <span>Hubo un problema con tu solicitud. Por favor, volvé a intentar.</span>
                            </Toast>
                            )}

                            {(suscripciones?.length == 0 ? (
                            <>
                                <ToastEmptyState hasBanner generico />
                            </>
                            ) : (
                            <>
                                {suscripcionesActivas?.length < 1 ? (
                                    <Toast status="info" title="No tenés suscripciones">
                                        No se encontraron suscripciones para solicitar un cambio de forma de pago.
                                    </Toast>
                                ) : (
                                    <>
                                        <Wizard steps={steps} activeStep={1} />
                                        <Title size="--s" mod="--font-bold --mb-24">
                                            Seleccioná las suscripciones donde necesites cambiar el medio de pago
                                        </Title>

                                        <form action="#">
                                            <SuscripcionesSelector
                                                suscriptions={suscripcionesActivas}
                                                unChecked={suscriptionsUnChecked}
                                                onUnChecked={setSuscriptionsUnChecked}
                                                onSuscriptionsSelected={setSelected}
                                                textoTarjeta={true}
                                                cambioFormaDePago={true}
                                                planes={planes}
                                                evento={evento}
                                            />
                                            {suscriptionsUnChecked && (
                                                <Text tag="p" size="5xs" color="#E2342D">
                                                    {formState.checkBoxErrorMsg}
                                                </Text>
                                            )}
                                            <FormRow mod="--btn-groups">
                                                <Button mod="state--mobile-w100" type="button" label="Continuar" onClick={handleContinuar} />
                                            </FormRow>
                                        </form>
                                    </>
                                )}
                            </>
                            ))}
                        </ErrorBoundary>
                    ))}
                </LoadingSpinner>
            </section>
        </div>
    );
};

export default ElegirSuscripcion;