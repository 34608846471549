import newsletters from '../Statics/Imgs/newsletters.png';
import kiosco from '../Statics/Imgs/kiosco.png';
import vivo from '../Statics/Imgs/vivo.png';
import eventos from '../Statics/Imgs/eventos.png';
import appLn from '../Statics/Imgs/app-ln.png';
import trivias from '../Statics/Imgs/trivias.png';
import suscriptores from '../Statics/Imgs/suscriptores.png';
import ln from '../Statics/Imgs/ln.png';
import beneficios from '../Statics/Imgs/beneficios.png';
import codigos from '../Statics/Imgs/codigos.png';
import appClub from '../Statics/Imgs/app-club.png';
import credencial from '../Statics/Imgs/credencial.png';

export const BeneficiosPorSuscripcion = {
    digital: {
        newsletter: {
            text: 'Recibí newsletters',
            image: newsletters,
            href: 'https://newsletter.lanacion.com.ar',
        },
        edicionesImpresas: {
            text: 'Recorré las ediciones impresas',
            image: kiosco,
            href: 'https://edicionimpresa.lanacion.com.ar/la-nacion',
        },
        eventosVivo: {
            text: 'Eventos en vivo',
            image: vivo,
            href: 'https://www.lanacion.com.ar/tema/la-nacion-cerca',
        },
        seriesEspeciales: {
            text: 'Series especiales',
            image: eventos,
            href: 'https://www.lanacion.com.ar/tema/contenidos-especiales/',
        },
        appLn: {
            text: 'Descargá la app de LA NACION',
            image: appLn,
            href: 'https://play.google.com/store/apps/details?id=app.lanacion.activity&hl=es_419&pli=1',
        },
        trivias: {
            text: 'Divertite con las trivias',
            image: trivias,
            href: 'https://www.lanacion.com.ar/juegos',
        },
        suscriptores: {
            text: 'Compartí tus historias y reflexiones',
            image: suscriptores,
            href: 'https://www.lanacion.com.ar/opinion/carta-de-suscriptores',
        },
        ln: {
            text: 'Navegá sin límites en LA NACION',
            image: ln,
            href: 'https://www.lanacion.com.ar',
        },
    },
    club: {
        beneficiosClub: {
            text: 'Descubrí todos los beneficios',
            image: beneficios,
            href: 'https://club.lanacion.com.ar/beneficios',
        },
        codigosDescuento: {
            text: 'Códigos de descuento',
            image: codigos,
            href: 'https://club.lanacion.com.ar/codigos-de-descuento',
        },
        appClub: {
            text: 'Descargá la app de Club LA NACION',
            image: appClub,
            href: 'https://play.google.com/store/apps/details?id=com.clublanacion',
        },
        credencialClub: {
            text: 'Credencial Club LA NACION',
            image: credencial,
            href: 'https://myaccount.lanacion.com.ar/vincular-credencial',
        },
    },
};
