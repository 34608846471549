import { NewsLetters } from "../Models/DTO/Newsletters";
import DateHelper from "../Helpers/Date";

const mapNewslettersToTable = (newsletters) => {
    if(newsletters == null) return [];
    const NewslettersMapeados = [];
    newsletters.map((newsletter) => {
      let NewslettersFormatted = JSON.parse(JSON.stringify(NewsLetters));

      NewslettersFormatted.nombre.value = newsletter.nombre;
      NewslettersFormatted.date.value = DateHelper.changeDateStringFormat(newsletter.fecha);

      return NewslettersMapeados.push(NewslettersFormatted);
    });

    return NewslettersMapeados;
}

export default mapNewslettersToTable;
