import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Pagination from "../Pagination/Pagination";
import BasicTable from "./BasicTable";
import NoResults from "../NoResults";

const propTypes = {
  /**
   * Celdas del header.
   */
  cols: PropTypes.array,
  /**
   * Valores y propiedades de
   * cada celda.
   */
  items: PropTypes.array,
  /**
   * Cantidad de items por página.
   */
  itemsPerPage: PropTypes.number,
  /**
   * Handler para los cambios de
   * página, sobre Table.
   */
  onPageChange: PropTypes.func,
  /**
   * Handler para los botones
   * del paginador.
   */
  onPageClick: PropTypes.func,
  /**
   * Alineación general.
   */
  align: PropTypes.string,
  /**
   * Flag, ¿es checkeable?
   */
  isCheckable: PropTypes.bool,
  /**
   * Handler para los checks.
   */
  handlerCheck: PropTypes.func,
  /**
   * Flag, ¿es fixed layout?
   */
  isFixedLayout: PropTypes.bool,
  /**
   * Título cuando no hay resultados.
   */
  noResultsTitle: PropTypes.string,
  /**
   * Descripción cuando no hay resultados.
   */
  noResultsDescription: PropTypes.string,
  /**
   * Icono cuando no hay resultados.
   */
  noResultsIcon: PropTypes.string,
  /**
   * Icono cuando no hay resultados.
   */
  totalItems: PropTypes.number,
  /**
   * Página inicial activa.
   */
  initialActivePage: PropTypes.number,
  /**
   * Orientacion de la celda botón fija
   */
  actionPositionSticky: PropTypes.oneOf(["left", "right"]),
  /**
   * Orientacion de la celda común fija
   */
  cellPositionSticky: PropTypes.oneOf(["left", "right"]),
};

const defaultProps = {
  itemsPerPage: 8,
  align: "center",
  handlerCheck: () => {},
  onPageChange: () => {},
  onPageClick: () => {},
  noResultsDescription: "No se encontraron resultados.",
  initialActivePage: 0,
};

const BasicTablePagination = ({
  cols,
  items,
  actionPositionSticky,
  cellPositionSticky,
  cellHeaderSticky,
  tooltipText,
  tooltipPosition,
  itemSelected,
  onClickItemButton,
  itemsPerPage,
  onPageChange,
  onPageClick,
  align,
  isCheckable,
  handlerCheck,
  isFixedLayout,
  noResultsTitle,
  noResultsDescription,
  noResultsIcon,
  totalItems,
  initialActivePage,
}) => {
  const [activePage, setActivePage] = useState(initialActivePage);
  const total = totalItems ? totalItems : items?.length;

  const goToPage = (pageNumber) => {
    const paginationData = {
      currentPage: pageNumber,
      totalPages: Math.ceil(total / itemsPerPage),
      pageLimit: itemsPerPage,
      totalRecords: total,
    };

    setActivePage(pageNumber);
    onPageClick(paginationData);
  };

  /**
   * METHOD for disable pagination if the items are less or same to items por page
   */
  const [isPagination, setIsPagination] = useState(true);
  useEffect(() => {
    if (total <= itemsPerPage) {
      setIsPagination(false);
    }
  }, []);

  return (
    <>
      {total ? (
        <>
          <BasicTable
            cols={cols}
            items={items}
            actionPositionSticky={actionPositionSticky}
            cellPositionSticky={cellPositionSticky}
            cellHeaderSticky={cellHeaderSticky}
            tooltipText={tooltipText}
            tooltipPosition={tooltipPosition}
            itemSelected={itemSelected}
            onClickItemButton={onClickItemButton}
            itemsPerPage={itemsPerPage}
            currentPage={activePage}
            align={align}
            onPageChange={onPageChange}
            isCheckable={isCheckable}
            handlerCheck={handlerCheck}
            isFixedLayout={isFixedLayout}
          />
          {isPagination && (
            <Pagination
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              totalItems={total}
              onPageClick={goToPage}
            />
          )}
        </>
      ) : (
        <NoResults
          title={noResultsTitle}
          description={noResultsDescription}
          iconName={noResultsIcon}
        />
      )}
    </>
  );
};

BasicTablePagination.propTypes = propTypes;
BasicTablePagination.defaultProps = defaultProps;

export default BasicTablePagination;
