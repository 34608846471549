import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import FormCol from '../../Common/Atoms/Form/FormCol';
import FormRow from '../../Common/Atoms/Form/FormRow';
import InputBox from '../../Common/Atoms/Form/InputBox';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import Label from '../../Common/Atoms/Form/Label';
import Button from '../../Common/Modules/Button';
import DatepickerFormik from '../../Common/Formik/Datepicker';
import InputFormik from '../../Common/Formik/Input';
import RadioFormik from '../../Common/Formik/Radio';
import SelectFormik from '../../Common/Formik/Select';
import Text from '../../Common/Text/Text';
import Paragraph from '../../Common/Atoms/Paragraph';

const CrearUsuarioForm = ({
    FormCrearUsuarioSchema,
    cliente,
    fechaDesde,
    onChangeCalendario,
    errorGenero,
    handleOnChange,
    generoSelected,
    handleSubmit,
}) => {
    const history = useHistory();
    return (
        <div>
            <Formik validationSchema={FormCrearUsuarioSchema} initialValues={cliente}>
                {(props) => (
                    <Form>
                        <FormRow>
                            <InputGroup>
                                <FormCol>
                                    <Label text={'Nombre'} />
                                    <InputFormik type="text" name="nombre" state={{ status: '', msg: '' }} aria-label="Ingrese su nombre" />
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <FormRow>
                            <InputGroup>
                                <FormCol>
                                    <Label text={'Apellido'} />
                                    <InputFormik type="text" name="apellido" state={{ status: '', msg: '' }} aria-label="Ingrese su apellido" />
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <FormRow>
                            <InputGroup className="grid-25-75">
                                <FormCol>
                                    <Label text={'Tipo de documento'} />
                                    <SelectFormik disabled name="tipoDocumento" state={{ status: '', msg: '' }} aria-label="Seleccione su tipo de documento">
                                        <option selected value="DNI">D.N.I.</option>
                                    </SelectFormik>
                                </FormCol>

                                <FormCol>
                                    <Label text={'Número de documento'} />
                                    <InputFormik
                                        type="text"
                                        name="numeroDocumento"
                                        state={{ status: '', msg: '' }}
                                        aria-label="Ingrese número de documento"
                                    />
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <FormRow>
                            <InputGroup>
                                <FormCol>
                                    <Label text={'Fecha de nacimiento'} />
                                    <DatepickerFormik
                                        isInvalid={fechaDesde === null}
                                        hideCalendar={false}
                                        name="fechaCumpleanios"
                                        selected={fechaDesde}
                                        onChange={onChangeCalendario}
                                        value={fechaDesde}
                                        preventOpenOnFocus={true}
                                        placeholder="DD/MM/AAAA"
                                        isTabInsideEnabled={false}
                                        aria-label="Ingrese su fecha de nacimiento"
                                    />
                                    <Paragraph size="--fivexs" mod="--arial --font-regular --light-neutral-600 --mt-16">La persona debe ser mayor de 18 años.</Paragraph>
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <FormRow>
                            <Label text={'Género'} />
                            <InputGroup className="grid-50-50">
                                <FormCol>
                                    <InputBox
                                        error={errorGenero}
                                        input={
                                            <RadioFormik
                                                error={errorGenero}
                                                onChange={handleOnChange}
                                                value={'M'}
                                                name="Sexo"
                                                checked={generoSelected === 'M'}
                                                aria-label="Masculino"
                                            >
                                                Masculino
                                            </RadioFormik>
                                        }
                                    />
                                </FormCol>
                                <FormCol>
                                    <InputBox
                                        error={errorGenero}
                                        input={
                                            <RadioFormik
                                                error={errorGenero}
                                                onChange={handleOnChange}
                                                value={'F'}
                                                name="Sexo"
                                                checked={generoSelected === 'F'}
                                                aria-label="Femenino"
                                            >
                                                Femenino
                                            </RadioFormik>
                                        }
                                    />
                                </FormCol>
                            </InputGroup>
                            {errorGenero && (
                                <Text tag="p" size="5xs" font="arial" className="--red-500 gender">
                                    Completá el Género para continuar
                                </Text>
                            )}
                        </FormRow>

                        <FormRow>
                            <InputGroup>
                                <FormCol>
                                    <Label text={'Correo electrónico'} />
                                    <InputFormik type="text" name="correo" aria-label="Ingrese su email" />
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <FormRow>
                            <InputGroup>
                                <FormCol>
                                    <Label text={'Teléfono celular'} />
                                    <InputFormik type="text" name="celular" aria-label="Ingrese su número de teléfono celular" />
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <FormRow>
                            <InputGroup>
                                <FormCol>
                                    <Label text={'Teléfono particular'} />
                                    <InputFormik type="text" name="particular" aria-label="Ingrese su número de teléfono particular" />
                                </FormCol>
                            </InputGroup>
                        </FormRow>

                        <div className="otorgar-credencial-buttons">

                            <FormRow mod="--btn-groups">
                                <Button className="state--mobile-w100" size="small" type="submit" label="Continuar" onClick={() => handleSubmit(props)} />
                                <Button
                                    mod="tertiary"
                                    size="small"
                                    label="Volver"
                                    onClick={() => {
                                        history.push({
                                            pathname: PathsConfig.MisCredencialesBuscarCliente,
                                        });
                                    }}
                                />
                            </FormRow>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CrearUsuarioForm;
