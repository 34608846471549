import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useHistory } from 'react-router';

import PathsConfig from "../../../Config/RoutePaths";
import { Button } from '@ln/experiencias-ui-button';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import { MotivoBaja } from "./components/MotivoBaja";
import CheckboxesMap from '../../../Map/SuscripcionesToInputListMap';
import FormIniciarBajaSuscripcion from "../../../Validations/FormIniciarBajaSuscripcion";
import { Text } from "@ln/common-ui-text";
import useObtenerEstrategiaRetencion from "../../../Hooks/useObtenerEstrategiaRetencion";
import { configAccesoDigital } from "../../../Config/Suscripciones";
import Label from "../../Common/Atoms/Form/Label";
import { readCookie } from "../../../Helpers/Cookies";

const propTypes = { suscripciones: PropTypes.array };

const FormularioIniciarBaja = ({ suscripciones, motivosBajaSuscripcion, setHabilidadBasicaStates }) => {
    const history = useHistory();
    const [suscripcionSelected, setSuscripcionSelected] = useState(null);
    const [invalidSuscripciones, setInvalidSuscripciones] = useState(false);    
    const [estrategia, errorEstrategia, isLoadingEstrategia, obtenerEstrategia] = useObtenerEstrategiaRetencion(setHabilidadBasicaStates)
    const [nextPagePayload, setNextPagePayload] = useState(null)
    const suscripcionesSinBonificadosNiCorpo = suscripciones.filter(item => !(item.nombre.toLowerCase().includes('bonificado') || item.tipoDeVenta.toLowerCase() == 'venta corporativa'));

    const initialValuesFormik = {
        suscripcionesABajar: null,
        motivo: "",
    };

    const handleCheckedSuscripciones = event => {
        if (event.target.checked) {
            const sus = suscripcionesSinBonificadosNiCorpo.find(suscripcion => suscripcion.id == event.target.value);
            setSuscripcionSelected(sus)
            setInvalidSuscripciones(false);
        } else {
            setSuscripcionSelected(null);
        }
    };

    const handleValidationsOnSubmit = () => {
        setInvalidSuscripciones(!suscripcionSelected)
    };

    const clickCancelar = () => {
        history.push({
            pathname: PathsConfig.MisSuscripciones
        });
    };

    const goToNextPage = (sus, HabilidadBasica) => {
        const retencionSavedLastDay = readCookie('retencionSavedLastDay');
        const nextPage = (sus.idAccesoDigital == configAccesoDigital.ln && HabilidadBasica) && !retencionSavedLastDay ? PathsConfig.BeneficiosUtilizados: PathsConfig.ConfirmarBaja;

        history.push({
            pathname: nextPage,
            state: {
                suscripcionABajar: nextPagePayload.suscripcionABajar,
                suscripciones: suscripcionesSinBonificadosNiCorpo,
                motivo: nextPagePayload.motivo,
                retencion: estrategia
            }
        })
    }

    const onSubmitFormik = async (values, { setSubmitting, setFieldError }) => {
        if (!values.motivo || !suscripcionSelected) return;
        setSubmitting(true);
        let payload = {
            ...values,
            suscripcionABajar: suscripcionSelected,
        };

        setNextPagePayload(payload)
        obtenerEstrategia(payload.motivo.split("|")[0], suscripcionSelected.idRegimenFacturacion)         
        setSubmitting(false);
    };

    useEffect(() => {
        //HabilidadBasica debe ser true o false 
        if(estrategia != null && suscripcionSelected) goToNextPage(suscripcionSelected, estrategia.habilidadBasica)
    }, [estrategia, suscripcionSelected])

    return (
        <Formik
            validationSchema={FormIniciarBajaSuscripcion}
            initialValues={initialValuesFormik}
            onSubmit={onSubmitFormik}
        >
            {(props) => (
                <Form noValidate>
                    <div className="flex flex-column gap-32">
                        <div className="flex flex-column gap-16">
                            <Text as="h5" className="arial-bold text-18 text-neutral-light-800">Seleccioná las suscripciones en las cuales querés solicitar la baja</Text>
                            {suscripcionesSinBonificadosNiCorpo && (
                                <div className="flex flex-column gap-8">
                                    <Label className="text-14 text-neutral-light-800">Mis suscripciones</Label>
                                    {CheckboxesMap({ suscripciones: suscripcionesSinBonificadosNiCorpo, clickHandler: handleCheckedSuscripciones, suscripcionSeleccionada: suscripcionSelected, invalid: invalidSuscripciones })}
                                    {invalidSuscripciones && (<span className="text-12 --red-500">Seleccioná la suscripción que quieras dar de baja.</span>)}
                                </div>
                            )}
                        </div>

                        <MotivoBaja
                            name='motivo'
                            label="Seleccioná el motivo de la baja"
                            setFieldValue={props.setFieldValue}
                            motivosBajaSuscripcion={motivosBajaSuscripcion}
                        />

                        <Groupbutton>
                            <Button title='Continuar' type='submit' onClick={handleValidationsOnSubmit}>CONTINUAR</Button>
                            <Button title='Cancelar' variant="secondary" onClick={clickCancelar}>CANCELAR</Button>
                        </Groupbutton>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

FormularioIniciarBaja.propTypes = propTypes;

export default FormularioIniciarBaja;