import React from 'react';
import Toast from '../Modules/Toast';
import Link from '../Atoms/Link';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';

function Deuda() {
    const history = useHistory();

    return (
        <Toast status="warning" title="Tenés un saldo pendiente con LA NACION. ">
            Regularizá la situación desde{' '}
            <Link target="_blank" href="/mis-pagos/pagos-pendientes">
                Pagos Pendientes
            </Link>{' '}
            para luego acceder al beneficio.
        </Toast>
    );
}

export default Deuda;
