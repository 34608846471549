import React from "react";
import EmbedHide from "../Organism/EmbedHide";
import Divider from "../Atoms/Divider";
import CallbackArticle from "../Modules/CallbackArticle";
import Link from "../Atoms/Link";
import imgPromo from "../../../Statics/Imgs/image-promo.png";

const IncentivarSuscripcionBanner = ({ showBottomDivider }) => {
  const EnvSuscripciones = process.env.REACT_APP_SUSCRIPCIONES;

  return (
    <EmbedHide>
      <CallbackArticle
        title="¡Suscribite ahora!"
        imageUrl={imgPromo}
        description={
          <p className="--twoxs">
            Conocé los planes digitales de LA NACION. <br />
            <Link
              target="_blank"
              href={`${EnvSuscripciones}suscribirme/?utm_source=micuenta&utm_medium=online`}
            >
              Más información
            </Link>
            .
          </p>
        }
      />
      {showBottomDivider && <Divider />}
    </EmbedHide>
  );
};

export default IncentivarSuscripcionBanner;