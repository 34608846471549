let HistorialCredencialTableModel = {
  id: {
    value: "",
    name: "#",
  },
  fecha: {
    value: "",
    name: "Fecha",
  },
  evento: {
    value: "",
    name: "Evento",
  },
  origen: {
    value: "",
    name: "Origen",
  },
  buttonProps: [
    {
      icon: "arrow-right-line",
      size: "small",
      className: "--tertiary",
      iconSize: "s",
    },
  ],
};

export default HistorialCredencialTableModel;
