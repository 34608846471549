import React from "react";
import PropTypes from "prop-types";
import "../../../../Statics/Scss/Components/TableCells.scss";

const propTypes = {
  mod: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.string,
  cellSticky: PropTypes.oneOf(["first", "last"]),
};

const defaultProps = {
  align: "a-left",
  mod: "--fourxs --arial --font-bold",
};

const TableHeaderCell = ({ children, mod, cellSticky, ...r }) => {
  const className = `com-table-header-cell${mod ? ` ${mod}` : ""}${
    cellSticky ? ` --${cellSticky}` : ""
  }`;

  return (
    <th className={className} {...r}>
      {children}
    </th>
  );
};

TableHeaderCell.propTypes = propTypes;
TableHeaderCell.defaultProps = defaultProps;

export default TableHeaderCell;
