let Beneficio = {
    date: {
        value: '',
    },
    desc: {
        value: '',
        cellProps: {},
        elementProps: {
            className: 'com-colorBox --blue',
        },
    },
    comercio: {
        value: '',
        elementProps: {
            className: '--tt-capitalize',
        },
    },
    rubro: {
        value: '',
    },
};

export default Beneficio;
