import { useState } from 'react';
import Datadog from '../Helpers/Datadog';
import ApiService from '../Services/ApiService';
import SessionHandler from '../../src/Handlers/Session';
import { useDispatch } from '../Store/storeProvider';

export const useGuardarEventoProductoPremium = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const fetchData = (evento, callback) => {
        setError(false);
        setIsLoading(true);
        Datadog.trackCustomEvent('guardar-evento-bonificado-payload', { evento }); //sacar log debug
        ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripcionesV1/GuardarEventoProductoPremiumAdq`, evento)
            .then((data) => {
                if (data && data.code === '0000') {
                    Datadog.trackCustomEvent('guardar-evento-alta-bonificado');
                    SessionHandler.Reload(dispatch);
                    callback();
                } else {
                    Datadog.trackCustomEventWarning('error-guardarEvento-bonificado', { error: data.message, code: data.code });
                    //del refactor estabamos enviando dos eventos en caso de error en dos .then distintos de la misma promise
                    //Datadog.trackCustomEventWarning('errorCode-enviar-evento-alta-bonificado', { error: response.message, code: response.code });
                    setError(true);
                }
            })
            .catch((error) => {
                Datadog.trackCustomEventWarning('error-guardarEvento-bonificado', { error: error });
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return [error, isLoading, fetchData];
};

export default useGuardarEventoProductoPremium;