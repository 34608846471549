import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const DomicilioSchema = Yup.object().shape({
    calle: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val === 'COUNTRY-BARRIO-CLUB DE CAMPO',
            then: Yup.string(),
            otherwise: Yup.string().required('El domicilio es requerido'),
        }),
    altura: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val === 'COUNTRY-BARRIO-CLUB DE CAMPO',
            then: Yup.string(),
            otherwise: Yup.string().matches(/^[0-9]{1,}$/, "Ingresá la altura correctamente.")
                .min(1, "Ingresá al menos ${min} números")
                .max(5, "Ingresá hasta ${max} números")
                .required('El Número es requerido'),
        }),
    codigoPostal: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val === 'COUNTRY-BARRIO-CLUB DE CAMPO',
            then: Yup.string(),
            otherwise: Yup.string().matches(/^[0-9]{3,}$/, "Ingresá un código postal sin letras.")
                .min(4, "Ingresá al menos ${min} números")
                .max(4, "Ingresá hasta ${max} números")
                .required('El código postal es requerido'),
        }),
    entreCalle1: Yup.string()
        .when('tipoDePropiedad', {
            is: val => val === "COUNTRY-BARRIO-CLUB DE CAMPO",
            then: Yup.string().required('La entre calle es requerida'),
            otherwise: Yup.string().nullable()
                .required('La entre calle es requerida')
                .matches(/^[ A-Za-z0-9]+$/g, 'No se aceptan caracteres especiales'),
        }),
    entreCalle2: Yup.string()
        .when('tipoDePropiedad', {
            is: val => val === "COUNTRY-BARRIO-CLUB DE CAMPO",
            then: Yup.string().required('La entre calle es requerida'),
            otherwise: Yup.string().nullable()
                .required('La entre calle es requerida')
                .matches(/^[ A-Za-z0-9]+$/g, 'No se aceptan caracteres especiales'),
        }),
    localidad: Yup.string().nullable()
        .required('La Localidad es requerida'),
    barrio: Yup.string()
        .required('El Barrio es requerido'),
    tipoDePropiedad: Yup.string()
        .required('Campo requerido'),
    piso: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val !== 'CASA',
            // then: Yup.string().required('El Piso es requerido'),
            otherwise: Yup.string(),
        }),
    dto: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val !== 'CASA',
            // then: Yup.string().required('El Departamento es requerido'),
            otherwise: Yup.string(),
        }),
    nombreCountry: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val === 'COUNTRY-BARRIO-CLUB DE CAMPO',
            then: Yup.string().required('Campo requerido'),
            otherwise: Yup.string(),
        }),
    nombreComplejo: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => (val === 'COMPLEJO-MONOBLOCK' || val === "COMERCIO"),
            then: Yup.string().required('Campo requerido'),
            otherwise: Yup.string(),
        }),
    torre: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val === 'COMPLEJO-MONOBLOCK',
            then: Yup.string().required('La Torre es requerida'),
            otherwise: Yup.string(),
        }),
    piso: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val !== 'COUNTRY-BARRIO-CLUB DE CAMPO' && val !== 'CASA',
            // then: Yup.string().required('El Piso es requerido'),
            otherwise: Yup.string(),
        }),
    dto: Yup.string()
        .when('tipoDePropiedad', {
            is: (val) => val !== 'COUNTRY-BARRIO-CLUB DE CAMPO' && val !== 'CASA',
            // then: Yup.string().required('El Departamento es requerido'),
            otherwise: Yup.string(),
        }),
    torre: Yup.string()
        .test("escalera", "Por favor completá la Torre y/o el", function () {
            return (this.parent.escalera || this.parent.cuerpo || this.parent.torre) || this.parent.tipoDePropiedad !== 'COMPLEJO-MONOBLOCK';
        }),
    cuerpo: Yup.string()
        .test("escalera", "Cuerpo y/o la Escalera del complejo", function () {
            return (this.parent.escalera || this.parent.torre || this.parent.cuerpo) || this.parent.tipoDePropiedad !== 'COMPLEJO-MONOBLOCK';
        }),
    escalera: Yup.string()
        .test("escalera", "    ", function () {
            return (this.parent.cuerpo || this.parent.torre || this.parent.escalera) || this.parent.tipoDePropiedad !== 'COMPLEJO-MONOBLOCK';
        }),
    observaciones: Yup.string().max(100, 'La observación no puede tener más de 100 caracteres'),
});

export default DomicilioSchema;