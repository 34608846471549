import React from 'react';
import CheckInput from '../Components/Common/Atoms/Form/CheckInput';
import InputBox from '../Components/Common/Atoms/Form/InputBox';

const Map = ({ suscripciones, clickHandler, suscripcionSeleccionada, disabled, invalid }) => {
    const esSuscripcionSeleccionada = (sus, id) => sus?.id === id;

    return suscripciones.map((suscripcion, key) => {
        return (
            <InputBox
                key={key}
                value={suscripcion.id}
                error={invalid}
                success={suscripcionSeleccionada && suscripcionSeleccionada.id === suscripcion.id}
                disabled={disabled}
                input={
                    <CheckInput
                        onChange={clickHandler}
                        value={suscripcion.id}
                        disabled={disabled}
                        checked={esSuscripcionSeleccionada(suscripcionSeleccionada, suscripcion.id)}
                    >
                        {suscripcion.nombre}
                    </CheckInput>
                }
            />
        );
    });
};

export default Map;
