import React, { useEffect, useState, useRef, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../../../../Statics/Scss/Components/InputAutocomplete.scss';

import Input from './Input';
import Text from '../../Text/Text';
import UseOnClickOutside from '../../../../Helpers/UseOnClickOutside';

const propTypes = {
    /**
     * Lista de datos a sugerir.
     */
    data: PropTypes.array,
    /**
     * Flag, ¿está deshabilitado?
     */
    disabled: PropTypes.bool,
    /**
     * Default value.
     */
    value: PropTypes.string,
    /**
     * Handler para la selección.
     */
    handleSelected: PropTypes.func,
    /**
     * Handler para el clear.
     */
    handleClearValue: PropTypes.func,
    /**
     * Handler de inicialiación.
     */
    handleInit: PropTypes.func,
    /**
     * Handler para el tipeo.
     */
    handleChange: PropTypes.func,
    /**
     * Nombre del icono.
     */
    icon: PropTypes.string,
    /**
     * Tamaño del icono.
     */
    iconSize: PropTypes.string,
    /**
     * Placeholder asociado al input.
     */
    placeholder: PropTypes.string,
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
    /**
     * Empieza a desplegar resultados
     * a partir de 'n' cantidad de caracteres.
     */
    startsAt: PropTypes.number,
    /**
     * Render para cuando no se
     * encuentran resultados.
     * Default: 'No existen sugerencias.'.
     */
    noSuggestions: PropTypes.element,
    /**
     * Render para las búsquedas
     * recientes.
     */
    recentSearches: PropTypes.element,
    /**
     * Handler callback para cuando es seleccionado un item.
     */
    handleOnSelection: PropTypes.func,
};

const defaultProps = {
    handleSelected: () => {},
    handleClearValue: () => {},
    handleInit: () => {},
    startsAt: 0,
    data: [],
};

const InputAutocomplete = forwardRef(
    (
        {
            id,
            value,
            data,
            handleSelected,
            handleClearValue,
            handleInit,
            handleChange,
            disabled,
            iconDisabled,
            icon,
            iconSize,
            placeholder,
            className,
            startsAt,
            noSuggestions,
            recentSearches,
            handleOnSelection,
            isLoading,
            ...r
        },
        ref
    ) => {
        const [filteredSuggestions, setFilteredSuggestions] = useState([]);
        const [activeSuggestion, setActiveSuggestion] = useState(null);
        const [showSuggestions, setShowSuggestions] = useState(false);
        const [showRecents, setShowRecents] = useState(false);
        const [userInput, setUserInput] = useState('');
        const [input, setInput] = useState((data?.length > 0 && data.find((d) => d.value === value)?.name) || '');
        const [showSinResultados, setShowSinResultados] = useState(false);
        const [mouseOver, setMouseOver] = useState(false);
        const recentSearchesRef = useRef();
        const noSuggestionsRef = useRef();
        const inputAutocompleteRef = useRef(null);
        const classes = classNames('input-autocomplete', className);

        useEffect(() => {
            if (value !== '' && data?.length > 0 && data.find((d) => d.value === value) !== undefined) {
                setInput(data.find((d) => d.value === value)?.name);
            }
        }, [data, value]);

        useEffect(() => {
            if (data && data.length > 0) {
                setFilteredSuggestions(data);
            } else {
                setFilteredSuggestions([]);
                setShowSuggestions(false);
            }
        }, [data]);

        useEffect(() => {
            if (handleInit) handleInit();
        }, [handleInit]);

        useEffect(() => {
            if (input === '' && handleClearValue) {
                handleClearValue();
            }
        }, [input, handleClearValue]);

        useEffect(() => {
            const notSuggestions = (e) => {
                if (inputAutocompleteRef.current && showSuggestions && !inputAutocompleteRef.current.contains(e.target)) {
                    setShowSuggestions(false);
                    setFilteredSuggestions([]);
                    setShowSinResultados(false);
                }
            };
            document.addEventListener('mousedown', notSuggestions);
        }, [showSuggestions]);

        UseOnClickOutside(recentSearchesRef, () => {
            if (showRecents) setShowRecents(false);
        });

        UseOnClickOutside(noSuggestionsRef, () => {
            if (showSinResultados) setShowSinResultados(false);
        });

        const closeOptions = () => {
            setShowSuggestions(false);
            setInput('');
            setShowSinResultados(false);
        };

        const onFocus = () => {
            setShowRecents(!!JSON.parse(localStorage.getItem('recent_searches')?.length));
        };

        const onChange = (e) => {
            const suggestions = data;
            const userInput = e?.currentTarget?.value;
            setInput(userInput);

            const filteredSuggestions = suggestions?.filter((suggestion) =>
                Array.isArray(suggestion?.subItems)
                    ? {
                          ...suggestion,
                          subItems: suggestion?.subItems?.filter((item) => ~item?.name?.toLowerCase().indexOf(userInput.toLowerCase())),
                      }.subItems.length
                    : ~suggestion?.name?.toLowerCase().indexOf(userInput.toLowerCase())
            );

            setFilteredSuggestions(handleChange ? handleChange(userInput) : filteredSuggestions);
            setShowSuggestions(true);
            setUserInput(e.currentTarget.value);
            setShowSinResultados(filteredSuggestions === undefined || filteredSuggestions.length === 0);
        };

        const onClick = (e) => {
            const input = document.querySelector('.input-autocomplete input');
            const selectedItem = filteredSuggestions?.find((f) => {
                f.Id === e.currentTarget.id;
            });

            setFilteredSuggestions([]);
            setShowSuggestions(false);
            setUserInput(e.currentTarget.innerText);
            setInput(selectedItem?.name);
            setShowSinResultados(false);
            handleSelected(selectedItem || userInput);

            input.value = e.currentTarget.innerText;

            handleOnSelection(e.currentTarget.id);
        };

        const onKeyDown = (e) => {
            if (!mouseOver) {
                if (e.code === 'Enter' && showSuggestions) {
                    const input = document.querySelector('.input-autocomplete .input input');

                    const selectedItem = filteredSuggestions[activeSuggestion];

                    setShowSuggestions(false);
                    setUserInput(selectedItem?.name);
                    setInput(selectedItem?.name);
                    setShowSinResultados(false);
                    handleSelected(selectedItem || userInput);
                    handleOnSelection(selectedItem?.Id);
                    input.value = selectedItem?.name || userInput;
                } else if (e.code === 'ArrowUp') {
                    const suggestList = document.querySelector('.suggestions');
                    const active = suggestList.querySelector('.--active');

                    if (activeSuggestion === 0) {
                        setActiveSuggestion(filteredSuggestions?.length - 1);
                        suggestList.scrollTop = 1000;
                    } else {
                        setActiveSuggestion(Math.max(activeSuggestion - 1, 0));
                        suggestList.scrollTop = active.offsetTop - 200;
                    }
                } else if (e.code === 'ArrowDown') {
                    const suggestList = document.querySelector('.suggestions');
                    const active = suggestList.querySelector('.--active');

                    if (activeSuggestion + 1 === filteredSuggestions?.length) {
                        setActiveSuggestion(0);
                        suggestList.scrollTop = -1000;
                    } else {
                        setActiveSuggestion(Math.min(activeSuggestion === null ? 0 : activeSuggestion + 1, filteredSuggestions?.length - 1));
                        suggestList.scrollTop = active.offsetTop - 130;
                    }
                } else if (e.code === 'Escape') closeOptions();
            }
        };

        const renderSuggestionsList = () => {
            if (filteredSuggestions?.length > 0 && input.length >= startsAt) {
                return (
                    <div ref={inputAutocompleteRef}>
                        {showSuggestions && data.length > 0 && (
                            <ul className="suggestions">
                                {filteredSuggestions.map((suggestion, index) => {
                                    let className = 'suggestion --arial --fourxs';
                                    const hasSubItems = suggestion.subItems?.length;

                                    if (index === activeSuggestion) className += ' --active';

                                    return (
                                        <li
                                            className={hasSubItems ? 'suggestion parent' : className}
                                            key={suggestion.Id}
                                            {...(!hasSubItems && {
                                                id: suggestion.Id,
                                            })}
                                            {...(!hasSubItems && {
                                                onClick,
                                            })}
                                            tabIndex={index}
                                            onMouseOver={() => {
                                                setActiveSuggestion(null);
                                                setMouseOver(true);
                                            }}
                                            onMouseLeave={() => {
                                                setMouseOver(false);
                                            }}
                                        >
                                            <a
                                                {...(!hasSubItems && {
                                                    onClick,
                                                })}
                                            >
                                                {suggestion.Barrio ? suggestion.Barrio : suggestion.name}
                                            </a>
                                            {!!hasSubItems && (
                                                <ul>
                                                    {suggestion.subItems?.map(({ value, name }) => (
                                                        <li className={className} key={value} id={value}>
                                                            <a onClick={onClick}>{name}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                );
            } else if (showRecents) {
                return (
                    recentSearches !== null && (
                        <div className="suggestions" ref={recentSearchesRef}>
                            {recentSearches}
                        </div>
                    )
                );
            } else if (isLoading && input.length >= startsAt) {
                return (
                    <div className="suggestions no-suggestions">
                        <Text size="4xs">Buscando resultados...</Text>
                    </div>
                );
            } else if (showSinResultados) {
                return (
                    <div className="suggestions no-suggestions" ref={noSuggestionsRef}>
                        <Text size="4xs">Sin resultados</Text>
                    </div>
                );
            }
        };

        return (
            <div className={classes}>
                <Input
                    autoComplete="off"
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => onKeyDown(e)}
                    disabled={disabled}
                    value={input}
                    placeholder={placeholder}
                    ref={ref}
                    id={id}
                    onFocus={onFocus}
                    {...((showSuggestions || showSinResultados || input) && {
                        rightIcon: 'icon-close',
                    })}
                    {...((showSuggestions || showSinResultados || input) && {
                        funcIconDer: closeOptions,
                    })}
                    {...r}
                />
                {renderSuggestionsList()}
            </div>
        );
    }
);

InputAutocomplete.prototypes = propTypes;
InputAutocomplete.defaultProps = defaultProps;

export default InputAutocomplete;
