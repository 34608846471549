import React from 'react';
import Paragraph from '../../Atoms/Paragraph';
import Button from '../Button';
import '../../../../Statics/Scss/Modules/ItemsBills.scss';

const ItemsBills = ({ item, onRowClick }) => {
    return (
        <>
            <div className="containerItemsBills --flex-inline --mb-16 --font-bold --arial">
                <div className="com-paragraphBills">
                    <Paragraph>Mes de Facturación</Paragraph>
                    <Paragraph>{item.date.value}</Paragraph>
                </div>
                <div className="com-paragraphBills">
                    <Paragraph>Número de factura</Paragraph>
                    <Paragraph>{item.number.value}</Paragraph>
                </div>
                <Button
                    onClick={() => onRowClick(item)}
                    label={<span className="downloadLabelBills">{item.buttonProps[0].text}</span>}
                    icon={item.buttonProps[0].icon}
                    href={item.buttonProps[0].href}
                    tag="button"
                ></Button>
            </div>
        </>
    );
};

export default ItemsBills;
