import { useState } from "react";
import ApiService from "../Services/ApiService";
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";

const useAsignarCredencial = () => {
  let { REACT_APP_APIRECLAMOS } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const asignarCredencial = async (data, tipoCredencial) => {
    const pathUrl = `${REACT_APP_APIRECLAMOS}credenciales/AsignarCredencialAClientePorGestion`;
    setIsLoading(true);
    return ApiService.post(pathUrl, data)
      .then((response) => {
        if (response.Code == 0) {
          Datadog.trackCustomEvent(CustomEventsDatadog.otorgarCredencial, { tipoCredencial });
          setData(response.Response);
          setIsLoading(false);
        } else {
          Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorOtorgarCredencial, { tipoCredencial}); 
          setIsLoading(false);
          setError(response);
        }

        return Promise.resolve(response);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
        Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorOtorgarCredencial, { error }); 
        return Promise.reject(error);
      });
  };

  return [data, error, isLoading, asignarCredencial];
};

export default useAsignarCredencial;
