import { useMemo } from 'react';

const assignRef = (ref, value) => {
	if (ref == null) return;

	if (typeof ref === 'function') {
		ref(value);
		return;
	}

	try {
		ref.current = value;
	} catch (error) {
		throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
	}
};

const useMergeRefs = (...refs) => {
	return useMemo(() => {
		if (refs.every(ref => ref == null)) return null;

		return node => {
			refs.forEach(ref => {
				if (ref) assignRef(ref, node);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, refs);
};

export default useMergeRefs;
