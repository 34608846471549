import { useState } from 'react';
import ApiService from '../Services/ApiService';
import Datadog from "../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../Config/Datadog";

const useActualizarClaveMiCuenta = () => {
    let { REACT_APP_APILOGIN } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    const sendData = async (data) => {
        const pathUrl = `${REACT_APP_APILOGIN}Auth0/ChangeUserPassword`;
        setIsLoading(true);
        setError(null);
        setData(null);
        ApiService.post(pathUrl, {"Password": data.ClaveVieja, "NewPassword": data.ClaveNueva, "Recaptcha": data.Recaptcha})
            .then(response => {
                if (response.code == 0) {
                    Datadog.trackCustomEvent(
                        CustomEventsDatadog.cambiarContrasena
                    );
                    setData(response);
                    setIsLoading(false);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCambiarContrasena, {codigo: response.code, error: response.message});
                    setIsLoading(false);
                    setError(response);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error);
            });
    };

    return [data, error, isLoading, sendData];

};


export default useActualizarClaveMiCuenta;

