const GoogleTagManagerService =
{
    trackPageview: function (pagePath, title, pageName, additional) {
        window.dataLayer = window.dataLayer || [];
        let data = {
            'event': 'trackPage',
            'pagePath': pagePath,
            'pageTitle': title,
            'pageName': pageName
        };
        if (!additional) {
            window.dataLayer.push(data);
        }
        else {
            const returnedTarget = Object.assign(data, additional);
            window.dataLayer.push(returnedTarget);
        }
    },

    trackClick: function (category, action, label, additional) {
        let data = {
            'event': 'trackClick',
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label
        };
        const returnedTarget = Object.assign(data, additional);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(returnedTarget);
    },

    trackEvent: function (event, category, action, label, additional) {
        let data = {
            'event': event,
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label
        };
        const returnedTarget = Object.assign(data, additional);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(returnedTarget);
    },

    trackCustomEvent: function (event, otherParams){
        let data = {
            'event': event,
        }
        const returnedTarget = Object.assign(data, otherParams);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(returnedTarget);
    }
}

export default GoogleTagManagerService;
