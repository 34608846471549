import Embed from '../Models/Embed';

const Send = (request) => {
    if (Embed) {
        console.log('AppEvent');
        console.log(request);
        try {
            webkit && webkit.messageHandlers.ios.postMessage(JSON.stringify(request));
        } catch (err) {
            console.error('Error IOs: ' + err.message);
        }
        try {
            android && android.performClick(JSON.stringify(request));
        } catch (err) {
            console.error('Error Android: ' + err.message);
        }
    }
    return Embed;
};

export default Send