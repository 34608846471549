import React, { useState, useRef, useEffect } from 'react';
import './Statics/Scss/Styles.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Routes from './Routes/Routes';
import Layout from './Components/Common/Organism/Layout';
import { CookieContext } from './Contexts/CookieContext';
import Hotjar from './Handlers/Hotjar';
import './Helpers/Datadog';
import NotFoundLayout from './Components/Common/Organism/NotFoundLayout';
import PrivateRoute from './Components/Features/PrivateRoute';

import Home from './Pages/Home';
import Header from './Components/Common/Organism/Header';
import Footer from './Components/Common/Organism/Footer';
import EmbedHide from './Components/Common/Organism/EmbedHide';

import Logout from './Handlers/_Logout';
import { DrawerAccountContent } from './Components/Common/Organism/DrawerAccount/DrawerAccountContent';
import Modal from './Components/Common/Organism/Modal';
import ModalWizard from './Pages/ModalWizard';
import useGetSwitchsStates from './Hooks/useGetSwitchsStates';
import { useDispatch } from './Store/storeProvider';
import { IsUserLogInAsync } from '@ln/user.client.libs';
import Url from './Helpers/Url';

const App = () => {
    if (!window.dataLayer) window.dataLayer = [];

    if (!window.hj) Hotjar.HotjarRun();
    const [updateCookie, setUpdateCookie] = useState(false);
    const dispatch = useDispatch();
    const [menuVisibility, setMenuVisibility] = useState(false);
    const el = useRef(null);
    const [showModalSteps, setShowModalSteps] = useState(false);
    const [visibleSubscriberAttentions, setVisibleSubscriberAttentions] = useState(false);
    const toggleVisible = () => setVisibleSubscriberAttentions(!visibleSubscriberAttentions);
    const [switchsStates, errorSwitchsStates, isLoadingSwitchsStates] = useGetSwitchsStates();
    const handleCloseModal = () => {
        setShowModalSteps(false);
    };
    const closeMenu = () => {
        setTimeout(() => setMenuVisibility(!menuVisibility), 700);
        document.body.classList.remove('menu-open');
    };

    const openMenu = () => {
        setMenuVisibility(!menuVisibility);
        document.body.classList.add('menu-open');
    };

    const handleMenuVisibilityChange = () => {
        if (menuVisibility) closeMenu();
        else openMenu();
    };

    const addBackDrop = (e) => {
        if (menuVisibility && el && !el.current.contains(e.target)) closeMenu();
    };

    const isUserLogIn = async () => {
        const isLogIn = await IsUserLogInAsync();
        if (!isLogIn) {
            Url.RedirectToLoginPreserveCallback();
        }
    };

    useEffect(() => {
        isUserLogIn();
        window.addEventListener('click', addBackDrop);
        return () => {
            window.removeEventListener('click', addBackDrop);
        };
    }, []);

    const handleLogout = () => {
        Logout.Logout(true);
    };

    useEffect(() => {
        if (!errorSwitchsStates) {
            dispatch({
                type: 'SwitchsStates',
                value: switchsStates
            })
        }
    }, [isLoadingSwitchsStates])

    return (
        /*Para el seteo de cookies sin tener que pasar por todos los componentes. Usar hook useContext()*/
        <>
            <CookieContext.Provider value={[updateCookie, setUpdateCookie]}>
                    <Router>
                        <Switch>
                            <Route path="/" exact>
                                <EmbedHide>
                                    <Header
                                        setMenuVisibility={handleMenuVisibilityChange}
                                        setVisibleSubscriberAttentions={setVisibleSubscriberAttentions}
                                    />
                                </EmbedHide>
                                <div className="container">
                                    <Home />
                                    <DrawerAccountContent
                                        handleLogout={handleLogout}
                                        setShowModalSteps={setShowModalSteps}
                                        visibleSubscriberAttentions={visibleSubscriberAttentions}
                                        toggleVisible={toggleVisible}
                                    />
                                </div>
                                <EmbedHide>
                                    <Footer />
                                </EmbedHide>
                                {showModalSteps && (
                                    <Modal closeModal={handleCloseModal} classes="--none">
                                        <ModalWizard close={handleCloseModal} />
                                    </Modal>
                                )}
                            </Route>
                            <Layout>
                                <Switch>
                                    {Routes.map((route, i) => (
                                        <PrivateRoute path={route.path} key={i} exact={true} component={route.component} />
                                    ))}
                                    <Route component={NotFoundLayout} />
                                </Switch>
                            </Layout>
                        </Switch>
                    </Router>
            </CookieContext.Provider>
        </>
    );
};

export default App;
