import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@ln/experiencias-ui-image'
import premiumDuo from '../../../Statics/Imgs/duo_premium.png';
import premiumTriple from '../../../Statics/Imgs/triple_premium.png';
import blackFamiliar from '../../../Statics/Imgs/black_familiar.png';
//promo images
// import premiumDuo from '../../../Statics/Imgs/promos/promoDuo-HotSale2023.png';
// import premiumTriple from '../../../Statics/Imgs/promos/promoTriple-HotSale2023.png';
// import blackFamiliar from '../../../Statics/Imgs/promos/promoFamiliar-HotSale2023.png';


function ImagenSuperior(props) {
    const src = getUrlImagen(props.cantidad);

    if (!src) return <></>

    return <Image src={src} className='ratio-14-3' />;
}

const getUrlImagen = (key) => {
    // TODO: Descomentar para mostrar la imagen
    // if (key in urlImagen) return urlImagen[key];
    return urlImagen['default'];
};

const urlImagen = {
    2: premiumDuo,
    3: premiumTriple,
    4: blackFamiliar,
    default: '',
};

ImagenSuperior.propTypes = {
    cantidad: PropTypes.number,
};

export default ImagenSuperior;
