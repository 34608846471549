import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';

import Pagination from './Pagination';

const propTypes = {
	/**
	 * Página activa inicial.
	 */
	initialActivePage: PropTypes.number,
	/**
	 * Cantidad de items por página.
	 */
	itemsPerPage: PropTypes.number,
	/**
	 * Cantidad de items por página.
	 */
	items: PropTypes.array,
	/**
	 * Handler para cuando se clickee una page.
	 */
	onPageClick: PropTypes.func,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Flag, ¿clonar las props de la paginación?
	 */
	clonePaginationProps: PropTypes.bool,
};

const defaultProps = {
	initialActivePage: 0,
	itemsPerPage: 8,
	onPageClick: () => {},
};

const PaginationWrapper = ({
	children,
	initialActivePage,
	items,
	itemsPerPage,
	onPageClick,
	onPageChange,
	className,
	clonePaginationProps,
	...r
}) => {
	const [activePage, setActivePage] = useState(initialActivePage || 0);
	const childrenProps = { itemsPerPage, items, onPageChange, activePage };
	const totalItems = items?.length;

	const goToPage = pageNumber => {
		const paginationData = {
			currentPage: pageNumber,
			totalPages: Math.ceil(totalItems / itemsPerPage),
			pageLimit: itemsPerPage,
			totalRecords: totalItems,
		};

		onPageClick(paginationData);
		setActivePage(pageNumber);
	};

	return (
		<>
			{children && cloneElement(children, { ...childrenProps })}
			<Pagination
				activePage={activePage}
				itemsPerPage={itemsPerPage}
				totalItems={items?.length}
				onPageClick={goToPage}
				className={className}
				{...r}
			/>
		</>
	);
};

PaginationWrapper.propTypes = propTypes;
PaginationWrapper.defaultProps = defaultProps;

export default PaginationWrapper;