import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import Toast from '../../Components/Common/Modules/Toast';
import IncentivarAgregarClubBanner from '../../Components/Common/Banners/IncentivarAgregarClub';
import BeneficioExclusivoBanner from '../../Components/Common/Banners/BeneficioExclusivoBanner';
import ErrorServicioYContacto from '../../Components/Common/Banners/ErrorServicioYContacto';
import ContactoDudasBanner from '../../Components/Common/Banners/ContactoDudas';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import Divider from '../../Components/Common/Atoms/Divider';
import TitleWeb from '../../Components/Common/Web/Title';
import useGetPlanesYsuscripciones from '../../Components/Features/MisSuscripciones/useGetPlanesYsuscripciones';
import ContainerCardBox from '../../Components/Features/MisSuscripciones/ContainerCardBox';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { getCondicionSuscripcionUsuario, estadoSuscripcion } from '../../Models/CondicionSuscripcion';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import { SinSuscripciones } from '../../Components/Features/MisSuscripciones/SinSuscripciones';
import { EstadosBonificados } from '../../Config/EstadosBonificados';

const propTypes = { toastCondition: PropTypes.bool };

const MisSuscripciones = (props) => {
    const [planes, suscripciones, suscriptionLinks, esApta, setEsApta, isLoadingPlanesYsuscripciones, errorPlanesYsuscripciones, dataPin, errorPin, evento] =
        useGetPlanesYsuscripciones();
    const [dataRelacionClub, errorRelacionClub, isLoadingRelacionClub] = useObtenerRelacionClub();
    const activarState = evento?.IsBonificada && evento?.EstadoTyC == EstadosBonificados[1]; //tiene bonificados y pendiente de aceptar tyc
    const condicionSuscripcion = getCondicionSuscripcionUsuario(dataRelacionClub?.response);
    const [cambiosGuardados, setCambiosGuardados] = useState(
        props?.history?.location?.state && props?.history?.location?.state?.cambiosGuardados ? props.history.location.state.cambiosGuardados : null
    );

    useEffect(() => {
        const history = createBrowserHistory();
        if (history.location.state && history.location.state.cambiosGuardados) {
            let state = { ...history.location.state };
            delete state.cambiosGuardados;
            history.replace({ ...history.location, state });
        }
    }, []);

    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis suscripciones" text="Ver suscripciones" href="#" />
                <TitleWeb text="Ver suscripciones" />
            </section>

            <section className="container-streched">
                {cambiosGuardados && (
                    <Toast
                        positionFloat="center"
                        closeAuto={3000}
                        status="success"
                        value={cambiosGuardados}
                        changeValue={setCambiosGuardados}
                        title="Tu solicitud fue procesada."
                    >
                        Nos estaremos contactando próximamente para continuar el proceso.
                    </Toast>
                )}
                {!esApta && (
                    <Toast status="alert" positionFloat="center" closeAuto={7000} value={!esApta} changeValue={setEsApta}>
                        Si ya solicitaste la baja de tu/s suscripciones recientemente, te pedimos que por favor aguardes. Nos contactaremos a la
                        brevedad a través de los medios indicados en la solicitud.
                    </Toast>
                )}
                <LoadingSpinner isLoading={isLoadingPlanesYsuscripciones || isLoadingRelacionClub }>
                    <ErrorBoundary error={errorRelacionClub || errorPlanesYsuscripciones}>
                        {activarState && <BeneficioExclusivoBanner evento={evento}/>} 
                        {errorPlanesYsuscripciones ? (
                            <ErrorServicioYContacto title={'Hubo un error al consultar tus suscripciones'} />
                        ) : (
                            <>
                                {suscripciones?.length == 0 &&
                                (condicionSuscripcion === estadoSuscripcion.SIN_SUSCRIPCION || condicionSuscripcion === estadoSuscripcion.CLASSIC) ? (
                                    <>
                                        <SinSuscripciones />
                                    </>
                                ) : (
                                    <>
                                        {suscripciones?.map((sus, index) => {
                                            return (
                                                <ContainerCardBox
                                                    index={index}
                                                    suscription={sus}
                                                    suscriptionLinks={suscriptionLinks}
                                                    planes={planes}
                                                    dataPin={dataPin}
                                                    evento={evento}
                                                />
                                            );
                                        })}

                                        {
                                            //TODO: Si tiene suscripciones digitales y no tiene tarjetas (tiene combo 1):
                                            (condicionSuscripcion === estadoSuscripcion.SIN_SUSCRIPCION ||
                                                condicionSuscripcion === estadoSuscripcion.SUSCRIP_SIN_CLUB) && <IncentivarAgregarClubBanner />
                                        }
                                    </>
                                )}
                            </>
                        )}
                        <Divider />
                        <ContactoDudasBanner />
                    </ErrorBoundary>
                </LoadingSpinner>
            </section>
        </>
    );
};

MisSuscripciones.propTypes = propTypes;

export default MisSuscripciones;
