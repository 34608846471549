import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../Config/RoutePaths';
import Datadog, { trackCustomEvent, trackCustomEventWarning } from '../../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../../Config/Datadog';
import { RecodeBase64SpecialChars } from '../../Helpers/RecodeBase64SpecialChars';
import stepsTrackeoPagosPendientes from '../../Config/StepsTrackeoPagosPendientes';
import usePostPagoPendiente from '../../Hooks/usePostPagoPendiente';
import PostPagoPendiente from '../../Models/PostPagoPendiente';
import useProcesarCobroMinimoTarjetaCIC from './../../Hooks/useProcesarCobroMinimoTarjetaCIC';
import { useDispatch } from '../../Store/storeProvider';

const WondersoftIframe = ({ dataWondersoft, iframeHeight, setIsLoadingCIC }) => {
    const {
        setErrorPagoWS,
        setMensaje,
        idAbandonoCobranza,
        IdTransaccion,
        suscripcionesFormDePago,
        origenCambiarFormaDePago,
        guardarRtaWs,
    } = dataWondersoft;
    const EnvMC2 = process.env.REACT_APP_MYACCOUNT;
    const history = useHistory();
    const formRef = useRef();
    const [respToast, setRespToast] = useState(false);
    const dispatch = useDispatch();
    const [dataCambioFormaDePago, errorCambioFormaDePago, isLoadingCambioFormaDePago, cambiarFormaDePago] = useProcesarCobroMinimoTarjetaCIC();
    const [errorPostPago, guardarPostPagoPendiente] = usePostPagoPendiente();
    useEffect(() => {
        //se crea un event listener que escucha la rta de la api de myaccount, con lo que responde ws
        window.addEventListener('message', handleMessageReceived);
        writeHtml(dataWondersoft);
        if (!origenCambiarFormaDePago) {
            trackCustomEvent(stepsTrackeoPagosPendientes["VER-IFRAME-PAGO"]);
        } else {
            trackCustomEvent(CustomEventsDatadog.visualizacionIframeCambioFormaDePago);
        }
        return () => {
            window.removeEventListener('message', handleMessageReceived);
        };
    }, []);

    const handleMessageReceived = (event) => {
        if (event?.data?.data?.Codrta) {
            if (origenCambiarFormaDePago) {
                //flujo desde cambiarFormaDePago
                handleNewCardEvent(event.data.data);
            } else {
                //flujo desde pagosPendientes
                handlePaymentEvent(event.data.data);
            }
        }
    };

    const fetchStateSuscripciones = () => {
        dispatch({
            type: 'Suscripciones',
            value: {
                fetchSuscripciones: true,
            },
        });
    }

    const handleNewCardEvent = (data) => {
        setIsLoadingCIC(true);
        //se re-convierten ciertos datos, al pasar de api a cliente se pierden algunos caracteres como &!
        const hashConvertido = RecodeBase64SpecialChars(data.hash);
        const passConvertida = RecodeBase64SpecialChars(data.password);
        const tokenConvertido = RecodeBase64SpecialChars(data.token);
        const token_da_Convertido = RecodeBase64SpecialChars(data.token_da);
        let payload = {
            CodigoRespuesta: data.Codrta,
            DescripcionRespuesta: data.Descripcion,
            Hash: hashConvertido,
            NumeroTarjetaEnmascarado: data.mascara,
            Password: passConvertida,
            TokenCobroOnline: tokenConvertido,
            TokenDebitoAutomatico: token_da_Convertido,
        };
        cambiarFormaDePago(payload) 
            .then((resp) => {
                fetchStateSuscripciones();
                console.log('handleNewCardEvent::cambiarFormaDePago: success');
                history.push({
                    pathname: PathsConfig.CambiarFormaDePagoFinalizado,
                    state: {
                        succeed: resp.code == '0000',
                        tarjeta: resp.response?.UltimosNumeros ? resp.response.UltimosNumeros : '',
                        suscripciones: suscripcionesFormDePago,
                        nroGestion: resp.response?.IdGestionCIC ? resp.response.IdGestionCIC : '', //el mensaje de error no se usa en confirmacion
                    },
                });
            })
            .catch((error) => {
                setIsLoadingCIC(false);
                console.error('WondersoftIframe::handleNewCardEvent error:', error);
                history.push({
                    pathname: PathsConfig.CambiarFormaDePagoFinalizado,
                    state: {
                        succeed: false,
                        mensaje: "Ha ocurrido un error", //en caso de catch se debe hacer redirect para matar el listener y no tener un iframe loading infinito
                    },
                });
            });
    };
    
    const handlePaymentEvent = (data) => {
        const checkVal = document.querySelectorAll('input[type="checkbox"]')[0]?.checked;
        trackCustomEvent(stepsTrackeoPagosPendientes["CLICK-CONTINUAR-IFRAME"]);
        const hashConvertido = RecodeBase64SpecialChars(data.hash);
        const passConvertida = RecodeBase64SpecialChars(data.password);
        const tokenConvertido = RecodeBase64SpecialChars(data.token);
        const token_da_Convertido = RecodeBase64SpecialChars(data.token_da);
        const payload = {
            Codrta: data.Codrta,
            Descripcion: data.Descripcion,
            hash: hashConvertido,
            password: passConvertida,
            mascara: data.mascara,
            token: tokenConvertido,
            token_da: token_da_Convertido,
            GuardarTarjetaProximasPresentaciones: checkVal,
        };

        guardarRtaWs(payload)
            .then((response) => {
                PostPagoPendiente.GuardarRtaWsResponse = response;
                if (response.Code == 0) {
                    fetchStateSuscripciones();
                    console.log('WondersoftIframe::handlePaymentEvent: respuesta guardada correctamente');
                    history.push({
                        pathname: PathsConfig.PagosPendientesConfirmacion,
                        state: {
                            idAbandonoCobranza: idAbandonoCobranza,
                            idRechazo: IdTransaccion,
                            guardarTarjetaChecked: !!checkVal
                        },
                    });
                } else {
                    trackCustomEventWarning(CustomEventsErrorDatadog.errorPagosPendientes, { response });
                    setMensaje('No pudimos procesar tu pago. Por favor, verificá los datos ingresados o intentá con otro medio de pago.'); //resp.Message devuelve mensaje de error (hash invalido etc));
                    setRespToast(true);
                    setErrorPagoWS(true);
                }
            })
            .catch((error) => {
                trackCustomEventWarning(CustomEventsErrorDatadog.errorPagosPendientes, { error });
                PostPagoPendiente.MsgFrontError = `WondersoftIframe::handlePaymentEvent - error:  ${error}`;
                console.error('WondersoftIframe::handlePaymentEvent - error: ', error);
            })
            .finally(() => {
                PostPagoPendiente.Codrta = data.Codrta;
                PostPagoPendiente.Descripcion = data.Descripcion;
                PostPagoPendiente.Hash = hashConvertido;
                PostPagoPendiente.Mascara = data.mascara;
                PostPagoPendiente.Token = tokenConvertido;
                PostPagoPendiente.TokenDa = token_da_Convertido;
                guardarPostPagoPendiente(PostPagoPendiente).catch((error) => {
                    //guarda data pago en la db
                    if (error) {
                        console.log('Error al guardar post pago', error);
                    }
                });
            });
    };

    const writeHtml = (data) => {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = data.UrlWondersoftPost;
        form.target = 'ws-iframe';
        const redireccion = origenCambiarFormaDePago ? `${EnvMC2}post-tarjeta` : `${EnvMC2}procesar-cobro`;
        //url buscada cuando se procesa el pago desde ws

        const params = {
            HASH: data.Hash,
            PASSWORD: data.Password,
            URLREDIRECCION: redireccion,
            TOKEN: data.Token,
            TIPOTRAN: data.TipoTransaccion,
        };

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        formRef.current.innerHTML = form.submit();
    };

    return (
        <>
            <iframe
                name="ws-iframe"
                scrolling="yes"
                height={iframeHeight}
                width={350}
                frameBorder="0"
                // ref={writeHtml(props)}
                ref={formRef}
            ></iframe>
        </>
    );
};

export default WondersoftIframe;
