import React from 'react'
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas'
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle'
import Toast from '../../../Components/Common/Modules/Toast'
import { mailAtencionSocios } from '../../../Config/DatosDeContacto'


const ErrorReclamoAccesoDigital = () => {
    return (
        <>
            <Toast status={'warning'} title={"¡Ups!"}>No pudimos procesar la información.</Toast>
            <CallbackArticle
                title={"Información no procesada"}
                description={
                    <p className="--arial --font-regular --twoxs">
                        La información solicitada no pudo ser procesada, por favor volvé a intentarlo más tarde. Si el error persiste comunicate con nosotros a {' '}
                        <span className="email">
                            <strong>{mailAtencionSocios}</strong>
                        </span>
                    </p>
                }
            />
            <ContactoDudasBanner />
        </>)

}
export default ErrorReclamoAccesoDigital