import React, { useState, useEffect } from 'react';
import ApiService from '../Services/ApiService';

const useListarEventos = () => {
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoadinghistorial, setIsLoadinghistorial] = useState(true);
    const [historial, sethistorial] = useState(null);
    const [errorhistorial, setErrorhistorial] = useState(null);

    const outFunc = async () => {
        ApiService.post(`${REACT_APP_APIRECLAMOS}credenciales/ListarEventos`, {
            FechaDesde: null,
            FechaHasta: null,
            IdClaseDeEvento: null,
            NumeroFilas: null,
            Pagina: 1,
        })
            .then((data) => {
                if (data) {
                    if (data.Code === 0) {
                        sethistorial(data.Response);
                        setIsLoadinghistorial(false);
                    } else {
                        setErrorhistorial(true);
                        setIsLoadinghistorial(false);
                    }
                }
            })
            .catch(() => {
                setErrorhistorial(true);
                setIsLoadinghistorial(false);
            });
    };

    return [historial, errorhistorial, isLoadinghistorial, outFunc];
};

export default useListarEventos;