import React from 'react'
import { Mediascroller } from '@ln/common-ui-mediascroller'
import { Button } from '@ln/experiencias-ui-button'
import '@ln/common-ui-mediascroller/index.css'

export const Carousel = ({ children, elementsToScroll = 4 }) => {
  return (
    <Mediascroller
      className="flex flex-column gap-16"
      elementsToScroll={elementsToScroll}
    >
      <Mediascroller.Track fullWidth={false}>
        {children}
      </Mediascroller.Track>
      <Mediascroller.Arrows
        arrowSize={16}
        className='bg-neutral-light-1'
        buttonTag={(props) => (
          <Button variant="secondary" rounded="rounded-circle" {...props} />
        )}
      />
      <Mediascroller.Progress
        containerClassName="w-144 h-5 mx-auto bg-neutral-light-100 rounded-24"
        className="bg-neutral-light-800 rounded-24 transition-linear"
      />
    </Mediascroller>
  )
}
