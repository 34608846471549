import React, { useState } from 'react';

import classNames from 'classnames';
import '../../../../Statics/Scss/Components/InputBox.scss';

const InputBox = ({ input, text, link, className, error, success, disabled, isChecked, mod }) => {
    const [isActive, setIsActive] = useState(false);
    const classes = classNames('com-inputBox', className, {
        'state--error': error,
        'state--success': success,
        'state--disabled': disabled,
        'state--active': (isActive && !success) || isChecked,
        mod,
    });

    return (
        <div className={classes} tabIndex="0">
            <div className="com-inputBox__main">
                <div className="com-inputBox__main__input">{React.cloneElement(input, { setIsActive })}</div>
                {link && <div className="com-inputBox__main__link">{link}</div>}
            </div>
            {text && <div className="com-inputBox__descrip --fivexs">{text}</div>}
        </div>
    );
};

export default InputBox;
