import { useState } from 'react';
import { useHistory } from 'react-router';
import ApiService from '../Services/ApiService';

const useActualizarTelefonos = () => {
    const history = useHistory();
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const outFunc = async (data) => {
        const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/ActualizarTelefonos`;
        setIsLoading(true);
        
        ApiService.post(pathUrl, data)
            .then(response => {
                if (response.Code == 0) {
                    setIsLoading(false);
                    setError(false);
                }
                else{
                    setIsLoading(false);
                    setError(true);
                }
            })
            .catch(() => {
                setIsLoading(false);
                setError(true);
            });
    };

    return [error, isLoading, outFunc];
    
};


export default useActualizarTelefonos;

