import * as Yup from 'yup';

const VendedorReclamaPagoSchema = Yup.object().shape({
    linea: Yup.string()
        .required('Ingresá el número de línea')
        .matches(/^\d+$/, 'Solo se admiten números')
        .max(4, 'No debe exceder los 4 dígitos'),
    distribuidor: Yup.string()
        .required('Ingresá el número de distribuidor')
        .matches(/^\d+$/, 'Solo se admiten números')
        .max(4, 'No debe exceder los 4 dígitos'),
    vendedor: Yup.string()
        .required('Ingresá el número de vendedor')
        .matches(/^\d+$/, 'Solo se admiten números')
        .max(4, 'No debe exceder los 4 dígitos'),
    observacion: Yup.string()
        .max(255, 'La observación no puede exceder los 255 caracteres')
});

export default VendedorReclamaPagoSchema;
