import React, { useState } from 'react';
import Toast from '../../Common/Modules/Toast';
import ErrorServicioYContacto from '../../Common/Banners/ErrorServicioYContacto';
import ButtonDescargarLibreDeuda from './ButtonDescargarLibreDeuda';

const SuscripcionSinSaldo = ({setIsLoadingPdf}) => {
    const [errorDescarga, setErrorDescarga] = useState(false);

    return (
        <>
            {errorDescarga && <ErrorServicioYContacto title={'No pudimos procesar tu solicitud de libre de deuda'} />}
            {!errorDescarga && (
                <Toast status="info" title="¡Genial! Tus pagos están al día">
                    Actualmente no contás con pagos pendientes para saldar.
                </Toast>
            )}

            <ButtonDescargarLibreDeuda setIsLoadingPdf={setIsLoadingPdf} setErrorDescarga={setErrorDescarga}/>
        </>
    );
};

export default SuscripcionSinSaldo;