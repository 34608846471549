import React from 'react';
import './Style.scss';

const FooterSuperCardBox = ({ children }) => {
    return (
        <div className="com-SuperCardBox__footer">
            {children}
        </div>
    );
}

export { FooterSuperCardBox };