const StepsPagosPendientes = {
	step_1: {
		number: 1,
		text: 'Pagos pendientes',
	},
	step_2: {
		number: 2,
		text: 'Metodo de pago',
	},
	step_3: {
		number: 3,
		text: 'Confirmación',
	},
};

export default StepsPagosPendientes;
