import React from "react";
import { useField } from "formik";
import Input from "../Atoms/Form/Input";
import _handleStatusFormik from "../../../Helpers/StatusFormik";

const InputFormik = (props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      <Input {...field} {...props} state={_handleStatusFormik(meta)} />
    </>
  );
};

export default InputFormik;
