import React from 'react'
import { Icon } from '@ln/common-ui-icon'
import { Checked, CloseIcon } from '@ln/experiencias-ui-assets'
import { Text } from '@ln/common-ui-text'

export const ListaBeneficiosModal = ({ beneficios = [], check, title, withoutBlueFirstItem }) => {
  const iconProps = {
    check: {
      size: 20,
      color: 'primary'
    },
    default: {
      size: 12,
      color: 'dark',
      hasWrapper: true,
      bgColor: '#E6E6E6'
    }
  }

  const shouldCheck = (value) => check || (withoutBlueFirstItem ? false : value === 0);

  return (
    <div className='col-span-1 flex flex-column gap-12'>
      <Text className='arial-bold'>{title}</Text>
      <ul>
        {beneficios.map((beneficio, i) => (
          <li key={`beneficio-${i}`} className='flex ai-center gap-8 py-4 text-14'>
            <Icon {...iconProps[(shouldCheck(i)) ? 'check' : 'default']}>
              {(shouldCheck(i)) ? <Checked /> : <CloseIcon />}
            </Icon>
            {beneficio}
          </li>
        ))}
      </ul>
    </div>
  )
}
