import React, { useState, useEffect } from "react";

import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import Toast from "../../Components/Common/Modules/Toast";
import CallbackArticle from "../../Components/Common/Modules/CallbackArticle";
import CallBackArticleLink from "../../Components/Common/Modules/CallBackArticleLink";

import Divider from "../../Components/Common/Atoms/Divider";
import Paragraph from "../../Components/Common/Atoms/Paragraph";
import ProteccionDatosPersonales from "../../Components/Features/MiClub/ProteccionDatosPersonales";
import useObtenerDatosCRM from "../../Hooks/useObtenerDatosCRM";
import useObtenerDatosDomicilio from "../../Hooks/useObtenerDatosDomicilio";
import useActualizarDatosPersonales from "../../Hooks/useActualizarDatosPersonales";
import FormularioDatosPersonales from "../../Components/Features/DatosPersonales/FormularioDatosPersonales";
import LoadingSpinner from "../../Helpers/LoadingSpinner";
import ErrorBoundary from "../../Components/Common/Organism/ErrorBoundary";
import TarjetaDatosPersonales from "../../Components/Features/MiClub/TarjetaDatosPersonales";
import TitleWeb from "../../Components/Common/Web/Title";
import useObtenerDatosUsuario from "../../Hooks/useObtenerDatosUsuario";

const DatosPersonales = () => {
  const [cliente, errorCRM, isLoadingCRM, fetchData] = useObtenerDatosCRM();
  const [domicilios, errorDomicilio, isLoadingDomicilios] = useObtenerDatosDomicilio();
  const [user, userError, userLoading] = useObtenerDatosUsuario();
  const [errorAlGuardar, isLoadingSaveChanges, ActualizarDatos] = useActualizarDatosPersonales();
  const [cambiosGuardados, setCambiosGuardados] = useState(false);

  const GuardarCambiosDatosPersonales = (params) => {
    ActualizarDatos(params, cambiosActualizadosCallback);
  }

  const cambiosActualizadosCallback = (success) => {
    if (success) {
      setCambiosGuardados(success);
      fetchData();
    }
  }
  
   useEffect(() => {
     fetchData();
   }, []);

   
  return (
    <>
      <Breadcrumb firstItem={"Mi cuenta"} href={"#"} text={"Datos Personales"} />
      <TitleWeb text="Datos personales" />
      <div className="container-streched">
        {cambiosGuardados && (
          <Toast
            positionFloat="center"
            value={cambiosGuardados}
            changeValue={setCambiosGuardados}
            closeAuto={3000}
            status="success"
            title="Modificaste tu información personal con éxito."
          />
        )}
        <LoadingSpinner isLoading={isLoadingCRM || isLoadingSaveChanges || userLoading}>
          {!cliente ? (
            <Toast
              status="warning"
              title={"Solamente te pediremos que ingreses tu información personal durante el proceso de suscripción. Esta información solamente sera visible por vos."}
            />
          ) : (
            <ErrorBoundary error={errorCRM || errorDomicilio || errorAlGuardar || userError}>
              {errorAlGuardar}
              <CallbackArticle title="Información personal" description="No vamos a publicar tu información personal, ni tu domicilio." />

              {cliente && cliente.DatosPersonales && (
                <FormularioDatosPersonales onSave={GuardarCambiosDatosPersonales} cliente={cliente.DatosPersonales} emailPrincipal={user.usuario_detalle_email}></FormularioDatosPersonales>
              )}

              <TarjetaDatosPersonales />

              <Divider />
              <LoadingSpinner isLoading={isLoadingDomicilios}>
                <CallbackArticle
                  description={
                    <Paragraph size="--s" mod="--arial --font-bold">
                      Mis domicilios
                    </Paragraph>
                  }
                />
                <div className="--mb-32 --boxCard">
                  {domicilios && domicilios.length > 0 ? (
                    domicilios.map((domicilio) => (
                      <CallBackArticleLink key={domicilio.Id}
                        descrip={
                          <Paragraph size="--fourxs" mod="--arial">
                            {domicilio.Calle} {domicilio.Altura} {domicilio.Localidad} - {domicilio.Provincia} CP {domicilio.CodigoPostal}
                          </Paragraph>
                        }
                      />
                    ))
                  ) : (
                    <Paragraph size="--fourxs" mod="--arial">
                      No tenés domicilios registrados.
                    </Paragraph>
                  )}
                </div>
              </LoadingSpinner>
              <Divider />
              <ProteccionDatosPersonales />
            </ErrorBoundary>
          )}
        </LoadingSpinner>
      </div>
    </>
  );
};

export default DatosPersonales;
