import { useState } from 'react';
import ApiService from '../Services/ApiService';
import { handleActivarPinResponse } from './useActivarPinResponse';
import { useHistory } from 'react-router';
import PathsConfig from '../Config/RoutePaths';

const useRedimirPin = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [pinResponse, setPinResponse] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const pathUrl = `${REACT_APP_APIPAYWALL}Pin/RedeemPin`;
    const history = useHistory();
    const redimirPinExitoso = (pin, crmId) => { 
        history.push({
            pathname: PathsConfig.ConfirmacionActivarSuscripcion,
            state:{
                pin,
                crmId
            }
        });
    }
    const handleRedimirPin = (pin) => {
        setIsLoading(true);
        setError(null);
        ApiService.post(pathUrl, { Pin: pin}).then( async res => { 
            const { code, response } = res
            const crmId = response?.crmId
            if(code === "0001"){
                setError(true)
            }
            setPinResponse(res)
            handleActivarPinResponse(code, pin, redimirPinExitoso, crmId)
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            setError(true)
        })
    };

    return {pinResponse, error, isLoading, handleRedimirPin};

};


export default useRedimirPin;