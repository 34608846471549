import React from 'react';

import '../../../../Statics/Scss/Components/CheckInput.scss';

const CheckInput = ({
	children,
	value,
	error,
	success,
	checked,
	onChange = () => {},
	disabled,
}) => {

	const onClick = e => {
		onChange(e);
	};

	return (
		<label
			className={`com-checkbox ${(error && 'state--error') ||
				''} ${(success && 'state--success') || ''} ${(disabled &&
				'state--disabled') ||
				''}`}>
			<input
				type="checkbox"
				value={value}
				disabled={disabled}
				onChange={onClick}
				checked={checked}
			/>
			<span>{children}</span>
		</label>
	);
};

export default CheckInput;
