import React from 'react';
import TitleWeb from '../../../Components/Common/Web/Title';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import PathsConfig from '../../../Config/RoutePaths';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@ln/experiencias-ui-button';
import Text from '../../../Components/Common/Text/Text';

export const UpsellingAdClubSeleccionarPago = ()=>{ 

    const history = useHistory();
    const { plan } = useParams();

    return(
        <LoadingSpinner isLoading={false}>
            <section>
                <TitleWeb text="Cambiar a Acceso Digital + Club" />
                <Text tag="p" size="5xs" font="arial" weight="bold">
                    Medio de pago screen
                </Text>
                <Button
                    className="state--mobile-w100"
                    onClick={() => {
                        history.push({
                            pathname: `${PathsConfig.UpsellingConfirmar}/${plan}/domicilio`
                        })
                    }}
                >
                    CONTINUAR
                </Button>
            </section>
        </LoadingSpinner>
    )
}