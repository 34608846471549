import Factura from '../Models/DTO/Factura';
import Cookies from 'js-cookie';

const mapFacturasToTable = (facturas, env) => {
    const FacturasMapeadas = [];
    const token = Cookies.get('token');

    facturas.map((factura) => {
        let FacturaFormatted = JSON.parse(JSON.stringify(Factura));

        FacturaFormatted.date.value = factura.fecha;
        FacturaFormatted.number.value = factura.numeroLegal;
        FacturaFormatted.buttonProps[0].href = `${env}Mobile/DescargarFactura?documentId=${factura.numeroLegal}&token=${token}`;

        return FacturasMapeadas.push(FacturaFormatted);
    });

    return FacturasMapeadas;
};

export default mapFacturasToTable;
