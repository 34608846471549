import * as Yup from "yup";

const FormDatosPersonalesSchema = Yup.object().shape({
  nombre: Yup.string(),
  apellido: Yup.string(),
  tipoDocumento: Yup.string(),
  numeroDocumento: Yup.string(),
  genero: Yup.string(),
  fechaCumpleanios: Yup.string().required("Campo obligatorio"),
  celular: Yup.string()
    .matches(/^[0-9]{6,}$/, "Ingresá un telefono celular.")
    .min(8, "Ingresá al menos ${min} números")
    .max(20, "Ingresá hasta ${max} números")
    .nullable()
    .test(
      "Celular",
      "Ingresá al menos un teléfono de contacto",
      function (value) {
        return this.parent.celular || this.parent.particular;
      }
    ),
  particular: Yup.string()
    .matches(/^[0-9]{6,}$/, "Ingresá un telefono particular.")
    .min(8, "Ingresá al menos ${min} números")
    .max(20, "Ingresá hasta ${max} números")
    .nullable()
    .test(
      "Particular",
      "Ingresá al menos un teléfono de contacto",
      function (value) {
        return this.parent.celular || this.parent.particular;
      }
    ),
  email: Yup.string().email("Ingresá un email válido"),
});

export default FormDatosPersonalesSchema;
