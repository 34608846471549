import { Notas } from "../Models/DTO/Notas";

const mapNotasToTable = (notas, ordered = false) => {
  if (notas == null) return [];

  const cantidadTotal = notas.reduce((suma, beneficio) => suma + beneficio.cantidad, 0);

  let porcentajeTotalRedondeado = 0;

  let notasMapeadas = notas.map((beneficio, indice) => {
    let notasFormateadas = JSON.parse(JSON.stringify(Notas));
    const porcentaje = (beneficio.cantidad / cantidadTotal) * 100;
    const porcentajeRedondeado = Math.round(porcentaje);

    // Se filtran los beneficios que se redondean a 0%
    if (porcentajeRedondeado === 0) return null;

    porcentajeTotalRedondeado += porcentajeRedondeado;
    notasFormateadas.categorias.value = beneficio.nombre;
    notasFormateadas.cantidad.value = `${porcentajeRedondeado} %`;

    // Se ajusta el último porcentaje si es necesario
    if (indice === notas.length - 1 && porcentajeTotalRedondeado !== 100) {
      const ajuste = 100 - porcentajeTotalRedondeado;
      notasFormateadas.cantidad.value = `${porcentajeRedondeado + ajuste} %`;
    }

    return notasFormateadas;
  });

  // Se filtran los nulos que se generaron por los redondeos a 0%
  notasMapeadas = notasMapeadas.filter(item => item !== null);

  if (ordered) notasMapeadas.sort((a, b) => parseFloat(b.cantidad.value.replace(' %', '')) - parseFloat(a.cantidad.value.replace(' %', '')));

  return notasMapeadas;
};

export default mapNotasToTable;
