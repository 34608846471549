import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Components/Common/Atoms/Spinner';

const LoadingSpinner = ({ isLoading, children, spinnerSmall, withBody}) => {
    return (
        <React.Fragment>
            {isLoading ? <Spinner spinnerSmall={spinnerSmall} withBody={withBody} /> : children}
        </React.Fragment>
    );
};
LoadingSpinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export default LoadingSpinner;
