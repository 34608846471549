import React from 'react';

import '../../../../Statics/Scss/Components/Textarea.scss';

const Textarea = props => {
	const { state, rightIcon, description, name, ...r } = props;
	const { status, msg } = state || {};
	const rows = props.rows || 10;
	const className = `com-textarea${(status == 'error' &&
		' state--error rightIcon') ||
		''}${(status == 'success' && ' state--success rightIcon') ||
		''}${(rightIcon && ' rightIcon') || ''}`;

	return (
		<div className={className}>
			<textarea
				className="--arial"
				name={name}
				rows={rows}
				{...r}>
			</textarea>

			{/*meta != undefined  && meta.error &&  meta.touched*/}
			{status == 'error' && (
				<i className="com-textarea__rightIcon icon-error-warning"></i>
			)}

			{/*meta != undefined && !meta.error && meta.touched*/}
			{status == 'success' && (
				<i className="com-textarea__rightIcon icon-check-mark"></i>
			)}

			{/*meta != undefined  && meta.error &&  meta.touched*/}
			{status == 'error' ? (
				<span className="com-textarea__descrip --fivexs">{msg}</span>
			) : (
				description && (
					<span className="com-textarea__descrip --fivexs">
						{description}
					</span>
				)
			)}
		</div>
	);
};

export default Textarea;
