import React, { useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import useDrawer from "./useDrawer";

import classNames from "classnames";
import "../../../../Statics/Scss/Modules/Drawer.scss";
import useResizeObserver from "../../../../Helpers/useResizeObserver";
import useMergeRefs from "../../../../Helpers/UseMergeRefs";

const propTypes = {
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * Flag, ¿está abierto?
   */
  isOpen: PropTypes.bool,
  /**
   * Flag, ¿hay contenido cargando?
   * Esperas asíncronas.
   */
  isLoading: PropTypes.bool,
  /**
   * Flag, ¿se puede cerrar?
   */
  isClosable: PropTypes.bool,
  /**
   * Dirección por donde sale.
   */
  direction: PropTypes.oneOf(["bottom", "left", "right"]),
  /**
   * Flag, ¿tiene overlay?
   */
  overlay: PropTypes.bool,
  /**
   * Handler para el cierre
   * del drawer.
   */
  handleClose: PropTypes.func,
  /**
   * Posición del botón de cerrar.
   */
  closePosition: PropTypes.oneOf([
    "top-right",
    "bottom-right",
    "bottom-left",
    "top-left",
  ]),
};

const defaultProps = {
  direction: "bottom",
  handleClose: () => {},
  overlay: true,
  closePosition: "top-right",
};

const Drawer = forwardRef(
  (
    {
      children,
      className,
      isOpen,
      isLoading,
      isClosable,
      buttonBackgroundNone,
      loadingColor,
      direction,
      overlay,
      handleClose,
      closePosition,
      ...r
    },
    ref
  ) => {
    const drawerRef = useRef();
    const mergedRefs = useMergeRefs(drawerRef, ref);
    let dimensions = useResizeObserver({ ref: drawerRef, box: "border-box" });
    const classes = classNames("drawer", className, {
      [`--${direction}`]: direction,
    });
    const props = {
      className: classes,
      style: {
        [direction]: isOpen
          ? "0px"
          : `-${
              direction === "bottom" ? dimensions?.height : dimensions?.width
            }px`,
      },
      ref: mergedRefs,
      role: "dialog",
      tabIndex: "-1",
      ...r,
    };
    const { overlayClasses, drawer } = useDrawer({
      children,
      isOpen,
      isLoading,
      isClosable,
      buttonBackgroundNone,
      loadingColor,
      handleClose,
      props,
      closePosition,
    });

    return overlay ? (
      <section className={overlayClasses}>
        {drawer}
        <article className="layer" onClick={handleClose}></article>
      </section>
    ) : (
      drawer
    );
  }
);

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

export default Drawer;
