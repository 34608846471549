import React, { useEffect, useState } from 'react'
import useConfirmarCambioUpselling from '../../Hooks/useConfirmarCambioUpselling';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../Store/storeProvider';
import Planes from '../../Components/Features/UpSelling/Planes';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import PathsConfig from '../../Config/RoutePaths';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import useGetInfoPlanesUpselling from '../../Hooks/useGetInfoPlanesUpselling';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import Toast from '../../Components/Common/Modules/Toast';
import UrlProperties from '../../Helpers/UrlProperties';
import { CambioPlanCodigo } from '../../Models/CambioPlanCodigo';
import { findKeyPlanUpselling } from './../../Helpers/GetKeyPlanUpselling';
import useGetCampaniasUpsellingOnDemand from '../../Hooks/useGetCampaniasUpsellingOnDemand';
import { getUpsellingKeys } from '../../Config/UpsellingNombresAmigables';
import useObtenerSuscripcionesOnDemand from '../../Hooks/useObtenerSuscripcionesOnDemand';
import useObtenerPlanes from '../../Hooks/useObtenerPlanes';
import { esAptoUpselling, isBonificado } from '../../Filter/suscripciones';
import useObtenerEvento from '../../Hooks/useObtenerEvento';
import useObtenerSuscripcionesDigitalesAnaliticsOnDemand from './../../Hooks/useObtenerSuscripcionesDigitalesAnaliticsOnDemand';
import estadoSuscripciones from '../../Models/EstadoSuscripciones';
import UpsellingConfirmadoBlack from './UpsellingConfirmadoBlack';
import { esSuscriptorDigital } from '../../Helpers/Subscription';
import Date from '../../Helpers/Date';
import TitleWeb from '../../Components/Common/Web/Title';
import SinSuscripcionDigital from '../../Components/Features/UpSelling/SinSuscripcionDigital';
import Mensaje from '../../Models/Mensaje';
import { accesosDigitalesIds } from '../../Config/AccesosDigitalesIds';
import { TipoVentaUpselling } from '../../Models/TipoVenta';

export const UpsellingMejoraTuPlan = (props) => {
    const history = useHistory();
    const { REACT_APP_UPSELL_ADC_MONTHS } = process.env;
    const planes = props.location.state?.planes;
    const campania = props.location.state?.campania;
    const campaniaId = campania?.flatMap(camp => camp.id)[0];
    const [planActual, setPlanActual] = useState(props.location.state?.planActual)
    const [tieneClub, setTieneClub] = useState(props.location.state?.tieneClub)
    const [infoUpselling, errorInfoUpselling, loadingInfoUpselling, getInfoPlanes] = useGetInfoPlanesUpselling();
    const { HandleSwitchsStates } = useStore();
    const [errorConfirmarUpselling, loadingConfirmarUpselling, confirmarUpselling] = useConfirmarCambioUpselling();
    const [planesHook, errorPlanes, isLoadingPlanes] = useObtenerPlanes();
    const [evento, errorEventos, isLoadingEventos] = useObtenerEvento();
    const [plan, setPlan] = useState();
    const planesUpselling = infoUpselling?.filter((plan) => plan.Codigo == CambioPlanCodigo.OK);
    const [esBlack, setEsBlack] = useState();
    const [tieneAccesoDigital, setTieneAccesoDigital] = useState();
    //validaciones si no vienen props(podria armarse un hook que encapsule todo y no duplicarlo)
    const [suscripcionesDA, errorSuscripcionesDA, isLoadingSuscripcionesDA, getSuscripcionesDa] = useObtenerSuscripcionesDigitalesAnaliticsOnDemand();
    const [campaniasUpselling, errorCampaniasUpselling, isLoadingCampaniasUpselling, getCampanias] = useGetCampaniasUpsellingOnDemand();
    const [suscripciones, isLoadingSuscripciones, errorSuscripciones, getSuscripciones] = useObtenerSuscripcionesOnDemand();
    const arrayCampanias = campaniasUpselling?.flatMap(campania => campania.planes);
    const planAdClubId = arrayCampanias?.find(plan => plan.key === getUpsellingKeys.AccesoYClub)?.id;
    const orderGA = ['ga-combo2', 'ga-comboDuo', 'ga-comboTriple', 'ga-comboFamiliar'];
    const gaAD = "ga-combo1";
    const planFamiliar = orderGA[3];
    const campaniaClub = campaniasUpselling?.filter(item =>
        item.planes.some(plane => plane.key === getUpsellingKeys.AccesoYClub)
    );
    const campaniaNoClub = campaniasUpselling?.filter(item =>
        item.planes.every(plane => plane.key !== getUpsellingKeys.AccesoYClub)
    );

    useEffect(() => {
        setEsBlack(!tieneAccesoDigital && (planesUpselling && planesUpselling?.length < 1));
    }, [planesUpselling]);

    useEffect(() => {
        if (planes && campania && planActual) {
            getInfoPlanes({ campania: campaniaId, planes, origen: 'mejora-tu-plan' })
        } else {
            getSuscripciones();
            getCampanias();
            getSuscripcionesDa();
        }
    }, []);

    useEffect(() => {
        if (suscripciones && campaniasUpselling && planesHook && suscripcionesDA) {
            suscripciones.forEach((sus) => {
                if (sus.Estado == estadoSuscripciones.ACTIVA && esAptoUpselling(sus, planesHook) && !sus.payment && !isBonificado(evento, sus, planesHook)) {
                    const subscripcionLN = suscripcionesDA?.find(element => orderGA.includes(element.nombre));
                    const esSoloSuscriptorDigital = esSuscriptorDigital(sus.IdFamiliaDeProducto);
                    const cantidadTiempoSuscripto = Date.validatePeriodByMonth(sus.FechaDesde, Number(REACT_APP_UPSELL_ADC_MONTHS));
                    const upsellingAccesoDigital = esSoloSuscriptorDigital && cantidadTiempoSuscripto;
                    setTieneAccesoDigital(suscripcionesDA[0]?.nombre == gaAD && !upsellingAccesoDigital);
                    if (subscripcionLN == planFamiliar) {
                        setEsBlack(true);
                    } else {
                        if (!infoUpselling && !planActual) {
                            const upsellingGABeginIndex = orderGA.findIndex(suscripcion => suscripcion == subscripcionLN?.nombre);
                            const planesUpsellingIds = upsellingAccesoDigital ? [planAdClubId] : (esSoloSuscriptorDigital && !cantidadTiempoSuscripto)
                            ? [] : orderGA.slice(upsellingGABeginIndex + 1, orderGA.length);
                            
                            const idsUpselling = [];
                            arrayCampanias?.filter(combo => {
                                if (planesUpsellingIds.includes(combo.comboType)) {
                                    idsUpselling.push(combo.id);
                                }
                            })
                            const datasSscripcion = suscripciones?.find((suscr) => suscr.IdPlanDeVenta == sus.IdPlanDeVenta);
                            if(datasSscripcion.IdAccesoDigital == accesosDigitalesIds.LN && datasSscripcion.TipoDeVenta != TipoVentaUpselling.Corporativa && datasSscripcion.NombreRegimenDeFacturacion != 'Anual'){
                                //valida si no es foodit, si es venta individuos y si no es plan anual = continua
                                const campaniaObtenida = upsellingAccesoDigital ? campaniaClub?.flatMap(camp => camp.id)[0] : campaniaNoClub?.flatMap(camp => camp.id)[0];
                                setPlanActual({ nombre: sus.NombreAmigable, id: sus.IdPlanDeVenta })
                                setTieneClub(!esSoloSuscriptorDigital);
                                getInfoPlanes({ campania: campaniaObtenida, planes: upsellingAccesoDigital ? planesUpsellingIds : idsUpselling, origen: 'mejora-tu-plan' })
                            }
                        }
                    }

                }
            })
        }
    }, [suscripciones, campaniasUpselling, planesHook, suscripcionesDA]);

    const handleRouteToADClub = () => {
        const keyPlan = findKeyPlanUpselling(plan.NombreUpsell);
        const campaniaObtenida = !campania && campaniasUpselling?.find((campania) => campania.id = plan?.IdCampaniaUpsell);
        history.push({
            pathname: `${PathsConfig.UpsellingConfirmar}/${keyPlan}/domicilio`,
            state: {
                IdCanalDeVenta: UrlProperties.getParamOrSetIfNotExist('cv', campania ? campania[0].canalVentaDefault : campaniaObtenida?.canalVentaDefault),
                IdCampaniaUpsell: plan.IdCampaniaUpsell,
                IdFormaDeContacto: UrlProperties.getParamOrSetIfNotExist('fc', campania ? campania[0].formaContactoDefault : campaniaObtenida?.formaContactoDefault),
            },
        });
    };

    const handleClickConfirmacionUpsell = () => {
        const campaniaObtenida = !campania && campaniasUpselling?.find((campania) => campania.id = plan?.IdCampaniaUpsell);
        confirmarUpselling(
            campaniaId ? campaniaId : plan.IdCampaniaUpsell,
            plan.PlanDeVentaUpsell,
            plan.NombreUpsell,
            'mejora-tu-plan',
            infoUpselling,
            UrlProperties.getParamOrSetIfNotExist('cv', campania ? campania[0].canalVentaDefault : campaniaObtenida?.canalVentaDefault),
            UrlProperties.getParamOrSetIfNotExist('fc', campania ? campania[0].formaContactoDefault : campaniaObtenida?.formaContactoDefault),
            tieneClub,
            handleRouteToADClub,
            plan.IdSuscripcionActual,
            planActual
        );
    };

    const redirectMiUsuario = () => {
        history.push({
            pathname: PathsConfig.MiUsuario,
        });
    }

    return (
        <section className="container-fluid-streched">
            <Breadcrumb
                firstItem="Mis suscripciones"
                secondItem={`Mejora Tu Plan`}
                text="Ver suscripciones"
                href="#"
            />
            <TitleWeb text="Mejorá Tu Plan" />
            {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.MejoraTuPlan) ? (
                <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.MejoraTuPlan} />
            ) : (
                <LoadingSpinner isLoading={loadingInfoUpselling
                    || loadingConfirmarUpselling
                    || isLoadingCampaniasUpselling
                    || isLoadingSuscripciones
                    || isLoadingSuscripcionesDA
                    || isLoadingPlanes
                    || isLoadingEventos}>
                    <ErrorBoundary>
                        {(errorInfoUpselling
                            || errorConfirmarUpselling
                            || errorPlanes
                            || errorPlanes
                            || errorCampaniasUpselling
                            || errorSuscripcionesDA
                            || errorSuscripciones) ? (
                            <Toast status="warning" title={Mensaje.getError('GENERAL')} />
                        ) :
                            (esBlack ? (
                                <>
                                    <UpsellingConfirmadoBlack handleClick={redirectMiUsuario} />
                                </>
                            ) : (
                                planesUpselling?.length > 0 && !tieneAccesoDigital ? <Planes noDrawer planes={planesUpselling} setPlan={setPlan} ConfirmarPlan={handleClickConfirmacionUpsell} /> :
                                    <>
                                        <SinSuscripcionDigital />
                                    </>
                            )
                            )
                        }
                    </ErrorBoundary>
                </LoadingSpinner>
            )}
        </section>
    );

}
