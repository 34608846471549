import React from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '../../../Routes/Menu';
import '../../../Statics/Scss/Components/Nav.scss';
const Nav = ({closeMenu = ()=> null}) => {
    const _renderItem = (list) => {
        let renderItem = list.map((item, key) => {
            const Tag = item.Url !== '#' ? 'a' : 'button';
            let $item = item.Url ? (
                <React.Fragment key={key}>
                    <dd data-test-id>
                        <Tag {...(item.Url !== '#' && { href: item.Url })} {...(item.function && { onClick: item.function })}>
                            {item.Display}
                        </Tag>
                    </dd>
                </React.Fragment>
            ) : (
                <React.Fragment key={key}>
                    {' '}
                    <dd data-test-id>
                        <NavLink onClick={closeMenu} activeClassName="--active" to={`/${item.Path}`}>
                            {item.Display}
                        </NavLink>
                    </dd>
                </React.Fragment>
            );
            return $item;
        });
        return renderItem;
    };
    const _renderGroup = (sections) => {
        let renderGroup = sections.map((grupo, key) => {
            let $list = _renderItem(grupo.Childrens);
            return (
                <React.Fragment key={key}>
                    {' '}
                    <dt data-test-id>{grupo.GroupName}</dt> {$list}{' '}
                </React.Fragment>
            );
        });
        return renderGroup;
    };
    const _renderSection = () => {
        let renderSection = Menu.map((section, key) => {
            let $group = _renderGroup(section.SectionGroup);
            return (
                <React.Fragment key={key}>
                    <dl data-test-id className={section.SectionClass}>{$group}</dl>
                </React.Fragment>
            );
        });
        return renderSection;
    };
    return <nav className="com-nav --fourxs">{_renderSection()}</nav>;
};
export default Nav;