import estadoSuscripciones from "../Models/EstadoSuscripciones";
import idProductoSuscripciones from "../Models/TipoSuscripciones";

const excludedIdsPlanDeVenta = process.env.REACT_APP_EXCLUDE_ID_PLAN_VENTA
const excludeIdsPlanDeVentaArray = excludedIdsPlanDeVenta.split(',').map(element => Number(element))
export const estadosSuscripcionesMuestranDomicilioEnvio = [
    estadoSuscripciones.ACTIVA, 
    estadoSuscripciones.SUSP_VACACIONES, 
    estadoSuscripciones.PENDIENTE, 
    estadoSuscripciones.FALTA_PAGO, 
    estadoSuscripciones.BAJA_SOLICITADA,
    estadoSuscripciones.BAJA_DIFERIDA
];

export const validacionesCambioPago = [
    estadoSuscripciones.ACTIVA,
    estadoSuscripciones.PENDIENTE,
    estadoSuscripciones.SUSP_VACACIONES,
    estadoSuscripciones.FALTA_PAGO
];

export const validacionesSuspender = [
    estadoSuscripciones.ACTIVA,
    estadoSuscripciones.FALTA_PAGO,
    estadoSuscripciones.BAJA_DIFERIDA,
    estadoSuscripciones.SUSP_VACACIONES
];

export const validacionesReclamar = [
    estadoSuscripciones.ACTIVA,
    estadoSuscripciones.PENDIENTE,
    estadoSuscripciones.SUSP_VACACIONES
];

export const titulosEstados = {
    [estadoSuscripciones.ACTIVA]: "SUSCRIPCIÓN ACTIVA",
    [estadoSuscripciones.SUSP_VACACIONES]: "SUSPENDIDA POR VACACIONES",
    [estadoSuscripciones.PENDIENTE]: "PENDIENTE",
    [estadoSuscripciones.RECHAZADA]: "RECHAZADA",
    [estadoSuscripciones.BAJA]: "DADA DE BAJA",
    [estadoSuscripciones.TERMINADA]: "TERMINADA",
    [estadoSuscripciones.FALTA_PAGO]: "SUSPENDIDA POR FALTA DE PAGO",
    [estadoSuscripciones.BAJA_SOLICITADA]: "BAJA SOLICITADA",
    [estadoSuscripciones.BAJA_DIFERIDA]: "BAJA DIFERIDA",
    [estadoSuscripciones.ALTA_VENCIDA]: "ALTA VENCIDA"
};

export const descripcionEstadosSuscripciones = {
    [estadoSuscripciones.ACTIVA]: "Activa",
    [estadoSuscripciones.SUSP_VACACIONES]: "Susp vacación",
    [estadoSuscripciones.PENDIENTE]: "Pendiente",
    [estadoSuscripciones.RECHAZADA]: "Rechazada",
    [estadoSuscripciones.BAJA]: "Baja",
    [estadoSuscripciones.TERMINADA]: "Terminada",
    [estadoSuscripciones.FALTA_PAGO]: "Susp falta pago",
}

export const tipoDeSuscripcionesIdProducto = {
  suscriptorADClub : idProductoSuscripciones.SuscriptorADClub,
  suscriptorADClubSinActivacion : idProductoSuscripciones.SuscriptorADClubSinActivacion,
  suscriptorDigital : idProductoSuscripciones.SuscriptorDigital
}

export const configFilterSuscripciones = {
    anuladoFlag : 'ANULACIÓN',
    daysToBlock : 6,
    excludedIds : excludeIdsPlanDeVentaArray
}

export const configAccesoDigital = {
    ln: 1,
    foodit: 2
}
