import Datadog from "../../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../../Config/Datadog";
import Auth0 from '../../Services/Auth0Service';
import Cookies from 'js-cookie';
import Auth0Config from '../../Config/Auth0';
import UnlinkIdentityProviderErrors from '../../Models/ErrorMessages/UnlinkIdentityProviderErrors';
import {MapErrorMessage} from '../../Helpers/MapErrorMessage';

const useUnlinkFacebookAccount = (setIdentitiesProviderData, showAlertToast, setLoadingFacebook) => {

    const unlinkFacebookAccount = () => {
        setLoadingFacebook(true);
        const data = {
            primary_acccount_access_token: Cookies.get('access-token'),
            secondary_account_provider: Auth0Config.FacebookConnection
        };
        Auth0.UnlinkAccount(data)
            .then((apiResponse) => {
                if (apiResponse.code === '0000' || apiResponse.code === '0020') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.desvincularRedSocial, { redSocial: 'Facebook' });
                    let profiles = {};
                    apiResponse.response.forEach((identity) => {
                        profiles[identity.profile_name] = identity
                    });
                    setIdentitiesProviderData()
                    showAlertToast('¡Tu desvinculación con Facebook se realizó con éxito!', 'success');
                }
                else if (apiResponse.code === '0006') {
                    //Error de obtención de identidades, hacemos un reload para refrescar el cliente
                    location.reload;
                }
                else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorVincularRedSocial, { redSocial: 'Facebook' });
                    const errorMessage = MapErrorMessage(apiResponse.code, UnlinkIdentityProviderErrors);
                    showAlertToast(errorMessage, 'alert');
                }
                setLoadingFacebook(false);
            })
            .catch((reason) => {
                Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorDesvincularRedSocial, { redSocial: 'Facebook' });
                console.log(`rejected by ${reason}`);
                setLoadingFacebook(false);
            });
    };

    return [unlinkFacebookAccount];
};
export default useUnlinkFacebookAccount;
