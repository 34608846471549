const PathsConfig = {
    // TODO: hacer andar para unificar paths en un único lugar.
    // no funciona correctamente, tuvo que ser revertido.
    // o eliminar éste archivo

    /*
    ------ MENU ------
    */

    // Mi cuenta
    MenuMiUsuario: `mi-usuario`,
    MenuDatosPersonales: `datos-personales`,

    // Club LA NACION
    MenuVincularCredencial: `vincular-credencial`,
    MenuMisCredenciales: `mis-credenciales`,
    MenuMisConsumos: `mis-consumos`,

    // Mis suscripciones
    MenuMisSuscripciones: `mis-suscripciones`,
    MenuSuspensionVacaciones: `suspender-por-vacaciones`,
    MenuMisDomicilios: `mis-suscripciones-domicilio`,

    // Mis reclamos
    MenuReclamoEntrega: `reclamo-entrega`,
    MenuReclamoTarjeta: `reclamo-tarjeta`,
    MenuReclamoAccesoDigital: `reclamo-acceso-digital`,

    // Mis pagos
    MenuMisFacturas: `mis-facturas`,
    MenuPagosPendientes: `mis-pagos/pagos-pendientes`,
    MenuEstadoDeCuenta: `mis-pagos/estado-de-cuenta`,
    MenuCambiarFormaDePago: `mis-pagos/cambiar-forma-de-pago/elegir-suscripcion`,

    /*
    ------ NAVEGACIÓN INTERNA ------
    */

    //MiCuenta
    HomeMyAccount: '/',
    MiUsuario: '/mi-usuario',
    CambiarPassword: '/mi-usuario/cambiar-password',
    DatosPersonales: '/datos-personales',

    //Club LA NACION
    VincularCredencial: '/vincular-credencial',
    VincularCredencialActivarBonificada: '/vincular-credencial/mi-club/activar-bonificada',
    VincularCredencialActivarBonificadaConfirmacion: '/vincular-credencial/mi-club/activar-bonificada/confirmacion',
    VincularCredencialVincular: '/vincular-credencial/mi-club/vinculacion',
    VincularCredencialDesvincular: '/vincular-credencial/mi-club/desvincular',
    VincularCredencialMiClub: '/vincular-credencial/mi-club',
    MisCredenciales: '/mis-credenciales',
    MisCredencialesCrearUsuario: '/mis-credenciales/crear-usuario',
    MisCredencialesBuscarCliente: '/mis-credenciales/buscar-cliente',
    MisCredencialesSeleccionarDomicilio: '/mis-credenciales/seleccionar-domicilio',
    MisCredencialesConfirmacionOtorgarSuscripcion: '/mis-credenciales/confirmacion-otorgar-credencial',
    MisCredencialesSolicitudDeBaja: '/mis-credenciales/solicitud-de-baja',
    MisConsumos: '/mis-consumos',

    //Mis Suscripciones
    MisSuscripciones: '/mis-suscripciones',
    ActivarSuscripcion: '/mis-suscripciones/activar-suscripcion',
    ActivarSuscripcionConPinEnUrl: '/mis-suscripciones/activar-suscripcion/:pin',
    ConfirmacionActivarSuscripcion: '/mis-suscripciones/confirmacion-suscripcion',
    SolicitudBaja: '/solicitud-baja', // se espera ID de Suscripción en la URL
    SolicitudBajaConIdEnUrl: '/solicitud-baja/:idSuscripcion',    
    BeneficiosUtilizados: '/solicitud-baja/beneficios-utilizados',
    MantenerSuscripcion: '/solicitud-baja/mantener-suscripcion',
    ConfirmarBaja: '/solicitud-baja/confirmar',
    ConfirmacionBaja: '/solicitud-baja/baja-confirmada',
    SuspensionVacaciones: '/suspender-por-vacaciones',
    SeleccionFechaSuspension: '/suspender-por-vacaciones/fechas-suspension',
    ConfirmarSuspension: '/suspender-por-vacaciones/confirmacion-suspension',
    MisSuscripcionesCambiarDomicilio: '/mis-suscripciones-domicilio',
    MisSuscripcionesCambiarDomicilioSeleccionar: '/mis-suscripciones-domicilio/seleccionar-domicilio',
    MisSuscripcionesCambiarDomicilioConfirmacion: '/mis-suscripciones-domicilio/confirmacion',

    //Mis Reclamos
    ReclamoEntrega: '/reclamo-entrega',
    ReclamoEntregaMotivo: '/reclamo-entrega/motivo',
    ReclamoEntregaContactoCallcenter: '/reclamo-entrega/contacto-callcenter',
    ReclamoEdiciones: '/reclamo-entrega/ediciones',
    ReclamoEntregaDemora: '/reclamo-entrega/reclamo-recibi-con-demora',
    ReclamoEntregaNoRecibi: '/reclamo-entrega/no-recibi',
    ReclamoEntregaRecibiDaniado: '/reclamo-entrega/recibi-daniado',
    ReclamoEntregaVendedor: '/reclamo-entrega/vendedor-reclama-pago',
    ReclamoEntregaProdNoSolicitado: '/reclamo-entrega/prod-no-solicitado',
    ReclamoEntregaConfirmacion: '/reclamo-entrega/confirmacion',
    ReclamoEntregaContacto: '/reclamo-entrega/contacto-reclamo',
    ReclamoTarjeta: '/reclamo-tarjeta',
    ReclamoTarjetaContactoCallcenter: '/reclamo-tarjeta/contacto-callcenter',
    ReclamoTarjetaDomicilioEntrega: '/reclamo-tarjeta/domicilio-de-entrega',
    ReclamoTarjetaFinalizado: '/reclamo-tarjeta/reclamo-finalizado',
    ReclamoAccesoDigital: '/reclamo-acceso-digital',
    ReclamoAccesoDigitalPeriodo: '/reclamo-acceso-digital/reclamo-periodo',
    ReclamoAccesoDigitalFinalizado: '/reclamo-acceso-digital/reclamo-finalizado',

    //Mis Pagos
    MisFacturas: '/mis-facturas',
    PagosPendientes: '/mis-pagos/pagos-pendientes',
    SeleccionarDeuda: '/mis-pagos/seleccion-deuda',
    SeleccionDeudaHash: '/mis-pagos/seleccion-deuda-back', //ruta intermedia pagos pendientes
    SeleccionarTarjeta: '/mis-pagos/seleccion-tarjeta',
    DeudaContactoCallcenter: '/mis-pagos/deuda-contacto-callcenter',
    PagosPendientesConfirmacion: '/mis-pagos/confirmacion-pago',
    CambiarFormaDePago: '/mis-pagos/cambiar-forma-de-pago',
    CambiarFormaDePago2: '/mis-pagos/cambiar-forma-de-pago-2',
    ElegirSuscripcion: '/mis-pagos/cambiar-forma-de-pago/elegir-suscripcion',
    SeleccionarMetodoPago: '/mis-pagos/cambiar-forma-de-pago/seleccionar-metodo-de-pago',
    CambiarFormaDePagoFinalizado: '/mis-pagos/cambiar-forma-de-pago/cambio-metodo-de-pago-finalizado',
    EstadoDeCuenta: '/mis-pagos/estado-de-cuenta',

    //Upselling
    UpsellingConfirmar: '/confirmar-upselling/up-selling',
    UpsellingPlanes: '/confirmar-upselling/up-selling/:plan',
    UpsellingGracias: '/bienvenido-upselling',
    UpsellingADClubSeleccionPago: '/confirmar-upselling/up-selling/:plan/seleccionar-pago',
    UpsellingADClubDomicilio: '/confirmar-upselling/up-selling/:plan/domicilio',
    UpsellingADClubConfirmacion: '/confirmar-upselling/up-selling/:plan/confirmacion',
    UpsellingMejoraTuPlan: '/mejora-tu-plan',

    //Not Found
    NotFound: 'not-found',
    UnderMaintenance: 'under-maintenance',

    //Typeform
    RetainTypeform: '/quiero-ser-contactado',

    //Leer edición impresa
    EdicionImpresa: 'https://edicionimpresa.lanacion.com.ar/la-nacion',

    //NewsLetters
    NewsLetters: 'https://newsletter.lanacion.com.ar/'
};

export default PathsConfig;
