import React      from "react";
import PropTypes  from "prop-types";
import WizardItem from "../Atoms/WizardItem";
import WizardItemMobile from "../Atoms/WizardItemMobile";
import { useWindowSize } from '@ln/hooks';
import "../../../Statics/Scss/Modules/Wizard.scss";


const Wizard = ({ steps, activeStep }) => {
    const { width: viewportWidth } = useWindowSize();

    const isMobile = viewportWidth < 768;

    const stepsLength = Object.keys(steps).length

    const isInBetween = step => {
        
        return (1 < step) && (step < stepsLength);
    };

    const evaluateClassNames = (stepNumber) => {
        let className = '';

        if (activeStep === stepNumber)
            if (activeStep === 1)
                className = "active first";
            else if (activeStep === stepsLength)
                className = "active last";
            else
                className = "active";

        if ( stepNumber === 1 )
            className += activeStep !== stepNumber ? "done first" : "";
        else if ( isInBetween(stepNumber) ) {

            className += activeStep < stepNumber ? "disabled" : activeStep > stepNumber ? "done" : "";
            className += " middle";
        } else
            className += stepNumber > activeStep ? "disabled last" : "";

        return className;
    };

    return(
        <div className="mod-wizard">

            {
                isMobile ? (
                    <WizardItemMobile 
                        stepNumber = { activeStep }
                        totalSteps={stepsLength}
                        steps={steps}
                    />
                ) : (
                    Object.keys(steps).map( (step, index) => 
                        <WizardItem 
                            stepNumber = { steps[step].number }
                            mod        = { evaluateClassNames(steps[step].number) }
                            {...((!isMobile || (isMobile && stepsLength < 5)) && 
                            { text: steps[step].text })}
                            key        = { index }
                        />
                    )
                )
            }
        
        </div>
    );
}


Wizard.propTypes = {
    activeStep: PropTypes.number,
    steps:       PropTypes.object,
}


export default Wizard;