import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import '../../../Statics/Scss/Organism/Modal.scss';

const ModalPortalBG = ({ children }) => {
    useEffect(() => {
        document.body.classList.add('modal-active');
        return () => {
            document.body.classList.remove('modal-active');
        };
    }, []);

    return ReactDOM.createPortal(
        <>{children}</>,

        document.body
    );
};

export default ModalPortalBG;
