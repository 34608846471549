import React from 'react';
import Button from '../Common/Modules/Button';
import Modal from '../Common/Organism/Modal';
import { useStore } from '../../Store/storeProvider';
import IconMsgDeuda from '../../Statics/Imgs/iconMsgDeuda';
import "../../Statics/Scss/Modules/ModalWizard.scss";
import { telefonoSecundario } from '../../Config/DatosDeContacto';

const MsgDeudaModal = () => {
    const { HandleExisteDeuda } = useStore();

    return (
        <Modal>
            <div className='row'><IconMsgDeuda width="201.24" height="160" /></div> <br />
            <h3 className="row com-title --l --sueca --font-bold">Tu cuenta registra un saldo pendiente de ${HandleExisteDeuda.TotalDeuda.toFixed(2)}</h3> <br />
            <p className="row com-paragraph --twoxs --arial">Para seguir gestionando tus suscripciones y continuar disfrutando de nuestros productos podés realizar el pago ingresando a Pagos pendientes.</p> <br />
            <div className="row mod-button"><Button tag="a" href="/mis-pagos/pagos-pendientes" label="Pagos Pendientes" className="state--w100 --mb-0"/></div>
            <p className="row com-paragraph --onexs --arial">Si tienes alguna duda, comunicate con nosotros al {telefonoSecundario}.</p> 
        </Modal>
    );
};

export default MsgDeudaModal;
