const tipoPropiedadMap = [
    {
        "id": "CASA",
        "descripcion": "CASA"
    },
    {
        "id": "COUNTRY-BARRIO-CLUB DE CAMPO",
        "descripcion": "COUNTRY"
    },
    {
        "id": "EDIFICIO-PH",
        "descripcion": "EDIFICIO/PH"
    },
    {
        "id": "COMERCIO",
        "descripcion": "COMERCIO"
    },
    {
        "id": "COMPLEJO-MONOBLOCK",
        "descripcion": "COMPLEJO/MONOBLOCK"
    }
]

export default tipoPropiedadMap;