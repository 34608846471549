import React, { useEffect } from 'react';
import Toast from '../../Common/Modules/Toast';

const CredencialesEnDistribucion = ({ credenciales }) => {
    const credencialEnDist = credenciales.find(i => i.SituacionDeEntregaId < 5);

    const getToastMsg = (credencial) => {
        const domicilio = credencial.Domicilio;
        return (
            <>
                Está pendiente la entrega de la credencial <b>{credencial.NumeroCredencialFormateado}</b>
                , a nombre de <b>{credencial.NombreCliente} {credencial.ApellidoCliente}</b>
                , para el domicilio <b>{domicilio.Calle} {domicilio.Altura} {domicilio.Localidad} - {domicilio.Provincia} {domicilio.CodigoPostal}</b>
            </>
        )
    }

    return (
        credencialEnDist ?
        <Toast status={'info'} title={"Tenés credenciales en distribución"}>
            {getToastMsg(credencialEnDist)}
        </Toast> : null
    )
}

export default CredencialesEnDistribucion;