import React from "react";

function PersonModal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="104"
      fill="none"
      viewBox="0 0 62 104"
    >
      <path
        fill="#081FA0"
        d="M52.424.036L9.576 0C4.43.018.571 5.316.571 10.05L.535 94.924c0 2.412.938 4.681 2.645 6.388a8.984 8.984 0 006.387 2.653l42.857.036c4.958-.018 9.005-4.065 9.005-9.023l.036-85.9C61.455 4.1 57.409.045 52.424.036zM9.585 2.26l.018 1.019 42.82.035c3.172 0 5.754 2.591 5.754 5.763l-.036 85.9c0 3.162-2.573 5.735-5.762 5.744l-42.82-.035a5.704 5.704 0 01-4.065-1.689 5.716 5.716 0 01-1.68-4.074l.036-84.872c0-3.056 2.564-6.763 5.735-6.772V2.26z"
      ></path>
      <path
        fill="#126FFF"
        stroke="#126FFF"
        strokeWidth="0.3"
        d="M39.68 55.964c3.248-2.516 5.177-6.354 5.177-11V37.8c0-3.485-1.721-6.774-4.395-8.323-2.864-1.744-6.498-1.744-6.685-1.744h-7.263c-5.35 0-9.743 4.452-9.743 9.873v7.164c0 4.452 2.098 8.52 5.35 11.035-1.337.58-3.058 1.355-4.585 3.099-3.442 3.487-5.159 8.908-5.159 16.262 0 .775.765 1.55 1.527 1.55.765 0 1.53-.775 1.53-1.55 0-12.97 5.923-15.876 8.981-16.65v2.127c0 2.903 2.292 5.421 5.347 5.421h2.675c2.867 0 5.35-2.322 5.35-5.42v-2.128c3.059.968 8.6 4.26 8.6 16.65 0 .775.764 1.55 1.528 1.55.765 0 1.53-.775 1.53-1.55 0-7.358-2.15-16.62-9.766-19.202zM20.014 45.156V37.99c0-3.675 3.059-6.772 6.686-6.772h7.263c.192 0 2.865 0 5.159 1.351 1.72.971 2.863 3.099 2.863 5.617v7.164c0 6.196-4.97 11.036-10.893 11.036-5.923 0-11.078-5.227-11.078-11.23zm12.23 18.006H29.57c-1.337 0-2.294-.97-2.294-2.324V58.71c1.146.388 2.294.58 3.63.58a11.02 11.02 0 003.631-.58v2.128c.002 1.355-.954 2.324-2.292 2.324z"
      ></path>
    </svg>
  );
}

export default PersonModal;
