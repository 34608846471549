import React, { useState } from 'react';
import PropTypes from "prop-types";
import FormRow from '../Atoms/Form/FormRow';
import Label from '../Atoms/Form/Label';
import Link from '../Atoms/Link';
import Credencial from '../../Features/ReclamoTarjeta/Credencial';
import CredencialModel from "../../../Models/Credencial";

const propTypes = {
    title: PropTypes.string.isRequired,
    credencialesSelected: PropTypes.func.isRequired,
    credenciales: PropTypes.array.isRequired,
    credValError: PropTypes.bool.isRequired,
    setCredValError:PropTypes.func.isRequired,
    preSelected:PropTypes.object
}

const CredencialesSelector = (props) => {
    const {credenciales, credValError, setCredValError, preSelected} = props;
    const [credencialesSeleccionadas, setCredencialesSeleccionadas] = useState(preSelected ? [preSelected.IdCredencialAsignada.toString()] : []); 
    const opcionesDeSeleccion = ['Quitar selección', 'Seleccionar todas'];
    const reclamables = credenciales.filter(cred => cred.SituacionDeEntregaId == CredencialModel.SituacionDeEntrega.ENTREGADA);
    const credencialesSinReclamo = reclamables?.filter(i=>i.Reclamo === null);

    const handleSelectAll = (event) => {
        const select = event.target.text === opcionesDeSeleccion[1];
        
        if (credencialesSinReclamo.length) {
            const reclamablesMap = credencialesSinReclamo.map(i=>i.IdCredencial.toString());
            setCredencialesSeleccionadas(select ? reclamablesMap : []);
            props.credencialesSelected(select ? reclamablesMap : []);
        }
        setCredValError(!select);
    };

    const handleCredencialSelected = (event) => {
        const isChecked = event.target.checked;
        const value = event.target.value;
        var arrCreds = credencialesSeleccionadas;
        if (isChecked) {
            arrCreds = [...arrCreds, value.toString()];
        } else {
            arrCreds = arrCreds.filter(i => i != value);
        }

        setCredencialesSeleccionadas(arrCreds);
        props.credencialesSelected(arrCreds);
        setCredValError(arrCreds.length === 0);
    };
    return (
        <FormRow>
            <Label
                text={props.title}
                link= { 
                <Link onClick={handleSelectAll} >
                    {credencialesSinReclamo.length && credencialesSeleccionadas.length == credencialesSinReclamo.length ? opcionesDeSeleccion[0] : opcionesDeSeleccion[1]}</Link> } />
            
            {credenciales && credenciales.filter(i=>i.Reclamo === null).map((credencial) => (
                <Credencial disabled={credencial.SituacionDeEntregaId != CredencialModel.SituacionDeEntrega.ENTREGADA} selectedItems={credencialesSeleccionadas} key={credencial.IdCredencial} credencial={credencial} withValidationError={credValError} handleSelection={handleCredencialSelected} />
            ))}
            {credValError && <span className="com-input__box__descrip --red-500 --fivexs --mb-16">Seleccioná una credencial</span>}
        </FormRow>
    )
}

CredencialesSelector.propTypes = propTypes;

export default CredencialesSelector;
