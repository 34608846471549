import React, { useState, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import MsgDeudaModal from '../Modals/MsgDeudaModal';
import { useDispatch, useStore } from '../../Store/storeProvider';
import useGetDeudasSinHistoryPushPromise from '../../Hooks/useGetDeudasSinHistoryPushPromise';
import Datadog from '../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../Config/Datadog';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [rechazos, errorRechazos, isLoadingRechazos, fetchData] = useGetDeudasSinHistoryPushPromise();
    const dispatch = useDispatch();
    const { HandleExisteDeuda, HandleSwitchsStates } = useStore();

    const ValidationPath = () => {
        if (!history.location.pathname.includes('/mis-pagos'))
            return true;

        if (history.location.pathname.includes('/estado-de-cuenta') || history.location.pathname.includes('/cambiar-forma-de-pago'))
            return true

        return false;
    };

    useEffect(() => {
        if (HandleExisteDeuda.ExisteDeuda == null) {
            fetchData();
        }
    }, [])
    useEffect(() => {
        if (HandleExisteDeuda.ExisteDeuda == null && !isLoadingRechazos) {
            if (!errorRechazos) {
                if (rechazos?.rechazosCobrosAdelantados?.length > 0 || rechazos?.rechazosCobrosVencidos?.length > 0) {
                    dispatch({
                        type: 'ExisteDeuda',
                        value: {
                            ExisteDeuda: true,
                            TotalDeuda:
                                rechazos.rechazosCobrosVencidos?.reduce((sum, { Importe }) => sum + +Importe, 0) +
                                rechazos.rechazosCobrosAdelantados?.reduce((sum, { Importe }) => sum + +Importe, 0),
                            ErrorApi: null,
                        },
                    });
                } else {
                    dispatch({
                        type: 'ExisteDeuda',
                        value: {
                            ExisteDeuda: false,
                            TotalDeuda: null,
                            ErrorApi: null,
                        },
                    });
                }
            } else {
                dispatch({
                    type: 'ExisteDeuda',
                    value: {
                        ExisteDeuda: null,
                        TotalDeuda: null,
                        ErrorApi: true,
                    },
                });
            }
        }
    }, [isLoadingRechazos]);

    useEffect(() => {
        if (HandleExisteDeuda.ExisteDeuda && ValidationPath() && HandleSwitchsStates?.PagosPendientes) {
            Datadog.trackCustomEvent(CustomEventsDatadog.displayModalDeuda)
            setShowModal(true);
        }
    }, [HandleExisteDeuda]);

    return (
        <>
       { showModal ?
            <MsgDeudaModal /> :
            <Route path={rest.path} key={rest.key} exact={true} component={Component} />}
        </>
    )
    //<Component {...rest} />;
};

export default PrivateRoute;
