import React from 'react';

import Paragraph from '../Atoms/Paragraph';

const NotaCoberturaDeEnviosBanner = () => {
    return (
        <Paragraph size="--fivexs" mod="--light-neutral-600">
            La cobertura de envío es sólo para domicilios en Argentina.
        </Paragraph>
    );
};

export default NotaCoberturaDeEnviosBanner;
