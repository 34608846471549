import { useState } from "react";
import ApiService from "../Services/ApiService";


const useConsultarProductosPremiumPorCrmId = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(null);
    const [resp, setResp] = useState(null);
    const [error, setError] = useState(null);

    const fetch = async (crmId) => {
        setIsLoading(true);
        return ApiService.get(`${REACT_APP_APIPAYWALL}EventosSuscripciones/ConsultarProductosPremiumPorCrm`, { crmId })
            .then((data) => {
                if (data && data.code === '0000') {
                    setResp(data.response);
                    setIsLoading(false);
                } else {
                    setError(data.message);
                    setIsLoading(false);
                }
                return Promise.resolve(data);
            })
            .catch((err) => {
                setError(err);
                setIsLoading(false);
                return Promise.reject(err);
            });
    };

    return [resp, error, isLoading, fetch];
};

export default useConsultarProductosPremiumPorCrmId;
