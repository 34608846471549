
export const getBeneficiosPorPlan = (plan) => {
  const credencialesPorPlan = {
    club: 'Club LA NACION 1 credencial premium',
    duo: 'Club LA NACION 2 credenciales premium',
    triple: 'Club LA NACION 3 credenciales premium',
    familiar: 'Club LA NACION 4 credenciales black',
  }
  
  const beneficios = [
    'Escuchar, guardar y comentar notas',
    credencialesPorPlan[plan],
    'Kiosko LN: diario y revistas digitales',
    'Aplicación móvil personalizada',
    'Eventos LA NACION + cerca',
    'Newsletters: anticipo de columnas y análisis periodísticos',
  ].filter(Boolean)
  
  const beneficiosConSuscripcion = [
    'Contenido sin límites a lanacion.com',
    ...beneficios
  ]
  const beneficiosSinSuscripcion = [
    'Contenido sin límites a lanacion.com',
    ...beneficios
  ]

  return {
    beneficiosConSuscripcion,
    beneficiosSinSuscripcion
  }
}