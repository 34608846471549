import React, { useState } from 'react'
import ApiService from '../Services/ApiService';
import Datadog, { trackCustomEvent } from './../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';


const useProcesarCobroMinimoTarjetaCIC = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async (payload) => {
        setIsLoading(true);
        return ApiService.post(`${REACT_APP_APIPAYWALL}Suscripcion/ProcesarCobroMinimoTarjetaCIC`, payload)
            .then((data) => {
                if (data.code == '0000') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.cambioFormaDePago, { origen: 'cambiar-forma-de-pago' });
                    setResponse(data.response);
                    setIsLoading(false);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorCambioFormaDePago, { error: data.message, code: data.code });
                    setError(true);
                    setIsLoading(false);
                }
                return Promise.resolve(data);
            })
            .catch((error) => {
                setError(true);
                setIsLoading(false);
                return Promise.reject(error);
            });
    };

    return [response, error, isLoading, fetchData];
};

export default useProcesarCobroMinimoTarjetaCIC