import React, { useState } from 'react';
import { useHistory } from 'react-router';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import steps from '../../../Config/StepsReclamoAccesoDigital';
import Wizard from '../../../Components/Common/Modules/Wizard';
import useObtenerMotivosReclamosOnRender from '../../../Hooks/useObtenerMotivosReclamosOnRender';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import Motivos from '../../../Components/Features/Motivos';
import Button from '../../../Components/Common/Modules/Button';
import PathsConfig from '../../../Config/RoutePaths';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import InfoSuscription from '../../../Components/Common/Atoms/InfoSuscription';
import { Text } from '@ln/common-ui-text';

const ReclamoAccesoDigitalSuscriptor = (props) => {
    const { suscripciones } = props;
    const [motivosReclamo, errorMotivos, isLoadingMotivos] = useObtenerMotivosReclamosOnRender(suscripciones[0]?.Id);
    const [motivoSelected, setMotivoSelected] = useState(null);
    const [suscripcionSelectedId, setSuscripcionSelectedId] = useState(null);
    const [valMotivo, setValMotivo] = useState(null);
    const [valSuscripcion, setValSuscripcion] = useState(null);
    const history = useHistory();
    const motivosReclamosFilter = motivosReclamo?.filter((item) => item.Id !== 58);
    const selectedSuscription = suscripciones.find(item => item.Id === suscripcionSelectedId);
    const handleClickNextStep = (motivo) => {
        if (!motivo || !suscripcionSelectedId) {
            !motivo && setValMotivo({ status: 'error', msg: 'Seleccioná un motivo de reclamo' });
            !suscripcionSelectedId && setValSuscripcion({ status: 'error', msg: 'Seleccioná una suscripción a reclamar' });
        } else {
            history.push({
                pathname: PathsConfig.ReclamoAccesoDigitalPeriodo,
                state: {
                    IdSuscripcion: suscripcionSelectedId,
                    motivoSelected: motivosReclamosFilter.find((m) => m.Id === Number(motivo)),
                    nombreSuscripcion: selectedSuscription.NombreAmigable,
                    suscripcion: selectedSuscription,
                },
            });
        }
    };

    const handleMotivoSelected = (value) => {
        setMotivoSelected(value);
        setValMotivo(null);
    };

    const handleSuscripcionSelectedId = (id) => {
        setSuscripcionSelectedId(id);
        setValSuscripcion(null);
    };

    return (
        <section className="container-streched">
            <LoadingSpinner isLoading={isLoadingMotivos}>
                {errorMotivos ? (
                    <ErrorGenerico />
                ) : (
                    <>
                        <Wizard steps={steps} activeStep={1} />
                        <div className="flex flex-column gap-8 mb-24 arial">
                            <Text as="h3" className="arial-bold text-20">Seleccioná la suscripción y motivo a reclamar</Text>
                            <Text as="p" className='text-16'>Queremos solucionar tu problema, completá el siguiente formulario:</Text>
                        </div>
                        {suscripciones ? (
                            <>
                                <InfoSuscription
                                    title="Suscripción a reclamar"
                                    suscripcion={props}
                                    suscripciones={suscripciones}
                                    suscripcionSelectedId={suscripcionSelectedId}
                                    handleSuscripcionSelectedId={handleSuscripcionSelectedId}
                                    valSuscripcion={valSuscripcion}
                                />
                                {valSuscripcion && (
                                    <Text as='p' className="text-danger-500 --fivexs mt-8">{valSuscripcion.msg}</Text>
                                )}
                            </>
                        ) : (
                            <ErrorGenerico />
                        )}
                        <FormRow>
                            <FormCol>
                                {motivosReclamosFilter && motivosReclamosFilter.length > 0 && (
                                    <>                                    
                                        <Motivos
                                            motivos={motivosReclamosFilter}
                                            setMotivoSelected={handleMotivoSelected}
                                        />
                                        {valMotivo && (
                                            <Text as='p' className="text-danger-500 --fivexs mt-8">{valMotivo.msg}</Text>
                                        )}
                                    </>
                                )}
                            </FormCol>
                        </FormRow>
                        <FormRow mod="--btn-groups">
                            <Button
                                type="submit"
                                tag="input"
                                mod="state--mobile-w100"
                                label="CONTINUAR RECLAMO"
                                onClick={() => handleClickNextStep(motivoSelected)}
                            />
                        </FormRow>
                    </>
                )}
            </LoadingSpinner>
        </section>
    );
};

export default ReclamoAccesoDigitalSuscriptor;
