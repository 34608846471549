const Estado = {
    ACTIVA: 1,
    SUSP_VACACIONES: 2,
    PENDIENTE: 3,
    RECHAZADA: 4,
    BAJA: 5,
    TERMINADA: 6,
    FALTA_PAGO: 7,
    BAJA_SOLICITADA: 8,
    BAJA_DIFERIDA: 9,
    ALTA_VENCIDA: 10
};

export default { Estado };