import React from "react";
import PropTypes from "prop-types";

import Title     from "../Atoms/Title";
import Paragraph from "../Atoms/Paragraph";
import Icon      from "../Atoms/Icon";

import "../../../Statics/Scss/Modules/NoResults.scss";


const propTypes =  {
  title:       PropTypes.string,
  description: PropTypes.string,
  iconName:    PropTypes.string,
}


const NoResults = ({ title, description, iconName }) => {

    return(
        <div className="mod-no-editions">

            { iconName && <div className="mod-no-editions__icon-wrapper"><Icon name={ iconName } size={"xl"} /></div> }
            { title && <Title>{ title }</Title> }
            { description && <Paragraph>{ description }</Paragraph> }

        </div>
    );
}


NoResults.propTypes = propTypes;


export default NoResults;