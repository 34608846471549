import Analytics from "./Analytics"
const NA = 'N/A';

const handleBellClick = () => {
    Analytics.trackEvent('campanita', 'campanita', NA);
}

const handleButtonClick = (data) => {
    const additional = {
        button: data.buttonLabel,
        identifier: data.id,
        title: `mensaje-${data.title}` || NA,
        page_notification: data.url || NA
    };
    Analytics.trackActionNotification(additional);
}

const handleNotificationsClick = (data) => {
    const additional = {
        button: NA,
        identifier: data.id,
        title: `notificación-${data.title}` || NA,
        page_notification: data.url || NA
    };
    Analytics.trackActionNotification(additional);
}

export default { handleBellClick, handleButtonClick, handleNotificationsClick };