import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Table from "./Table";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TableHeaderCell from "./TableHeaderCell";
import TableBodyCell from "./TableBodyCell";
import TableRow from "./TableRow";

const propTypes = {
  /**
   * Celdas del header.
   */
  cols: PropTypes.array,
  /**
   * Valores y propiedades de
   * cada celda.
   */
  items: PropTypes.array,
  /**
   * Página actual.
   */
  currentPage: PropTypes.number,
  /**
   * Alineación de las celdas.
   */
  align: PropTypes.string,
  /**
   * Cantidad de items por página.
   */
  itemsPerPage: PropTypes.number,
  /**
   * Handler para cuando cambie una página.
   */
  onPageChange: PropTypes.func,
  /**
   * Flag, ¿posee checkbox?
   */
  isCheckable: PropTypes.bool,
  /**
   * Handler para los checkboxes.
   */
  handlerCheck: PropTypes.func,
  /**
   * Flag, ¿es fixed layout?
   */
  isFixedLayout: PropTypes.bool,
  /**
   * Text del tooltip
   */
  tooltipText: PropTypes.string,
  /**
   * Posición del tooltip
   */
  tooltipPosition: PropTypes.string,
  /**
   * Orientacion de la celda botón fija
   */
  actionPositionSticky: PropTypes.oneOf(["left", "right"]),
  /**
   * Orientacion de la celda común fija
   */
  cellPositionSticky: PropTypes.oneOf(["left", "right"]),
  /**
   * Celda del header que va a quedar fija
   */
  cellHeaderSticky: PropTypes.oneOf(["first", "last"]),
};

const defaultProps = {
  currentPage: 0,
  align: "center",
  itemsPerPage: 8,
  onPageChange: () => {},
  handlerCheck: () => {},
};

const BasicTable = ({
  cols,
  items,
  tooltipText,
  tooltipPosition,
  actionPositionSticky,
  cellPositionSticky,
  cellHeaderSticky,
  itemSelected,
  itemsPerPage,
  currentPage,
  onPageChange,
  onClickItemButton,
  align,
  isCheckable,
  handlerCheck,
  isFixedLayout,
  totalItems,
  ...r
}) => {
  let maxIndex, minIndex, actualItems;

  /*
   * Si contiene la cantidad de items por páginas,
   * realizo cálculos para la paginación, y... pagino.
   */
  if (itemsPerPage) {
    maxIndex = (currentPage + 1) * itemsPerPage;
    minIndex = maxIndex - itemsPerPage;
    actualItems = items.slice(minIndex, maxIndex);

    items = actualItems;
  }

  const handleRowClick = (item) => {
    if(itemSelected) {
      const id = item.id.value;
      itemSelected(id);
    };
    
    if(onClickItemButton) {
      onClickItemButton(true);
    };
  };

  return (
    <div className="mod-basic-table" {...r}>
      <Table align={align} isFixedLayout={isFixedLayout}>
        <TableHeader>
          <TableRow isCheckable={isCheckable} isHeaderRow={true}>
            {cols.map((col, i) => (
              <TableHeaderCell key={i} cellSticky={cellHeaderSticky}>
                {col?.name}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {items.map((item, i) => (
            <TableRow
              key={i}
              id={item.key?.value}
              isCheckable={isCheckable}
              handlerCheck={handlerCheck}
            >
              {Object.entries(item).map(([key, value], i) => {
                return key.toLowerCase().includes("button") ? (
                  <TableBodyCell
                    buttonProps={value}
                    key={i}
                    positionSticky={actionPositionSticky}
                    tooltipPosition={tooltipPosition}
                    tooltipText={tooltipText}
                    onClick={() => handleRowClick(item)}
                  />
                ) : value.primaryKey ? (
                  <></>
                ) : (
                  <TableBodyCell
                    key={i}
                    cellProps={value.cellProps}
                    elementProps={value.elementProps}
                    positionSticky={cellPositionSticky}
                  >
                    {value.value}
                  </TableBodyCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

BasicTable.propTypes = propTypes;
BasicTable.defaultProps = defaultProps;

export default BasicTable;
