import React from "react";
import { useField } from "formik";
import Datepicker from "../Modules/DatePicker";
import _handleStatusFormik from "../../../Helpers/StatusFormik";
import '../../../Statics/Scss/Components/Input.scss'

const DatepickerFormik = (props) => {
  const [field, meta, helpers] = useField(props);
  const { selected } = props;

  return (
    <div>
      <Datepicker {...field} {...props} state={_handleStatusFormik(meta)} />
      {!selected && <span className="com-input__box__descrip --red-500 --fivexs">Ingresá una fecha valida dd/mm/yyyy.</span>}
    </div>
  );
};

export default DatepickerFormik;
