import Datadog from "../../Helpers/Datadog";
import { CustomEventsDatadog, CustomEventsErrorDatadog } from "../../Config/Datadog";
import Auth0 from '../../Services/Auth0Service';
import Cookies from 'js-cookie';
import Auth0Config from '../../Config/Auth0';
import UnlinkIdentityProviderErrors from '../../Models/ErrorMessages/UnlinkIdentityProviderErrors';
import MapErrorMessage from '../../Helpers/MapErrorMessage';

const useUnlinkGoogleAccount = (setIdentitiesProviderData, showAlertToast, setLoadingGoogle) => {

    const unlinkGoogleAccount = () => {
        setLoadingGoogle(true);
        const data = {
            primary_acccount_access_token: Cookies.get('access-token'),
            secondary_account_provider: Auth0Config.GoogleConnection
        };
        Auth0.UnlinkAccount(data)
            .then((apiResponse) => {
                if (apiResponse.code === '0000' || apiResponse.code === '0020') {
                    Datadog.trackCustomEvent(CustomEventsDatadog.desvincularRedSocial, { redSocial: 'Google' });
                    let profiles = {};
                    apiResponse.response.forEach((identity) => {
                        profiles[identity.profile_name] = identity
                    });
                    setIdentitiesProviderData();
                    showAlertToast('¡Tu desvinculación con Google se realizó con éxito!', 'success');
                }
                else if (apiResponse.code === '0006'){
                    //Error de obtención de identidades, hacemos un reload para refrescar el cliente
                    location.reload;
                }
                else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorVincularRedSocial, { redSocial: 'Google' });
                    const errorMessage = MapErrorMessage(apiResponse.code, UnlinkIdentityProviderErrors);
                    showAlertToast(errorMessage, 'alert');
                }
                setLoadingGoogle(false);
            })
            .catch((reason) => {
                Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorDesvincularRedSocial, { redSocial: 'Google' });
                console.log(`rejected by ${reason}`);
                setLoadingGoogle(false);
            });
    };

    return [unlinkGoogleAccount];
};
export default useUnlinkGoogleAccount;
