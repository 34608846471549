import React from 'react';
import classNames from 'classnames';
import ConditionalWrapper from '../../../Helpers/ConditionalWrapper';
import Icon from '../Atoms/Icon';
import PropTypes from 'prop-types';
import ArrowRightLineIcon from '../../../Statics/Imgs/arrowRightLineIcon';
import '../../../Statics/Scss/Modules/Button.scss';

const propTypes = {
    /**
     * Label del botón
     */
    label: PropTypes.string,
    /**
     * Modificador de estado.
     */
    mod: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'special']),
    /**
     * Posibles tags.
     */
    tag: PropTypes.oneOf(['input', 'button', 'a']),
    /**
     * Url asociado al link.
     */
    href: PropTypes.string,
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
    /**
     * Atributo target asociado
     * al link.
     */
    target: PropTypes.string,
    /**
     * Icono asociado al button.
     */
    icon: PropTypes.string,
    /**
     * Tamaño del botón.
     */
    size: PropTypes.oneOf(['mini', 'small', 'large']),
    /**
     * Flag, ¿está deshabilitado?
     */
    disabled: PropTypes.bool,
    /**
     * Tamaño del icono.
     */
    iconSize: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
    /**
     * Handler asociado al evento
     * click.
     */
    onClick: PropTypes.func,
    /**
     * Flag, ¿el icono está
     * posicionado a la izquierda?
     */
    isFirstPositioned: PropTypes.bool,
    /**
     * Flag, ¿es de solo lectura?
     */
    readOnly: PropTypes.string,
};

const Button = ({
    tag,
    href,
    genericRef,
    mod,
    className,
    target,
    label,
    size,
    disabled,
    type,
    icon,
    onClick,
    onChange,
    iconSize,
    isFirstPositioned,
    readOnly,
    ...r
}) => {
    const classes = classNames('mod-button', className, {
        '--disabled': disabled,
        [`--${mod}`]: mod,
        [`--${size}`]: size,
    });
    const CustomTag = tag || 'button';
    const iconMargin = isFirstPositioned ? { marginRight: '8px' } : { marginLeft: '8px' };

    return (
        <ConditionalWrapper
            condition={CustomTag === 'input'}
            wrapper={(children) => (
                <CustomTag
                    className={classes}
                    type={type ? type : 'submit'}
                    value={children.slice(1, -1)}
                    ref={genericRef}
                    onClick={onClick}
                    onChange={onChange}
                    readOnly={readOnly}
                    disabled={disabled}
                    {...r}
                />
            )}
            defaultWrapper={(children) => (
                <CustomTag
                    className={classes}
                    {...(CustomTag === 'a' && { href: href })}
                    target={target}
                    {...(CustomTag === 'button' && { onClick: onClick })}
                    {...(CustomTag === 'button' && {
                        type: type ? type : 'button',
                    })}
                    {...(CustomTag === 'button' && disabled && { disabled })}
                    {...r}
                >
                    {children}
                </CustomTag>
            )}
        >
            {icon && isFirstPositioned && <Icon name={icon} size={iconSize} style={label && iconMargin} />}
            {label}
            {icon &&
                !isFirstPositioned &&
                (icon !== 'arrow-right-line' ? (
                    <Icon name={icon} size={iconSize} style={label && iconMargin} />
                ) : (
                    <div className="arrow-right-line-icon">
                        <ArrowRightLineIcon />
                    </div>
                ))}
        </ConditionalWrapper>
    );
};

ConditionalWrapper.propTypes = propTypes;

export default Button;
