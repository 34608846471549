import React from "react";
import Paragraph from "../Atoms/Paragraph";
import { horario, telefonoSecundario } from "../../../Config/DatosDeContacto";

const ContactoDudasBanner = () => {
    return (
        <Paragraph>
            <strong>¿Tenés dudas?</strong> Comunicate al <strong>{telefonoSecundario}</strong> {' '} de {horario}
        </Paragraph>
    );
};

export default ContactoDudasBanner;
