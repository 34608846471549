import React from 'react'
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Wizard from '../../../Components/Common/Modules/Wizard';
import TitleWeb from '../../../Components/Common/Web/Title';
import steps from '../../../Config/StepsReclamoAccesoDigital';
import Toast from '../../../Components/Common/Modules/Toast';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import PathsConfig from '../../../Config/RoutePaths';
import ErrorReclamoAccesoDigital from './ErrorReclamoAccesoDigital';
import { Text } from '@ln/common-ui-text';

const ReclamoFinalizado = (props) => {
    const stepProps = ['reclamoGenerado', 'nombreSuscripcion'];
    const { reclamoGenerado, errorAlGenerarReclamo } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoAccesoDigital);

    return (
        <>
            <Breadcrumb firstItem="Mis reclamos" href="#" text="Acceso Digital" />
            <TitleWeb text="Reclamo Acceso Digital" />
            <section className="container-streched">
                <Wizard steps={steps} activeStep={3} />
                {errorAlGenerarReclamo ?
                    (
                        <ErrorReclamoAccesoDigital />
                    ) : (
                        <>
                            <Toast className="arial" status="success" title="¡Listo!">
                                <Text as="p">¡Reclamo generado con éxito!</Text>
                                <Text as='p'>Se generó un reclamo con el motivo: <span className='arial-bold'>{reclamoGenerado?.motivo}</span></Text>
                            </Toast>

                            <div className='flex flex-column gap-8 arial mb-24'>
                                <Text as="p" className='arial-bold text-20'>El número de gestión correspondiente es: <span>{reclamoGenerado?.nroGestion}</span></Text>
                                <Text as="p" className='text-16'>Suscripción reclamada: <span className='arial-bold'>{reclamoGenerado?.nombreSuscripcion}</span></Text>
                            </div>

                            <div className="flex flex-column gap-16 arial mb-24">
                                <Text as='p' className='text-16'>Nos estaremos contactando con vos a la brevedad para ayudarte a resolver el inconveniente.</Text>
                                <Text as='p' className='text-16'>Fecha estimada de resolución: <span className='arial-bold'>7 días hábiles.</span></Text>
                            </div>

                            <ContactoDudasBanner />
                        </>
                    )}

            </section>
        </>
    )
}

export default ReclamoFinalizado;