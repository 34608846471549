import { useEffect, useState } from 'react';
import Mensaje from '../Models/Mensaje';
import Datadog from '../Helpers/Datadog';
import { CustomEventsErrorDatadog } from '../Config/Datadog';
import ApiService from '../Services/ApiService';

const useGetCampaniasUpselling = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [loading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [campaniasUpselling, setCampaniasUpselling] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIPAYWALL}CampaniaV1/GetPlanesUpselling`)
                .then((res) => {
                    setIsLoading(false);
                    if (res.code == '0000') {
                        setCampaniasUpselling(res.response);
                    } else {
                        Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorConsultaCampaniasUpselling , { response: res });
                        throw new Error();
                    }
                })
                .catch((err) => {
                    setError(Mensaje.getError('GENERAL'));
                    Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConsultaCampaniasUpselling , { error: err });
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [campaniasUpselling, error, loading];
};
export default useGetCampaniasUpselling;
