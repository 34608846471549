import { useState } from "react";
import ApiService from "../Services/ApiService";
const useObtenerDatosCRM = () => {
  const { REACT_APP_APIPAYWALL } = process.env;
  const [isLoadingCRM, setIsLoading] = useState(true);
  const [cliente, setCliente] = useState(null);
  const [errorCRM, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);

    ApiService.get(`${REACT_APP_APIPAYWALL}Cliente/GetDatosCliente`)
      .then((data) => {
        if (data && data.code) {
          if (data.code === "0000" || data.code === "4010") {
            setCliente(data.response);
          } else {
            setError(true);
          }
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  return [cliente, errorCRM, isLoadingCRM, fetchData];
};
export default useObtenerDatosCRM;
