import useObtenerDatosDomicilio from "./UseObtenerDatosDomicilio";

const useObtenerDomicilioPrincipal = () => {
  const [datosDomicilios, errorDatosDomicilios, isLoadingDatosDomicilios] =
    useObtenerDatosDomicilio();

  return [
    datosDomicilios?.find((domicilio) => domicilio.DomicilioPrincipal === true),
    errorDatosDomicilios,
    isLoadingDatosDomicilios,
  ];
};

export default useObtenerDomicilioPrincipal;
