import React, { useEffect } from 'react';
import useObtenerDatosClienteOnLoad from '../../Hooks/useObtenerDatosClienteOnLoad';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { useStore } from '../../Store/storeProvider';

const RetainTypeform = () => {
    const [cliente, errorCRM, isLoadingCRM] = useObtenerDatosClienteOnLoad();
    const { HandleSwitchsStates } = useStore();

    const checkTypeformState = () => {
        if (HandleSwitchsStates?.TypeformRetenerCliente == true)
            window.open(`${process.env.REACT_APP_RETAIN_TYPEFORM}#crmid=${cliente.Crmid}`, '_self');
        else
            window.location.replace(process.env.REACT_APP_HOME)   
    }

    useEffect(() => {
        if (errorCRM) window.location.replace(process.env.REACT_APP_LOGIN)

        if (HandleSwitchsStates && cliente) checkTypeformState()
    }, [errorCRM, cliente, HandleSwitchsStates])

    return (
        <LoadingSpinner isLoading={isLoadingCRM} />
    );
};

export default RetainTypeform;
