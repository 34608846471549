const cols = [
    {
        field: 'fecha',
        name: 'Fecha',
    },
    {
        field: 'descuento',
        name: 'Descuento',
    },
    {
        field: 'comercio',
        name: 'Comercio',
    },
    {
        field: 'rubro',
        name: 'Rubro',
    },
];

export default cols;