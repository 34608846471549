import { useEffect, useState } from 'react';
import Mensaje from '../Models/Mensaje';
import Datadog from '../Helpers/Datadog';
import { CustomEventsErrorDatadog } from '../Config/Datadog';
import ApiService from '../Services/ApiService';

const useGetCampaniasUpsellingOnDemand = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [campaniasUpselling, setCampaniasUpselling] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        return ApiService.post(`${REACT_APP_APIPAYWALL}CampaniaV1/GetPlanesUpselling`)
            .then((res) => {
                setIsLoading(false);
                if (res.code == '0000') {
                    setCampaniasUpselling(res.response);
                    return Promise.resolve(res.response);
                } else {
                    Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorConsultaCampaniasUpselling, { response: res });
                    return Promise.reject(error);
                }
            })
            .catch((err) => {
                setError(Mensaje.getError('GENERAL'));
                Datadog.trackCustomEventError(CustomEventsErrorDatadog.errorConsultaCampaniasUpselling, { error: err });
                setIsLoading(false);
                return Promise.reject(error);
            });
    };

    return [campaniasUpselling, error, loading, fetchData];
};
export default useGetCampaniasUpsellingOnDemand;
