import React from 'react';

import '../../../Statics/Scss/Components/Box.scss';
import classNames from 'classnames';

const Box = ({className, children, as: Component = 'section', ...r}) => {
	const classes = classNames('mc-box', className);

	return (
		<Component className={classes} {...r}>
			{children}
		</Component>
	);
};

export default Box;
