import React from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';
import ContactoDudasBanner from '../../Components/Common/Banners/ContactoDudas';
import Toast from '../../Components/Common/Modules/Toast';
import Button from '../../Components/Common/Modules/Button';
import FormRow from '../../Components/Common/Atoms/Form/FormRow';
import PathsConfig from '../../Config/RoutePaths';
import Divider from '../../Components/Common/Atoms/Divider';
import useRedirectWithout from '../../Hooks/useRedirectWithout';
import LoadingSpinner from './../../Helpers/LoadingSpinner';
import useObtenerEventosSuscripcionesOnDemand from '../../Hooks/useObtenerEventosSuscripcionesOnDemand';
import { readCookie } from '../../Helpers/Cookies';

export const ActivarBonificadaConfirmacion = (props) => {
    const history = useHistory();
    const stepProps = ['activacionExitosa'];
    const state = useRedirectWithout(
        props.location.state,
        ['activacionExitosa'],
        PathsConfig.VincularCredencialMiClub
    );
    const crmId = readCookie("Crm_id");
    const [, cookiesActualizadas, errorObtenerEventosOnDemand, isLoadingObtenerEventosOnDemand] = useObtenerEventosSuscripcionesOnDemand(crmId);

    return (
        <section className="container-streched">
            <Breadcrumb
                firstItem="Club LA NACION"
                text="Vincular credencial"
                href="#"
            />
            <TitleWeb text="Acceso Digital" />
            <LoadingSpinner isLoading={isLoadingObtenerEventosOnDemand}>
                <fieldset>
                    {errorObtenerEventosOnDemand || !cookiesActualizadas ? (
                        <Toast
                            positionFloat="center"
                            closeAuto={3000}
                            status="warning"
                            title="Se ha producido un error al completar la activación"
                        >Por favor vuelva a intenterlo más tarde</Toast>
                    ) :
                        <>
                            <Toast status="success" title={'¡Ya sos suscriptor digital!'}>
                                Seguí disfrutando LA NACION sin límites.
                            </Toast>
                            <FormRow mod="--btn-groups">
                                <Button label="IR A LA NACION" tag="a"
                                    className="--mb-0"
                                    mod="primary"
                                    href={'https://www.lanacion.com.ar'} />
                                <Button href={'#'} mod="secondary" label="VOLVER"
                                    onClick={() => {
                                        history.replace({
                                            pathname: PathsConfig.VincularCredencialMiClub,
                                            state: {
                                                redireccion: true,
                                            }
                                        })
                                    }} />
                            </FormRow>
                            <Divider />
                            <ContactoDudasBanner />

                        </>
                    }
                </fieldset>
            </LoadingSpinner>

        </section>
    );
}