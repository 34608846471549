import React from 'react';
import Paragraph from '../../Common/Atoms/Paragraph';
import Button from '../../Common/Modules/Button';
import CallbackIconTextButton from '../../Common/Modules/CallbackIconTextButton';

const Facebook = ({ identitiesProviderData, showAlertToast, linkFacebookAccount, unlinkFacebookAccount }) => {

    const vinculadoFacebook = identitiesProviderData?.facebookProfile ? true : false;
    const profileName = identitiesProviderData?.facebookProfile?.display_name ?? '';
    const isPrimaryProfile = identitiesProviderData?.facebookProfile?.is_primary;
    const nativeProfileExist = identitiesProviderData?.nativeProfile ? true : false;
    const shouldNativeLogin = nativeProfileExist && isPrimaryProfile;
    const shouldFacebookLogin = vinculadoFacebook && !identitiesProviderData?.facebookProfile?.is_migrated;
    const handleUnlinkFacebook = () => {
        if(shouldNativeLogin){
            showAlertToast(`Para poder desvincular su perfil de Facebook debe iniciar sesión con su cuenta de email y contraseña: ${identitiesProviderData?.nativeProfile.display_name}`, 'alert');
        }
        else if (shouldFacebookLogin){
            showAlertToast(`Para poder desvincular su perfil de Facebook debe iniciar sesión con su cuenta de Facebook`, 'alert');
        }
        else{
            unlinkFacebookAccount();
        }
    }

    return (
        <CallbackIconTextButton
            mod={'--ai-center --mb-32'}
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path
                        d="M14 0.666687C6.636 0.666687 0.666664 6.63602 0.666664 14C0.666664 20.6547 5.54266 26.1707 11.9173 27.172V17.8534H8.53066V14H11.9173V11.0627C11.9173 7.72135 13.9067 5.87602 16.9533 5.87602C18.412 5.87602 19.9373 6.13602 19.9373 6.13602V9.41602H18.2573C16.6 9.41602 16.084 10.444 16.084 11.4987V14H19.7813L19.1907 17.8534H16.084V27.172C22.4573 26.172 27.3333 20.6534 27.3333 14C27.3333 6.63602 21.364 0.666687 14 0.666687Z"
                        fill="#0074C4"
                    />
                </svg>
            }
            text={
                <>
                    <Paragraph size="--twoxs" mod="--font-bold --arial --mb-8">
                        Facebook
                    </Paragraph>
                    {vinculadoFacebook && (
                        profileName != '' ? (
                            <Paragraph size="--fourxs" mod="--arial --mb-8">
                                Conectado como: {profileName}
                            </Paragraph>
                        ) : (
                            <Paragraph size="--fourxs" mod="--arial --mb-8">
                                Cuenta ya vinculada
                            </Paragraph>
                        )
                    )}
                </>
            }
            button={
                (vinculadoFacebook && (!isPrimaryProfile || shouldNativeLogin || shouldFacebookLogin)) ? //vinculado: vinculada con facebook y que facebook no sea su perfil primario, y en caso de ser perfil primario y tieneuna cuenta de usuario y contraseña sin migrar. Permite desvincular.
                    <Button
                        onClick={handleUnlinkFacebook}
                        href={'#'}
                        label="DESVINCULAR"
                        className="state--w100 --mb-0"
                        mod="tertiary"
                        isFirstPositioned={true}
                    /> :
                    !vinculadoFacebook && ( //si no está vinculado
                        <Button
                            onClick={linkFacebookAccount}
                            href={'#'}
                            aria-label="Vincular con la cuenta de Facebook"
                            label="VINCULAR"
                            className="state--w100 --mb-0"
                            mod="tertiary"
                            isFirstPositioned={true}
                        />
                    )}
        />
    );
};

export default Facebook;
