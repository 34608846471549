const env = process.env.REACT_APP_ENV;
const ConfigUpselling =
{
    local: {
        "campania": {
            "key": "up-selling",
            "id": 409,
            "productCategory": "Upsell",
            "formaContactoDefault": 580,
            "canalVentaDefault": 578,
            "planes": [
                {
                    "pasate-a-duo":
                    {
                        "id": 4530,
                        "nombre": "Dúo Premium",
                        "comboType": "ga-comboDuo"
                    }
                },
                {
                    "pasate-a-triple":
                    {
                        "id": 4528,
                        "nombre": "Triple Premium",
                        "comboType": "ga-comboTriple"
                    }
                },
                {
                    "pasate-a-black":
                    {
                        "key": "pasate-a-black",
                        "id": 4529,
                        "nombre": "Black Familiar",
                        "comboType": "ga-comboFamiliar"
                    }
                }
            ]
        }
    },
    QA:
    {
        "campania": {
            "key": "up-selling",
            "id": 409,
            "productCategory": "Upsell",
            "formaContactoDefault": 580,
            "canalVentaDefault": 578,
            "planes": [
                {
                    "pasate-a-duo":
                    {
                        "id": 4530,
                        "nombre": "Dúo Premium",
                        "comboType": "ga-comboDuo"
                    }
                },
                {
                    "pasate-a-triple":
                    {
                        "id": 4528,
                        "nombre": "Triple Premium",
                        "comboType": "ga-comboTriple"
                    }
                },
                {
                    "pasate-a-black":
                    {
                        "key": "pasate-a-black",
                        "id": 4529,
                        "nombre": "Black Familiar",
                        "comboType": "ga-comboFamiliar"
                    }
                }
            ]
        }
    },
    PRE:
    {
        "campania": {
            "key": "up-selling",
            "id": 409,
            "productCategory": "Upsell",
            "formaContactoDefault": 580,
            "canalVentaDefault": 578,
            "planes": [
                {
                    "pasate-a-duo":
                    {
                        "id": 4286,
                        "nombre": "Dúo Premium",
                        "comboType": "ga-comboDuo"
                    }
                },
                {
                    "pasate-a-triple":
                    {
                        "id": 4287,
                        "nombre": "Triple Premium",
                        "comboType": "ga-comboTriple"
                    }
                },
                {
                    "pasate-a-black":
                    {
                        "key": "pasate-a-black",
                        "id": 4288,
                        "nombre": "Black Familiar",
                        "comboType": "ga-comboFamiliar"
                    }
                }
            ]
        }
    },
    PROD:
    {
        "campania": {
            "key": "up-selling",
            "id": 409,
            "productCategory": "Upsell",
            "formaContactoDefault": 580,
            "canalVentaDefault": 578,
            "planes": [
                {
                    "pasate-a-duo":
                    {
                        "id": 4286,
                        "nombre": "Dúo Premium",
                        "comboType": "ga-comboDuo"
                    }
                },
                {
                    "pasate-a-triple":
                    {
                        "id": 4287,
                        "nombre": "Triple Premium",
                        "comboType": "ga-comboTriple"
                    }
                },
                {
                    "pasate-a-black":
                    {
                        "key": "pasate-a-black",
                        "id": 4288,
                        "nombre": "Black Familiar",
                        "comboType": "ga-comboFamiliar"
                    }
                }
            ]
        }
    }
}

const baseURl = "/confirmar-upselling/up-selling/"

export const EnlacesDeRedireccionPorID = {
    "wall":  `${process.env.REACT_APP_WALL_VOLUNTARIO}?cv=670&fc=771&callback=${window.btoa(location.href)}`,
    "79": baseURl + "pasate-a-club",
    "78": baseURl + "pasate-a-duo",
    "83": baseURl + "pasate-a-triple",
    "85": baseURl + "pasate-a-black"
}

export const IdsFamiliaProducto = Object.keys(EnlacesDeRedireccionPorID)

export default ConfigUpselling[env];