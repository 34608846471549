import { validacionesCambioPago } from "../Config/Suscripciones";

const hasAvailableAppleSuscription = (suscripciones) => {
  var sus = suscripciones.filter((suscripcion) => {
    return canPagoApple(suscripcion);
  });

  return sus.length > 0;
};

const canPagoApple = (suscripcion) => {
  if (!suscripcion || !suscripcion.EsApple) {
    return false;
  };

  return validacionesCambioPago.includes(suscripcion.Estado);
};

export default { hasAvailableAppleSuscription };
