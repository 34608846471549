import React from 'react'
import { useHistory } from 'react-router-dom'
import PathsConfig from '../../../Config/RoutePaths';
import { Text } from '@ln/common-ui-text'
import { Button } from '@ln/experiencias-ui-button';
import { Groupbutton } from '@ln/experiencias-ui-groupbutton';
import Divider from '../../Common/Atoms/Divider';
import ContactoDudasBanner from '../../Common/Banners/ContactoDudas';
import _Logout from '../../../Handlers/_Logout';

export const ErrorActivarSuscripcion = ({ email }) => {
  const history = useHistory();

  const handleVolver = () => {
    history.push({
      pathname: PathsConfig.MisSuscripciones
    })
  }

  const handleLogout = () => {
    _Logout.Logout(true);
  }

  return (
    <>
      <div className='flex flex-column gap-32 mb-32 arial'>
        <div className='flex flex-column gap-16'>
          <Text as='h5' className='arial-bold text-20'>Hola {email}</Text>
          <div className='text-14'>
            <Text as='p' className='arial-bold'>No podemos activar tu suscripción</Text>
            <Text as='p'>Actualmente ingresaste con tu cuenta {email}, y ya posees una o más suscripciones asociadas al usuario. Si esta no es tu cuenta, inicia sesión con el usuario correspondiente para activar tu suscripción</Text>
          </div>
        </div>
        <Groupbutton>
          <Button title="CAMBIAR USUARIO" onClick={handleLogout}>CAMBIAR USUARIO</Button>
          <Button title="VOLVER" variant='secondary' onClick={handleVolver}>VOLVER</Button>
        </Groupbutton>
      </div>
      <Divider />
      <ContactoDudasBanner />
    </>
  )
}
