import React, { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useGuardarRtaWondersoft = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [respuestaWs, setRespuestaWs] = useState([]);
    const [error, setError] = useState(null);

    const outFunc = async (payload) => {
        setIsLoading(true);
        return ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/GuardarRespuestaWs`, payload)
            .then((data) => {
                if (data && data.Code === 0) {
                    setRespuestaWs(data.Message);
                } else {
                    setError(data.Message);
                }
                setIsLoading(false);
                return Promise.resolve(data);
            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
                return Promise.reject(error);
            });
    };

    return [respuestaWs, error, isLoading, outFunc];
};

export default useGuardarRtaWondersoft;
