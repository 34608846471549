import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { readCookie } from '../../Helpers/Cookies';
import GoogleTagManagerService from '../../Helpers/GoogleTagManagerService';
import { useHistory } from 'react-router';
import PathsConfig from '../../Config/RoutePaths';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../Components/Common/Web/Title';
import useActualizarProductoPremium from '../../Hooks/useActualizarProductoPremium';
import useGuardarEventoProductoPremium from '../../Hooks/useGuardarEventoProductoPremium';
import ConfirmacionLogin from '../../Components/Common/Login/ConfirmacionLogin';
import DetallesVentaBonificada from '../../Components/Common/Banners/DetallesVentaBonificada';
import Toast from '../../Components/Common/Modules/Toast';
import { TipoOperacionAccesoDigital } from '../../Config/TIpoOperacionAccesoDigital';

export const ActivarBonificada = (props) => {
    const [errorActualizarPP, isLoadingActualizarPP, fetchActualizarProductoPremium] = useActualizarProductoPremium();
    const [errorGuardarEventoPP, isLoadingGuardarPP, fetchGuardarEventoProductoPremium] = useGuardarEventoProductoPremium()
    const history = useHistory();
    const [isLoadingVentaBonificada, setIsLoadingVentaBonificada] = useState(false);
    const [error, setError] = useState(false);
    const userEmail = readCookie('usuarioemail');
    
    useEffect(() => {
        let params = {
            pageName: 'debe_aceptar_terminosycondiciones',
            checkoutProd: 'Acceso Digital Bonificado',
        }
        GoogleTagManagerService.trackCustomEvent('trackPage', params);
    }, []);

    useEffect(() => {
        if (errorActualizarPP || errorGuardarEventoPP) {
            setError(true);
        }
    }, [errorActualizarPP, errorGuardarEventoPP]);

    const navegarConfirmacion = () => {
        history.replace({
            pathname: PathsConfig.VincularCredencialActivarBonificadaConfirmacion,
            state: {
                activacionExitosa: true,
            },
        });
    };

    const actualizarProductoPremiumCallback = () => {
        const fechaEnvioEvento = new Date();
        const eventoProp = props.location.state;
        //response es 0 cuando se actualizo correctamente el usuarioProdPremium, si vuelve 1 el registro ya existia
        const eventoGuardar = {
            SuscripcionId: eventoProp?.SuscripcionId,
            IsBonificada: eventoProp?.IsBonificada,
            ProductosPremium: eventoProp?.ProductosPremium,
            EstadoId: 3, //3 para activacion
            EstadoTyC: 'Activa',
            IdEstadoSuscripcion: eventoProp?.IdEstadoSuscripcion,
            FechaEvento: fechaEnvioEvento,
            EstadoSuscripcion: eventoProp?.EstadoSuscripcion,
            FechaActivacion: fechaEnvioEvento,
            TipoOperacion: TipoOperacionAccesoDigital.AceptarTyC
            //el resto lo saco desde el back
        };

        fetchGuardarEventoProductoPremium(eventoGuardar, navegarConfirmacion);
    }

    const handleClickFinalizar = () => {
        fetchActualizarProductoPremium(props.location.state, actualizarProductoPremiumCallback);
    };

    const isLoading = () => {
        return isLoadingVentaBonificada || isLoadingActualizarPP || isLoadingGuardarPP
    }

    return (
        <>
            <section>
                <Breadcrumb
                    firstItem="Club LA NACION"
                    text="Vincular credencial"
                    href="#"
                />
                <TitleWeb text="Vincular credencial" />
            </section>
            <section className="container-streched">
                {error && (
                    <Toast
                        positionFloat="center"
                        closeAuto={3000}
                        status="warning"
                        value={error}
                        changeValue={setError}
                        title="Se ha producido un error al solicitar la activación"
                    >Por favor vuelva a intenterlo más tarde</Toast>
                )}
                <LoadingSpinner isLoading={isLoading()}>
                    <ConfirmacionLogin
                        titleText={'¡Bienvenido!'}
                        firstBtnLabel={'continuar'}
                        firstBtnOnClick={handleClickFinalizar}
                        showCallbackArticle={false}
                    >
                        <DetallesVentaBonificada email={userEmail} />
                    </ConfirmacionLogin>
                </LoadingSpinner>
            </section>
        </>
    );
}