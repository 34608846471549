import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../Components/Common/Modules/Breadcrumb';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../Components/Common/Organism/ErrorBoundary';
import { getCondicionSuscripcionUsuario, estadoSuscripcion } from '../../Models/CondicionSuscripcion';
import IncentivarVincularClubBanner from '../../Components/Common/Banners/IncentivarVincularClub';
import IncentivarSuscripcionBanner from '../../Components/Common/Banners/IncentivarSuscripcion';
import IncentivarAgregarClubBanner from '../../Components/Common/Banners/IncentivarAgregarClub';
import TitleWeb from '../../Components/Common/Web/Title';
import PathsConfig from '../../Config/RoutePaths';
import useObtenerVerificacionCredenciales from '../../Hooks/useObtenerVerificacionCredenciales ';
import { useHistory } from 'react-router';
import useObtenerRelacionClub from '../../Hooks/useObtenerRelacionClub';
import { useStore } from '../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../Helpers/FuncionalidadDeshabilitada';
import useObtenerSuscripciones from '../../Hooks/useObtenerSuscripciones';

const VincularCredencial = () => {
    const { HandleSwitchsStates } = useStore();
    const [dataRelacionClub, errorRelacionClub, isLoadingRelacion] = useObtenerRelacionClub();
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const condicionSuscripcion = getCondicionSuscripcionUsuario(dataRelacionClub?.response);
    const conSuscripciones = suscripciones?.length > 0;
    const [isBlockedCredenciales, errorVerificacionCredenciales, isLoadingVerificacionCredenciales] = useObtenerVerificacionCredenciales();
    const history = useHistory();
    const [functionalityClosed, setFunctionalityClosed] = useState(false);
    const tieneCredencial = () => {
        return dataRelacionClub?.response?.tieneClub;
    };
    const noTieneCredencialesBloqueadas = () => {
        return !isBlockedCredenciales || !errorVerificacionCredenciales;
    };

    useEffect(() => {
        if (funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.VinculacionCredencial)) {
            setFunctionalityClosed(true);
        } else {
            if (dataRelacionClub != null) {
                if (noTieneCredencialesBloqueadas()) {
                    if (tieneCredencial()) {
                        history.replace({
                            pathname: PathsConfig.VincularCredencialMiClub,
                            state: {
                                redirectFromNoSuscriptor: true,
                            },
                        });
                    }
                }
            }
        }
    }, [dataRelacionClub]);

    return (
        <div>
            <Breadcrumb firstItem="Club LA NACION" text="Vincular credencial" href="#" />
            <TitleWeb text="Vincular credencial" />
            <div className="container-streched">
                {/*Pantalla de carga*/}
                {functionalityClosed ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.VinculacionCredencial} />
                ) : (
                    <LoadingSpinner isLoading={isLoadingRelacion || isLoadingSuscripciones || isLoadingVerificacionCredenciales}>
                        <ErrorBoundary error={errorRelacionClub || errorVerificacionCredenciales || (errorSuscripciones && !suscripciones)}>
                            <>
                                {dataRelacionClub && (!dataRelacionClub.response || !dataRelacionClub.nroCredencial) && (
                                    <IncentivarVincularClubBanner />
                                )}
                                {
                                    //TODO: Si tiene suscripciones digitales y no tiene tarjetas (tiene combo 1)
                                    conSuscripciones &&
                                        (condicionSuscripcion === estadoSuscripcion.SIN_SUSCRIPCION ||
                                            condicionSuscripcion === estadoSuscripcion.SUSCRIP_SIN_CLUB) && <IncentivarAgregarClubBanner />
                                }

                                {
                                    //TODO: Si no tiene suscripciones digitales activas, se muestra
                                    ((condicionSuscripcion === estadoSuscripcion.SIN_SUSCRIPCION && !conSuscripciones) ||
                                        condicionSuscripcion === estadoSuscripcion.CLASSIC) && <IncentivarSuscripcionBanner />
                                }
                            </>
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </div>
        </div>
    );
};

export default VincularCredencial;
