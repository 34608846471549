import React, { useEffect } from 'react';

import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import useObtenerDatosCRM from '../../../Hooks/useObtenerDatosCRM';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import FormularioSolicitudBaja from '../../../Components/Features/MisSuscripciones/FormularioSolicitudBaja';
import TitleWeb from '../../../Components/Common/Web/Title';
import { useStore } from '../../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';
import { esAptoParaBaja } from '../../../Filter/solicitudBaja';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import PathsConfig from '../../../Config/RoutePaths';

const ConfirmarBaja = (props) => {
    const [cliente, errorCRM, isLoadingCRM, getDataCrm] = useObtenerDatosCRM();
    const { HandleSwitchsStates } = useStore();
    const stepProps = ['suscripciones', 'suscripcionABajar', 'motivo'];
    const { suscripciones, suscripcionABajar, motivo } = useRedirectWithout(props.location.state, stepProps, PathsConfig.MisSuscripciones);

    useEffect(() => {
        getDataCrm();
    }, []);

    return (
        <>
            <section>
                <Breadcrumb firstItem={'Mis suscripciones'} href={'#'} text={'Solicitud de baja'} />
                <TitleWeb text="Solicitud de baja" />
            </section>
            <section className="container-streched arial">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.BajaSuscripciones) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.BajaSuscripciones} />
                ) : (
                    <LoadingSpinner isLoading={isLoadingCRM}>
                        <ErrorBoundary error={errorCRM}>
                            <>
                                {cliente && cliente.DatosPersonales && (
                                    <FormularioSolicitudBaja
                                        cliente={cliente.DatosPersonales}
                                        suscripcionABajar={suscripcionABajar}
                                        suscripciones={suscripciones}
                                        motivo={motivo}
                                    />
                                )}
                            </>
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </section>

        </>
    );
};

export default ConfirmarBaja;
