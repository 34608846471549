import React, { useState } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useHistory } from 'react-router';

import Toast from "../../../Components/Common/Modules/Toast";
import FormCol from '../../Common/Atoms/Form/FormCol';
import InputBox from '../../Common/Atoms/Form/InputBox';
import InputGroup from '../../Common/Atoms/Form/InputGroup';
import Label from '../../Common/Atoms/Form/Label';
import InputFormik from '../../Common/Formik/Input';
import RadioFormik from '../../Common/Formik/Radio';
import FormBajaSuscripcionesSchema from '../../../Validations/FormBajaSuscripcionesSchema';
import CheckboxesMap from '../../../Map/SuscripcionesToInputListMap';
import useSuscripcionesBajaWeb from "../../../Hooks/useSuscripcionesBajaWeb";
import LoadingSpinner from "../../../Helpers/LoadingSpinner";
import ContactoDudasBanner from "../../../Components/Common/Banners/ContactoDudas";
import PathsConfig from "../../../Config/RoutePaths";
import { Groupbutton } from "@ln/experiencias-ui-groupbutton";
import { Button } from "@ln/experiencias-ui-button";
import { Text } from "@ln/common-ui-text";
import TextAreaFormik from "../../Common/Formik/TextArea";

const propTypes = { suscripciones: PropTypes.array };

const FormularioSolicitudBaja = (props) => {
    const history = useHistory();
    const { suscripciones, cliente, suscripcionABajar, motivo } = props;
    const [dataForm, errorForm, isLoadingForm, solicitarBaja] = useSuscripcionesBajaWeb();
    const [horarioSelected, setHorarioSelected] = useState("");
    const [invalidHorario, setInvalidHorario] = useState(false);

    const initialValuesFormik = {
        telefono: cliente.TelCelular,
        telAlternativo: cliente.TelParticular,
        email: cliente.EMail,
        franjaHoraria: horarioSelected
    };

    const handleOnChangeHorario = (event) => {
        const horario = event.target.value;
        setHorarioSelected(horario);
        setInvalidHorario(false);
    };

    const handleValidationsOnSubmit = () => {
        setInvalidHorario(!horarioSelected);
    };

    const getFranja = (value) => {
        let matches = value.match(/\D+(\d+)\D+(\d+)/)
        return { inicio: parseInt(matches[1]), fin: parseInt(matches[2]) };
    };

    const redirectToConfirmacionBaja = () => {
        history.push({
            pathname: PathsConfig.ConfirmacionBaja,
            state: {
                suscripcionesABajar: [suscripcionABajar],
                motivo
            }
        })
    }

    const getMotivo = (observaciones) => {
        if (observaciones) return motivo.split("|")[1].concat(' | ', observaciones);
        return motivo.split("|")[1];
    }

    const onSubmitFormik = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);

        const payload = {
            ...values,
            franjaHoraria: horarioSelected,
            franjaHorariaInicio: getFranja(horarioSelected).inicio,
            franjaHorariaFin: getFranja(horarioSelected).fin,
            suscripcionesABajar: [{ suscripcionId: suscripcionABajar.id, nombreAmigable: suscripcionABajar.nombre }],
            motivo: getMotivo(values.observaciones),
        };

        solicitarBaja(payload, redirectToConfirmacionBaja)

        setSubmitting(false);
    };

    const clickCancelar = () => {
        history.push({
            pathname: PathsConfig.MisSuscripciones
        });
    };

    return (
        <Formik
            validationSchema={FormBajaSuscripcionesSchema}
            initialValues={initialValuesFormik}
            onSubmit={onSubmitFormik}
        >
            {() => (
                <LoadingSpinner isLoading={isLoadingForm}>
                    <Form noValidate>
                        {errorForm ? <Toast status="warning" title={errorForm} /> :
                            <>
                                <div className="flex flex-column gap-32">
                                    <div className="flex flex-column gap-16">
                                        <Text as="h5" className="arial-bold text-18 text-neutral-light-800">Suscripciones en las cuales querés solicitar la baja</Text>
                                        {suscripciones && (
                                            <div className="flex flex-column gap-8">
                                                <Text className="text-14 text-neutral-light-800">Mis suscripciones</Text>
                                                {CheckboxesMap({ suscripciones, suscripcionSeleccionada: suscripcionABajar, disabled: true })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex flex-column gap-16">
                                        <Text as="h5" className="arial-bold text-18 text-neutral-light-800">Ingresá los datos donde podamos contactarte para gestionar la baja</Text>
                                        <div>
                                            <Label text="Teléfono de contacto" />
                                            <InputFormik
                                                name="telefono"
                                                type="text"
                                                description="Código de área y número (Ej: 0117891234)"
                                                aria-label="Ingrese su número de teléfono con el código de área"
                                            />
                                        </div>
                                        <div>
                                            <Label text="Teléfono alternativo" />
                                            <InputFormik
                                                name="telAlternativo"
                                                type="text"
                                                description="Código de área y número (Ej: 0117891234)"
                                                aria-label="Ingrese un teléfono alternativo (opcional)"
                                            />
                                        </div>
                                        <div>
                                            <Label text="Email de contacto" />
                                            <InputFormik
                                                name="email"
                                                type="email"
                                                description="Ej: email@mail.com"
                                                aria-label="Ingrese su email"
                                            />
                                        </div>

                                        <div>
                                            <Label text="Horario de contacto" />
                                            <div className="grid grid-cols-2">
                                                <InputBox
                                                    error={invalidHorario}
                                                    success={horarioSelected !== ""}
                                                    input={
                                                        <RadioFormik
                                                            aria-label="Seleccione horario de 8 a 14"
                                                            onChange={handleOnChangeHorario}
                                                            value={"De 8 a 14"}
                                                            name="franjaHoraria"
                                                            checked={horarioSelected === "De 8 a 14"}
                                                            error={invalidHorario}
                                                            success={horarioSelected !== ""}
                                                        >
                                                            De 8 a 14
                                                        </RadioFormik>
                                                    }
                                                />
                                                <InputBox
                                                    error={invalidHorario}
                                                    success={horarioSelected !== ""}
                                                    input={
                                                        <RadioFormik
                                                            aria-label="Seleccione horario de 14 a 20"
                                                            onChange={handleOnChangeHorario}
                                                            value={"De 14 a 20"}
                                                            name="franjaHoraria"
                                                            checked={horarioSelected === "De 14 a 20"}
                                                            error={invalidHorario}
                                                            success={horarioSelected !== ""}
                                                        >
                                                            De 14 a 20
                                                        </RadioFormik>
                                                    }
                                                />
                                            </div>
                                            {invalidHorario && (
                                                <span className="com-input__box__descrip --fivexs --red-500">
                                                    Seleccioná un horario de contacto.
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label text="Observaciones" />
                                            <TextAreaFormik
                                                name="observaciones"
                                                aria-label="Ingrese observaciones"
                                                rows={4}
                                            />
                                        </div>
                                    </div>


                                    <Groupbutton>
                                        <Button title="SOLICITAR BAJA" type="submit" onClick={handleValidationsOnSubmit}>SOLICITAR BAJA</Button>
                                        <Button title="CANCELAR" variant="secondary" onClick={clickCancelar}>CANCELAR</Button>
                                    </Groupbutton>
                                </div>
                            </>
                        }
                    </Form>
                    <ContactoDudasBanner />
                </LoadingSpinner>
            )}
        </Formik>
    );
};

FormularioSolicitudBaja.propTypes = propTypes;

export default FormularioSolicitudBaja;