import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../../../Config/RoutePaths';
import { esAptoParaBaja } from '../../../Filter/solicitudBaja';
import { canPago, showReadPdfFeature, isPinNYT, canReclamarEditorial, esAptoUpselling, isBonificado } from '../../../Filter/suscripciones';
import useObtenerPlanes from '../../../Hooks/useObtenerPlanes';
import useSuscripcionesConEstado from '../../../Hooks/useSuscripcionesConEstado';
import useObtenerSuscripcionesDigitalesAnalitics from '../../../Hooks/useObtenerSuscripcionesDigitalesAnalitics';
import Embed from '../../../Models/Embed';
import estadoSuscripciones from '../../../Models/EstadoSuscripciones';
import useObtenerPinUsuario from '../../../Hooks/useObtenerPinUsuario';
import useObtenerEvento from '../../../Hooks/useObtenerEvento';
import useGetCampaniasUpselling from '../../../Hooks/useGetCampaniasUpselling';
import { esSuscriptorDigital } from '../../../Helpers/Subscription';
import Date from '../../../Helpers/Date';
import { useStore } from '../../../Store/storeProvider';
import { getUpsellingKeys } from '../../../Config/UpsellingNombresAmigables';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';
import { accesosDigitalesIds } from '../../../Config/AccesosDigitalesIds.js';
import { TipoVentaUpselling } from '../../../Models/TipoVenta.js';
import useObtenerSuscripciones from '../../../Hooks/useObtenerSuscripciones.js';

const useGetPlanesYsuscripciones = () => {
	const [error, setError] = useState();
	const { REACT_APP_UPSELL_ADC_MONTHS } = process.env;
	const [isLoading, setIsLoading] = useState(true);
	const [suscripcionesHook, setsuscripcionesHook] = useState([]);
	const [planesHook, setplanesHook] = useState([]);
	const [planes, errorPlanes, isLoadingPlanes] = useObtenerPlanes();
	const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
	const [suscripcionesConEstado, errorSuscripcionesConEstado, isLoadingSuscripcionesConEstado] = useSuscripcionesConEstado();
	const [evento, errorEventos, isLoadingEventos] = useObtenerEvento();
	const [suscriptionLinks, setsuscriptionLinks] = useState();
	const historyRouter = useHistory();
	const [esApta, setEsApta] = useState(true);
	const [dataPin, errorPin, isLoadingPin] = useObtenerPinUsuario();
	const [suscripcionesDA, errorSuscripcionesDA, isLoadingSuscripcionesDA] = useObtenerSuscripcionesDigitalesAnalitics();
	const [campaniasUpselling, errorCampaniasUpselling, isLoadingCampaniasUpselling] = useGetCampaniasUpselling();
	const arrayCampanias = campaniasUpselling?.flatMap(campania => campania.planes);
	const planAdClubId = arrayCampanias?.find(plan => plan.key === getUpsellingKeys.AccesoYClub)?.id;
	const { HandleSwitchsStates } = useStore();
	const orderGA = ['ga-combo2', 'ga-comboDuo', 'ga-comboTriple', 'ga-comboFamiliar'];
	const planFamiliar = orderGA[3];
	const campaniaClub = campaniasUpselling?.filter(item =>
		item.planes.some(plane => plane.key === getUpsellingKeys.AccesoYClub)
	);
	const campaniaNoClub = campaniasUpselling?.filter(item =>
		item.planes.every(plane => plane.key !== getUpsellingKeys.AccesoYClub)
	);

	useEffect(() => {

		if (!isLoadingPlanes && !isLoadingSuscripciones && !isLoadingSuscripcionesConEstado && !isLoadingPin && !isLoadingEventos && !isLoadingSuscripcionesDA && !isLoadingCampaniasUpselling) {
			setsuscripcionesHook(suscripciones);
			setplanesHook(planes);
			setsuscriptionLinks(suscripciones?.map((sus) => listDropCreator(sus, planes, evento)));
			setIsLoading(false);
			if (errorPlanes || errorEventos || (errorSuscripcionesConEstado && !suscripcionesConEstado) || (errorSuscripciones && !suscripciones) || errorSuscripcionesDA || errorCampaniasUpselling) {
				setError(true);
			}
		}
	}, [isLoadingPlanes, isLoadingSuscripciones, isLoadingSuscripcionesConEstado, isLoadingPin, isLoadingEventos, isLoadingSuscripcionesDA, isLoadingCampaniasUpselling]);

	const handlerSolicitarBaja = (id) => {
		const filteredSuscription = suscripcionesConEstado.filter((suscripcion) => suscripcion.IdSuscripcion === id);
		if (!esAptoParaBaja(...filteredSuscription)) {
			setEsApta(false);
		} else {
			historyRouter.push({ pathname: PathsConfig.SolicitudBaja, state: { internalNavigation: true } });
		}
	};

	const listDropCreator = (sus, planes, evento) => {
		let links = [];

		if (sus.Estado === estadoSuscripciones.BAJA) {
			return null;
		}

		if (sus.EsGoogle) {
			links.push({
				name: 'Administrar a través de Google',
				onClick: () => window.open(!Embed ? `${process.env.REACT_APP_PAYMENTGOOGLE}` : '', '_blank'),
			});
		}
		if (sus.EsApple) {
			links.push({
				name: 'Administrar a través de Apple',
				onClick: () => window.open(!Embed ? `${process.env.REACT_APP_PAYMENTAPPLE}` : '', '_blank'),
			});
		}
		if (showReadPdfFeature(sus, planes)) {
			links.push({
				name: 'Leer PDF',
				onClick: () => window.open(!Embed ? 'https://pdf.lanacion.com.ar' : '', '_blank'),
			});
		}
		if (isPinNYT(sus, planes)) {
			links.push({
				name: `Leer ${dataPin && dataPin.descripcion}`,
				onClick: () => window.open(!Embed ? 'https://www.nytimes.com/subscription/redeem?campaignId=77HWU&amp;gift_code=%3c/url%3e' : '', '_blank'),
			});
		}
		if (canPago(sus, evento, planes)) {
			links.push(
				{ name: 'Cambiar forma de pago', onClick: () => (location.href = `${process.env.REACT_APP_MICUENTA}cambiar-forma-de-pago`) },
				{ name: 'Solicitar baja', onClick: () => handlerSolicitarBaja(sus.Id) }
			);
		}
		if (canReclamarEditorial(sus, planes)) {
			links.push({
				name: 'Reclamo de entrega',
				onClick: () => {
					historyRouter.push({
						pathname: PathsConfig.ReclamoEntrega,
						state: {
							suscripcionSeleccionado: sus,
						},
					});
				},
			});
		}
		if (esAptoUpselling(sus, planes) && !sus.payment && !isBonificado(evento, sus, planes)) {
			if (sus.IdAccesoDigital == accesosDigitalesIds.LN && sus.TipoDeVenta != TipoVentaUpselling.Corporativa && sus.NombreRegimenDeFacturacion != 'Anual') {
				if (!funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.MejoraTuPlan)) {
					const esSoloSuscriptorDigital = esSuscriptorDigital(sus.IdFamiliaDeProducto); //es accesoDigital
					const cantidadTiempoSuscripto = Date.validatePeriodByMonth(sus.FechaDesde, Number(REACT_APP_UPSELL_ADC_MONTHS));
					const subscripcionLN = suscripcionesDA?.find(element => orderGA.includes(element.nombre))
					const upsellingAccesoDigital = esSoloSuscriptorDigital && cantidadTiempoSuscripto; //solo ad + 6 meses ok
					const upsellingGABeginIndex = orderGA.findIndex(suscripcion => suscripcion == subscripcionLN?.nombre);

					const planesUpsellingIds = upsellingAccesoDigital ? [planAdClubId] : (esSoloSuscriptorDigital && !cantidadTiempoSuscripto)
						? [] : orderGA.slice(upsellingGABeginIndex + 1, orderGA.length) //planesIds a los que puede hacer upselling

					const idsUpselling = [];
					arrayCampanias?.filter(combo => {
						if (planesUpsellingIds.includes(combo.comboType)) {
							idsUpselling.push(combo.id);
						}
					})
					if (subscripcionLN?.nombre != planFamiliar) { //valido si ya tiene plan familiar
						if ((esSoloSuscriptorDigital && !cantidadTiempoSuscripto) || upsellingAccesoDigital) {
							return !links.length ? null : links;
						} else {
							links.push(
								{
									name: upsellingAccesoDigital ? 'Actualizar a Acceso Digital + Club LA NACION' : 'Mejora tu plan',
									onClick: () => historyRouter.push({
										pathname: `${PathsConfig.UpsellingMejoraTuPlan}`,
										state: {
											tieneClub: !esSoloSuscriptorDigital,
											planes: upsellingAccesoDigital ? planesUpsellingIds : idsUpselling,
											campania: upsellingAccesoDigital ? campaniaClub : campaniaNoClub,
											planActual: { nombre: sus.NombreAmigable, id: sus.IdPlanDeVenta }
										}
									})
								}
							)
						}
					}
				}
			}
		};

		if (!links.length) {
			return null;
		} else {
			return links;
		}
	};


	return [planesHook, suscripcionesHook, suscriptionLinks, esApta, setEsApta, isLoading, error, dataPin, errorPin, evento];
};

export default useGetPlanesYsuscripciones;
