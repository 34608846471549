import React, { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';

const useObtenerNumeroDeGestionPago = (idRechazo) => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [NumeroUnicoDeGestion, setNumeroUnicoDeGestion] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const payload ={
                idRechazo: idRechazo,
            }
            ApiService.post(`${REACT_APP_APIRECLAMOS}cobranzas/ObtenerNumeroUnicoDeGestion`, payload)
                .then((data) => {
                    if (data && data.Code === 0) {
                        setNumeroUnicoDeGestion(data.Response.NumeroUnicoDeGestion);
                    } else {
                        setError(data.Message);
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        };
        fetchData();
    }, []);

    return [NumeroUnicoDeGestion, error, isLoading];
};

export default useObtenerNumeroDeGestionPago;