import { useEffect, useState } from "react";
import ApiService from "../Services/ApiService";

const useObtenerDomicilios = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [domicilios, setData] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        ApiService.post(`${REACT_APP_APIPAYWALL}Domicilio/ObtenerDomicilios`)
            .then(data => {
                if (data && data.code === "0000") {
                    setData(data.response.Domicilios)
                    setIsLoading(false)
                }
                else {
                    setError(data.message)
                    setIsLoading(false)
                }
            })
            .catch(error => {
                setError(error)
                setIsLoading(false)
            });
    };

    return [domicilios, error, isLoading, fetchData];
}

export default useObtenerDomicilios;