import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	/**
	 * Tipo de tarjeta.
	 *  1. Visa.
	 *  2. Master.
	 *  3. American.
	 * No utilizamos oneOf
	 * debido a que puede venir
	 * en cualquier case.
	 */
	cardType: PropTypes.string,
};

const MiniCards = ({cardType}) => {
	let _cardType = cardType && cardType.toLowerCase();

	//default card
	const renderDefaultCardType = (
		<svg
			width={36}
			height={24}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 58 38">
			<path
				d="M0 4a4 4 0 014-4h50a4 4 0 014 4v30a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
				fill="url(#paint0_linear)"
			/>
			<rect x="37" y="9" width="14" height="7" rx="3.5" fill="#0074C4" />
			<path
				d="M8 26a1 1 0 100 2h6a1 1 0 100-2H8zM20 26a1 1 0 100 2h6a1 1 0 100-2h-6zM31 27a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM44 26a1 1 0 100 2h6a1 1 0 100-2h-6z"
				fill="#919191"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="0"
					y1="38"
					x2="58"
					y2="0"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#EAEAEA" />
					<stop offset="1" stopColor="#fff" />
				</linearGradient>
			</defs>
		</svg>
	);

	//types cards
	const renderCardType = {
		//Club cards
		classic: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={36}
				height={24}
				viewBox="0 0 36 24"
				fill="none">
				<path
					d="M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
					fill="#008DFF"
				/>
				<path
					opacity={0.4}
					d="M11.05 19.95c3.516 0 6.388-2.42 6.388-5.396 0-2.977-2.85-5.396-6.367-5.396H0v10.791h11.05z"
					fill="url(#prefix__paint0_linear)"
				/>
				<path
					d="M3.855 6.946V4.755h3.85c.77 0 1.394.62 1.394 1.385V8.33H5.25a1.39 1.39 0 01-1.394-1.385zm-.208-2.419v2.419c0 .889.729 1.592 1.623 1.613h4.058v-2.42c0-.888-.728-1.591-1.602-1.612H3.647z"
					fill="url(#prefix__paint1_linear)"
				/>
				<path
					d="M31.664 21.08h1.456c.437 0 .77-.352.77-.765v-1.447l-2.226 2.212z"
					fill="#008DFF"
				/>
				<path
					d="M32.434 20.356c.041-.041.083-.062.104-.103.02-.041.041-.041.062-.062.02-.02 0 0 0 0l.042-.041.062-.063.021-.02c.02-.02 0 0 0 0l.042-.041.02-.021.021-.02.021-.021.02-.021.167.165c.084.062.146.145.23.228l.02.02h.042v-.041l-.125-.104a36.36 36.36 0 01-.063-.061l-.041-.042-.063-.062-.02-.02c-.021-.021 0 0 0 0-.021 0-.021-.021-.042-.042l-.02-.02-.022-.021-.02-.02-.021-.021.041-.042c.021-.041.063-.062.084-.083l.02-.02.021-.02c.02-.022.02-.022.042-.042l.041-.042s.021-.02 0-.02l.084-.083.041-.041.021-.021v-.041h-.02c-.022.02-.042.041-.063.062l-.208.206-.084.083-.02.02-.042.042-.042-.041c-.02-.042-.062-.062-.083-.083l-.02-.041-.021-.02-.042-.042-.042-.042c-.02-.02-.02-.02-.02 0l-.084-.082-.041-.042-.021-.02v.041l.062.062c.063.062.125.145.208.207l.084.082.02.021.042.041-.166.166-.23.186-.02.02v.042l.02.041zm.645-.682l.041-.041.021-.021.021-.02.042-.042-.167.165.042-.04zm-.5.02c-.02-.02-.02-.04-.041-.04l-.021-.021-.02-.021-.042-.041.166.165-.042-.041zM3.647 4.383L3.564 4.3l-.042-.041-.062-.062-.02-.021s-.022-.02-.042-.041l-.021-.021-.021-.02s-.02 0-.02-.021l-.022-.021.208-.207.25-.248-.042-.02-.104.103-.083.082c-.041.042 0 0 0 0l-.041.042-.063.062s-.02 0-.02.02c0 .021 0 0 0 0l-.042.042s0 .02-.021.02l-.02.021s0 .02-.022.02l-.041-.04-.104-.104c0-.02-.021-.02-.021-.02l-.021-.021-.042-.042-.041-.04h-.021l-.083-.084-.063-.062-.041.042.041.041c.084.083.146.165.23.228l.082.082.021.02.042.042h.02l-.02.02-.104.104-.021.02-.02.022-.042.04-.042.042s-.02.021 0 .021l-.083.083-.042.041.042.041.041-.041c.084-.083.167-.145.23-.227l.082-.083.021-.02.042-.042.187.186.25.248.042-.041-.105-.104-.083.042zm-.062-.62l.02-.021s-.02 0-.02.02zm-.604 0L2.94 3.72l-.021-.02-.02-.021-.043-.042.188.186-.063-.062zm.042.578l-.042.042h-.02l-.021.02-.021.021-.042.041.188-.186c-.042.042-.042.042-.042.062zm.541.021l-.02-.02.02.02zM4.777 10.108c.084-.103.084-.248-.02-.33-.105-.083-.25-.083-.333.02-.084.103-.084.248.02.33.104.083.25.063.333-.02z"
					fill="#fff"
				/>
				<path
					d="M5.229 4.982c0 .641-.52 1.158-1.186 1.158a1.164 1.164 0 01-1.166-1.178c0-.641.52-1.158 1.166-1.158.645 0 1.165.517 1.186 1.178zM3.231 9.835a.584.584 0 01-.583-.6.65.65 0 01.146-.393.594.594 0 011.04.372c.021.331-.228.6-.561.62-.021 0-.042.021-.042 0zm0-1.055c-.25 0-.479.207-.479.455a.44.44 0 00.167.352.474.474 0 00.333.124.483.483 0 00.458-.497.492.492 0 00-.479-.434z"
					fill="#FCB73C"
				/>
				<path
					d="M29.252 18.19H12.666a.943.943 0 01-.932-.932c0-.506.426-.932.932-.932h16.586c.506 0 .932.426.932.932a.943.943 0 01-.932.932zM18.816 14.063h-6.203a.943.943 0 01-.932-.931c0-.506.426-.932.932-.932h6.203c.506 0 .932.426.932.932 0 .505-.4.931-.932.931z"
					fill="#fff"
				/>
				<path
					d="M8.194 20a.931.931 0 01-.852-.532c-.054-.134-.133-.267-.187-.4-.559-1.33-.825-2.769-.825-4.233a10.797 10.797 0 013.168-7.64c.985-1.012 2.157-1.784 3.434-2.343A10.896 10.896 0 0117.14 4c1.464 0 2.875.293 4.233.852 1.304.559 2.449 1.331 3.434 2.316a10.713 10.713 0 012.316 3.434c.24.533.4 1.092.56 1.65a.906.906 0 01-.693 1.119.906.906 0 01-1.118-.692 9.324 9.324 0 00-.453-1.385 8.954 8.954 0 00-4.765-4.765c-1.118-.48-2.29-.692-3.488-.692s-2.396.24-3.487.692a8.954 8.954 0 00-4.766 4.765c-.479 1.172-.718 2.343-.718 3.541s.24 2.396.692 3.488c.053.106.106.213.133.32a.951.951 0 01-.453 1.25.611.611 0 01-.372.107z"
					fill="#fff"
				/>
				<defs>
					<linearGradient
						id="prefix__paint0_linear"
						x1={8.719}
						y1={9.158}
						x2={8.719}
						y2={18.744}
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#008DFF" />
						<stop offset={1} stopColor="#008DFF" stopOpacity={0} />
					</linearGradient>
					<linearGradient
						id="prefix__paint1_linear"
						x1={6.488}
						y1={4.548}
						x2={6.488}
						y2={8.321}
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#008DFF" />
						<stop offset={1} stopColor="#008DFF" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		),
		premium: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={36}
				height={24}
				viewBox="0 0 36 24"
				fill="none">
				<path
					d="M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
					fill="#0003A6"
				/>
				<path
					opacity={0.4}
					d="M11.155 20.14c3.55 0 6.45-2.443 6.45-5.448 0-3.005-2.879-5.447-6.429-5.447H0V20.14h11.155z"
					fill="url(#prefix__paint0_linear)"
				/>
				<path
					d="M3.892 7.012V4.8h3.886c.778 0 1.408.626 1.408 1.398V8.41H5.3a1.403 1.403 0 01-1.408-1.398zm-.21-2.442v2.442c0 .898.735 1.607 1.639 1.628h4.096V6.198c0-.897-.735-1.607-1.618-1.628H3.682z"
					fill="url(#prefix__paint1_linear)"
				/>
				<path
					d="M31.965 21.28h1.47a.773.773 0 00.778-.772v-1.46l-2.248 2.233z"
					fill="#008DFF"
				/>
				<path
					d="M32.742 20.55c.043-.042.085-.063.106-.104.02-.042.042-.042.063-.063.02-.02 0 0 0 0l.042-.042.063-.062.02-.021c.022-.02 0 0 0 0l.043-.042.02-.02.022-.022.02-.02.022-.021.168.167c.084.062.147.146.23.23l.022.02h.042v-.042l-.126-.104-.063-.063-.042-.041-.063-.063-.021-.02c-.021-.022 0 0 0 0-.021 0-.021-.022-.042-.043l-.021-.02-.021-.021-.021-.021-.021-.021.042-.042c.02-.041.063-.062.084-.083l.02-.021.022-.021.042-.042.042-.042s.02-.02 0-.02l.084-.084.042-.042.02-.02v-.042h-.02l-.063.062-.21.21-.084.083-.021.02-.042.042-.042-.041c-.021-.042-.063-.063-.084-.084l-.021-.042-.021-.02-.042-.042-.042-.042c-.021-.02-.021-.02-.021 0l-.084-.084-.042-.041-.022-.021V19.674l.064.062c.063.063.126.146.21.209l.084.083.02.021.043.042-.168.167-.231.188-.022.02v.042l.022.042zm.652-.689l.042-.041.02-.021.022-.021.042-.042-.168.167.042-.042zm-.504.021c-.021-.02-.021-.042-.042-.042l-.021-.02-.021-.021-.042-.042.168.167-.042-.042zM3.682 4.424l-.084-.083-.042-.042-.063-.062-.021-.021-.042-.042-.021-.02-.021-.022s-.021 0-.021-.02l-.021-.022.21-.208.252-.25-.042-.022-.105.105-.084.083c-.042.042 0 0 0 0l-.042.042-.063.063s-.021 0-.021.02c0 .021 0 0 0 0l-.042.042s0 .021-.021.021l-.021.021s0 .02-.021.02l-.042-.04-.105-.105c0-.021-.021-.021-.021-.021l-.021-.021-.042-.042-.042-.041h-.021l-.084-.084-.063-.063-.042.042.042.042c.084.083.147.167.23.23l.085.083.02.02.043.043h.02l-.02.02-.105.105-.021.02-.021.022-.042.041-.042.042s-.021.02 0 .02c.02 0 0 0 0 0l-.084.084-.042.042.042.042.042-.042c.084-.083.168-.146.23-.23l.085-.083.02-.02.043-.043.189.188.252.25.042-.041-.105-.104-.084.041zm-.063-.626l.02-.02c.001 0-.02 0-.02.02zm-.61 0l-.041-.041-.021-.021-.021-.021-.042-.042.189.188-.063-.063zm.043.585l-.042.041h-.021l-.021.021-.021.021-.042.042.189-.188c-.042.042-.042.042-.042.063zm.546.02l-.021-.02.02.02zM4.823 10.204c.084-.104.084-.25-.021-.333-.105-.084-.252-.084-.336.02-.084.105-.084.25.02.334a.256.256 0 00.337-.02z"
					fill="#fff"
				/>
				<path
					d="M5.279 5.03c0 .646-.526 1.168-1.198 1.168a1.175 1.175 0 01-1.176-1.19A1.17 1.17 0 014.08 3.84c.651 0 1.176.522 1.197 1.19zM3.262 9.928a.59.59 0 01-.588-.605c0-.146.063-.292.147-.396a.6.6 0 011.05.375.59.59 0 01-.567.626c-.021 0-.042.021-.042 0zm0-1.064c-.252 0-.483.209-.483.46 0 .145.063.27.168.354.084.083.21.125.336.125a.487.487 0 00.462-.5.497.497 0 00-.483-.439z"
					fill="#FCB73C"
				/>
				<path
					d="M28.912 18.19H12.326a.943.943 0 01-.932-.932c0-.506.426-.932.932-.932h16.586c.506 0 .932.426.932.932a.943.943 0 01-.932.932zM18.476 14.063h-6.203a.943.943 0 01-.932-.931c0-.506.426-.932.932-.932h6.203c.506 0 .932.426.932.932 0 .505-.4.931-.932.931z"
					fill="#fff"
				/>
				<path
					d="M7.854 20a.931.931 0 01-.852-.532c-.054-.134-.133-.267-.187-.4-.559-1.33-.825-2.769-.825-4.233a10.797 10.797 0 013.168-7.64c.985-1.012 2.157-1.784 3.434-2.343A10.895 10.895 0 0116.8 4c1.464 0 2.875.293 4.233.852 1.304.559 2.449 1.331 3.434 2.316a10.715 10.715 0 012.316 3.434c.24.533.4 1.092.56 1.65a.906.906 0 01-.693 1.119.906.906 0 01-1.118-.692 9.324 9.324 0 00-.453-1.385 8.952 8.952 0 00-4.765-4.765c-1.118-.48-2.29-.692-3.488-.692s-2.396.24-3.487.692a8.954 8.954 0 00-4.766 4.765c-.479 1.172-.718 2.343-.718 3.541s.24 2.396.692 3.488c.053.106.106.213.133.32a.951.951 0 01-.453 1.25.611.611 0 01-.372.107z"
					fill="#fff"
				/>
				<defs>
					<linearGradient
						id="prefix__paint0_linear"
						x1={8.802}
						y1={9.245}
						x2={8.802}
						y2={18.922}
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#008DFF" />
						<stop offset={1} stopColor="#008DFF" stopOpacity={0} />
					</linearGradient>
					<linearGradient
						id="prefix__paint1_linear"
						x1={6.549}
						y1={4.591}
						x2={6.549}
						y2={8.4}
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#008DFF" />
						<stop offset={1} stopColor="#008DFF" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		),
		black: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={36}
				height={24}
				viewBox="0 0 36 24"
				fill="none">
				<path
					d="M0 4a4 4 0 014-4h28a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
					fill="#00043A"
				/>
				<path
					d="M10.088 4.121H3.114v2.045l.441-.042V4.56H12.714v3.465h.441V4.12h-3.067z"
					fill="url(#prefix__paint0_linear)"
				/>
				<path
					d="M12.706 7.637H0v6.52c0 1.916 1.775 3.479 3.951 3.479h12.683v-6.562c-.024-1.916-1.775-3.437-3.928-3.437z"
					fill="url(#prefix__paint1_linear)"
				/>
				<path
					d="M4.668 6.011H3.177a.795.795 0 00-.798.793v1.482l2.29-2.275z"
					fill="#C6480C"
				/>
				<path
					d="M31.826 17.05h-2.542v2.526c0 .75.61 1.335 1.345 1.335h2.542v-2.525c0-.73-.61-1.336-1.345-1.336z"
					fill="#353763"
				/>
				<path
					d="M33.948 20.285a1.3 1.3 0 01-1.281 1.294c-.694 0-1.303-.584-1.303-1.273a1.3 1.3 0 011.282-1.294 1.274 1.274 0 011.302 1.273z"
					fill="#C6480C"
				/>
				<path
					d="M31.238 18.991l-.042.042.252.25.19.188-.043.042-.02.02-.085.084c-.084.084-.147.167-.23.23l-.043.042.042.041.042-.041.084-.084v-.02c0-.021 0 0 .021 0l.042-.042.042-.042v-.021l.021-.02.021-.043.105-.104.042-.02.042.04.021.022.084.083s0 .021 0 0l.231.23.042.042.042-.042-.042-.042c-.02-.02-.042-.063-.084-.083l-.02-.021-.043-.042-.042-.042-.02-.02-.043-.021c-.02-.042-.063-.063-.105-.105l-.042-.041.021-.021.021-.021.021-.021c0-.021.021-.021.021-.021l.042-.042.021-.02.063-.063.042-.042c.021-.02.042-.063.084-.084l.105-.104-.042-.041-.252.25-.21.209-.02-.021-.022-.021-.02-.021-.022-.02-.042-.043-.02-.02-.064-.063c-.02-.021 0 0 0 0l-.042-.042s-.063-.042-.084-.083l-.105-.105h.042zm.672.793l.19.188-.043-.042-.02-.02-.022-.021c0-.021-.042-.021-.042-.042 0-.02 0 0 0 0l-.063-.063zm-.483-.083l-.189.188.042-.042.021-.02.021-.022.042-.041.063-.063zM29.572 18.19H12.986a.943.943 0 01-.932-.932c0-.506.426-.932.932-.932h16.586c.506 0 .932.426.932.932a.943.943 0 01-.932.932zM19.136 14.063h-6.203a.943.943 0 01-.932-.931c0-.506.426-.932.932-.932h6.203c.506 0 .932.426.932.932 0 .505-.4.931-.932.931z"
					fill="#fff"
				/>
				<path
					d="M8.514 20a.931.931 0 01-.852-.532c-.054-.134-.133-.267-.187-.4-.559-1.33-.825-2.769-.825-4.233a10.797 10.797 0 013.168-7.64c.985-1.012 2.157-1.784 3.434-2.343A10.895 10.895 0 0117.46 4c1.464 0 2.875.293 4.233.852 1.304.559 2.449 1.331 3.434 2.316a10.713 10.713 0 012.316 3.434c.24.533.4 1.092.56 1.65a.906.906 0 01-.693 1.119.906.906 0 01-1.118-.692 9.324 9.324 0 00-.453-1.385 8.954 8.954 0 00-4.765-4.765c-1.118-.48-2.29-.692-3.488-.692s-2.396.24-3.487.692a8.954 8.954 0 00-4.766 4.765c-.479 1.172-.718 2.343-.718 3.541s.24 2.396.692 3.488c.053.106.106.213.133.32a.951.951 0 01-.453 1.25.611.611 0 01-.372.107z"
					fill="#fff"
				/>
				<defs>
					<linearGradient
						id="prefix__paint0_linear"
						x1={8.135}
						y1={5.095}
						x2={8.135}
						y2={8.024}
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#242656" />
						<stop offset={1} stopColor="#242656" stopOpacity={0} />
					</linearGradient>
					<linearGradient
						id="prefix__paint1_linear"
						x1={8.317}
						y1={9.034}
						x2={8.317}
						y2={17.636}
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#242656" />
						<stop offset={1} stopColor="#242656" stopOpacity={0} />
					</linearGradient>
				</defs>
			</svg>
		),

		//Credit cards
		visa: (
			<svg
				width="58"
				height="38"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 58 38">
				<path
					d="M0 4a4 4 0 014-4h50a4 4 0 014 4v30a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
					fill="url(#paint2_linear)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M37.197 20.962c.007-1.885-1.548-2.7-2.754-3.331-.764-.4-1.388-.727-1.388-1.207 0-.407.396-.84 1.236-.951 1-.1 2.01.076 2.918.506l.52-2.472a7.962 7.962 0 00-2.77-.507c-2.918 0-4.945 1.558-4.945 3.783 0 1.644 1.47 2.559 2.584 3.103 1.112.544 1.545.927 1.533 1.422 0 .766-.915 1.112-1.768 1.125a6.182 6.182 0 01-3.017-.742l-.531 2.473a8.853 8.853 0 003.288.605c3.103 0 5.143-1.533 5.155-3.906l-.062.099zm-8.024-7.764l-2.472 11.436h-2.968l2.473-11.436h2.967zm12.437 7.418l1.558-4.29.902 4.29h-2.46zm6.045 4.055h-2.744l-.359-1.706h-3.72l-.607 1.706H37.11l4.438-10.595a1.348 1.348 0 011.236-.84h2.473l2.398 11.435zm-27.445-.075l4.797-11.435h-3.214l-3.079 7.776-1.236-6.614a1.385 1.385 0 00-1.372-1.162h-5.032l-.074.333c1.01.198 1.99.522 2.918.965.395.186.663.566.704 1.001l2.361 9.136h3.227z"
					fill="#fff"
				/>
				<defs>
					<linearGradient
						id="paint2_linear"
						x1="0"
						y1="38"
						x2="58"
						y2="0"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#2A2A6C" />
						<stop offset="1" stopColor="#26449A" />
					</linearGradient>
				</defs>
			</svg>
		),
		master: (
			<svg
				width="58"
				height="38"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 58 38">
				<path
					d="M0 4a4 4 0 014-4h50a4 4 0 014 4v30a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
					fill="url(#paint3_linear)"
				/>
				<path
					d="M24.25 10.46h10.21v16.68H24.25V10.46z"
					fill="#F26122"
				/>
				<path
					d="M25.35 18.8a10.58 10.58 0 014-8.34 10.61 10.61 0 100 16.68 10.58 10.58 0 01-4-8.34z"
					fill="#EA1D25"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M45.49 25.03v.34h-.06v-.34h-.14v-.07h.35v.07h-.15zm.68-.07v.41h-.07v-.31l-.11.27h-.08l-.12-.27v.31h-.07v-.41h.1l.13.29.12-.29h.1z"
					fill="#F69E1E"
				/>
				<path
					d="M46.51 18.8a10.61 10.61 0 01-17.16 8.34c4.6-3.623 5.397-10.286 1.78-14.89a10.4 10.4 0 00-1.78-1.79 10.61 10.61 0 0117.16 8.34z"
					fill="#F69E1E"
				/>
				<defs>
					<linearGradient
						id="paint3_linear"
						x1="0"
						y1="38"
						x2="58"
						y2="0"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#1C1C1F" />
						<stop offset="1" stopColor="#4C4C55" />
					</linearGradient>
				</defs>
			</svg>
		),
		american: (
			<svg
				width="58"
				height="38"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 58 38">
				<g clipPath="url(#clip0)">
					<path
						d="M0 4a4 4 0 014-4h50a4 4 0 014 4v30a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
						fill="url(#paint9_linear)"
					/>
					<path
						d="M10.73 21.256v9.69h9.98l1.04-1.449 1.015 1.449h6.102v-2.185h.677a6.057 6.057 0 001.812-.261v2.446h4.145v-1.734l1.402 1.734h18.874a4.1 4.1 0 002.199-.594V38H0V18.287h2.755l.568-1.448h1.353l.556 1.448h22.16v-1.733l1.403 1.733h5.812v-.878a4.893 4.893 0 002.924.878h3.468l.556-1.448h1.353l.592 1.448h5.92v-1.876l1.21 1.876h4.083v-9.69h-4.338v1.972l-1.292-1.972h-3.795v2.494L44.08 8.597h-6.453a4.743 4.743 0 00-3.02.974v-.974H13.292l-.725 2.09-.725-2.137H7.008v2.494L5.873 8.55h-3.94L0 13.062V4 0h58v21.256H22.79l-1.04 1.425-1.015-1.425H10.73z"
						fill="url(#paint10_linear)"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M25.559 22.655h3.728v-.048a2.17 2.17 0 012.35 2.254 2.23 2.23 0 01-2.398 2.278h-2.038v2.23h-1.642v-6.714zm-7.925 1.392v-1.439h-5.43v6.761h5.43v-1.438h-3.8v-1.199h3.729v-1.463h-3.729v-1.198l3.8-.024zm4.904 1.966l2.709-3.357h-1.99l-1.678 2.086-1.667-2.134h-2.038l2.745 3.405-2.637 3.356h1.966l1.63-2.157 1.631 2.181h2.062l-2.733-3.38zm6.605-.265h-1.942v-1.702h1.942a.827.827 0 010 1.654v.048zm9.423-1.187a2.149 2.149 0 01-1.882 2.386l2.265 2.422H36.9l-2.014-2.398h-.743v2.398h-1.63v-6.69h3.668a2.149 2.149 0 012.386 1.882zm-2.53 1.043h-1.894v-1.558h1.894a.743.743 0 01.84.743.731.731 0 01-.84.743v.072zm8.752-2.925v1.439l-3.8-.048v1.199h3.704v1.462h-3.705v1.199h3.8v1.439h-5.454v-6.69h5.455zm4.52 2.638h-1.571a.611.611 0 110-1.2h2.913l.647-1.438h-3.512a2.086 2.086 0 00-2.326 2.11 1.882 1.882 0 002.11 1.99h1.582a.611.611 0 110 1.199h-3.5v1.438h3.5a2.098 2.098 0 002.266-2.205 1.858 1.858 0 00-2.11-1.942v.048zm7.587-1.199h-2.913l-.036-.047a.611.611 0 100 1.198h1.63c.83-.085 1.621.369 1.967 1.127v1.607a2.122 2.122 0 01-2.206 1.39h-3.44v-1.438h3.464a.611.611 0 100-1.2h-1.546a1.882 1.882 0 01-2.11-1.99 2.086 2.086 0 012.325-2.11h3.513l-.648 1.463z"
						fill="url(#paint20_linear)"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M30.51 11.963a2.098 2.098 0 01-1.847 2.133l2.254 2.494H28.88l-2.014-2.398h-.743v2.398h-1.666V9.829h3.656a2.122 2.122 0 012.398 2.133zM5.07 9.829H2.937L0 16.589h1.774l.624-1.366H5.55l.576 1.415h1.846l-2.901-6.81zm-2.218 4.027l.66-1.582.431-1.055.432 1.055.647 1.582h-2.17zm9.854-.12l1.32-3.907h2.624v6.76H15.07v-5.465l-.312 1.03-1.57 4.436h-1.439l-1.534-4.436-.336-1.03v5.466H8.284V9.829h2.65l1.342 3.908.216.743.215-.743zm10.67-2.469V9.83h-5.395v6.76h5.395v-1.438h-3.8v-1.198h3.704V12.49h-3.704V11.29l3.8-.024zm4.64 1.486h-1.895v-1.486h1.894a.756.756 0 01.84.743.731.731 0 01-.84.743zM31.3 16.59V9.829h1.642v6.76h-1.642zm5.994-5.3h1.762V9.829h-1.894a3.057 3.057 0 00-3.237 3.333v.096a2.997 2.997 0 003.141 3.333h.096l.66-1.439h-.54a1.678 1.678 0 01-1.75-1.894v-.096a1.678 1.678 0 011.75-1.87h.012zm3.572-1.461h2.158l2.89 6.809h-1.847l-.575-1.415h-3.201l-.575 1.367h-1.75l2.9-6.761zm-.047 4.027l.623-1.582.431-1.055.408 1.055.647 1.582h-2.11zm10.261-.431V9.829h1.667v6.76H50.77l-2.506-3.811-.431-.744v4.604h-1.607V9.877h2.002l2.398 3.596.455.767v-.815z"
						fill="url(#paint30_linear)"
					/>
				</g>
				<defs>
					<linearGradient
						id="paint9_linear"
						x1="0"
						y1="38"
						x2="58"
						y2="0"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#fff" />
						<stop offset="1" stopColor="#fff" />
					</linearGradient>
					<linearGradient
						id="paint10_linear"
						x1="0"
						y1="38"
						x2="58"
						y2="0"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#296DB6" />
						<stop offset="1" stopColor="#1F8CCC" />
					</linearGradient>
					<linearGradient
						id="paint20_linear"
						x1="12.204"
						y1="29.424"
						x2="19.298"
						y2="9.19"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#296DB6" />
						<stop offset="1" stopColor="#1F8CCC" />
					</linearGradient>
					<linearGradient
						id="paint30_linear"
						x1="0"
						y1="16.638"
						x2="6.283"
						y2="-4.2"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#296DB6" />
						<stop offset="1" stopColor="#1F8CCC" />
					</linearGradient>
					<clipPath id="clip0">
						<path
							d="M0 4a4 4 0 014-4h50a4 4 0 014 4v30a4 4 0 01-4 4H4a4 4 0 01-4-4V4z"
							fill="#fff"
						/>
					</clipPath>
				</defs>
			</svg>
		),
	};

	return renderCardType[_cardType] || renderDefaultCardType;
};

MiniCards.propTypes = propTypes;

export default MiniCards;
