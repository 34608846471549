import React, { useEffect } from 'react';
import { Button } from '@ln/experiencias-ui-button';
import { Icon } from '@ln/common-ui-icon';
import { CloseIcon } from '@ln/experiencias-ui-assets';
import ModalPortalBG from '../Common/Atoms/ModalPortalBG';
import ArrowRightLineIcon from '../../Statics/Imgs/arrowRightLineIcon';
import OnClickOutElement from '../../Helpers/OnClickOutElement';
import { CustomEventsDatadog } from '../../Config/Datadog';
import Analytics from '../../Helpers/Analytics';
import Datadog from '../../Helpers/Datadog';

import '../../Statics/Scss/Modules/PromoNoSuscriptores.scss';


export const PromoNoSuscriptores = ({ closeModal }) => {
    useEffect(() => {
        Datadog.trackCustomEvent(CustomEventsDatadog.displayModalPromoNoSuscriptor);
    }, []);

    const handleClick = () => {
        Analytics.trackClickModalPromoNoSuscriptor('my_account', 'click_suscribirme_modal', 'suscribirme');
        window.location.href = `${process.env.REACT_APP_CHECKOUT}C/746/?skn=LC&cv=670&fc=771&productCategory=Voluntario&ref=aHR0cHM6Ly9zdXNjcmlwY2lvbmVzLmxhbmFjaW9uLmNvbS5hci9zdXNjcmliaXJtZT9jdj02NzAmZmM9NzcxJmNhbGxiYWNrPWFIUjBjSE02THk5dGVXRmpZMjkxYm5RdWJHRnVZV05wYjI0dVkyOXRMbUZ5THc9PQ==&callback=${window.btoa(location.href)}`;
    };

    const handleClose = () => {
        Analytics.trackClickModalPromoNoSuscriptor('my_account', 'click_suscribirme_modal', 'cerrar');
        closeModal();
    };
    return (
        <ModalPortalBG>
            <div className="contenedor">
                <div className="modal-no-suscriptores" ref={OnClickOutElement(handleClose)}>
                    <div className="close-button-container ml-auto">
                        <Button onClick={handleClose} variant="link" title="Cerrar">
                            <Icon size={20} color="light">
                                <CloseIcon />
                            </Icon>
                        </Button>
                    </div>
                    <div className="content-container flex flex-column ai-center gap-24">
                        <div className="ilustracion" />
                        <Button size={32} onClick={handleClick} variant="default" title="DESBLOQUEAR PROMO EXCLUSIVA">
                            DESBLOQUEAR PROMO EXCLUSIVA
                            <Icon size={16}>
                                <ArrowRightLineIcon />
                            </Icon>
                        </Button>
                    </div>
                </div>
            </div>
        </ModalPortalBG>
    );
};

export default PromoNoSuscriptores;
