import React from 'react';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Toast from '../../../Components/Common/Modules/Toast';
import Button from '../../../Components/Common/Modules/Button';
import Link from '../../../Components/Common/Atoms/Link';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import Divider from '../../../Components/Common/Atoms/Divider';
import { useHistory } from 'react-router';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';
import ContactoDudasBanner from '../../../Components/Common/Banners/ContactoDudas';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import { mailAtencionSocios } from '../../../Config/DatosDeContacto';

const ContactoReclamo = (props) => {
    const history = useHistory();
    const stepProps = ['success'];
    const { success } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoEntrega);

    return (
        <div>
            <div className="lay-strechted">
                <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                <TitleWeb text="Nuevo reclamo de entrega" />
            </div>

            <div className="container-streched">
                {success ? (
                    <Toast status="success" title="¡Está todo en orden!">
                        Le informamos que lo recibido se encuentra dentro de lo estipulado para la suscripción.
                    </Toast>
                ) : (
                    <Toast status="alert" title="No pudimos procesar tu reclamo">
                        Debido a que no estaba el producto en el listado. Por favor, comunicate con nosotros a{' '}
                        <Link href={`mailto: ${mailAtencionSocios}`}>{mailAtencionSocios}</Link> para gestionarlo y dar solución al mismo.
                    </Toast>
                )}
                <FormRow mod="--btn-groups --mb-32">
                    <Button
                        onClick={() => history.push({ pathname: PathsConfig.ReclamoEntrega, state: null })}
                        href={'#'}
                        label="REALIZAR OTRO RECLAMO"
                        mod="state--mobile-w100"
                    />
                </FormRow>
            </div>

            <Divider />
            <ContactoDudasBanner />
        </div>
    );
};

export default ContactoReclamo;
