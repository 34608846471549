import React from 'react';
import '../../../Statics/Scss/Components/ProteccionDatos.scss';

const ProteccionDatosPersonales = () => {
    return (
        <p className="com-proteccion-datos --sixxs --arial"><strong>Protección de datos personales</strong><br />
        S.A. La Nación utilizará la información para los siguientes fines: a) identificación y autenticación, b) administración y gestión comercial, c) mejora del servicio, d) fines estadísticos, e) envío de notificaciones, CallbackArticleciones o publicidad, entre otros. Los datos personales no serán difundidos ni empleados para un fin distinto o incompatible al tenido en cuenta al ser ingresados en la base. S.A. La Nación podrá emplear a otras compañías y/o personas físicas para llevar a cabo tareas o funciones en su nombre. Entre los ejemplos de ese tipo podemos mencionar el de enviar correo postal y electrónico, retirar información reiterativa de las listas de usuarios, analizar datos en forma estadística, etc. Dichas personas cuentan con acceso a la información personal necesaria para cumplir con sus tareas y funciones, pero no pueden utilizarla con fines distintos a los estipulados. Las bases de datos de S.A. La Nación se encuentran registradas en la Dirección Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos de la Presidencia de la Nación (República Argentina), en cumplimiento de lo dispuesto en el art. 3 de la Ley 25.326. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a 6 meses, salvo que se acredite un interés legítimo al efecto, conforme lo establecido en el art. 14, inc. 3 de la Ley 25.326. La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de Control de la Ley 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.
        </p>
    )
}

export default ProteccionDatosPersonales
