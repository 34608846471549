import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardBox from '../../../Components/Common/Modules/CardBox';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Title from '../../../Components/Common/Atoms/Title';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Button from '../../../Components/Common/Modules/Button';
import Pagination from '../../../Components/Common/Modules/Pagination';
import Label from '../../../Components/Common/Atoms/Form/Label';
import Link from '../../../Components/Common/Atoms/Link';
import TitleWeb from '../../../Components/Common/Web/Title';
import DateHelper from '../../../Helpers/Date';
import StepsPagosPendientes from '../../../Config/StepsPagosPendientes';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import PathsConfig from '../../../Config/RoutePaths';
import useCrearAbandonoCobranza from '../../../Hooks/useCrearAbandonoCobranza';
import { getPendientes } from '../../../Components/Features/PagosPendientes/getDeudasPendientes';
import { trackCustomEvent } from '../../../Helpers/Datadog';
import stepsTrackeoPagosPendientes from '../../../Config/StepsTrackeoPagosPendientes';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import GetCardType from '../../../Helpers/GetCardType';

const SeleccionarDeuda = (props) => {
    const history = useHistory();
    const stepProps = ['campanita', 'filtroUsuario', 'rechazos']; //filtro usuario vendra siempre en true a menos que se cambie el filtro en /pagos-pendientes
    const { campanita, filtroUsuario, rechazos } = useRedirectWithout(props.location.state, stepProps, PathsConfig.PagosPendientes);
    const [idAbandonoCobranza, errorAbandonoCobranza, isLoadingAbandonoCobranza] = useCrearAbandonoCobranza();
    const [total, setTotal] = useState(0);
    const [pendientes, setPendientes] = useState();
    const [errorValidacion, setErrorValidacion] = useState(false);

    useEffect(() => {
        setPendientes(getPendientes(rechazos));
    }, [rechazos]);

    useEffect(() => {
        const cacularTotal = () => {
            let sum = pendientes?.filter((p) => p.check).reduce((sum, { Importe }) => sum + +Importe, 0);
            setTotal(sum);
        };
        cacularTotal();
    }, [pendientes]);

    const deseleccionarPendientes = (suscripcionIds, fecha, key) => {
        let obligatorios = pendientes?.find((p) => {
            return p.Suscripcion.some((s) => suscripcionIds.has(s.Id)) && DateHelper.esFechaPosterior(p.Fecha, fecha) && p.check;
        });

        if (!obligatorios) {
            setPendientes(
                pendientes?.map((pend, i) => {
                    return i === key ? { ...pend, check: false } : pend;
                })
            );
        }
    };

    const seleccionObligatoria = (suscripcionIds, fecha, key, value) => {
        let obligatorios = pendientes?.map((p, i) => {
            return p.Suscripcion.some((s) => suscripcionIds.has(s.Id)) && DateHelper.esFechaAnterior(p.Fecha, fecha)
                ? { ...p, check: true }
                : i === key
                    ? { ...p, check: value }
                    : p;
        });
        setPendientes(obligatorios);
    };

    const handleCheck = (key, value, suscripcionIds, fecha) => {
        setErrorValidacion(false);
        if (value) {
            seleccionObligatoria(suscripcionIds, fecha, key, value);
        } else {
            deseleccionarPendientes(suscripcionIds, fecha, key);
        }
    };

    const handleContinuar = () => {
        let selected = pendientes?.find((p) => p.check === true);
        setErrorValidacion(!selected ? true : false);
        if (selected) {
            trackCustomEvent(stepsTrackeoPagosPendientes["CLICK-SELECCION-DEUDA"])
            let deudas = pendientes.filter((p) => p.check);
            history.push({
                pathname: PathsConfig.SeleccionarTarjeta,
                state: {
                    deudas: deudas,
                    idAbandonoCobranza: idAbandonoCobranza,
                    campanita: campanita
                },
            });
        }
    };

    const handleSelectAll = () => {
        setPendientes(
            pendientes.map((p) => {
                return { ...p, check: pendientes.some((p) => p.check === true) ? false : true };
            })
        );
    };

    return (
        <>
            <section>
                <Breadcrumb firstItem="Mis pagos" href="#" text="Pagos pendientes" />

                <TitleWeb text="Pagos pendientes" />
            </section>

            <section className="container-streched">
                {errorAbandonoCobranza ? (
                    <ErrorGenerico />
                ) : (
                    <LoadingSpinner withBody isLoading={isLoadingAbandonoCobranza}>
                        <ErrorBoundary error={errorAbandonoCobranza}>
                            <Wizard steps={StepsPagosPendientes} activeStep={1} />

                            <Title size="--s" mod="--font-bold --arial --mb-24">
                                Seleccioná los pagos pendientes a saldar
                            </Title>

                            <Label
                                text="Mis pagos pendientes"
                                mod="--arial --font-bold"
                                size="--fourxs"
                                link={
                                    <Link onClick={handleSelectAll}>
                                        {pendientes?.some((p) => p.check === true) ? 'Quitar selección' : 'Seleccionar todos'}
                                    </Link>
                                }
                            />
                            <form>
                                <Pagination itemsQty={4} hasCost cost={+total}>
                                    {pendientes &&
                                        pendientes.map((pendiente, i) =>
                                            pendiente.Suscripcion.map((suscripcion) => (
                                                <CardBox
                                                    key={suscripcion.Id}
                                                    error={errorValidacion}
                                                    costo={suscripcion.Importe.toString()}
                                                    estadoTitulo={pendiente.Estado}
                                                    estadoDescripcion={suscripcion.Nombre}
                                                    motivoRechazo={`Motivo Del Rechazo: ${pendiente.MotivoRechazo}. Último intento de cobro: ${pendiente.Fecha}`}
                                                    periodoContenido={`${pendiente.FechaPeriodoDesde} al ${pendiente.FechaPeriodoHasta}`}
                                                    numeroFacturaContenido={pendiente.NroFactura}
                                                    checked={pendiente.check}
                                                    lastNumbers={pendiente.UltimosNumerosTarjeta}
                                                    cardType={GetCardType(pendiente.InstrumentoDeCobro)}
                                                    className="--mb-8"
                                                    onCheck={(e) => handleCheck(i, e.target.checked, new Set(pendiente.Suscripcion.map(sus => sus.Id)), pendiente.Fecha)}
                                                />
                                            ))
                                        )}
                                    {errorValidacion && <p className="--red-500">Seleccioná al menos un pago pendiente a saldar</p>}
                                </Pagination>
                                <FormRow mod="--btn-groups">
                                    <Button mod="state--mobile-w100" onClick={handleContinuar} label="Continuar" />
                                </FormRow>
                            </form>
                        </ErrorBoundary>
                    </LoadingSpinner>
                )}
            </section>
        </>
    );
};

SeleccionarDeuda.propTypes = {
    toastCondition: PropTypes.bool
};

export default SeleccionarDeuda;
