import React from 'react';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import TitleWeb from '../../../Components/Common/Web/Title';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import useObtenerSuscripciones from '../../../Hooks/useObtenerSuscripciones';
import ErrorReclamoAccesoDigital from './ErrorReclamoAccesoDigital';
import ReclamoAccesoDigitalSuscriptor from './ReclamoAccesoDigitalSuscriptor';
import ReclamoSinSuscripciones from './ReclamoSinSuscripciones';
import estadoSuscripciones from '../../../Models/EstadoSuscripciones';
import { useStore } from '../../../Store/storeProvider';
import { ToastFuncionalidadSinServicio } from '../../../Components/Common/Banners/ToastFuncionalidadSinServicio';
import FuncionalidadesConfig from '../../../Config/FuncionalidadesConfig';
import { funcionalidadDeshabilitada } from '../../../Helpers/FuncionalidadDeshabilitada';

const AccesoDigital = () => {
    const { HandleSwitchsStates } = useStore();
    const [suscripciones, error, isLoading] = useObtenerSuscripciones();
    const arrayAccesoDigital = suscripciones?.filter(
        (item) => (item.Estado === estadoSuscripciones.ACTIVA || item.Estado === estadoSuscripciones.BAJA_DIFERIDA) && item.TipoFamilia === 'E'
    );

    return (
        <>
            <Breadcrumb firstItem="Mis reclamos" href="#" text="Acceso Digital" />
            <TitleWeb text="Reclamo Acceso Digital" />
            <div className="container-streched">
                {funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.ReclamoAD) ? (
                    <ToastFuncionalidadSinServicio funcionalidad={FuncionalidadesConfig.ReclamoAD}/>
                ) : (
                    <LoadingSpinner isLoading={isLoading}>
                        {error ? (
                            <ErrorReclamoAccesoDigital />
                        ) : suscripciones?.length === 0 || !suscripciones ? (
                            <ReclamoSinSuscripciones />
                        ) : arrayAccesoDigital?.length > 0 ? (
                            <ReclamoAccesoDigitalSuscriptor
                                suscripciones={arrayAccesoDigital}
                            />
                        ) : (
                            <ReclamoSinSuscripciones />
                        )}
                    </LoadingSpinner>
                )}
            </div>
        </>
    );
};

export default AccesoDigital;
