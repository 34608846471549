import { useEffect, useState } from 'react';
import ApiService from '../Services/ApiService';
import { funcionalidadDeshabilitada } from '../Helpers/FuncionalidadDeshabilitada';
import { useStore } from '../Store/storeProvider';
import Datadog from "../Helpers/Datadog";
import { CustomEventsErrorDatadog } from "../Config/Datadog";

const useObtenerBeneficios = () => {
    const { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [beneficios, setBeneficios] = useState({});
    const [error, setError] = useState(null);
    const { HandleSwitchsStates } = useStore();
    const featureFlag = funcionalidadDeshabilitada(HandleSwitchsStates, HandleSwitchsStates?.MisConsumos)
    useEffect(() => {
        if(HandleSwitchsStates && !featureFlag){
            const fetchData = async () => {
                ApiService.post(`${REACT_APP_APIRECLAMOS}beneficios/GrupoBeneficios`)
                    .then(data => {
                        if (data && data.Code == 0) {
                            setBeneficios(data.Response.BeneficiosViewModel || {});
                            setIsLoading(false);
                        } else {
                            setError(true);
                            setIsLoading(false);
                            Datadog.trackCustomEventWarning( CustomEventsErrorDatadog.errorObtenerBeneficiosParaBaja, { response: data })
                        }
                    })
                    .catch(error => {
                        setError(error);
                        setIsLoading(false);
                        Datadog.trackCustomEventError( CustomEventsErrorDatadog.errorObtenerBeneficiosParaBaja, { error })
                    });
            };
            fetchData();
        }
    }, []);

    return [beneficios, error, isLoading];
};

export default useObtenerBeneficios;
