import { useState } from 'react';
import { useHistory } from 'react-router';
import PathsConfig from '../Config/RoutePaths';
import ApiService from '../Services/ApiService';
import Datadog from '../Helpers/Datadog';
import { CustomEventsDatadog, CustomEventsErrorDatadog } from '../Config/Datadog';
import MotivoReclamo from '../Models/MotivoReclamo';

const useCrearReclamoSuscripcion = () => {
    const history = useHistory();
    let { REACT_APP_APIRECLAMOS } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const outFunc = async (data) => {
        const pathUrl = `${REACT_APP_APIRECLAMOS}reclamos/CrearReclamoDeSuscripcion`;
        setIsLoading(true);

        ApiService.post(pathUrl, data)
            .then((response) => {
                if (response.Code == 0) {
                    if (
                        response.Response.NumeroUnicoDeGestion &&
                        !isNaN(response.Response.ValorDeLaNotaDeCredito)
                    ) {
                        Datadog.trackCustomEvent(
                            CustomEventsDatadog.reclamarEntrega,
                            {
                                motivoReclamo: MotivoReclamo.find(
                                    (motivo) =>
                                        motivo.id == data.IdMotivoDeReclamo
                                ).value,
                                datos: data,
                            }
                        );
                        var params = {
                            pathname: PathsConfig.ReclamoEntregaConfirmacion,
                            state: {
                                numeroUnicoDeGestion:
                                    response.Response.NumeroUnicoDeGestion,
                                valorDeLaNotaDeCredito:
                                    response.Response.ValorDeLaNotaDeCredito,
                                idMotivoDeReclamo: data.IdMotivoDeReclamo,
                            },
                        };
                        history.push(params);
                    }
                } else {
                    Datadog.trackCustomEventWarning(
                        CustomEventsErrorDatadog.errorReclamarEntrega,
                        {
                            motivoReclamo: MotivoReclamo.find(
                                (motivo) =>
                                    motivo.id == data.IdMotivoDeReclamo
                            ).value,
                            codigo: response.Code,
                            error: response.Message,
                            datos: data
                        }
                    );
                    setIsLoading(false);
                    setError(response);
                }
            })
            .catch((error) => {
                Datadog.trackCustomEventWarning(
                    CustomEventsErrorDatadog.errorReclamarEntrega,
                    {
                        motivoReclamo: MotivoReclamo.find(
                            (motivo) =>
                                motivo.id == data.IdMotivoDeReclamo
                        ).value,
                        error: error.message,
                        datos: data
                    }
                );
                setIsLoading(false);
                setError(true);
            });
    };

    return [error, isLoading, outFunc];
};

export default useCrearReclamoSuscripcion;
