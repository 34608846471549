import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Label from '../Common/Atoms/Form/Label'
import Select from '../Common/Atoms/Form/Select'

const propTypes = {
    setMotivoSelected: PropTypes.func.isRequired,
    hasValidationError: PropTypes.object,
    motivos: PropTypes.array.isRequired
}

const Motivos = (props) => {
    let { setMotivoSelected, hasValidationError, motivos, changeLabel } = props;

    const handleChange = (e) => {
        setMotivoSelected(e.target.value);
    }

    return (
        <>
            <Label               
                text={changeLabel ? "" : "Motivo del reclamo"}
            />
            <Select state={hasValidationError} handleChange={handleChange}>
                <option>Seleccioná un motivo</option>
                {motivos.map((option) => {
                    return <option key={option.Id} value={option.Id}>{option.Nombre}</option>
                })}
            </Select>
        </>
    )
}

Motivos.propTypes = propTypes;

export default Motivos
