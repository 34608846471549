import React, { useEffect, useState } from 'react';

import useObtenerDespacho from '../../../Hooks/useObtenerDespacho';
import useObtenerSuscripcionDetalle from '../../../Hooks/useObtenerSuscripcionDetalle';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import RedirectReclamoSegunMotivo from '../../../Helpers/RedirectReclamoSegunMotivo';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import CallbackArticle from '../../../Components/Common/Modules/CallbackArticle';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';
import FormCol from '../../../Components/Common/Atoms/Form/FormCol';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import InputGroup from '../../../Components/Common/Atoms/Form/InputGroup';
import steps from '../../../Config/Steps';
import DatePicker from '../../../Components/Common/Modules/DatePicker';
import RangePicker from '../../../Components/Common/Modules/RangePicker';
import { useHistory } from 'react-router';
import useObtenerSuscripciones from '../../../Hooks/useObtenerSuscripciones';
import Toast from '../../../Components/Common/Modules/Toast';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import SelectorDespachos from '../../../Components/Features/Reclamos/SelectorDespachos';
import ErrorGenerico from '../../../Components/Features/ErrorGenerico';
import { reclamoEntregaParams } from '../../../Helpers/Reclamo';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';

const { REACT_APP_DIAS_INTERVALO_DESPACHOS_DEFAULT } = process.env;

const Ediciones = (props) => {
    const history = useHistory();
    const stepProps = ['suscripcionSelected', 'motivoSelected'];
    const { suscripcionSelected, motivoSelected } = useRedirectWithout(props.location.state, stepProps, PathsConfig.ReclamoEntrega);
    const [detalleSuscripcion, errorProducto, isLoadingProducto] = useObtenerSuscripcionDetalle(suscripcionSelected);
    const [despachos, errorDespacho, isLoadingDespacho, getDespacho] = useObtenerDespacho();
    const [suscripciones, errorSuscripciones, isLoadingSuscripciones] = useObtenerSuscripciones();
    const [redirect] = RedirectReclamoSegunMotivo();
    const [despachosSelected, setDespachosSelected] = useState([]);
    const [invalid, setInvalid] = useState(false);
    const tmpDesde = new Date();
    tmpDesde.setDate(tmpDesde.getDate() - parseInt(REACT_APP_DIAS_INTERVALO_DESPACHOS_DEFAULT));
    const [fechaDesde, setFechaDesde] = useState(tmpDesde.getTime());
    const [fechaHasta, setFechaHasta] = useState(new Date().getTime());
    const [filtroActivo, setFiltroActivo] = useState(false);

    const esSuscripcionEspecial = (sus) => {
        return sus.ZonaPeligrosa || sus.DomicilioConError || sus.ValeLaNacion || sus.RechazoDeSuscripcion;
    };

    useEffect(() => {
        if (detalleSuscripcion && esSuscripcionEspecial(detalleSuscripcion)) {
            history.push({ pathname: PathsConfig.ReclamoEntregaContactoCallcenter, state: reclamoEntregaParams });
        }
        if (suscripcionSelected && motivoSelected && detalleSuscripcion) {
            getDespacho(suscripcionSelected, motivoSelected, detalleSuscripcion, fechaDesde, fechaHasta);
        }
    }, [detalleSuscripcion]);

    const filtrarDespachos = (fechaDesdeDatePicker, fechaHastaDatePicker) => {
        getDespacho(suscripcionSelected, motivoSelected, detalleSuscripcion, fechaDesdeDatePicker, fechaHastaDatePicker);
        setFiltroActivo(true);
    };

    const clickContinuarReclamo = (suscripcion, motivo, despachos, fechas) => {
        if (!despachosSelected.length) {
            setInvalid(true);
        } else {
            var params = {
                pathname: '',
                state: {
                    suscripcionSelected: suscripcion,
                    motivoSelected: motivo,
                    address: detalleSuscripcion.AddressId,
                    despachos: despachos.map((i) => {
                        return { IdDespacho: i, ObservacionCorrecta: true };
                    }),
                    fechaDesde: new Date(fechas.desde).toISOString().split('T')[0],
                    fechaHasta: new Date(fechas.hasta).toISOString().split('T')[0],
                },
            };

            //requeridos para reclamo de tipo "recibí un producto no solicitado" y "No recibi"
            if (motivo == 6 || motivo == 8) {
                params.state['detalleSuscripcion'] = detalleSuscripcion;
            }
            //requerido para reclamo del tipo "Sin ejemplar en el Kiosco"
            if (motivo == 9) {
                params.state.title = reclamoEntregaParams.title;
                params.state.buttonRoute = reclamoEntregaParams.buttonRoute;
                params.state.showBanner = reclamoEntregaParams.showBanner;
                params.state.steps = reclamoEntregaParams.steps;
                params.state.activeStep = reclamoEntregaParams.activeStep;
                params.state.breadcrumb = reclamoEntregaParams.breadcrumb;
            }
            params.pathname = redirect(motivo);

            history.push(params);
        }
    };

    const clickCancelar = (response) => {
        history.push({
            pathname: PathsConfig.ReclamoEntrega,
            state: {
                respuesta: response,
            },
        });
    };

    return (
        <div>
            <ErrorBoundary error={errorProducto || errorDespacho || errorSuscripciones}>
                <section className="lay-strechted">
                    <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />
                    <TitleWeb text="Nuevo reclamo de entrega" />
                </section>
                <section className="container-streched">
                    <LoadingSpinner isLoading={isLoadingProducto || isLoadingDespacho || isLoadingSuscripciones}>
                        <Wizard steps={steps} activeStep={2} />

                        {(errorSuscripciones && !suscripciones) || errorProducto || errorDespacho ? (
                            <ErrorGenerico />
                        ) : despachos && !despachos.length && !filtroActivo ? (
                            <>
                                <Toast status="info" title="No posee despachos">
                                    No se encontraron despachos para hacer el reclamo correspondiente.
                                </Toast>
                                <Button
                                    href={'#'}
                                    mod="secondary"
                                    label="VOLVER"
                                    onClick={() => {
                                        clickCancelar('Volver');
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <CallbackArticle
                                    description={
                                        detalleSuscripcion && (
                                            <Paragraph size="--s" mod="--arial --font-bold">
                                                Seleccioná las ediciones de {detalleSuscripcion.NombreAmigable} a reclamar
                                            </Paragraph>
                                        )
                                    }
                                />
                                {detalleSuscripcion?.TipoFamilia != 'R' && (
                                    <form>
                                        <FormRow>
                                            <InputGroup className="--align-bottom">
                                                <FormCol>
                                                    <RangePicker
                                                        initialDatePicker={
                                                            <DatePicker
                                                                label="Desde"
                                                                selected={fechaDesde}
                                                                onChange={setFechaDesde}
                                                                startDate={fechaDesde}
                                                                endDate={fechaHasta}
                                                                isInvalid={fechaDesde > fechaHasta}
                                                                aria-label="Desde"
                                                            />
                                                        }
                                                        finalDatePicker={
                                                            <DatePicker
                                                                label="Hasta"
                                                                selected={fechaHasta}
                                                                onChange={setFechaHasta}
                                                                startDate={fechaDesde}
                                                                endDate={fechaHasta}
                                                                isInvalid={fechaDesde > fechaHasta}
                                                                aria-label="Hasta"
                                                            />
                                                        }
                                                    />
                                                </FormCol>
                                                <FormCol>
                                                    <Button
                                                        mod="tertiary"
                                                        label="Filtrar"
                                                        icon="filter"
                                                        iconSize="--fivexs"
                                                        isFirstPositioned={true}
                                                        onClick={() => filtrarDespachos(fechaDesde, fechaHasta)}
                                                    />
                                                </FormCol>
                                            </InputGroup>
                                        </FormRow>
                                    </form>
                                )}
                                <form>
                                    <FormRow>
                                        <SelectorDespachos
                                            despachos={despachos}
                                            isRevista={detalleSuscripcion?.TipoFamilia == 'R'}
                                            onSelected={setDespachosSelected}
                                            onInvalid={setInvalid}
                                            invalid={invalid}
                                        />
                                    </FormRow>
                                    <FormRow mod="--btn-groups">
                                        <Button
                                            disabled={invalid}
                                            href={'#'}
                                            label="CONTINUAR RECLAMO"
                                            mod="state--mobile-w100"
                                            onClick={() => {
                                                clickContinuarReclamo(suscripcionSelected, motivoSelected, despachosSelected, {
                                                    desde: fechaDesde,
                                                    hasta: fechaHasta,
                                                });
                                            }}
                                        />
                                        <Button
                                            href={'#'}
                                            mod="secondary"
                                            label="CANCELAR"
                                            onClick={() => {
                                                clickCancelar('Cancelar');
                                            }}
                                        />
                                    </FormRow>
                                </form>

                                <CallbackArticle description="En las siguientes fechas especiales no hay entregas: 1 de Enero, Viernes Santo, 1 de Mayo, 7 de Noviembre y 25 de Diciembre." />
                            </>
                        )}
                    </LoadingSpinner>
                </section>
            </ErrorBoundary>
        </div>
    );
};

export default Ediciones;
