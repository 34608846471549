const convertDate_ddMMyyyy = (inputDate) => {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputDate);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  //inputDate.toLocaleDateString("es-AR") NO SIRVE POR QUE NO LE PONE LOS 00,
  //EJ: 26/6/2011 VS 26/06/2011
  //y el servicio los requiere
};

const correctTimeZone = (ISOString) => {
  if (!ISOString) return ISOString;
  //con el split quito las horas que le agrega el servicio del crm al guardarla, solo me interesa la fecha
  //ademas la paso a local sacandole la Z final
  //return new Date((ISOString).split('T')[0]).toLocaleDateString("es-AR", {timeZone: "UTC"});
  return new Date(ISOString.split("T")[0] + "T00:00");
};

const convertStringDDMMYYYYToDate = (date) => {
  var dateParts = date.split("/");
  return new Date(dateParts[1] + "-" + dateParts[0] + "-" + dateParts[2]);
};

const changeDateStringFormat = (date) => {
  // Cambia yyyy-mm-dd string format a dd/mm/yyyy y modifica (-) por (/)
  let dateParts = date.split("-");
  return dateParts[2].substring(0, 2) + "/" + dateParts[1] + "/" + dateParts[0];
};

const validatePeriod = (fechaInicial, cantidadDias, inclusive = false) => {
  let inicio = new Date(fechaInicial);
  let limite = new Date();
  let hoy = new Date();

  hoy.setUTCHours(0, 0, 0, 0);
  limite.setDate(hoy.getDate() + 1 + cantidadDias);
  inclusive
    ? limite.setUTCHours(23, 59, 59, 999)
    : limite.setUTCHours(0, 0, 0, 0);

  return inicio <= limite && inicio >= hoy;
};

const getDateDiff = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  start.setUTCHours(0, 0, 0, 0);
  end.setUTCHours(0, 0, 0, 0);

  let dif = endDate - startDate;
  return Math.floor(dif / (1000 * 60 * 60 * 24));
};

const esIntervaloFechasValido = (startDate, endDate, minDays, maxDays) => {
  let dif = getDateDiff(startDate, endDate);

  return dif >= minDays && dif <= maxDays;
};

const esFechaAnterior = (fecha, fechaLimite) => {
  let fechaString = convertStringDDMMYYYYToDate(fecha);
  let fechaLimiteString = convertStringDDMMYYYYToDate(fechaLimite);

  return fechaString < fechaLimiteString;
};
const esFechaPosterior = (fecha, fechaLimite) => {
  let fechaString = convertStringDDMMYYYYToDate(fecha);
  let fechaLimiteString = convertStringDDMMYYYYToDate(fechaLimite);

  return fechaString > fechaLimiteString;
};

const addDaystoDate = (days, dateMin) => {
  const date = dateMin ? new Date(dateMin.valueOf()) : null;
  if(date){
    date.setDate(date.getDate() + days);
  }
  return date;
}

const getFechaConvertida = (strDate) => {
  const fechaTransf = strDate.split(' ')[0].split('/');
  return new Date(fechaTransf[2], fechaTransf[1] - 1, fechaTransf[0]);
};

const dateIsValid = (date) => {
  return date instanceof Date && !isNaN(date);
};

const validatePeriodByMonth = (initialDate, months)=>{
 let limitDate = new Date(initialDate)
 let today = new Date()
 if(limitDate){
  limitDate.setMonth(limitDate.getMonth() + months)
 }
 
 return today >= limitDate;

}

export default {
  convertDate_ddMMyyyy,
  correctTimeZone,
  convertStringDDMMYYYYToDate,
  changeDateStringFormat,
  validatePeriod,
  esIntervaloFechasValido,
  esFechaAnterior,
  esFechaPosterior,
  addDaystoDate,
  getFechaConvertida,
  dateIsValid,
  validatePeriodByMonth
};
