import React from 'react';
import { useField } from 'formik';
import RadioInput from '../Atoms/Form/RadioInput';
import _helperStatusFormik from '../../../Helpers/StatusFormik'
import _handleStatusFormik from '../../../Helpers/StatusFormik';

const RadioFormik = (props) => {
    const [field, meta, helpers] = useField(props);

    return (
        <RadioInput {...props} state={_helperStatusFormik(meta)}>
            {props.children}
        </RadioInput>
    );
};

export default RadioFormik;