import GoogleTagManagerService from './GoogleTagManagerService';
import Embed from '../Models/Embed';
import Cookies from 'js-cookie';
import { GetKeyFromValue } from '../Models/CambioPlanCodigo';

const trackPage = (pageName, pathName, data) => {
    const { NombreActual, NombreUpsell, PrecioUpsell, PlanDeVentaUpsell, PlanDeVentaActual, Codigo } = data;
    const params = {
        pageName: pageName || pathName,
        planActual: NombreActual,
        planActualId: PlanDeVentaActual,
        planSeleccionado: NombreUpsell,
        planSeleccionadoId: PlanDeVentaUpsell,
        precioNuevoPlan: PrecioUpsell,
        codigoDescripcion: GetKeyFromValue(Codigo),
    };
    GoogleTagManagerService.trackCustomEvent('trackPage', params);
};

const trackError = (pathName) => {
    GoogleTagManagerService.trackCustomEvent('trackPage', { pageName: 'upselling_error_page', urlPlanSeleccionado: pathName });
};

const trackTransaction = (data) => {
    const { NombreUpsell, PrecioUpsell, PlanDeVentaUpsell, CredencialesPlanUpsell, productCategory, IdCanalDeVenta, IdFormaDeContacto } = data;
    let platformCookie = Embed;
    let platformName = '';
    if (platformCookie) {
        let platformNameCookie = Cookies.get('platformName');
        platformName = platformNameCookie || 'App Android';
    }

    const params = {
        name: NombreUpsell,
        price: PrecioUpsell,
        sku: PlanDeVentaUpsell,
        quantity: CredencialesPlanUpsell,
        brand: platformName || 'Web',
        category: 'Upsell',
        canalVenta: IdCanalDeVenta,
        formaContacto: IdFormaDeContacto,
    };
    if (productCategory) params.productCategory = productCategory;
    GoogleTagManagerService.trackCustomEvent('upselling-confirmado', params);
};

export { trackPage, trackError, trackTransaction };
