import * as Yup from 'yup';

const CambiarContrasenaSchema = Yup.object().shape({
    viejaContrasena: Yup.string()
        .matches(/\d+/, 'La contraseña debe contener al menos un número')
        .matches(/[a-zA-Z]+/, 'La contraseña debe contener al menos una letra')
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .required('Campo requerido'),
    nuevaContrasena: Yup.string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .matches(/\d+/, 'La contraseña debe contener al menos un número')
        .matches(/[a-zA-Z]+/, 'La contraseña debe contener al menos una letra')
        .required('Campo requerido'),
    repiteContrasena: Yup.string()
        .matches(/\d+/, 'La contraseña debe contener al menos un número')
        .matches(/[a-zA-Z]+/, 'La contraseña debe contener al menos una letra')
        .required('Campo requerido')
        .oneOf([Yup.ref("nuevaContrasena"), null], "Las contraseñas deben ser iguales"),
});

export default CambiarContrasenaSchema;