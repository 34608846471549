import React, { useState } from 'react'
import ApiService from '../Services/ApiService';
import { EstadosBonificados } from '../Config/EstadosBonificados';

const useObtenerEventosBonificadosPorAccesoDigital = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        ApiService.post(`${REACT_APP_APIPAYWALL}EventosSuscripciones/ObtenerEventosBonificadosByAccesoDigital`)
            .then(data => {
                if (data && data.code === "0000") { 
                    const eventosObtenidos = data.response?.res?.eventos;
                    if(eventosObtenidos?.length > 1){
                        const tycActivos = eventosObtenidos.some(obj => obj.EstadoTyC == EstadosBonificados[2] || obj.EstadoTyC == EstadosBonificados[6]);
                        setEventos(tycActivos ? null : eventosObtenidos.filter((evento) => evento.EstadoTyC == EstadosBonificados[1]));
                    } else if(eventosObtenidos?.EstadoTyC == EstadosBonificados[1]){
                        setEventos([eventosObtenidos])
                    } else {
                        setEventos([]);
                    }
                    setIsLoading(false);
                } else if (data && (data.code === "0001" || data.code === "0002")) {
                    setEventos(null);
                    setIsLoading(false);
                }
                else {
                    setError(data.message);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setError(error);
                setIsLoading(false);
            });
    };

    return [eventos, error, isLoading, fetchData];
}

export default useObtenerEventosBonificadosPorAccesoDigital