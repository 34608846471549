import PrePagoPendiente from "../../../Models/PrePagoPendiente";

export const getPrepagoModel = (deudas, idTarjeta, idAbandonoCobranza) => {
    PrePagoPendiente.Deudas = deudas?.map(deuda => ({
        Id : deuda.Id,
        Estado : deuda.Estado,
        Importe : deuda.Importe,
        InstrumentoDeCobro : deuda.InstrumentoDeCobro,
        MotivoRechazo : deuda.MotivoRechazo,
        NroFactura : deuda.NroFactura,
        UltimosNumerosTarjeta : deuda.UltimosNumerosTarjeta,
        Vencida: deuda.vencida
    }));
    PrePagoPendiente.IdTarjeta = String(idTarjeta);
    PrePagoPendiente.IdAbandonoCobranza = String(idAbandonoCobranza);

    return PrePagoPendiente;
}