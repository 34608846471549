import React from 'react';
import FormCol from '../../Common/Atoms/Form/FormCol';
import InputBox from '../../Common/Atoms/Form/InputBox';
import CheckInput from '../../Common/Atoms/Form/CheckInput';
import MiniCards from '../../Common/Atoms/MiniCards';
import PropTypes from "prop-types";
import CredencialModel from "../../../Models/Credencial";

const propTypes = {
    credencial: PropTypes.object.isRequired,
    withValidationError: PropTypes.any,
    handleSelection: PropTypes.func.isRequired,
    selectedItems: PropTypes.array.isRequired
}

const Credencial = ({ credencial, withValidationError, handleSelection, selectedItems, disabled }) => {
    // TODO: checkear que cuando éste componente se consuma en el futuro esté bien el agregado de éstos disabled
    return (
        <FormCol className="--mb-16">
            <InputBox
                disabled={disabled}
                error={!disabled && withValidationError}
                mod="--withSvg"
                input={
                    <CheckInput
                        value={credencial.IdCredencial}
                        onChange={handleSelection}
                        disabled={disabled} 
                        checked={selectedItems.some(item => item == credencial.IdCredencial && credencial.SituacionDeEntregaId == CredencialModel.SituacionDeEntrega.ENTREGADA )}
                    >
                        <div className="--grid --ai-center --gt-40-1fr">
                            <MiniCards
                                cardType={
                                    credencial.CategoriaCredencial.Descripcion
                                }
                            />
                            <p>
                                <b className="--ml-8">
                                    {credencial.NumeroCredencialFormateado}
                                </b>
                                <span className="--ml-8 --light-neutral-600 --flex-inline">{`${credencial.NombreCliente} ${credencial.ApellidoCliente}`}</span>
                            </p>
                        </div>
                    </CheckInput>
                }
            />
        </FormCol>
    );
};

Credencial.propTypes = propTypes;

export default Credencial;
