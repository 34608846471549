const _handleStatusFormik = (meta) => {
    let res;

    if (meta == undefined) {
        res = false;
    }
    if (meta.error && meta.touched) {
        res = { status: 'error', msg: meta.error };
    } else if (!meta.error && meta.touched) {
        res = { status: 'success' };
    }
    return res;
};

export default _handleStatusFormik;
