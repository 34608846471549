import MotivosMap from '../Map/MotivosMap'

const RedirectSegunMotivo = () =>{
    const [ getMotivo ] = MotivosMap()

    const redirect = (motivo) => { 
        let value = getMotivo(motivo)
        return value
    }

    return [redirect]
}

export default RedirectSegunMotivo;