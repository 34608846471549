import React from 'react';
import Link from '../../Common/Atoms/Link';
import Button from '../../Common/Modules/Button';
import Toast from '../../Common/Modules/Toast';
import FormRow from '../../Common/Atoms/Form/FormRow';


const SuscripcionConSaldo = ({ saldoPendiente }) => {
    return (
        <div>
            <>
                <Toast status="warning" title={`Tu cuenta registra un saldo pendiente de $${saldoPendiente}`}>
                    Saldá tus pagos en <Link href="/mis-pagos/pagos-pendientes">Pagos Pendientes</Link> y obtené el libre de deuda
                </Toast>

                <FormRow mod="--btn-groups">
                    <Button className="state--mobile-w100" label="Ver libre de deuda" disabled={true} />
                </FormRow>
            </>
        </div>
    );
};

export default SuscripcionConSaldo;
