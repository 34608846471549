import React, { useState, forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../../Statics/Scss/Components/CredentialCardBox.scss';
import Link from '../Atoms/Link';
import MiniCards from '../Atoms/MiniCards';
import Paragraph from '../Atoms/Paragraph';
import CardBoxItem from './CardBoxItem';
import Button from './Button';
import Badge from './Badge';
import { states } from '../../../Config/StatesMisCredenciales';

const propTypes = {
    /**
     * Nombre asociado.
     */
    nombre: PropTypes.string,
    /**
     * Tipo de credencial: Classic, Premium o Black
     */
    tipoDeCredencial: PropTypes.string,
    /**
     * Número de la credencial
     */
    nroDeCredencial: PropTypes.string,
    /**
     * Activa / Baja pendiente / Baja
     */
    estadoCredencial: PropTypes.string,
    /**
     * Entregada / Pendiente de despacho / No entregada
     */
    situacionDeEntrega: PropTypes.string,
    /**
     * fecha desde - fecha hasta
     */
    vigencia: PropTypes.string,
    /**
     * Domicilio de entrega de la credencial
     */
    domicilioDeEntrega: PropTypes.string,

    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const CredentialCardBox = forwardRef(
    (
        {
            nombre,
            tipoDeCredencial,
            nroDeCredencial,
            estadoCredencial,
            situacionDeEntrega,
            vigencia,
            domicilioDeEntrega,
            onClickBaja,
            onClickReclamar,
            className,
            esAdicional,
            esCredTitular,
            ...r
        },
        ref
    ) => {
        const classes = classNames('mc-credential-card-box', className);
        const [message, setMessage] = useState(false);
        const [situacionDeEntregaColor, setSituacionDeEntregaColor] = useState('primary');
        const [estadoCredencialColor, setEstadoCredencialColor] = useState('primary');
        const buttonMenu = useRef(null);

        useEffect(() => {
            const closeOpenMenu = (e) => {
                if (buttonMenu.current && message && !buttonMenu.current.contains(e.target)) {
                    setMessage(false);
                }
            };
            document.addEventListener('mousedown', closeOpenMenu);
        }, [message]);

        useEffect(() => {
            if (situacionDeEntrega && estadoCredencial) {
                states.forEach((s) => {
                    if (s.text.toLowerCase() === situacionDeEntrega.toLowerCase()) {
                        setSituacionDeEntregaColor(s.color);
                    } else if (s.text.toLowerCase() === estadoCredencial.toLowerCase()) {
                        setEstadoCredencialColor(s.color);
                    }
                });
            }
        }, [situacionDeEntrega, estadoCredencial]);

        return (
            <div className={classes} {...r}>
                <div className="content-box">
                    <MiniCards cardType={tipoDeCredencial.toLowerCase()} />

                    <div className="content">
                        {nombre && (
                            <Paragraph size="--twoxs" mod="--arial --font-bold">
                                {nombre}
                            </Paragraph>
                        )}

                        {tipoDeCredencial && (
                            <Paragraph size="4xs" mod="--arial">
                                {tipoDeCredencial}
                            </Paragraph>
                        )}
                    </div>

                    {!esAdicional && (
                        <div className="button-menu" ref={buttonMenu}>
                            <Button icon="more" className="--mb-0" mod="tertiary" iconSize="s" onClick={() => setMessage(!message)} />

                            {message && (
                                <div className="text-menu">
                                    {!esCredTitular && 
                                        <div className="text-baja">
                                            <Link href="javascript:void(0)" onClick={onClickBaja}>
                                                Eliminar credencial
                                            </Link>
                                        </div>
                                    }
                                    <div className="text-baja">
                                        <Link href="javascript:void(0)" onClick={onClickReclamar}>
                                            Reclamar credencial
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="columns">
                    <div className="data">
                        {nroDeCredencial && (
                            <CardBoxItem title={'N° credencial'} className="nro-credencial">
                                {nroDeCredencial}
                            </CardBoxItem>
                        )}

                        {estadoCredencial && situacionDeEntrega && (
                            <CardBoxItem title={'Estado de la credencial'} className="estado-credencial">
                                <Badge type={situacionDeEntregaColor}>{situacionDeEntrega}</Badge>
                                <Badge type={estadoCredencialColor}>{estadoCredencial}</Badge>
                            </CardBoxItem>
                        )}
                    </div>

                    <div className="data">
                        {vigencia && (
                            <CardBoxItem className="--mobile-three-items vigencia" title={'Vigencia'}>
                                {vigencia}
                            </CardBoxItem>
                        )}

                        {domicilioDeEntrega && <CardBoxItem title={'Domicilio de entrega'}>{domicilioDeEntrega}</CardBoxItem>}
                    </div>
                </div>
            </div>
        );
    }
);

CredentialCardBox.propTypes = propTypes;

export default CredentialCardBox;
