import React, { useState } from "react";
import { useHistory } from "react-router";

import Breadcrumb from "../../Components/Common/Modules/Breadcrumb";
import Wizard from "../../Components/Common/Modules/Wizard";
import steps from "../../Config/StepsMisCredenciales";
import LoadingSpinner from "../../Helpers/LoadingSpinner";
import Text from "../../Components/Common/Text/Text";
import TitleWeb from "../../Components/Common/Web/Title";
import Link from "../../Components/Common/Atoms/Link";
import "../../Statics/Scss/Pages/OtorgarCredencial.scss";
import useListarClientesPorDni from "../../Hooks/useListarClientesPorDni";
import ValidacionSchemaDni from "../../Validations/ValidacionClienteExistente";
import NumeroDocumentoForm from "../../Components/Features/MisCredenciales/NumeroDocumentoForm";
import PathsConfig from "../../Config/RoutePaths";
import useRedirectWithout from "../../Hooks/useRedirectWithout";

const BuscarCliente = (props) => {
  const vieneDeBoton = useRedirectWithout(props.location.state,
    ['vieneDeBoton'],
    PathsConfig.MisCredenciales);
  const history = useHistory();
  const [resultados, setResultados] = useState(false);
  const [tieneCredencial, setTieneCredencial] = useState(false);
  const [
    buscarClientes,
    clientesEncontrados,
    errorClientes,
    isLoadingClientes,
  ] = useListarClientesPorDni();

  const handleSubmit = async (props) => {
    setResultados(false);
    if (!props.values.numeroDocumento) {
      props.setErrors = "error";
    } else {
      if (props.errors.numeroDocumento) {
        setResultados(false);
      } else {
        buscarClientes(props.values.numeroDocumento).then((res) => {
          if (res?.length > 0 && res[0].EstadoCredencial !== 0) {
            setTieneCredencial(true)
          } else {
            setTieneCredencial(false)
          }
        });
        setResultados(true);
      }
    }
  };


  const redirectCrearUsuario = () => {
    history.push({
      pathname: PathsConfig.MisCredencialesCrearUsuario,
      state: vieneDeBoton
    });
  };

  return (
    <>
      <section>
        <Breadcrumb
          firstItem="Club LA NACION"
          text="Mis credenciales"
          href="#"
        />
        <TitleWeb text="Otorgar credencial" />
      </section>

      <section className="container-streched">
        <LoadingSpinner isLoading={isLoadingClientes}>
          <Wizard steps={steps} activeStep={1} />

          <Text
            tag="p"
            size="--s"
            font="arial"
            weight="bold"
            className="text-title"
          >
            Completá el siguiente formulario para otorgar una credencial
          </Text>

          <Text tag="p" size="2xs" font="arial" className="link-text">
            Buscá un cliente existente o {""}
            <Link onClick={redirectCrearUsuario}>creá uno nuevo.</Link>
          </Text>

          <NumeroDocumentoForm
            tieneCredencial={tieneCredencial}
            ValidacionSchemaDni={ValidacionSchemaDni}
            resultados={resultados}
            handleSubmit={handleSubmit}
            clientesEncontrados={clientesEncontrados}
          />
        </LoadingSpinner>
      </section>
    </>
  );
};

export default BuscarCliente;
