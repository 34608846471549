export const formatearDomicilio = domicilio => {
    return {
        Altura: domicilio.altura,
        Barrio: domicilio.barrio,
        Calle: domicilio.calle,
        CodigoPostal: domicilio.codigoPostal,
        Country: domicilio.country,
        Cuerpo: domicilio.cuerpo,
        Dpto: domicilio.dto ? domicilio.dto : 'S/N',
        EntreCalle1: domicilio.entreCalle1,
        EntreCalle2: domicilio.entreCalle2,
        Id: domicilio.id,
        Localidad: domicilio.localidad,
        Lote: domicilio.lote,
        Observaciones: domicilio.observaciones,
        Pais: domicilio.pais,
        Piso: domicilio.piso ? domicilio.piso : 'S/N',
        Provincia: domicilio.provincia,
        Torre: domicilio.torre,
    };
};