import React, { useState, useRef } from 'react';
import ErrorBoundary from '../../../Components/Common/Organism/ErrorBoundary';
import LoadingSpinner from '../../../Helpers/LoadingSpinner';
import Breadcrumb from '../../../Components/Common/Modules/Breadcrumb';
import Button from '../../../Components/Common/Modules/Button';
import Wizard from '../../../Components/Common/Modules/Wizard';
import Toast from '../../../Components/Common/Modules/Toast';
import steps from '../../../Config/Steps';
import CallBackArticle from '../../../Components/Common/Modules/CallbackArticle';
import Paragraph from '../../../Components/Common/Atoms/Paragraph';
import useCrearReclamoSuscripcion from '../../../Hooks/useCrearReclamoDeSuscripcion';
import useActualizarTelefonos from '../../../Hooks/useActualizarTelefonos';
import { useHistory } from 'react-router';
import { volver } from '../../../Helpers/Reclamo';
import useRedirectWithout from '../../../Hooks/useRedirectWithout';
import MismoDomicilioDiario from '../../../Components/Features/ReclamoEntrega/ReclamoNoRecibi/MismoDomicilioDiario';
import MismoDomicilioRevista from '../../../Components/Features/ReclamoEntrega/ReclamoNoRecibi/MismoDomicilioRevista';
import '../../../Statics/Scss/Components/Input.scss';
import Domicilios from '../../../Components/Features/Domicilios';
import InfoCanillita from '../../../Components/Features/Reclamos/InfoCanillita';
import TitleWeb from '../../../Components/Common/Web/Title';
import PathsConfig from '../../../Config/RoutePaths';
import FormRow from '../../../Components/Common/Atoms/Form/FormRow';

const NoRecibi = (props) => {
    const history = useHistory();
    const stepProps = ['suscripcionSelected', 'motivoSelected', 'despachos', 'address', 'fechaDesde', 'fechaHasta', 'detalleSuscripcion'];
    const { suscripcionSelected, motivoSelected, despachos, address, fechaDesde, fechaHasta, detalleSuscripcion } = useRedirectWithout(
        props.location.state,
        stepProps,
        PathsConfig.ReclamoEntrega
    );
    const [reclamoError, reclamoIsLoading, CrearReclamoDeSuscripcion] = useCrearReclamoSuscripcion();
    const [errorTelefono, isLoadingTelefono, updateTelefono] = useActualizarTelefonos();
    const [errorFormDomicilios, setErrorFormDomicilios] = useState(false);
    const [domicilioCreado, setDomicilioCreado] = useState(null);
    const [infoContacto, setInfoContacto] = useState(null);
    const [statusErrorCanillita, setStatusErrorCanillita] = useState(false);
    const [optionCheckedTelCanillita, setOptionCheckedTelCanillita] = useState(false);
    const [optionCheckedCambiarCanillita, setOptionCheckedCambiarCanillita] = useState(false);
    const [optionRetiraProductoEnKiosko, setOptionRetiraProductoEnKiosko] = useState(false);
    const [esSuDomicilio, setEsSuDomicilio] = useState(true);
    const [domicilio, setDomicilio] = useState(address);
    const formRef = useRef();

    const isRevista = detalleSuscripcion?.TipoFamilia == 'R' || false;

    const crearReclamo = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }

        if (!domicilio) {
            setErrorFormDomicilios(true);
            return;
        }

        if (statusErrorCanillita && !isRevista) return;

        let data = {
            IdSuscripcion: suscripcionSelected,
            IdMotivoDeReclamo: motivoSelected,
            Despachos: [{ IdDespacho: despachos[0].IdDespacho, ObservacionCorrecta: true }],
            AddressId: domicilio,
            FechaDesde: fechaDesde,
            FechaHasta: fechaHasta,
            Distribuidor: optionCheckedCambiarCanillita ? infoContacto.nroDistribuidor : '',
            Linea: optionCheckedCambiarCanillita ? infoContacto.nroLinea : '',
            Vendedor: optionCheckedCambiarCanillita ? infoContacto.nroVendedor : '',
            CambiarDomicilio: domicilio != address,
            //PagoAlCanillita: false,//se utiliza para otro tipo de reclamo
            //Horario: '',
            RetiraProductoEnKiosko: optionRetiraProductoEnKiosko,
            Observaciones: infoContacto?.observacionContacto,
            DomicilioCorrecto: domicilio == address,
            EnviarTelefonoAlCanillita: optionCheckedTelCanillita,
            RealizarCambioDeCanillita: optionCheckedCambiarCanillita,
        };

        if (infoContacto && optionCheckedTelCanillita && infoContacto.celular) {
            updateTelefono({ TelCelular: infoContacto.celular, TelParticular: infoContacto.TelParticular });
        }

        CrearReclamoDeSuscripcion(data);
    };
    return (
        <div>
            <section>
                <Breadcrumb firstItem="Mis reclamos" text="De entrega" href="#" />

                <TitleWeb text="Nuevo reclamo de entrega" />
            </section>

            <section className="container-streched">
                <LoadingSpinner isLoading={reclamoIsLoading || isLoadingTelefono}>
                    {domicilioCreado && !domicilioCreado.Id && (
                        <Toast status="success" title="Nuevo domicilio creado">
                            <span>Un nuevo domicilio fue creado correctamente</span>
                        </Toast>
                    )}

                    {reclamoError.Code == -1 && (
                        <Toast status="warning" title="Ocurrió un error">
                            <span>{reclamoError.Message}</span>
                        </Toast>
                    )}

                    <ErrorBoundary error={reclamoError || errorTelefono}>
                        <Wizard steps={steps} activeStep={3} />

                        <CallBackArticle
                            description={
                                <Paragraph size="--s" mod="--font-bold --arial">
                                    Confirmá la siguiente información antes de generar el reclamo
                                </Paragraph>
                            }
                        />

                        <Domicilios
                            nombreSuscripcion={detalleSuscripcion?.Nombre}
                            susDomicilioId={address}
                            idSuscripcion={suscripcionSelected}
                            setDomicilio={setDomicilio}
                            setErrorForm={setErrorFormDomicilios}
                            errorForm={errorFormDomicilios}
                            setEsSuDomicilio={setEsSuDomicilio}
                            domicilioPrincipal={{
                                Id: detalleSuscripcion?.Id,
                                Calle: detalleSuscripcion?.Calle,
                                Altura: detalleSuscripcion?.Altura,
                                Localidad: detalleSuscripcion?.Localidad,
                                Provincia: detalleSuscripcion?.Provincia,
                                CodigoPostal: detalleSuscripcion?.CodigoPostal,
                            }}
                        />

                        {esSuDomicilio && domicilio && domicilio == address && (
                            <>
                                <InfoCanillita canillitaDescripcion={detalleSuscripcion?.VendedorDescripcion} canillitaObservaciones={detalleSuscripcion?.VendedorObservaciones} />
                                {isRevista ? (
                                    <MismoDomicilioRevista
                                        optionRetiraProductoEnKiosko={optionRetiraProductoEnKiosko}
                                        setOptionRetiraProductoEnKiosko={setOptionRetiraProductoEnKiosko}
                                        formRef={formRef}
                                    />
                                ) : (
                                    <MismoDomicilioDiario
                                        formRef={formRef}
                                        setInfoContacto={setInfoContacto}
                                        setStatusErrorCanillita={setStatusErrorCanillita}
                                        optionCheckedTelCanillita={optionCheckedTelCanillita}
                                        setOptionCheckedTelCanillita={setOptionCheckedTelCanillita}
                                        optionCheckedCambiarCanillita={optionCheckedCambiarCanillita}
                                        setOptionCheckedCambiarCanillita={setOptionCheckedCambiarCanillita}
                                    />
                                )}
                            </>
                        )}
                        <FormRow mod="--btn-groups">
                            <Button mod="state--mobile-w100" href={'#'} onClick={crearReclamo} label="GENERAR RECLAMO" />
                            <Button
                                onClick={() => volver(history, suscripcionSelected, motivoSelected)}
                                href={'#'}
                                mod="secondary"
                                label="VOLVER"
                            />
                        </FormRow>
                    </ErrorBoundary>
                </LoadingSpinner>
            </section>
        </div>
    );
};

export default NoRecibi;
