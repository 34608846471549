import { isBonificado } from "../Filter/suscripciones";

export const ObtenerEstadoSuscripcionPorTipoVenta = (sus, evento, planes) => {
    const isBonificada = isBonificado(evento, sus, planes); //isBonificado debe recibir en todas sus implementaciones el evento, la suscripcion y los planes
    let idEstadoSuscripcion = 0;
    if(!sus.Tarjeta){
        sus.TipoDeVenta === 'Venta Alianza' ? idEstadoSuscripcion = 1 : idEstadoSuscripcion = 4 ;
    }
    if ((sus.TipoDeVenta !== 'Venta Individuo' && sus.TipoDeVenta !== 'Venta Alianza') || isBonificada) {
        idEstadoSuscripcion = 1;
    }
    if (sus.EsGoogle) {
        idEstadoSuscripcion = 2;
    }
    if (sus.EsApple) {
        idEstadoSuscripcion = 3;
    }
    return idEstadoSuscripcion;
};