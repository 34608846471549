import React, { useEffect, useState } from 'react'
import ApiService from '../Services/ApiService';
import Datadog from '../Helpers/Datadog';
import { CustomEventsErrorDatadog } from '../Config/Datadog';

const useObtenerVerificacionCredenciales = () => {
    const { REACT_APP_APIPAYWALL } = process.env;
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            ApiService.post(`${REACT_APP_APIPAYWALL}CredencialV1/ObtenerVerificacionCredenciales`)
                .then((data) => {
                    if (data.code == '0000') {
                        const isBlockedCredenciales = data.response;
                        if(isBlockedCredenciales) Datadog.trackCustomEventWarning(CustomEventsErrorDatadog.errorBloqueoGestionCredenciales);
                        setResponse(isBlockedCredenciales);
                    } else {
                        setError(true);
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        fetchData();
    }, []);

    return [response, error, isLoading];
}

export default useObtenerVerificacionCredenciales