import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RadioInput from './Form/RadioInput';
import Label from './Form/Label';
import InputBox from './Form/InputBox';

const propTypes = {
    title: PropTypes.string.isRequired,
    suscripciones: PropTypes.array.isRequired,
    suscripcionSelectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleSuscripcionSelectedId: PropTypes.func.isRequired,
    className: PropTypes.string,
    valSuscripcion: PropTypes.object
};

const InfoSuscription = ({ title, suscripciones, suscripcionSelectedId, handleSuscripcionSelectedId, className, valSuscripcion }) => {
    const classes = classNames('suscripcionContainer', className);
    const suscriptionCommonClasses = 'flex flex-column gap-8';

    return (
        <div className={classes}>
            <Label text={title} />
            <div className={suscriptionCommonClasses}>
                {suscripciones?.map((suscripcion) => (
                    <InputBox 
                        key={suscripcion.Id} 
                        error={valSuscripcion} 
                        input={
                            <RadioInput
                                name="suscripcion"
                                value={suscripcion.Id}
                                checked={suscripcionSelectedId === suscripcion.Id}
                                onChange={() => handleSuscripcionSelectedId(suscripcion.Id)}
                                error={valSuscripcion}
                            >
                                {suscripcion.NombreAmigable}
                            </RadioInput>
                    }>
                    </InputBox>
                ))}
            </div>
        </div >
    );
};

InfoSuscription.propTypes = propTypes;

export default InfoSuscription;
