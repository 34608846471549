import React, { useState } from 'react'
import IframeTarjeta from "../../../Pages/Pagos/IframeTarjeta";
import Title from "../../Common/Atoms/Title";
import Button from "../../Common/Modules/Button";
import TotalImport from "../../Common/Modules/TotalImport";
import ConfigCanalesDeContacto from '../../../Config/ConfigCanalesDeContacto';

const WondersoftContainer = (props) => {
    const { title, heigh, showImport, deudas, deudasCompletas, idTarjeta, idAbandonoCobranza, total, volverFunc, ocultarCheckCIC, campanita } = props;
    return (
        <>
            <Title size="--s" mod="--font-bold --arial --mb-24">
                {title}
            </Title>

            <div>
                <IframeTarjeta iframeHeight={heigh ? heigh : 410}
                    showImport={showImport}
                    deudas={deudas}
                    deudasCompletas={deudasCompletas ? deudasCompletas : null}
                    tarjeta={idTarjeta ? idTarjeta : null}
                    ocultarCheckCIC={ocultarCheckCIC}
                    idAbandonoCobranza={idAbandonoCobranza} 
                    idRechazoCanalDeContacto={campanita ? ConfigCanalesDeContacto.Campanita : ConfigCanalesDeContacto.Autogestion}/>
            </div>

            {showImport && <TotalImport className="--mt-24" cost={total} />}

            <Button
                label="Volver"
                onClick={volverFunc}
                className="--mb-8 --mt-24"
                mod="tertiary"
            />
        </>
    )
}

export default WondersoftContainer;