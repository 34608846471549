import React from 'react';
import { useHistory } from 'react-router';
import Button from '../../Common/Modules/Button';
import Text from '../../Common/Text/Text';
import PropTypes from 'prop-types';
import '../../../Statics/Scss/Pages/MisCredenciales.scss';
import PathsConfig from '../../../Config/RoutePaths';

const propTypes = {
    grupoCredenciales: PropTypes.object,
};

const CardCredencialesPendientesDeOtorgar = ({ gruposCredenciales }) => {
    const history = useHistory();
    const grupoConCredencialesPendientes = gruposCredenciales.find(grupo => grupo?.CantidadPendientes?.Cantidad > 0)?.CantidadPendientes;
    
    return (
        <div className="info-credenciales">
            <div className="info-credenciales-text">
                <Text
                    tag="p"
                    size="--xl"
                    font="arial"
                    weight="bold"
                    className="info-number"
                >
                    {!!grupoConCredencialesPendientes
                        ? grupoConCredencialesPendientes?.Cantidad
                        : '0'}
                </Text>
                <Text tag="p" size="2xs" font="arial" className="info-text">
                    {!!grupoConCredencialesPendientes ?
                    (
                        <span className="no-hay-credenciales">
                            Credenciales disponibles
                        </span>
                    ) : (
                        <span>
                            Credenciales <strong>{grupoConCredencialesPendientes?.Categoria ? grupoConCredencialesPendientes?.Categoria : "Premium"}</strong> disponibles
                        </span>
                    )}
                </Text>
            </div>

            {!!grupoConCredencialesPendientes && (
                <Button
                    size={'large'}
                    label={'Otorgar'}
                    onClick={() => {
                        history.push({
                            pathname: PathsConfig.MisCredencialesBuscarCliente,
                            state: { vieneDeBoton: true }
                        });
                    }}
                ></Button>
            )}
        </div>
    );
};

CardCredencialesPendientesDeOtorgar.propTypes = propTypes;

export default CardCredencialesPendientesDeOtorgar;
