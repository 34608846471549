const CambioPlanCodigo = {
    ERROR_DATOS: -1,
    OK: 0,
    USUARIO_INVALIDO: 1,
    DEUDA: 2,
    CALL_CENTER: 3,
    DOWNSALE: 4,
}

const GetKeyFromValue = (value) => {
    return Object.keys(CambioPlanCodigo).find(key => CambioPlanCodigo[key] == value);
}

export { CambioPlanCodigo, GetKeyFromValue};