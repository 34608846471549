import React from 'react';

import classNames from 'classnames';
import '../../../Statics/Scss/Components/CardBoxContent.scss';

import Paragraph from '../Atoms/Paragraph';
import Title from '../Atoms/Title';
import CardBoxItem from './CardBoxItem';

const CardBoxContent = ({
	className,
	period,
	billNumber,
	cost,
	description,
	...r
}) => {
	const classes = classNames('mc-card-box-content', className);

	return (
		<div className={classes} {...r}>
			<div>
				<Title size="--twoxs">{description}</Title>
				<Paragraph size="--s">${cost.toFixed(2)}</Paragraph>
			</div>
			<div>
				{period && <CardBoxItem title="Período">{period}</CardBoxItem>}
				{billNumber && (
					<CardBoxItem title="Número de factura">
						{billNumber}
					</CardBoxItem>
				)}
			</div>
		</div>
	);
};

export default CardBoxContent;
