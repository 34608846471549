import React from 'react';
import { useHistory } from 'react-router';
import { SuperCardBox, HeaderSuperCardBox, BodySuperCardBox } from '../SuperCardBox';
import Text from '../Text/Text';
import Button from '../Modules/Button';
import FormRow from '../Atoms/Form/FormRow';
import PathsConfig from '../../../Config/RoutePaths';

const BeneficioExclusivoBanner = ({ evento }) => {
    const history = useHistory();

    const handleRedirect = () => {
        history.replace({
            pathname: PathsConfig.VincularCredencialActivarBonificada,
            state: evento 
        })
    }

    return (
        <SuperCardBox bgState="blue">
            <HeaderSuperCardBox>
                <Text tag="p" size="5xs" font="arial" weight="bold">
                    BENEFICIO EXCLUSIVO
                </Text>
                <Text tag="h5" size="--twoxs" font="arial" weight="bold">
                    Acceso Digital Bonificado
                </Text>
            </HeaderSuperCardBox>

            <BodySuperCardBox>
                <Text tag="p" size="4xs" font="arial" className="--paragraph --mb-16">
                    Por ser parte de CLUB LA NACION tenés Acceso Digital SIN CARGO. Comenzá a disfrutar de este beneficio.
                </Text>
                <FormRow mod="--btn-groups">
                    <Button type="submit" mod="state--mobile-w100" onClick={handleRedirect} label="ACTIVAR BENEFICIO" />
                </FormRow>
            </BodySuperCardBox>
        </SuperCardBox>
    );
};

export default BeneficioExclusivoBanner;
