import React, { useState } from 'react';
import Button from '../Common/Modules/Button';
import Paragraph from '../Common/Atoms/Paragraph';
import surveyImage from '/ClientApp/src/Statics/Imgs/encuesta.png';
import useObtenerDatosClienteOnLoad from '../../Hooks/useObtenerDatosClienteOnLoad';
import ModalPortalBG from '../Common/Atoms/ModalPortalBG';
import { trackCustomEvent } from '../../Helpers/Datadog';
import { CustomEventsDatadog } from '../../Config/Datadog';

import '../../Statics/Scss/Modules/ExperienceModal.scss';
import FormRow from '../Common/Atoms/Form/FormRow';

const ExperienceModal = ({ nroGestion , origenEncuesta}) => {
    const [cliente, isLoadingCRM] = useObtenerDatosClienteOnLoad();
    const [modalOpened, setModalOpened] = useState(true);

    const handleOnSurveyLink = () => {
        trackCustomEvent(CustomEventsDatadog.responderEncuesta, { origenEncuesta , crmid: cliente.Crmid} );
        window.open(`${process.env.REACT_APP_ENCUESTA}#crmid=${cliente.Crmid}`, '_blank');
    };

    const closeModal = () => {
        setModalOpened(false);
        remove.document.className("modal-active");
    };

    return (
        modalOpened && (
            <ModalPortalBG>
                <div className="contenedor" id="ContarExperienciaModal">
                    <div className="modalContainer">
                        <div className="modalContainer__icon-close-container">
                            <Button
                                className="modalContainer__icon-close-container__icon-close"
                                size="small"
                                onClick={closeModal}
                                icon="close"
                                aria-label="Cerrar"
                            />
                        </div>
                        <div className="modal__body">
                            <div className="container-experience">
                                <img className="container-experience__img" src={surveyImage} />
                                <Paragraph size="--xl" mod="--font-bold --sueca --mb-8">
                                    ¡Tu gestión se realizó con éxito!
                                </Paragraph>
                                <Paragraph size="--m" mod="--font-bold --sueca">
                                    Tu número de transacción es: {nroGestion}
                                </Paragraph>
                                <Paragraph size="--s" mod="--font-medium --sueca --mb-8">
                                    Queremos conocer tu opinión sobre la gestión que realizaste desde Mi Cuenta. <br /> Sólo te va a llevar unos
                                    minutos.
                                </Paragraph>
                                <div className="container-experience__line"></div>
                            </div>
                            <div className="surveyButtons">
                                {!isLoadingCRM && (
                                    <FormRow mod="--btn-groups">
                                        <Button
                                            type="button"
                                            onClick={closeModal}
                                            label="VOLVER A MI CUENTA"
                                            isFirstPositioned={true}
                                            className="state--mobile-w100 --mb-0 backButton"
                                            iconSize="s"
                                            mod="secondary"
                                        />
                                        <Button
                                            type="button"
                                            onClick={handleOnSurveyLink}
                                            label="RESPONDER LA ENCUESTA"
                                            isFirstPositioned={true}
                                            className="state--mobile-w100 --mb-0 respondSurvey"
                                            iconSize="s"
                                        />
                                    </FormRow>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalPortalBG>
        )
    );
};

export default ExperienceModal;
