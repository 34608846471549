import React from 'react';
import CallBackArticle from "../../../Components/Common/Modules/CallbackArticle";
import Paragraph from "../../../Components/Common/Atoms/Paragraph";
import Link from "../../../Components/Common/Atoms/Link";

const MensajeApple = ({linkApple}) => {
    return (
        <CallBackArticle
        title="Comprobantes"
        description={
          <Paragraph>
            Para ver tus comprobantes de pago de suscripciones a través de Google Suscriptions{" "}
            <Link target={"_blank"} href={linkApple}>
              hacé clic aquí
            </Link>
          </Paragraph>
        }
      />
    );
};

export default MensajeApple;